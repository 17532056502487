import { LinkContext } from '@anm/contexts/LinkContext';
import { useContext, FC } from 'react';

import { ListItemWrapper, ListWrapper, Title, NativeLink } from './Wrapper';
import { getDropdownOptionsList } from './helpers';
import { TDropdownOptions } from './types';

const DropdownOptions: FC<TDropdownOptions> = props => {
  const { Link } = useContext(LinkContext);
  const optionsList = getDropdownOptionsList(props);

  return (
    <ListWrapper>
      {optionsList.map(({ key, icon, route, label, href, isDesktopOnly, handler, renderComponent }) =>
        icon || renderComponent ? (
          <ListItemWrapper key={key || label} isDesktopOnly={isDesktopOnly} icon={icon} onClick={handler}>
            {renderComponent ? (
              renderComponent()
            ) : href ? (
              <NativeLink href={href} target="_blank">
                {label}
              </NativeLink>
            ) : route ? (
              <Link route={route}>{label}</Link>
            ) : (
              label
            )}
          </ListItemWrapper>
        ) : (
          <Title key={key || label}>{label}</Title>
        )
      )}
    </ListWrapper>
  );
};

export default DropdownOptions;
