export const USERS_BEFORE_REALIZE = 1000000;
export const USERS_PER_DAY = 1000;
const HOSTING_REALIZE_DATE = '01/01/2020';

const getDaysAfterHostingRealise = () => {
  const realizeDate = new Date(HOSTING_REALIZE_DATE).getTime();
  const now = Date.now();

  return (now - realizeDate) / 1000 / 60 / 60 / 24;
};

export const usersCount = Math.floor(
  USERS_BEFORE_REALIZE + getDaysAfterHostingRealise() * USERS_PER_DAY
);
