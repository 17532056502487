import getFromCdn from '@anm/helpers/getFromCdn';
import styled, { css } from 'styled-components';

import { media } from '../../styles/helpers';

export type BackgroundVariants =
  | 'mac'
  | 'white'
  | 'sign-up'
  | 'no-waves'
  | 'white-phone'
  | 'text-on-video'
  | 'with-top-wave'
  | 'company-quote'
  | 'with-both-waves'
  | 'with-top-banner'
  | 'success-stories'
  | 'with-bottom-wave'
  | 'different-devices'
  | 'vertical-text-video'
  | 'horizontal-image-text'
  | 'with-smaller-bottom-wave'
  | 'horizontal-blue-gradient';

export type BackgroundProps = {
  variant: BackgroundVariants;
  imageBackground?: string;
  customBackground?: string;
  withOutTopWave?: boolean;
  isSectionPicture?: boolean;
};

const imagesLink = `${getFromCdn('images/data')}`;

const DifferentDevicesOnLaptop = media.laptop`
    background: url(${imagesLink}/wave_top.svg) no-repeat 70% 0 / auto 148px ,linear-gradient(to left,#1e90c2 0%,#1dbbc3 100%);
`;

const VerticalTextVideoOnLaptop = media.laptop`
    height: 152px;
`;

const BackgroundMacOnTablet = media.tablet`
    padding: 3.8% 11.3% 7.9%;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top;
    background-image: url(${imagesLink}/laptop_1_frame_1x.png);
    width: 100%;
    height: 100%;
    min-height: 402px;
`;

const SignUpOnTablet = media.tablet`
  padding: 190px 0 10px 0;

  &:before {
    position: absolute;
    z-index: 3;
    content: "";
    height: 150px;
    width: 100%;
    top: -2px;
    left: 0;
    background: url(${imagesLink}/wave_top_gray.svg) repeat-x top transparent;
  }
`;

const SignUpWithOutTopWaveOnTablet = media.tablet`
  padding: 100px 0 10px 0;

  &:before {
      display: none;
  }
`;

const TextOnVideoOnLaptop = media.laptop`
  background: url(${imagesLink}/gradient-smmw.svg) no-repeat top center / cover;
`;

const HorizontalImageTextOnLaptop = media.laptop`
  padding-top: 100px;
`;

const HorizontalImageTextOnDesktop = media.desktop`
  padding-top: 50px;
`;

const WhitePhoneOnLaptop = media.laptop`
  max-width: 228px;
  padding: 48px 13px 58px 14px;
  border-radius: 32px;
`;

const WaveOnLaptop = media.laptop`
  background-position: 0 0;
`;

const WithBottomWaveOnLaptop = media.laptop`
  padding-bottom: ${({ isSectionPicture }: BackgroundProps) => (isSectionPicture ? '315px' : '225px')};
`;

const WithTopWaveOnLaptop = media.laptop`
  padding-top: 225px;
`;

const WhiteBgOnLaptop = media.laptop`
  padding: 75px 0;
`;

const WithTopBanner = media.laptop`
  padding: 40px 0;
`;

const WithSmallerBottomWaveOnLaptop = media.laptop`
  padding: 91px 0 121px 0;
`;

const SmallBottomWaveOnLaptop = media.laptop`
  height: 60px;
  background-image: url(${imagesLink}/small-bottom-wave.svg);
`;

const variants: { [key in BackgroundVariants]: ReturnType<typeof css> } = {
  'no-waves': css``,
  mac: css`
    ${BackgroundMacOnTablet};
  `,
  'different-devices': css`
    position: relative;

    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: -1;
      bottom: 0;
      background-image: linear-gradient(to left, #1e90c2 0%, #1dbbc3 100%);
      ${DifferentDevicesOnLaptop};
    }
  `,
  'vertical-text-video': css`
    background-image: linear-gradient(to right, #9de0ff 0%, #2192cd 100%);

    &:before,
    &:after {
      display: block;
      content: '';
      width: 100%;
      height: 75px;
      ${VerticalTextVideoOnLaptop};
    }

    &:before {
      background: url(${imagesLink}/wave_top.svg) no-repeat center top / cover;
    }

    &:after {
      background: url(${imagesLink}/wave_bottom_white.svg) no-repeat center bottom / cover;
    }
  `,
  'sign-up': css`
    background-image: linear-gradient(180deg, #0069a0 0%, #1192b4 50%, #71bbd0 100%);
    padding: 30px 0 10px 0;
    ${SignUpOnTablet};
  `,
  'text-on-video': css`
    background-image: linear-gradient(180deg, #0069a0 0%, #1192b4 49%, #71bbd0 100%);
    ${TextOnVideoOnLaptop};
  `,
  'horizontal-blue-gradient': css`
    background-image: linear-gradient(90deg, #1f84a9 0, #21c4eb);
  `,
  'horizontal-image-text': css`
    position: relative;
    background-image: linear-gradient(to right, #9de0ff 0%, #2192cd 100%);
    ${HorizontalImageTextOnLaptop};
    ${HorizontalImageTextOnDesktop};

    &:before {
      content: '';
      background: url(${imagesLink}/wave_top_revert.svg) repeat-x 0 transparent;
      background-size: cover;
      top: -2px;
      left: 0;
      position: absolute;
      height: 150px;
      width: 100%;
    }
  `,
  'success-stories': css`
    position: relative;

    &:after,
    &:before {
      display: block;
      content: '';
      width: 100%;
    }

    &:before {
      background-image: linear-gradient(90deg, #1f84a9 0, #21c4eb);
      opacity: 0.6;
      height: 100%;
      bottom: 0;
      left: 0;
      position: absolute;
    }

    &:after {
      background: url(${imagesLink}/wave_bottom_white.svg) repeat-x bottom transparent;
      position: relative;
      height: 150px;
    }
  `,
  'company-quote': css`
    background-color: #f7f8fa;
    position: relative;

    &:before {
      display: block;
      content: '';
      width: 100%;
      height: 150px;
      position: absolute;
      top: -150px;
      left: 0;
      background: url(${imagesLink}/gray-wave-white-bg.svg) repeat-x top transparent;
    }

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 150px;
      position: absolute;
      left: 0;
      z-index: 0;
      bottom: 0;
      background: url(${imagesLink}/wave_bottom_white.svg) repeat-x bottom transparent;
    }
  `,
  'with-bottom-wave': css`
    position: relative;
    padding-bottom: ${({ isSectionPicture }: BackgroundProps) => (isSectionPicture ? '175px' : '125px')};
    ${WithBottomWaveOnLaptop};

    &:after {
      display: block;
      content: '';
      width: 100%;
      height: 125px;
      background: url(${imagesLink}/white_wave_to_top.svg);
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      background-position-x: 47%;
      ${WaveOnLaptop};
    }
  `,
  'with-top-banner': css`
    ${WithTopBanner}
  `,

  'with-top-wave': css`
    position: relative;
    padding-top: 125px;
    ${WithTopWaveOnLaptop};

    &:after {
      display: block;
      content: '';
      width: 100%;
      height: 125px;
      background: url(${imagesLink}/white_wave_to_bottom.svg);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background-position-x: 47%;
      ${WaveOnLaptop};
    }
  `,
  'with-both-waves': css`
    position: relative;
    padding-top: 125px;
    padding-bottom: ${({ isSectionPicture }: BackgroundProps) => (isSectionPicture ? '175px' : '125px')};
    ${WithTopWaveOnLaptop};
    ${WithBottomWaveOnLaptop};

    &:after {
      display: block;
      content: '';
      width: 100%;
      height: 125px;
      background: url(${imagesLink}/white_wave_to_top.svg);
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      background-position-x: 47%;
      ${WaveOnLaptop};
    }

    &:before {
      display: block;
      content: '';
      width: 100%;
      height: 125px;
      background: url(${imagesLink}/white_wave_to_bottom.svg);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background-position-x: 47%;
      ${WaveOnLaptop};
    }
  `,
  'white-phone': css`
    max-width: 118px;
    box-shadow: 0 0 50px rgba(33, 34, 81, 0.15);
    border-radius: 15px;
    background-color: #ffffff;
    padding: 24px 6.1px 27px;
    transform: rotate(5deg);
    ${WhitePhoneOnLaptop};
  `,
  white: css`
    background: #fff;
    padding: 20px 0;
    ${WhiteBgOnLaptop};
  `,
  'with-smaller-bottom-wave': css`
    padding: 32px 0 0 0;
    ${WithSmallerBottomWaveOnLaptop};

    &:after {
      display: block;
      content: '';
      width: 100%;
      height: 20px;
      background-image: url(${imagesLink}/small-bottom-wave-mobile.svg);
      position: absolute;
      bottom: 0;
      left: 0;
      background-position-x: 100%;
      ${SmallBottomWaveOnLaptop};
    }
  `
};

const Background = styled.div<BackgroundProps>`
  ${({ variant }) => variants[variant]};

  ${({ imageBackground }) =>
    imageBackground &&
    css`
      background: url(${getFromCdn(imageBackground)}) no-repeat center / cover;
    `};

  ${({ withOutTopWave }) =>
    withOutTopWave &&
    css`
      ${SignUpWithOutTopWaveOnTablet};
    `};

  ${({ customBackground }) =>
    customBackground &&
    css`
      background: ${customBackground};
    `};
`;

export default Background;
