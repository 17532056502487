import { MediaObject } from '../../types';

const mapThumbnails = (thumbnail: MediaObject) => ({
  previewThumbnails: {
    enabled: true,
    src: `${thumbnail.path}`
  }
});

export default mapThumbnails;
