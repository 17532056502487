import ModalPortal from '@anm/components/modals/Modal/Portal';
import { FC, RefObject } from 'react';

import Wrapper, { ModalBody, ModalDialog, ModalOverlay } from './Wrapper';

export type ModalProps = {
  closeModal: () => void;
  isVisible?: boolean;
  modalRef?: RefObject<HTMLDivElement>;
  className?: string;
  modalWidth?: number | null;
  modalHeight?: number;
  isModalOpened?: boolean;
  isSidebarMenu?: boolean;
};

const Modal: FC<ModalProps> = ({
  modalRef,
  children,
  className,
  closeModal,
  modalWidth,
  modalHeight,
  isModalOpened,
  isVisible = true,
  isSidebarMenu = false
}) => (
  <ModalPortal>
    <Wrapper>
      <ModalOverlay onClick={() => closeModal()} />
      {!isSidebarMenu ? (
        <ModalDialog
          ref={modalRef}
          {...{ isVisible, className, modalWidth, modalHeight, isModalOpened }}
        >
          <ModalBody>{children}</ModalBody>
        </ModalDialog>
      ) : (
        <>{children}</>
      )}
    </Wrapper>
  </ModalPortal>
);

export default Modal;
