import { flexCenter, stretchBlock } from '@anm/styles/helpers';
import styled from 'styled-components';

import { ContentProps } from './types';

export const VideoOverlay = styled.div`
  ${flexCenter};
  ${stretchBlock};
  z-index: 1;
  color: #000;
  background-color: #292a2b;
`;

export const Content = styled.div<Required<ContentProps>>`
  ${flexCenter};
  z-index: 3;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  width: ${({ size }) => size}px;
  min-height: ${({ size }) => size}px;
  background: ${({ color }) => color};
  font-size: ${({ size }) => size / 2.3}px;
  img {
    height: ${({ size }) => size}px;
  }
`;
