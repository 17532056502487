import Container from '@anm/components/Container';
import PreviewList from '@anm/components/PreviewList';
import { FC } from 'react';
import styled from 'styled-components';

type ExampleItem = {
  link: string;
  title: string;
  preview: string;
};

export type ButtonExamplesListProps = {
  examples: ExampleItem[];
};

const ButtonExamplesSection = styled.section`
  margin-top: 11px;
  &:last-of-type {
    margin-bottom: 101px;
  }
`;

const ButtonExamplesList: FC<ButtonExamplesListProps> = ({ examples }) => (
  <ButtonExamplesSection>
    <Container size={1000}>
      <PreviewList list={examples} />
    </Container>
  </ButtonExamplesSection>
);

export default ButtonExamplesList;
