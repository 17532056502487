import LazyImage from '@anm/components/image/LazyImage';
import { Omit } from 'helpers';
import { FC } from 'react';

import { ListContentProps, ListItem as ListItemProps } from '../types';

import { IconContainer, Title, Wrapper } from './Wrapper';

type CardProps = Omit<ListContentProps, 'list'> & ListItemProps;

const ListItem: FC<CardProps> = ({
  title,
  iconUrl,
  background,
  linkUrl,
  description,
  headingLevel,
  borderRadius,
  ...cardProps
}) => (
  <Wrapper
    key={title}
    href={linkUrl}
    hasIcon={!!iconUrl}
    {...{ ...cardProps, background, borderRadius }}
  >
    {iconUrl && (
      <IconContainer>
        <LazyImage src={iconUrl} alt={title} />
      </IconContainer>
    )}
    <Title {...{ title, description }} color="black" level={headingLevel} />
  </Wrapper>
);

export default ListItem;
