import Image, { ImageProps } from '@anm/components/image/Image';
import { getSrc } from '@anm/helpers/image';
import useIntersection from '@anm/hooks/useIntersection';
import { useCallback, useState, FC } from 'react';

const placeHolder =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII=';

const LazyImage: FC<ImageProps> = ({ src, alt, ...restProps }) => {
  const [imageSrc, setImageSrc] = useState(placeHolder);

  const onObserveSuccess = useCallback(() => setImageSrc(src), [src]);

  const targetRef = useIntersection({
    onObserveSuccess,
    canObserve: imageSrc !== src
  });

  return <Image alt={alt} src={getSrc(imageSrc)} imageRef={targetRef} {...restProps} />;
};

export default LazyImage;
