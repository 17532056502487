import styled from 'styled-components';

import { media } from '../../../styles/helpers';
import { TitleWrapper } from '../../SectionTitle';

const titleStyles = media.laptop`
  ${TitleWrapper} {
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-size: 40px;
      line-height: 54px;
    }
  }
`;

const HorizontalListWrapper = styled.section<{ columns?: number }>`
  position: relative;
  overflow: hidden;
  text-align: center;
  padding-top: 50px;
  ${({ columns }) => columns && titleStyles}
`;

export default HorizontalListWrapper;
