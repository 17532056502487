import { Request } from '@anm/api';
import { getThrottleAdapter } from '@anm/api/helpers/cacheAdapters';
import { CommonStats } from 'user';

import {
  CreateFolderResponse,
  CreatePartUpload,
  CreateUploadRequest,
  CreateUploadResponse,
  DownloadStatus,
  FetchUploadRequest,
  FinishMultiPartUpload,
  GetFolderData,
  GetFolderPathReponse,
  GetUploadsCountResponse,
  MediaRequest,
  MoveFolderProps,
  MoveUploadProps,
  UpdateFolderParams,
  UpdateMediaParams,
  UploadElement,
  UploadFileRequest,
  UploadFolder,
  UploadMediaFile,
  UploadProjectsResponse
} from './types';

export * from './types';

export const getFolderData = (request: Request) => (params: GetFolderData) =>
  request<UploadElement[]>({
    url: 'v2/upload/folder/data/list',
    method: 'GET',
    params: {
      ...params,
      order: 'desc',
      path: params.path
    }
  });

const cacheAdapter = getThrottleAdapter(15 * 1000);

export const getUploadMedia = (request: Request) => ({ id, isCached }: FetchUploadRequest) =>
  request<UploadMediaFile>({
    url: `v2/media/${id}`,
    method: 'GET',
    adapter: isCached ? cacheAdapter : undefined
  });

export const updateFolderPath = (request: Request) => (data: UpdateFolderParams) =>
  request<{ id: string }>({
    url: 'v2/upload/folder',
    method: 'PUT',
    data
  });

export const updateMediaMeta = (request: Request) => ({ uploadId, name, duration, extra }: UpdateMediaParams) =>
  request<UploadFolder>({
    url: `v2/media/${uploadId}`,
    method: 'PUT',
    data: {
      meta: { name, duration },
      extra,
      isEmbedded: false
    }
  });

export const createUploadFolder = (request: Request) => (folders: string[]) =>
  request<CreateFolderResponse>({
    url: 'v2/upload/folder',
    method: 'POST',
    data: folders
  });

export const deleteFolder = (request: Request) => (folderPath: string) =>
  request<string>({
    url: 'v2/upload/folder',
    method: 'DELETE',
    params: {
      path: folderPath
    }
  });

export const createUpload = (request: Request) => ({
  nameSpace = 'upload',
  simpleUpload = false,
  deleteAfterUpload = false,
  createHost,
  ...data
}: CreateUploadRequest) =>
  request<CreateUploadResponse>({
    url: `v2/${nameSpace}`,
    method: 'POST',
    data,
    params: {
      createHost,
      ...(simpleUpload && { simpleUpload }),
      ...(deleteAfterUpload && { deleteAfterUpload })
    }
  });

// it will return upload url in request.url
// PUT chunk into this url
// it will return ETag in headers, save it like this: {partNumber, eTag}
export const createUploadUrlForPart = (request: Request) => ({ fileId, ...data }: CreatePartUpload) =>
  request<{ request: MediaRequest }>({
    url: `/v2/media/file/${fileId}/multipart-presigned-url`,
    method: 'POST',
    data
  });

export const completeMultipartUpload = (request: Request) => ({ fileId, ...data }: FinishMultiPartUpload) =>
  request<{ request: MediaRequest }>({
    url: `/v2/media/file/${fileId}/complete-multipart-upload`,
    method: 'POST',
    data
  });

export const uploadFile = (request: Request) => ({
  url,
  file,
  method,
  cancelToken,
  onUploadProgress
}: UploadFileRequest) =>
  request<void>({
    method,
    data: file,
    cancelToken,
    baseURL: url,
    onUploadProgress,
    onlyData: false
  });

export const getUserStorageStats = (request: Request) => () =>
  request<CommonStats>({
    url: 'profile/stats/storage',
    method: 'GET'
  });

export const deleteUploadFile = (request: Request) => (uploadId: string) =>
  request<{ id: string }>({
    method: 'DELETE',
    url: `v2/media/${uploadId}`
  });

export const getUploadProjects = (request: Request) => (uploadId: string) =>
  request<UploadProjectsResponse>({
    url: `uploads/projects`,
    method: 'GET',
    params: { uploadId }
  });

export const getUploadByProjectId = (request: Request) => (projectId: string) =>
  request<UploadMediaFile>({
    url: `v2/media/find`,
    method: 'GET',
    params: { projectId }
  });

export const moveUploadToFolder = (request: Request) => ({ uploadId, folderPath }: MoveUploadProps) =>
  request<{ id: string }>({
    url: `v2/media/${uploadId}/move`,
    method: 'POST',
    params: { folderPath }
  });

export const moveUploadFolderToFolder = (request: Request) => ({ newFolderPath, folderPath }: MoveFolderProps) =>
  request<{ id: string }>({
    url: `v2/upload/folder`,
    method: 'PUT',
    data: { newPath: newFolderPath, path: folderPath }
  });

export const getDownloadUrl = (request: Request) => (uploadId: string) =>
  request<string>({
    url: `streaming/download/${uploadId}`
  });

export const checkLiveDownloadStatus = (request: Request) => (uploadId: string) =>
  request<DownloadStatus>({
    url: `streaming/download/status/${uploadId}`
  });

export const generateDownload = (request: Request) => (uploadId: string) =>
  request<DownloadStatus>({
    method: 'POST',
    url: `streaming/download/${uploadId}`
  });

export const getUploadsCount = (request: Request) => () =>
  request<GetUploadsCountResponse>({
    url: `v2/upload/user/uploadsCount`,
    method: 'GET'
  });

export const getUploadFolderPath = (request: Request) => (folderId: string) =>
  request<GetFolderPathReponse>({
    url: `v2/upload/folder/${folderId}`,
    method: 'GET'
  });
