import Image from '@anm/components/image/Image';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;

export const Badge = styled.div`
  font-size: 12px;
  line-height: 15px;
  max-width: 60px;
  margin: 10px 10px 0 0;
  background: rgba(31, 51, 71, 0.5);
  border-radius: 3px;
  z-index: 3;
  font-weight: bold;
  color: #fff;
  text-align: center;
  padding: 10px 6px 6px;
  cursor: pointer;
`;

export const MuteIcon = styled(Image)`
  display: block;
  width: 18px;
  height: 16px;
  margin: 0 auto 4px;
`;
