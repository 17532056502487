import { forwardRef, useCallback, useEffect, useState, RefForwardingComponent } from 'react';

import DynamicHeight from './DynamicHeight';
import ExpandMenuItem from './ExpandMenuItem';
import IconArrow from './IconArrow';
import CollapseWrapper from './Wrapper';
import { CollapseProps } from './types';

export { DynamicHeight, IconArrow, ExpandMenuItem };

export type CollapseVariants =
  | 'right-down-arrow'
  | 'up-down-arrow'
  | 'plus-minus'
  | 'up-down-small-arrow'
  | 'arrow-with-title';

const Collapse: RefForwardingComponent<HTMLElement, CollapseProps> = (
  {
    variant,
    isOpened: isOpenedOutside = false,
    renderTarget,
    renderContent,
    onChange,
    itemProp,
    minHeight,
    isArrowHidden = false,
    isTargetClickable = true
  },
  ref: React.RefObject<HTMLDivElement>
) => {
  const [isOpened, toggleCollapse] = useState(isOpenedOutside);

  const handleToggleCollapse = useCallback(
    (value: boolean) => {
      toggleCollapse(value);
      onChange?.(value);
    },
    [onChange]
  );

  useEffect(() => {
    isOpenedOutside && handleToggleCollapse(isOpenedOutside);
  }, [isOpenedOutside]);

  const toggle = useCallback(() => {
    handleToggleCollapse(!isOpened);
  }, [isOpened]);

  const handleTargetClick = useCallback(() => {
    isTargetClickable && toggle();
  }, [isTargetClickable, isOpened]);

  const styleProps = { ...{ variant, isOpened, minHeight } };

  return (
    <CollapseWrapper ref={ref}>
      <ExpandMenuItem {...styleProps} {...(itemProp && { itemProp })} onClick={handleTargetClick}>
        {!isArrowHidden && <IconArrow {...styleProps} onClick={toggle} />}
        {renderTarget()}
      </ExpandMenuItem>
      <DynamicHeight {...styleProps}>{renderContent()}</DynamicHeight>
    </CollapseWrapper>
  );
};

export default forwardRef(Collapse);
