import { FC } from 'react';

import ListItemContent from './ListItemContent';
import { LazyImageWrapper, ListItem, ListItemTitle } from './Wrapper';
import { ListContentProps } from './types';

const imagaMap: { [key: string]: string } = {
  location: 'images/data/company-info/location_v1.svg',
  industry: 'images/data/company-info/industry.svg',
  use_cases: 'images/data/company-info/use_case_v1.svg',
  site: 'images/data/company-info/website_v1.svg',
  support: 'images/data/company-info/support.svg',
  contact: 'images/data/company-info/contact.svg',
  partner: 'images/data/company-info/partner.svg'
};

const ListContent: FC<ListContentProps> = ({ list }) => (
  <>
    {list.map(({ iconText, imageName, ...itemProps }) => (
      <ListItem key={iconText} className="list-item">
        <LazyImageWrapper src={imagaMap[imageName]} alt={iconText} />
        <ListItemTitle>{iconText}</ListItemTitle>
        <ListItemContent item={itemProps} />
      </ListItem>
    ))}
  </>
);

export default ListContent;
