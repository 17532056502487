import { FC } from 'react';
import Slider, { Settings } from 'react-slick';

import ListItem from '../List/ListItem';
import { ListContentProps } from '../types';

import { CarouselWrapper } from './Wrapper';

const SliderArrow = () => null;

const settings: Settings = {
  speed: 700,
  dots: true,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  focusOnSelect: false,
  centerMode: true,
  prevArrow: <SliderArrow />,
  nextArrow: <SliderArrow />
};

const Carousel: FC<ListContentProps> = ({ list, ...otherProps }) => (
  <CarouselWrapper>
    <Slider {...settings}>
      {list.map(slideProps => (
        <ListItem {...{ ...slideProps, ...otherProps }} />
      ))}
    </Slider>
  </CarouselWrapper>
);

export default Carousel;
