import styled from 'styled-components';

import { flexCenter, media } from '../../../styles/helpers';
import { LandingVideoWrapper } from '../../landing/LandingVideo';

import { centeredStyles } from './TopSectionTextWrapper';
import { videoConfigs } from './VideoTitle';

const TopSectionDataWrapper = styled.div<{ isCentered?: boolean }>`
  z-index: 1;
  width: 100%;
  display: flex;
  margin-top: 62px;
  position: relative;
  flex-direction: column;
  ${({ isCentered }) => isCentered && centeredStyles}
`;

const ButtonsContainerMobile = media.mobile`
  flex-direction: row;
${LandingVideoWrapper} {
    margin-left: 32px;
    margin-bottom: 0;
  }
`;

const ButtonsContainerLaptop = media.laptop<{ isCentered?: boolean }>`
  justify-content: flex-start;
  ${({ isCentered }) => isCentered && flexCenter};
`;

export const ButtonsContainer = styled.div<{ isCentered?: boolean }>`
  ${flexCenter};
  flex-direction: column-reverse;
  ${LandingVideoWrapper} {
    margin-bottom: 15px;
  }
  ${ButtonsContainerMobile};
  ${ButtonsContainerLaptop};
`;

export const ClippedContainer = styled.div<{ svgHeight?: number }>`
  width: 100%;
  position: relative;
  height: ${({ svgHeight }) => svgHeight || 300}px;
  clip-path: url(#svgTextPath);
  -ms-clip-path: url(#svgTextPath);
  -webkit-clip-path: url(#svgTextPath);
`;

export const VideoWrapper = styled.video.attrs(videoConfigs)<{
  showVideo: boolean;
}>`
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
  opacity: ${({ showVideo }) => (showVideo ? 1 : 0)};
  transition: all ease-in-out 0.5s;
  & + svg {
    width: 100%;
    height: 100%;
    text {
      font-size: 85px;
      font-weight: 900;
      text-anchor: middle;
    }
  }
`;

export default TopSectionDataWrapper;
