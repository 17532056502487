import { isArray, isFunction } from 'lodash/fp';
import React, { FC } from 'react';

export type ViewSwitcherProps = {
  active: number;
  children: (() => JSX.Element)[] | React.ReactNode | JSX.Element[];
};

const ViewSwitcher: FC<ViewSwitcherProps> = ({ active, children }) => {
  const currentView = isArray(children) ? children[active] : children;

  return <>{isFunction(currentView) ? currentView() : currentView}</>;
};

export default ViewSwitcher;
