import { UserContext } from '@anm/components/user/UserProvider';
import { useContext } from 'react';

const useSafeAction = (isLogged?: boolean) => {
  const { anon, isUserLogged: isLoggedInProvider } = useContext(UserContext);

  const isAuthorized = isLogged || isLoggedInProvider;

  const getSafeAction = (action: () => void) => () => (isAuthorized ? action() : anon.signupAndRun(action));

  return getSafeAction;
};

export default useSafeAction;
