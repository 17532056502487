import { FC } from 'react';

const currentYear = new Date().getFullYear();

const Copyright: FC = () => (
  <span>
    © 2011-{currentYear} Animatron Inc. - Wave.video<sup>SM</sup> (wavevideo) -
    All rights reserved.
  </span>
);

export default Copyright;
