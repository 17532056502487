import TitleBlock from '@anm/components/TitleBlock';
import Image from '@anm/components/image/Image';
import getFromCdn from '@anm/helpers/getFromCdn';
import { fadeInAnimation, flexCenter } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

import { NotificationBlockProps } from '.';
import { ANIMATION_TIME } from './hooks';

export type ContentColor = 'red' | 'green' | 'blue' | 'yellow';
type ContentProps = Partial<NotificationBlockProps> & { color?: ContentColor };

const getContentStyles = ({ variant, type }: ContentProps) =>
  ({
    aside: css`
      width: 320px;
      border-radius: 5px;
      padding: 16px 46px 16px 61px;
    `,
    highlight: css`
      width: 347px;
      margin: 0 20px 8px;
      border-radius: 5px;
      padding: 16px 46px 16px 61px;
      max-width: calc(100vw - 40px);
    `,
    custom: css`
      display: flex;
      width: 285px;
      transition: 0.3s;
      border-radius: 10px;
      padding: ${type === 'error' ? '20px 20px 20px 62px' : '20px'};
      ${fadeInAnimation};
    `
  }[variant || 'aside']);

export const Wrapper = styled.div<Partial<NotificationBlockProps>>`
  pointer-events: none;
  ${({ variant }) =>
    variant === 'aside' &&
    css`
      margin-right: 20px;
      margin-bottom: 20px;
    `}
`;

const contentColors: { [key in ContentColor]: ReturnType<typeof css> } = {
  red: css`
    background: var(--error_bkg);;
    border: 1px solid var(--error);
  `,
  blue: css`
    background: #d9f2ff;
    border: 1px solid #30aef2;
  `,
  green: css`
    background: var(--green_bkb);
    border: 1px solid #0c9744;
  `,
  yellow: css`
    background: #fff4db;
    border: 1px solid #fcbd26;
  `
};

export const Content = styled.div<ContentProps>`
  position: relative;
  pointer-events: all;
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(1, 83, 130, 0.2);

  ${props => getContentStyles(props)}
  ${({ color }) => color && contentColors[color]}
`;

const progressIconStyles = css`
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  animation: spin 1s infinite linear;
`;

export const NotificationIcon = styled(Image)<Partial<NotificationBlockProps>>`
  top: 16px;
  left: 16px;
  max-width: 30px;
  max-height: 30px;
  position: absolute;
  ${({ type }) => type === 'progress' && progressIconStyles};
`;

export const NotificationTitle = styled(TitleBlock)`
  min-height: 30px;
  ${flexCenter};
  justify-content: flex-start;
  flex: 1;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #292a2b;
    line-height: 22px;
  }
  a {
    color: var(--blue_200);
    font-size: 14px;
    line-height: 22px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;
export const NotificationDescription = styled.p`
  margin: 4px 0 0;
  font-size: 14px;
  color: #292a2b;
  line-height: 22px;
  font-style: normal;
  font-weight: normal;
  overflow-wrap: break-word;
  letter-spacing: 0.01em;

  a {
    color: var(--blue_200);
  }
`;

export const ButtonClose = styled(Image)`
  position: absolute;
  cursor: pointer;
  right: 16px;
  top: 16px;
`;

export const slideInAnimation = css`
  animation-name: slideIn;
  @keyframes slideIn {
    0% {
      transform: translateX(300px);
    }
    100% {
      transform: translateX(0);
    }
  }
`;

export const slideOutAnimation = css`
  animation-name: slideOut;
  @keyframes slideOut {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(350px);
    }
  }
`;

export const SlideWrapper = styled.div<{ canShow: boolean }>`
  ${({ canShow }) => (canShow ? slideInAnimation : slideOutAnimation)};
  animation-duration: ${ANIMATION_TIME}ms;
  animation-fill-mode: forwards;
`;

export const CustomSaveText = styled.div`
  width: 225px;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #292a2b;
  padding-left: 77px;

  &:before {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    content: '';
    background: url(${getFromCdn('images/icons/notification/success.svg')});
  }
`;

export default Wrapper;
