import styled, { css } from 'styled-components';

import { media } from '../../styles/helpers';

export type AnimatedVariants =
  | 'left-appear'
  | 'right-appear'
  | 'default'
  | 'disabled';

export type AnimatedProps = {
  variant: AnimatedVariants;
};

interface AnimatedWrapperProps {
  isShadow?: boolean;
}

const variants: { [key in AnimatedVariants]: ReturnType<typeof css> } = {
  'left-appear': css`
    will-change: opacity, transform;
    transition: transform 1s cubic-bezier(0.25, 0.1, 0.25, 1),
      opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    transform: translate3d(-60px, 0, 0);
  `,
  'right-appear': css`
    will-change: opacity, transform;
    transition: transform 1s cubic-bezier(0.25, 0.1, 0.25, 1),
      opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    transform: translate3d(60px, 0, 0);
  `,
  default: css`
    transform: translateY(50px);
    opacity: 0.2;
  `,
  disabled: css``
};

const AnimatedWrapperOnDesktop = media.desktop<AnimatedProps>`
    ${({ variant }) => variants[variant]};
`;

const AnimatedWrapper = styled.div<AnimatedProps & AnimatedWrapperProps>`
  &.active {
    transition: opacity 1000ms cubic-bezier(0.25, 0.1, 0.25, 1),
      transform 1000ms cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 1;
    transform: none;
  }

  ${({ isShadow }: AnimatedWrapperProps) =>
    isShadow &&
    css`
      box-shadow: 0 5px 50px rgba(4, 25, 79, 0.25);
    `};

  ${AnimatedWrapperOnDesktop};
`;

export default AnimatedWrapper;
