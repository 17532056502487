import { FC } from 'react';

import { ImageMedia } from '../Wrapper';
import { MediaProps } from './index';

const ImageSideMedia: FC<MediaProps> = ({ imageSideMediaUrl, alt, title }) => (
  <ImageMedia src={imageSideMediaUrl!} alt={alt || title} />
);

export default ImageSideMedia;
