import LazyImage from '@anm/components/image/LazyImage';
import { Omit } from 'helpers';
import { FC } from 'react';

import { ListContentProps, ListItem as ListItemProps } from '../types';

import { CardTitle, CardWrapper, ImageContainer } from './Wrapper';

type CardProps = Omit<ListContentProps, 'list'> & ListItemProps;

const ListItem: FC<CardProps> = ({
  title,
  imageUrl,
  cardUrl,
  description,
  headingLevel,
  ...cardProps
}) => (
  <CardWrapper href={cardUrl} key={title} {...cardProps}>
    {imageUrl && (
      <ImageContainer>
        <LazyImage src={imageUrl} alt={title} />
      </ImageContainer>
    )}
    <CardTitle {...{ title, description }} color="black" level={headingLevel} />
  </CardWrapper>
);

export default ListItem;
