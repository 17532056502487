import Button from '@anm/components/buttons/Button';
import { getSrc } from '@anm/helpers/image';
import { flexCenter } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

import { IconButtonProps } from '.';

type IconSizes = { [key: string]: string };

const iconSizes: IconSizes = {
  small: '22px',
  medium: '54px',
  big: '100px',
  '18x18': '18px',
  '20x20': '20px',
  '24x24': '24px',
  '32x32': '32px'
};

const getWithIconCss = (icon: string, iconSize = 'small', withIconDirection = 'horizontal', disabled?: boolean) => {
  const size = iconSizes[iconSize];
  const margin = withIconDirection === 'vertical' ? '0 0 20px 0' : '0 8px 0 0';
  const direction = withIconDirection === 'vertical' ? 'column' : 'row';

  const iconElement =
    !!icon &&
    css`
      &:before {
        content: '';
        flex-shrink: 0;
        width: ${size};
        height: ${size};
        display: block;
        margin: ${margin};
        opacity: ${disabled ? 0 : 1};
        background-image: url(${getSrc(icon)});
        background-repeat: no-repeat;
        background-position: center;
      }
    `;

  return css`
    ${flexCenter};
    flex-direction: ${direction};
    ${iconElement}
  `;
};

const IconButton = styled(Button)<IconButtonProps>`
  ${({ icon, iconSize, withIconDirection, disabled }) =>
    icon && getWithIconCss(icon, iconSize, withIconDirection, disabled)};
`;

export default IconButton;
