import getFromCdn from '@anm/helpers/getFromCdn';
import isMobile from '@anm/helpers/is/isMobile';
import isServer from '@anm/helpers/is/isServer';
import useIsMountedCheck from '@anm/hooks/useIsMountedCheck';
import { useEffect, useRef, useState, FC } from 'react';

import { ClippedContainer, VideoWrapper } from './TopSectionDataWrapper';
import { HiddenTitle } from './TopSectionTitle';

const DEFAULT_SCALE_RATIO = 1;
const MOBILE_WIDTH_RATIO = 0.9;

export const videoConfigs = {
  playsInline: true,
  muted: true,
  loop: true,
  autoPlay: true
};

type VideoTitleProps = {
  title: string;
  videoPath: string;
  lineHeight?: number;
};

const VideoTitle: FC<VideoTitleProps> = ({
  title,
  videoPath,
  lineHeight = 90
}) => {
  const lines = title.split('<br/>');
  const [svgHeight, setSvgHeight] = useState<number>();
  const svgRef = useRef<SVGSVGElement>(null);
  const [svgScale, setSvgScale] = useState<number>(DEFAULT_SCALE_RATIO);
  const isMounted = useIsMountedCheck();

  useEffect(() => {
    const height = (lines.length + 0.5) * lineHeight * svgScale;
    setSvgHeight(height);
  }, [svgScale]);

  const getTransform = () => {
    if (isServer()) return;

    const svgWidth = svgRef.current?.clientWidth!;

    const newScale = svgWidth / window.innerHeight;

    const canUpdateScale =
      svgScale === DEFAULT_SCALE_RATIO &&
      newScale <= DEFAULT_SCALE_RATIO &&
      isMobile();

    canUpdateScale && setSvgScale(newScale * MOBILE_WIDTH_RATIO);

    return `scale(${svgScale})`;
  };

  return (
    <ClippedContainer {...{ svgHeight }}>
      <HiddenTitle color="white" {...{ title }} />
      <VideoWrapper
        preload="metadata"
        showVideo={isMounted}
        poster={getFromCdn('images/data/video_preview.jpg')}
      >
        <source src={getFromCdn(videoPath)} type="video/mp4" />
      </VideoWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" ref={svgRef}>
        <clipPath id="svgTextPath">
          {lines.map((item, i) => (
            <text
              key={item}
              x={`${50 / svgScale}%`}
              y={(i + 1) * lineHeight}
              transform={getTransform()}
            >
              {item}
            </text>
          ))}
        </clipPath>
      </svg>
    </ClippedContainer>
  );
};

export default VideoTitle;
