import noop from '@anm/helpers/noop';

class PlayControl {
  pauseCount = 0;
  handlePlay = noop;
  windowOnFocusHandler = noop;
  windowOnBlurHandler = noop;

  unsubscribe = () => {
    window.removeEventListener('focus', this.windowOnFocusHandler, false);
    window.removeEventListener('blur', this.windowOnBlurHandler, false);
  };

  subscribe(handlePlay: (canPlay: boolean) => void) {
    this.handlePlay = handlePlay;
    this.windowControl();
    return this.unsubscribe;
  }

  windowControl() {
    this.windowOnFocusHandler = this.getPlayHandler(true);
    this.windowOnBlurHandler = this.getPlayHandler(false);

    window.addEventListener('focus', this.windowOnFocusHandler, false);
    window.addEventListener('blur', this.windowOnBlurHandler, false);
  }

  getPlayHandler(isPlaying: boolean) {
    return () => {
      this.pauseCount = isPlaying
        ? Math.max(0, this.pauseCount - 1)
        : this.pauseCount + 1;

      this.handlePlay(this.pauseCount === 0);
    };
  }
}

export default PlayControl;
