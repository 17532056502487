import ProgressBar from '@anm/components/ProgressBar';
import Image from '@anm/components/image/Image';
import { FC } from 'react';

import { SectionTitleProps } from '../../../SectionTitle';

import { BodyRow, BodyTitle, Cell, ProgressBase } from './Wrapper';
import { getProgressColor } from './helpers';

const MaxMarkValue = 5;

type ListItem = SectionTitleProps & {
  firstColumnValue: boolean;
  secondColumnValue: boolean | string;
};

export type BodyProps = {
  list: ListItem[];
};

type StatusCellProps = { value: string | boolean | number };

const FirstCell: FC<SectionTitleProps> = props => (
  <Cell isFirstCell>
    <BodyTitle {...props} color="black" level={4} />
  </Cell>
);

const StatusCell: FC<StatusCellProps> = ({ value }) => {
  const isImage = typeof value === 'boolean';
  const isProgrssBar = typeof value === 'number';
  const src = value ? 'tick_ic.svg' : 'cross_ic.svg';

  const progressValue = ((value as number) / MaxMarkValue) * 100;

  return (
    <Cell color={getProgressColor(value as number)}>
      {isImage ? (
        <Image src={`images/icons/${src}`} alt={src} />
      ) : isProgrssBar ? (
        <ProgressBar variant="radial" value={progressValue}>
          <p>{(value as number).toFixed(1)}</p>
          <ProgressBase />
        </ProgressBar>
      ) : (
        <span dangerouslySetInnerHTML={{ __html: value as string }} />
      )}
    </Cell>
  );
};

const TableHeader: FC<BodyProps> = ({ list }) => (
  <>
    {list.map(({ firstColumnValue, secondColumnValue, ...titleProps }) => (
      <BodyRow key={titleProps.title}>
        <FirstCell {...titleProps} />
        <StatusCell value={firstColumnValue} />
        <StatusCell value={secondColumnValue} />
      </BodyRow>
    ))}
  </>
);

export default TableHeader;
