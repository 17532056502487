type RedirectProps = {
  link: string;
  domain: string;
  isUserLogged: boolean;
  isEncodeLink?: boolean;
};

const useSignupRedirect = ({ link, domain, isUserLogged, isEncodeLink = true }: RedirectProps) => {
  const redirectLink = isEncodeLink ? encodeURIComponent(link) : link;
  const signupUrl = `${domain}signup?redirectUrl=${redirectLink}`;

  return isUserLogged ? link : signupUrl;
};
export default useSignupRedirect;
