import styled, { css } from 'styled-components';

const disabledInputStyle = css`
  background-color: #f5f7fa;
`;

const TextInputWrapper = styled.div<{ isDisabled: boolean }>`
  display: block;
  position: relative;
  input {
    margin-bottom: 10px;
    ${({ isDisabled }) => isDisabled && disabledInputStyle}
  }
`;

export const Optional = styled.div`
  position: absolute;
  bottom: calc(100% + 10px);
  right: 1px;
  font-size: 12px;
  text-align: right;
  color: #8b9499;
`;

export default TextInputWrapper;
