import * as analytic from '@anm/analytic';
import { JobIdFromSourceParams, JobIdFromTextParams } from '@anm/api/modules/storyboard';

import useSwitchState from '@anm/hooks/useSwitchState';
import { useCallback, useEffect, useState } from 'react';
import useSafeAction from '@anm/auth/hooks/useSafeAction';
import useGetFormatUrl, { EditorTarget, ProjectKind } from '@anm/hooks/useGetFormatUrl';
import {
  UseGenerateVideoFromLinkProps,
  UseGenerateVideoFromTextProps,
  UseOpenFormats,
  UseProjectActionsHandlersProps
} from './types';

const useOpenFormats = ({ jobId, onOpenFormats }: UseOpenFormats) => {
  useEffect(() => {
    jobId && onOpenFormats();
  }, [jobId]);
};

export const useTemplateModal = (onClose?: () => void) => {
  const [isFormatStyleModal, openFormatStyleModal, closeFormatStyleModal] = useSwitchState();

  const handleCloseTemplateModal = useCallback(() => {
    closeFormatStyleModal();
    onClose?.();
  }, []);

  return [isFormatStyleModal, openFormatStyleModal, handleCloseTemplateModal] as const;
};

export const useGenerateVideoFromText = ({
  jobId,
  isUserLogged,
  openFormatsModal,
  onCreateVideoFromText
}: UseGenerateVideoFromTextProps) => {
  const getSafeAction = useSafeAction(isUserLogged);

  const [
    isOpenGenerateVideoFromTextModal,
    openGenerateVideoFromTextModal,
    closeGenerateVideoFromTextModal
  ] = useSwitchState();

  const handleOpenFormats = useCallback(() => {
    openFormatsModal();
    closeGenerateVideoFromTextModal();
  }, []);

  useOpenFormats({ jobId, onOpenFormats: handleOpenFormats });

  const handleCreateVideoFromText = useCallback((params: JobIdFromTextParams) => {
    onCreateVideoFromText(params);
  }, []);

  const openGenerateFromText = getSafeAction(() => {
    analytic.trackCreateVideoFromTextChoosen();
    openGenerateVideoFromTextModal();
  });

  return [
    isOpenGenerateVideoFromTextModal,
    handleCreateVideoFromText,
    openGenerateFromText,
    closeGenerateVideoFromTextModal
  ] as const;
};

export const useGenerateVideoFromLink = ({
  jobId,
  isUserLogged,
  openFormatsModal,
  onCreateVideoFromLink
}: UseGenerateVideoFromLinkProps) => {
  const getSafeAction = useSafeAction(isUserLogged);

  const [
    isOpenGenerateVideoFromLinkModal,
    openGenerateVideoFromLinkModal,
    closeGenerateVideoFromLinkModal
  ] = useSwitchState();

  const handleOpenFormats = useCallback(() => {
    openFormatsModal();
    closeGenerateVideoFromLinkModal();
  }, []);

  useOpenFormats({ jobId, onOpenFormats: handleOpenFormats });

  const handleCreatVideoFromLink = useCallback((params: JobIdFromSourceParams) => {
    onCreateVideoFromLink(params);
  }, []);

  const openGenerateFromLink = getSafeAction(() => {
    analytic.trackCreateVideoFromBlogChoosen();
    openGenerateVideoFromLinkModal();
  });

  return [
    isOpenGenerateVideoFromLinkModal,
    handleCreatVideoFromLink,
    openGenerateFromLink,
    closeGenerateVideoFromLinkModal
  ] as const;
};

export const useProjectActionsHandlers = ({
  jobId,
  waveUrl,
  editorUrl,
  currentFolderId,
  isUserLogged
}: UseProjectActionsHandlersProps) => {
  const [isOpenFormatsModal, openFormatsModal, closeFormatsModal] = useSwitchState();
  const [editorTarget, setEditorTarget] = useState<EditorTarget>('edit');
  const [projectKind, setProjectKind] = useState<ProjectKind>('video');

  // const formatModalLinkTarget = jobId ? '_self' : '_blank';
  const formatModalLinkTarget = '_self';
  const getFormatUrl = useGetFormatUrl({
    isUserLogged,
    waveUrl,
    editorUrl,
    currentFolderId,
    editorTarget,
    projectKind,
    newParam: jobId
  });

  const getSafeAction = useSafeAction(isUserLogged);

  const handleStocksOpen = useCallback(
    getSafeAction(() => {
      analytic.trackCreateVideoStocksChoosen();
      setEditorTarget('stock-library');
      openFormatsModal();
    }),
    [getSafeAction]
  );

  const handleBlankVideo = useCallback(
    getSafeAction(() => {
      analytic.trackCreateVideoBlankChoosen();
      setEditorTarget('edit');
      setProjectKind('video');
      openFormatsModal();
    }),
    [getSafeAction]
  );

  const handleBlankImage = useCallback(
    getSafeAction(() => {
      analytic.trackCreateImageBlankChoosen();
      setEditorTarget('edit');
      setProjectKind('image');
      openFormatsModal();
    }),
    [getSafeAction]
  );

  const handleFormatModalClose = useCallback(() => {
    closeFormatsModal();
  }, []);

  return [
    isOpenFormatsModal,
    formatModalLinkTarget,
    getFormatUrl,
    handleStocksOpen,
    handleBlankVideo,
    handleBlankImage,
    handleFormatModalClose
  ] as const;
};
