import isMobileOrIPad from '@anm/helpers/is/isMobileOrIPad';

import { GridProps, VideoProps } from '../types';

export const generateColumns = ({ list, columns = 4 }: GridProps) => {
  const responsiveColumns = isMobileOrIPad() ? 2 : columns;

  return list?.reduce((acc, item, index) => {
    const currentIndex = index % responsiveColumns;
    const temp = acc as VideoProps[][];
    if (!temp[currentIndex]) temp[currentIndex] = [];

    temp[currentIndex].push(item);
    return temp;
  }, []);
};
