import { FC } from 'react';

import Background from '../../../Background';
import WavePlayerModal from '../../../WavePlayerModal';

type VideoModalProps = {
  videoId: string;
  isMobileFrame?: boolean;
};

const VideoModal: FC<VideoModalProps> = ({ videoId, isMobileFrame }) => (
  <Background variant={isMobileFrame ? 'white-phone' : 'no-waves'}>
    <WavePlayerModal
      {...{ videoId }}
      playerIconSize="80x80"
      playIconVariant="white"
    />
  </Background>
);

export default VideoModal;
