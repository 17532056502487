import { LinkContext } from '@anm/contexts/LinkContext';
import useHover from '@anm/hooks/useHover';
import { useContext, FC, ReactNode } from 'react';

import PreviewContent from './Content';
import PreviewTitleCardWrapper, { CardWrapper, TitleCard } from './Wrapper';
import { PreviewTitleCardProps, WrapperProps } from './types';

export * from './types';
export { PreviewTitleCardWrapper, CardWrapper, TitleCard };

const PreviewWrapper: FC<WrapperProps> = ({
  link,
  children,
  isLink = false,
  renderLink = (_?: ReactNode) => <a>{children}</a>
}) => {
  const { Link } = useContext(LinkContext);

  if (isLink && link) {
    return (
      <Link {...link} scroll={false}>
        <CardWrapper>{children}</CardWrapper>
      </Link>
    );
  }

  return renderLink(children);
};

const PreviewTitleCard: FC<PreviewTitleCardProps> = ({
  title,
  weight,
  preview,
  videoUrl,
  children,
  renderLink,
  alt = title,
  ...otherProps
}) => {
  const [hoverRef, isHover] = useHover<HTMLDivElement>();

  return (
    <>
      <PreviewTitleCardWrapper isPadding={false} cardRef={hoverRef}>
        <PreviewWrapper {...{ renderLink, ...otherProps }}>
          <PreviewContent {...{ alt, isHover, preview, videoUrl, ...otherProps }} />
          {title && <TitleCard {...{ weight }}>{title}</TitleCard>}
        </PreviewWrapper>
      </PreviewTitleCardWrapper>
      {children}
    </>
  );
};

export default PreviewTitleCard;
