import api from '@anm/api';
import { UploadsApi } from '@anm/shared/api/uploads';

let apiInstance: UploadsApi;

export const uploadsApi = () => {
  if (!apiInstance) {
    apiInstance = api().uploads;
  }
  return apiInstance;
};

export const setUploadsApi = (api: UploadsApi) => {
  apiInstance = api;
};
