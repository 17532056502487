import Dropdown, { DropdownContent, DropdownWrapper } from '@anm/components/Dropdown';
import Button from '@anm/components/buttons/Button';
import { HEADER_HEIGHT } from '@anm/styles/constants';
import { flexCenter, media, mobileMenuItem } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

import { NavigationVariants, NavigationWrapperProps } from './types';

const getNavButtonsStyles = (color: string, hoverColor: string) => css`
  ${DropdownWrapper} {
    &:hover {
      & > a,
      & > button,
      & > button * {
        color: ${hoverColor};
      }
    }
  }
  ${DropdownContent} a {
    color: #292a2b;
  }
  ${DropdownContent}:hover {
    button {
      color: ${hoverColor};
    }
    a {
      &:hover {
        color: var(--blue_100);
      }
    }
  }
  nav {
    a,
    button {
      color: ${color};
      font-weight: normal;
      &:hover {
        color: ${hoverColor};
      }
    }
  }
  ul > li > ${DropdownContent} > a {
    color: ${color};
    &:hover {
      color: ${hoverColor}!important;
    }
  }
`;

const getVariant = (variant: NavigationVariants = 'blue', isScrolledOut: boolean): ReturnType<typeof css> =>
  ({
    pink: css`
      background: #fff;
      ${getNavButtonsStyles('#292a2b', '#2294df')}
    `,
    'blue-light': css`
      background-color: #e7fcff;
      ${getNavButtonsStyles('#292a2b', 'var(--blue_100)')};
    `,
    blue: css`
      background-image: linear-gradient(to right, #1e83a8 0%, #20c4ea 100%);
      ${getNavButtonsStyles(isScrolledOut ? '#292a2b' : '#fff', isScrolledOut ? 'var(--blue_100)' : '#aff5fe')};
    `,
    grey: css`
      background: #f4f6f7;
      ${getNavButtonsStyles('#292a2b', 'var(--blue_100)')};
    `,
    white: css`
      background: #fff;
      ${getNavButtonsStyles('#292a2b', 'var(--blue_100)')};
    `,
    'white-hosting': css`
      background: #fff;
      ${getNavButtonsStyles('#292a2b', 'var(--blue_100)')};
    `,
    live: css`
      background: #fff;
      ${getNavButtonsStyles('#292a2b', 'var(--blue_100)')};
    `,
    lives: css`
      background: #fff;
      ${getNavButtonsStyles('#292a2b', 'var(--blue_100)')};
    `,
    transparent: css`
      background-color: transparent;
      ${getNavButtonsStyles(isScrolledOut ? '#292a2b' : '#fff', isScrolledOut ? 'var(--blue_100)' : '#aff5fe')};
    `
  }[variant]);

export const WrapperButtonLink = styled(Button)`
  color: #292a2b;
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
`;

const NavigationWrapperAdaptiveStyles = media.tablet`
  padding: 15px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const headerNavAdaptiveStyles = media.tablet`
  top: 0;
  right: 0;
  width: 80%;
  z-index: 101;
  display: flex;
  height: 100vh;
  padding: 20px;
  position: absolute;
  align-items: flex-start;
  background-color: #292a2b;
  flex-direction: column-reverse;
`;

const headerNavUlAdaptiveStyles = media.tablet`
  margin-left: 0;
  margin-top: 24px;
  flex-direction: column;
`;

const headerNavUlLiAdaptiveStyles = media.tablet`
  justify-content: flex-start;
`;

const headerNavUlLiAAdaptiveStyles = media.tablet`
  color: #ffffff;
  line-height: 45px;
`;

const MobileMenuButtonWrapperAdaptiveStyles = css`
  @media (max-width: 1200px) {
    display: block;
  }
`;

export const MobileMenuButtonWrapper = styled.div`
  display: none;
  ${MobileMenuButtonWrapperAdaptiveStyles};
`;

const DesktopNavigationAdaptiveStyles = css`
  @media (max-width: 1200px) {
    display: none !important;
  }
`;

export const DesktopNavigation = styled.div<{ isNoLinks?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ isNoLinks }) =>
    !isNoLinks &&
    css`
      flex: 1;
    `}

  ${DesktopNavigationAdaptiveStyles};
`;

const shadowStyles = css`
  background: #fff;
  box-shadow: 0 2px 10px rgba(1, 83, 130, 0.1);
`;

const headerNavSmallDesktopStyles = css`
  @media (min-width: 1200px) and (max-width: 1350px) {
    margin-left: 20px;
    > li {
      margin-right: 20px !important;
    }
  }
`;

const linkLightColors = ['white', 'blue-light', 'pink'];
const lightBlueLinks = ['blue', 'transparent'];

const dropdownOpenerStyles = css<NavigationWrapperProps>`
  ${DropdownWrapper}:after {
    ${({ variant, isScrolled }) =>
      !linkLightColors.includes(variant) &&
      !isScrolled &&
      css`
        filter: brightness(1000%);
      `}
  }
  nav > ul > li {
    ${DropdownWrapper}:hover:after {
      ${({ variant, isScrolled }) =>
        lightBlueLinks.includes(variant) &&
        !isScrolled &&
        css`
          filter: invert(48%) sepia(36%) saturate(760%) hue-rotate(164deg) brightness(281%) contrast(98%);
        `}
    }
  }
`;

const noLogoStyles = css`
  margin-left: 65px;
`;

export const NavigationWrapper = styled.div<NavigationWrapperProps>`
  top: 0;
  left: 0;
  right: 0;
  z-index: 111;
  display: flex;
  transition: 0.3s;
  align-items: stretch;
  max-height: ${HEADER_HEIGHT};
  justify-content: space-between;
  position: ${({ isFixed }) => (isFixed ? 'fixed' : 'sticky')};
  padding: ${(+HEADER_HEIGHT.replace('px', '') - 45) / 2}px 40px;
  ${NavigationWrapperAdaptiveStyles};

  nav {
    flex: 1;
    display: flex;
    align-items: center;
    ${headerNavAdaptiveStyles};
    ${({ isNoLogo }) => isNoLogo && noLogoStyles};
    > ul {
      display: flex;
      margin-left: 68px;
      ${headerNavUlAdaptiveStyles};
      ${headerNavSmallDesktopStyles}
      > li {
        ${flexCenter};
        margin-right: 40px;
        justify-content: flex-start;
        ${headerNavUlLiAdaptiveStyles};
      }
    }
    a,
    button {
      ${flexCenter};
      height: 100%;
      font-size: 16px;
      cursor: pointer;
      font-weight: 400;
      line-height: 45px;
      text-decoration: none;
      justify-content: flex-start;
      ${headerNavUlLiAAdaptiveStyles};
    }
    ${DropdownWrapper} {
      align-items: baseline;
    }
  }

  ${({ variant, isScrolled }) => getVariant(variant, !!isScrolled)};
  ${({ isScrolled, hasShadow }) => (isScrolled || hasShadow) && shadowStyles};
  ${dropdownOpenerStyles};
`;

export const MobileUserMenuLinkList = styled.div`
  li {
    border-bottom: solid 1px rgba(203, 220, 229, 0.5);
    margin: 0 20px;
    > a,
    span {
      ${mobileMenuItem};
    }
  }
`;

export const UserMenuDropdown = styled(Dropdown)`
  a {
    transition: none;
    &:hover {
      color: ${({ theme }) => theme.textColor};
      background: rgba(32, 171, 210, 0.1);
    }
  }
  &:after,
  &:before {
    left: auto;
    right: 27px;
  }
`;

export const UserMenuHeader = styled.li`
  font-size: 16px;
  color: #292a2b;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 6px;
  padding: 15px 30px;
  background: #fff;
  white-space: nowrap;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #ccdde6;
  cursor: default;
  > div {
    overflow: hidden;
    max-width: 300px;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:last-of-type {
      color: #a0a0a7;
      font-size: 14px;
      line-height: 32px;
    }
  }
`;

export const MenuDivider = styled.li`
  height: 1px;
  margin: 5px 0;
  background: #ccdde6;
`;
