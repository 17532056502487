import { ContainerSize } from '@anm/components/Container';
import { FC } from 'react';

import { CommonLandingProps } from '../../../features/getLanding';
import LandingButton, { LandingButtonProps } from '../../LandingButton';
import { SectionTitleProps } from '../../SectionTitle';

import Table from './Table';
import { BodyProps } from './Table/Body';
import { TableHeaderProps } from './Table/Header';
import Wrapper, { ContainerWrapper, TitleWrapper } from './Wrapper';

export type TableComparisonProps = CommonLandingProps &
  ContainerSize &
  SectionTitleProps &
  TableHeaderProps &
  BodyProps & {
    topButton?: LandingButtonProps;
    bottomButton?: LandingButtonProps;
    isDescriptionUnderTable?: boolean;
  };

const TableCompare: FC<TableComparisonProps> = ({
  size,
  title,
  topButton,
  description,
  bottomButton,
  headingLevel,
  isDescriptionUnderTable,
  ...tableProps
}) => (
  <Wrapper>
    <ContainerWrapper size={size || 1000}>
      <TitleWrapper
        color="black"
        level={headingLevel}
        {...{ title, ...(!isDescriptionUnderTable && { description }) }}
      />
      <LandingButton variant="light-blue-lowercase" size="upper-big" {...topButton} />
      <Table {...tableProps} />
      {isDescriptionUnderTable && <TitleWrapper {...{ description }} color="black" level={2} />}
      <LandingButton size="upper-big" variant="light-blue-lowercase" {...bottomButton} />
    </ContainerWrapper>
  </Wrapper>
);

export default TableCompare;
