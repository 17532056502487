import { StockItem } from '@anm/api/modules/stocks';
import { ENTER_NAME } from '@anm/constants/keyCodes';
import { toUrlParams } from '@anm/helpers/parseParams';
import { b64EncodeUnicode } from '@anm/helpers/string/base64';
import useSignupRedirect from '@anm/hooks/useSignupRedirect';
import useSwitchState from '@anm/hooks/useSwitchState';
import axios from 'axios';
import { useCallback, useEffect, useState, ChangeEvent, KeyboardEvent } from 'react';

import lpConfig from '../../../config';

import { StockFetchProps } from './types';

const { apiUrl, editorUrl, waveUrl } = lpConfig;

export const useTemplateFromStockUrl = (selectedStock: StockItem, isUserLogged: boolean) => {
  const strStock = JSON.stringify(selectedStock);

  const params = {
    new: b64EncodeUnicode(strStock)
  };
  const urlParams = toUrlParams(params, false);

  const stockUrl = `${editorUrl}/#${urlParams}`;
  const linkWithRedirect = useSignupRedirect({
    isUserLogged,
    link: stockUrl,
    domain: waveUrl
  });

  return linkWithRedirect;
};

export const useFetchStocks = ({ searchName, isVideoStock, isImageStock }: StockFetchProps) => {
  const [isPending, setPending, unsetPending] = useSwitchState();
  const [stocks, setStocks] = useState<StockItem[]>([]);

  const fetchStocks = (slug: string) =>
    axios({
      method: 'GET',
      url: `${apiUrl}stock/${slug}/search/${searchName}`,
      params: {
        page: 1,
        limit: 20
      }
    }).then(({ data }) => {
      setStocks(prevState => [...prevState, ...data.items]);
    });

  useEffect(() => {
    if (!searchName) return;

    setStocks([]);

    (async () => {
      setPending();

      isImageStock && (await fetchStocks('image'));
      isVideoStock && (await fetchStocks('video'));

      unsetPending();
    })();
  }, [searchName]);

  return [isPending, stocks] as const;
};

export const useSearchHandlers = (defaultState: string) => {
  const [text, setText] = useState(defaultState);
  const [savedText, setSavedText] = useState(defaultState);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setText(event.target.value.toLowerCase().trimLeft()),
    []
  );

  const handleOnKeyDown = useCallback(
    ({ code }: KeyboardEvent) => {
      ENTER_NAME === code && setSavedText(text);
    },
    [text]
  );

  const saveText = useCallback(() => {
    setSavedText(text);
  }, [text]);

  const handleClear = useCallback(() => {
    setText('');
    setSavedText('');
  }, []);

  return {
    text,
    savedText,
    handleClear,
    saveText,
    handleChange,
    handleOnKeyDown
  };
};

export const useAllStocksUrl = (savedText: string, isUserLogged: boolean) => {
  const params = {
    new: 1,
    s: savedText,
    target: 'stock-library'
  };

  const editorUrlWithHash = `${editorUrl}/#`;

  const urlParams = toUrlParams(params);
  const editorAllStocksUrl = `${editorUrlWithHash}${urlParams}`;

  const allStocksButtonUrl = useSignupRedirect({
    isUserLogged,
    domain: editorUrlWithHash,
    link: editorAllStocksUrl,
    isEncodeLink: false
  });

  return allStocksButtonUrl;
};
