import styled, { css } from 'styled-components';

import { media } from '../../../styles/helpers';

import { ListIconSizes } from './types';

type IconProps = {
  size?: ListIconSizes;
};

const SmallIconHolderOnLaptop = media.desktop`
  max-width: 70px;
  height: 70px;
  margin-bottom: 22px;
`;
const BigIconHolderOnLaptop = media.desktop`
  max-width: 380px;
  height: 255px;
  margin-bottom: 52px;
`;

const MediumIconHolderOnDesktop = media.desktop`
    max-width: 155px;
    height: 120px;
`;

const MediumIconHolderOnTablet = media.tablet`
    max-width: 132px;
    height: 102px;
`;

const iconSizes: { [key in ListIconSizes]: ReturnType<typeof css> } = {
  small: css`
    height: 70px;
    padding: 15px;
    max-width: 70px;
    margin-bottom: 14px;
    ${SmallIconHolderOnLaptop}
  `,
  big: css`
    height: 201px;
    max-width: 300px;
    margin-bottom: 24px;
    ${BigIconHolderOnLaptop}
  `,
  medium: css`
    max-width: 78px;
    height: 48px;
    margin-top: 10px;
    ${MediumIconHolderOnTablet}
    ${MediumIconHolderOnDesktop}
  `,
  '90x90': css`
    height: 90px;
    max-width: 90px;
    margin-bottom: 25px;
    img {
      min-height: 90px;
      min-width: 90px;
    }
  `,
  'small-in-circle': css`
    width: 70px;
    height: 70px;
    padding: 15px;
    background: #fff;
    border-radius: 50%;
    img {
      height: 40px;
      width: 40px;
    }
  `
};

const HorizontalListIconHolder = styled.div<IconProps>`
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-shrink: 0;
  ${({ size }) => iconSizes[size || 'small']};

  > div {
    width: 100%;
    margin: auto;
    max-height: 100%;
  }
`;

export default HorizontalListIconHolder;
