import { useEffect, useRef } from 'react';

export const useMediaRef = <T extends HTMLMediaElement>(
  stream: MediaStream | null = null
) => {
  const ref = useRef<T>(null);

  useEffect(() => {
    if (ref.current && stream) {
      ref.current.srcObject = stream;
    }
  }, [stream?.id]);

  return ref;
};
