import Tooltip from '@anm/components/Tooltip';
import noop from '@anm/helpers/noop';
import { FC } from 'react';
import styled from 'styled-components';

import { SelectColors, SelectOption } from '.';
import { OptionContainer, OptionWrapper } from './SelectOptionsContainer';
import { CheckboxWrapper } from './Wrapper';

type LinkProps = {
  selected: boolean;
  href?: string;
};

type OptionProps = {
  disabled?: boolean;
  color: SelectColors;
  multiSelect?: boolean;
  currentOption?: SelectOption;
  multiSelectValues?: string[];
  onClick?: () => void;
};

const LinkWrapper = styled(OptionContainer.withComponent('a'))<OptionProps>``;

const Container: FC<OptionProps & LinkProps> = props => {
  const isLink = !!props.href;

  const isDownloadLink = isLink && new RegExp('filename=').test(props.href!);

  return isLink ? (
    <LinkWrapper {...props} {...{ ...(isDownloadLink && { download: props.href }) }} />
  ) : (
    <OptionContainer {...props} />
  );
};

const Option: FC<OptionProps & SelectOption> = ({
  href,
  icon,
  value,
  label,
  color = 'gray',
  disabled,
  multiSelect,
  renderIcon,
  renderTooltip,
  currentOption,
  onClick = noop,
  multiSelectValues
}) => {
  const isSelected =
    !multiSelect &&
    !!currentOption &&
    (currentOption.value ? currentOption.value === value : currentOption.label === label);
  const isChecked =
    (!!multiSelectValues && multiSelectValues.includes(value)) || (!!currentOption && currentOption.value === value);

  return (
    <OptionWrapper color={color} data-value={value}>
      <Container selected={isSelected} {...{ onClick, color, disabled, icon, href }}>
        <Tooltip delayShow={300} delayHide={300} renderContent={renderTooltip} direction="above">
          {renderIcon?.()}
          {multiSelect && <CheckboxWrapper checked={isChecked} />}
          {label}
        </Tooltip>
      </Container>
    </OptionWrapper>
  );
};

export default Option;
