import NextImage from '@anm/components/image/NextImage';
import { FC } from 'react';

import HorizontalListIconHolder from './IconHolder';
import { ListItem, ListWrapper } from './ListWrapper';
import { getImageAlt } from './helpers';
import { ListProps } from './types';

const List: FC<ListProps> = ({ list, columns, iconSize, multiLine = false, isSimpleImageList }) => {
  const listLength = list.length;

  return (
    <ListWrapper length={listLength} {...{ columns, multiLine, isSimpleImageList }}>
      {list.map(({ iconUrl, ariaLabel, title, description, customIcon, clickableIconLink }) => (
        <ListItem
          key={`${title}-${clickableIconLink}`}
          length={listLength}
          {...{ multiLine, iconUrl, customIcon, isSimpleImageList, clickableIconLink }}
        >
          {iconUrl && (
            <HorizontalListIconHolder
              size={iconSize}
              aria-label={ariaLabel}
              {...(clickableIconLink ? { href: clickableIconLink, as: 'a', target: '_blank' } : { as: 'div' })}
            >
              <NextImage
                width="100%"
                height="100%"
                layout="responsive"
                objectFit="contain"
                src={iconUrl}
                alt={getImageAlt(title, description)}
              />
            </HorizontalListIconHolder>
          )}
          <div>
            {title && <span dangerouslySetInnerHTML={{ __html: title }} />}
            {description && <p dangerouslySetInnerHTML={{ __html: description }} />}
          </div>
        </ListItem>
      ))}
    </ListWrapper>
  );
};

export default List;
