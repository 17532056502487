import Accordion from '@anm/components/Accordion';
import { useState, FC } from 'react';

import Media from '../Media';
import { AccordionMediaProps } from '../types';

import { AccordionWrapper, MediaWrapper, RowWrapper } from './Wrapper';

const AccordionMedia: FC<AccordionMediaProps> = ({
  list,
  direction = 'accordion-media',
  activeItem = 0,
  mediaMargin,
  headingLevel
}) => {
  const [currentMediaIndex, setCurrentMediaIndex] = useState(activeItem);
  const currentMedia = list[currentMediaIndex];

  return (
    <RowWrapper {...{ direction }} isSingleItem={list.length === 1}>
      <AccordionWrapper>
        <Accordion
          onItemChange={setCurrentMediaIndex}
          {...{ list, headingLevel }}
        />
      </AccordionWrapper>
      <MediaWrapper {...{ direction, mediaMargin }}>
        <Media
          {...currentMedia}
          key={currentMedia.videoId || currentMedia.imgUrl}
        />
      </MediaWrapper>
    </RowWrapper>
  );
};

export default AccordionMedia;
