import styled, { css } from 'styled-components';

import { inlineCodeStyles, media } from '../../../../styles/helpers';

const DocsTableLaptop = media.laptop`
    display: table;
    width: 100%;
    tbody {
        display: table-row-group;
    }
    tr {
        display: table-row;
        td {
            padding: 31px 40px 31px 47px;
            &:first-of-type {
               padding: 31px 30px;
               width: 25%;
            }
        }
    }
`;

const attrStyles = css`
  color: #0c9744;
`;

export const DocsTableWrapper = styled.section`
  margin-bottom: 67px;
`;

export const DocsTable = styled.table`
  border: 1px solid #ccdde6;
  border-collapse: collapse;
  table-layout: fixed;
  display: block;
  tbody {
    display: block;
  }
  tr {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ccdde6;
    &:last-of-type {
      border-bottom: 0 none;
    }
    td {
      padding: 20px;
      font-size: 16px;
      line-height: 28px;
      vertical-align: top;
      &:first-of-type {
        white-space: nowrap;
        width: 100%;
      }
      p {
        margin: 0 0 25px;
        span {
          ${attrStyles};
        }
        a {
          color: var(--blue_100);
        }
        code {
          ${inlineCodeStyles};
        }
      }
      & > * {
        max-width: 650px;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  ${DocsTableLaptop};
`;

export const Attr = styled.span`
  ${attrStyles};
`;
