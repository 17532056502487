import {
  hasSnippetForCurrentPage,
  reviewNameMap,
  reviewSnippet,
  PageName
} from '@anm/data/structures/Review';
import Head from 'next/head';
import { FC } from 'react';
import { organizationData } from '@anm/data/structures/Organization';

const addReviewSnippet = (pageName: PageName) => {
  const pageSnippet = reviewNameMap[pageName] || {};

  return JSON.stringify({
    ...organizationData,
    ...reviewSnippet,
    ...pageSnippet
  });
};

type ReviewSnippetProps = {
  pageName: PageName;
};

const ReviewSnippet: FC<ReviewSnippetProps> = ({ pageName }) => {
  const isSnippetForCurrentPage = !!reviewNameMap[pageName];

  if (!isSnippetForCurrentPage) return null;

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: addReviewSnippet(pageName) }}
      />
    </Head>
  );
};

type ReviewSnippetOrNullProps = {
  pageName: PageName | string;
};

const ReviewSnippetOrNull: FC<ReviewSnippetOrNullProps> = ({ pageName }) =>
  hasSnippetForCurrentPage(pageName) ? (
    <ReviewSnippet pageName={pageName} />
  ) : null;

export const getUrlPath = (link: string) => {
  const url = new URL(link);
  const pathnameNoTrailingSlash =
    url.pathname.endsWith('/') && url.pathname !== '/'
      ? url.pathname.slice(0, -1)
      : url.pathname;

  return pathnameNoTrailingSlash;
};

export const getUrlShortPath = (link: string) => {
  const url = new URL(link);
  const splittedUrl = url.pathname.split('/');
  const shortPath = splittedUrl[1];
  const slashAndSplittedUrl = `/${shortPath}`;
  return slashAndSplittedUrl;
};

export default ReviewSnippetOrNull;
