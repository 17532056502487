import { FC } from 'react';

import Media, { MediaProps } from './Media';
import { MediaContent } from './SideMediaContentWrapper';
import { MediaWrapper } from './Wrapper';

const MediaBlock: FC<MediaProps> = ({
  title,
  sideMedia,
  mediaFrame,
  playIconSize,
  mediaMaxWidth,
  imageSideMediaUrl
}) => (
  <MediaContent {...{ mediaMaxWidth }}>
    <MediaWrapper {...{ mediaMaxWidth, mediaFrame, playIconSize }}>
      <Media {...{ ...sideMedia, mediaMaxWidth, imageSideMediaUrl, title }} />
    </MediaWrapper>
  </MediaContent>
);

export default MediaBlock;
