import getFromCdn from '@anm/helpers/getFromCdn';
import { useEffect, useRef, useState, FC } from 'react';

import { ListItem } from '.';
import { ArrowIcon, Row, Title } from './Wrapper';

const ROW_VERTICAL_PADDING = 21;
const DESCRIPTION_MARGIN_TOP = 15;
const DEFAULT_ROW_HEIGHT = 74;

type DynamicRowProps = ListItem & {
  isActiveRow: boolean;
  headingLevel: number;
  onRowClick(): void;
};

const DynamicRow: FC<DynamicRowProps> = ({
  title,
  onRowClick,
  isActiveRow,
  description,
  headingLevel
}) => {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const descRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(DEFAULT_ROW_HEIGHT);

  const getRowHeight = () => {
    const titleClientHeight = titleRef?.current?.clientHeight;
    const descClientHeight = descRef?.current?.clientHeight;

    const titleHeight = ROW_VERTICAL_PADDING * 2 + titleClientHeight!;
    const descriptionHeight = DESCRIPTION_MARGIN_TOP + descClientHeight!;
    const rowHeight = isActiveRow
      ? descriptionHeight + titleHeight
      : titleHeight;

    setHeight(rowHeight);
  };

  useEffect(() => {
    if (!titleRef.current || !descRef.current) return;
    getRowHeight();
  }, [titleRef, descRef, isActiveRow]);

  return (
    <Row
      key={title}
      onClick={onRowClick}
      isActive={isActiveRow}
      height={height}
    >
      <Title
        titleRef={titleRef}
        level={headingLevel}
        descriptionRef={descRef}
        {...{ title, description }}
      />
      <ArrowIcon
        src={getFromCdn('images/icons/accordion_arrow.svg')}
        alt="arrow"
      />
    </Row>
  );
};

export default DynamicRow;
