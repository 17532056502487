import styled from 'styled-components';

import { media } from '../../../styles/helpers';
import SectionTitle from '../../SectionTitle';

const TopSectionTitleOnLaptop = media.laptop`
  max-width: 613px;
  margin-right: auto;    
  `;

const TopSectionHeadingOnLaptop = media.laptop`
  font-size: 60px;
  font-weight: 700;
  line-height: 66px;
  text-align: left;
  margin-bottom: 41px;
`;

const TopSectionTitle = styled(SectionTitle)`
  ${TopSectionTitleOnLaptop};

  h1,
  h2,
  h3,
  h4,
  h4,
  h5,
  h6 {
    font-size: 30px;
    ${TopSectionHeadingOnLaptop};
  }
`;

export const HiddenTitle = styled(TopSectionTitle)`
  visibility: hidden;
`;

export default TopSectionTitle;
