import getFromCdn from '@anm/helpers/getFromCdn';
import { FC } from 'react';
import { Omit } from 'types/helpers';

import { ActionItemProps } from './ActionItem';
import ActionsList, { ActionsListProps } from './ActionsList';

export type AudioActionsProps = Omit<ActionsListProps, 'list'>;

const list: ActionItemProps[] = [
  {
    title: 'Create a video podcast',
    description: 'Make video with title and dynamic wave forms',
    buttonName: 'Create podcast',
    intent: 'create-podcast',
    icon: `${getFromCdn('images/icons/media/speaker-pink.svg')}`
  },
  {
    title: 'Translate audio with AI voice',
    description: 'You can pick from a variety of voices and languages',
    buttonName: 'Translate',
    intent: 'translate-audio',
    icon: `${getFromCdn('images/icons/media/translate.svg')}`
  },
  {
    title: 'Generate .srt',
    description: 'Generate and download a subtitle file',
    buttonName: 'Generate .srt',
    intent: 'srt',
    icon: `${getFromCdn('images/icons/media/auto-captions-purple.svg')}`
  }
];
const AudioActions: FC<AudioActionsProps> = props => <ActionsList list={list} {...props} />;

export default AudioActions;
