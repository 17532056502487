import { LinkProps } from 'next-routes';
import { createContext, ComponentType } from 'react';

export type AnmLinkProps = LinkProps & {
  scroll?: boolean;
};

type LinkContextProps = {
  Link: ComponentType<AnmLinkProps>;
};

export const LinkContext = createContext({} as LinkContextProps);

export const LinkProvider = LinkContext.Provider;
