import Image from '@anm/components/image/Image';
import { FC } from 'react';

import WavePlayerModal from '../../WavePlayerModal';

import StatisticCircleList from './StaticCircle/List';
import {
  StatImageWrapper,
  SubTitle,
  VideoContainer,
  VideoStatisticDataWrapper,
  VideoStatisticItem
} from './Wrapper';
import { StatisticListItem } from './types';

const StatisticItem: FC<StatisticListItem> = ({
  alt,
  title,
  videoId,
  reverse,
  imageUrl,
  description,
  circleProgressList
}) => (
  <VideoStatisticItem {...{ reverse }}>
    {videoId && (
      <VideoContainer>
        <WavePlayerModal
          playIconVariant="white"
          playerIconSize="80x80"
          {...{ videoId }}
        />
      </VideoContainer>
    )}
    {imageUrl && (
      <StatImageWrapper>
        <Image src={imageUrl} alt={alt || title} />
      </StatImageWrapper>
    )}

    <VideoStatisticDataWrapper>
      <SubTitle level={4} color="black" {...{ title, description }} />
      <StatisticCircleList {...{ circleProgressList }} />
    </VideoStatisticDataWrapper>
  </VideoStatisticItem>
);

export default StatisticItem;
