import noop from '@anm/helpers/noop';
import useDiffSave from '@anm/hooks/useDiffSave';
import { useEffect, useRef, FC } from 'react';
import { FormSpy } from 'react-final-form';

type AutoSaveProps = {
  values: object;
  debounce: number;
  onSave?: (values: object) => void;
};

export const AutoSave: FC<AutoSaveProps> = ({ values: prevValues, debounce, onSave = noop }) => {
  const save = useDiffSave<{}>({ initValues: prevValues, onSave });
  const timeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => save(prevValues), debounce);
  });

  return null;
};

const AutoSaveSpy = (props: any) => <FormSpy {...props} subscription={{ values: true }} component={AutoSave} />;

export default AutoSaveSpy;
