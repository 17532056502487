import { Workspace } from '@anm/api/modules/team';
import { createContext } from 'react';

type WorkspaceContextProps = {
  workspace: Workspace;
};

export const WorkspaceContext = createContext<WorkspaceContextProps>({ workspace: 'user' });

export const WorkspaceProvider = WorkspaceContext.Provider;
