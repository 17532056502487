import styled, { css } from 'styled-components';

import { media } from '../../../styles/helpers';
import { VideoStepsListItemContent } from './Wrapper';

export type DirectionType = 'text-video' | 'video-text';

type VideoStepsListWrapperProps = {
  isOneStep: boolean;
  listDirection: DirectionType;
  fullWidth?: boolean;
};

const oddListItemOnLaptop = media.laptop`
  transform: translateX(50px);
`;

const evenListItemOnLaptop = media.laptop`
  transform: translateX(-50px);
`;

const directions: { [key in DirectionType]: ReturnType<typeof css> } = {
  'text-video': css`
    li {
      &:nth-child(odd) {
        ${VideoStepsListItemContent} {
          flex-direction: row-reverse;
          ${oddListItemOnLaptop};
        }
      }

      &:nth-child(even) {
        ${VideoStepsListItemContent} {
          ${evenListItemOnLaptop};
        }
      }
    }
  `,
  'video-text': css`
    li {
      &:nth-child(even) {
        ${VideoStepsListItemContent} {
          flex-direction: row-reverse;
          ${oddListItemOnLaptop};
        }
      }

      &:nth-child(odd) {
        ${VideoStepsListItemContent} {
          ${evenListItemOnLaptop};
        }
      }
    }
  `
};

const videoStepsListWrapperFullWidthTablet = media.tablet`
  li {
    ${VideoStepsListItemContent} {
      padding: 20px 40px;
    }
  }
`;

const videoStepsListWrapperFullWidth = css`
  li {
    ${VideoStepsListItemContent} {
      padding: 0 16px 24px;
      max-width: 1400px;
      margin: 0 auto;
      transform: none !important;
    }
  }
  ${videoStepsListWrapperFullWidthTablet};
`;

const VideoStepsListWrapper = styled.ul<VideoStepsListWrapperProps>`
  margin-top: 40px;
  ${({ listDirection }) => directions[listDirection]};
  ${({ fullWidth }) => fullWidth && videoStepsListWrapperFullWidth};
  ${({ isOneStep }) =>
    isOneStep &&
    css`
      li {
        ${VideoStepsListItemContent} {
          transform: none !important;
        }
      }
    `}
`;
export default VideoStepsListWrapper;
