import { Component } from 'react';

import withDefaultProps from '../../HOCs/widthDefaultProps';
import Modal from '../modals/Modal';

import SidebarContent from './Content';
import SidebarModalWrapper from './Wrapper';

export type SidebarModalProps = {
  onModalClose: () => void;
} & DefaultProps;

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps = {
  width: '100%',
  direction: 'right' as 'right' | 'left',
  background: 'transparent'
};

class SidebarModal extends Component<SidebarModalProps> {
  render() {
    const { width, children, direction, background, onModalClose } = this.props;

    return (
      <Modal closeModal={onModalClose} isSidebarMenu={true}>
        <SidebarModalWrapper direction={direction}>
          <SidebarContent width={width} background={background}>
            {children}
          </SidebarContent>
        </SidebarModalWrapper>
      </Modal>
    );
  }
}

export default withDefaultProps(defaultProps)(SidebarModal);
