import parsePageName from '@anm/analytic/helpers/parsePageName';
import memoizeLast from '@anm/helpers/memoizeLast';
import capitalize from '@anm/helpers/string/capitalize';
import LodashCompose from 'lodash/fp/compose';

import { trackVisitPage } from '../../';

const WAVE_MAIN_PAGE_NAME = 'Wave Main';

const pageNameMap: { [key: string]: string } = {
  '': WAVE_MAIN_PAGE_NAME,
  '/': WAVE_MAIN_PAGE_NAME
};

const getPageName = (path: string) => pageNameMap[path] || path;

const memoizedTrackVisitPage = memoizeLast(
  LodashCompose(trackVisitPage, capitalize, parsePageName, getPageName)
);

export default memoizedTrackVisitPage;
