import Container from '@anm/components/Container';
import Button from '@anm/components/buttons/Button';
import LazyImage from '@anm/components/image/LazyImage';
import { flexCenter } from '@anm/styles/helpers';
import styled from 'styled-components';

import SectionTitle from '../../SectionTitle';

export const TitleWrapper = styled(SectionTitle)`
  margin-bottom: 70px;
`;

export const ImageWrapper = styled(LazyImage)`
  max-height: 48px;
`;

export const ListWrapper = styled(Container)`
  ${flexCenter};
  flex-wrap: wrap;
  flex-direction: row;
`;

export const Item = styled.div`
  ${flexCenter};
  min-width: 150px;
  min-height: 90px;
  margin-bottom: 32px;
  flex-direction: column;
  img {
    max-height: 50px;
  }
`;

export const Title = styled.span`
  color: #292a2b;
  font-size: 18px;
  margin-top: auto;
  line-height: 26px;
`;

export const ButtonWrapper = styled(Button)`
  display: block;
  font-size: 18px;
  margin: 33px auto;
  text-decoration: underline;
`;

const Wrapper = styled.section`
  margin: 50px 0;
`;

export default Wrapper;
