import { PLAYER_ANALYTICS_DEV, PLAYER_ANALYTICS_PROD, PLAYER_ANALYTICS_TEST } from '@anm/constants/domains';

export type EnvType = 'dev' | 'prod' | 'test';

type Configs = { [key in EnvType]: Config };

type Config = {
  cdnUrl: string;
  apiUrl: string;
  analyticsUrl: string;
  supportedExtensions: string[];
};

const supportedExtensions = ['mp4', 'webm', 'ogg', 'mov', 'm3u8'];

const configs: Configs = {
  dev: {
    cdnUrl: 'https://embed.wave-test.video',
    apiUrl: 'https://api.wave-test.video/v2',
    analyticsUrl: PLAYER_ANALYTICS_DEV,
    supportedExtensions
  },
  test: {
    cdnUrl: 'https://embed.wave-test.video',
    apiUrl: 'https://api.wave-test.video/v2',
    analyticsUrl: PLAYER_ANALYTICS_TEST,
    supportedExtensions
  },
  prod: {
    cdnUrl: 'https://embed.wave.video',
    apiUrl: 'https://api.wave.video/v2',
    analyticsUrl: PLAYER_ANALYTICS_PROD,
    supportedExtensions
  }
};

const currentEnv: EnvType = process.env['TS_ENV'] as EnvType;

if (!currentEnv) throw new Error('Missed env: TS_ENV');

const playerConfig = (env?: EnvType) => configs[env || currentEnv];

export default playerConfig;
