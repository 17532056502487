import styled from 'styled-components';

import { media } from '../../../styles/helpers';
import SectionTitle from '../../SectionTitle';

export const BackgroundTextTitle = styled(SectionTitle)`
  max-width: 650px;
  margin: 0 auto;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 13px;
    font-weight: 900;
  }
`;

const BackgroundTextWrapperOnLaptop = media.laptop`
  padding: 35px 0 52px 0;
`;

const BackgroundTextWrapper = styled.div`
  padding: 50px 0 30px 0;
  ${BackgroundTextWrapperOnLaptop};
`;

export const ButtonWrapper = styled.div`
  padding: 40px 0 30px 0;
  text-align: center;
`;

export default BackgroundTextWrapper;
