import api from '@anm/api';
import hasAuthAccess from '@anm/auth/helpers/hasAuthAccess';
import { UploadFileProps as UploadProps } from '@anm/components/InputFile';
import { DEFAULT_VIDEO_NAME } from '@anm/constants/my-videos';
import { Logger } from '@anm/helpers/Debugger';
import cutFileExtension from '@anm/helpers/cutFileExtension';
import getExtensionFromPath from '@anm/helpers/getExtensionFromPath';
import isIOS from '@anm/helpers/is/isIOS';
import useVideoStatusChecker from '@anm/hooks/useVideoStatusChecker';
import { useEffect, useState } from 'react';

import config from '../../../../../config';
import { OpenEditorProps, PreparedMedia, UseUploadVideoProps, UseUploadVideoState } from '../types';
import sortByQuality from '@anm/helpers/sortByQuality';
import { ButtonSize, ButtonVariants } from '@anm/components/buttons/Button';
import Dialog from '@anm/components/modals/Dialog';
import Confirm from '@anm/components/notification/Confirm';
import noop from '@anm/helpers/noop';
import useSwitchState from '@anm/hooks/useSwitchState';
import { CreateUploadRequest, UploadFileRequest } from '@anm/api/modules/uploads';
import { AxiosProgressEvent } from 'axios';

const logger = new Logger('upload_video');

const DEFAULT_DESCRIPTION = 'hosted by Wave.video';

const openEditor = ({ uploadIds, intent }: OpenEditorProps) => {
  const query = intent ? `&intent=${intent}` : '';
  const uploadParam = `#uploadIds=${uploadIds.join(',')}`;
  const editorUrl = config.editorUrl;

  window.open(`${editorUrl}/${uploadParam}${query}`, '_self');
};

const initialState: UseUploadVideoState = {
  hostId: '',
  mp4Url: '',
  totalFiles: 1,
  progressValue: '',
  uploadIds: [],
  isMP4ModalOpened: false,
  preparedMedia: undefined,
  currentUploadIndex: 1
};

const useUploadVideo = ({
  intent,
  onUpload = 'openEditor',
  onUploadProgress: onProgress,
  checkStorage
}: UseUploadVideoProps) => {
  const [hostId, setHostId] = useState(initialState.hostId);
  const [mp4Url, setMp4Url] = useState<string | undefined>(initialState.mp4Url);
  const [totalFiles, setTotalFiles] = useState(initialState.totalFiles);
  const [currentUploadIndex, setCurrentUploadIndex] = useState(initialState.currentUploadIndex);
  const [progressValue, setProgressValue] = useState(initialState.progressValue);
  const [uploadIds, setUploadIds] = useState<string[]>(initialState.uploadIds);
  const [preparedMedia, setPreparedMedia] = useState<PreparedMedia | undefined>(initialState.preparedMedia);
  const [isMP4ModalOpened, openMP4Modal, closeMP4Modal] = useSwitchState();

  const cleanState = () => {
    closeMP4Modal();
    setHostId(initialState.hostId);
    setMp4Url(initialState.mp4Url);
    setTotalFiles(initialState.totalFiles);
    setCurrentUploadIndex(initialState.currentUploadIndex);
    setProgressValue(initialState.progressValue);
    setUploadIds(initialState.uploadIds);
    setPreparedMedia(initialState.preparedMedia);
  };

  // const createVideo = (data: CreateNewVideoData) => api().hosting.createNewVideo(data);
  const createVideo = (data: CreateUploadRequest) => api().uploads.createUpload(data);
  // const uploadVideo = (data: UploadFileProps) => api().hosting.uploadNewVideo(data);
  const uploadVideo = (data: UploadFileRequest) => api().uploads.uploadFile(data);

  const onUploadProgress = (e: AxiosProgressEvent) => {
    const value = `${Math.round((e.loaded * 100) / e.total!)}%`;
    onProgress?.(value);
    setProgressValue(value);
  };

  const handleFileChange = async (uploads: UploadProps[]) => {
    if (!hasAuthAccess()) return;

    setTotalFiles(uploads.length);

    for (const uploadProps of uploads) {
      const isStorageError = await checkStorage(uploadProps.file.size);

      if (isStorageError) return;

      await upload(uploadProps.file);
      setProgressValue('0%');
      setCurrentUploadIndex(index => index + 1);
    }
  };

  const upload = async (file: File) => {
    try {
      const name = isIOS() ? DEFAULT_VIDEO_NAME : cutFileExtension(file.name);
      const description = `${name} - ${DEFAULT_DESCRIPTION}`;
      const extension = getExtensionFromPath(file.name) || '';

      const params: CreateUploadRequest = {
        extension,
        folderPath: '/',
        isEmbedded: false,
        projectType: 'upload',
        meta: { name, description }
      };

      const createdVideoData = await createVideo(params);

      const url = createdVideoData.request.url;
      const uploadId = createdVideoData.data.upload.id;

      setHostId(createdVideoData.hostId);

      await uploadVideo({
        url,
        file,
        method: 'PUT',
        onUploadProgress
      });

      setUploadIds(ids => [...(ids || []), uploadId]);
    } catch (e) {
      logger.error(e);
    }
  };

  useEffect(() => {
    if (typeof totalFiles !== 'number' || !uploadIds) return;

    const isAllUploaded = totalFiles === uploadIds?.length;

    if (isAllUploaded) {
      setProgressValue('');

      switch (onUpload) {
        case 'openEditor':
          return openEditor({ uploadIds, intent });
        case 'convertToMP4':
          return setPreparedMedia('video');
        case 'downloadAudio': {
          return setPreparedMedia('audio');
        }
      }
    }
  }, [totalFiles, uploadIds?.length, onUpload]);

  const media = useVideoStatusChecker({
    hostId,
    source: 'api',
    mediaType: preparedMedia,
    supportedExtensions: preparedMedia === 'audio' ? config.supportedAudioExtensions : config.supportedVideoExtensions,
    progress: !!preparedMedia ? 100 : null
  });

  useEffect(() => {
    if (!media.isMediaReady || !preparedMedia) return;

    switch (preparedMedia) {
      case 'audio': {
        const mp3 = media.videoManifest?.main.find(file => file.type === 'audio')?.path;
        setPreparedMedia(undefined);
        window.open(mp3, '_self');
        break;
      }

      case 'video': {
        const allVideos = media.videoManifest?.main?.filter(s => s.type === 'video');
        const bestVideo = allVideos && sortByQuality(allVideos)[0].path;
        setPreparedMedia(undefined);
        setMp4Url(bestVideo);
        openMP4Modal();
      }
    }
  }, [media.isMediaReady, preparedMedia]);

  const convertToMP4Modal = isMP4ModalOpened ? (
    <Dialog onModalClose={cleanState}>
      <Confirm
        title="Video converted successfully!"
        buttonLeft={{
          text: 'Download mp4',
          size: 'upper-medium' as ButtonSize,
          variant: 'silver-border' as ButtonVariants
        }}
        buttonRight={{
          text: 'Edit in Video Editor',
          size: 'upper-medium' as ButtonSize,
          variant: 'yellow' as ButtonVariants
        }}
        onLeftButtonClick={() => window.open(`${mp4Url}?filename=${uploadIds?.[0]}.mp4`, '_self')}
        onRightButtonClick={() => (!uploadIds ? noop : openEditor({ uploadIds, intent }))}
      />
    </Dialog>
  ) : null;

  return [progressValue, handleFileChange, totalFiles, currentUploadIndex, preparedMedia, convertToMP4Modal] as const;
};

export default useUploadVideo;
