import { FC } from 'react';

import { FlexBlock } from './FlexContainerWrapper';

export type FlexContainerVariants =
  | 'on-laptop'
  | 'between-on-tablet'
  | 'between-on-laptop'
  | 'end-center-on-laptop'
  | 'between-wrap-on-laptop'
  | 'start-center-on-laptop'
  | 'start-center-on-tablet'
  | 'center-center-on-laptop'
  | 'center-between-on-laptop'
  | 'column-reverse-on-mobile'
  | 'center-between-on-tablet'
  | 'wrap-on-mobile-nowrap-around-on-tablet'
  | 'baseline-on-mobile-end-nowrap-on-laptop'
  | 'column-reverse-on-mobile-row-center-on-laptop'
  | 'column-center-on-mobile-between-reverse-on-tablet';

export type FlexContainerProps = {
  variant: FlexContainerVariants;
  isList?: boolean;
};

const FlexContainer: FC<FlexContainerProps> = ({
  variant,
  isList,
  children
}) => (
  <FlexBlock as={isList ? 'ul' : 'div'} variant={variant}>
    {children}
  </FlexBlock>
);

export default FlexContainer;
