import NotificationBlock, { NotificationBlockProps } from '@anm/components/NotificationBlock';
import { FC } from 'react';

import Content from './Content';
import NotificationPortal from './Portal';

export type ModalProps = NotificationBlockProps & {
  onModalClose: (id: number) => void;
};

const NotificationModal: FC<ModalProps> = props => (
  <NotificationPortal>
    <Content variant={props.variant} position={props.position}>
      <NotificationBlock {...props} />
    </Content>
  </NotificationPortal>
);

export default NotificationModal;
