import { ApiError } from '@anm/api';
import {
  CompleteUpload,
  CreateFolderResponse,
  CreateUploadRequest,
  CreateUploadResponse,
  FetchUploadRequest,
  GetFolderData,
  MoveFolderProps,
  MoveUploadProps,
  UpdateFolderParams,
  UpdateMediaParams,
  UploadElement,
  UploadFileRequest,
  UploadMediaFile,
  UploadProjectsResponse
} from '@anm/api/modules/uploads';
import { createAction, createAsyncAction } from '@anm/helpers/saga/typesafe-actions';

import { CancelUploadingProps, CreateUploadProps, UploadBreadcrumbItemProps, UploadForBranParams } from './types';

export const fetchUploads = createAction('wave.video/uploads/FETCH_UPLOADS')<Partial<GetFolderData> | void>();

export const fetchUploadsAsync = createAsyncAction(
  'wave.video/uploads/FETCH_UPLOADS_REQUEST',
  'wave.video/uploads/FETCH_UPLOADS_SUCCESS',
  'wave.video/uploads/FETCH_UPLOADS_ERROR'
)<GetFolderData, UploadElement[], ApiError>();

export const updateBreadcrumbs = createAction('wave.video/uploads/UPDATE_BREADCRUMBS')<
  UploadBreadcrumbItemProps | null | UploadBreadcrumbItemProps[]
>();

export const fetchUploadsNextPage = createAction('wave.video/uploads/FETCH_UPLOADS_NEXT_PAGE')();

export const fetchUploadMedia = createAction('wave.video/uploads/FETCH_UPLOAD_MEDIA')<FetchUploadRequest>();

export const fetchUploadMediaAsync = createAsyncAction(
  'wave.video/uploads/FETCH_UPLOAD_MEDIA_REQUEST',
  'wave.video/uploads/FETCH_UPLOAD_MEDIA_SUCCESS',
  'wave.video/uploads/FETCH_UPLOAD_MEDIA_FAILURE'
)<FetchUploadRequest, UploadMediaFile, ApiError>();

export const updateMediaName = createAction('wave.video/uploads/UPDATE_MEDIA_NAME')();

export const updateMediaNameAsync = createAsyncAction(
  'wave.video/uploads/UPDATE_MEDIA_NAME_REQUEST',
  'wave.video/uploads/UPDATE_MEDIA_NAME_SUCCESS',
  'wave.video/uploads/UPDATE_MEDIA_NAME_FAILURE'
)<string, UploadMediaFile, ApiError>();

export const updateFolderPath = createAction('wave.video/uploads/UPDATE_FOLDER_PATH')<UpdateFolderParams>();

export const updateFolderPathAsync = createAsyncAction(
  'wave.video/uploads/UPDATE_FOLDER_PATH_REQUEST',
  'wave.video/uploads/UPDATE_FOLDER_PATH_SUCCESS',
  'wave.video/uploads/UPDATE_FOLDER_PATH_FAILURE'
)<UpdateFolderParams, { id: string }, ApiError>();

export const updateMediaMeta = createAction('wave.video/uploads/UPDATE_MEDIA_META')<UpdateMediaParams>();

export const updateMediaMetaAsync = createAsyncAction(
  'wave.video/uploads/UPDATE_MEDIA_META_REQUEST',
  'wave.video/uploads/UPDATE_MEDIA_META_SUCCESS',
  'wave.video/uploads/UPDATE_MEDIA_META_FAILURE'
)<UpdateMediaParams, { id: string }, ApiError>();

export const createFolder = createAction('wave.video/uploads/CREATE_FOLDER')<string[]>();

export const createFolderAsync = createAsyncAction(
  'wave.video/uploads/CREATE_FOLDER_REQUEST',
  'wave.video/uploads/CREATE_FOLDER_SUCCESS',
  'wave.video/uploads/CREATE_FOLDER_FAILURE'
)<string[], CreateFolderResponse[], ApiError>();

export const removeNewAttributeFromFolder = createAction('wave.video/uploads/REMOVE_NEW_ATTRIBUTE_FROM_FOLDER')();

export const deleteFolder = createAction('wave.video/uploads/DELETE_FOLDER')<string>();

export const deleteFolderAsync = createAsyncAction(
  'wave.video/uploads/DELETE_FOLDER_FOLDER_REQUEST',
  'wave.video/uploads/DELETE_FOLDER_FOLDER_SUCCESS',
  'wave.video/uploads/DELETE_FOLDER_FOLDER_FAILURE'
)<string, { id: string }, ApiError>();

export const createUpload = createAction('wave.video/uploads/CREATE_UPLOADS')<CreateUploadProps>();

export const createUploadAsync = createAsyncAction(
  'wave.video/uploads/CREATE_UPLOAD_REQUEST',
  'wave.video/uploads/CREATE_UPLOAD_SUCCESS',
  'wave.video/uploads/CREATE_UPLOAD_FAILURE'
)<CreateUploadRequest, CreateUploadResponse, ApiError>();

export const completeMultipartUpload = createAction('wave.video/uploads/COMPLETE_MULTIPART_UPLOAD')<CompleteUpload>();

export const completeMultipartUploadAsync = createAsyncAction(
  'wave.video/uploads/COMPLETE_MULTIPART_UPLOAD_REQUEST',
  'wave.video/uploads/COMPLETE_MULTIPART_UPLOAD_SUCCESS',
  'wave.video/uploads/COMPLETE_MULTIPART_UPLOAD_FAILURE'
)<CompleteUpload, { id: string }, ApiError>();

export const uploadFileAsync = createAsyncAction(
  'wave.video/uploads/UPLOAD_FILE_REQUEST',
  'wave.video/uploads/UPLOAD_FILE_SUCCESS',
  'wave.video/uploads/UPLOAD_FILE_FAILURE'
)<UploadFileRequest, string, ApiError>();

export const updateProgress = createAction('wave.video/uploads/UPLOAD_UPDATE_PROGRESS')<{
  id: string;
  progress: number;
}>();

export const cancelUploading = createAction('wave.video/uploads/CANCEL_UPLOADING')<CancelUploadingProps>();

export const clearCancelToken = createAction('wave.video/uploads/CLEAR_CANCEL_TOKEN')<string>();

export const removeListItem = createAction('wave.video/uploads/REMOVE_LIST_ITEM')<string>();

export const getUploadProjects = createAction('wave.video/uploads/GET_UPLOAD_PROJECTS')<string>();

export const getUploadProjectsAsync = createAsyncAction(
  'wave.video/uploads/GET_UPLOAD_PROJECTS_REQUEST',
  'wave.video/uploads/GET_UPLOAD_PROJECTS_SUCCESS',
  'wave.video/uploads/GET_UPLOAD_PROJECTS_FAILURE'
)<string, UploadProjectsResponse, ApiError>();

export const clearUploadProjects = createAction('wave.video/uploads/CLEAR_UPLOAD_PROJECTS')();

export const deleteUpload = createAction('wave.video/uploads/DELETE_UPLOAD')<string>();

export const deleteUploadAsync = createAsyncAction(
  'wave.video/uploads/DELETE_UPLOAD_REQUEST',
  'wave.video/uploads/DELETE_UPLOAD_SUCCESS',
  'wave.video/uploads/DELETE_UPLOAD_FAILURE'
)<string, { id: string }, ApiError>();

export const saveUploadToBrand = createAction('wave.video/uploads/SAVE_UPLOAD_TO_BRAND')<string>();

export const clearUploadForBrand = createAction('wave.video/uploads/CLEAR_UPLOAD_TO_BRAND')();

export const fetchUploadForBrand = createAction('wave.video/uploads/FETCH_UPLOAD_FOR_BRAND')<UploadForBranParams>();

export const fetchUploadForBrandAsync = createAsyncAction(
  'wave.video/uploads/FETCH_UPLOAD_FOR_BRAND_UPLOAD_REQUEST',
  'wave.video/uploads/FETCH_UPLOAD_FOR_BRAND_UPLOAD_SUCCESS',
  'wave.video/uploads/FETCH_UPLOAD_FOR_BRAND_UPLOAD_FAILURE'
)<FetchUploadRequest, UploadMediaFile, ApiError>();

export const refreshUploads = createAction('wave.video/uploads/REFRESH_PAGE')();

export const moveUploadToFolder = createAction('wave.video/uploads/MOVE_UPLOAD_TO_FOLDER')<MoveUploadProps>();

export const moveUploadToFolderAsync = createAsyncAction(
  'wave.video/uploads/MOVE_UPLOAD_TO_FOLDER_REQUEST',
  'wave.video/uploads/MOVE_UPLOAD_TO_FOLDER_SUCCESS',
  'wave.video/uploads/MOVE_UPLOAD_TO_FOLDER_FAILURE'
)<MoveUploadProps, { id: string }, ApiError>();

export const moveUploadFolderToFolder = createAction('wave.video/uploads/MOVE_UPLOAD_FOLDER_TO_FOLDER')<
  MoveFolderProps
>();

export const moveUploadFolderToFolderAsync = createAsyncAction(
  'wave.video/uploads/MOVE_UPLOAD_FOLDER_TO_FOLDER_REQUEST',
  'wave.video/uploads/MOVE_UPLOAD_FOLDER_TO_FOLDER_SUCCESS',
  'wave.video/uploads/MOVE_UPLOAD_FOLDER_TO_FOLDER_FAILURE'
)<MoveFolderProps, { id: string }, ApiError>();

export const setIsStorageFull = createAction('wave.video/uploads/SET_IS_STORAGE_FULL')<boolean>();

export const changeUploadStatus = createAction('wave.video/uploads/CHANGE_UPLOAD_STATUS')<{
  uploadId: string;
  duration: number;
  preview: string;
}>();

export const clearUploadedFiles = createAction('wave.video/uploads/CLEAR_UPLOADED_FILES')();

export const changeSearchOpen = createAction('wave.video/uploads/CHANGE_SEARCH_OPEN')<boolean>();
export const updateSearchTerm = createAction('wave.video/uploads/UPDATE_SEARCH_TERM')<string>();
