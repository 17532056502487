import { Link } from './types';

export const getStepsData = (advancedLink: Link, onSimple: () => void) => [
  {
    ...advancedLink,
    image: 'images/data/webcam-test/advanced_record.png',
    title: 'Advanced',
    description:
      'Cloud recording using Live Studio. Use it when you invite guests or need to show overlays, play videos, audio and have different layouts. ' +
      'The recording will be ready right away.'
  },
  {
    handler: onSimple,
    image: 'images/data/webcam-test/simple_record.png',
    title: 'Simple',
    description:
      'Local high quality recording. Use it when you need to record only webcam and/or screen share or the quality is paramount. ' +
      'The recordings will need to be edited and published from Wave.video editor.'
  }
];
