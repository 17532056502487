import styled, { css } from 'styled-components';

import { mobileMenuItem } from '../../styles/helpers';

import { CollapseVariants } from '.';
import { RowProps } from './types';

const variants: { [key in CollapseVariants]: ReturnType<typeof css> } = {
  'right-down-arrow': css`
    display: flex;
    align-items: center;
    padding: 0 40px 0 30px;
    &:hover {
      color: #2294df;
    }
  `,
  'up-down-arrow': css``,
  'plus-minus': css<RowProps>`
    ${mobileMenuItem};
    display: flex;
    margin: 0 20px;
    border-bottom: ${({ isOpened }) => (isOpened ? '0 none' : 'solid 1px rgba(203, 220, 229, 0.5)')};
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    h3 {
      font-size: 16px;
      font-weight: 600;
    }
  `,
  'up-down-small-arrow': css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
  `,
  'arrow-with-title': css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
  `
};

const ExpandMenuItem = styled.div<RowProps>`
  ${({ variant }) => variants[variant]};
`;

export default ExpandMenuItem;
