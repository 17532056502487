import { useDropdownGroup } from '@anm/components/Dropdown';
import {
  NavigationLinkProps,
  NavigationListProps,
  NavigationNestContainer
} from '@anm/components/header';
import NavigationDropdown from '@anm/components/header/NavigationDropdown';
import { FC } from 'react';

const isNestedNavigation = (
  props: NavigationLinkProps | NavigationNestContainer
): props is NavigationNestContainer =>
  !!(props as NavigationNestContainer).children;

const NavigationList: FC<NavigationListProps> = ({
  list,
  renderNavLink,
  ...containerProps
}) => {
  const [delay, handleDropdownOpen, handleDropdownClose] = useDropdownGroup();

  return (
    <ul>
      {list.map(item => (
        <li key={item.title}>
          {isNestedNavigation(item) ? (
            <NavigationDropdown
              navList={item.children}
              {...{
                delay,
                renderNavLink,
                handleDropdownOpen,
                handleDropdownClose,
                ...containerProps,
                ...item
              }}
            />
          ) : (
            renderNavLink(item)
          )}
        </li>
      ))}
    </ul>
  );
};

export default NavigationList;
