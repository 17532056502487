import Button, { ButtonVariants } from '@anm/components/buttons/Button';
import { FC } from 'react';

import vtConfig from '../../config';

type MyProjectsButtonProps = { buttonVariant: ButtonVariants };

const MyProjectsButton: FC<MyProjectsButtonProps> = ({ buttonVariant }) => (
  <Button
    href={`${vtConfig.waveUrl}videos`}
    variant={buttonVariant}
    size="medium"
  >
    My projects
  </Button>
);

export default MyProjectsButton;
