import Button from '@anm/components/buttons/Button/Button';
import { flexCenter } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

import { media } from '../../../styles/helpers';
import SectionTitle, { SectionTitleProps } from '../../SectionTitle';
import { LandingVideoProps } from '../../landing/LandingVideo/index';

const VideoContainerOnLaptop = media.laptop`
    margin: 30px auto;
`;

export const VideoContainer = styled.div`
  width: 100%;
  max-width: 720px;
  position: relative;
  background-color: #fff;
  margin: 32px auto 40px auto;

  ${({ isShadow }: Partial<LandingVideoProps>) =>
    isShadow &&
    css`
      box-shadow: 0 5px 50px rgba(4, 25, 79, 0.25);
    `};

  ${VideoContainerOnLaptop};
`;

const paragraphOnTablet = media.tablet`
    display: block;
`;

const firstSectionSpaceOnLaptop = media.laptop`
  padding-top: 135px;
`;

const sectionTitleOnLaptop = media.laptop`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 60px;
    line-height: 72px;
    margin-bottom: 30px;
    & + p {
      font-size: 24px;
      line-height: 36px;
    }
  }
`;

export const VerticalTextVideoTitle = styled(SectionTitle)`
  ${({ isFirstSection }: SectionTitleProps) =>
    isFirstSection &&
    css`
      padding-top: 90px;
      ${firstSectionSpaceOnLaptop};
      ${sectionTitleOnLaptop};
    `};

  p {
    display: none;
    ${paragraphOnTablet};
  }
`;

export const VerticalTextVideoWrapper = styled.section`
  position: relative;
  overflow: hidden;
  text-align: center;
  margin-bottom: 100px;
`;

export const MediaWrapper = styled.div`
  img {
    width: 100%;
    position: relative;
    margin-bottom: -4px;
  }
`;

export const ButtonContainer = styled.div`
  ${Button} {
    a {
      ${flexCenter};
      padding: 0 30px;
      font-size: 20px;
      color: #fff !important;
    }
  }
`;
