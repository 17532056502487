import Image from '@anm/components/image/Image';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 10px 0;

  a[type='button'] {
    width: 100%;
    color: #292a2b;
    cursor: pointer;
    line-height: 60px;
    padding-left: 30px;
  }
`;

export const WrappedImage = styled(Image)`
  left: 20px;
  position: absolute;
  pointer-events: none;
  & + * {
    padding-left: 80px;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  min-width: 240px;
  min-height: 60px;
  position: relative;
  align-items: center;
  flex-direction: row;
  a {
    flex: 1;
    padding-left: 30px;
    font-weight: 700 !important;
    line-height: 60px !important;
  }
  &:hover {
    background-color: rgba(32, 171, 210, 0.1);
  }
`;

export default Wrapper;
