import { flexCenter, media } from '@anm/styles/helpers';
import { useCallback, useRef, FC, MouseEvent } from 'react';
import styled from 'styled-components';

const wrapperMobileStyles = media.tablet`
  align-items: flex-start;
`;

export const Wrapper = styled.div`
  ${flexCenter};
  top: 0;
  width: 100%;
  z-index: 99;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  ${wrapperMobileStyles};
`;

type OverlayProps = { onModalClose: () => void };

const Overlay: FC<OverlayProps> = ({ children, onModalClose }) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleModalClose = useCallback((event: MouseEvent<HTMLDivElement>) => {
    if (!ref.current || ref.current !== event.target) return;

    event.stopPropagation();
    onModalClose();
  }, [onModalClose]);

  return (
    <Wrapper ref={ref} onMouseDown={handleModalClose}>
      {children}
    </Wrapper>
  );
};
export default Overlay;
