import Button from '@anm/components/buttons/Button';
import { media } from '@anm/styles/helpers';
import styled from 'styled-components';

type CardProps = {
  background: string;
};

const imageWrapperMobile = media.tablet`
  max-height: 236px;
`;

const imageWrapperDesktopSmall = media.desktop_small`
  max-height: 70%;
`;

export const AvatarContainer = styled.div`
  left: 0;
  bottom: 0;
  width: 50%;
  top: -30px;
  overflow: visible;
  position: absolute;
`;

const cardTextMobile = media.phone`
  font-size: 16px;
  line-height: 25px;
`;

const cardTextTablet = media.tablet`
  font-size: 30px;
  line-height: 48px;
`;

export const CardText = styled.p`
  ${cardTextTablet};
  ${cardTextMobile};
  font-size: 30px;
  max-width: 403px;
  text-align: left;
  line-height: 48px;
`;

const cardLinkMobile = media.tablet`
  font-size: 16px;
  line-height: 19px;
  margin: 20px 0;
`;

export const CardLink = styled(Button)`
  ${cardLinkMobile};
  text-decoration: underline;
  align-self: flex-start;
  display: flex;
  font-size: 20px;
  text-align: left;
  line-height: 28px;
  text-decoration-line: underline;
`;

export const CardName = styled.p`
  ${cardTextMobile}
  font-size: 20px;
  text-align: left;
  line-height: 32px;
  color: rgba(41, 42, 43, 0.5);
`;

const cardContentTablet = media.tablet`
  min-height: 300px;
  max-width: 350px;
  margin: 0 auto;
  padding: 0;
`;

const cardContentMobile = media.phone`
  justify-content: flex-start;
  min-height: 200px;
`;

export const CardContent = styled.div`
  margin-left: 56%;
  ${cardContentTablet};
  ${cardContentMobile};
  display: flex;
  min-height: 380px;
  flex-direction: column;
  justify-content: space-between;
`;

const cardWrapperTabletStyles = media.tablet`
  margin: 0 20px;
  padding: 40px 30px 219px;
`;

const cardWrapperSmallDesktopStyles = media.desktop_small`
  padding: 30px;
  min-height: 285px;
`;

const cardWrapperMobile = media.phone`
  padding: 30px 30px 210px;
`;

const CardWrapper = styled.div<CardProps>`
  ${cardWrapperSmallDesktopStyles};
  ${cardWrapperTabletStyles};
  ${cardWrapperMobile};
  margin: 0 50px;
  overflow: visible;
  position: relative;
  border-radius: 20px;
  padding: 60px 95px 30px;
  background: ${({ background }) => background};
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  img {
    ${imageWrapperDesktopSmall};
    ${imageWrapperMobile};
    left: 0;
    bottom: 0;
    height: 100%;
    max-width: unset;
    position: absolute;
    border-bottom-left-radius: 20px;
  }
`;

export default CardWrapper;
