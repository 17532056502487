import { FC } from 'react';

import { NavigationLinkProps } from '../../../types';

import { ItemWrapper, WrappedImage } from './Wrapper';

const ListItem: FC<NavigationLinkProps> = ({ title, iconSrc, children }) => (
  <ItemWrapper>
    {iconSrc && <WrappedImage src={iconSrc} alt={title} />}
    {children}
  </ItemWrapper>
);

export default ListItem;
