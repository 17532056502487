import { StockItem } from '@anm/api/modules/stocks';
import { Stock, Stocks, StockName } from '@anm/api/modules/subscription';
import { UserSubscriptionDetails } from '@anm/api/modules/user';
import roundedPrice from '@anm/helpers/pricing/roundPrice';
import getFreeSubscription from '@anm/helpers/subscription/getFreeSubscription';

type CuttedListProps = {
  list: StockItem[];
  limit: number;
  columnCount: number;
};

const getMixedStock = ({ list, limit }: CuttedListProps): StockItem[] => {
  const videoStockList = list.filter(el => el.type === 'STOCK_VIDEO');
  const imageStockList = list.filter(el => el.type === 'STOCK_IMAGE');

  const result = [] as StockItem[];

  for (let i = 0; i < limit; i++) {
    const video = videoStockList[i];
    const image = imageStockList[i];
    video && result.push(video);
    image && result.push(image);
  }

  return result;
};

type StockCountProps = {
  limit: number;
  columnCount: number;
  list: StockItem[];
};

const getStocksCount = ({
  list,
  limit,
  columnCount
}: StockCountProps): number =>
  list.length > limit
    ? (Math.floor(limit / columnCount) + 1) * columnCount + columnCount - 1
    : limit;

export const getCuttedList = ({
  list,
  limit,
  columnCount
}: CuttedListProps): StockItem[] => {
  const mixedList = getMixedStock({ list, limit, columnCount });
  const stocksCount = getStocksCount({
    limit,
    columnCount,
    list: mixedList
  });

  return mixedList.slice(0, stocksCount);
};

export const getStockPrice = (
  subscriptionList: UserSubscriptionDetails[] | undefined,
  stocksFeature: Stocks | undefined,
  { isVideo, stockName }: { isVideo: boolean; stockName: StockName }
) => {
  if (!subscriptionList) return null;

  const freeSubscription = getFreeSubscription(subscriptionList);
  const waveSubscription = subscriptionList.find(sub => sub.product === 'WAVE');

  const serviceData =
    (stocksFeature?.[stockName] as Stock) ||
    freeSubscription?.features.Stocks[stockName] ||
    waveSubscription?.features.Stocks[stockName];

  if (!serviceData) return null;

  const stockPriceInCents = isVideo
    ? serviceData.videoPrice
    : serviceData.imagePrice;
  const stockPriceInDollars = roundedPrice(stockPriceInCents);

  return stockPriceInDollars;
};

export const getPriceValue = (value: number | null) =>
  value ? `$${value}` : 'Free';
