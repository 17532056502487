import Container from '@anm/components/Container';
import getFromCdn from '@anm/helpers/getFromCdn';
import { flexCenter } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

import { DemoVariant } from './';

type WrapperProps = {
  filled: boolean;
  variant?: DemoVariant;
};

const defaultVideoSettings = {
  controls: true,
  autoPlay: true
};

export const RatioContainer = styled.div`
  background: #f5f7fa;
  border: 1px solid #ccdde6;
  position: relative;
  height: 0;
  width: 100%;
  box-sizing: content-box;
`;

export const RatioContainerContent = styled.div`
  ${flexCenter};
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const variants: { [key in DemoVariant]: ReturnType<typeof css> } = {
  static: css`
    ${Container} {
      max-width: 640px;
    }
    ${RatioContainer} {
      padding-top: 56.25%;
    }
  `,
  demo1: css`
    background: url("${getFromCdn(
      'images/wave-button/fashion.png'
    )}") no-repeat center 0 transparent;
    background-size: 1000px auto;
    height: calc(100vh - 74px);
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      background-color: #FFE2D6;
      width: 50%;
      z-index: 1;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      right: 40px;
      top: 47px;
      height: 33%;
      background: url("${getFromCdn(
        'images/wave-button/demo-3-3.png'
      )}") no-repeat center 0 transparent;
      background-size: 344px auto;
      width: 50%;
      z-index: 2;
    }
    ${Container} {
      max-width: 800px;
      margin-top: 84px;
      position: relative;
      z-index: 2;
    }
    ${RatioContainer} {
      padding-top: 56.25%;
      &:after {
        content: "";
        display: block;
        width: 360px;
        height: 360px;
        background: url("${getFromCdn(
          'images/wave-button/demo-3-2.png'
        )}") no-repeat center 0 transparent;
        background-size: cover;
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(50%, 12%);
      }
    }
  `,
  demo2: css`
    background: ${({ filled }: WrapperProps) =>
      `url("${getFromCdn(
        `images/wave-button/demo2_${filled ? 'filled' : 'initial'}.png`
      )}") no-repeat center 0 transparent;`};
    background-size: 1000px auto;
    min-height: calc(100vh - 74px);
    ${Container} {
      max-width: 380px;
      margin-top: 115px;
    }
    ${RatioContainer} {
      padding-top: 100%;
      margin-left: 30px;
    }
  `
};

export const Wrapper = styled.section<WrapperProps>`
  padding: 31px 0;
  ${({ variant }) => variants[variant || 'static']}
`;

export const Video = styled.video.attrs(defaultVideoSettings)`
  max-height: 100%;
  max-width: 100%;
  outline: none;

  & + span {
    position: absolute;
    top: calc(100% + 20px);
  }
`;

export const EmbedButtonError = styled.div`
  color: rgb(241, 57, 52);
  margin-bottom: -25px;
  margin-top: 8px;
  transform: translateY(25px);
`;
