import React, { FC } from 'react';

import {
  ContentWrapper,
  LoaderMessage,
  LoaderWrapper,
  PlayerLoader
} from './Wrapper';

type LoaderProps = {
  color: string;
  message?: string;
  transparent?: boolean;
};

const Loader: FC<LoaderProps> = ({ color, message, transparent = true }) => (
  <LoaderWrapper>
    <ContentWrapper>
      <PlayerLoader {...{ transparent }}>
        <svg
          width="44"
          height="44"
          viewBox="0 0 44 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M43 43C43 19.804 24.196 1 1 1"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </PlayerLoader>
      {message && <LoaderMessage>{message}</LoaderMessage>}
    </ContentWrapper>
  </LoaderWrapper>
);

export default Loader;
