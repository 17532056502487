import LazyImage from '@anm/components/image/LazyImage';
import isMobile from '@anm/helpers/is/isMobile';
import { FC } from 'react';

import CardWrapper, {
  AvatarContainer,
  CardContent,
  CardLink,
  CardName,
  CardText,
  ImageWrapper
} from './CardWrapper';
import { CardFeedbacksProps } from './FeedbackList';

const CardFeedback: FC<{ item: CardFeedbacksProps }> = ({
  item: { cardBackground, twit, customButton, avatar, name }
}) => (
  <CardWrapper background={cardBackground}>
    <AvatarContainer>
      <ImageWrapper>
        <LazyImage src={`images/data/feedbacks/${avatar}`} alt={name} />
      </ImageWrapper>
    </AvatarContainer>
    <CardContent>
      <CardText>{twit}</CardText>
      {customButton && (
        <CardLink {...customButton}>{customButton.children}</CardLink>
      )}
      {!isMobile() && <CardName dangerouslySetInnerHTML={{ __html: name }} />}
    </CardContent>
  </CardWrapper>
);

export default CardFeedback;
