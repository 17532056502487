import TitleBlock, { TitleDescription } from '@anm/components/TitleBlock';
import Image from '@anm/components/image/Image';
import getFromCdn from '@anm/helpers/getFromCdn';
import { flexColumn, flexRow, media } from '@anm/styles/helpers';
import slick from '@anm/styles/third-party/slick';
import styled from 'styled-components';

export const MainTitle = styled(TitleBlock)`
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 40px;
    margin: 0 0 55px;
    font-weight: 800;
    text-align: center;
  }
`;

const markDetailsOnMobile = media.phone`
  top: 77px;
  left: 7px;
  transform: scale(0.8);
  img:first-child {
    margin-right: 10px;
  }
`;

const markDetailsOnTablet = media.tablet`
  top: 71px;
  left: 138px;
  position: absolute;
`;

export const MarkDetails = styled.div`
  ${flexRow};
  margin-top: 4px;
  margin-bottom: 24px;
  img:first-child {
    margin-right: 21px;
  }
  ${markDetailsOnTablet};
  ${markDetailsOnMobile};
`;

export const AvatarImage = styled(Image)`
  min-width: 50px;
  max-height: 50px;
  margin-right: 16px;
  border-radius: 50%;
  box-sizing: border-box;
`;

export const PostInfo = styled.div`
  max-width: 537px;
  text-align: left;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  ${TitleDescription} {
    font-size: 16px;
    line-height: 25px;
    font-weight: normal;
  }
`;

export const AuthorInfo = styled.div`
  ${flexRow};
`;

const authorWrapperOnTablet = media.tablet`
  max-width: unset;
  margin: 30px 0 0;
`;

export const AuthorWrapper = styled.div`
  ${flexColumn};
  max-width: 233px;
  margin-left: auto;
  ${authorWrapperOnTablet}
`;

export const AuthorTitle = styled(TitleBlock)`
  width: unset;
  text-align: left;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 16px;
    line-height: 25px;
    font-weight: bold;
  }
  ${TitleDescription} {
    font-size: 16px;
    min-width: 120px;
    line-height: 25px;
    font-weight: normal;
  }
`;

const contentOnMobile = media.phone`
  margin: 0 0 0 10px;
  padding: 116px 30px 40px;
  &::before {
    left: 27px;
  }
`;

const contentOnTablet = media.tablet`
  ${flexColumn};
  padding: 100px 122px 50px 110px;

`;

export const Content = styled.div`
  ${flexRow};
  margin: 0 30px;
  background: #e9f8ff;
  border-radius: 20px;
  min-height: inherit;
  padding: 50px 122px 50px 190px;
  &::before {
    content: '';
    width: 68.96px;
    height: 52.7px;
    top: 0px;
    left: 93px;
    position: absolute;
    background: url(${getFromCdn('images/icons/blue_quotes.svg')}) no-repeat;
  }
  ${contentOnTablet};
  ${contentOnMobile};
`;

export const Wrapper = styled.div<{ minHeight: number }>`
  padding-top: 16px;
  position: relative;
  margin-right: 15px;
  min-height: ${({ minHeight }) => minHeight}px;
`;

export const CarouselWrapper = styled.div`
  position: relative;
  text-align: center;
  padding: 0 0 30px;
  margin: 0 -20px;

  ${slick}

  div {
    outline: none;
    cursor: grab;
  }

  .slick-dots {
    left: 3px;
    bottom: -36px;
    li {
      width: 25px;
      margin: 1px 0 0 0px;
      button:before {
        font-size: 40px;
      }
    }
  }
`;

const SectionWrapper = styled.section`
  margin: 50px 0;
`;

export default SectionWrapper;
