import styled from 'styled-components';

import { media } from '../../../styles/helpers';

const TopSectionWrapperOnLaptop = media.laptop`
    position: relative;
`;

const TopSectionWrapper = styled.section`
  overflow: hidden;
  ${TopSectionWrapperOnLaptop};
`;

export default TopSectionWrapper;
