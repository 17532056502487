import Avatar from '@anm/components/Avatar';
import Container from '@anm/components/Container';
import Button, { ButtonProps } from '@anm/components/buttons/Button';
import React, { FC } from 'react';

import lfConfig from '../../../config';
import { CommonLandingProps } from '../../../features/getLanding';
import getWaves from '../../../helpers/getWaves';
import Background from '../../Background/index';
import FlexContainer from '../../FlexContainer/index';
import SectionPicture from '../../SectionPicture';

import Wrapper, { ImageWrapper, ListWrapper, Title } from './Wrapper';

export type ImageListProps = CommonLandingProps & {
  title: string;
  list: ImageListItemProps[];
  description: string;
  button?: ButtonProps;
};

export type ImageListItemProps = {
  title: string;
  imageUrl: string;
  description: string;
};

const SimpleImageList: FC<ImageListProps> = ({
  list,
  waves,
  title,
  button,
  description,
  headingLevel,
  sectionPicture,
  customBackground
}) => (
  <Wrapper>
    <Background
      variant={getWaves(waves) || 'white'}
      customBackground={customBackground}
      isSectionPicture={!!sectionPicture}
    >
      <Container size={1000}>
        <Title
          {...{ title, description }}
          color="black"
          level={headingLevel || 3}
        />
        <ListWrapper>
          <FlexContainer variant="between-on-tablet" isList={true}>
            {list.map(el => {
              return (
                <li key={el.title}>
                  <ImageWrapper>
                    <Avatar src={el.imageUrl} alt={el.title} size={120} />
                  </ImageWrapper>
                  <p>{el.title}</p>
                  <span>{el.description}</span>
                </li>
              );
            })}
          </FlexContainer>
        </ListWrapper>
        {button && (
          <div>
            <Button
              size="big"
              variant="light-blue-lowercase"
              href={`${lfConfig.waveUrl}${button.href}`}
            >
              {button.children}
            </Button>
          </div>
        )}
      </Container>
    </Background>
    {sectionPicture && (
      <SectionPicture pictureUrl={sectionPicture} title={title} />
    )}
  </Wrapper>
);

export default SimpleImageList;
