import { MediaObject } from '../../types';

const getBestQuality = (mediaObjects: MediaObject[]) => {
  const sources = mediaObjects.sort((a, b) => b.width - a.width);

  if (sources.length<=1) return sources[0];

  const v0 = sources[0];
  if (!v0.isOriginal) return v0;

  const v1 = sources[1];
  if (v1.width===v0.width || v1.width>=1080 || v1.height>=1080) return v1;

  return v0;
}

export default getBestQuality;
