import Container from '@anm/components/Container';
import { media } from '@anm/styles/helpers';
import styled from 'styled-components';

import SectionTitle from '../../SectionTitle';

import { ListContainerProps } from './types';

const listTitleOnMobile = media.phone`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 28px;
    line-height: 40px;
    margin: 20px 30px;
  }
  p {
    margin-top: unset;
  }
`;

const linksListContainerOnDesktop = media.desktop`
  padding: 30px;
`;

const linksListContainerOnMobile = media.phone<ListContainerProps>`
  border-radius: 0;
  padding: 30px 20px;
   ${({ customMarginMobile }) =>
     customMarginMobile && `margin:${customMarginMobile}`};
`;

export const ListTitle = styled(SectionTitle)`
  margin: 0 auto 31px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
  }
  p {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
  }
  ${listTitleOnMobile};
`;

export const ListContainer = styled(Container)<ListContainerProps>`
  z-index: 2;
  position: relative;
  border-radius: 20px;
  padding: ${({ customPadding }) => customPadding || '60px'};
  margin: ${({ customMargin }) => customMargin || '0 auto 80px'};
  background-color: ${({ customBackground }) => customBackground || '#FFF4DB'};
  ${linksListContainerOnMobile};
  ${linksListContainerOnDesktop};
`;

const Wrapper = styled.section``;

export default Wrapper;
