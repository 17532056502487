import styled from 'styled-components';

export default styled.div`
  &:not(:empty) {
    flex: 1;
  }
  outline: none;
  overflow: hidden;
  user-select: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;
