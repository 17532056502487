import useMediaDevicesControls from '@anm/hooks/media/useMediaDevicesControls';
import { noop } from 'lodash/fp';
import { useEffect, useRef, useState } from 'react';

import { MediaAllow } from '../AllowMicCam';

type UseMediaPendingProps = { mediaAllow?: MediaAllow; enableTimeout?: boolean };
const useMediaPending = (
  { enableTimeout, mediaAllow }: UseMediaPendingProps | undefined = {
    enableTimeout: false,
    mediaAllow: {
      video: true,
      audio: true
    }
  }
) => {
  const [isMediaPending, setMediaPending] = useState(true);
  const { webcams, mics } = useMediaDevicesControls();
  const isMediaLoaded =
    (!mediaAllow?.video || !!webcams?.[0]?.deviceId) && (!mediaAllow?.audio || !!mics?.[0]?.deviceId);
  const clear = useRef(noop);

  useEffect(() => {
    if (enableTimeout) {
      clear.current();
      const clearId = setTimeout(() => {
        isMediaPending && setMediaPending(false);
      }, 3000);

      clear.current = () => clearTimeout(clearId);
    }

    isMediaLoaded && setMediaPending(false);
  }, [isMediaLoaded]);

  return { isMediaPending, isMediaLoaded };
};

export default useMediaPending;
