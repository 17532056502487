import { SubscriptionPeriod } from '@anm/api/modules/subscription';
import BillingPeriodsInput, { BillingPeriodsInputProps } from '@anm/components/form/BillingPeriodsInput';
import TextArea from '@anm/components/form/TextArea';
import TextInput, { TextInputOwnProps } from '@anm/components/form/TextInput';
import noop from '@anm/helpers/noop';
import cutLengthTo from '@anm/helpers/parsers/cutLengthTo';
import parseCardName from '@anm/helpers/parsers/parseCardName';
import parseCardNumber from '@anm/helpers/parsers/parseCardNumber';
import toNumber from '@anm/helpers/parsers/toNumber';
import compose from 'lodash/fp/compose';
import { FC } from 'react';
import { Field, FieldProps } from 'react-final-form';

import { SelectOption } from '../../select/Select';
import SelectInput, { SelectInputProps } from '../SelectInput';
import {
  composeValidators,
  confirmPassword,
  couponValidator,
  displayName,
  email,
  mailWithCheckExistValidator,
  maxLength,
  minLength,
  mustBeNumber,
  password,
  required
} from '../validators';
import { validateCardNumber, validateCVC } from '../validators/creditCardValidators';

export const EmailField: FC<Partial<FieldProps<string, HTMLInputElement> & TextInputOwnProps> & {
  maxLength?: number;
}> = props => (
  <Field
    name="email"
    type="email"
    label="Email"
    autoFocus={true}
    component={TextInput}
    validate={composeValidators(required(), email, maxLength(props.maxLength || 60))}
    {...props}
  />
);

type EmailCheckExistFieldProps = {
  currentEmail?: string;
};
export const EmailCheckExistField: FC<EmailCheckExistFieldProps &
  Partial<FieldProps<string, HTMLInputElement> & TextInputOwnProps> & { maxLength?: number }> = ({
  currentEmail,
  ...props
}) => (
  <Field
    name="email"
    type="email"
    label="Email"
    autoFocus={true}
    component={TextInput}
    validate={composeValidators(
      maxLength(props.maxLength || 60),
      required(),
      email,
      mailWithCheckExistValidator(currentEmail)
    )}
    {...props}
  />
);

export const TextField: FC<Partial<FieldProps<string, HTMLInputElement> & TextInputOwnProps>> & {
  maxLength?: number;
} = props => (
  <Field
    name="text"
    type="text"
    component={TextInput}
    validate={composeValidators(maxLength(props.maxLength))}
    {...props}
  />
);

export const RequiredTextField: FC<Partial<
  FieldProps<string, HTMLInputElement> & {
    minLength: number;
  }
> & { label: string }> = props => (
  <TextField
    validate={composeValidators(required(), minLength(props.minLength || 0), maxLength(props.maxLength || 60))}
    {...props}
  />
);

export const CardNumberField: FC<Partial<
  FieldProps<string, HTMLInputElement> & {
    minLength: number;
  }
>> = props => (
  <TextField
    required
    name="number"
    parse={parseCardNumber}
    label="Card Number"
    showError={false}
    data-stripe="number"
    autocomplete="cc-number"
    validate={composeValidators(
      required('Card number is required'),
      validateCardNumber,
      maxLength(props.maxLength || 60)
    )}
    {...props}
  />
);

export const CardNameField: FC<Partial<
  FieldProps<string, HTMLInputElement> & {
    minLength: number;
  }
>> = props => (
  <TextField
    name="name"
    label="Cardholder Name"
    parse={compose(cutLengthTo(30), parseCardName)}
    showError={false}
    data-stripe="name"
    placeholder="JOHN SMITH"
    validate={composeValidators(displayName, maxLength(props.maxLength || 60))}
    {...props}
  />
);

export const CardExpiryMonthField: FC<Partial<FieldProps<string, HTMLInputElement>>> = props => (
  <TextField
    required
    name="expiry_month"
    label="Expires"
    parse={compose(toNumber, cutLengthTo(2))}
    showError={false}
    placeholder="MM"
    data-stripe="exp-month"
    validate={composeValidators(required('Month expiry is required'), minLength(2), maxLength(props.maxLength || 60))}
    {...props}
  />
);

export const CardExpiryYearField: FC<Partial<FieldProps<string, HTMLInputElement>>> = props => (
  <TextField
    name="expiry_year"
    parse={compose(toNumber, cutLengthTo(2, 2))}
    showError={false}
    placeholder="YY"
    data-stripe="exp-year"
    validate={composeValidators(required('Year expiry is required'), minLength(2), maxLength(props.maxLength || 60))}
    {...props}
  />
);

export const CardCvcField: FC<Partial<FieldProps<string, HTMLInputElement>>> = props => (
  <TextField
    required
    name="cvc"
    label="CVC"
    parse={compose(toNumber, cutLengthTo(4))}
    showError={false}
    data-stripe="cvc"
    placeholder="000"
    autocomplete="cc-csc"
    validate={composeValidators(
      required('CVC is required'),
      validateCVC,
      minLength(3),
      maxLength(props.maxLength || 60)
    )}
    {...props}
  />
);

export const CardZipCodeField: FC<Partial<FieldProps<string, HTMLInputElement>>> = props => (
  <TextField
    name="address_zip"
    parse={compose(toNumber, cutLengthTo(5))}
    label="zip code"
    showError={false}
    data-stripe="address_zip"
    validate={composeValidators(minLength(5), maxLength(props.maxLength || 60))}
    {...props}
  />
);

export const OptionalCouponField: FC<Partial<
  FieldProps<string, HTMLInputElement> & {
    minLength?: number;
  }
>> = props => (
  <TextField
    type="text"
    name="optionalCoupon"
    validate={composeValidators(minLength(props.minLength || 12), couponValidator, maxLength(props.maxLength || 60))}
    {...props}
  />
);

export const TextAreaField: FC<Partial<FieldProps<string, HTMLTextAreaElement>> & {
  label: string;
}> = props => <Field name="textarea" type="textarea" component={TextArea} {...props} />;

export const BillingPeriodsField: FC<Partial<
  FieldProps<SubscriptionPeriod, HTMLDivElement> & BillingPeriodsInputProps
>> = props => <Field name="billingPeriod" type="text" component={BillingPeriodsInput} {...props} />;

export const DisplayNameField: FC<Partial<FieldProps<string, HTMLInputElement> & TextInputOwnProps> & {
  maxLength?: number;
}> = props => (
  <Field
    name="displayName"
    type="text"
    label="Name"
    component={TextInput}
    validate={composeValidators(displayName, maxLength(props.maxLength || 60))}
    {...props}
  />
);

export const DisplayNameRequiredField: FC<Partial<FieldProps<string, HTMLInputElement> & TextInputOwnProps> & {
  maxLength?: number;
}> = props => (
  <Field
    name="displayName"
    type="text"
    label="Name"
    component={TextInput}
    validate={composeValidators(required(), displayName, maxLength(props.maxLength || 60))}
    {...props}
  />
);

const allowEmptyValue = (value: string) => value;
export const DisplayNameFieldWithoutValidation: FC<Partial<
  FieldProps<string, HTMLInputElement> & TextInputOwnProps
>> = props => (
  <Field
    name="displayName"
    type="text"
    label="Name"
    parse={allowEmptyValue}
    isTruncate={true}
    component={TextInput}
    validate={maxLength(props.maxLength || 40)}
    {...props}
  />
);

export const DisplayNameRequiredFieldWithoutValidation: FC<Partial<
  FieldProps<string, HTMLInputElement> & TextInputOwnProps
>> = props => (
  <Field
    name="displayName"
    type="text"
    label="Name"
    isTruncate={true}
    component={TextInput}
    validate={composeValidators(required(), maxLength(props.maxLength || 40))}
    {...props}
  />
);

export const NameField: FC<Partial<FieldProps<string, HTMLInputElement> & TextInputOwnProps> & {
  maxLength?: number;
}> = props => (
  <Field
    name="name"
    type="text"
    label="Name"
    component={TextInput}
    validate={composeValidators(required(), maxLength(props.maxLength || 60))}
    {...props}
  />
);

export const PasswordField: FC<Partial<FieldProps<string, HTMLInputElement> & TextInputOwnProps> & {
  isSimple?: boolean;
  maxLength?: number;
}> = props => (
  <Field
    name="password"
    type="password"
    label="Password"
    component={TextInput}
    validate={props.isSimple ? noop : composeValidators(password, maxLength(props.maxLength || 60))}
    {...props}
  />
);

export const RequiredPasswordField: FC<Partial<
  FieldProps<string, HTMLInputElement> & {
    minLength: number;
  }
>> = props => (
  <PasswordField
    validate={composeValidators(required(), minLength(props.minLength || 0), maxLength(props.maxLength || 60))}
    {...props}
  />
);

export const ConfirmPasswordField: FC<Partial<FieldProps<string, HTMLInputElement> & TextInputOwnProps> & {
  newPassword: string;
  maxLength?: number;
}> = props => (
  <Field
    name="confirmPassword"
    type="password"
    label="Repeat password"
    component={TextInput}
    validate={composeValidators(required(), confirmPassword(props.newPassword), maxLength(props.maxLength || 60))}
    {...props}
  />
);

export const SelectField: FC<Partial<FieldProps<SelectOption, HTMLInputElement> & SelectInputProps>> = props => (
  <Field name="select" type="select" component={SelectInput} {...props} />
);

export const NumberField: FC<Partial<FieldProps<string, HTMLInputElement>> & { maxLength?: number }> = props => (
  <Field
    label="age"
    type="text"
    name="age"
    component={TextInput}
    validate={composeValidators(required(), mustBeNumber, maxLength(props.maxLength || 500))}
    {...props}
  />
);
