import createNumberFromResolution from './createNumberFromResolution';

export const isFHD = (resolution: string) => {
  const numberVal = createNumberFromResolution(resolution);
  return resolution?.includes('1080p') || numberVal > 720;
};

export const isHD = (resolution: string) => {
  const numberVal = createNumberFromResolution(resolution);
  return resolution?.includes('720p') || numberVal <= 720;
};
