import {
  subscriptionModalSectionWrapperStyles,
  subscriptionModalSidePaddings
} from '@anm/styles/helpers';
import styled from 'styled-components';

export const BillingPeriodBlockWrapper = styled.div`
  ${subscriptionModalSectionWrapperStyles};
  ${subscriptionModalSidePaddings};
  text-align: center;
`;
