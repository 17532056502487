import { trackVisit } from '@anm/analytic';
import initAnalytic from '@anm/analytic/helpers/initAnalytic';
import memoizedTrackVisitPage from '@anm/analytic/helpers/memoizedTrackVisitPage';
import hasAuthAccess from '@anm/auth/helpers/hasAuthAccess';
import sleep from '@anm/helpers/sleep';
import { useEffect } from 'react';
import { User } from 'user';

import lfConfig from '../config';

const ANIMATRON_PRODUCT = 'WAVE';

const useGuestVisitTrack = (user: User | null) => {
  useEffect(() => {
    if (hasAuthAccess() && !user) {
      return;
    }

    trackVisit({
      userProfile: user,
      product: ANIMATRON_PRODUCT
    });
  });
};

const useVisitPageTrack = () => {
  useEffect(() => {
    (async () => {
      await sleep(0);
      memoizedTrackVisitPage(location.pathname);
    })();
  }, []);
};

const useVisitTracker = (user: User | null) => {
  initAnalytic(lfConfig.analytics, ANIMATRON_PRODUCT);
  useGuestVisitTrack(user);
  useVisitPageTrack();
};

export default useVisitTracker;
