import Container from '@anm/components/Container';
import Button, { PartialButtonProps } from '@anm/components/buttons/Button';
import { FC } from 'react';

import { CommonLandingProps } from '../../../features/getLanding';
import Background, { BackgroundVariants } from '../../Background';

import BackgroundTextContainer, {
  BackgroundTextTitle,
  ButtonWrapper
} from './BackgroundTextContainer';

export type BackgroundTextProps = CommonLandingProps & {
  title: string;
  description: string;
  background: {
    alt?: string;
    variant?: BackgroundVariants;
    imageUrl?: string;
  };
  logoUrl?: string;
  customButton?: PartialButtonProps;
};

const BackgroundText: FC<BackgroundTextProps> = ({
  background,
  headingLevel,
  customButton,
  ...titleProps
}) => (
  <section>
    <Background variant={background?.variant || 'horizontal-blue-gradient'}>
      <BackgroundTextContainer>
        <Container>
          <BackgroundTextTitle
            level={headingLevel}
            color="white"
            {...titleProps}
          />
        </Container>
        {customButton && (
          <ButtonWrapper>
            <Button size="big" variant="yellow" {...customButton} />
          </ButtonWrapper>
        )}
      </BackgroundTextContainer>
    </Background>
  </section>
);

export default BackgroundText;
