import { ENTER_NAME, ESC_NAME } from '@anm/constants/keyCodes';
import { useCallback, useEffect, useRef, FC, KeyboardEvent } from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div`
  outline: none;
  overflow-y: auto;
`;

export type EscOnBlockProps = {
  onEsc(): void;
  onEnter?(): void;
  className?: string;
};

const KeyDownOnBlock: FC<EscOnBlockProps> = ({ children, onEsc, onEnter, className }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    container && setTimeout(() => container.focus(), 0);
  }, []);

  const handleKeyDown = useCallback(
    ({ code }: KeyboardEvent<HTMLDivElement>) => {
      code === ESC_NAME && onEsc();
      code === ENTER_NAME && onEnter?.();
    },
    [onEsc, onEnter]
  );

  return (
    <Wrapper ref={containerRef} tabIndex={1} className={className} onKeyDown={handleKeyDown}>
      {children}
    </Wrapper>
  );
};

export default KeyDownOnBlock;
