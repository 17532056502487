import { branch, renderComponent } from 'recompose';

import { MediaProps } from '../../types';

import PlayerBlock from './PlayerBlock';
import WistiaBlock from './WistiaBlock';

const isWavePlayer = (props: MediaProps) => !!props?.isWavePlayer;

export default branch(isWavePlayer, renderComponent(PlayerBlock))(WistiaBlock);
