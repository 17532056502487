import { AudioOptions } from '../mediaManager';
import { isChrome } from '@anm/helpers/is/browserCheckers';

export function getConstraints(deviceId: string, options: AudioOptions) {
  if (isChrome()) {
    return getConstraintsChrome(deviceId, options);
  } else {
    return getConstraintsStandard(deviceId, options);
  }
}

function getConstraintsChrome(deviceId: string, options: AudioOptions) {
  const noiseControl = options.noiseSuppression !== false;
  const echoControl = options.echoCancellation;

  const googEchoCancellation = echoControl && { googEchoCancellation: true };
  const googEchoCancellation2 = echoControl && { googEchoCancellation2: true };

  const googAutoGainControl = { googAutoGainControl: true };

  const googNoiseSuppression = noiseControl && { googNoiseSuppression: true };
  const googHighpassFilter = noiseControl && { googHighpassFilter: true };

  // const mandatory = deviceId === 'default' ? {}: {mandatory: { sourceId: deviceId }};

  return {
    audio: {
      // ...mandatory,
      mandatory: {
        sourceId: deviceId
      },
      optional: [
        googEchoCancellation,
        googEchoCancellation2,
        googAutoGainControl,
        googNoiseSuppression,
        googHighpassFilter
      ].filter(c => !!c)
    }
  };
}

function getConstraintsStandard(deviceId: string, options: AudioOptions) {
  const noiseControl = options.noiseSuppression !== false;
  const echoControl = options.echoCancellation;

  const echoCancellation = !echoControl && { echoCancellation: { ideal: false } };
  const noiseSuppression = noiseControl && { noiseSuppression: { ideal: true } };

  // const autoGainControl = echoControl && { autoGainControl: { ideal: true } };
  const autoGainControl = { autoGainControl: { ideal: true } };
  // const googExperimentalAutoGainControl = { googExperimentalAutoGainControl: { exact: true } };

  const stereo = !echoControl && options.stereo && { channelCount: { ideal: 2 } };
  // const sampleRate = {}; // { sampleRate: { ideal: 44100 } }

  // https://chromium.googlesource.com/experimental/chromium/src/+/refs/wip/bajones/webvr_1/content/renderer/media/media_stream_audio_processor_options.cc
  // const googEchoCancellation = echoControl && { googEchoCancellation: { exact: true } };
  // const googExperimentalEchoCancellation = echoControl && { googExperimentalEchoCancellation: { exact: true } };

  // const googExperimentalNoiseSuppression = noiseControl && { googExperimentalNoiseSuppression: { exact: true } };
  // const googHighpassFilter = noiseControl && { googHighpassFilter: { exact: true } };

  // const googAudioMirroring = {}; // echoControl && {googAudioMirroring: {exact: true}};

  /*
    const advanced = [
      // googEchoCancellation,
      // googExperimentalEchoCancellation,
      autoGainControl,
      noiseSuppression,
      // googHighpassFilter,
      // googExperimentalNoiseSuppression,
      // googExperimentalAutoGainControl,
      echoCancellation
    ];
  */

  // const deviceId = device.deviceId === 'default'? {}: {deviceId: { ideal: device.deviceId }};

  return {
    audio: {
      deviceId: { ideal: deviceId },
      // ...googEchoCancellation,
      // ...googExperimentalEchoCancellation,
      // ...googExperimentalNoiseSuppression,
      // ...googExperimentalAutoGainControl,
      // ...googHighpassFilter,
      // ...googAudioMirroring,
      ...autoGainControl,
      ...echoCancellation,
      ...noiseSuppression,
      ...stereo
      // advanced
    }
  };
}
