import { useCallback, useState } from 'react';

type UseActiveItemProps = {
  onChange: (i: number) => void;
  activeItem?: number;
};

export const useActiveItem = ({
  onChange,
  activeItem: selectedIndex = 0
}: UseActiveItemProps) => {
  const [activeItem, setActiveItem] = useState(selectedIndex);

  const handleItemClick = useCallback((i: number) => {
    setActiveItem(i);
    onChange(i);
  }, []);

  return [activeItem, handleItemClick] as const;
};
