import styled from 'styled-components';

import { media } from '../../styles/helpers';

const showOnLaptop = media.laptop`
  display: block;
`;

const HideOnMobile = styled.div`
  display: none;
  flex: 1;
  ${showOnLaptop};
`;

export default HideOnMobile;
