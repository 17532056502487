import styled from 'styled-components';

import { media } from '../../../styles/helpers';

const ListWrapperOnLaptop = media.tablet`
  margin-top: 60px;
`;

const IconOnTablet = media.tablet`
  width: 90px;
  height: 90px;
  text-align: center;
  line-height: 90px;
  color: #292a2b;
  font-size: 36px;
  font-weight: bold;
  top: 0;
`;

const ListItemOnTablet = media.tablet`
  padding-left: 120px;
`;

const NotLastListItemOnLaptop = media.laptop`
  margin-bottom: 30px;
`;

export const ListWrapper = styled.ul`
  margin-top: 24px;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  ${ListWrapperOnLaptop};

  li {
    display: flex;
    counter-increment: step-counter;
    position: relative;
    padding-left: 42px;
    min-height: 100px;
    ${ListItemOnTablet};

    &:not(:last-child) {
      margin-bottom: 24px;
      ${NotLastListItemOnLaptop};
    }

    &:before {
      position: absolute;
      top: 3px;
      left: 0;
      display: block;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      content: counter(step-counter);
      background-color: #fff200;
      color: #fff200;
      font-size: 0;
      ${IconOnTablet};
    }
  }

  p {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 6px;
  }

  span {
    font-weight: 400;
    line-height: 1.65;
    font-size: 17px;

    a {
      color: #292a2b;
      text-decoration: underline;
      &:hover {
        color: #2294df;
      }
    }
  }
`;

export const ButtonWrapper = styled.div`
  margin: 60px auto;
  text-align: center;
  & > a,
  button {
    padding: 0 30px;
  }
`;

export const VerticalListWrapper = styled.section`
  position: relative;
  overflow: hidden;
`;

export default VerticalListWrapper;
