import Container from '@anm/components/Container/index';
import { FC } from 'react';

import { CommonLandingProps } from '../../../features/getLanding';
import getSubtitleLevel from '../../../helpers/getSubtitleLevel';
import LandingButton, { LandingButtonProps } from '../../LandingButton';
import HideOnMobile from '../../mobile/HideOnMobile';

import StepsList from './List';
import { DirectionType } from './StepsListWrapper';
import Wrapper, { ButtonWrapper, MainTitle } from './Wrapper';
import { VideoStepItemProps } from './types';

export type VideoStepsProps = CommonLandingProps & {
  steps: VideoStepItemProps[];
  direction: DirectionType;
  title?: string;
  description?: string;
  button?: LandingButtonProps;
  disableMicrodata?: boolean;
  customAuthButtonGuestText?: string;
  fullWidth?: boolean;
};

const VideoSteps: FC<VideoStepsProps> = ({
  title,
  steps,
  button,
  customAuthButtonGuestText,
  direction,
  headingLevel,
  description,
  disableMicrodata = false,
  fullWidth
}) => {
  const isOneStep = steps.length === 1;
  const isMicrodata = !isOneStep && !disableMicrodata;

  return (
    <Wrapper {...{ fullWidth }}>
      <Container itemScope itemType={isMicrodata ? 'https://schema.org/HowTo' : ''}>
        <MainTitle itemProp="name" level={headingLevel || 2} {...{ title }} color="black" />
        {description && (
          <MainTitle itemProp="description" level={headingLevel || 2} {...{ description }} color="black" />
        )}
        <StepsList {...{ steps, direction, fullWidth }} headingLevel={getSubtitleLevel(headingLevel, true)} />
        <LandingButton size="big" variant="light-blue-lowercase" {...{ ...button, customAuthButtonGuestText }}>
          {landingButton => (
            <HideOnMobile>
              <ButtonWrapper>{landingButton} </ButtonWrapper>
            </HideOnMobile>
          )}
        </LandingButton>
      </Container>
    </Wrapper>
  );
};

export default VideoSteps;
