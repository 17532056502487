import { FC } from 'react';

import { NavigationLinkProps, RenderNavLinkProp } from '../../../types';

import ListItem from './ListItem';
import Wrapper from './Wrapper';

export type ContentNestedLinkProps = RenderNavLinkProp & {
  contentList: NavigationLinkProps[];
  isDropdownWithPreview?: boolean;
};

const ContentVerticalList: FC<ContentNestedLinkProps> = ({ contentList, renderNavLink }) => (
  <Wrapper>
    {contentList.map(link => (
      <ListItem key={link.title + link.href} {...link}>
        {renderNavLink(link)}
      </ListItem>
    ))}
  </Wrapper>
);

export default ContentVerticalList;
