import { MicStatus } from '@anm/components/media/MicVolume/hooks/useMicStatus';
import { useState, FC } from 'react';

import FormHelperText from '../FormHelperText';
import SelectInput, { SelectInputProps } from '../SelectInput';

import MicInputWrapper, { MicText, MicVolumeWrapper } from './Wrapper';

export type MicInputProps = SelectInputProps & { audioStream?: MediaStream };

const MicInput: FC<MicInputProps> = ({ className, audioStream, ...selectProps }) => {
  const [status, setStatus] = useState<MicStatus>();

  const canShowSelect = selectProps.options.length > 1;

  return (
    <MicInputWrapper className={className}>
      {status?.noVolume && <FormHelperText variant="over-right">Please check your mic</FormHelperText>}
      {canShowSelect ? (
        <SelectInput {...{ className, ...selectProps }} />
      ) : (
        <MicText>{selectProps.options[0]?.label}</MicText>
      )}
      <MicVolumeWrapper audioStream={audioStream} onMicStatusChange={setStatus} />
    </MicInputWrapper>
  );
};

export default MicInput;
