import TitleBlock, { TitleDescription } from '@anm/components/TitleBlock';
import { flexRow } from '@anm/styles/helpers';
import styled from 'styled-components';

export const SelectorWrapper = styled.a`
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  max-width: 388px;

  img {
    height: 205px;
  }

  &:hover {
    background: var(--blue_100_20);
  }
`;

export const Row = styled.div`
  gap: 12px;
  ${flexRow};
`;

export const CardTitle = styled(TitleBlock)`
  margin-top: 22px;
  text-align: center;
  h1,
  h2,
  h3,
  h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 8px;
  }
  ${TitleDescription} {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    max-width: 300px;
    margin: 0 auto;
  }
`;

export const Title = styled(TitleBlock)`
  margin-bottom: 40px;
  text-align: center;
  h1,
  h2,
  h3,
  h4 {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
  }
  ${TitleDescription} {
    font-size: 16px;
    line-height: 25px;
    margin-top: 8px;
  }
`;

export const Content = styled.div`
  padding: 40px 40px 80px;
`;
