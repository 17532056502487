import { FC } from 'react';

export type DefaultThumbProps = {
  width: number;
  height: number;
};

const DefaultThumb: FC<DefaultThumbProps> = ({ width, height }) => (
  <svg viewBox={`0 0 ${width} ${height}`}>
    <rect width={`${width}px`} height={`${height}px`} fill="none" />
  </svg>
);

export default DefaultThumb;
