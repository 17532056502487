import getFromCdn from '@anm/helpers/getFromCdn';
import React, { FC } from 'react';
import { Badge, MuteIcon, Wrapper } from './Wrapper';

type TapToUnmuteProps = {
  onClick: () => void;
  isHostingPlayer?: boolean;
};

const TapToUnmute: FC<TapToUnmuteProps> = ({ onClick }) => (
  <Wrapper onClick={onClick}>
    <Badge>
      <MuteIcon src={getFromCdn('images/icons/sound-off.svg')} />
      Tap to unmute
    </Badge>
  </Wrapper>
);

export default TapToUnmute;
