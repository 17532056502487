const formatIntNum = (num: number) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const formatFloatNum = (num: number) => {
  const [intPart, floatPart] = num.toString().split('.');
  return `${formatIntNum(+intPart)}.${floatPart}`;
};

export default (num: number) =>
  Number.isInteger(num) ? formatIntNum(num) : formatFloatNum(num);
