import Image from '@anm/components/image/Image';
import { FC } from 'react';

import { VerticalListItem } from './types';
import { ListItemWrapper } from './Wrapper';

const ListItem: FC<VerticalListItem> = ({ title, iconUrl, url, color }) => (
  <li key={title}>
    <ListItemWrapper href={url} {...{ color }}>
      {iconUrl && <Image src={iconUrl} alt={title} />}
      <span dangerouslySetInnerHTML={{ __html: title }} />
    </ListItemWrapper>
  </li>
);

export default ListItem;
