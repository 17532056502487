import { SliderArrowProps } from '.';
import SliderArrowWrapper from './SliderArrowWrapper';

const SliderArrow = (props: SliderArrowProps) => (
  <SliderArrowWrapper
    {...props}
    className="slider-custom-arrow"
    aria-label={`slider ${props.direction} arrow`}
  />
);

export default SliderArrow;
