import { FC } from 'react';
import { CSSProp } from 'styled-components';

import Wrapper, { Content } from './Wrapper';

export type ModalDirection =
  | 'to-left'
  | 'to-right'
  | 'to-left-bottom'
  | 'to-bottom';

type InfoBlockProps = {
  canShow: boolean;
  renderContent: () => JSX.Element;
  className?: string;
  styles?: CSSProp;
  direction?: ModalDirection;
};

const InfoBlock: FC<InfoBlockProps> = ({
  children,
  className,
  renderContent,
  ...restProps
}) => (
  <Wrapper className={className}>
    <Content {...restProps}>{renderContent()}</Content>
    {children}
  </Wrapper>
);

export default InfoBlock;
