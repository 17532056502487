import styled from 'styled-components';

export const A = styled.a`
  text-decoration-line: underline;
  color: #292a2b;
`;
export const Title = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #292a2b;
`;
export const Content = styled.div`
  margin-left: 15px;
`;
export const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #292a2b;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 15px;

  background: #fff4db;
  border: 1px solid #fcbd26;
  border-radius: 10px;
  padding: 15px 20px;
`;

export default Wrapper;
