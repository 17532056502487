import { useEffect, useState } from 'react';
import { TemplateItem } from '@anm/api/modules/templates';
import axios from 'axios';

type HookProps = {
  apiUrl: string;
  isOpenModal: boolean;
};

const useFetchTemplatesFormats = ({ apiUrl, isOpenModal }: HookProps) => {
  const [templates, setTemplates] = useState<TemplateItem[] | null>(null);

  useEffect(() => {
    if (!isOpenModal || !!templates) return;

    const params = { tags: 'ai', types: 'SERVICE' };

    (async () => {
      const { data } = await axios.get<{ content: TemplateItem[] }>(`${apiUrl}templates`, {
        params
      });

      setTemplates(data.content);
    })();
  }, [isOpenModal]);

  return templates;
};

export default useFetchTemplatesFormats;
