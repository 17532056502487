import { StockItem } from '@anm/api/modules/stocks';
import { Stocks, StockName } from '@anm/api/modules/subscription';
import { UserSubscriptionDetails } from '@anm/api/modules/user';
import { getPriceValue, getStockPrice } from '@anm/components/stocks/StockList';
import isMobile from '@anm/helpers/is/isMobile';

export const getBreakpointColumnsObj = () => ({
  default: isMobile() ? 1 : 4,
  1366: 3,
  1060: 2,
  860: 1
});

export const getStockPriceValue = (
  stock: StockItem,
  subscriptionDetails?: UserSubscriptionDetails[]
) => {
  const isVideo = stock.type === 'STOCK_VIDEO';
  const stockName = stock.service.toLowerCase().split('_')[0] as StockName;

  const stockFeature = (subscriptionDetails?.find(sub => sub.product === 'WAVE')
    ?.features as unknown) as Stocks;

  const stockPrice = getStockPrice(subscriptionDetails, stockFeature, {
    isVideo,
    stockName
  });
  const priceValue = getPriceValue(stockPrice);

  return priceValue;
};
