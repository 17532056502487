import noop from '@anm/helpers/noop';
import { useEffect, FC } from 'react';
import styled from 'styled-components';

import { ButtonColor, ButtonSize, VideoFormat } from '../EmbedButtonForm/data';

declare global {
  interface Window {
    waveconfig: any;
  }
}

export type EmbedButtonProps = {
  id?: string;
  size?: ButtonSize;
  color?: ButtonColor;
  format?: VideoFormat;
  disabled?: boolean;
  onError?: (error: string) => void;
  onPublish?: string;
} & ButtonServicesUrls;

type ButtonServicesUrls = {
  api: string;
  embed: string;
  editor: string;
  apiKey: string;
};

const ButtonWrapper = styled.span<{ disabled: boolean }>`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`;

const load = (apiKey: string) => {
  (function(d: Document, w: Window, e: string, c: any, a: string) {
    const cf: any = (w[c] = w[c] || {});
    cf.apiKey = a;
    const sc = d.createElement<any>(e)!;
    const s = d.getElementsByTagName(e)[0];
    sc.async = !0;
    sc.src = cf.embed || 'https://wave.video/editor/embed.js';
    s.parentNode!.insertBefore(sc, s);
  })(document, window, 'script', 'waveconfig', apiKey);
};

const EmbedButton: FC<EmbedButtonProps> = ({
  id,
  apiKey,
  size = 'large',
  color = 'default',
  format = 'youtube_horizontal',
  children: title = 'Make a video',
  disabled = false,
  onError = noop,
  onPublish,
  ...serviceUrls
}) => {
  useEffect(() => {
    if (!apiKey) onError('No API key');

    window.waveconfig = {
      apiKey,
      ...serviceUrls
    };
    load(apiKey);
  }, [apiKey]);

  return (
    <ButtonWrapper
      disabled={disabled}
      data-video-id={id}
      className={`wave-video-button ${size} ${color}`}
      data-video-format={format}
      data-publish-callback={onPublish}
      data-navigation-target="templates"
    >
      {title}
    </ButtonWrapper>
  );
};

export default EmbedButton;
