import { getParamsFromSearch } from '@anm/hooks/usePageParams';

type SearchParams = { noMics: boolean; noCams: boolean };

const getMediaDevices = async () => {
  const { noCams, noMics } = getParamsFromSearch<SearchParams>();

  return (await navigator.mediaDevices.enumerateDevices()).filter(
    ({ kind, deviceId }) =>
      deviceId && ((!noCams && kind === 'videoinput') || (!noMics && kind === 'audioinput') || kind === 'audiooutput')
  );
};

export default getMediaDevices;
