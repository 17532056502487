import { flexCenter, media } from '@anm/styles/helpers';
import styled from 'styled-components';

import SectionTitle from '../../../SectionTitle';

type CardProps = {
  itemsPerRow?: number;
};

const descriptionOnMobile = media.phone`
  font-size: 14px;
  line-height: 22px;
  min-height: auto;
`;

export const CardTitle = styled(SectionTitle)`
  padding: 20px 30px 36px;
  border: 1px solid rgba(1, 83, 130, 0.2);
  border-radius: 15px;
  flex: 1;
  &:not(:first-child) {
    border-top: 0 none;
    border-radius: 0 0 15px 15px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
    text-align: left;
  }
  p {
    margin: 0;
    font-size: 16px;
    text-align: left;
    line-height: 25px;
    font-weight: normal;
    ${descriptionOnMobile};
  }
`;

const CardWrapperMobile = media.phone<CardProps>`
  margin: 20px;
  min-width: 260px;
  width: 100%;
  &:nth-child(${({ itemsPerRow }) => `${itemsPerRow}n`}) {
    margin-right: 20px;
  }
`;

const CardWrapperTablet = media.tablet<CardProps>`
  width: calc(50% - 30px);
  margin: 15px;
  &:nth-child(${({ itemsPerRow }) => `${itemsPerRow}n`}) {
    margin-right: 15px;
  }
`;

export const CardWrapper = styled.a<CardProps>`
  display: flex;
  border-radius: 15px;
  margin: 0 40px 40px 0;
  flex-direction: column;
  width: ${({ itemsPerRow }) => `calc(100% / ${itemsPerRow} - 30px)`};
  box-shadow: 0 2px 10px rgba(1, 83, 130, 0.1);
  &:nth-of-type(${({ itemsPerRow }) => `${itemsPerRow}n`}) {
    margin-right: 0;
  }
  &:hover {
    background-color: rgba(32, 171, 210, 0.1);
  }

  ${CardWrapperTablet};
  ${CardWrapperMobile};
`;

export const ImageContainer = styled.div`
  ${flexCenter};
  overflow: hidden;
  border-radius: 15px 15px 0 0;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

export default ListWrapper;
