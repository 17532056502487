import Container from '@anm/components/Container';
import EmbedButtonBlock from '@anm/components/embed-wave-button/EmbedButtonBlock';
import { FC } from 'react';
import styled from 'styled-components';

import { CommonLandingProps } from '../../../features/getLanding';
import AnimatedWrapper from '../../Animated';
import SectionTitle from '../../SectionTitle';

export type CreateEmbedButtonProps = CommonLandingProps & {
  title: string;
  description: string;
};

const CreateEmbedButtonSection = styled.section`
  margin-bottom: 37px;
`;

const CreateEmbedButton: FC<CreateEmbedButtonProps> = ({
  title,
  description,
  headingLevel
}) => (
  <CreateEmbedButtonSection>
    <AnimatedWrapper variant="default" className="animated">
      <Container size={1000}>
        <SectionTitle
          level={headingLevel || 2}
          color="white"
          {...{ title, description }}
        />
        <EmbedButtonBlock {...{ headingLevel }} />
      </Container>
    </AnimatedWrapper>
  </CreateEmbedButtonSection>
);

export default CreateEmbedButton;
