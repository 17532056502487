import TitleBlock from '@anm/components/TitleBlock';
import Image from '@anm/components/image/Image';
import getFromCdn from '@anm/helpers/getFromCdn';
import { FC } from 'react';
import styled from 'styled-components';

export type CopyRightProps = {};

const Wrapper = styled.div`
  margin-top: 100px;
  max-width: 480px;
  padding: 30px 15px;
  display: flex;
  align-items: flex-start;

  > :first-child {
    margin-right: 15px;
  }

  background: #d9f2ff;
  border: 1px solid #30aef2;
  border-radius: 10px;
`;

const ImageWrapper = styled(Image)`
  width: 30px;
`;
const TitleBlockWrapper = styled(TitleBlock)`
  > * {
    line-height: 22px !important;
  }
`;

const CopyRight: FC<CopyRightProps> = () => (
  <Wrapper>
    <ImageWrapper src={getFromCdn('images/icons/media/guard.png')} />
    <TitleBlockWrapper
      level={4}
      size="super-small"
      title="Copyright disclaimer"
      description="By using this service, you confirm that this video belongs to you or that you have all the rights to download it."
    />
  </Wrapper>
);

export default CopyRight;
