import TitleBlockWrapper from '@anm/components/TitleBlock/Wrapper';
import { TooltipWrapper } from '@anm/components/Tooltip';
import IconButton from '@anm/components/buttons/IconButton/Wrapper';
import MicInput from '@anm/components/form/MicInput';
import TextFieldWrapper from '@anm/components/form/SelectInput/Wrapper';
import SwitchInputWrapper from '@anm/components/form/SwitchInput/Wrapper';
import { SelectOptionsContainer, SelectValueContainer } from '@anm/components/select/Select';
import { truncate } from '@anm/styles/helpers';
import styled from 'styled-components';

import CameraSettingsBlock from '../CameraSettingsBlock';
import TestSpeaker from '../TestSpeaker';

export const TestSpeakerWrapper = styled(TestSpeaker)`
  width: 100%;
`;

export const MediaTesterFormWrapper = styled.div`
  margin-bottom: 14px;
  width: 100%;
  ${TitleBlockWrapper} {
    margin-bottom: 7px;
  }
  ${TextFieldWrapper} {
    margin-bottom: 10px;
  }
  ${SelectValueContainer} {
    font-size: 14px;
  }
  ${SelectOptionsContainer} {
    font-size: 14px;
    top: unset;
    ${TooltipWrapper} {
      ${truncate('100%')};
    }
  }
  ${SwitchInputWrapper} {
    font-size: 14px;
  }
  ${IconButton} {
    font-size: 14px;
  }
`;

export const CameraSettingsBlockWrapper = styled(CameraSettingsBlock)`
  margin-bottom: 24px;
`;
export const MicInputWrapper = styled(MicInput)`
  ${TextFieldWrapper} {
    margin-bottom: 5px;
  }
`;
