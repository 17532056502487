import { FC } from 'react';
import styled from 'styled-components';

import { ButtonContainerProps } from '.';
import Button from './Button';

const Container = styled<FC<ButtonContainerProps>>(
  Button.withComponent('div')
)``;

export default Container;
