import { useCallback, useEffect, useState } from 'react';

import { NotificationBlockProps } from './types';

export const ANIMATION_TIME = 800;

export const useCanShowNotification = ({ timeout = 0, onModalClose }: Partial<NotificationBlockProps>) => {
  const [canShow, setCanShow] = useState(false);

  useEffect(() => {
    setCanShow(true);

    if (timeout === Infinity) return;

    // start closing animation
    const timer2 = setTimeout(() => {
      setCanShow(false);
    }, timeout);

    // remove notification
    const timer1 = setTimeout(() => {
      onModalClose?.();
    }, timeout + ANIMATION_TIME);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, [timeout]);

  const handleCloseNotification = useCallback(() => setCanShow(false), []);

  return [canShow, handleCloseNotification] as const;
};
