import getUTMQuery from '@anm/helpers/utm/getUTMQuery';
import { useEffect, useState } from 'react';

const useUTMQuery = () => {
  const [query, setQuery] = useState('');

  useEffect(() => {
    setQuery(getUTMQuery());
  });

  return query;
};

export default useUTMQuery;
