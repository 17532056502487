import useResize from '@anm/hooks/useResize';
import useSwitchState from '@anm/hooks/useSwitchState';
import { useCallback, useEffect, useRef } from 'react';

import { CalcMaxWidthProps } from './types';

export const useDropdownArrows = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isRightArrow, showRightArrow, hideRightArrow] = useSwitchState();
  const [isLeftArrow, showLeftArrow, hideLeftArrow] = useSwitchState();

  const dropdownContent = wrapperRef.current;

  const onResize = useCallback(() => {
    if (!dropdownContent) return;

    const { scrollWidth, offsetWidth, scrollLeft } = dropdownContent;

    const isScrolledToEnd = scrollWidth === offsetWidth + scrollLeft;

    isScrolledToEnd ? showLeftArrow() : hideLeftArrow();
    scrollLeft === 0 ? showRightArrow() : hideRightArrow();
  }, [dropdownContent?.scrollWidth]);

  useResize(onResize);

  useEffect(() => {
    if (!dropdownContent) return;
    onResize();

    dropdownContent?.addEventListener('scroll', onResize);

    return () => dropdownContent?.removeEventListener('scroll', onResize);
  }, [dropdownContent]);

  const onRightButtonClick = useCallback(() => {
    const left = dropdownContent?.scrollWidth;

    dropdownContent?.scrollTo({ left, behavior: 'smooth' });
  }, [dropdownContent?.scrollWidth]);

  const onLeftButtonClick = useCallback(() => {
    if (!dropdownContent) return;

    dropdownContent.scrollTo({ left: 0, behavior: 'smooth' });
  }, [dropdownContent]);

  return [
    wrapperRef,
    isLeftArrow,
    isRightArrow,
    onLeftButtonClick,
    onRightButtonClick
  ] as const;
};

export const useDropdownMaxWidth = ({
  wrapperRef,
  contentMaxWidth = 900
}: CalcMaxWidthProps) => {
  const [
    isDropdownContentBig,
    setIsDropdownContentBig,
    setIsDropdownContentSmall
  ] = useSwitchState();

  const calcDropdownWidth = () => {
    if (!wrapperRef.current) return;

    const { scrollWidth } = wrapperRef.current;

    const canSetMaxWidth =
      wrapperRef.current.getBoundingClientRect().left + scrollWidth >
      window.innerWidth;

    canSetMaxWidth ? setIsDropdownContentBig() : setIsDropdownContentSmall();
  };

  useResize(calcDropdownWidth);

  useEffect(() => {
    calcDropdownWidth();
  }, [wrapperRef.current]);

  const maxWidth = isDropdownContentBig ? `${contentMaxWidth}px` : 'unset';

  return [maxWidth, isDropdownContentBig] as const;
};
