import Image from '@anm/components/image/Image';
import { FC } from 'react';

import { Template } from '.';
import { ImageWrapper, LogoWrapper, TemplateLink } from './Wrapper';

const TemplatePageItem: FC<Template> = ({ href, logo, title, background }) => (
  <li>
    <TemplateLink {...{ href }}>
      <ImageWrapper>
        {background && <Image src={background} alt={title} />}
        {logo && (
          <LogoWrapper>
            <Image src={logo} alt={title} />
          </LogoWrapper>
        )}
      </ImageWrapper>
      <p>{title}</p>
    </TemplateLink>
  </li>
);

export default TemplatePageItem;
