import Avatar from '@anm/components/Avatar';
import Container from '@anm/components/Container';
import useSwitchState from '@anm/hooks/useSwitchState';
import { useEffect, FC } from 'react';

import AnimatedWrapper from '../../Animated';
import Background from '../../Background';
import FlexContainer from '../../FlexContainer';

import Wrapper, {
  AuthorInfo,
  AuthorWrapper,
  ContentWrapper,
  TextWrapper
} from './Wrapper';

export type CompanyQuoteProps = {
  text: string;
  avatar: string;
  username: string;
  description: string;
};

const CompanyQuote: FC<CompanyQuoteProps> = ({
  text,
  avatar,
  username,
  description
}) => {
  const breakpoint = 300;
  const quoteLength = text.replace(/<\/?[^>]+(>|$)/g, '').length;
  const [isLongText, setLongText] = useSwitchState();

  useEffect(() => {
    quoteLength >= breakpoint && setLongText();
  }, []);

  return (
    <Wrapper>
      <AnimatedWrapper variant="default" className="animated">
        <Background variant="company-quote">
          <Container size={1000}>
            <ContentWrapper>
              <FlexContainer variant="on-laptop">
                <AuthorWrapper>
                  <Avatar size={240} src={avatar} alt={username} />
                  {isLongText && (
                    <AuthorInfo center>
                      <div>{username}</div>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: description
                        }}
                      />
                    </AuthorInfo>
                  )}
                </AuthorWrapper>
                <TextWrapper>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: text
                    }}
                  />
                  {!isLongText && (
                    <AuthorInfo>
                      <div>{username}</div>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: description
                        }}
                      />
                    </AuthorInfo>
                  )}
                </TextWrapper>
              </FlexContainer>
            </ContentWrapper>
          </Container>
        </Background>
      </AnimatedWrapper>
    </Wrapper>
  );
};

export default CompanyQuote;
