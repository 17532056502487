import { useEffect } from 'react';

const useRedirect = () => {
  useEffect(() => {
    const { pathname } = location;

    const canRedirect = [
      '/button',
      '/button/examples',
      '/button/documentation',
      '/landings/button',
      '/landings/button/examples',
      '/landings/button/documentation'
    ].includes(pathname);

    if (canRedirect) location.href = '/';
  }, []);
};

export default useRedirect;
