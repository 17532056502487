import { NotificationPosition, NotificationVariant } from '@anm/components/NotificationBlock';
import styled, { css } from 'styled-components';

type ContentProps = {
  variant?: NotificationVariant;
  position?: NotificationPosition;
};

const getContentStyles = ({ variant, position }: ContentProps) =>
  ({
    aside: css`
      top: 95px;
      right: 500px;
      width: 100%;
      flex-direction: column;
      align-items: flex-end;
    `,
    highlight: css``,
    custom: css`
      position: absolute;
      top: ${position?.top || '0px'};
      left: ${position?.left || '0px'};
      transform: translateX(calc(-${position?.translateX || '0px'} / 2 - 50%));
    `
  }[variant || 'aside']);

const Content = styled.div<ContentProps>`
  display: flex;
  overflow: visible;
  pointer-events: none;

  ${props => getContentStyles(props)}
`;

export default Content;
