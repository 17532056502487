import { useCallback, useState, FC } from 'react';
import styled from 'styled-components';

import ActionItem, { ActionItemProps } from './ActionItem';

export type ActionsListProps = Pick<ActionItemProps, 'onClick' | 'isPending' | 'canEdit'> & { list: ActionItemProps[] };

const ActionsContainer = styled.div`
  margin-top: 15px;

  > *:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const ActionsList: FC<ActionsListProps> = ({ list, canEdit, isPending, onClick }) => {
  const [currentIntent, setCurrentIntent] = useState<string>();
  const handleOnClick = useCallback(
    (intent: string) => {
      onClick?.(intent);
      setCurrentIntent(intent);
    },
    [onClick]
  );

  return (
    <ActionsContainer>
      {list.map(props => (
        <ActionItem
          key={props.title}
          canEdit={canEdit}
          isPending={isPending && currentIntent === props.intent}
          onClick={handleOnClick}
          {...props}
        />
      ))}
    </ActionsContainer>
  );
};

export default ActionsList;
