import { LabelText } from '@anm/components/form/RadioButton/Wrapper';
import styled from 'styled-components';

type BillingPeriodButtonProps = { isChecked: boolean; disabled?: boolean };

export const BillingPeriodButton = styled.div<BillingPeriodButtonProps>`
  flex: 1 1 240px;
  margin: 10px;

  ${LabelText} {
    position: relative;
    padding: 15px;
    text-align: left;
    line-height: 1;
    user-select: none;

    border: 1px solid;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    background-color: ${({ isChecked }) => `${isChecked ? '#e9f8ff' : '#fff'}`};
    border-color: ${({ isChecked }) => `${isChecked ? '#cbdce5' : '#dedede'}`};
    color: ${({ disabled }) => `${disabled ? '#a0a0a7' : '#292a2b'}`};
    cursor: ${({ disabled }) => `${disabled ? 'default' : 'pointer'}`};

    &:after {
      top: 20px;
      left: 20px;
    }
  }
`;
