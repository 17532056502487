import withPosition, { PositionProps } from '@anm/HOCs/withPosition';
import ViewSwitcher from '@anm/components/ViewSwitcher';
import { HeaderNavigationItem, RenderNavLinkProp } from '@anm/components/header';
import { FC } from 'react';
import { compose, withState } from 'recompose';

import MobileNavigationCollapsedLinks from './MobileNavigationCollapsedLinks';
import MobileNavigationMainList from './MobileNavigationMainList';
import MobileNavigationUserMenu from './MobileNavigationUserMenu';
import MobileNavigationWrapper, { IconClose } from './Wrapper';
import { Logo, UserList, UserMenuBlock } from './types';

type CurrentNestedListState = {
  currentNestedList: HeaderNavigationItem | null;
  setCurrentNestedList(currentNestedList: HeaderNavigationItem): void;
};
type HOCs = PositionProps & CurrentNestedListState;
export type MobileNavigationProps = UserMenuBlock &
  Logo &
  RenderNavLinkProp &
  UserList & {
    list: HeaderNavigationItem[];
    onModalClose(): void;
  };

const MAIN_LIST_STEP = 0;
const NESTED_LIST_STEP = 1;
const USER_MENU_STEP = 2;

const MobileNavigation: FC<MobileNavigationProps & HOCs> = ({
  list: navList,
  position,
  renderLogo,
  onModalClose,
  renderNavLink,
  renderUserMenu,
  currentNestedList,
  renderUserLinkList,
  setCurrentNestedList
}) => (
  <MobileNavigationWrapper>
    <IconClose onClick={onModalClose} />
    <ViewSwitcher active={position.current}>
      <MobileNavigationMainList
        list={navList}
        {...{ renderNavLink, renderLogo }}
        onUserBlockClick={() => position.next(USER_MENU_STEP)}
        onNestedClick={item => {
          setCurrentNestedList(item);
          position.next(NESTED_LIST_STEP);
        }}
      >
        {renderUserMenu(() => position.next(USER_MENU_STEP))}
      </MobileNavigationMainList>
      <>
        {currentNestedList && (
          <MobileNavigationCollapsedLinks
            {...{ ...currentNestedList, renderNavLink }}
            onBack={() => position.next(MAIN_LIST_STEP)}
          />
        )}
      </>
      <MobileNavigationUserMenu onBack={() => position.next(MAIN_LIST_STEP)} {...{ renderUserLinkList }} />
    </ViewSwitcher>
  </MobileNavigationWrapper>
);

export default compose<HOCs, MobileNavigationProps>(
  withState('currentNestedList', 'setCurrentNestedList', null),
  withPosition()
)(MobileNavigation);
