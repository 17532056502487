import Button, {
  ButtonProps,
  ButtonSize,
  ButtonVariants
} from '@anm/components/buttons/Button';
import {
  ClickLinkProps,
  HrefLinkProps,
  NavigationLinkProps
} from '@anm/components/header';
import { memo, FC } from 'react';
import { branch, compose, renderComponent } from 'recompose';

import { StyledButtonLink } from './Wrapper';

const NavigationLink: FC<NavigationLinkProps> = ({
  rel,
  href,
  title,
  target = '_self'
}) => <a {...{ rel, href, target }}>{title}</a>;

export const isButtonLink = (link: ClickLinkProps): link is ClickLinkProps =>
  (link as ClickLinkProps).onClick !== undefined;

const ButtonLink: FC<ButtonProps> = memo(
  ({
    title,
    size = 'medium' as ButtonSize,
    variant = 'link' as ButtonVariants,
    ...rest
  }) => (
    <StyledButtonLink {...{ size, variant, ...rest }}>{title}</StyledButtonLink>
  )
);

export const isHrefLink = (link: NavigationLinkProps) => !!link.href;

const HrefLink: FC<HrefLinkProps &
  NavigationLinkProps &
  ButtonProps> = memo(
  ({
    rel,
    href,
    title,
    size = 'medium' as ButtonSize,
    target = '_self',
    variant = 'link' as ButtonVariants
  }) => <Button {...{ rel, href, size, variant, target }}>{title}</Button>
);

export default compose<{}, NavigationLinkProps | ClickLinkProps>(
  branch(isHrefLink, renderComponent(HrefLink)),
  branch(isButtonLink, renderComponent(ButtonLink))
)(NavigationLink);
