import Container from '@anm/components/Container';
import EmbedButton from '@anm/components/embed-wave-button/EmbedButton';
import { VideoFormat } from '@anm/components/embed-wave-button/EmbedButtonForm/data';
import { useEffect, useState, FC } from 'react';

import config from '../../../../config';

import {
  EmbedButtonError,
  RatioContainer,
  RatioContainerContent,
  Video,
  Wrapper
} from './Wrapper';

export type ButtonDemoProps = {
  format?: VideoFormat;
};

export type DemoVariant = 'static' | 'demo1' | 'demo2';

declare global {
  interface Window {
    onWavePublish: any;
  }
}

type Result = {
  videoId: string;
  videoUrl: string;
};

const { waveButtonApiKey, apiUrl, editorUrl, buttonEmbedUrl, waveUrl } = config;

const buttonServiceUrls = {
  api: apiUrl.replace(/\/$/, ''),
  site: waveUrl,
  embed: buttonEmbedUrl,
  editor: editorUrl,
  apiKey: waveButtonApiKey
};

const ButtonDemo: FC<ButtonDemoProps> = props => {
  const [error, setError] = useState<string>();
  const [videoId, setVideoId] = useState<string>();
  const [videoUrl, setVideoUrl] = useState<string>();

  useEffect(() => {
    window.onWavePublish = (_: any, result: Result, error: string) => {
      if (error) {
        setError(error);
        return;
      }

      result.videoId && setVideoId(result.videoId);
      result.videoUrl && setVideoUrl(result.videoUrl);
    };
  }, []);

  return (
    <Wrapper {...props} filled={!!videoUrl}>
      <Container>
        <RatioContainer>
          <RatioContainerContent>
            {videoUrl && <Video src={videoUrl}></Video>}
            <EmbedButton
              id={videoId || ''}
              format={props.format}
              onPublish="onWavePublish"
              {...buttonServiceUrls}
            >
              {videoUrl ? 'Edit video' : 'Make video'}
            </EmbedButton>
            {error && <EmbedButtonError>{error}</EmbedButtonError>}
          </RatioContainerContent>
        </RatioContainer>
      </Container>
    </Wrapper>
  );
};

export default ButtonDemo;
