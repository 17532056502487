import { useEffect } from 'react';

const useTrackEnded = (stream: MediaStream | undefined | null, cb: () => void) => {
  useEffect(() => {
    const videoTrack = stream?.getVideoTracks()[0];
    const audioTrack = stream?.getAudioTracks()[0];
    const track = videoTrack || audioTrack;
    track?.addEventListener('ended', cb);

    return () => track?.removeEventListener('ended', cb);
  }, [stream?.id]);
};

export default useTrackEnded;
