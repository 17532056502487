import getFromCdn from '@anm/helpers/getFromCdn';
import { flexColumn, flexRow, media } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

import { ItemProps } from './types';

const iconStyles = css<{ icon: string }>`
  background: ${({ icon }) => `url(${getFromCdn(icon)}) 6px center no-repeat transparent`};
`;

export const Title = styled.li`
  margin: 0;
  max-width: 50%;
  font-size: 16px;
  line-height: 36px;
  font-weight: bold;
  padding-left: 6px;
  margin-bottom: 7px;
  color: rgb(41, 42, 43);
`;

const desktopOnlyTagStyles = css`
  right: 0;
  display: block;
  font-size: 12px;
  position: absolute;
  padding: 0px 7px;
  top: calc(50% - 10px);
  color: rgb(145, 158, 170);
  line-height: 20px;
  border-radius: 5px;
  width: fit-content;
  white-space: nowrap;
  margin-right: 10px;
  background: rgb(245, 247, 250);
  content: 'Desktop only';
`;

const itemMobileStyles = media.phone<ItemProps>`
  width: unset;
  max-width: unset;
  color: ${({ theme, isDesktopOnly }) => (isDesktopOnly ? 'rgb(145, 158, 170)' : theme.textColor)};

  &:after {
    ${({ isDesktopOnly }) => isDesktopOnly && desktopOnlyTagStyles}
  }
`;

export const NativeLink = styled.a``;

export const ListItemWrapper = styled.li<ItemProps>`
  width: 50%;
  max-width: 50%;
  height: 52px;
  position: relative;
  border-radius: 5px;
  padding-left: 62px;
  padding-right: 30px;
  line-height: 52px;
  text-align: start;
  color: ${({ theme }) => theme.textColor};
  ${({ icon }) => icon && iconStyles};

  div {
    text-align: start;
  }

  &&& a {
    left: 0;
    width: 100%;
    position: absolute;
    background: none;
    text-align: start;
    line-height: inherit;
    padding-left: 62px;
  }

  &:hover {
    color: var(--blue_100);
    background-color: rgba(9, 188, 228, 0.1);
    a {
      color: var(--blue_100);
    }
  }

  ${itemMobileStyles};
`;

const mobileListStyles = media.phone`
  ${flexColumn};
  width: inherit;
  padding: 0 10px 5px 10px;
`;

export const ListWrapper = styled.ul`
  ${flexRow};
  width: 470px;
  padding: 10px 20px;
  flex-wrap: wrap;

  ${mobileListStyles};
`;
