import StorageErrorModal from '@anm/components/StorageErrorModal';
import useErrorState from '@anm/hooks/useErrorState';
import { useContext, FC } from 'react';

import MediaDownloader from '..';
import Error from '../Error';
import OpenEditorModal from '../OpenEditorModal';

import { MediaDownloaderContext } from './MediaDownloaderProvider';
import getPreview from './helpers/getPreview';
import useOpenEditorModalControls from './hooks/useOpenEditorModalControls';

type MediaDownloaderConsumerProps = {
  editorUrl: string;
  youtubeHint?: boolean;
};

const MediaDownloaderConsumer: FC<MediaDownloaderConsumerProps> = ({ editorUrl, youtubeHint }) => {
  const {
    getMediaInfo,
    startDownload,
    mediaInfoState,
    error,
    formatsOptions,
    uploadState,
    changeMediaType,
    changeUrl,
    mediaType,
    url
  } = useContext(MediaDownloaderContext);
  const [isStorageError, { hideError: closeStorageError }] = useErrorState(() => uploadState.error?.code === 507);
  const [{ isOpen: isModalOpen }, { changeIntent, closeModal, cancelOpenEditor }] = useOpenEditorModalControls(
    editorUrl
  );
  const info = mediaInfoState.data?.info;

  return (
    <div>
      <MediaDownloader
        url={url}
        changeUrl={changeUrl}
        mediaType={mediaType}
        title={info?.title}
        preview={info && getPreview(info)}
        options={formatsOptions}
        duration={info?.duration}
        isOpenEditorPending={uploadState.isPending}
        onUrlEnter={getMediaInfo}
        onChangeIntent={changeIntent}
        onChangeMediaType={changeMediaType}
        startDownload={startDownload}
        youtubeHint={youtubeHint}
        error={error}
      />
      {isModalOpen && <OpenEditorModal mediaType={mediaType} onModalClose={closeModal} onCancel={cancelOpenEditor} />}
      {error && <Error error={error} />}
      {isStorageError && <StorageErrorModal actionType="upload" onClose={closeStorageError} />}
      {/*<AdBlock/>*/}
    </div>
  );
};

export default MediaDownloaderConsumer;
