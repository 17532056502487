import styled, { css } from 'styled-components';

import TitleBlock, { TitleDescription } from '../TitleBlock';
import Image from '../image/Image';

type RowProps = {
  isActive: boolean;
  height: number;
};

const activeIconStyles = css`
  transform: rotate(180deg);
`;

export const Row = styled.div<RowProps>`
  cursor: pointer;
  overflow: hidden;
  position: relative;
  padding: 22px 53px 22px 20px;
  transition: height 0.4s ease-in-out;
  height: ${({ height }) => height}px;
  border-bottom: 1px solid rgba(203, 220, 229, 0.5);
  img {
    ${({ isActive }) => isActive && activeIconStyles}
  }
`;

export const Title = styled(TitleBlock)`
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 20px;
    line-height: 32px;
    font-weight: bold;
  }
  ${TitleDescription} {
    margin: 0;
    font-size: 16px;
    margin-top: 15px;
    overflow: hidden;
    line-height: 25px;
    transition: height 0.4s ease-in-out;
  }
`;

export const ArrowIcon = styled(Image)`
  top: 33px;
  right: 18px;
  cursor: pointer;
  min-width: 18px;
  position: absolute;
`;

const Wrapper = styled.div`
  border-top: 1px solid rgba(203, 220, 229, 0.5);
`;

export default Wrapper;
