const memoizeLast = <T extends (...args: any[]) => any>(func: T) => {
  if (typeof func !== 'function') {
    throw new TypeError('Expected a function');
  }

  let result: ReturnType<typeof func>;
  let lastKey: string;

  return (...args: any[]) => {
    const key = args[0];

    if (lastKey === key) {
      return result;
    }

    result = func(...args);
    lastKey = key;

    return result;
  };
};

export default memoizeLast;
