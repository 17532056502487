import browserStore from '@anm/helpers/browserStore';
import isServer from '@anm/helpers/is/isServer';
import { getParamsFromSearch } from '@anm/hooks/usePageParams';

import { ClientCommand, TSemrush } from './types';

declare global {
  interface Window {
    SM: TSemrush;
  }
}

export const isSemrushIframe = () => !isServer() && window.name === 'semrush_iframe';

export const isSemrushIframeWithProps = () => {
  const { isSemrush } = getParamsFromSearch<{ isSemrush?: string }>();

  return isSemrush === 'true' ? true : isSemrushIframe();
};

export const pushUrl = (url: string) => window.SM.client(ClientCommand.pushUrl, url);

export const replaceUrl = (url: string) => window.SM.client(ClientCommand.replaceUrl, url);

export const getAccessToken = (): Promise<string> => window.SM.client(ClientCommand.getAccessToken);

export const requestPurchase = () => {
  const semrushAppId = getAppId();
  console.log('requestInAppPurchase ', semrushAppId, window.SM);
  semrushAppId && window.SM.client(ClientCommand.requestInAppPurchase, [semrushAppId]);
};

export const load = () =>
  new Promise(resolve => {
    /* tslint:disable */
    (function(d: any, e: any, v: any, t?: any, s?: any) {
      t = d.createElement(e);
      t.type = 'text/javascript';
      t.async = true;
      t.src = v;
      t.onload = function() {
        resolve('OK');
      };
      s = d.getElementsByTagName('body')[0];
      s.append(t);
    })(document, 'script', 'https://static.semrush.com/app-center/sdk.js');
  });

export const init = async (appId?: string) => {
  appId && setAppId(appId);

  await window.SM.init();

  console.log('Semrush configs ', window.SM.config);
};

const AppIdKey = 'SemrushAppId';

const setAppId = (appId: string) => browserStore.set(AppIdKey, appId);

const getAppId = () => browserStore.get<string>(AppIdKey);
