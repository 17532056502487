import Image from '@anm/components/image/Image';
import { FC } from 'react';

import TopSectionMediaWrapper from '../TopSectionMediaWrapper';

import VideoModal from './VideoModal';

type MediaProps = {
  title: string;
  rotateImage: boolean;
  videoId?: string;
  imageUrl?: string;
  isMobileFrame?: boolean;
  imageMaxWidth?: number;
  sectionPicture?: string;
};

const Media: FC<MediaProps> = ({
  title,
  videoId,
  imageUrl,
  isMobileFrame,
  ...wrapperProps
}) => (
  <TopSectionMediaWrapper {...wrapperProps}>
    {videoId && <VideoModal {...{ videoId, isMobileFrame }} />}
    {imageUrl && <Image src={imageUrl} alt={title} />}
  </TopSectionMediaWrapper>
);

export default Media;
