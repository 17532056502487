import { ListItem } from '@anm/components/Accordion';
import { FC } from 'react';

import Media from '../Media';

import { Title } from './Wrapper';

type SlideProps = ListItem & {
  headingLevel?: number;
};

const Slide: FC<SlideProps> = ({
  title,
  description,
  headingLevel,
  ...mediaProps
}) => (
  <>
    <Media {...mediaProps} />
    <Title {...{ title, description }} color="black" level={headingLevel} />
  </>
);

export default Slide;
