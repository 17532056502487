import getFromCdn from '@anm/helpers/getFromCdn';
import isMobileOrIPad from '@anm/helpers/is/isMobileOrIPad';
import { media } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

type ActionItemProps = {
  icon?: string;
  hasIcon?: boolean;
  disabled?: boolean;
  isBigIcon?: boolean;
  showDivider?: boolean;
  isDesktopOnly?: boolean;
};

const disabledItem = css`
  cursor: default;

  && a,
  && span {
    opacity: 0.4;

    &:hover {
      background-color: unset;
    }
  }
`;

const desktopOnlyLabelStyles = css`
  &:after {
    display: block;
    color: #919eaa;
    padding: 0 7px;
    font-size: 12px;
    line-height: 20px;
    border-radius: 5px;
    white-space: nowrap;
    margin-right: 10px;
    background: #f5f7fa;
    content: 'Desktop only';
  }
`;

const withDividerStyles = css`
  display: block;

  &:after {
    display: block;
    content: '';
    width: 100%;
    background-color: #ccdde6;
    height: 1px;
    margin: 4px 0;
  }
`;

const noDividerStyles = css`
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: space-between;
`;

const bigActionItemOnMobileOrTablet = media.tablet`
  && {
    a,
    > span,
    label > span {
      padding-left: 65px;
    }

   a > span {
      padding-left: 0;
    }
  }
`;

const actionIconStyle = css<ActionItemProps>`
  background: url(${({ icon }) => icon}) no-repeat 15px center transparent;
`;

const ActionItemWrapper = styled.li<ActionItemProps>`
  ${({ icon }) => icon && actionIconStyle};
  ${({ showDivider }) => (showDivider ? withDividerStyles : noDividerStyles)};

  label {
    width: 100%;
  }

  && a > span {
    padding-left: 18px;

    &:hover {
      background-color: unset;
    }
  }

  ${({ isBigIcon }) => isBigIcon && bigActionItemOnMobileOrTablet}

  ${({ isDesktopOnly }) => isDesktopOnly && isMobileOrIPad() && desktopOnlyLabelStyles}

  ${({ disabled }) => disabled && disabledItem}
`;

export const ActionItemLink = styled.a<ActionItemProps>`
  margin: 0;
  && {
    padding-left: ${({ hasIcon }) => (hasIcon ? 48 : 10)}px;
  }
  padding-right: ${isMobileOrIPad() ? '20px !important' : 'inherit'};
`;

export const Crown = styled.div`
  width: 20px;
  height: 20px;
  background: url(${getFromCdn('images/icons/crown.svg')}) no-repeat center / cover;
`;

export default ActionItemWrapper;
