import Container from '@anm/components/Container';
import Tabs, { TabsProps, TabItem } from '@anm/components/Tabs';
import { FC } from 'react';

import lfConfig from '../../../config';
import { CommonLandingProps } from '../../../features/getLanding';
import { SectionTitleProps } from '../../SectionTitle';
import TopSectionTitle from '../TopSection/TopSectionTitle';

import TabsSectionWrapper from './Wrapper';

export type TabsSectionProps = CommonLandingProps & TabsProps & SectionTitleProps;

const { nextDevMode } = lfConfig;

const getRoute = (route: string) => (nextDevMode ? route.replace('', '/landings') : route);

const mapTabRoute = (list: TabItem[]) =>
  list.map(({ route, ...otherProps }) => ({
    ...otherProps,
    route: getRoute(route)
  }));

const TabsSection: FC<TabsSectionProps> = ({ list, title, activeTab, headingLevel }) => (
  <TabsSectionWrapper>
    <Container size={1000}>
      {title && <TopSectionTitle color="black" level={headingLevel} {...{ title }} />}
      <Tabs list={mapTabRoute(list)} {...{ activeTab }} />
    </Container>
  </TabsSectionWrapper>
);

export default TabsSection;
