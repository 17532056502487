import isEmpty from 'lodash/fp/isEmpty';

const oneMinute = 60 * 1000;
const oneHour = oneMinute * 60;
const oneDay = oneHour * 24;
const timeValues = [oneDay, oneHour, oneMinute];

const timeListToString = (list: number[]) => list.map(time => (time ? timeToString(time) : '00')).join(':');

export const timeToString = (time: number) => (time < 10 ? `0${time}` : time.toString());

const findTimeRange = (list: number[]) =>
  list.reduce((acc, time) => (time || !isEmpty(acc) ? [...acc, time] : acc), []);

const getTimeListInMilliseconds = (timeStamp: number) =>
  timeValues.reduce((acc, timeValue, index) => [...acc, acc[index] % timeValue], [timeStamp]);

const getTimeList = (timeStampList: number[]) =>
  timeStampList.reduce((acc, timeStamp, index) => [...acc, Math.floor(timeStamp / (timeValues[index] || 1000))], []);

type FormatTimeSettings = { onlyMin?: boolean };

const formatTime = (timeInSeconds: number, { onlyMin }: FormatTimeSettings = {}) => {
  if (timeInSeconds <= 0) return '00:00';

  const timeStamp = timeInSeconds * 1000;
  const timeInMilliseconds = getTimeListInMilliseconds(timeStamp);
  const timeList = getTimeList(timeInMilliseconds);
  const [firstTime = 1, ...otherTimeList] = findTimeRange(timeList);

  return onlyMin && isEmpty(otherTimeList)
    ? '0'
    : isEmpty(otherTimeList)
    ? `00:${timeToString(firstTime)}`
    : onlyMin
    ? `${firstTime}`
    : `${firstTime}:${timeListToString(otherTimeList)}`;
};

export default formatTime;
