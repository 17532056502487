import Image from '@anm/components/image/Image';
import { FC } from 'react';

import EnvHostingPlayer from '../../EnvHostingPlayer';
import WavePlayerModal from '../../WavePlayerModal';

import { Media } from '.';
import { MediaWrapper } from './Wrapper';

type MediaProps = Media & {
  title: string;
};

type MediaModalProps = {
  videoId: string;
};

const Modal: FC<MediaModalProps> = ({ videoId }) => (
  <MediaWrapper>
    <WavePlayerModal {...{ videoId }} playIconVariant="black" />
  </MediaWrapper>
);

const MediaContainer = ({ videoId, imgSrc, title }: MediaProps) => {
  if (videoId) {
    return <Modal {...{ videoId, imgSrc, title }} />;
  }

  if (imgSrc) {
    return <Image src={`${imgSrc}`} alt={title} />;
  }

  return <EnvHostingPlayer videoId={videoId!} />;
};

export default MediaContainer;
