import Container from '@anm/components/Container';
import React, { FC } from 'react';

import { ListItem, SimpleLinkListWrapper } from './Wrapper';

export type SimpleLinkListItem = {
  title: string;
  href: string;
};

export type ListProps = {
  list: SimpleLinkListItem[];
};

export type SimpleLinkListProps = ListProps & {
  containerSize?: number;
};

const SimpleLinkList: FC<SimpleLinkListProps> = ({ list, containerSize }) => (
  <section>
    <Container size={containerSize || 1150}>
      <SimpleLinkListWrapper>
        {list.map(({ title, href }) => (
          <ListItem key={`${title}-${href}`}>
            {
              <a href={href}>
                <span dangerouslySetInnerHTML={{ __html: title }} />
              </a>
            }
          </ListItem>
        ))}
      </SimpleLinkListWrapper>
    </Container>
  </section>
);

export default SimpleLinkList;
