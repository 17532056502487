import { SelectOptionsContainer } from '@anm/components/select/Select';
import { flexCenter } from '@anm/styles/helpers';
import styled from 'styled-components';

import Card from '../../Card';
import TitleBlock from '../../TitleBlock';
import { TooltipContent } from '../../Tooltip/Wrapper';

export const CardForm = styled(Card)`
  flex: 1;
  min-width: 330px;
  border-radius: 10px;
  padding: 22px 30px 25px;
  border: 1px solid #ccdde6;
  box-shadow: 0 2px 10px rgba(1, 83, 130, 0.1);
  ${SelectOptionsContainer} {
    overflow: auto;
    max-height: 200px;
    top: calc(100% + 10px);
  }
  & > * {
    margin-bottom: 20px;
    width: 100%;
  }
  input {
    margin: 0;
  }
  button {
    margin: 10px 0 0;
  }
`;

export const EmbedButtonFormTitle = styled(TitleBlock)`
  margin-bottom: 10px;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 20px;
    line-height: 32px;
  }
`;

export const NameFieldWithToolTipWrapper = styled.div`
  position: relative;
  ${TooltipContent} {
    left: auto;
    width: 220px;
  }
`;

export const TooltipLink = styled.a`
  color: var(--blue_100);
  text-decoration: underline;
`;

export const NameFieldTooltipWrapper = styled.div`
  ${flexCenter};
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  z-index: 10;
  text-align: right;
`;
