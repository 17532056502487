import IconPlay, {
  IconPlayBehavior,
  IconPlayVariants
} from '@anm/components/IconPlay';
import Image from '@anm/components/image/Image';
import resizeWithAspectRatio from '@anm/helpers/size/resizeWithAspectRatio';
import { Component } from 'react';

import withModalControls, {
  WithModalControlsProps
} from '../../../HOCs/withModalControls';
import Modal from '../../modals/Modal';

import LandingVideoWrapper from './LandingVideoWrapper';
export { LandingVideoWrapper };

declare global {
  interface Window {
    _wq: any;
  }
}

export type ModalVideoProps = {
  wistiaId: string;
  iconText: string;
  playIconUrl?: string;
  mobileWistiaId?: string;
};

type LandingVideoState = {
  mobileScreen: boolean;
  modalWidth?: number;
  modalHeight?: number;
  isPlayerReady?: boolean;
};

export type LandingVideoProps = WithModalControlsProps & {
  time?: number;
  alt?: string;
  muted?: boolean;
  wistiaId: string;
  iconText?: string;
  preview?: string;
  duration?: string;
  isMobile?: boolean;
  isShadow?: boolean;
  autoplay?: boolean;
  imageUrl?: string;
  playIconUrl?: string;
  openInModal?: boolean;
  autoModalOpen?: boolean;
  withIconPlay?: boolean;
  alwaysInModal?: boolean;
  mobileWistiaId?: string;
  scrollCentredOn?: number;
  iconPlayVariant?: IconPlayVariants;
  iconPlayBehavior?: IconPlayBehavior;
  imageIconPlayBehavior?: IconPlayBehavior;
  videoTimeChange?: any;
  transparentPlayIcon?: boolean;
};

class LandingVideo extends Component<LandingVideoProps, LandingVideoState> {
  state = {
    mobileScreen: false,
    isPlayerReady: false,
    modalWidth: 0,
    modalHeight: 0
  };

  videoInstance: any;

  componentDidMount = () => {
    this.setupPlayer();

    this.setState({
      mobileScreen: !(window.innerWidth >= 768)
    });

    const {
      openModal,
      autoModalOpen,
      openInModal = false,
      alwaysInModal = false
    } = this.props;

    let { autoplay } = this.props;
    const muted =
      alwaysInModal || (openInModal && window.innerWidth >= 768)
        ? false
        : this.props.muted;

    autoplay = openInModal || alwaysInModal || autoplay;

    this.initPlayer(autoplay, muted, openInModal, alwaysInModal);

    if (autoModalOpen) openModal();
  };

  setupPlayer = () => {
    if (!document.getElementById('wistia_script')) {
      const wistiaScript = document.createElement('script');
      wistiaScript.id = 'wistia_script';
      wistiaScript.type = 'text/javascript';
      wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';
      wistiaScript.async = true;
      document.body.appendChild(wistiaScript);
    }
  };

  initPlayer = (
    autoplay = false,
    muted = true,
    playInModal?: boolean,
    alwaysInModal?: boolean
  ) => {
    window._wq = window._wq || [];
    window._wq.push({
      id: this.props.wistiaId,
      options: {
        muted,
        autoPlay: autoplay,
        silentAutoPlay: 'allow',
        videoFoam: true
      },
      onReady: (video: any) => {
        this.videoReadyCallback(video, playInModal, alwaysInModal);
      }
    });
  };

  videoReadyCallback = (
    video: any,
    playInModal?: boolean,
    alwaysInModal?: boolean
  ) => {
    if (this.props.videoTimeChange) {
      this.videoInstance = video;
      video.bind('secondchange', (s: number) => {
        this.props.videoTimeChange(s);
      });
    }

    video.bind('play', () => {
      const videoLayer = document.querySelector(
        '#modal-root .w-video-wrapper.w-css-reset'
      );
      if (!videoLayer) return null;

      videoLayer.setAttribute('style', 'background: #fff');

      return video.unbind;
    });

    ((playInModal && !this.state.mobileScreen) || alwaysInModal) &&
      this.playInModal(video);
  };

  playInModal = (video: any) => {
    const maxWidth = window.innerWidth / 1.4;
    const maxHeight = window.innerHeight / 1.2;

    const VideoWidth = video.width();
    const VideoHeight = video.height();

    const { width, height } = resizeWithAspectRatio(
      VideoWidth,
      VideoHeight,
      maxWidth,
      maxHeight,
      true,
      true
    );

    const modalPadding = this.state.mobileScreen ? 0 : 20;

    this.setState({
      modalWidth: width,
      modalHeight: height,
      isPlayerReady: true
    });

    // modalHeight correction for 16:9 ratio
    if (this.state.modalWidth / this.state.modalHeight >= 1.7) {
      const oldHeight = this.state.modalHeight;
      this.setState({
        modalHeight: oldHeight + 8
      });
    }

    video.width(this.state.modalWidth - modalPadding);
    video.height(this.state.modalHeight - modalPadding);
  };

  componentDidUpdate(prevProps: any) {
    if (
      this.props.time === prevProps.time ||
      (this.props.time && Math.abs(this.props.time - prevProps.time) === 1)
    ) {
      return;
    }

    if (this.videoInstance) {
      this.videoInstance.time(this.props.time);
    }
  }

  render() {
    const {
      alt,
      wistiaId,
      imageUrl,
      iconText,
      playIconUrl,
      isModalOpened,
      transparentPlayIcon,
      imageIconPlayBehavior,
      withIconPlay = false,
      openInModal = false,
      alwaysInModal = false,
      iconPlayBehavior = 'hover-scale'
    } = this.props;

    const { mobileScreen } = this.state;

    // TODO: should be refactored for all video player variants
    const shouldDisplayIconPlay = (withIconPlay && !mobileScreen) || iconText;
    const shouldDisplayPreview = !mobileScreen && openInModal;
    const shouldDisplayInModal =
      (openInModal && !mobileScreen) || alwaysInModal;

    return (
      <LandingVideoWrapper imageUrl={imageUrl} withIconText={iconText}>
        {shouldDisplayIconPlay && (
          <>
            <IconPlay
              size="80x80"
              behavior={iconPlayBehavior}
              transparentPlayIcon={transparentPlayIcon}
              onClick={this.props.openModal}
              playIconUrl={playIconUrl}
            />
            {iconText && <span onClick={this.props.openModal}>{iconText}</span>}
          </>
        )}

        {shouldDisplayPreview && imageUrl && (
          <Image src={imageUrl} alt={alt || 'Video created by Wave.video'} />
        )}

        {alwaysInModal && imageUrl && (
          <>
            <IconPlay
              size="80x80"
              behavior={imageIconPlayBehavior || iconPlayBehavior}
              transparentPlayIcon={transparentPlayIcon}
              onClick={this.props.openModal}
              playIconUrl={playIconUrl}
            />
            <Image src={imageUrl} alt={alt || 'Video created by Wave.video'} />
          </>
        )}

        {shouldDisplayInModal ? (
          isModalOpened && (
            <Modal
              closeModal={this.props.closeModal}
              modalWidth={this.state.modalWidth}
              modalHeight={this.state.modalHeight}
              isModalOpened={this.state.isPlayerReady}
            >
              <div
                className={`wistia_embed wistia_async_${wistiaId} fullscreenButton=true playbar=true`}
              />
            </Modal>
          )
        ) : (
          <div className={`wistia_embed wistia_async_${wistiaId}`} />
        )}
      </LandingVideoWrapper>
    );
  }
}

export default withModalControls(LandingVideo);
