import { ApiError } from '@anm/api';
import isApiError from '@anm/api/helpers/isApiError';
import { GetMediaInfoResponse, MediaInfoFormat } from '@anm/api/modules/youtube';

import mapFormatsToOptions from './mapFormatsToOptions';
export { mapFormatsToOptions };

export const getError = (errors: (string | ApiError | null)[]) => {
  const error = errors.find(val => !!val);

  return (isApiError(error) && error) || error || undefined;
};

export const findFormat = (formatId: string, formats: MediaInfoFormat[]) =>
  formats.find(({ format_id }) => format_id === formatId);

export const getFilenameByFormatId = (formatId: string, formats: MediaInfoFormat[]) =>
  findFormat(formatId, formats)?.filename;

export const getFileSizeByFormatId = (formatId: string, formats: MediaInfoFormat[]) => {
  const format = findFormat(formatId, formats);
  if (!format) return format;

  const { filesize, filesize_approx } = format;

  return filesize || filesize_approx;
};

export const isAllowedSize = (size: number) => size / 1024 / 1024 / 1024 <= 1;

export const getResolutionByFormatId = (formatId: string, formats: MediaInfoFormat[]) =>
  findFormat(formatId, formats)?.height;

export const getDownloadUrlByFormatId = (formatId: string, formats: MediaInfoFormat[]) =>
  findFormat(formatId, formats)?.downloadUrl;

export const isLiveVideo = ({ formats }: GetMediaInfoResponse) =>
  formats.some(({ downloader_options }) => !downloader_options);
