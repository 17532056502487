import styled from 'styled-components';

import { media } from '../../../styles/helpers';

import { MediaFrame } from './Media';
import { getPlayIconStyles, PlayIconSizes } from './PlayIcon';
import Image from '@anm/components/image/Image';
import getFromCdn from '@anm/helpers/getFromCdn';

type StaticMedia = {
  mediaMaxWidth?: number;
  mediaFrame?: MediaFrame;
  playIconSize?: PlayIconSizes;
};

const mediaWrapperTablet = media.tablet`
  margin: 0 auto;
`;

const frameStyles = media.tablet<{ mediaFrame: MediaFrame }>`
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: ${({ mediaFrame }) => mediaFrame.width || 20}px;
    left: ${({ mediaFrame }) => mediaFrame.width || 20}px;
    background-color: ${({ mediaFrame }) => mediaFrame.color || '#C4EAFF'};
  }
`;

export const MediaWrapper = styled.div<StaticMedia>`
  width: 100%;
  position: relative;
  margin: 24px 0 20px;
  max-width: ${({ mediaMaxWidth }) => `${mediaMaxWidth}px` || '100%'};
  ${({ mediaFrame }) => mediaFrame && frameStyles}
  .plyr__control.plyr__control--overlaid {
    ${({ playIconSize }) => playIconSize && getPlayIconStyles(playIconSize!)}
  }

  ${mediaWrapperTablet};
`;

export const ImageMedia = styled(Image)`
  position: relative;
`;

const SideMediaWrapper = styled.section`
  overflow: hidden;
  position: relative;
`;

export const PlayerWrapper = styled.div`
  img {
    width: 100%;
    position: relative;
    margin-bottom: -4px;
  }
`;

export const ListWrapper = styled.ul`
  margin-top: 24px;

  li {
    border: 1px solid rgba(0, 56, 87, 0.2);
    border-radius: 8px;
    background: url(${getFromCdn('images/data/icons/arrow-right.svg')}) no-repeat center right 15px #fff;
    background-size: 10px 16px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &:hover {
      background-color: rgba(48, 174, 242, 0.1);
    }
  }
  img {
    margin-right: 15px;
  }
`;

export const ListItemWrapper = styled.a`
  color: ${({ color }) => color || '#2294df'};
  font-weight: 700;
  width: 100%;
  display: flex;
  align-items: center;
  height: 54px;
  padding: 0 35px 0 23px;
`;

export default SideMediaWrapper;
