import { FC } from 'react';

import StartupBroadcastContent, { StartupBroadcastContentProps } from './StartupBroadcastContent';
import MediaWrapper, { StartupBroadcastBlock, Wrapper } from './Wrapper';

export type StartupBroadcastProps = StartupBroadcastContentProps & {
  className?: string;
};

const StartupBroadcast: FC<StartupBroadcastProps> = ({ className, isSettings, ...otherProps }) => (
  <StartupBroadcastBlock className={className} isSettings={isSettings}>
    <Wrapper>
      <MediaWrapper>
        <StartupBroadcastContent isSettings={isSettings} {...otherProps} />
      </MediaWrapper>
    </Wrapper>
  </StartupBroadcastBlock>
);

export default StartupBroadcast;
