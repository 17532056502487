import { Workspace } from '@anm/api/modules/team';
import { getParamsFromSearch } from '@anm/hooks/usePageParams';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { MediaDownloaderContext } from '../MediaDownloaderProvider';

type OpenEditorProps = {
  intent: string;
  uploadId: string;
  editorUrl: string;
};
const openEditor = ({ intent, uploadId, editorUrl }: OpenEditorProps) => {
  const { workspace = 'user' } = getParamsFromSearch<{ workspace: Workspace }>();
  const uploadParam = `#uploadIds=${uploadId}&intent=${intent}&workspace=${workspace}`;

  window.open(`${editorUrl}/${uploadParam}`, '_self');
};

type UseEditorOpenerProps = {
  editorUrl: string;
};
const useEditorOpener = ({ editorUrl }: UseEditorOpenerProps) => {
  const [intent, setIntent] = useState<string>();
  const { uploadState, upload, cancelUpload, mediaType } = useContext(MediaDownloaderContext);
  const uploadId = uploadState.data?.id;
  const prevMediaTypeRef = useRef<string>();

  useEffect(() => {
    if (!intent || uploadState.isPending || uploadId) return;
    upload(intent);
    prevMediaTypeRef.current = mediaType;
  }, [!!intent, !!uploadId]);

  useEffect(() => {
    if (intent && uploadId) {
      openEditor({ intent, uploadId, editorUrl });
      setIntent('');
    }
  }, [intent, !!uploadId]);

  const cancel = useCallback(() => {
    cancelUpload();
    setIntent('');
  }, []);

  return [{ isError: uploadState.isError }, { open: setIntent, cancel }] as const;
};

export default useEditorOpener;
