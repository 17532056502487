import logger from '@anm/helpers/logger';
import { pick } from 'lodash/fp';

import createMediaGet from '../createMediaGet';

const getAudioMedia = createMediaGet('audio');
const getAudioMediaGuard = (constraints: MediaStreamConstraints, id: string) => {
  const { video, audio } = constraints;

  if (!video && audio) {
    return getAudioMedia(constraints, id);
  } else {
    video && logger('you can not specify video prop when creating audio stream');
    !audio && logger('audio prop is undefined');
  }
};

const getVideoMedia = createMediaGet('video');
const getVideoMediaGuard = (constraints: MediaStreamConstraints, id: string) => {
  const { video, audio } = constraints;

  if (video && !audio) {
    return getVideoMedia(constraints, id);
  } else {
    audio && logger('you can not specify audio prop when creating video stream');
    !video && logger('video prop is undefined');
  }
};

const getUserMedia = (constraints: MediaStreamConstraints, id: string) => {
  const audioConstrains = pick<MediaStreamConstraints, keyof MediaStreamConstraints>('audio')(constraints);
  const videoConstrains = pick<MediaStreamConstraints, keyof MediaStreamConstraints>('video')(constraints);

  const audioStream = constraints.audio && getAudioMediaGuard(audioConstrains, id);
  const videoStream = constraints.video && getVideoMediaGuard(videoConstrains, id);
  return videoStream || audioStream || null;
};

export default getUserMedia;
