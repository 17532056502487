import { flexCenter } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

import { BrowseMoreButtonProps } from '.';

export const PlusIcon = styled.div`
  ${flexCenter};
  width: 55px;
  height: 55px;
  background: #fff;
  border-radius: 50%;
  position: relative;
  box-shadow: 0px 2px 10px rgba(1, 83, 130, 0.1);
  &:after {
    content: '';
    width: 3px;
    height: 19px;
    position: absolute;
    background: #919eaa;
  }
  &:before {
    content: '';
    width: 19px;
    height: 3px;
    position: absolute;
    background: #919eaa;
  }
`;

export const ButtonText = styled.span`
  color: #292a2b;
  font-size: 16px;
  font-weight: bold;
  line-height: 25px;
  text-align: center;
`;

const getButtonWidth = css<BrowseMoreButtonProps>`
  ${({ width, columnCount, itemMargin }) => css`
    width: calc(
      ${width}% - (${itemMargin}px / ${columnCount}) * ${columnCount - 1}
    );
  `}
`;

const Wrapper = styled.a<BrowseMoreButtonProps>`
  ${flexCenter};
  ${getButtonWidth};
  cursor: pointer;
  border-radius: 5px;
  background: #f5f7fa;
  padding: 25px 0 14px;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid rgba(1, 83, 130, 0.2);
  height: ${({ itemHeight }) => itemHeight}px;
  margin-bottom: ${({ itemMargin }) => itemMargin}px;
  &:hover {
    background: #e0f0f6;
    ${PlusIcon} {
      background: #e9f7fb;
    }
  }
`;

export default Wrapper;
