import Head from 'next/head';
import { FC, PropsWithChildren, ReactNode } from 'react';

const SafeHead: FC<PropsWithChildren<{}>> = ({ children }) => {
  const filteredChildren = (children as ReactNode[]).filter(elem => !!elem);

  return <Head>{filteredChildren}</Head>;
};

export default SafeHead;
