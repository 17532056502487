import LazyImage from '@anm/components/image/LazyImage';
import { Omit } from 'helpers';
import { FC } from 'react';

import { ListContentProps, ListItem as ListItemProps } from '../types';

import { CardLink, CardTitle, CardWrapper, IconContainer } from './Wrapper';

type CardProps = Omit<ListContentProps, 'list'> & ListItemProps;

const ListItem: FC<CardProps> = ({
  title,
  iconUrl,
  description,
  headingLevel,
  customButton,
  isSmallIcon = true,
  ...cardProps
}) => (
  <CardWrapper key={title} {...cardProps}>
    {iconUrl && (
      <IconContainer {...{ isSmallIcon }}>
        <LazyImage src={iconUrl} alt={title} />
      </IconContainer>
    )}
    <CardTitle
      {...{ title, description, isSmallIcon }}
      color="black"
      level={headingLevel}
    />
    {customButton && (
      <CardLink variant="link" size="medium" {...customButton}>
        {customButton.children}
      </CardLink>
    )}
  </CardWrapper>
);

export default ListItem;
