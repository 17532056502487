import {
  FetchUploadRequest,
  GetFolderData,
  MoveFolderProps,
  MoveUploadProps,
  UpdateFolderParams,
  UpdateMediaParams
} from '@anm/api/modules/uploads';
import asyncEntity from '@anm/helpers/redux/asyncEntity';
import { takeType } from '@anm/helpers/saga/typesafe-actions';
import { uploadsApi } from '@anm/store/modules/uploads/api';
import { DEFAULT_FETCH_PARAMS } from '@anm/store/modules/uploads/reducer';
import { isEqual } from 'lodash/fp';
import { call, fork, put, select, take } from 'typed-redux-saga';

import { uploadsActions, uploadsSelectors } from '..';
import { notify } from '../../appNotifications/actions';
import { UploadBreadcrumbItemProps } from '../types';

import newUploadSagaWatchers from './newUploadSaga';

const fetchUploadsRequest = asyncEntity(uploadsActions.fetchUploadsAsync, (params: GetFolderData) =>
  uploadsApi().getFolderData(params)
);

function* generalFetchUploadsRequest(otherParams: Partial<GetFolderData> | void) {
  const isPending = yield* select(uploadsSelectors.selectUploadsListPending);
  const { id, extraProps } = (yield* select(uploadsSelectors.selectCurrentTeamUserBreadcrumb)) || {};
  const { type, workspace } = extraProps || { workspace: 'user' };
  const path = yield* select(uploadsSelectors.selectCurrentFolderPath);

  const params: GetFolderData = {
    ...DEFAULT_FETCH_PARAMS,
    workspace,
    ...(path && { path }),
    ...(type === 'user' && { userId: id }),
    ...otherParams
  };

  if (!isPending) yield* call(fetchUploadsRequest, params);
}

function* watchFetchUploads() {
  while (true) {
    const { payload } = yield* take(takeType(uploadsActions.fetchUploads));

    yield* call(generalFetchUploadsRequest, payload);
  }
}

function* watchSearchUploads() {
  while (true) {
    yield* take(takeType(uploadsActions.updateSearchTerm));

    const searchInput = yield* select(uploadsSelectors.selectSearchInput);

    yield* fork(generalFetchUploadsRequest, { ...(searchInput && { name: searchInput }) });
  }
}

const isSameBreadcrumb = (() => {
  let prevBreadcrumb: UploadBreadcrumbItemProps | null | UploadBreadcrumbItemProps[];

  return function*() {
    const currentBreadcrumb = yield* select(uploadsSelectors.selectCurrentBreadcrumb);
    const isSame = isEqual(prevBreadcrumb, currentBreadcrumb);

    prevBreadcrumb = currentBreadcrumb;

    return isSame;
  };
})();

function* watchOpenFolder() {
  while (true) {
    yield* take(takeType(uploadsActions.updateBreadcrumbs));
    const isSame = yield* call(isSameBreadcrumb);

    if (!isSame) yield* call(generalFetchUploadsRequest, { page: 1 });
  }
}

function* watchFetchUploadsNextPage() {
  while (true) {
    yield* take(takeType(uploadsActions.fetchUploadsNextPage));

    const selectedParams = yield* select(uploadsSelectors.selectUploadsListParams);

    const page = (selectedParams?.page || DEFAULT_FETCH_PARAMS.page) + 1;
    const params = { ...selectedParams, page };

    yield* call(generalFetchUploadsRequest, params);
  }
}

const fetchUploadMediaRequest = (params: FetchUploadRequest) => uploadsApi().getUploadMedia(params);

const updateFolderPathRequest = (params: UpdateFolderParams) => uploadsApi().updateFolderPath(params);

const updateFolderPathAsync = asyncEntity(uploadsActions.updateFolderPathAsync, updateFolderPathRequest);

function* watchUpdateFolderPath() {
  while (true) {
    const { payload } = yield* take(takeType(uploadsActions.updateFolderPath));

    yield* fork(updateFolderPathAsync, payload);
  }
}

function* watchUpdateFolderPathFailure() {
  while (true) {
    const { payload } = yield* take(takeType(uploadsActions.updateFolderPathAsync.failure));

    yield* put(
      notify({
        type: 'error',
        timeout: 5000,
        description: payload.message,
        title: 'Fail to update folder'
      })
    );
  }
}

const updateFileMetaRequest = (params: UpdateMediaParams) => uploadsApi().updateMediaMeta(params);

const updateMediaMetaAsync = asyncEntity(uploadsActions.updateMediaMetaAsync, updateFileMetaRequest);

function* watchMediaMetaPath() {
  while (true) {
    const { payload } = yield* take(takeType(uploadsActions.updateMediaMeta));

    yield* fork(updateMediaMetaAsync, payload);
  }
}

const createFolderRequest = (params: string[]) => uploadsApi().createUploadFolder(params);

const createFolderAsync = asyncEntity(uploadsActions.createFolderAsync, createFolderRequest);

function* watchCreateFolder() {
  while (true) {
    const { payload } = yield* take(takeType(uploadsActions.createFolder));

    yield* fork(createFolderAsync, payload);
  }
}

function* watchCreateFolderSuccess() {
  while (true) {
    yield* take(takeType(uploadsActions.createFolderAsync.success));

    yield* put(uploadsActions.removeNewAttributeFromFolder());
  }
}

function* watchCreateFolderFailure() {
  while (true) {
    const { payload } = yield* take(takeType(uploadsActions.createFolderAsync.failure));

    yield* put(
      notify({
        type: 'error',
        timeout: 5000,
        description: payload.message,
        title: 'Fail to create folder'
      })
    );
  }
}

const deleteFolderRequest = (folderPath: string) => uploadsApi().deleteFolder(folderPath);

const deleteFolderAsync = asyncEntity(uploadsActions.deleteFolderAsync, deleteFolderRequest);

function* watchDeleteFolder() {
  while (true) {
    const { payload } = yield* take(takeType(uploadsActions.deleteFolder));

    yield* fork(deleteFolderAsync, payload);
  }
}

function* watchDeleteFolderFailure() {
  while (true) {
    const { payload } = yield* take(takeType(uploadsActions.deleteFolderAsync.failure));

    yield* put(
      notify({
        type: 'error',
        timeout: 5000,
        description: payload.message,
        title: 'Fail to delete folder'
      })
    );
  }
}

const getUploadProjectsRequest = (uploadId: string) => uploadsApi().getUploadProjects(uploadId);

const getUploadProjectsAsync = asyncEntity(uploadsActions.getUploadProjectsAsync, getUploadProjectsRequest);

function* watchGetUploadProject() {
  while (true) {
    const { payload } = yield* take(takeType(uploadsActions.getUploadProjects));

    yield* fork(getUploadProjectsAsync, payload);
  }
}

const deleteUploadRequest = (uploadId: string) => uploadsApi().deleteUploadFile(uploadId);

const deleteUploadAsync = asyncEntity(uploadsActions.deleteUploadAsync, deleteUploadRequest);

function* watchDeleteUpload() {
  while (true) {
    const { payload } = yield* take(takeType(uploadsActions.deleteUpload));

    yield* fork(deleteUploadAsync, payload);
  }
}

function* watchDeleteUploadSuccess() {
  while (true) {
    const { payload } = yield* take(takeType(uploadsActions.deleteUploadAsync.success));

    yield* put(uploadsActions.removeListItem(payload.id));
  }
}

function* watchDeleteUploadFailure() {
  while (true) {
    yield* take(takeType(uploadsActions.deleteUploadAsync.failure));

    yield* put(
      notify({
        type: 'error',
        timeout: 5000,
        title: 'Failed to delete upload'
      })
    );
  }
}

const fetchUploadForBrandAsync = asyncEntity(uploadsActions.fetchUploadForBrandAsync, (id: string) =>
  fetchUploadMediaRequest({ id, isCached: false })
);

function* watchFetchUploadForBrand() {
  while (true) {
    const { payload } = yield* take(takeType(uploadsActions.fetchUploadForBrand));

    yield* fork(fetchUploadForBrandAsync, payload.id, payload);
  }
}

function* watchRefreshUploads() {
  while (true) {
    yield* take(takeType(uploadsActions.refreshUploads));

    const params = yield* select(uploadsSelectors.selectUploadsListParams);

    yield* fork(fetchUploadsRequest, params || DEFAULT_FETCH_PARAMS);
  }
}

const moveUploadToFolderRequest = asyncEntity(uploadsActions.moveUploadToFolderAsync, (props: MoveUploadProps) =>
  uploadsApi().moveUploadToFolder(props)
);

function* watchMoveUploadToFolder() {
  while (true) {
    const { payload } = yield* take(takeType(uploadsActions.moveUploadToFolder));

    yield* fork(moveUploadToFolderRequest, payload);
  }
}

function* watchMoveUploadFailed() {
  while (true) {
    const { payload } = yield* take(takeType(uploadsActions.moveUploadToFolderAsync.failure));

    yield* put(
      notify({
        type: 'error',
        timeout: 5000,
        description: payload.message,
        title: 'Fail to move upload'
      })
    );
  }
}

const moveFolderToFolderRequest = asyncEntity(uploadsActions.moveUploadFolderToFolderAsync, (props: MoveFolderProps) =>
  uploadsApi().moveUploadFolderToFolder(props)
);

function* watchMoveFolderToFolder() {
  while (true) {
    const { payload } = yield* take(takeType(uploadsActions.moveUploadFolderToFolder));

    yield* fork(moveFolderToFolderRequest, payload);
  }
}

function* watchMoveUploadFolderFailed() {
  while (true) {
    const { payload } = yield* take(takeType(uploadsActions.moveUploadFolderToFolderAsync.failure));

    yield* put(
      notify({
        type: 'error',
        timeout: 5000,
        description: payload.message,
        title: 'Fail to move upload folder'
      })
    );
  }
}

const getWatchers = () => [
  call(watchOpenFolder),
  call(watchFetchUploads),
  call(watchSearchUploads),
  call(watchDeleteFolder),
  call(watchCreateFolder),
  call(watchDeleteUpload),
  call(watchMediaMetaPath),
  call(watchRefreshUploads),
  call(watchUpdateFolderPath),
  call(watchGetUploadProject),
  call(watchMoveUploadFailed),
  call(watchMoveFolderToFolder),
  call(watchMoveUploadToFolder),
  call(watchDeleteUploadFailure),
  call(watchFetchUploadForBrand),
  call(watchDeleteUploadSuccess),
  call(watchDeleteFolderFailure),
  call(watchCreateFolderSuccess),
  call(watchCreateFolderFailure),
  call(watchFetchUploadsNextPage),
  call(watchMoveUploadFolderFailed),
  call(watchUpdateFolderPathFailure),
  ...newUploadSagaWatchers.map(w => call(w))
];

export default getWatchers;
