import { getAuthToken } from '@anm/auth/helpers/authToken';

const getAuthHeaders = () => {
  const token = getAuthToken();
  if (!token) return;

  const requestHeaders = {
    headers: {
      'Animatron-Security-Token': token
    }
  };

  return requestHeaders;
};

export default getAuthHeaders;
