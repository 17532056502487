import { MainApi } from './instances/mainApi';

type InstanceApi = MainApi;

let instanceApi: InstanceApi;

export const get = () => instanceApi;
export const set = (newInstance: InstanceApi) => {
  instanceApi = newInstance;
};
