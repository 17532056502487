import { CloseButton } from '@anm/styles/helpers';
import { FC } from 'react';

import Modal, { ModalProps } from '../Modal';

import DialogContent from './Content';
import DialogWrapper from './Wrapper';

export { DialogContent };

export type DialogProps = ModalProps & {
  className?: string;
  noMinWidth?: boolean;
  closeIconColor?: 'black';
  dialogBackground?: string;
};

const Dialog: FC<DialogProps> = ({
  children,
  onSubmit,
  onModalClose,
  closeIconColor,
  noMinWidth = false,
  isModalTopAligned,
  ...otherProps
}) => (
  <Modal {...{ isModalTopAligned, onSubmit, onModalClose }}>
    <DialogWrapper>
      <DialogContent {...{ ...otherProps, noMinWidth }}>
        <CloseButton {...{ closeIconColor }} onClick={onModalClose} />
        {children}
      </DialogContent>
    </DialogWrapper>
  </Modal>
);

export default Dialog;
