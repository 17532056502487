import Logo, { LogoProps } from '@anm/components/Logo';
import { FC } from 'react';

import lfConfig from '../../config';

type LandingLogoProps = Omit<LogoProps, 'studioUrl' | 'waveUrl'>;

const { waveUrl, studioUrl } = lfConfig;

const LandingLogo: FC<LandingLogoProps> = ({ link, variant }) => (
  <Logo {...{ link, variant, waveUrl, studioUrl }} />
);

export default LandingLogo;
