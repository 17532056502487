import { useEffect, useState } from 'react';

export default (top: number) => {
  const [isScrolledOut, setScrolledOut] = useState(false);

  const onResize = () => {
    setScrolledOut(window.pageYOffset >= top);
  };

  useEffect(() => {
    window.addEventListener('scroll', onResize);
    return () => window.removeEventListener('scroll', onResize);
  }, []);

  return isScrolledOut;
};
