import { flexCenter } from '@anm/styles/helpers';
import { FC } from 'react';
import styled from 'styled-components';

import EmbedButton, { EmbedButtonProps } from '../EmbedButton';

export type EmbedButtonPreviewProps = EmbedButtonProps;

const Wrapper = styled.div`
  background-color: #f5f7fa;
  border-radius: 10px;
  ${flexCenter};
  justify-content: space-between;
  padding: 20px;
  min-height: 80px;
  &:after {
    display: block;
    content: '';
    flex: 1;
  }
`;
const Span = styled.span`
  color: #919eaa;
  line-height: 15px;
  flex: 1;
`;

const EmbedButtonPreview: FC<EmbedButtonPreviewProps> = props => (
  <Wrapper>
    <Span>Your button preview</Span>
    <EmbedButton {...props} disabled={true}></EmbedButton>
  </Wrapper>
);

export default EmbedButtonPreview;
