import TitleBlock from '@anm/components/TitleBlock';
import { flexCenter, media } from '@anm/styles/helpers';
import styled from 'styled-components';

export const Content = styled.div`
  ${flexCenter};
  display: inline-flex;
  & > * {
    flex-shrink: 0;
  }
`;

export const Title = styled(TitleBlock)`
  margin-left: 20px;
  width: auto;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 18px;
    line-height: 28px;
  }
`;

const wrapperOnTablet = media.tablet`
  margin-bottom: 20px;
`;

const Wrapper = styled.section`
  margin-bottom: 28px;
  margin-top: 60px;
  ${wrapperOnTablet};
`;

export default Wrapper;
