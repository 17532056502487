import { useContext, FC } from 'react';

import { VideoDataItem } from '../../../../../features/getVideoData';
import { VideoDataContext } from '../../../../../store/VideoDataContext';
import LandingVideo from '../../../../landing/LandingVideo';
import { MediaBlockProps, MediaPlayerProps } from '../../types';

const WistiaPlayer: FC<MediaPlayerProps> = ({ videoId, design }) => {
  const videoData = useContext(VideoDataContext);
  const currentVideo = videoData[videoId] as VideoDataItem;

  return (
    <li>
      <LandingVideo
        imageUrl={currentVideo.thumbnail}
        alt={currentVideo.name}
        wistiaId={videoId}
        iconPlayBehavior={design === 'new' ? 'hover-opacity' : 'hover-scale'}
        withIconPlay={true}
        openInModal={true}
      />
    </li>
  );
};

const WistiaBlock: FC<MediaBlockProps> = ({ videos, design }) => (
  <>
    {videos!.map(({ videoId, customButton }) => (
      <WistiaPlayer key={videoId} {...{ videoId, design, customButton }} />
    ))}
  </>
);

export default WistiaBlock;
