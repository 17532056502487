import { compose, mapProps, withState } from 'recompose';

export type PositionProps = {
  position: PositionState;
};

export type PositionState = {
  current: number;
  next: (position: number) => number;
};

const withPosition = (defaultPosition = 0) =>
  compose(
    withState('current', 'next', defaultPosition),
    mapProps(({ current, next, ...other }) => ({
      position: { current, next },
      ...other
    }))
  );

export default withPosition;
