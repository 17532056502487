import { useEffect, useRef, useState } from 'react';

const MODAL_MAX_WIDTH_RATIO = 0.9;

const useVideoModalDimension = (isAssetInited: boolean, isSmallGap = false) => {
  const [modalWidth, setModalWidth] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const video = containerRef.current?.querySelector('video');
    const image = containerRef.current?.querySelector('img');

    const asset = video || image;
    if (!asset) return;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const windowRatio = windowHeight / windowWidth;
    const assetRatio = asset.clientHeight / asset.clientWidth;

    const isAssetVertical = assetRatio > 1;
    const modalVerticalPadding = isAssetVertical || isSmallGap ? 50 : 250;

    const modalWidth = (windowWidth * MODAL_MAX_WIDTH_RATIO) / (assetRatio / windowRatio) - modalVerticalPadding;

    setModalWidth(modalWidth);
  }, [isAssetInited]);

  return [modalWidth, containerRef] as const;
};

export default useVideoModalDimension;
