import { call as originCall } from 'typed-redux-saga';

import { isPromiseResolvedOrRejected } from './../promise/index';
export * from 'typed-redux-saga';

let asyncRequests = [] as Promise<any>[];

const asyncCounter = (fn: (...arg: any[]) => any) => (...arg: any[]) => {
  const res = fn(...arg);
  const isAsync = !!res.then;

  if (isAsync) {
    asyncRequests.push(res);
  }

  return res;
};

export const call: typeof originCall = <Fn extends (...args: any[]) => any>(
  fn: Fn,
  ...args: Parameters<Fn>
) => originCall(asyncCounter(fn), ...args);

const waitNextMacroTask = () => new Promise(resolve => setTimeout(resolve, 0));

const waitAsyncRequests = async (isDebug: boolean) => {
  try {
    await Promise.all(asyncRequests);
  } catch (e) {
    isDebug && console.info('wait async requests error: ', e);
  }
};

const isAsyncRequestsResolved = async () => {
  const promises = asyncRequests.map(isPromiseResolvedOrRejected);
  const all = await Promise.all(promises);

  return all.every(v => v);
};

export const waitAsync = ({ isDebug = false }) =>
  new Promise(resolve => {
    const check = async () => {
      if (await isAsyncRequestsResolved()) {
        resolve('ok');
        asyncRequests = [];
        return;
      }

      await waitAsyncRequests(isDebug);
      await waitNextMacroTask();

      check();
    };

    check();
  });
