import { UserContext } from '@anm/components/user/UserProvider';
import useIntercom from '@anm/hooks/useIntercom';
import { useContext } from 'react';

import config from '../config';
import { LandingData } from '../features/getLanding';
import { LandingDataContext } from '../store/LandingDataContext';

const {
  support: { intercom }
} = config;

const useLandingIntercom = () => {
  const { user } = useContext(UserContext);
  const { services }: LandingData = useContext(LandingDataContext);

  useIntercom({
    id: intercom.id!,
    user,
    enable: services?.intercom !== undefined ? services?.intercom : intercom.enable,
    product: 'WAVE'
  });
};

export default useLandingIntercom;
