import LazyImage from '@anm/components/image/LazyImage';
import React, { FC } from 'react';

import Wrapper from './Wrapper';

export type AbsoluteBgProps = {
  alt: string;
  bgImageUrl: string;
};

const AbsoluteBg: FC<AbsoluteBgProps> = ({ alt, bgImageUrl }) => (
  <Wrapper>
    <LazyImage alt={alt} src={bgImageUrl} />
  </Wrapper>
);

export default AbsoluteBg;
