import { ListItem } from '@anm/components/Accordion';
import Image from '@anm/components/image/Image';
import getFromCdn from '@anm/helpers/getFromCdn';
import { FC } from 'react';

const ImageWrapper: FC<ListItem> = ({ imgUrl }) => (
  <Image src={getFromCdn(imgUrl!)} />
);

export default ImageWrapper;
