import hasAuthAccess from '@anm/auth/helpers/hasAuthAccess';
import { UserContext } from '@anm/components/user/UserProvider';
import waitFor from '@anm/helpers/waitFor';
import { useCallback, useContext, useRef } from 'react';

const useAnonAuth = () => {
  const { anon, isUserLogged } = useContext(UserContext);
  const ref = useRef(isUserLogged);
  ref.current = isUserLogged;

  const signup = useCallback(async () => {
    !hasAuthAccess() && (await anon.signup());
    await waitFor(() => ref.current, 800, 'wait for anon user signup');
  }, [anon.signup]);

  return signup;
};

export default useAnonAuth;
