import styled from 'styled-components';

import { media } from '../../../styles/helpers';
import SectionTitle from '../../SectionTitle';

const HorizontalListTitleOnLaptop = media.laptop`
  font-size: 36px;
`;

const HorizontalListTitle = styled(SectionTitle)`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 30px;
    ${HorizontalListTitleOnLaptop};
  }
`;

export default HorizontalListTitle;
