import noop from '@anm/helpers/noop';
import { FC } from 'react';

import ContentList from './ContentList';
import {
  ColumnHeader,
  ColumnIcon,
  FooterButton,
  FooterLink,
  HeaderButtonWrapper,
  HeaderLink,
  NavigationDropdownColumn
} from './Wrapper';
import { ColumnsListProps, LinkContainerProps } from './types';

const LinkContainer: FC<LinkContainerProps> = ({ href, title, route, params, children, renderNavLink = noop }) => {
  if (href) return <HeaderLink {...{ href }}>{children}</HeaderLink>;
  if (route) {
    return (
      <HeaderButtonWrapper>
        <>{renderNavLink({ title, route, params })}</>
      </HeaderButtonWrapper>
    );
  }

  return children;
};

const ColumnsList: FC<ColumnsListProps> = ({ contentList, renderNavLink }) => (
  <>
    {contentList.map(({ title: headerTitle, links, href, iconSrc, footerLink, footerButton, ...titleLink }, index) => (
      <NavigationDropdownColumn key={headerTitle || index}>
        {iconSrc && (
          <LinkContainer href={footerLink?.href}>
            <ColumnIcon src={iconSrc} alt={headerTitle} />
          </LinkContainer>
        )}
        {headerTitle && (
          <LinkContainer title={headerTitle} {...{ renderNavLink, ...titleLink }} href={href || footerLink?.href}>
            <ColumnHeader>{headerTitle}</ColumnHeader>
          </LinkContainer>
        )}
        <ContentList contentList={links} {...{ renderNavLink }} />
        {footerLink && <FooterLink href={footerLink.href}>{footerLink.title}</FooterLink>}
        {footerButton && (
          <FooterButton size={'medium'} variant={'light-blue-lowercase'} href={footerButton.href}>
            {footerButton.title}
          </FooterButton>
        )}
      </NavigationDropdownColumn>
    ))}
  </>
);

export default ColumnsList;
