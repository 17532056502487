import styled from 'styled-components';
import { flexColumn, flexRow } from '@anm/styles/helpers';

export const Group = styled.div`
  flex: 1;
  ${flexColumn};
`;

export const Label = styled.p`
  color: #919eaa;
  font-size: 14px;
  line-height: 17px;
  margin: 0 0 6px;
`;

export const Row = styled.div`
  ${flexRow};
  position: relative;
`;

export const SettingsWrapper = styled.div`
  width: 100%;
  min-height: 40px;
  margin-bottom: 23px;
  position: relative;
  z-index: 12;
  ${flexColumn};
  gap: 24px;

  ${Row}:first-child {
    gap: 12px;
  }
`;