import React, { lazy, FC, Suspense } from 'react';
import { AppProps } from '../../types';

import DelayedLoader from '../DelayedLoader';
import { usePlayerWidth } from './hooks';

const AppContent = lazy(() => import(/* webpackChunkName: "plr" */ '../AppContent'));

const App: FC<AppProps> = ({ playerWidth: playerWithFromProps, ...appProps }) => {
  const [playerRef, playerWidth] = usePlayerWidth(playerWithFromProps);

  return (
    <div ref={playerRef}>
      {playerWidth && (
        <Suspense fallback={<DelayedLoader />}>
          <AppContent {...{ playerWidth, ...appProps }} />
        </Suspense>
      )}
    </div>
  );
};

export default App;
