import IconButton from '@anm/components/buttons/IconButton';
import useAlertSound from '@anm/hooks/useAlertSound';
import { FC } from 'react';

export type TestSpeakerProps = { speakerId: string; className?: string };

const SOUND_URL = 'https://media.animatron.io/prod-blocks/177299_original_RuQJe9iDQjUqKjUGt9ZbP1.mp3';

const TestSpeaker: FC<TestSpeakerProps> = ({ speakerId, className }) => {
  const { alert } = useAlertSound({
    speakerId,
    soundUrl: SOUND_URL
  });

  return (
    <IconButton
      icon="images/icons/media/speaker.svg"
      size="medium"
      variant="gray-system"
      className={className}
      onClick={alert}
    >
      Test speaker
    </IconButton>
  );
};

export default TestSpeaker;
