import { MediaDevicesList, MediaOptions } from '@anm/helpers/media/mediaManager';
import { useEffect, useState } from 'react';

const useMediaManagerOptions = <T extends MediaOptions>(mediaDevice: MediaDevicesList<T>) => {
  const [options, setOptions] = useState(mediaDevice.getCurrentOptions());

  useEffect(() => {
    const clear = mediaDevice.changeOptionsEvent.on(newOptions => {
      setOptions(newOptions);
    });

    return () => clear();
  }, []);

  return options;
};

export default useMediaManagerOptions;
