import styled, { css } from 'styled-components';

import { media } from '../../../styles/helpers';
import SectionTitle from '../../SectionTitle';

import { CardThemeProps } from '.';

const cardStyles = css`
  width: 100%;
  max-width: 1920px;
  overflow-x: hidden;
  margin: 0 auto;
`;

const FeedbackTitleOnLaptop = media.laptop`
  font-size: 36px;
  margin-bottom: 60px;
`;

export const FeedbackTitle = styled(SectionTitle)`
  margin-bottom: 36px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 28px;
    line-height: 40px;
  ${FeedbackTitleOnLaptop};
  }
`;

const FeedbackSectionWrapper = styled.section<CardThemeProps>`
  ${({ isCardTheme }) => isCardTheme && cardStyles}
`;

export default FeedbackSectionWrapper;
