import { Component, ComponentType } from 'react';

type WithModalControlsState = {
  isModalOpened: boolean;
};

export type WithModalControlsProps = {
  isModalOpened: boolean;
  openModal: () => void;
  closeModal: () => void;
  toggleModal: () => void;
};

const withModalControls = <P extends object>(
  WrappedComponent: ComponentType<P & WithModalControlsProps>
) => {
  class WithModalControls extends Component<P, WithModalControlsState> {
    static displayName = `withModalControls(${WrappedComponent.name ||
      'BaseComponent'})`;

    state = {
      isModalOpened: false
    };

    toggleModal = () => {
      this.setState(prevState => ({
        isModalOpened: !prevState.isModalOpened
      }));
    };

    openModal = () => {
      this.setState({
        isModalOpened: true
      });
    };

    closeModal = () => {
      this.setState({
        isModalOpened: false
      });
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          openModal={this.openModal}
          closeModal={this.closeModal}
          toggleModal={this.toggleModal}
        />
      );
    }
  }

  type PropsExcludingDefaults = Pick<
    P,
    Exclude<keyof P, keyof WithModalControlsProps>
  >;
  type RecomposeProps = Partial<WithModalControlsProps> &
    PropsExcludingDefaults;

  return (WithModalControls as ComponentType<P>) as ComponentType<
    RecomposeProps
  >;
};

export default withModalControls;
