import { ApiError } from '@anm/api';
import { useCallback, useEffect, useRef, useState } from 'react';

import useCancelPromise from '../useCancelPromise';

export type RequestState<T, P = unknown> = {
  data?: T;
  params?: P;
  error: ApiError | null;
  isError: boolean;
  isPending: boolean;
};

const mutate = <T extends {}>(toObj: T, fromObj: T) =>
  (Object.keys(toObj) as [keyof T]).reduce((acc, key) => {
    toObj[key] = fromObj[key];
    return acc;
  }, toObj);

type Options = {
  resetState?: boolean;
};

const useRequestState = <T extends unknown, P extends unknown>(
  asyncAction: (props?: P) => Promise<T>,
  configs: Options = { resetState: false }
) => {
  const [data, setData] = useState<T>();
  const [params, setParams] = useState<P>();
  const [isPending, setPending] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);
  const ref = useRef({ data, error, params, isError, isPending });
  const { cancelPromise, runPromise } = useCancelPromise();

  const request = useCallback(
    async (props?: P) => {
      try {
        setPending(true);
        setError(null);
        setIsError(false);
        setParams(props);
        configs.resetState && setData(undefined);
        const res = await runPromise(() => asyncAction(props));
        setData(res);
      } catch (error) {
        if (error?.code === 499) return;

        setError(error);
        setIsError(true);
      } finally {
        setPending(false);
      }
    },
    [asyncAction]
  );

  useEffect(() => {
    return cancelPromise;
  }, []);

  const cancelRequest = useCallback(() => {
    setPending(false);
    cancelPromise();
  }, [cancelPromise]);

  const resetState = useCallback(() => {
    setPending(false);
    setError(null);
    setIsError(false);
    setData(undefined);
    setParams(undefined);
  }, []);

  mutate(ref.current, { data, params, error, isError, isPending });

  return [ref.current as RequestState<T, P>, { request, cancelRequest, resetState }] as const;
};

export default useRequestState;
