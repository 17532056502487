import TitleBlock, { TitleDescription } from '@anm/components/TitleBlock';
import Button from '@anm/components/buttons/Button';
import Image from '@anm/components/image/Image';
import CardModal from '@anm/components/modals/CardModal';
import { Wrapper } from '@anm/components/video/ProgressiveVideoPreview';
import { flexCenter, flexColumn, flexRow } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';
import { EmptyListWrapper } from '@anm/components/EmptyList/Wrapper';

export const CardModalWrapper = styled(CardModal)``;

export const CreateButton = styled(Button)`
  padding: 0;
  min-width: 162px;
`;

export const Title = styled(TitleBlock)`
  text-align: center;
  margin-bottom: 27px;
  ${TitleDescription} {
    text-align: center;
    max-width: 577px;
    margin: 4px auto 0;
    line-height: 23px;
    margin-top: 4px;
  }
`;

export const FormatImage = styled(Image)`
  margin-right: 10px;
  outline-width: 2px;
  outline-offset: -2px;
  outline-style: solid;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

export const Content = styled.div`
  ${flexColumn};
  height: 720px;
  width: 1180px;
  max-width: 90vw;
  padding: 32px 27px;
  align-items: center;
`;

export const FormatButton = styled.div<{ isSelected: boolean }>`
  ${flexRow};
  ${flexCenter};
  height: 38px;
  cursor: pointer;
  padding: 0px 11px 11px 9px;
  border-bottom: 1px solid ${({ isSelected }) => (isSelected ? '#292A2B' : '#919EAA')};
  img {
    outline-color: ${({ isSelected }) => (isSelected ? '#292A2B' : '#919EAA')};
  }
  span {
    min-width: fit-content;
    color: ${({ isSelected }) => (isSelected ? '#292A2B' : '#919EAA')};
  }
`;

export const TabRow = styled.div`
  ${flexRow};
  margin: 0 auto;
`;

export const TemplatesContainer = styled.div<{ width: number }>`
  display: grid;
  grid-template-columns: repeat(auto-fill, calc(${({ width }) => width}px + 14px));
  justify-content: center;
  justify-items: center;
  grid-gap: 40px 18px;

  height: 405px;
  width: 100%;
  overflow: auto;
  margin-top: 33px;
  margin-bottom: 29px;
  padding: 7px 9px 0;
  ${EmptyListWrapper} {
    width: 100%;
  }
`;

export const SelectedWrapper = styled.div<{ isSelected: boolean; width: number; height: number }>`
  position: relative;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  & > div {
    border-radius: 12px;
    border: 1px solid rgba(1, 83, 130, 0.2);
  }
  ${({ isSelected }) =>
    isSelected &&
    css`
      &:before {
        content: '';
        border-radius: 16px;
        position: absolute;
        border: 3px solid var(--blue_100);
        top: -7px;
        left: -7px;
        z-index: 126;
        height: calc(100% + 7px);
        width: calc(100% + 7px);
      }
    `}
`;

export const ProgressiveVideoPreviewWrapper = styled.div`
  width: inherit;
  cursor: pointer;
  height: inherit;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  && ${Wrapper} {
    border-radius: 10px 10px 0 0;
  }
`;
