import {
  centerOriented,
  leftCenterOriented,
  leftCenterOrientedHeight,
  leftCenterOrientedWithArrowHeight,
  leftOriented,
  rightCenterOriented
} from '@anm/components/Dropdown/helpers';
import isMobileOrIPad from '@anm/helpers/is/isMobileOrIPad';
import { dropDownArrow, fadeInAnimation, flexCenter, media } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

import { DropdownDirections } from '.';

type DropdownContentProps = {
  isOpen: boolean;
  direction: DropdownDirections;
};

const dropDownToLeftAnimation = css`
  @keyframes dropdown-to-left {
    from {
      transform: translateY(-8px) translateX(0);
    }

    to {
      transform: translateY(0) translateX(0);
    }
  }
`;

const dropDownBox = css`
  box-shadow: 0 5px 30px rgba(4, 25, 79, 0.2);
  background-color: ${({ theme }) => theme.dropdownAndModalBg};
  border-radius: 4px;
  transform-origin: top;
  transition: visibility 0.1s, opacity 0.2s, transform 0.1s ease;

  z-index: 140;
  position: absolute;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  ${fadeInAnimation};

  li {
    width: 100%;

    &:hover {
      background-color: ${({ theme }) => theme.selectOptionHoverBg};
    }
    a,
    span {
      color: ${({ theme }) => theme.textColor};
      min-height: 45px;
      line-height: 45px;
    }
    a,
    span,
    button {
      ${flexCenter};
      font-size: 16px;
      font-weight: 400;
      width: 100%;
      justify-content: flex-start;
      white-space: nowrap;
      text-decoration: none;
      padding: 0 30px;
      cursor: pointer;
    }
  }
`;

const toBottomNoArrowMobileStyles = css`
  width: calc(100vw - 30px);
  left: 15px;
  bottom: 15px;
  position: fixed;
  z-index: 132;
  transform: none;
  top: auto;
`;

const toBottomNoArrowPortraitStyles = media.phone`
  ${toBottomNoArrowMobileStyles}
`;

const toBottomNoArrowLandscapeStyles = css`
  @media screen and (orientation: landscape) {
    ${toBottomNoArrowMobileStyles}
  }
`;

const toBottomLeftNoArrowStyles = css`
  ${dropDownBox};
  ${leftCenterOriented};
  top: ${leftCenterOrientedHeight};
  padding: 10px 0;
`;

const toBottomRightNoArrowStyles = css`
  ${dropDownBox};
  ${rightCenterOriented};
  top: ${leftCenterOrientedHeight};
  padding: 10px 0;
`;

const toTopStyles = css`
  ${toBottomLeftNoArrowStyles};
  ${toBottomNoArrowPortraitStyles};
  ${isMobileOrIPad() && toBottomNoArrowLandscapeStyles};
  bottom: calc(100% + 10px);
  top: unset;
`;

const toRight = css`
  top: 0;
  right: 0;
  left: auto;
`;

const directions: { [key in DropdownDirections]: ReturnType<typeof css> } = {
  'to-top': css`
    ${toTopStyles};
  `,
  'to-top-low-tunnel': css`
    ${toTopStyles};
  `,
  'to-top-center': css`
    ${toTopStyles};
    ${centerOriented};
  `,
  'to-top-center-with-arrow': css`
    ${toTopStyles};
    ${centerOriented};
    ${dropDownArrow};
    &:after,
    &:before {
      bottom: 0;
      top: unset;
      left: 50%;
      transform: translate(-50%, 100%) rotate(180deg);
    }
  `,
  'to-bottom': css`
    ${dropDownBox};
    top: 47px;
    ${centerOriented};
    ${dropDownArrow}
    &:after,
    &:before {
      left: 0;
      right: 0;
    }
  `,
  'to-bottom-left-with-arrow': css`
    ${dropDownBox};
    top: ${leftCenterOrientedWithArrowHeight};
    ${leftCenterOriented};
    ${dropDownArrow}
    &:after,
    &:before {
      left: 0;
      right: 0;
    }
  `,
  'to-bottom-right': css`
    ${toBottomLeftNoArrowStyles};
    ${toBottomNoArrowPortraitStyles};
    ${isMobileOrIPad() && toBottomNoArrowLandscapeStyles};
  `,
  'to-bottom-right-low-tunnel': css`
    ${toBottomRightNoArrowStyles};
    ${toBottomNoArrowPortraitStyles};
    ${isMobileOrIPad() && toBottomNoArrowLandscapeStyles};
  `,
  'to-bottom-left-low-tunnel': css`
    ${toBottomLeftNoArrowStyles};
    ${toBottomNoArrowPortraitStyles};
    ${isMobileOrIPad() && toBottomNoArrowLandscapeStyles};
  `,
  'to-bottom-left': css`
    ${toBottomLeftNoArrowStyles};
    ${toBottomNoArrowPortraitStyles};
    ${isMobileOrIPad() && toBottomNoArrowLandscapeStyles};
  `,
  'to-bottom-no-arrow-static': css`
    ${toBottomLeftNoArrowStyles};
  `,
  'to-bottom-from-left-corner': css`
    ${dropDownBox};
    left: 0;
    top: calc(100% + 5px);

    &:before {
      content: '';
      position: absolute;
      top: unset;
      left: 0;
      bottom: calc(100% + 5px);
      width: 100%;
      height: 5px;
      transform: translateY(100%);
    }
  `,
  'to-bottom-from-right-corner': css`
    ${dropDownBox};
    right: 0;
    top: calc(100% + 5px);

    &:before {
      content: '';
      position: absolute;
      top: unset;
      left: 0;
      bottom: calc(100% + 5px);
      width: 100%;
      height: 5px;
      transform: translateY(100%);
    }
  `,
  'to-left': css`
    ${dropDownBox};
    ${leftCenterOriented};
    ${dropDownToLeftAnimation};
    animation-name: dropdown-to-left;
    top: 47px;
    ${dropDownArrow};
    padding-bottom: 20px;
    &:after,
    &:before {
      left: auto;
      right: 27px;
    }
  `,
  'to-right': css`
    ${dropDownBox};
    ${toRight}
    transform: translate(100%);
  `,
  'to-right-centered': css`
    ${dropDownBox};
    ${toRight}
    transform: translate(100%, calc(-50% + 30px));
  `,
  'to-left-no-arrow': css`
    ${dropDownBox};
    ${leftOriented};
    ${dropDownToLeftAnimation};
    animation-name: dropdown-to-left;
    top: 0;
    right: calc(100% + 16px);
  `
};

const DropdownContent = styled.div<DropdownContentProps>`
  pointer-events: all;
  ${({ direction }) => [directions[direction]]};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
`;

export default DropdownContent;
