import { FC } from 'react';
import styled from 'styled-components';
import { Omit } from 'types/helpers';

import CopyRight from '../CopyRight';
import Preview, { PreviewProps } from '../Preview';

export type LeftSideProps = Omit<PreviewProps, 'src'> & { preview?: string };

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
`;

const LeftSide: FC<LeftSideProps> = ({ preview, ...props }) => (
  <Wrapper>
    <Preview src={preview} {...props} />
    <CopyRight />
  </Wrapper>
);

export default LeftSide;
