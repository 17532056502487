import { useCallback, useState } from 'react';

const useSwitchState = (defaultValue = false) => {
  const [isTrue, setIsTrue] = useState(defaultValue);

  const onSwitchToTrue = useCallback(() => setIsTrue(true), []);
  const onSwitchToFalse = useCallback(() => setIsTrue(false), []);
  const onToggleState = useCallback(() => setIsTrue(prevState => !prevState), []);

  return [isTrue, onSwitchToTrue, onSwitchToFalse, onToggleState, setIsTrue] as const;
};

export default useSwitchState;
