import Button from '@anm/components/buttons/Button';
import Image from '@anm/components/image/Image';
import { flexColumn, flexRow } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

type WrapperProps = { isSimple: boolean; maxWidth?: string };

export const RightArrow = styled(Image)`
  top: 80px;
  right: 10px;
  cursor: pointer;
  position: absolute;
`;

export const LeftArrow = styled(Image)`
  top: 80px;
  left: 10px;
  cursor: pointer;
  position: absolute;
  transform: rotate(180deg);
`;

export const NavigationDropdownColumn = styled.div`
  ${flexColumn};
`;

export const ColumnHeader = styled.div`
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
  margin-left: 20px;
  margin-bottom: 16px;
  white-space: nowrap;
`;

export const ColumnIcon = styled(Image)`
  max-width: 190px;
  margin: 0 20px 21px;
`;

export const ListWrapper = styled.div`
  display: flex;
  margin-top: 6px;
  margin-bottom: 10px;
  ul {
    width: 100%;
  }
`;

export const MenuItem = styled.li`
  margin-right: 0;
  font-size: 14px;
  min-height: unset;
  a {
    padding: 0 20px !important;
  }
`;

const footerLinkButtonStyles = css`
  display: inline-block;
  max-width: fit-content;
  margin-left: 20px;
`;

export const FooterLink = styled.a`
  ${footerLinkButtonStyles};
  font-size: 14px;
  max-height: 45px;
  margin-top: auto;
  text-decoration: underline !important;
`;

export const FooterButton = styled(Button)`
  ${footerLinkButtonStyles};
  margin-top: 26px;
  color: #fff !important;
  font-weight: bold !important;
`;

export const Content = styled.div<WrapperProps>`
  ${flexRow};
  padding: ${({ isSimple }) => (isSimple ? '41px 20px 20px' : '41px 12px 23px')};
`;

export const HeaderLink = styled.a`
  padding: 0 !important;
  height: auto !important;
`;

export const HeaderButtonWrapper = styled.div`
  a {
    max-height: 18px;
    font-weight: bold !important;
    margin-left: 20px !important;
    margin-bottom: 16px !important;
  }
`;

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  overflow-x: hidden;
  max-width: ${({ maxWidth }) => maxWidth};
`;

export default Wrapper;
