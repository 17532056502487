import React from 'react';

type EnvContextProps = {
  env?: 'test' | 'prod';
};

const EnvContext = React.createContext<EnvContextProps>({});

export const EnvProvider = EnvContext.Provider;

export default EnvContext;
