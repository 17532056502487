import Container from '@anm/components/Container';
import { FC } from 'react';

import { CommonLandingProps } from '../../../features/getLanding';
import getWaves from '../../../helpers/getWaves';
import AnimatedWrapper from '../../Animated';
import Background from '../../Background';
import LandingButton, { LandingButtonProps } from '../../LandingButton';
import SectionPicture from '../../SectionPicture';
import { SectionTitleColor } from '../../SectionTitle';

import MediaContainer from './Media';
import { ButtonContainer, VerticalTextVideoTitle, VerticalTextVideoWrapper, VideoContainer } from './Wrapper';

export type Media = {
  imgSrc?: string;
  videoId?: string;
  isModal?: boolean;
};

export type VerticalTextVideoProps = CommonLandingProps & {
  title: string;
  description: string;
  mediaShadow: boolean;
  media: Media;
  button?: LandingButtonProps;
  titleColor?: SectionTitleColor;
  isFirstSection?: boolean;
};

const VerticalTextVideo: FC<VerticalTextVideoProps> = ({
  title,
  media,
  waves,
  button,
  description,
  titleColor = 'white',
  mediaShadow,
  headingLevel = 5,
  isFirstSection,
  sectionPicture,
  customBackground
}) => (
  <VerticalTextVideoWrapper>
    <AnimatedWrapper className="animated" variant="default">
      <Background
        variant={(waves && getWaves(waves)) || 'vertical-text-video'}
        customBackground={customBackground}
        isSectionPicture={!!sectionPicture}
      >
        <Container>
          <VerticalTextVideoTitle color={titleColor} level={headingLevel} {...{ title, description, isFirstSection }} />
          <VideoContainer isShadow={mediaShadow}>
            <MediaContainer {...{ ...media, title }} />
          </VideoContainer>
          <ButtonContainer>
            <LandingButton size="big" variant="yellow" {...button} />
          </ButtonContainer>
          {sectionPicture && <SectionPicture pictureUrl={sectionPicture} title={title} />}
        </Container>
      </Background>
    </AnimatedWrapper>
  </VerticalTextVideoWrapper>
);

export default VerticalTextVideo;
