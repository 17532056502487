import isComment from '@anm/helpers/is/isComment';
import { FC } from 'react';

import { Code, Line, List, Wrapper } from './Wrapper';

export type CodeSampleProps = {
  code: string;
};

const CodeSample: FC<CodeSampleProps> = ({ code }) => {
  const codeArray = code.split('\n');
  const isSingleLine = codeArray.length === 1;

  return (
    <Wrapper>
      <List>
        {codeArray.map((line, i) => (
          <Line key={i} isSingleLine={isSingleLine}>
            <Code isComment={isComment(line)}>{line}</Code>
          </Line>
        ))}
      </List>
    </Wrapper>
  );
};

export default CodeSample;
