import Container from '@anm/components/Container';
import toCamelCase from '@anm/helpers/string/toCamelCase';
import { FC } from 'react';

import { CommonLandingProps } from '../../../features/getLanding';
import Background from '../../Background/';
import FlexContainer from '../../FlexContainer/';
import LandingButton from '../../LandingButton';
import SectionPicture from '../../SectionPicture';
import HideOnMobile from '../../mobile/HideOnMobile';

import Media from './Media';
import TopSectionDataWrapper, { ButtonsContainer } from './TopSectionDataWrapper';
import TextWrapper from './TopSectionTextWrapper';
import TopSectionTitle from './TopSectionTitle';
import TopSectionWrapper from './TopSectionWrapper';
import VideoTitle from './VideoTitle';
import { TopSectionCommonProps } from './types';

export type TopSectionProps = CommonLandingProps & TopSectionCommonProps;

const TopSection: FC<TopSectionProps> = ({
  title,
  button,
  videoId,
  imageUrl,
  maxWidth,
  videoPath,
  description,
  imageMaxWidth,
  headingLevel,
  sectionPicture,
  waveBackground,
  descriptionTheme,
  customBackground,
  rotateImage = true,
  isMobileFrame = true
}) => {
  const showMediaSection = imageUrl || videoId;

  return (
    <TopSectionWrapper>
      <Background
        variant={waveBackground || 'with-bottom-wave'}
        customBackground={customBackground}
        isSectionPicture={!!sectionPicture}
      >
        <Container size={maxWidth || 1000}>
          <FlexContainer variant="between-on-laptop">
            <TopSectionDataWrapper isCentered={!showMediaSection}>
              {videoPath && <VideoTitle {...{ videoPath, title }} />}
              {!videoPath && (
                <TopSectionTitle title={title} color="black" level={headingLevel} data-title={toCamelCase(title)} />
              )}
              <TextWrapper
                theme={descriptionTheme}
                dangerouslySetInnerHTML={{ __html: description }}
                isFullWidth={!showMediaSection}
                isVideoTitle={!!videoPath}
              />
              {showMediaSection && (
                <Media
                  {...{
                    title,
                    videoId,
                    imageUrl,
                    rotateImage,
                    isMobileFrame,
                    imageMaxWidth,
                    sectionPicture
                  }}
                />
              )}
              <ButtonsContainer isCentered={!!videoPath}>
                <LandingButton size="big" variant="yellow" {...button}>
                  {landingButton => <div>{landingButton}</div>}
                </LandingButton>
              </ButtonsContainer>
            </TopSectionDataWrapper>
          </FlexContainer>
        </Container>
      </Background>
      {sectionPicture && title && (
        <HideOnMobile>
          <SectionPicture pictureUrl={sectionPicture} title={title} />
        </HideOnMobile>
      )}
    </TopSectionWrapper>
  );
};

export default TopSection;
