import { TitleBlockSizes } from '@anm/components/TitleBlock';
import { ButtonLoaderProps } from '@anm/components/buttons/ButtonLoader';
import noop from 'lodash/fp/noop';
import { FC, ReactNode, RefObject } from 'react';

import ConfirmWrapper, { ButtonsWrapper, CancelButton, OkButton, TitleBlockWrapper } from './Wrapper';

export { TitleBlockWrapper, ButtonsWrapper };

export type ConfirmButton = ButtonLoaderProps & {
  text: string;
  ref?: RefObject<HTMLDivElement>;
};

export type ConfirmProps = {
  buttonLeft: ConfirmButton;
  buttonRight?: ConfirmButton;
  title?: string;
  titleSize?: TitleBlockSizes;
  onLeftButtonClick?: () => void;
  onRightButtonClick?: () => void;
  renderAdditionalBlock?: () => ReactNode;
};

const Confirm: FC<ConfirmProps> = ({
  buttonLeft,
  buttonRight,
  children,
  title = '',
  titleSize = 'medium',
  onLeftButtonClick = noop,
  onRightButtonClick = noop,
  renderAdditionalBlock = noop
}) => (
  <ConfirmWrapper>
    <TitleBlockWrapper title={title} size={titleSize} description={children} />
    {renderAdditionalBlock()}
    <ButtonsWrapper>
      <div ref={buttonLeft.ref}>
        <OkButton onClick={onLeftButtonClick} data-button={`confirm ${buttonLeft.text}`} {...buttonLeft}>
          {buttonLeft.text}
        </OkButton>
      </div>
      {buttonRight && (
        <div ref={buttonRight.ref}>
          <CancelButton onClick={onRightButtonClick} data-button={`confirm ${buttonRight.text}`} {...buttonRight}>
            {buttonRight.text}
          </CancelButton>
        </div>
      )}
    </ButtonsWrapper>
  </ConfirmWrapper>
);

export default Confirm;
