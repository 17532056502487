import { ApiError } from '@anm/api';
import { AuthUserResponse } from '@anm/api/modules/auth';
import { UserSubscriptionDetails } from '@anm/api/modules/user';
import * as authStatusWatcher from '@anm/auth/helpers/authStatusWatcher';
import handleUserSessionError from '@anm/auth/helpers/handleUserSessionError';
import resetAppState from '@anm/auth/helpers/resetAppState';
import updateAuthCookie from '@anm/auth/helpers/updateAuthCookie';
import useAnonUser from '@anm/auth/hooks/useAnonUser';
import { AUTH_COOKIE } from '@anm/constants/auth';
import { Logger } from '@anm/helpers/Debugger';
import fetchUser from '@anm/helpers/user/fetchUser';
import { getUserProfile } from '@anm/helpers/user/userLocalStore';
import { isDev } from '@anm/shared/mode';
import { createContext, FC, useCallback, useEffect, useState } from 'react';
import { User as RegisteredUser } from 'user';

type Anon = {
  isPending: boolean;
  error?: ApiError;
  signup: () => Promise<AuthUserResponse | null | undefined>;
  signupAndRun: (cb: () => void) => Promise<void>;
};

/**
 * How username and title work
 *
 * We store username and title of each client in browser localstore.
 * On load we take username and title from local store and save it in redux.
 * We listen to changes in redux and update local store. This way we maintain sync between local store and redux.
 *
 * When we create a room we get username and title from redux and pass it to user object and then to room client when we create it in useRoomClient:57
 *
 * In RoomClient we authenticate using this user object, so it becomes part of a peer and is shared between all the peers.
 *
 * When we create primary feed we use username/title from this peer user here: _addPrimaryCameraAndMic()
 *
 * We listen to primary feed updates and set username/title in redux from primary feed.
 * We also listen to redux updates and sync with redux as well.
 */
type UserContext = {
  anon: Anon;
  user: RegisteredUser | null;
  plan: UserSubscriptionDetails | null;
  logOut: () => void;
  isUserLogged: boolean;
  isSuperUser: boolean;
  updateUser(user: Partial<RegisteredUser>): void;
};

export const UserContext = createContext<UserContext>({
  isUserLogged: false
} as UserContext);

export const Provider = UserContext.Provider;
export const UserConsumer = UserContext.Consumer;

const logger = new Logger('UserProvider');

export type UserProviderProps = { apiUrl: string; onLogin?: () => void };

const UserProvider: FC<UserProviderProps> = ({ apiUrl, children, onLogin }) => {
  const [user, setUser] = useState<RegisteredUser | null>(null);
  const [isUserLogged, setUserLogged] = useState(false);
  const [anonUser, anonUserActions] = useAnonUser();

  const handleLogin = async () => {
    try {
      const user = await fetchUser(apiUrl);
      setUser(user);
      setUserLogged(true);
      onLogin?.();
      logger.debug(`authenticated as ${user?.displayName}`);
    } catch (err) {
      logger.warn(`error authenticating`, err);
      await handleLogout();
      handleUserSessionError()(err);
    }
  };

  const handleLogout = async () => {
    logger.debug(`logged out from ${user?.displayName}`);
    if (isDev) {
      logger.warn(`SKIPPING USER RESET LOCALLY`);
    } else {
      logger.warn(`handleLogout in UserProvider`);
      setUser(null);
      setUserLogged(false);
      await updateAuthCookie(null);
    }
  };

  useEffect(() => {
    const user = getUserProfile();
    setUser(user);
    setUserLogged(!!user);

    const clear = authStatusWatcher.run({
      cookieName: AUTH_COOKIE,
      onLogin: handleLogin,
      onLogout: handleLogout,
      onReset: () => {
        logger.info(`app reset`);
        if (isDev) {
          logger.warn(`SKIPPING APP RESET LOCALLY`);
        } else {
          resetAppState();
        }
      }
    });

    return () => {
      clear();
    };
  }, []);

  const updateUser = useCallback((props: Partial<RegisteredUser>) => {
    setUser(prevState => ({ ...prevState, ...props } as RegisteredUser));
  }, []);

  const plan = (user && user.subscriptionDetails.find(s => s.product === 'WAVE')) || null;
  const isSuperUser = !!plan && (plan.name === 'Staff' || plan.name === 'Jedi');

  return (
    <Provider
      value={{
        anon: { ...anonUser, ...anonUserActions },
        user,
        plan,
        isUserLogged,
        updateUser,
        isSuperUser,
        logOut: handleLogout
      }}
    >
      {children}
    </Provider>
  );
};
export default UserProvider;
