import InlineListItem from '@anm/components/InlineListItem';
import Buttom, { ButtonProps } from '@anm/components/buttons/Button';
import Image from '@anm/components/image/Image';
import { FC } from 'react';

import config from '../../../../config';

import {
  ButtonExampleHeaderBackButton,
  ButtonExampleHeaderLeft,
  ButtonExampleHeaderRight,
  ButtonExampleHeaderTitle,
  ButtonExampleSection
} from './Wrapper';

const { nextDevMode } = config;

export type ButtonExampleHeaderProps = {
  title?: string;
  button?: ButtonProps;
  backLink?: string;
};

const defaultCustomButton: ButtonProps = {
  size: 'big',
  children: 'Sign up free',
  href: 'https://animatron.typeform.com/to/TlO0P7',
  variant: 'silver-border',
  newWindow: true
};

const defaultProps: ButtonExampleHeaderProps = {
  backLink: `${nextDevMode ? '/landings' : ''}/button/examples`,
  title: 'Demo: Create a video using the Wave.video button',
  button: defaultCustomButton
};

const ButtonExampleHeader: FC<ButtonExampleHeaderProps> = ({
  title,
  button,
  backLink
}) => (
  <ButtonExampleSection>
    <InlineListItem
      renderLeft={() => (
        <ButtonExampleHeaderLeft>
          <ButtonExampleHeaderBackButton
            href={backLink || defaultProps.backLink}
          >
            <Image src="images/wave-button/back.svg" />
            Back
          </ButtonExampleHeaderBackButton>
        </ButtonExampleHeaderLeft>
      )}
      renderCenter={() => (
        <ButtonExampleHeaderTitle
          size="small"
          color="white"
          title={title || defaultProps.title!}
        />
      )}
      renderRight={() => (
        <ButtonExampleHeaderRight>
          <Buttom
            {...defaultProps.button!}
            {...button}
            variant="light-blue-lowercase"
          />
        </ButtonExampleHeaderRight>
      )}
    />
  </ButtonExampleSection>
);

export default ButtonExampleHeader;
