import styled, { css } from 'styled-components';

import { media } from '../../../styles/helpers';
import SectionTitle from '../../SectionTitle';

import { TextVariant } from '.';

type TextProps = {
  textVariant: TextVariant;
  isTitleExtraBold?: boolean;
};

const getTitleStyles = (variant: TextVariant) =>
  ({
    medium: css`
      font-size: 48px;
      line-height: 60px;
      margin-top: 20px;
    `,
    big: css`
      font-size: 52px;
      line-height: 64px;
      margin-bottom: 30px;
    `
  }[variant]);

const getDescriptionStyles = (variant: TextVariant) =>
  ({
    medium: css`
      font-size: 20px;
      line-height: 1.6;
      margin-top: 60px;
    `,
    big: css`
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 80px;
    `
  }[variant]);

const SideMediaDataWrapperOnLaptop = media.laptop<{ dataMaxWidth?: number }>`
  margin: 0 40px 0 0;
  flex: ${({ dataMaxWidth }) => `${dataMaxWidth || 600}px`} 0 0;
`;

const TitleHeadOnLaptop = media.laptop<TextProps>`
  text-align: left;
  ${({ textVariant }) => getTitleStyles(textVariant)};
  font-weight: ${({ isTitleExtraBold }) => (isTitleExtraBold ? 900 : 'bold')};
`;

const TitleDescriptionOnLaptop = media.laptop<TextProps>`
  text-align: left;
  ${({ textVariant }) => getDescriptionStyles(textVariant)}
`;
export const SideMediaDataWrapper = styled.div<{ dataMaxWidth?: number }>`
  z-index: 2;
  display: flex;
  position: relative;
  margin: 0 auto 20px;
  flex-direction: column;
  width: 100%;
  ${SideMediaDataWrapperOnLaptop};
`;

export const SideMediaTitle = styled(SectionTitle)<TextProps>`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 28px;
    font-weight: 900;
    line-height: 36px;
    ${TitleHeadOnLaptop};
  }
`;

export const SideMediaDescription = styled(SectionTitle)<TextProps>`
  p {
    margin: 10px 0;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    font-weight: normal;
    margin-top: 20px;
    ${TitleDescriptionOnLaptop};
  }
`;
