import { FC } from 'react';
import styled from 'styled-components';

import Tab, { TabProps } from './Tab';

export type TabItem = Omit<TabProps, 'isActive'> & { name: string };
export type TabsProps = {
  list: TabItem[];
  activeTab: string;
};

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 27px;
`;

const Tabs: FC<TabsProps> = ({ list, activeTab }) => (
  <Wrapper>
    {list.map(({ name, ...otherProps }) => (
      <Tab key={name} {...otherProps} isActive={activeTab === name} />
    ))}
  </Wrapper>
);

export default Tabs;
