import noop from '@anm/helpers/noop';
import { useEffect, useRef, useState } from 'react';

import { HorizontalTabsProps } from './types';

export const useTabHandlers = ({ tabs, defaultTab, onTabChange = noop }: HorizontalTabsProps) => {
  const [activeTab, setActiveTab] = useState(defaultTab);
  const canCallCb = useRef(false);

  const activeView = tabs.indexOf(activeTab);

  useEffect(() => {
    if (!canCallCb.current) {
      canCallCb.current = true;
      return;
    }
    defaultTab !== activeTab && onTabChange(activeView);
  }, [activeView]);

  useEffect(() => {
    if (activeTab === defaultTab) return;

    setActiveTab(defaultTab);
  }, [defaultTab]);

  return [activeView, activeTab, setActiveTab] as const;
};
