import TitleBlock, { TitleDescription } from '@anm/components/TitleBlock';
import { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  border-radius: 20px;
  padding: 40px 0 50px;
  background: #f5f7fa;
  h1,
  h2,
  h3,
  h4 {
    font-size: 40px;
    line-height: 54px;
    text-align: center;
    margin-bottom: 10px;
  }
  ${TitleDescription} {
    text-align: center;
  }
`;

const NoResultMessage: FC = () => (
  <Wrapper>
    <TitleBlock
      level={3}
      title="No results found"
      description="Make sure the spelling is correct or try different keywords"
    />
  </Wrapper>
);

export default NoResultMessage;
