export enum Formats {
  story = 'story',
  square = 'square',
  vertical = 'vertical',
  horizontal = 'horizontal'
}

export const styledFormats = [
  {
    width: 245,
    format: Formats.horizontal,
    icon: {
      src: 'images/video-ratio-landscape-icon_v2.svg',
      height: 24,
      width: 40
    }
  },
  {
    width: 147,
    format: Formats.square,
    icon: {
      src: 'images/video-ratio-square-icon_v2.svg',
      height: 24,
      width: 24
    }
  },
  {
    width: 147,
    format: Formats.vertical,
    icon: {
      src: 'images/video-ratio-vertical_lo-icon_v2.svg',
      height: 24,
      width: 19.2
    }
  },
  {
    width: 147,
    format: Formats.story,
    icon: {
      src: 'images/video-ratio-stories-icon_v2.svg',
      height: 24,
      width: 13.5
    }
  }
];

export const formats = [
  {
    title: 'Horizontal',
    format: '16:9',
    description: 'YouTube, Facebook <br> and Twitter feed',
    previewWidth: 250,
    images: ['twitter', 'facebook', 'youtube'],
    urlFormatParam: 'youtube_horizontal'
  },
  {
    title: 'Square',
    format: '1:1',
    description: 'Instagram and <br> Facebook feed,<br>Pinterest',
    previewWidth: 156,
    images: ['facebook', 'instagram', 'pinterest'],
    urlFormatParam: 'instagram_square'
  },
  {
    title: 'Story',
    format: '9:16',
    description: 'Instagram and Facebook<br> Stories, Reels, Pinterest, <br>TikTok, Snapchat',
    previewWidth: 146,
    images: ['facebook', 'instagram', 'pinterest', 'tikTok', 'snapchat'],
    urlFormatParam: 'instagram_story'
  },
  {
    title: 'Vertical',
    format: '4:5',
    description: 'Facebook, Instagram <br> and Linkedin feed',
    previewWidth: 156,
    images: ['facebook', 'instagram', 'linkedin'],
    urlFormatParam: 'linkedin_vertical_feed'
  }
];
