import { FC } from 'react';

import Image from '@anm/components/image/Image';

import {
  PreviewImageWrapper,
  PreviewItemTitle,
  PreviewListWrapper,
  PreviewListItem,
  SquarePreviewWrapper,
  PreviewLink
} from './Wrapper';

type PreviewItem = {
  link: string;
  title: string;
  preview: string;
  titleLevel?: number;
};

export type PreviewListProps = {
  list: PreviewItem[];
};

const PreviewItem: FC<PreviewItem> = ({ link, title, preview, titleLevel }) => (
  <PreviewListItem key={title}>
    <PreviewLink href={link}>
      <SquarePreviewWrapper>
        <PreviewImageWrapper>
          <Image src={preview} alt={title} />
        </PreviewImageWrapper>
      </SquarePreviewWrapper>
      <PreviewItemTitle title={title} level={titleLevel || 3} />
    </PreviewLink>
  </PreviewListItem>
);

const PreviewList: FC<PreviewListProps> = ({ list }) => (
  <PreviewListWrapper>
    {list.map(listItem => (
      <PreviewItem key={listItem.title} {...listItem} />
    ))}
  </PreviewListWrapper>
);

export default PreviewList;
