import { ReturnOptionValue } from 'helpers';

export type VideoFormat = ReturnOptionValue<typeof videoFormats>;
export type ButtonSize = ReturnOptionValue<typeof buttonSizes>;
export type ButtonColor = ReturnOptionValue<typeof buttonColors>;

export const videoFormats = [
  {
    value: <const>'youtube_horizontal',
    label: '16:9 Horizontal'
  },
  {
    value: <const>'youtube_skippable',
    label: '16:9 Skippable'
  },
  {
    value: <const>'youtube_non_skippable',
    label: '16:9 Non-skippable'
  },
  {
    value: <const>'youtube_mid_roll',
    label: '16:9 Mid-roll'
  },
  {
    value: <const>'youtube_bumper',
    label: '16:9 Bumper'
  },
  {
    value: <const>'twitter_square',
    label: '1:1 Square'
  },
  {
    value: <const>'twitter_horizontal',
    label: '16:9 Horizontal'
  },
  {
    value: <const>'facebook_vertical_feed',
    label: '4:5 Vertical'
  },
  {
    value: <const>'facebook_vertical',
    label: '2:3 Vertical'
  },
  {
    value: <const>'facebook_square',
    label: '1:1 Square'
  },
  {
    value: <const>'facebook_carusel',
    label: '1:1 Carousel'
  },
  {
    value: <const>'facebook_square_articles',
    label: '1:1 Instant articles'
  },
  {
    value: <const>'facebook_portrait',
    label: '9:16 Full portrait'
  },
  {
    value: <const>'facebook_horizontal',
    label: '16:9 Horizontal'
  },
  {
    value: <const>'facebook_horizontal_articles',
    label: '16:9 Instant articles'
  },
  {
    value: <const>'facebook_in_stream',
    label: '16:9 In-stream'
  },
  {
    value: <const>'facebook_cover',
    label: 'Cover'
  },
  {
    value: <const>'instagram_feed',
    label: '4:5 Vertical'
  },
  {
    value: <const>'instagram_square',
    label: '1:1 Square'
  },
  {
    value: <const>'instagram_story',
    label: '9:16 Story'
  },
  {
    value: <const>'instagram_igtv',
    label: '9:16 IGTV'
  },
  {
    value: <const>'linkedin_vertical_feed',
    label: '4:5 Vertical'
  },
  {
    value: <const>'linkedin_vertical',
    label: '2:3 Vertical'
  },
  {
    value: <const>'linkedin_square',
    label: '1:1 Square'
  },
  {
    value: <const>'linkedin_portrait',
    label: '9:16 Portrait'
  },
  {
    value: <const>'linkedin_horizontal',
    label: '16:9 Horizontal'
  },
  {
    value: <const>'linkedin_video_ad',
    label: '16:9 Video ad'
  },
  {
    value: <const>'generic_horizontal',
    label: '16:9 Horizontal'
  },
  {
    value: <const>'generic_square',
    label: '1:1 Square'
  },
  {
    value: <const>'generic_vertical',
    label: '2:3 Vertical'
  },
  {
    value: <const>'generic_vertical_low',
    label: '4:5 Vertical Low'
  },
  {
    value: <const>'generic_vertical_full',
    label: '9:16 Vertical Full'
  },
  {
    value: <const>'generic_standard',
    label: '4:3 Standard'
  }
];
export const defaultVideoFormat = videoFormats[0];

export const buttonSizes = [
  { label: 'Small', value: <const>'small' },
  { label: 'Large', value: <const>'large' }
];
export const defaultButtonSizes = buttonSizes[1];

export const buttonColors = [
  { label: 'Light', value: <const>'light' },
  { label: 'Dark', value: <const>'dark' },
  { label: 'Default', value: <const>'default' }
];
export const defaultButtonColors = buttonColors[2];
