import { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';

import Select, { SelectOption, SelectProps, SelectSizes } from '../../select/Select';
import FormHelperText from '../FormHelperText';

import SelectInputWrapper from './Wrapper';

export type SelectInputProps = FieldRenderProps<SelectOption, HTMLInputElement> & {
  options: SelectOption[];
  label?: string;
  size?: SelectSizes;
  className?: string;
  disabled?: boolean;
  renderOption?: SelectProps['renderOption'];
};

const SelectInput: FC<SelectInputProps> = ({
  meta,
  size,
  label,
  input,
  options,
  disabled,
  className,
  renderOption
}) => {
  return (
    <SelectInputWrapper className={className}>
      {meta.touched && <FormHelperText variant="belowField">{meta.error}</FormHelperText>}
      <Select
        size={size || 'middle'}
        label={(input.value && input.value.label) || label || ''}
        options={options}
        disabled={disabled}
        renderOption={renderOption}
        {...input}
      />
    </SelectInputWrapper>
  );
};

export default SelectInput;
