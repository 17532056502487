import React, { FC } from 'react';

import InlineListItemWrapper from './Wrapper';

type InlineListItemProps = {
  renderLeft: () => JSX.Element;
  renderCenter: () => JSX.Element;
  renderRight: () => JSX.Element;
};

const InlineListItem: FC<InlineListItemProps> = ({
  renderLeft,
  renderCenter,
  renderRight
}) => (
  <InlineListItemWrapper>
    {renderLeft()}
    {renderCenter()}
    {renderRight()}
  </InlineListItemWrapper>
);

export default InlineListItem;
