import copyToClipboard from '@anm/helpers/copyToClipboard';
import { useCallback, useState, FC } from 'react';

import { EmbedButtonProps } from '../EmbedButton';
import EmbedButtonCode from '../EmbedButtonCode';
import EmbedButtonForm, { EmbedButtonFormFields } from '../EmbedButtonForm';
import {
  defaultButtonColors,
  defaultButtonSizes,
  defaultVideoFormat
} from '../EmbedButtonForm/data';
import EmbedButtonPreview from '../EmbedButtonPreview';

import EmbedButtonBlockWrapper, { Preview } from './Wrapper';

export type EmbedButtonBlockProps = {
  headingLevel?: number;
};

const mapFieldsToSettings = (o: any) =>
  Object.keys(o).reduce(
    (acc, key) => ({
      ...acc,
      [key]: o[key].value || o[key]
    }),
    {} as any
  );

const EmbedButtonBlock: FC<EmbedButtonBlockProps> = ({ headingLevel }) => {
  const initialValues: EmbedButtonFormFields = {
    size: defaultButtonSizes,
    color: defaultButtonColors,
    format: defaultVideoFormat,
    apiKey: ''
  };
  const [settings, setSettings] = useState<EmbedButtonProps>(
    mapFieldsToSettings(initialValues)
  );
  const [code, setCode] = useState<string>();
  const handleCopy = useCallback(() => {
    code && copyToClipboard(code);
  }, [code]);

  const handleAutoSave = (fields: EmbedButtonFormFields) => {
    setSettings({ ...settings, ...mapFieldsToSettings(fields) });
  };

  return (
    <EmbedButtonBlockWrapper>
      <EmbedButtonForm
        onSubmit={handleCopy}
        onAutoSave={handleAutoSave}
        {...{ headingLevel, initialValues }}
      />
      <Preview>
        <EmbedButtonCode onChange={setCode} {...settings}></EmbedButtonCode>
        <EmbedButtonPreview {...settings}>Make video</EmbedButtonPreview>
      </Preview>
    </EmbedButtonBlockWrapper>
  );
};

export default EmbedButtonBlock;
