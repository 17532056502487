import Container from '@anm/components/Container';
import Button from '@anm/components/buttons/Button';
import RecorderModal from '@anm/components/stream/RecorderModal';
import RecordingSelectorModal from '@anm/components/stream/RecordingSelectorModal';
import MediaTester from '@anm/components/video/MediaTester';
import useSwitchState from '@anm/hooks/useSwitchState';
import { FC } from 'react';

import lpConfig from '../../../config';
import LandingButton, { LandingButtonProps } from '../../LandingButton';
import useButtonAuthHandler from '../../LandingButton/components/ButtonAuth/hooks/useButtonAuthHandler';

import Wrapper, { ButtonContainer, SectionTitleWrapper } from './Wrapper';
import { Features } from '@anm/shared/features';

export type MediaTesterSectionProps = {
  title: string;
  description: string;
  headingLevel?: number;
  button?: LandingButtonProps;
};

const MediaTesterSection: FC<MediaTesterSectionProps> = ({ headingLevel = 2, button, title, description }) => {
  const [isRecordingOptionsModal, openRecordingOptions, closeRecordingOptions] = useSwitchState();
  const [isRecorderModal, openRecorder, closeRecorder] = useSwitchState();

  const [advancedCb, advancedHref] = useButtonAuthHandler(button as LandingButtonProps);
  const handelSimpleSelect = () => {
    openRecorder();
    closeRecordingOptions();
  };

  return (
    <>
      {isRecordingOptionsModal && (
        <RecordingSelectorModal
          advancedLink={{
            href: advancedHref,
            handler: advancedCb
          }}
          onSimpleSelect={handelSimpleSelect}
          title="Please choose how would you like to record:"
          onModalClose={closeRecordingOptions}
        />
      )}
      {isRecorderModal && <RecorderModal waveUrl={lpConfig.waveUrl} onModalClose={closeRecorder} />}
      <Wrapper>
        <Container fullWidth>
          <SectionTitleWrapper level={headingLevel} color="black" {...{ title, description }} />
          <MediaTester />
          <ButtonContainer>
            {!Features.videoRecorderChooser ? (
              <LandingButton size="large" variant="orange" {...button} />
            ) : (
              <Button size="large" variant="orange" onClick={openRecordingOptions}>
                Record video
              </Button>
            )}
          </ButtonContainer>
        </Container>
      </Wrapper>
    </>
  );
};

export default MediaTesterSection;
