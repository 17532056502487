import { FC } from 'react';
import Slider, { Settings } from 'react-slick';

import { AccordionMediaProps, ArrowProps } from '../types';

import Slide from './Slide';
import { CarouselWrapper, SliderArrowWrapper } from './Wrapper';

const SliderArrow = (props: ArrowProps) => (
  <SliderArrowWrapper {...props} className="slider-custom-arrow" />
);

const settings: Settings = {
  speed: 700,
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  focusOnSelect: true,
  prevArrow: <SliderArrow direction="left" className="slider-custom-arrow" />,
  nextArrow: <SliderArrow direction="right" className="slider-custom-arrow" />
};

type CarouselProps = AccordionMediaProps;

const Carousel: FC<CarouselProps> = ({ list, headingLevel }) => (
  <CarouselWrapper>
    <Slider {...settings}>
      {list.map(slideProps => (
        <Slide {...{ ...slideProps, headingLevel }} />
      ))}
    </Slider>
  </CarouselWrapper>
);

export default Carousel;
