import Loader from '@anm/components/Loader';
import Image from '@anm/components/image/Image';
import formatTime from '@anm/helpers/time/formatTime';
import { FC } from 'react';
import styled from 'styled-components';

export type PreviewProps = { src?: string; title?: string; duration?: number };

const Wrapper = styled.div`
  height: 100%;
`;
const Title = styled.h4`
  margin-top: 25px;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  color: #292a2b;
`;
const ImageWrapper = styled(Image)`
  border: 1px solid rgba(1, 83, 130, 0.2);
  border-radius: 10px;
`;
const Duration = styled.div`
  margin-top: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #292a2b;
`;
const LoaderWrapper = styled.div`
  margin: 100px 0 50px;
`;

const Preview: FC<PreviewProps> = ({ src, title, duration }) => {
  const isPending = !(src || title || duration);
  return (
    <Wrapper>
      {isPending && (
        <LoaderWrapper>
          <Loader size="medium" color="blue" />
        </LoaderWrapper>
      )}
      {src && <ImageWrapper src={src} />}
      {title && <Title>{title}</Title>}
      {duration && <Duration>Duration {formatTime(duration)}</Duration>}{' '}
    </Wrapper>
  );
};
export default Preview;
