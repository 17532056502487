import { useCallback, useEffect, useRef } from 'react';
import { ReturnFunctionArg } from 'types/helpers';

type Event = Plyr.StandardEvent | Plyr.Html5Event | Plyr.YoutubeEvent;
type Callback = (this: Plyr, event?: Plyr.PlyrEvent) => void;

const usePlyrEvents = (player: Plyr) => {
  const eventsRef = useRef<ReturnFunctionArg<Plyr['on']>[]>([]);
  const addEvent = useCallback((event: Event, callback: Callback) => {
    eventsRef.current.push([event, callback]);
  }, []);

  useEffect(() => {
    if (!player.on) return;
    eventsRef.current.forEach(props => player.on(...props));
    return () => {
      eventsRef.current.forEach(props => player.off(...props));
    };
  }, [player.on]);

  return addEvent;
};

export default usePlyrEvents;
