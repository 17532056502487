import getCaps from '@anm/data/streaming/destinationsCaps';
import TitleBlock from '@anm/components/TitleBlock';
import Image from '@anm/components/image/Image';
import { FC } from 'react';

import { Wrapper, ContainerWrapper, DestinationIconsWrapper, DestinationLabelsContainer } from './Wrapper';

const destinationImages = [
  getCaps('FACEBOOK').iconUrl,
  getCaps('YOUTUBE').iconUrl,
  getCaps('TWITCH').iconUrl,
  getCaps('RTMP', 'AmazonLive').iconUrl,
  getCaps('LINKEDIN').iconUrl,
  getCaps('INSTAGRAM').iconUrl,
  getCaps('RTMP').iconUrl
];

const destinationTexts = [
  'Facebook page',
  'Facebook group',
  'Facebook profile',
  'Instagram',
  'Youtube',
  'Twitch',
  'Linkedin profile',
  'Linkedin page',
  'Amazon Live',
  'Custom RTMP'
];

export type TStreamDestinations = {
  background?: string;
};

const StreamDestinations: FC<TStreamDestinations> = ({ background = 'var(--blue_20)' }) => {
  return (
    <Wrapper {...{ background }}>
      <ContainerWrapper size={1150}>
        <TitleBlock title="Stream to multiple destinations" level={2} />
        <DestinationIconsWrapper>
          {destinationImages.map(src => (
            <Image key={src} src={src} alt="destination" />
          ))}
        </DestinationIconsWrapper>
        <DestinationLabelsContainer>
          {destinationTexts.map(text => (
            <p key={text}>{text}</p>
          ))}
        </DestinationLabelsContainer>
      </ContainerWrapper>
    </Wrapper>
  );
};

export default StreamDestinations;
