import { ApiConfig } from '../types';

import { ObjectWithRequestHOF } from './bindFor';
import createApiInstance from './createApiInstance';
import presetAxiosInstance from './presetAxiosInstance';

const createOneModuleInstance = <T extends ObjectWithRequestHOF<T>>(module: T) => {
  const genApi = (apiConfig: ApiConfig) => {
    const { bind, axiosInstance } = presetAxiosInstance(apiConfig);
    return { axiosInstance, ...bind(module) };
  };

  return createApiInstance(genApi);
};
export default createOneModuleInstance;
