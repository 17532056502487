import { UserContext } from '@anm/components/user/UserProvider';
import { useContext, MouseEvent, useRef } from 'react';

const useButtonHandler = () => {
  const labelRef = useRef<HTMLLabelElement>(null);
  const { anon, isUserLogged } = useContext(UserContext);

  const selectFile = () => {
    const inputFile = labelRef.current;

    if (!inputFile) return;

    inputFile.click();
  };

  const handleClick = (e: MouseEvent) => {
    if (isUserLogged || anon.isPending) return;
    e.preventDefault();

    anon.signupAndRun(selectFile);
  };

  return [labelRef, handleClick] as const;
};

export default useButtonHandler;
