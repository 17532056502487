import * as analytics from '@anm/analytic';
import { ApiError } from '@anm/api';
import { CompleteUpload, CreateUploadRequest, CreateUploadResponse, PartInfo } from '@anm/api/modules/uploads';
import cutFileExtension from '@anm/helpers/cutFileExtension';
import getExtensionFromPath from '@anm/helpers/getExtensionFromPath';
import getVideoInfo from '@anm/helpers/getVideoInfo';
import noop from '@anm/helpers/noop';
import asyncEntity from '@anm/helpers/redux/asyncEntity';
import { takeType } from '@anm/helpers/saga/typesafe-actions';
import { isFreeUser } from '@anm/helpers/user/isUser';
import { uploadsApi } from '@anm/store/modules/uploads/api';
import throttle from 'lodash/fp/throttle';
import { all, call, fork, put, select, take } from 'typed-redux-saga';

import { getExtension, uploadsActions, uploadsSelectors } from '..';
import { notify } from '../../appNotifications/actions';
import { splitFile } from '../helpers';
import uploadQueue from '../helpers/uploadQueue';
import { selectLatestUpload } from '../selectors';
import { CreateUploadProps, FetchUploadRequest, NewUploadProps, PartUploadProps } from '../types';
import checkCanUpload from '../uploadRestrictions';

const createUploadRequest = (data: CreateUploadRequest) => uploadsApi().createUpload(data);
const createUploadAsync = asyncEntity(uploadsActions.createUploadAsync, createUploadRequest);

const completeUploadRequest = ({ fileId, eTags, multipartUploadId }: CompleteUpload) =>
  uploadsApi().completeMultipartUpload({ fileId, eTags, multipartUploadId });

const fetchUploadMediaRequest = (params: FetchUploadRequest) => uploadsApi().getUploadMedia(params);
const fetchUploadAsync = asyncEntity(uploadsActions.fetchUploadMediaAsync, fetchUploadMediaRequest);

const completeUploadAsync = asyncEntity(uploadsActions.completeMultipartUploadAsync, completeUploadRequest);

function* createUpload({ file, meta, isUploadFile = true, createHost, id }: NewUploadProps) {
  const folderPath = createHost ? '' : yield* select(uploadsSelectors.selectCurrentFolderPath) || '/';

  const data: CreateUploadRequest = {
    id,
    meta: meta?.name
      ? meta
      : {
          name: cutFileExtension(file?.name || ''),
          size: file?.size
        },
    extension: getExtension(file),
    folderPath,
    createHost,
    isEmbedded: false,
    projectType: 'upload',
    isMultipartUpload: isUploadFile
  };

  yield* fork(createUploadAsync, data);
}

function* createUploads({
  uploads: files,
  createHost,
  isUploadFile,
  user,
  hostingStats,
  canIgnoreStorage
}: CreateUploadProps) {
  const waveSubscription = user?.subscriptionDetails?.filter(({ product }) => product === 'WAVE')[0];
  const isUserFree = isFreeUser(user);

  const canPurchaseStorage = waveSubscription?.features.Hosting.canPurchaseStorage;
  const { maxSize = 0, maxSeconds = 0 } = waveSubscription?.features.ImportVideo || {};
  const isMaxPlan = !waveSubscription?.upgrades?.length;
  const canUpgrade = !isMaxPlan;

  for (const f of files) {
    const canUpload = yield* call(checkCanUpload, {
      file: f.file,
      limits: {
        maxSize,
        maxSeconds
      },
      hostingStats,
      isFreeUser: isUserFree,
      objectUrl: f.objectUrl,
      canUpgrade,
      canIgnoreStorage,
      extension: getExtension(f.file),
      canPurchaseStorage
    });

    if (canUpload) {
      yield* createUpload({ ...f, createHost, isUploadFile });
    }
  }
}

function* trackFailedUpload(error: string) {
  const upload = yield* select(selectLatestUpload);

  const file = upload.file;

  if (!file || !upload.createHost) return;

  const extension = getExtensionFromPath(file.name) || '';

  const { width, height, duration } = yield getVideoInfo({ src: upload.url, name: file.name });

  analytics.trackCreateProjectFromUpload({
    error,
    width,
    height,
    duration,
    extension,
    size: file.size,
    uploadStatus: 'failed',
    projectStatus: 'failed'
  });
}

function* makeMultipartUpload(payload: CreateUploadResponse) {
  const { data, multipartUploadId } = payload;
  const { id, meta } = data.upload;
  const fileId = payload.data.files[0].file.id;
  const uploadProps = yield* select(s => uploadsSelectors.selectUploadingFile(s, id));

  if (!uploadProps?.file || !uploadProps.uploadId || !multipartUploadId || !uploadProps.isUploadFile) return;

  let loaded = {} as Record<string, number>;
  let partsInfo: PartInfo[] = [];

  const chunks = splitFile(uploadProps.file);

  try {
    const requests = yield* all(
      chunks.map(chunk =>
        call(uploadPart, {
          id,
          chunk: chunk.blob,
          fileId,
          loaded,
          name: meta.name,
          partsInfo,
          totalSize: uploadProps.file!.size,
          partNumber: chunks.findIndex(c => c.id === chunk.id) + 1,
          method: payload.request.method,
          multipartUploadId
        })
      )
    );

    yield uploadQueue(requests);

    yield* put(
      uploadsActions.completeMultipartUpload({
        fileId,
        multipartUploadId,
        uploadId: uploadProps.uploadId,
        eTags: partsInfo.sort((p1, p2) => p1.partNumber - p2.partNumber)
      })
    );

    loaded = {};
    partsInfo = [];
  } catch (e) {
    yield put(uploadsActions.uploadFileAsync.failure(e as ApiError));
  }
}

function* uploadPart({
  id,
  name,
  chunk,
  fileId,
  loaded,
  multipartUploadId,
  method,
  partsInfo,
  totalSize,
  partNumber
}: PartUploadProps) {
  const uploadProps = yield* select(s => uploadsSelectors.selectUploadingFile(s, id));
  const listener = yield* select(uploadsSelectors.selectProgressListener);

  if (!uploadProps) return () => Promise.resolve();

  const uploadData = {
    name,
    method,
    totalSize,
    file: chunk,
    cancelToken: uploadProps.cancelSource ? uploadProps.cancelSource?.token : undefined,
    onUploadProgress: listener
      ? throttle(200)((e: ProgressEvent) => {
          loaded[partNumber] = Math.max(e.loaded, loaded[partNumber] || 0);
          const totalLoaded = Object.keys(loaded).reduce((acc, i) => acc + loaded[i], 0);
          const progress = Math.round((totalLoaded / totalSize) * 100);
          listener({ id, progress });
        })
      : noop
  };

  return async () => {
    const uploadUrl = await uploadsApi().createUploadUrlForPart({
      fileId,
      multipartUploadId,
      partNumber
    });

    const url = uploadUrl.request.url;
    const res = await uploadsApi().uploadFile({ ...uploadData, url });
    const eTag = (res as any).headers.etag;

    partsInfo.push({
      partNumber,
      eTag: eTag.substring(1, eTag.length - 1)
    });

    return res;
  };
}

function* watchCreateUpload() {
  while (true) {
    const { payload } = yield* take(takeType(uploadsActions.createUpload));

    yield* call(createUploads, payload);
  }
}

function* watchFetchMedia() {
  while (true) {
    const { payload } = yield* take(takeType(uploadsActions.fetchUploadMedia));

    yield* fork(fetchUploadAsync, payload);
  }
}

function* watchCreateUploadSuccess() {
  while (true) {
    const { payload } = yield* take(takeType(uploadsActions.createUploadAsync.success));

    yield* fork(makeMultipartUpload, payload);
  }
}

function* watchCreateUploadFailure() {
  while (true) {
    const { payload } = yield* take(takeType(uploadsActions.createUploadAsync.failure));

    notify({
      type: 'error',
      timeout: 5000,
      title: 'Failed to upload media'
    });

    yield call(trackFailedUpload, payload.message);
  }
}

function* watchCancelUploading() {
  while (true) {
    const {
      payload: { uploadId }
    } = yield* take(takeType(uploadsActions.cancelUploading));

    const props = yield* select(s => uploadsSelectors.selectUploadingFile(s, uploadId));

    if (props) {
      const cancelSource = props.cancelSource;

      yield* put(uploadsActions.clearCancelToken(uploadId));

      cancelSource?.cancel();

      yield* put(uploadsActions.removeListItem(uploadId));
      yield* put(uploadsActions.clearUploadedFiles());

      notify({
        type: 'warning',
        timeout: 5000,
        title: 'Uploading canceled'
      });
    }
  }
}

function* watchCompleteMultipartUpload() {
  while (true) {
    const { payload } = yield* take(takeType(uploadsActions.completeMultipartUpload));

    yield* fork(completeUploadAsync, payload);
  }
}

const watchers = [
  watchFetchMedia,
  watchCreateUpload,
  watchCancelUploading,
  watchCreateUploadSuccess,
  watchCreateUploadFailure,
  watchCompleteMultipartUpload
];

export default watchers;
