import { useRef, useState } from 'react';

const useSelect = () => {
  const selectRef = useRef<HTMLDivElement>(null);
  const [isOpened, setIsOpened] = useState(false);

  return { selectRef, isOpened, setIsOpened };
};

export default useSelect;
