import styled, { css } from 'styled-components';

import { LabelPositionVariant, LabelStyleVariant } from '.';

type LabelProps = {
  positionVariant: LabelPositionVariant;
  styleVariant: LabelStyleVariant;
};

export const Label = styled.div<LabelProps>`
  height: 24px;
  line-height: 24px;
  font-size: 13px;
  padding: 0 5px;
  font-weight: normal;
  border-radius: 4px;
  position: absolute;
  pointer-events: none;
  text-transform: none;

  ${({ positionVariant }) => positionVariants[positionVariant]}
  ${({ styleVariant }) => styleVariants[styleVariant]}
`;

const positionVariants: { [key in LabelPositionVariant]: ReturnType<typeof css> } = {
  'inline-right': css`
    top: 13px;
    right: 10px;
  `,
  'top-right': css`
    top: -6px;
    right: -6px;
    background: #fff;
  `
};

const styleVariants: { [key in LabelStyleVariant]: ReturnType<typeof css> } = {
  'beta-blue': css`
    color: var(--blue_100);
    border: 1px solid currentColor;
  `,
  'new-yellow': css`
    background-color: rgb(255, 242, 0);
    color: var(--gray_900);
  `
};
