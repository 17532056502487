import Select, { SelectOption, SelectProps, SelectValueContainer } from '@anm/components/select/Select';
import { absoluteCenter } from '@anm/styles/helpers';
import { FC } from 'react';
import styled from 'styled-components';

export type DropdownProps = Pick<SelectProps, 'options' | 'onChange'> & { current: SelectOption };

export const Upgrade = styled.div`
  ${absoluteCenter}
  left: 120px;
  width: 75px;

  padding: 4px 5px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  max-width: 70px;

  color: #292a2b;
  background: #ffea00;
  border-radius: 4px;
`;

const SelectWrapper = styled(Select)`
  margin-right: 10px;

  ${SelectValueContainer} {
    padding: 10px 15px;
    min-width: 200px;
  }
`;

const Dropdown: FC<DropdownProps> = ({ current, options, onChange }) => (
  <SelectWrapper name="media" size="middle" value={current} options={options} onChange={onChange} />
);

export default Dropdown;
