import { MediaInfoFormat } from '@anm/api/modules/youtube';
import { UserContext } from '@anm/components/user/UserProvider';
import { isGuestUser } from '@anm/helpers/user/isUser';
import useStorageCheck from '@anm/hooks/useStorageCheck';
import { compose } from 'lodash/fp';
import { useCallback, useContext } from 'react';

import { isFHD, isHD } from '../helpers/resolution';

type FindResolutionProps = {
  isUserGuest: boolean;
  mediaFormats?: MediaInfoFormat[];
};
const findResolution = ({ mediaFormats, isUserGuest }: FindResolutionProps) =>
  mediaFormats?.find(({ format_note, ext }) => (ext === 'mp4' && isUserGuest ? isHD(format_note) : isFHD(format_note)));
const takeSizeFromFormat = ({ filesize, filesize_approx } = {} as MediaInfoFormat) => filesize || filesize_approx;
const getUploadSize = compose(takeSizeFromFormat, findResolution);

const useMediaStorageCheck = (mediaFormats?: MediaInfoFormat[]) => {
  const { user } = useContext(UserContext);
  const isUserGuest = isGuestUser(user);

  const uploadedSize = getUploadSize({ isUserGuest, mediaFormats });
  const [checkStorage] = useStorageCheck();

  const check = useCallback(async () => {
    const error = await checkStorage(uploadedSize);
    if (error) return Promise.reject({ code: 507, message: 'Insufficient Storage' });
  }, [checkStorage, uploadedSize]);

  return check;
};

export default useMediaStorageCheck;
