import { isEmpty, isEqual } from 'lodash/fp';
import diff from 'object-diff';
import { useCallback, useState } from 'react';

type UseDiffSaveProps<T extends unknown> = {
  initValues?: T;
  onSave: (values: T) => void;
};

const useDiffSave = <T extends unknown>({ initValues, onSave }: UseDiffSaveProps<T>) => {
  const [valuesLocal, setValues] = useState(initValues);

  const save = useCallback(
    (values: T) => {
      if (isEqual(valuesLocal, values)) return;

      const difference = diff(valuesLocal, values);
      if (!isEmpty(difference)) {
        setValues(values);

        onSave(difference);
      }
    },
    [valuesLocal, onSave]
  );

  return save;
};

export default useDiffSave;
