import { getUpgradeLinkProps } from '@anm/helpers/upgradeLink';
import { isFreeUser } from '@anm/helpers/user/isUser';
import { uploadsActions } from '@anm/store/modules/uploads';
import { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { UserContext } from '../user/UserProvider';

import { ButtonsWrapper, CancelButton, ModalTitle, ModalWrapper, UpgradeButton } from './Wrapper';

export type ActionType = 'stream' | 'upload';

type ComponentProps = {
  onClose: () => void;
  actionType: ActionType;
};

const StorageErrorModal: FC<ComponentProps> = ({ actionType, onClose }) => {
  const { user } = useContext(UserContext);
  const dispatch = useDispatch();
  const descriptionStart = actionType === 'stream' ? 'To create new stream' : 'To upload new file';

  const descriptionEnding = `please either delete some of the videos, upgrade to premium plan or update your payment info to enable storage charges.`;

  const closeModal = () => {
    dispatch(uploadsActions.clearUploadedFiles());
    onClose();
  };

  return (
    <ModalWrapper onModalClose={closeModal}>
      <ModalTitle title="Video storage is full" description={`${descriptionStart}, ${descriptionEnding}`} />
      <ButtonsWrapper>
        <CancelButton variant="white-blue-border-share" size="medium" onClick={closeModal}>
          Cancel
        </CancelButton>
        <UpgradeButton variant="light-blue" size="medium" {...getUpgradeLinkProps()}>
          Upgrade
        </UpgradeButton>
        {user && !isFreeUser(user) && (
          <UpgradeButton variant="light-blue" size="medium" href="/subscription">
            Update payment info
          </UpgradeButton>
        )}
      </ButtonsWrapper>
    </ModalWrapper>
  );
};

export default StorageErrorModal;
