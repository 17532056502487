import { useEffect, useState, FC } from 'react';
import styled from 'styled-components';

import { LandingData } from '../../../features/getLanding';

const LandingsListWrapper = styled.section`
  padding: 100px 0;

  li {
    list-style-type: decimal;
    margin-bottom: 10px;
  }
`;

const LandingsList: FC = () => {
  const [landings, setLandings] = useState<LandingData[]>([]);

  useEffect(() => {
    (async () => {
      const { getAllLanding } = await import('../../../features/getLanding');
      const allLandings = await getAllLanding();
      setLandings(allLandings);
    })();
  }, []);

  return (
    <LandingsListWrapper>
      {!landings.length ? (
        <span>Loading...</span>
      ) : (
        <ol>
          {landings.map(({ url }) => {
            if (url === 'list' || url === 'all') return;

            return (
              <li key={url}>
                <a href={url}>{url}</a>
              </li>
            );
          })}
        </ol>
      )}
    </LandingsListWrapper>
  );
};

export default LandingsList;
