import styled, { css } from 'styled-components';

import getSafeImgUrl from '../../../helpers/images/getSafeImgUrl';

type LandingVideoWrapperProps = {
  imageUrl?: string;
  withIconText?: string;
}

const LandingVideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0 auto;
  }

  ${({ imageUrl }: LandingVideoWrapperProps) =>
    imageUrl &&
    css`
      background: url(${getSafeImgUrl(imageUrl)}) no-repeat center / cover;
    `};

  ${({ withIconText }: LandingVideoWrapperProps) =>
    withIconText &&
    css`
      display: flex;
      align-items: center;
      cursor: pointer;
      width: auto;

      span {
        display: block;
        margin-left: 14px;
        font-size: 20px;
        font-weight: bold;
        color: #292a2b;
      }
    `};

  .w-video-wrapper {
    background: transparent !important;
  }
`;

export default LandingVideoWrapper;
