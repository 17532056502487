import { useEffect, useState } from 'react';

const useEventPending = (isPending: boolean, onEvent: () => void) => {
  const [isEventPending, setEventPending] = useState(false);

  useEffect(() => {
    if (isPending || !isEventPending) return;
    onEvent();
    setEventPending(false);
  }, [isPending]);

  return () => setEventPending(true);
};

export default useEventPending;
