import styled from 'styled-components';

type WrapperProps = {
  avatarSize: number;
};

const setSizeByProps = ({ avatarSize }: WrapperProps) => `${avatarSize}px`;

export const AvatarWrapper = styled.div<WrapperProps>`
  width: ${setSizeByProps};
  height: ${setSizeByProps};
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;
