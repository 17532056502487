import styled, { css } from 'styled-components';

import getSafeImgUrl from '../../../helpers/images/getSafeImgUrl';
import { media } from '../../../styles/helpers';

type TopSectionMediaWrapperProps = {
  rotateImage: boolean;
  imageMaxWidth?: number;
  sectionPicture?: string;
};

const ImageOnLaptop = media.laptop`
    max-width: ${({ imageMaxWidth }: TopSectionMediaWrapperProps) =>
      imageMaxWidth || 228}px;

`;

const TopSectionMediaWrapperOnLaptop = media.laptop`
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    max-width: ${({ imageMaxWidth }: TopSectionMediaWrapperProps) =>
      imageMaxWidth || 300}px;
`;

const imageBgOnLaptop = media.laptop`
    display: none;
`;

const TopSectionMediaWrapper = styled.div<TopSectionMediaWrapperProps>`
  display: flex;
  margin: 25px 0;
  align-items: center;
  justify-content: center;
  position: relative;

  ${TopSectionMediaWrapperOnLaptop};

  &:after {
    display: block;
    content: '';
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 388px;
    height: 225px;

    ${({ sectionPicture }) =>
      sectionPicture &&
      css`
        background: url(${getSafeImgUrl(sectionPicture)}) no-repeat center
          bottom / contain;
      `};

    ${imageBgOnLaptop};
  }

  & > img,
  & > picture {
    display: block;
    transform: ${({ rotateImage }) => (rotateImage ? 'rotate(5deg)' : 'none')};
    filter: drop-shadow(0px 0px 50px rgba(33, 34, 81, 0.15));
    max-width: ${({ imageMaxWidth }) => imageMaxWidth || 118}px;
    ${ImageOnLaptop};
  }

  img {
    margin-bottom: -4px;
  }

  & + div {
    order: 2;
  }
`;

export default TopSectionMediaWrapper;
