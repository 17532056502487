import Modal from '@anm/components/modals/Modal';
import isMobile from '@anm/helpers/is/isMobile';
import React, { FC } from 'react';

type ModalWrapperProps = {
  isOpenContent: boolean;
  isModalOnMobile: boolean;
  closeDropdown(): void;
};

const ModalWrapper: FC<ModalWrapperProps> = ({ children, isOpenContent, closeDropdown }) => {
  if (!isOpenContent) return null;

  return <Modal onModalClose={closeDropdown}>{children}</Modal>;
};

const ContentWrapper: FC<ModalWrapperProps> = props => {
  const canShowModal = !!props.isModalOnMobile && isMobile();

  if (canShowModal) return <ModalWrapper {...props} />;

  return <>{props.children}</>;
};

export default ContentWrapper;
