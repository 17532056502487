import { AUTH_COOKIE } from '@anm/constants/auth';
import * as cookie from '@anm/helpers/cookie';

export const getAuthToken = () => {
  const token = cookie.get(AUTH_COOKIE);

  return token || null;
};

export const setAuthToken = (token?: string) => {
  cookie.set(AUTH_COOKIE, token, 30);

  return token || null;
};
