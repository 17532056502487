import { SelectOption } from '@anm/components/select/Select';
import { useEffect, useState } from 'react';

import useMediaDevicesControls from '../useMediaDevicesControls';

const mapDevicesInfoToSelectOption = (mediaDevicesInfo: MediaDeviceInfo[]): SelectOption[] =>
  mediaDevicesInfo.map(({ deviceId, label }) => ({ label, value: deviceId }));

const useMediaDevicesSelector = () => {
  const { mics, webcams, speakers } = useMediaDevicesControls();

  const [webcamsOptions, setWebcamsOptions] = useState<SelectOption[]>();
  const [micsOptions, setMicsOptions] = useState<SelectOption[]>();
  const [speakersOptions, setSpeakersOptions] = useState<SelectOption[]>();

  useEffect(() => {
    webcams && setWebcamsOptions(mapDevicesInfoToSelectOption(webcams));
    mics && setMicsOptions(mapDevicesInfoToSelectOption(mics));
    speakers && setSpeakersOptions(mapDevicesInfoToSelectOption(speakers));
  }, [webcams, mics, speakers]);

  return [webcamsOptions, micsOptions, speakersOptions] as const;
};

export default useMediaDevicesSelector;
