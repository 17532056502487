import React, { useEffect, useState, FC } from 'react';

import { SlideWrapper } from '../Wrapper';

type SlideProps = {
  canShow: boolean;
  onAnimationEnd: () => void;
};

const Slide: FC<SlideProps> = ({ canShow, children, onAnimationEnd }) => {
  const [canRender, setCanRender] = useState(canShow);

  useEffect(() => {
    canShow && setCanRender(true);
  }, [canShow]);

  const handleAnimationEnd = () => !canShow && onAnimationEnd();

  if (!canRender) return null;

  return (
    <SlideWrapper canShow={canShow} onAnimationEnd={handleAnimationEnd}>
      {children}
    </SlideWrapper>
  );
};

export default Slide;
