import { WorkspaceContext } from '@anm/contexts/WorkspaceContext';
import noop from '@anm/helpers/noop';
import { b64EncodeUnicode } from '@anm/helpers/string/base64/index';
import { useContext, useEffect } from 'react';

type Status = 'recorded' | 'canceled';

type Message = {
  clips: any;
  status: Status;
};

type Data = {
  id: string;
  message: Message;
};

type UseListenEventsProps = { waveUrl: string; folderId?: string | number; onClose?: () => void };
const useListenEvents = ({ waveUrl, folderId, onClose = noop }: UseListenEventsProps) => {
  const { workspace } = useContext(WorkspaceContext);

  const onMessage = ({ data }: MessageEvent<Data>) => {
    if (data.id !== 'video-record-data') return;

    if (data.message.status === 'recorded') {
      const jsonString = JSON.stringify(data.message);
      const base64 = b64EncodeUnicode(jsonString);
      const folderParam = folderId ? `&folderId=${folderId}` : '';

      window.open(`${waveUrl}editor/#new=${base64}${folderParam}&workspace=${workspace}`, '_self');
      setTimeout(onClose, 0);
    }
  };

  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);
};

export default useListenEvents;
