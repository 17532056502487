import { ANM_LOCAL_STORAGE } from '@anm/constants/auth';
import localStorage from '@anm/helpers/localStorage';
import { safeParse } from '@anm/helpers/safeOperation';
import { User } from 'types/user';

const getAnmState = () => {
  const state = localStorage().getItem(ANM_LOCAL_STORAGE);

  return state ? safeParse(state) : null;
};

export const getUserProfile = () => {
  const state = localStorage().getItem(ANM_LOCAL_STORAGE);
  if (!state) return null;

  return safeParse(state)?.user?.profile as User;
};

export const saveUserProfile = (userProfile: User) => {
  const anmState = getAnmState();

  const newState = {
    ...anmState,
    user: { ...anmState?.user, profile: userProfile }
  };
  localStorage().setItem(ANM_LOCAL_STORAGE, JSON.stringify(newState));
};
