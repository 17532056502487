import { DEFAULT_CONFIGS } from '@anm/data/hostingDefaults';
import { BlockedManifest, VideoData } from '@anm/HostingPlayer';

const getBlockedVideoData = (manifest: BlockedManifest): VideoData => ({
  audio: {
    path: ''
  },
  uploadId: '',
  projectId: '',
  bestQualityVideo: '',
  sizes: {
    width: manifest.meta.width,
    height: manifest.meta.height
  },
  meta: {
    name: '',
    duration: 1,
    uploadDate: '',
    description: ''
  },
  configs: DEFAULT_CONFIGS,
  customConfigs: {},
  sources: [],
  password: {},
  poster: {
    posterUrl: ''
  }
});

export default getBlockedVideoData;
