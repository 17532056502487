import styled from 'styled-components';

const UserBlockWrapper = styled.div`
  display: flex;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  align-items: center;
  border-bottom: solid 1px rgba(203, 220, 229, 0.5);
  margin: 18px 20px 0;
  padding-bottom: 16px;
`;

export default UserBlockWrapper;
