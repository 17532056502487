import { stretchBlock } from '@anm/styles/helpers';
import styled from 'styled-components';

const Backdrop = styled.div`
  ${stretchBlock};
  z-index: 1;
  cursor: pointer;
  position: fixed;
  pointer-events: auto;
  background-color: rgba(41, 42, 43, 0.6);
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
  }
`;

export default Backdrop;
