import { FC } from 'react';
import styled from 'styled-components';

import DesktopTabs, { DesktopTabsProps } from '../DesktopTabs';

export type RightSideProps = DesktopTabsProps & {};

const Wrapper = styled.div`
  max-width: 600px;
  width: 1000px;
`;
const RightSide: FC<RightSideProps> = props => (
  <Wrapper>
    <DesktopTabs {...props} />
  </Wrapper>
);

export default RightSide;
