import { noop } from 'lodash/fp';
import { useCallback, useRef } from 'react';

const useCancelPromise = () => {
  const cancelMsg = 'cancelled';
  const cancelResolveRef = useRef<() => void>(noop);

  const runPromise = useCallback(async <T extends unknown>(asyncAction: () => Promise<T>) => {
    const cancelPromise = new Promise<'cancelled'>(res => (cancelResolveRef.current = () => res(cancelMsg)));
    const result = await Promise.race([asyncAction(), cancelPromise]);
    if (result === cancelMsg) {
      throw new Error(cancelMsg);
    }
    return result;
  }, []);

  return {
    cancelPromise: () => cancelResolveRef.current(),
    runPromise
  };
};

export default useCancelPromise;
