import CardModal from '@anm/components/modals/CardModal';
import { formats } from '@anm/data/videoTemplatesFormatModal';
import { FC } from 'react';

import FormatItem from './FormatItem';
import Wrapper, { Content, MainTitle } from './Wrapper';
import { FormatModalProps } from './types';

export * from './Wrapper';
export * from './types';

const FormatModal: FC<FormatModalProps> = ({ getFormatUrl, onModalClose, linkTarget = '_blank', onFormatClick }) => (
  <CardModal {...{ onModalClose }} cardSettings={{ isPadding: false, borderRadius: 10 }}>
    <Wrapper>
      <MainTitle title="Choose a video format" description="You can change the format at any time" level={2} />
      <Content>
        {formats.map(item => (
          <FormatItem
            onClick={onFormatClick}
            key={item.title}
            {...{ ...item, onModalClose, getFormatUrl, linkTarget }}
          />
        ))}
      </Content>
    </Wrapper>
  </CardModal>
);

export default FormatModal;
