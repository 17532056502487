import styled from 'styled-components';
import { flexColumn, flexRow } from '@anm/styles/helpers';
import { media } from '../../../styles/helpers';

type MasonryImageListProps = {
  leftColumnPercentsWidth?: number;
  rightColumnPercentsWidth?: number;
};

const leftColumnTablet = media.tablet<MasonryImageListProps>`
  max-width: ${({ leftColumnPercentsWidth }) => (leftColumnPercentsWidth ? `${leftColumnPercentsWidth}%` : '100%')};
`;

const rightColumnTablet = media.tablet<MasonryImageListProps>`  
  max-width: ${({ rightColumnPercentsWidth }) => (rightColumnPercentsWidth ? `${rightColumnPercentsWidth}%` : '100%')};
`;

const listItemTablet = media.tablet<{ imagePercentsMaxWidth?: number }>` 
  margin-top: 8px;   
  max-width: ${({ imagePercentsMaxWidth }) => (imagePercentsMaxWidth ? `${imagePercentsMaxWidth}%` : '100%')};
`;

const masonryImageListSectionTablet = media.tablet`
    padding: 0 0 60px;
`;

export const ListItem = styled.div`
  ${listItemTablet};
`;

const masonryImageListMobile = media.tablet`
  ${flexRow};
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 28px;
`;

export const MasonryImageListWrapper = styled.div`
  ${flexColumn};
  width: 100%;
  margin-bottom: 16px;
  ${masonryImageListMobile};
}
`;

export const LeftColumn = styled.div`
  ${flexColumn};
  align-items: flex-end;
  ${leftColumnTablet};
`;
export const RightColumn = styled.div`
  ${flexColumn};
  ${rightColumnTablet};
`;

export const MasonryImageListSection = styled.section`
  padding: 0 0 10px;
  ${masonryImageListSectionTablet};
`;
