import noop from '@anm/helpers/noop';
import InfoBlock from '@anm/components/InfoBlock';
import CardModal from '@anm/components/modals/CardModal';
import InlineSelection from '@anm/components/list/InlineSelection';
import { FC, useEffect } from 'react';

import {
  Content,
  Description,
  MainTitle,
  TextAreaContainer,
  TextAreaWrapper,
  TitleInput,
  WrappedButtonLoader,
  Wrapper,
  WordsCounter,
  CharsCounter
} from '../Wrapper';
import ModalSettings from '../components/ModalSettings';
import TextFormatingHint from '../components/TextFormatingHint';
import { GenerateVideoModalProps } from '../types';

import CheckList from './CheckList';
import isValidText from './helpers/isValidText';
import getWordsCount from './helpers/getWordsCount';
import isCorrectWordsCount from './helpers/isCorrectWordsCount';
import { useCreateVideoFromTextHandlers, useModalMarginTop } from './hooks';

export const getMinWords = (time: number) => time * 2.5;

const MAX_TEXTAREA_CHARS = 50000;

const GenerateVideoFromTextModal: FC<GenerateVideoModalProps> = ({
  timeList,
  isPending,
  clearJobId,
  onModalClose,
  onCreateVideo
}) => {
  useEffect(() => {
    clearJobId();
  }, []);

  const [
    time,
    text,
    title,
    isUseTextIs,
    activeSummarizeText,
    activeTextSceneButton,
    activeStockAssetsButton,
    setTime,
    setMood,
    handleTextChange,
    handleTitleChange,
    handleTextSceneChange,
    setActiveSummarizeText,
    handleCreateVideoFromText,
    setActiveStockAssetsButton
  ] = useCreateVideoFromTextHandlers(onCreateVideo);

  const currentWords = getWordsCount(text);
  const isValidAIText = isValidText(text) && isCorrectWordsCount(time, text);
  const isInvalidText = isUseTextIs ? !text : !isValidAIText;

  const marginTop = useModalMarginTop();

  return (
    <CardModal
      {...{ onModalClose }}
      cardSettings={{
        isPadding: false,
        borderRadius: 10,
        variant: 'transparent'
      }}
    >
      <Wrapper mt={marginTop}>
        <Content>
          <MainTitle level={2} title="Generate video from text" />
          <InlineSelection
            activeItem={activeSummarizeText}
            onChange={setActiveSummarizeText}
            list={['Use my text as is', 'Reduce my text with AI']}
          />
          <TitleInput
            label="Title (Optional)"
            inputVariant="simple"
            labelVariant="static-above"
            input={{
              name: 'fd',
              value: title,
              onBlur: noop,
              onFocus: noop,
              onChange: handleTitleChange
            }}
            helperTextVariant="static"
            meta={{} as any}
            placeholder="Optimal at 60 characters"
          />
          {!isUseTextIs && (
            <Description>
              Let the artificial intelligence analyze the text to pick its best bits and compile the video.
            </Description>
          )}
          <TextAreaContainer>
            {!isUseTextIs && (
              <CharsCounter>
                {text.length} / {MAX_TEXTAREA_CHARS}
              </CharsCounter>
            )}
            {!isUseTextIs && (
              <WordsCounter>
                words {currentWords} / {getMinWords(time)}
              </WordsCounter>
            )}
            <InfoBlock
              canShow={isInvalidText && !isUseTextIs}
              direction="to-left-bottom"
              renderContent={() => <CheckList text={text} time={time} currentWords={currentWords} />}
            >
              <TextAreaWrapper
                label="Text"
                labelVariant="static-above"
                input={{
                  name: 'text',
                  value: text,
                  onBlur: noop,
                  onFocus: noop,
                  onChange: handleTextChange
                }}
                helperTextVariant="static"
                meta={{} as any}
                {...{ isUseTextIs }}
                maxLength={isUseTextIs ? MAX_TEXTAREA_CHARS : undefined}
                placeholder="Paste your text here. 50,000 character max"
              />
            </InfoBlock>
            {isUseTextIs && <TextFormatingHint />}
          </TextAreaContainer>

          <ModalSettings
            isVideoDurationAndTextScene={!isUseTextIs}
            {...{
              setMood,
              setTime,
              timeList,
              activeTextSceneButton,
              handleTextSceneChange,
              activeStockAssetsButton,
              setActiveStockAssetsButton
            }}
          />

          <WrappedButtonLoader
            size="large"
            {...{ isPending }}
            disabled={isInvalidText}
            onClick={handleCreateVideoFromText}
            variant="light-blue-lowercase"
          >
            Next
          </WrappedButtonLoader>
        </Content>
      </Wrapper>
    </CardModal>
  );
};

export default GenerateVideoFromTextModal;
