import styled, { css } from 'styled-components';

type ListItemProps = {
  count: number;
  isActive: boolean;
};

export const Item = styled.li<ListItemProps>`
  width: ${({ count }) => `calc(100%/${count})`};
  border: 1px solid var(--blue_50);
  margin-right: -1px;
  padding: 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;

  &:first-child {
    border-radius: 5px 0 0 5px;
  }

  &:last-child {
    border-radius: 0 5px 5px 0;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      z-index: 1;
      background: var(--blue_20);
      border-color: var(--blue_100);
    `}
`;

export const List = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 16px;
  line-height: 38px;
  color: #292a2b;
  cursor: pointer;
`;
