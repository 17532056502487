import Container from '@anm/components/Container';
import { flexCenter, media } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

import SectionTitle, { Description } from '../../SectionTitle';

import { TitleVariant } from '.';

type WrapperProps = {
  isLastSection: boolean;
  isFullWidth?: boolean;
};

const getTitleStyles = (variant: TitleVariant) =>
  ({
    medium: css`
      h1,
      h2,
      h3,
      h4,
      h5 {
        font-size: 40px;
        line-height: 54px;
        font-weight: bold;
      }
      ${Description} {
        font-size: 24px;
        line-height: 40px;
      }
    `,
    big: css`
      h1,
      h2,
      h3,
      h4,
      h5 {
        font-size: 55px;
        font-weight: 900;
        line-height: 65px;
      }
      ${Description} {
        font-size: 30px;
        line-height: 48px;
      }
    `
  }[variant]);

export const Content = styled.div<{ customBackground?: string }>`
  ${flexCenter};
  width: 100%;
  border-radius: 20px;
  flex-direction: column;
  padding: 90px 150px 75px;
  background: ${({ customBackground }) => customBackground || '#e9f8ff'};
`;

const titleOnMobile = media.phone`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 28px;
    line-height: 36px;
  }
  ${Description} {
    margin: 4px 0 0;
    font-size: 16px;
    line-height: 25px;
  }
`;

export const BannerTitle = styled(SectionTitle)<{ titleVariant: TitleVariant }>`
  ${Description} {
    margin: 10px 0 0;
    font-weight: normal;
  }
  &:not(:last-child) {
    margin-bottom: 47px;
  }
  ${({ titleVariant }) => getTitleStyles(titleVariant)};
  ${titleOnMobile};
`;

const fullWidthStyles = css`
  max-width: unset;
  ${Content} {
    border-radius: 0;
  }
`;

const containerOnMobile = media.phone`
  ${Content} {
    width: calc(100vw - 38px);
    border-radius: 0;
    padding: 30px 20px;
    border-radius: 20px;
  }
`;

export const ContainerWrapper = styled(Container)<{ isFullWidth?: boolean }>`
  ${({ isFullWidth }) => isFullWidth && fullWidthStyles};
  ${containerOnMobile};
`;

const wrapperOnMobile = media.phone<{ isLastSection?: boolean }>`
  margin-top: 20px;
  margin-bottom: ${({ isLastSection }) => (isLastSection ? 40 : 20)}px;
`;

export const Wrapper = styled.section<WrapperProps>`
  margin-top: 50px;
  margin-bottom: ${({ isLastSection, isFullWidth }) => (isLastSection && isFullWidth ? 80 : 100)}px;
  ${wrapperOnMobile};
`;

export default Wrapper;
