import { FC } from 'react';

import Wrapper, { ImageWrapper, TitleBlockWrapper } from './Wrapper';

export type MediaErrorProps = {
  title: string;
  hideIcon?: boolean;
  description?: string;
};

const MediaError: FC<MediaErrorProps> = ({ title, hideIcon, description }) => (
  <Wrapper>
    {!hideIcon && <ImageWrapper src="images/icons/crossed-camera.svg" />}
    <TitleBlockWrapper size="medium" title={title} description={description} />
  </Wrapper>
);

export default MediaError;
