import styled, { css } from 'styled-components';

import { media } from '../../../styles/helpers';
import { MediaAspectVariants } from './index';

type MediaWrapperProps = { mediaVariant?: MediaAspectVariants; mediaMaxWidth?: number };

const contentWrapperOnLaptop = media.laptop`
  margin-top: 0;
  text-align: left;
  margin-left: 33px;
  padding: 90px 0 36px 0;
`;

export const ContentWrapper = styled.div`
  color: #fff;
  margin-top: 10px;
  padding: 0 0 30px 0;
  text-align: center;
  ${contentWrapperOnLaptop};

  span {
    font-size: 34px;
    font-weight: 700;
    line-height: 44px;
  }

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    margin: 17px 0 25px 0;
  }
`;

const variants: { [key in MediaAspectVariants]: ReturnType<typeof css> } = {
  '16x9': css`
    padding-bottom: 56.25%;
  `,
  default: css``
};

const fI = css`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const MediaWrapper = styled.div<MediaWrapperProps>`
  max-width: ${({ mediaMaxWidth }) => (mediaMaxWidth ? `${mediaMaxWidth}px` : '100%')};
  box-shadow: 0 5px 20px rgba(1, 83, 130, 0.2);
  width: 100%;
  flex-shrink: 0;
  margin: 0 auto 40px;
  position: relative;

  & > div {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    & > div {
      ${fI};
      animation: fadeIn 1s;
    }
  }
  ${({ mediaVariant }) => variants[mediaVariant!]};
`;

export const Container = styled.div``;
