import { isObject } from 'lodash/fp';
import React, { createElement, FC, ReactNode, RefObject } from 'react';

import TitleBlockWrapper, { TitleDescription } from './Wrapper';

export { TitleBlockWrapper, TitleDescription };

export type TitleBlockDirection = 'left' | 'center' | 'right';

export type TitleBlockSizes =
  | 'super-small'
  | 'small'
  | 'medium'
  | 'medium-thin'
  | 'super-medium'
  | 'large'
  | 'large-bold'
  | 'super-large'
  | 'extra-large';
export type TitleBlockColors = 'black' | 'black-title-gray-description' | 'red' | 'green' | 'blue' | 'white' | 'yellow';
export type TitleBlockProps = {
  title?: string | JSX.Element;
  level?: number;
  size?: TitleBlockSizes;
  color?: TitleBlockColors;
  titleRef?: RefObject<HTMLHeadingElement>;
  className?: string;
  direction?: TitleBlockDirection;
  description?: string | ReactNode;
  descriptionRef?: RefObject<HTMLDivElement>;
};

const TitleBlock: FC<TitleBlockProps> = ({ level, title, titleRef, description, descriptionRef, ...otherProps }) => {
  if (!title && !description) return null;

  const h = createElement(`h${level || 1}`, {
    ...(typeof title === 'string' && { dangerouslySetInnerHTML: { __html: title } }),
    ...(isObject(title) && { children: title }),
    ref: titleRef
  });

  return (
    <TitleBlockWrapper {...otherProps}>
      {title && h}
      {description && (
        <TitleDescription
          ref={descriptionRef}
          dangerouslySetInnerHTML={{
            __html: description as string
          }}
        />
      )}
    </TitleBlockWrapper>
  );
};

export default TitleBlock;
