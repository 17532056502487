import { useCallback, useEffect, useRef, useState } from 'react';

let timerEnter: number;
let timerLeave: number;

const useTooltip = (delayHide: number, delayShow: number) => {
  const [isHovered, setHovered] = useState(false);
  const [canShow, setCanShow] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [timeAfterLeave, setTimeAfterLeave] = useState<number>(0);

  useEffect(
    () => () => {
      clearTimeout(timerEnter);
      clearTimeout(timerLeave);
    },
    []
  );

  const handleEnter = useCallback(() => {
    const canShowTooltip = Date.now() - timeAfterLeave < delayShow;
    canShowTooltip && setHovered(true);

    timerEnter = window.setTimeout(() => {
      setHovered(true);
      setCanShow(true);
    }, delayShow);
  }, [delayShow, timeAfterLeave]);

  const handleLeave = useCallback(() => {
    clearTimeout(timerEnter);
    setHovered(false);
    setTimeAfterLeave(Date.now());
    timerLeave = window.setTimeout(() => setCanShow(false), delayHide);
  }, [delayHide, timerEnter]);

  return [handleEnter, handleLeave, isHovered, canShow, contentRef] as const;
};

export default useTooltip;
