import TEXT_TO_VIDEO_CHECK_LIST from '@anm/data/textToVideoCheckList';
import { FC } from 'react';

import { ChecklistRow, CheckListContent, WrapperImage } from '../Wrapper';

import {getMinWords} from '.'

const getImageSlug = (isValid: boolean) => (isValid ? 'success' : 'error');

type CheckListProps = { text: string; time: number; currentWords: number };

const CheckList: FC<CheckListProps> = ({ text, time, currentWords }) => {
  const minWords = getMinWords(time);
  const isValidWords = minWords <= currentWords;

  return (
    <CheckListContent>
      {TEXT_TO_VIDEO_CHECK_LIST.map(({ label, pattern }) => (
        <ChecklistRow key={label}>
          <WrapperImage src={`images/icons/validation_${getImageSlug(pattern.test(text))}.svg`} />
          <span>{label}</span>
        </ChecklistRow>
      ))}
      <ChecklistRow>
        <WrapperImage src={`images/icons/validation_${getImageSlug(isValidWords)}.svg`} />
        <span>At least {minWords} words</span>
      </ChecklistRow>
    </CheckListContent>
  );
};

export default CheckList;
