const numbers = '0123456789'
const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
const specials = '!$%^&*()_+|~-=`{}[]:;<>?,./'

const buildChars = (opts: Options) => {
    let chars = '';
    if (opts.numeric) { chars += numbers; }
    if (opts.letters) { chars += letters; }
    if (opts.special) { chars += specials; }

    if (opts.exclude) {
        for (let i = 0; i <= opts.exclude.length; i++) {
            chars = chars.replace(opts.exclude[i], "");
        }
    }

    return chars;
}

export type Options = {
    length?: number
    numeric?: boolean
    letters?: boolean
    special?: boolean
    exclude?: string[]
}

const defaultOpts: Options = {
    length: 8,
    numeric: true,
    letters: true,
    special: false,
    exclude: []
}

export const randomString = (opts?: Options) => {
    const options = {...defaultOpts, ...opts}

    let i
    let rn
    let rnd = ''
    const len = options.length || 8
    // const _exclude = options.exclude
    const randomChars = buildChars(options);

    for (i = 1; i <= len; i++) {
        rnd += randomChars.substring(rn = Math.floor(Math.random() * randomChars.length), rn + 1);
    }

    return rnd;
}

export const ID_LENGTH = 16

export const newId = () => {
    return randomString({length: ID_LENGTH, numeric: true, letters: true})
}