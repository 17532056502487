import Image from '@anm/components/image/Image';
import React, { FC } from 'react';

import SectionPictureWrapper from './Wrapper';

export type SectionPictureProps = {
  title: string;
  pictureUrl: string;
};

const SectionPicture: FC<SectionPictureProps> = ({ pictureUrl, title }) => (
  <SectionPictureWrapper>
    <Image src={pictureUrl} alt={title} />
  </SectionPictureWrapper>
);

export default SectionPicture;
