import styled from 'styled-components';

import { media } from '../../../styles/helpers';

const AuthorCompanyOnLaptop = media.laptop`
  font-style: normal;
  font-weight: 400;
`;

const FeedbackAuthorWrapperOnLaptop = media.laptop`
  text-align: left;
`;

const FeedbackAuthorWrapper = styled.div`
  color: #000;
  ${FeedbackAuthorWrapperOnLaptop};

  & > :first-child {
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
    color: inherit;
  }

  p {
    color: #666;
    font-size: 16px;
    font-style: italic;
    font-weight: 300;
    line-height: 28px;
    ${AuthorCompanyOnLaptop};

    & + a {
      display: inline-block;
      margin-top: 5px;
      color: #666;
    }
  }

  a {
    &:hover {
      color: #2c96dd;
    }
  }
`;

export default FeedbackAuthorWrapper;
