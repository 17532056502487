import TitleBlock from '@anm/components/TitleBlock';
import Image from '@anm/components/image/Image';
import { flexCenter } from '@anm/styles/helpers';
import styled from 'styled-components';

export const ImageWrapper = styled(Image)`
  margin-bottom: 50px;
`;
export const TitleBlockWrapper = styled(TitleBlock)`
  text-align: center;
  margin-bottom: 20px;
`;

const Wrapper = styled.div`
  ${flexCenter}
  flex-direction: column;
`;

export default Wrapper;
