import { media } from '@anm/styles/helpers';
import styled from 'styled-components';

const embedButtonBlockWrapperAdaptive = media.tablet`
	flex-wrap: wrap;
`;

const previewAdaptive = media.tablet`
	max-width: 100%;
	width: 100%;
	margin-top: 20px;
	margin-left: 0;
`;

const EmbedButtonBlockWrapper = styled.div`
  display: flex;
  ${embedButtonBlockWrapperAdaptive};
`;

export const Preview = styled.div`
  max-width: 650px;
  margin-left: 20px;
  ${previewAdaptive};
`;

export default EmbedButtonBlockWrapper;
