import { MediaType } from '@anm/api/modules/youtube';
import Button from '@anm/components/buttons/Button';
import { useCallback, FC } from 'react';
import styled from 'styled-components';

import { getDefaultOption, DownloadMediaOptions, StartDownloadProps } from '../DownloadMedia';

export type DownloadMediaMobileProps = {
  options: DownloadMediaOptions;
  mediaType: MediaType;
  startDownload: (props: StartDownloadProps) => void;
};

const Wrapper = styled.div`
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DownloadMediaMobile: FC<DownloadMediaMobileProps> = ({ mediaType, options, startDownload }) => {
  const defaultOption = getDefaultOption(options);
  const formatId = defaultOption?.value?.format_id;

  const handleDownload = useCallback(() => {
    formatId && startDownload({ formatId, mediaType, isVideoOnlyFormat: false });
  }, [formatId, startDownload]);

  return (
    <Wrapper>
      <Button variant="light-blue" size="medium" onClick={handleDownload}>
        Download {mediaType}
      </Button>
    </Wrapper>
  );
};
export default DownloadMediaMobile;
