import { AUTH_COOKIE } from '@anm/constants/auth';
import * as cookie from '@anm/helpers/cookie';
import isServer from '@anm/helpers/is/isServer';

const hasAuthAccess = (originCookie?: string) => {
  if (isServer()) return false;

  const authCookie = cookie.get(AUTH_COOKIE, originCookie);
  return !!authCookie;
};

export default hasAuthAccess;
