import getAllowedExtensions from '@anm/helpers/get/getAllowedExtensions';
import config from '../../../../../config';
import { UploadButtonType } from '../types';

const { supportedAudioExtensions, supportedImageExtensions, supportedVideoExtensions } = config;

const getExtensions = (uploadType: UploadButtonType[]) =>
  uploadType.reduce(
    (acc, type) =>
      acc +
      getAllowedExtensions({
        mediaType: type,
        extensions: {
          supportedAudioExtensions,
          supportedImageExtensions,
          supportedVideoExtensions
        }
      }),
    ''
  );

export default getExtensions;
