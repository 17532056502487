const createActivityEvent = (cb: () => void) => {
  const events = [
    'click'
  ];

  events.forEach((eventName: string) =>
    document.addEventListener(eventName, cb)
  );

  return () =>
    events.forEach((eventName: string) =>
      document.removeEventListener(eventName, cb)
    );
};

export default (doActive: () => void) => createActivityEvent(doActive);
