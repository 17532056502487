import styled, { css } from 'styled-components';

export type FormHelperTextColors = 'error' | 'warning';

export type FormHelperTextVariants = 'belowField' | 'static' | 'static-left' | 'over-right' | 'inside-right';
export type FormHelperTextProps = {
  variant: FormHelperTextVariants;
  color?: FormHelperTextColors;
};

const variants: { [key in FormHelperTextVariants]: ReturnType<typeof css> } = {
  'over-right': css`
    bottom: calc(100% + 10px);
    right: 1px;
  `,
  'inside-right': css`
    top: 8px;
    right: 8px;
    pointer-events: none;
  `,
  belowField: css`
    bottom: 0;
    left: 0;
  `,
  static: css`
    position: relative;
    font-size: 14px;
    text-align: center;
  `,
  'static-left': css`
    position: relative;
    font-size: 14px;
    text-align: left;
  `
};
const colors: { [key in FormHelperTextColors]: ReturnType<typeof css> } = {
  error: css`
    color: var(--error);
  `,
  warning: css`
    color: #fcbd26;
  `
};

const FormHelperText = styled.div<FormHelperTextProps>`
  font-size: 12px;
  position: absolute;

  ${({ variant }) => variants[variant || 'inside-right']};
  ${({ color }) => colors[color || 'error']};
`;

export default FormHelperText;
