import throttle from 'lodash/fp/throttle';

import getAudioContext from '../getAudioContext';

const checkMicWork = (analyser: AnalyserNode) => {
  if (analyser.getFloatTimeDomainData) {
    const array = new Float32Array(analyser.frequencyBinCount);
    analyser.getFloatTimeDomainData(array);

    return array.find(p => p !== 0) || 0;
  } else {
    return 0;
  }
};

export const getVolume = (analyser: AnalyserNode) => {
  const array = new Uint8Array(analyser.frequencyBinCount);
  analyser.getByteFrequencyData(array);

  const values = array.reduce((acc, val) => acc + val, 0);
  const average = values / array.length;

  return Math.round(average);
};

export type OnChangeProps = {
  signal: number;
  volume: number;
};

const listenAudioVolume = (audioStream: MediaStream, onChange: (props: OnChangeProps) => void) => {
  let dispose: () => void = () => {};

  getAudioContext().then(audioContext => {
    const analyser = audioContext.createAnalyser();
    const microphone = audioContext.createMediaStreamSource(audioStream);
    const javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

    const handleAudioProcess = throttle(5)(() => {
      onChange({ volume: getVolume(analyser), signal: checkMicWork(analyser) });
    });

    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;

    microphone.connect(analyser);
    analyser.connect(javascriptNode);
    javascriptNode.connect(audioContext.destination);

    javascriptNode.addEventListener('audioprocess', handleAudioProcess);

    dispose = () => {
      try {
        handleAudioProcess.cancel();
        javascriptNode.removeEventListener('audioprocess', handleAudioProcess);
      } catch (e) {
        // ignored
      }
    };
  });

  return () => {
    dispose();
  };
};

export default listenAudioVolume;
