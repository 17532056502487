import getFromCdn from '@anm/helpers/getFromCdn';
import styled, { css } from 'styled-components';

export type BackgroundVariants =
  | 'white'
  | 'custom'
  | 'blue-light'
  | 'yellow-stars'
  | 'blue-dark-light'
  | 'white-to-light-blue'
  | 'white-to-dark-blue';
export interface BackgroundProps {
  variant?: BackgroundVariants;
  customBackground?: string;
}

const variants: { [key in BackgroundVariants]: ReturnType<typeof css> } = {
  custom: css``,
  white: css`
    background-color: #fff;
  `,
  'yellow-stars': css`
    background-image: url(${getFromCdn('images/icons/yellow-stars.svg')}),
      linear-gradient(to right, #1f84a9 0%, #21c4eb 100%);
    background-repeat: no-repeat, repeat;
    background-position: center;
  `,
  'blue-light': css`
    background-color: #e7fcff;
  `,
  'blue-dark-light': css`
    color: #fff;
    background-image: linear-gradient(to right, #1e83a8 0%, #20c4ea 100%);
  `,
  'white-to-light-blue': css`
    background-image: ${({ theme }) => theme.errorPageGradient};
  `,
  'white-to-dark-blue': css`
    background-image: linear-gradient(to top, #ecefff, #ffffff);
  `
};

const Background = styled.div<BackgroundProps>`
  ${({ variant }) => variants[variant || 'white']};

  ${({ customBackground }) =>
    customBackground &&
    css`
      background: ${customBackground};
    `};
`;

export default Background;
