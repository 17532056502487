import styled from 'styled-components';

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: calc(100% - 60px);
  color: #000000;
  font-weight: 600;
  line-height: 1.5;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
`;

export const UserEmailInfo = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: #a0a0a8;
`;

export default UserInfo;
