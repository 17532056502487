import Container from '@anm/components/Container';
import { FC } from 'react';

import { CommonLandingProps } from '../../../features/getLanding';
import AnimatedWrapper from '../../Animated';

import StatisticItem from './StaticItem';
import Wrapper, { Title, VideoStatisticWrapper } from './Wrapper';
import { StatisticListProps } from './types';

export type VideoStatisticListProps = CommonLandingProps &
  StatisticListProps & {
    title?: string;
  };

const VideoStatisticList: FC<VideoStatisticListProps> = ({
  title,
  statistics,
  headingLevel
}) => (
  <Wrapper>
    <AnimatedWrapper variant="default" className="animated">
      <Container size={1000}>
        {title && <Title color="black" {...{ title }} level={headingLevel} />}
        <VideoStatisticWrapper>
          {statistics.map(el => (
            <StatisticItem {...el} key={el.description} />
          ))}
        </VideoStatisticWrapper>
      </Container>
    </AnimatedWrapper>
  </Wrapper>
);

export default VideoStatisticList;
