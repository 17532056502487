import useSwitchState from '@anm/hooks/useSwitchState';
import { useCallback, useEffect } from 'react';

import useEditorOpener from './useEditorOpener';

const useOpenEditorModalControls = (editorUrl: string) => {
  const [{ isError }, openEditorActions] = useEditorOpener({ editorUrl });

  const [isOpen, open, closeModal] = useSwitchState();

  const changeIntent = useCallback(
    (intent: string) => {
      open();
      openEditorActions.open(intent);
    },
    [open]
  );
  const cancelOpenEditor = useCallback(() => {
    openEditorActions.cancel();
    closeModal();
  }, [closeModal]);

  useEffect(() => {
    isError && cancelOpenEditor();
  }, [cancelOpenEditor, isError]);

  return [{ isOpen }, { changeIntent, cancelOpenEditor, closeModal }] as const;
};

export default useOpenEditorModalControls;
