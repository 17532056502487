import NextImage from '@anm/components/image/NextImage';
import { ImageLoader } from 'next/image';
import { FC, MouseEvent } from 'react';
import styled from 'styled-components';

import Thumb from './Thumb';

export type ProgressiveImageLoadingProps = {
  alt?: string;
  thumb?: string;
  width?: number;
  height?: number;
  imageSrc?: string;
  className?: string;
  loader?: ImageLoader;
  onClick?: (e: MouseEvent<HTMLImageElement>) => void;
};

export const Wrapper = styled.div`
  position: relative;
  svg {
    display: block;
  }
`;

const ProgressiveImageLoading: FC<ProgressiveImageLoadingProps> = ({
  alt,
  thumb,
  imageSrc,
  className,
  loader,
  width = 300,
  height = 200,
  onClick
}) => (
  <Wrapper>
    {imageSrc && (
      <NextImage loader={loader} layout="fill" alt={alt} src={imageSrc} className={className} onClick={onClick} />
    )}
    <Thumb {...{ thumb, width, height }} />
  </Wrapper>
);

export default ProgressiveImageLoading;
