import useSwitchState from '@anm/hooks/useSwitchState';
import { useEffect } from 'react';

export const useAnimation = () => {
  const [isAnimating, startAnimating, endAnimating] = useSwitchState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      startAnimating();
    }, 50);
    return () => {
      endAnimating();
      clearTimeout(timer);
    };
  }, []);

  return isAnimating;
};
