import { MediaInfoFormat } from '@anm/api/modules/youtube';
import { useCallback } from 'react';

import { getDownloadUrlByFormatId, getFilenameByFormatId } from '../helpers';

type UseDirectDownloaderProps = {
  mediaFormats?: MediaInfoFormat[];
};

const open = (downloadUrl: string, filename: string) => {
  const id = 'download-button';
  document.getElementById(id)?.remove();
  const a = document.createElement('a');
  a.href = downloadUrl;
  a.setAttribute('id', id);
  a.setAttribute('download', 'true');
  a.setAttribute('download', filename);
  document.body.append(a);
  a.click();
};

const useDirectDownloader = ({ mediaFormats }: UseDirectDownloaderProps) => {
  const startDownload = useCallback(
    (formatId: string) => {
      if (!mediaFormats) return;

      const filename = getFilenameByFormatId(formatId, mediaFormats);
      const downloadUrl = getDownloadUrlByFormatId(formatId, mediaFormats);

      downloadUrl && filename && open(downloadUrl, filename);
    },
    [mediaFormats]
  );

  return startDownload;
};

export default useDirectDownloader;
