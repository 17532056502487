import NextImage from '@anm/components/image/NextImage';
import { FC } from 'react';

import AnimatedWrapper from '../../Animated';
import LandingButton from '../../LandingButton';

import StepsListWrapper from './StepsListWrapper';
import VideoMedia from './VideoMedia';
import {
  MediaWrapper,
  VideoStepsDataWrapper,
  VideoStepsListItemContent,
  VideoStepsListItemWrapper,
  VideoStepsTitle
} from './Wrapper';
import { getAnimationDirection } from './helpers';
import { ListItemProps, StepsListProps } from './types';

export const ListItem: FC<ListItemProps> = ({
  title,
  index,
  video,
  button,
  content,
  imageUrl,
  mediaShadow,
  imageHeight = '60%',
  headingLevel,
  listDirection,
  stepBackground,
  stickerUrl,
  stickerPositionVariant = 'left'
}) => (
  <VideoStepsListItemWrapper itemProp="step" itemScope itemType="https://schema.org/HowToStep" {...{ stepBackground }}>
    <VideoStepsListItemContent {...{ stickerUrl, stickerPositionVariant }}>
      <MediaWrapper>
        <AnimatedWrapper
          isShadow={mediaShadow}
          variant={getAnimationDirection(index, listDirection)}
          className="animated"
        >
          {video && <VideoMedia {...video} />}
          {imageUrl && (
            <NextImage
              itemProp="image"
              alt={title}
              src={imageUrl}
              height={imageHeight}
              width="100%"
              layout="responsive"
              objectFit="contain"
            />
          )}
        </AnimatedWrapper>
      </MediaWrapper>
      <VideoStepsDataWrapper textAlign="left">
        <AnimatedWrapper variant={getAnimationDirection(index + 1, listDirection)} className="animated">
          <VideoStepsTitle itemProp="name" title={title} color="black" level={headingLevel} />
          {content && <VideoStepsTitle itemProp="text" description={content} color="black" level={headingLevel} />}
          <LandingButton size="big" variant="light-blue-lowercase" {...button} />
        </AnimatedWrapper>
      </VideoStepsDataWrapper>
    </VideoStepsListItemContent>
  </VideoStepsListItemWrapper>
);

const List: FC<StepsListProps> = ({ steps, direction, headingLevel, fullWidth }) => (
  <StepsListWrapper listDirection={direction} isOneStep={steps.length === 1} {...{ fullWidth }}>
    {steps.map((el, index) => (
      <ListItem key={el.title || index} listDirection={direction} {...{ index, headingLevel, ...el }} />
    ))}
  </StepsListWrapper>
);

export default List;
