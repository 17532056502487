import { CardProps } from '@anm/components/Card';
import Button from '@anm/components/buttons/Button';
import { CloseButton } from '@anm/styles/helpers';
import { FC } from 'react';

import Modal, { ModalProps } from '../Modal';

import CardModalContent from './Content';
import CardModalWrapper, { CloseModalButtonWrapper } from './Wrapper';

export { CloseModalButtonWrapper, CardModalWrapper, CardModalContent };

export type CardModalProps = ModalProps & {
  cardSettings?: CardProps;
  isCloseButtonInsideModal?: boolean;
  className?: string;
  cardShadow?: boolean;
};

const CardModal: FC<CardModalProps> = props => {
  const { className, children, onModalClose, cardShadow, cardSettings = {}, isCloseButtonInsideModal = false } = props;

  return (
    <Modal {...props}>
      <CardModalWrapper className={className}>
        <CardModalContent {...cardSettings} hasShadow={cardShadow}>
          {children}
        </CardModalContent>
        {isCloseButtonInsideModal && <CloseButton onClick={onModalClose} />}
      </CardModalWrapper>
      {!isCloseButtonInsideModal && (
        <CloseModalButtonWrapper>
          <Button size="medium" variant="close-cross" onClick={onModalClose} />
        </CloseModalButtonWrapper>
      )}
    </Modal>
  );
};

export default CardModal;
