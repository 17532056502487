import IconPlay, { IconPlayBehavior, IconPlaySizes } from '@anm/components/IconPlay';
import { ButtonProps } from '@anm/components/buttons/Button';
import Image from '@anm/components/image/Image';
import useSwitchState from '@anm/hooks/useSwitchState';
import { FC } from 'react';

import PlayerModal from '../modals/PlayerModal';

import { Overlay } from './Wrapper';
import { usePlayerData } from './hooks';

export type MediaProps = {
  videoId?: string;
  imageUrl?: string;
  isModal?: boolean;
  wistiaId?: string;
  imageSideMediaUrl?: string;
};

type WaveModalPlayerProps = Partial<MediaProps> & {
  videoId: string;
  playerIconSize?: IconPlaySizes;
  customButton?: ButtonProps;
  playIconVariant?: 'black' | 'white';
  playIconBehavior?: IconPlayBehavior;
};

const playIconsMap = {
  black: 'images/icons/btn-play-dark.svg',
  white: 'images/icons/btn-play-white.svg'
};

const WavePlayerModal: FC<WaveModalPlayerProps> = ({
  videoId,
  playIconBehavior,
  playerIconSize = '90x90',
  playIconVariant = 'black',
  ...otherProps
}) => {
  const [isModalOpen, openModal, closeModal] = useSwitchState();

  const playIconUrl = playIconsMap[playIconVariant];

  const [playerRef, imgData] = usePlayerData(videoId);

  return (
    <div ref={playerRef}>
      {videoId ? (
        <IconPlay
          size={playerIconSize}
          variant="custom_image"
          playIconUrl={playIconUrl!}
          behavior={playIconBehavior}
          onClick={openModal}
        />
      ) : (
        <Overlay onClick={openModal} />
      )}
      {imgData && <Image {...imgData} {...(otherProps.imageUrl && { src: otherProps.imageUrl })} />}
      {isModalOpen && <PlayerModal {...otherProps} videoId={videoId!} onModalClose={closeModal} />}
    </div>
  );
};

export default WavePlayerModal;
