import Container from '@anm/components/Container';
import { FC } from 'react';

import { VideoGalleryProps } from '..';
import FlexContainer from '../../../FlexContainer';
import LandingButton from '../../../LandingButton';
import { ButtonWrapper } from '../Wrapper';

import MediaBlock from './Media';
import VideoGalleryTitle from './VideoGalleryTitle';
import VideosWrapper from './VideosWrapper';

const SectionContent: FC<VideoGalleryProps> = ({
  title,
  button,
  videos,
  description,
  design = 'default',
  isWavePlayer,
  headingLevel
}) => (
  <Container>
    <VideoGalleryTitle color="black" level={headingLevel || 4} {...{ title, design, description }} />
    <VideosWrapper {...{ design }} isSingle={videos?.length === 1}>
      <FlexContainer variant={design === 'new' ? 'center-between-on-tablet' : 'between-on-tablet'} isList={true}>
        <MediaBlock {...{ isWavePlayer, videos, design }} />
      </FlexContainer>
    </VideosWrapper>

    <LandingButton variant="light-blue-lowercase" size="big" {...button}>
      {landingButton => <ButtonWrapper>{landingButton} </ButtonWrapper>}
    </LandingButton>
  </Container>
);

export default SectionContent;
