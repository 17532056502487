import { ButtonVariants } from '@anm/components/buttons/Button';
import { FC } from 'react';
import styled from 'styled-components';

import MyProjectsButton from './MyProjectsButton';
import UserMenu from './UserMenu';

type UserLoggedButtonsProps = {
  variant: ButtonVariants;
};

const UserLoggedButtonsWrapper = styled.div`
  fill: ${({ variant }: UserLoggedButtonsProps) =>
    variant === 'border-transparent' ? '#fff' : '#000'};

  display: flex;
  > *:first-child {
    margin-right: 24px;
    min-width: 143px;
  }
`;

const UserLoggedButtons: FC<UserLoggedButtonsProps> = ({ variant }) => (
  <UserLoggedButtonsWrapper {...{ variant }}>
    <MyProjectsButton buttonVariant={variant} />
    <UserMenu />
  </UserLoggedButtonsWrapper>
);

export default UserLoggedButtons;
