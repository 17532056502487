import slick from '@anm/styles/third-party/slick';
import styled from 'styled-components';

import { CardThemeProps } from '.';

const CarouselWrapper = styled.div<CardThemeProps>`
  position: relative;
  text-align: center;

  ${slick}

  div {
    outline: none;
    cursor: grab;
  }

  .slick-slider {
    max-width: ${({ maxWidth }) => maxWidth || 850}px;
    margin: 0 auto;
    position: static;
  }

  .slick-list {
    overflow: ${({ isCardTheme }) => (isCardTheme ? 'visible' : 'hidden')};
  }
`;

export default CarouselWrapper;
