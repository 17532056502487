import { media } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

import { SectionDesign } from '..';
import SectionTitle from '../../../SectionTitle';

const DefaultTitleHeadOnMobile = media.phone`
  font-size: 30px;
  line-height: 38px;
`;

const NewTitleHeadOnMobile = media.phone`
  font-size: 30px;
  line-height: 1.2;
`;

const getVariants = ({ design = 'default' }: SectionDesign) =>
  ({
    new: css`
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 36px;
        line-height: normal;
        ${NewTitleHeadOnMobile};
      }
    `,
    default: css`
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 34px;
        line-height: 44px;
        ${DefaultTitleHeadOnMobile};
      }
    `
  }[design]);

const VideoGalleryTitle = styled(SectionTitle)`
  ${getVariants};
`;

export default VideoGalleryTitle;
