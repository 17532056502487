import getFromCdn from '@anm/helpers/getFromCdn';
import { FC } from 'react';
import styled, { css } from 'styled-components';

export type LoaderSizes = 'micro' | 'small' | 'medium' | 'large' | 'large2';
export type LoaderColors = 'black' | 'white' | 'blue' | 'red' | 'gray';

export type LoaderProps = {
  size: LoaderSizes;
  color: LoaderColors;
};

const sizes: { [key in LoaderSizes]: ReturnType<typeof css> } = {
  micro: css`
    width: 8px;
    height: 8px;
  `,
  small: css`
    width: 14px;
    height: 14px;
  `,
  medium: css`
    width: 21px;
    height: 21px;
  `,
  large: css`
    width: 28px;
    height: 28px;
  `,
  large2: css`
    width: 38px;
    height: 38px;
  `
};

const getColor = ({ color }: LoaderProps) =>
  ({
    black: css`
      background-image: url(${getFromCdn('images/loader-black.svg')});
    `,
    white: css`
      background-image: url(${getFromCdn('images/loader.svg')});
    `,
    blue: css`
      background-image: url(${getFromCdn('images/loader-blue.svg')});
    `,
    red: css`
      background-image: url(${getFromCdn('images/loader-red.svg')});
    `,
    gray: css`
      background-image: url(${getFromCdn('images/loader-gray.svg')});
    `
  }[color]);

export const LoaderWrapper = styled.div<LoaderProps>`
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  transform-origin: top right;
  animation: spin 0.5s infinite linear;
  margin: 0 auto;
  background-size: contain;
  ${({ size }) => sizes[size]};
  ${getColor};
`;

const Loader: FC<LoaderProps> = props => (
  <LoaderWrapper {...props}>
    <div />
  </LoaderWrapper>
);

export default Loader;
