import getFromCdn from '@anm/helpers/getFromCdn';
import { media } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

import { ArrowSize, SliderArrowProps, SliderArrowVariants } from '.';

const sizes: { [key in ArrowSize]: ReturnType<typeof css> } = {
  '15x24': css`
    width: 15px;
    height: 24px;
  `,
  '36x36': css`
    width: 36px;
    height: 36px;
  `
};

const variants: { [key in SliderArrowVariants]: ReturnType<typeof css> } = {
  left: css`
    left: 0;
  `,
  right: css`
    right: 0;
    transform: rotate(180deg);
  `
};

const mobile = media.phone<SliderArrowProps>`
transform: translateY(-100%) rotate(${({ direction }) =>
  direction === 'right' ? 180 : 0}deg) scale(0.5);
`;

const centerLeftArrow = css`
  margin-left: -17px;
  transform: translateY(-100%);
  ${mobile};
`;
const centerRightArrow = css`
  margin-right: -17px;
  transform: translateY(-100%) rotate(180deg);
  ${mobile};
`;

const cardArrowStyles = css<SliderArrowProps>`
  ${({ direction }) => direction === 'left' && centerLeftArrow};
  ${({ direction }) => direction === 'right' && centerRightArrow};
  opacity: ${({ isArrowVisible }) => (isArrowVisible ? 0.7 : 0)};

  &:hover {
    opacity: ${({ isArrowVisible }) => (isArrowVisible ? 1 : 0)};
  }
`;

const arrowMobileStyle = media.phone<SliderArrowProps>`
  top: ${({ isCardTheme }) => (isCardTheme ? '50%' : '110px')};
`;

const SliderArrowWrapper = styled.button<SliderArrowProps>`
  top: 50%;
  position: absolute;
  cursor: pointer;
  background: url('${getFromCdn('images/icons/feedback-arrow.svg')}') no-repeat
      center / contain;
  z-index: 1;
  transition: 0.3s;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }

  ${({ direction }) => variants[direction]};
  ${({ arrowSize }) => sizes[arrowSize!]};
  ${arrowMobileStyle};
  ${({ isCardTheme }) => isCardTheme && cardArrowStyles};
`;

export default SliderArrowWrapper;
