const PARTNERS = [
  {
    name: 'Facebook',
    iconUrl: 'images/data/integrations-facebook/logo.svg'
  },
  {
    name: 'YouTube',
    iconUrl: 'images/data/integrations-youtube/logo.svg'
  },
  {
    name: 'Twitter',
    iconUrl: 'images/data/integrations-twitter/logo.svg'
  },
  {
    name: 'Google Drive',
    iconUrl: 'images/data/integrations-google-drive/logo.png'
  },
  {
    name: 'Dropbox',
    iconUrl: 'images/data/integrations-dropbox/logo.svg'
  },
  {
    name: 'Vimeo',
    iconUrl: 'images/data/integrations-vimeo/logo.svg'
  },
  {
    name: 'Hubspot',
    iconUrl: 'images/data/integrations-hubspot/logo.svg'
  },
  {
    name: 'Wistia',
    iconUrl: 'images/data/integrations-wistia/logo.svg'
  },
  {
    name: 'Uscreen',
    iconUrl: 'images/data/monetize-live-streams/uscreen-logo.png'
  },
  {
    name: 'Restream',
    iconUrl: 'images/data/monetize-live-streams/restream-logo.png'
  },
  {
    name: 'Wave.video',
    iconUrl: 'images/data/monetize-live-streams/wave-logo.png'
  }
];

export default PARTNERS;
