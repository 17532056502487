import { LinkProps } from '@anm/components/header';
import compose from 'lodash/fp/compose';
import template from 'lodash/fp/template';

export type CommonLinkKeys = keyof typeof commonLinks;
export type CommonLinks = { [key in CommonLinkKeys]: LinkProps };

const commonLinks = {
  myPlan: {
    title: 'Subscription',
    route: 'subscription'
  },
  myProfile: {
    title: 'Profile',
    route: 'profile'
  }
};

export default (websiteUrl: string): CommonLinks =>
  compose(JSON.parse, compiled => compiled({ websiteUrl }), template, JSON.stringify)(commonLinks);
