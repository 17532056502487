import { FC } from 'react';

import IconPlayWrapper from './IconPlayWrapper';

export { IconPlayWrapper };

export type IconPlayProps = {
  size?: IconPlaySizes;
  playIconUrl?: string;
  variant?: IconPlayVariants;
  behavior?: IconPlayBehavior;
  transparentPlayIcon?: boolean;
  onClick?: () => void;
};

export type IconPlayBehavior = 'hover-scale' | 'hover-opacity';
export type IconPlayVariants = 'black' | 'absolute' | 'custom_image';

export type IconPlaySizes = '90x90' | '80x80' | '50x50';

const IconPlay: FC<IconPlayProps> = ({
  onClick,
  size = '50x50',
  transparentPlayIcon,
  variant = 'custom_image',
  behavior = 'hover-scale',
  playIconUrl = 'images/icons/btn-play-white.svg'
}) => (
  <>
    <IconPlayWrapper
      {...{
        variant,
        size,
        onClick,
        behavior,
        playIconUrl,
        transparentPlayIcon
      }}
    />
  </>
);

export default IconPlay;
