import { branch, compose, renderComponent } from 'recompose';

import EnvHostingPlayer from '../../../EnvHostingPlayer';
import { MediaProps as SideMedia } from '../../../WavePlayerModal';
import { PlayIconSizes } from '../PlayIcon';

import VideoPlayerModal from './VideoPlayerModal';
import WistiaPlayer from './WistiaPlayer';
import ImageSideMedia from './ImageSideMedia';

export type MediaFrame = {
  width: number;
  color: string;
};

export type MediaProps = {
  title?: string;
  sideMedia: SideMedia;
  imageSideMediaUrl?: string;
  mediaMaxWidth?: number;
  mediaFrame?: MediaFrame;
  playIconSize?: PlayIconSizes;
};

const isModalPlayer = ({ isModal }: SideMedia) => !!isModal;
const isWistiaPlayer = ({ wistiaId }: SideMedia) => !!wistiaId;
const isImageMedia = ({ imageSideMediaUrl }: SideMedia) => !!imageSideMediaUrl;

const Media = compose(
  branch(isModalPlayer, renderComponent(VideoPlayerModal)),
  branch(isWistiaPlayer, renderComponent(WistiaPlayer)),
  branch(isImageMedia, renderComponent(ImageSideMedia))
)(EnvHostingPlayer);

export default Media;
