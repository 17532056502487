import isServer from '@anm/helpers/is/isServer';
import { FC } from 'react';

import convertQueryToObject from '../../helpers/convertQueryToObject';
import LandingVideo from '../landing/LandingVideo';

const IntroduceVideo: FC = () => {
  if (isServer()) return null;
  const { play: wistiaId } = convertQueryToObject<{ play: string }>(
    window.location.search
  );

  if (!wistiaId) return null;

  return (
    <LandingVideo
      autoplay={true}
      wistiaId={wistiaId}
      autoModalOpen={true}
      openInModal={true}
    />
  );
};

export default IntroduceVideo;
