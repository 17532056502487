import { trackVisit } from '@anm/analytic';
import updateAuthCookie from '@anm/auth/helpers/updateAuthCookie';
import { useCallback, FC } from 'react';
import styled from 'styled-components';

import { mobileMenuItem } from '../../../styles/helpers';

const Logout = styled.a`
  ${mobileMenuItem};
  height: 45px;
  line-height: 45px;
  &:hover {
    color: #2294df;
  }
`;

const logout = async () => {
  await updateAuthCookie(null);
  await trackVisit({
    product: 'WAVE',
    userProfile: null
  });
};

const LogoutButton: FC = () => {
  const handleClick = useCallback(logout, []);
  return <Logout onClick={handleClick}>Log Out</Logout>;
};
export default LogoutButton;
