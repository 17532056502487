import Button from '@anm/components/buttons/Button/Button';
import { flexCenter, media } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

type WrapperProps = { width: number; isAssetInited: boolean };

export const PlayerWrapper = styled.div`
  padding: 10px;
  background: #fff;
  border-radius: 5px;
`;

export const ModalFooter = styled.div`
  ${flexCenter};
  ${Button}, a {
    margin: 26px 0 20px;
    padding: 0 34px;
  }
`;

const dynamicStyles = css<WrapperProps>`
  opacity: 1;
  width: ${({ width }) => width}px;
`;

const wrapperOnMobile = media.phone`
  &:after {
    right: 0;
    top: -45px;
  }
`;

const Wrapper = styled.div<WrapperProps>`
  opacity: 0;
  margin: 50% 0;
  max-width: 100vw;
  position: relative;
  pointer-events: all;
  max-height: calc(100vh - 50px);
  transition: opacity 300ms ease-in-out;
  ${({ isAssetInited }) => isAssetInited && dynamicStyles};
  @media (orientation: landscape) {
    margin: auto;
  }

  img {
    margin: auto;
    display: block;
  }

  &:after {
    right: 0;
    top: -39px;
    right: -35px;
    display: block;
    font-size: 35px;
    font-weight: 800;
    content: '\u2715';
    font-weight: bold;
    position: absolute;
    pointer-events: none;
    color: hsla(0, 0%, 100%, 0.6);
  }
  ${wrapperOnMobile};
`;

export default Wrapper;
