import Tooltip from '@anm/components/Tooltip';
import Image from '@anm/components/image/Image';
import { Config as FinalFormConfig } from 'final-form';
import { FC } from 'react';
import { Form, FormRenderProps } from 'react-final-form';

import Button from '../../buttons/Button';
import AutoSave from '../../form/AutoSave';
import FormHelperText from '../../form/FormHelperText';
import { NameField, SelectField } from '../../form/fields';
import { ButtonColor, ButtonSize, VideoFormat } from '../EmbedButtonForm/data';

import {
  CardForm,
  EmbedButtonFormTitle,
  NameFieldTooltipWrapper,
  NameFieldWithToolTipWrapper,
  TooltipLink
} from './Wrapper';
import { buttonColors, buttonSizes, videoFormats } from './data';

type HOCs = FinalFormConfig;

type Select<T> = {
  label: string;
  value: T;
};
export type EmbedButtonFormFields = {
  size: Select<ButtonSize>;
  color: Select<ButtonColor>;
  format: Select<VideoFormat>;
  apiKey: string;
};

export type EmbedButtonFormProps = {
  onAutoSave: (fields: EmbedButtonFormFields) => void;
  isPending?: boolean;
  headingLevel?: number;
  submitError?: string | null;
};

const renderForm = ({
  submitError,
  onAutoSave,
  headingLevel
}: EmbedButtonFormProps) => ({ handleSubmit }: FormRenderProps) => (
  <CardForm as="form" onSubmit={handleSubmit}>
    <AutoSave debounce={1000} onSave={onAutoSave} />
    <EmbedButtonFormTitle
      title="Set up your button"
      size="medium"
      level={headingLevel || 2}
    />
    {submitError && (
      <FormHelperText variant="static">{submitError}</FormHelperText>
    )}

    <NameFieldWithToolTipWrapper>
      <NameField label="Your API key" name="apiKey" />
      <NameFieldTooltipWrapper>
        <Tooltip
          variant="black"
          renderContent={() => (
            <TooltipLink
              target="_blank"
              href="https://animatron.typeform.com/to/TlO0P7"
            >
              Contact Us to get API key
            </TooltipLink>
          )}
        >
          <Image src="images/wave-button/hint-icon.svg" />
        </Tooltip>
      </NameFieldTooltipWrapper>
    </NameFieldWithToolTipWrapper>

    <SelectField label="Video Format" name="format" options={videoFormats} />

    <SelectField label="Button Size" name="size" options={buttonSizes} />

    <SelectField label="Button Color" name="color" options={buttonColors} />

    <Button size="large" buttonType="submit" variant="light-blue">
      Copy Code
    </Button>
  </CardForm>
);

const EmbedButtonForm: FC<HOCs & EmbedButtonFormProps> = props => (
  <Form render={renderForm(props)} {...props} />
);

export default EmbedButtonForm;
