const getSubtitleLevel = (parentLevel?: number, isLink = false) => {
  const mainHeadingLevel = parentLevel || 2;
  const incrementor = isLink ? 1 : 2;
  const subLevel = mainHeadingLevel + incrementor;
  const subHeadingLevel = subLevel <= 6 ? subLevel : 6;

  return subHeadingLevel;
};

export default getSubtitleLevel;
