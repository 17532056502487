import styled from 'styled-components';

import { media } from '../../../styles/helpers';

import { MediaFrame } from './Media';
import { getPlayIconStyles, PlayIconSizes } from './PlayIcon';
import Image from '@anm/components/image/Image';

type StaticMedia = {
  mediaMaxWidth?: number;
  mediaFrame?: MediaFrame;
  playIconSize?: PlayIconSizes;
};

const mediaWrapperTablet = media.tablet`
  margin: 0 auto;
`;

const frameStyles = media.tablet<{ mediaFrame: MediaFrame }>`
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: ${({ mediaFrame }) => mediaFrame.width || 20}px;
    left: ${({ mediaFrame }) => mediaFrame.width || 20}px;
    background-color: ${({ mediaFrame }) => mediaFrame.color || '#C4EAFF'};
  }
`;

export const MediaWrapper = styled.div<StaticMedia>`
  width: 100%;
  position: relative;
  margin: 24px 0 20px;
  max-width: ${({ mediaMaxWidth }) => `${mediaMaxWidth}px` || '100%'};
  ${({ mediaFrame }) => mediaFrame && frameStyles}
  .plyr__control.plyr__control--overlaid {
    ${({ playIconSize }) => playIconSize && getPlayIconStyles(playIconSize!)}
  }

  ${mediaWrapperTablet};
`;

export const ImageMedia = styled(Image)`
  position: relative;
`;

const SideMediaWrapper = styled.section`
  position: relative;
`;

export const PlayerWrapper = styled.div`
  img {
    width: 100%;
    position: relative;
    margin-bottom: -4px;
  }
`;

export default SideMediaWrapper;
