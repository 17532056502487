import api from '@anm/api';
import { getAuthToken, setAuthToken } from '@anm/auth/helpers/authToken';

import { setAnon } from '../authAnon';

const signupAnon = async () => {
  const token = getAuthToken();
  if (token) return;

  try {
    const user = await api().auth.signupAnon();
    if (!user.encryptedToken) return null;

    setAuthToken(user.encryptedToken);
    setAnon(true);

    return user;
  } catch (err) {
    return Promise.reject(err);
  }
};

export default signupAnon;
