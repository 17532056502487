import { useEffect, useState } from 'react';

const useDelayRender = (delay: number) => {
  const [canRender, setCanRender] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setCanRender(true), delay);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return canRender;
};

export default useDelayRender;
