import {
  HeaderNavigationItem,
  RenderNavLinkProp
} from '@anm/components/header';
import { FC } from 'react';

import CollapsedLinks from './CollapsedLinks';
import TitleBackArrow from './TitleBackArrow';

export type MobileNavigationCollapsedLinksProps = HeaderNavigationItem &
  RenderNavLinkProp & {
    onBack(): void;
  };

const MobileNavigationCollapsedLinks: FC<MobileNavigationCollapsedLinksProps> = ({
  title,
  renderNavLink,
  children: list,
  onBack
}) => (
  <>
    <TitleBackArrow title={title} onClick={onBack} />
    <CollapsedLinks list={list!} {...{ renderNavLink }} />
  </>
);

export default MobileNavigationCollapsedLinks;
