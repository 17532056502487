import isMobile from '@anm/helpers/is/isMobile';
import { FC } from 'react';

import { Cell, HeaderRow, HeaderTitle } from './Wrapper';
import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from '@anm/styles/constants';

export type TableHeaderProps = {
  header: string[];
};

const TableHeader: FC<TableHeaderProps> = ({ header }) => (
  <HeaderRow headerHeight={isMobile() ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT}>
    {header.map((title, i) => (
      <Cell key={title} isFirstCell={i === 0}>
        <HeaderTitle>{title}</HeaderTitle>
      </Cell>
    ))}
  </HeaderRow>
);

export default TableHeader;
