import { FC } from 'react';

import { Content, VideoOverlay } from './Wrapper';
import { getInitials, getRandomColor } from './helpers';
import { VideoAvatarProps } from './types';

export type { VideoAvatarProps };

export * from './helpers';

const VideoAvatar: FC<VideoAvatarProps> = ({ name, avatar, className, color = getRandomColor(), size, children }) => (
  <VideoOverlay className={className}>
    {children}
    {(name || avatar) && (
      <Content {...{ color, size }} title={name}>
        {avatar ? <img src={avatar} alt="avatar" /> : getInitials(name || '')}
      </Content>
    )}
  </VideoOverlay>
);

export default VideoAvatar;
