import { flexCenter, media } from '@anm/styles/helpers';
import styled from 'styled-components';
import Container from '@anm/components/Container';
import { SMALL_FOOTER_HEIGHT } from '@anm/styles/constants';

const copyrightWrapperMobile = media.phone`
  padding-top: 30px;
  justify-content: center;
`;

export const CopyrightWrapper = styled.div`
  ${flexCenter};
  color: #919eaa;
  font-size: 12px;
  line-height: 19px;
  padding: 36px 0 35px;
  justify-content: flex-start;
  ${copyrightWrapperMobile};
`;

export const SmallFooterContainer = styled(Container)`
  max-height: ${SMALL_FOOTER_HEIGHT};
`;

export const SmallFooterWrapper = styled.div``;
