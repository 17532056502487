import Container from '@anm/components/Container';
import { media, Row, flexRow } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

import { FooterWrapperProps } from './types';

export * from './types';

const getFooterBackgroundColor = ({ isDark, isTransparent }: FooterWrapperProps) =>
  isTransparent ? '#fff' : isDark ? '#292A2B' : '#f7f8fa';

const topBorderStyles = css`
  border-top: 1px solid #ccdde6;
`;

const FooterWrapper = styled.footer<FooterWrapperProps>`
  ${({ isTopBorder }) => isTopBorder && topBorderStyles};
  background-color: ${props => getFooterBackgroundColor(props)};
  ${Container} {
    box-sizing: content-box;
  }
`;

const footerTopSectionOnTablet = media.tablet`
  padding: 20px 0 0 0;
`;

export const FooterTopSection = styled.div`
  margin: 0 auto;
  padding: 26px 0 0;
  ${footerTopSectionOnTablet};
`;

export const FooterListTitle = styled.div<FooterWrapperProps>`
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 16px;
  text-transform: capitalize;
`;

const footerNavigationListMobile = media.phone`
  flex: 1;
  min-width: unset;
  &:first-child, &:nth-child(2) {
    margin-top: 16px;
  }
  a, button {
    font-size: 12px;
    line-height: 16px;
  }
  ${FooterListTitle} {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 16px;
  }
`;

const footerNavigationListTablet = media.tablet`
  min-width: 50%;
`;

export const FooterNavigationList = styled.div<FooterWrapperProps>`
  min-width: 220px;
  margin-top: 41px;
  ul {
    padding-right: 10px;
  }
  a,
  button {
    color: ${({ isDark }) => (isDark ? '#fff' : '#292a2b')};
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    align-items: center;
    text-decoration: none;

    &:hover {
      color: #2294df;
    }
  }

  ${FooterListTitle}, button {
    color: ${({ isDark }) => (isDark ? '#fff' : '#292a2b')};
  }
  ${footerNavigationListTablet};
  ${footerNavigationListMobile};
`;

const footerSocialWrapperTablet = media.tablet`
  justify-content: center;
`;

const socialListOnMobile = media.phone`
  gap: 17px;
`;

export const FooterSocialWrapper = styled.nav`
  ${footerSocialWrapperTablet};
  padding: 0;
  display: flex;
  justify-content: flex-end;

  ul {
    ${flexRow};
    gap: 31px;

    ${socialListOnMobile};
  }
`;

export const SocialLink = styled.a`
  transition: all 0.3s ease-in-out;
  background-size: auto;

  &:hover {
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
  }

  img {
    height: 32px;
  }
`;

const navListOnTablet = media.tablet`
  flex-wrap: wrap;
  margin-bottom: 36px;
`;

export const NavListWrapper = styled.div`
  display: flex;
  margin-bottom: 40px;
  justify-content: space-between;
  flex-wrap: wrap;
  ${navListOnTablet};
`;

const subscribtionAndSocialRowOnMobile = media.phone`
  label {
    text-align: left;
  }
  justify-content: center;
  nav {
    margin-bottom: 0;
  }
`;

const subscribtionAndSocialRowOnTablet = media.tablet`
  label {
    text-align: center;
  }
  justify-content: center;
`;

export const SubscribtionAndSocialRow = styled(Row)`
  margin-top: 13px;
  align-items: flex-end;
  justify-content: space-between;
  nav {
    margin-bottom: 6.5px;
  }
  ${subscribtionAndSocialRowOnTablet};
  ${subscribtionAndSocialRowOnMobile};
`;

export default FooterWrapper;
