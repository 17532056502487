import CodeSample from '@anm/components/CodeSample';
import Container from '@anm/components/Container';
import { FC } from 'react';
import { branch, renderComponent } from 'recompose';

import AnimatedWrapper from '../../../Animated';

import { Attr, DocsTable, DocsTableWrapper } from './Wrapper';

export type ButtonDocsTableProps = {
  table: TableRow[];
};

type TableDescriptionVariants = 'text' | 'code';

type TableItemDescription = { [key in TableDescriptionVariants]: string };

type TableRow = {
  attr: string;
  description: TableItemDescription[];
};

type ParagraphProps = {
  text: string;
};

const Paragraph: FC<ParagraphProps> = ({ text }) => (
  <p dangerouslySetInnerHTML={{ __html: text }} />
);

const isCodeSample = ({ code }: TableItemDescription) => !!code;

const TableItemDescription = branch(
  isCodeSample,
  renderComponent(CodeSample)
)(Paragraph);

const TableRow: FC<TableRow> = ({ attr, description }) => (
  <tr>
    <td>
      <Attr>{attr}</Attr>
    </td>
    <td>
      {description.map((line, i) => (
        <TableItemDescription {...line} key={i} />
      ))}
    </td>
  </tr>
);

const ButtonDocsTable: FC<ButtonDocsTableProps> = ({ table }) => (
  <DocsTableWrapper>
    <AnimatedWrapper variant="default" className="animated">
      <Container size={1000}>
        <DocsTable>
          <tbody>
            {table.map(item => (
              <TableRow {...item} key={item.attr} />
            ))}
          </tbody>
        </DocsTable>
      </Container>
    </AnimatedWrapper>
  </DocsTableWrapper>
);

export default ButtonDocsTable;
