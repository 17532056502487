import { css } from 'styled-components';

export type PlayIconSizes = '90x90';

export const getPlayIconStyles = (playIconSize: PlayIconSizes) =>
  ({
    '90x90': css`
      width: 90px !important;
      height: 90px !important;
    `
  }[playIconSize]);
