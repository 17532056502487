import { DEFAULT_VIDEO_META } from '@anm/data/hostingDefaults';
import sortByQuality from '@anm/helpers/sortByQuality';
import { getFilterVideoData } from '@anm/helpers/waveVideo';
import { Manifest } from '@anm/hosting-player';

import { LandingData } from '../../../getLanding';
import { VideoItem } from '../../videoUrls';
import getLandings from '../getLandings';

const isVideoId = (key: string) => key === 'videoId';
const isArrayOfIds = (key: string) => key === 'videoIds';

export const getLandingVideos = (landing: LandingData) => {
  const url = landing.url;

  let videoIds: string[] = [];

  const getVideoIdsFromUrl = (obj: { [key: string]: any }) => {
    Object.keys(obj).forEach(key =>
      isVideoId(key)
        ? (videoIds = [...videoIds, obj[key]])
        : isArrayOfIds(key)
        ? (videoIds = [...videoIds, ...[...obj[key]]])
        : Array.isArray(obj[key])
        ? obj[key].forEach(
            (k: any) => typeof k === 'object' && getVideoIdsFromUrl(k)
          )
        : typeof obj[key] === 'object'
        ? getVideoIdsFromUrl(obj[key])
        : null
    );
  };

  getVideoIdsFromUrl(landing);

  return { url, videoIds };
};

const getLandingVideoData = (videoManifests: Manifest[]) => {
  const landingsVideoData = videoManifests.map(manifest => {
    const videos = manifest.main?.filter(s => s.type === 'video');
    const images = manifest.main?.filter(s => s.type === 'image');
    const thumb = images && sortByQuality(images)[0].path;
    const title = manifest?.meta?.name || DEFAULT_VIDEO_META.name;
    const created = new Date(
      manifest.meta?.uploadDate || Date.now()
    ).toISOString();
    const duration = manifest.meta?.duration || DEFAULT_VIDEO_META.duration;
    const description =
      manifest.meta?.description || DEFAULT_VIDEO_META.description;
    const contentLocation = videos && sortByQuality(videos)[0].path;

    return {
      title,
      thumb,
      created,
      duration,
      description,
      contentLocation
    } as VideoItem;
  });

  return landingsVideoData;
};

export default async () => {
  const landings = await getLandings();
  const landingData = landings
    .map(l => getLandingVideos(l))
    .filter(l => l.videoIds.length);

  const landingVideoData = landingData.map(async ({ url, videoIds }) => {
    const videoManifests = await getFilterVideoData(videoIds);

    return {
      url,
      videos: getLandingVideoData(videoManifests)
    };
  });

  return Promise.all(landingVideoData);
};
