import { FC } from 'react';
import styled from 'styled-components';

type RadioLabelProps = {
  price: number;
  isAnnual: boolean;
};

const Wrapper = styled.div`
  display: flex;
  flex: 100%;
  justify-content: space-between;
`;

const RadioLabel: FC<RadioLabelProps> = ({ price, isAnnual, children }) => (
  <Wrapper>
    <span>{children}</span>
    <span>
      ${price}/{isAnnual ? 'an' : 'mo'}
    </span>
  </Wrapper>
);

export default RadioLabel;
