import { dropDownArrow, media } from '@anm/styles/helpers';
import styled, { css, CSSProp } from 'styled-components';

import { ModalDirection } from '.';
import { INPUT_FIELD_HEIGHT } from '@anm/styles/constants';

type ContentProps = {
  canShow: boolean;
  styles?: CSSProp;
  direction?: ModalDirection;
};

const Wrapper = styled.div`
  position: relative;
  z-index: 10;
`;

const hideStyles = css`
  pointer-events: none;
  opacity: 0;
`;

const showStyles = css`
  opacity: 1;
`;

const toLeftAdaptiveStyles = media.phone`
  transform: translateX(0) translateY(20%);
  top: 20px;

  &:before {
    top: -4px;
    right: 0;
    left: 0;
    transform: rotate(0) translateX(50%) translateY(-50%);
  }
`;

const getDirectionStyles = ({ direction = 'to-left' }: ContentProps) =>
  ({
    'to-left': css`
      top: 50%;
      transform: translateX(-108%) translateY(-50%);
      &:before {
        right: -12px;
        top: calc(50% - 7.5px);
        transform: rotate(90deg);
      }
      ${toLeftAdaptiveStyles}
    `,
    'to-right': css`
      transform: translateX(143%) translateY(-50%);
      &:before {
        left: -12px;
        transform: rotate(270deg);
      }
    `,
    'to-bottom': css`
      transform: translateY(${INPUT_FIELD_HEIGHT}) translateX(-50%);
      left: 50%;
      top: 20px;
      &:before {
        left: calc(50% - 7.5px);
      }
    `,
    'to-left-bottom': css`
      bottom: 0;
      transform: translateX(-108%);
      &:before {
        right: -12px;
        top: calc(50% - 7.5px);
        transform: rotate(90deg);
      }
    `
  }[direction]);

export const Content = styled.div<ContentProps>`
  ${dropDownArrow}
  ${getDirectionStyles}
  ${({ canShow }) => (canShow ? showStyles : hideStyles)}
  position: absolute;
  box-shadow: 0 5px 30px rgba(4, 25, 79, 0.2);
  background-color: #ffffff;
  border-radius: 10px;
  transition: all 200ms;

  &:after {
    content: unset;
  }
  ${({ styles }) => styles}
`;

export default Wrapper;
