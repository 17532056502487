import noop from '@anm/helpers/noop';
import { useState, FC } from 'react';

import DynamicRow from './DynamicRow';
import Wrapper, { ArrowIcon, Row } from './Wrapper';

export { Wrapper, ArrowIcon, Row };

export type MediaProps = {
  imgUrl?: string;
  videoId?: string;
  wistiaId?: string;
};

export type ListItem = MediaProps & {
  title: string;
  description: string;
};

export type AccordionProps = {
  list: ListItem[];
  headingLevel?: number;
  onItemChange?(i: number): void;
};

const Accordion: FC<AccordionProps> = ({
  list,
  headingLevel = 2,
  onItemChange = noop
}) => {
  const [activeRow, setActiveRow] = useState(0);

  const handleRowClick = (i: number) => {
    setActiveRow(i);
    onItemChange(i);
  };

  return (
    <Wrapper>
      {list.map((rowProps, i) => (
        <DynamicRow
          key={rowProps.title}
          isActiveRow={i === activeRow}
          onRowClick={() => handleRowClick(i)}
          {...{ ...rowProps, headingLevel }}
        />
      ))}
    </Wrapper>
  );
};

export default Accordion;
