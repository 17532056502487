import Container from '@anm/components/Container';
import isMobile from '@anm/helpers/is/isMobile';
import useIsMountedCheck from '@anm/hooks/useIsMountedCheck';
import React, { FC } from 'react';

import { CommonLandingProps } from '../../../features/getLanding';
import Background, { BackgroundVariants } from '../../Background';
import LandingButton, { LandingButtonProps } from '../../LandingButton';
import { SectionTitleProps } from '../../SectionTitle';

import { MediaProps } from './Media';
import MediaBlock from './MediaBlock';
import SideMediaButtonsWrapper from './SideMediaButtonsWrapper';
import SideMediaContentWrapper from './SideMediaContentWrapper';
import { SideMediaDataWrapper, SideMediaDescription, SideMediaTitle } from './SideMediaDataWrapper';
import SectionWrapper from './Wrapper';

export type TextVariant = 'medium' | 'big';

export type SideMediaProps = CommonLandingProps &
  SectionTitleProps &
  MediaProps & {
    sectionWidth?: number;
    dataMaxWidth?: number;
    imageSideMediaUrl?: string;
    button?: LandingButtonProps;
    textVariant?: TextVariant;
    isTitleExtraBold?: boolean;
    isVideoOnMobileUnderTitle?: boolean;
    backgroundVariant?: BackgroundVariants;
  };

const SideMedia: FC<SideMediaProps> = ({
  title,
  button,
  sideMedia,
  imageSideMediaUrl,
  alt,
  mediaFrame,
  description,
  sectionWidth,
  playIconSize,
  headingLevel,
  dataMaxWidth,
  mediaMaxWidth,
  customBackground,
  isTitleExtraBold,
  backgroundVariant,
  textVariant = 'medium',
  isVideoOnMobileUnderTitle
}) => {
  const showDesktopMedia = !(isMobile() && isVideoOnMobileUnderTitle) || !isMobile();
  const showMobileMedia = useIsMountedCheck() && isMobile() && isVideoOnMobileUnderTitle;

  const mediaProps = {
    title,
    sideMedia,
    mediaFrame,
    playIconSize,
    mediaMaxWidth,
    imageSideMediaUrl,
    alt
  };

  return (
    <SectionWrapper>
      <Background variant={backgroundVariant || 'with-smaller-bottom-wave'} customBackground={customBackground}>
        <Container size={sectionWidth || 1180}>
          <SideMediaContentWrapper>
            <SideMediaDataWrapper {...{ dataMaxWidth }}>
              <SideMediaTitle
                {...{
                  title,
                  textVariant,
                  isTitleExtraBold
                }}
                color="black"
                level={headingLevel}
              />
              {showMobileMedia && <MediaBlock {...mediaProps} />}
              <SideMediaDescription {...{ textVariant, description }} color="black" />
              <SideMediaButtonsWrapper>
                <LandingButton {...button} size={button?.size || 'upper-big'} variant={button?.variant || 'orange'} />
              </SideMediaButtonsWrapper>
            </SideMediaDataWrapper>
            {showDesktopMedia && <MediaBlock {...mediaProps} />}
          </SideMediaContentWrapper>
        </Container>
      </Background>
    </SectionWrapper>
  );
};

export default SideMedia;
