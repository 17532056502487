import styled from 'styled-components';
import { flexColumn, flexRow, media } from '@anm/styles/helpers';
import Container from '@anm/components/Container';
import getFromCdn from '@anm/helpers/getFromCdn';

import { TStreamDestinations } from '.';

const mobileWrapper = media.phone`
	background-image: url(${getFromCdn('images/data/small_star.svg')}), url(${getFromCdn('images/data/big_star.svg')});
  background-size: 66px, 140px;
  background-position: top -5px right -10px, left -64px bottom -64px;

`;

export const Wrapper = styled.section<TStreamDestinations>`
  padding: 30px 0 40px;
  background-color: ${({ background }) => background};
  background-image:
		url(${getFromCdn('images/data/small_star.svg')}), url(${getFromCdn('images/data/small_star.svg')}),
    url(${getFromCdn('images/data/big_star.svg')}), url(${getFromCdn('images/data/big_star.svg')});
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: 95px, 140px, 315px, 315px;
  background-position: top -64px left 350px, bottom -34px right 187px, bottom -152px left, top -140px right -172px;

  ${mobileWrapper};
`;

export const ContainerWrapper = styled(Container)`
  ${flexColumn};
  gap: 15px;
  justify-content: center;

  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: center;
    font-size: 40px;
    font-weight: 800;
    line-height: 54px;
  }
`;

const mobileDestinationIcons = media.phone`
	gap: 0;
	width: 100%;
	justify-content: space-between;
	img {
		width: 36px;
	}
`;

export const DestinationIconsWrapper = styled.div`
  ${flexRow};
  gap: 35px;
  align-self: center;
  margin: 24px 0;
  img {
    width: 60px;
  }

  ${mobileDestinationIcons};
`;

const mobileDestinationLabelsContainer = media.phone`
	max-width: 335px;
`

export const DestinationLabelsContainer = styled.div`
  ${flexRow};
  gap: 4px 24px;
  max-width: 700px;
  flex-wrap: wrap;
  align-self: center;
  justify-content: center;
  p {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

	${mobileDestinationLabelsContainer};
`;
