import { Component, ComponentType } from 'react';

interface WithModalControlsState {
  isModalOpened: any;
}

interface ExternalProps {}

export interface WithModalControlsProps {
  isModalOpened: any;
  openModal: any;
  closeModal: any;
}

const withModalControls = <P extends object>(
  WrappedComponent: ComponentType<P & WithModalControlsProps>
) => {
  class WithModalControls extends Component<
    P & ExternalProps,
    WithModalControlsState
  > {
    static displayName = `withModalControls(${WrappedComponent.name ||
      'BaseComponent'})`;

    state = {
      isModalOpened: false
    };

    openModal = () => {
      this.setState({
        isModalOpened: true
      });
    };

    closeModal = () => {
      this.setState({
        isModalOpened: false
      });
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          openModal={this.openModal}
          closeModal={this.closeModal}
        />
      );
    }
  }

  type PropsExcludingDefaults = Pick<
    P,
    Exclude<keyof P, keyof WithModalControlsProps>
  >;
  type RecomposeProps = Partial<WithModalControlsProps> &
    PropsExcludingDefaults;

  return (WithModalControls as ComponentType<P>) as ComponentType<
    RecomposeProps
  >;
};

export default withModalControls;
