import { stretchBlock } from '@anm/styles/helpers';
import styled from 'styled-components';

export const LoaderWrapper = styled.div`
  ${stretchBlock}
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

export const PlayerLoader = styled.div<{ transparent: boolean }>`
  background: ${({ transparent }) => (transparent ? 'transparent' : 'rgba(41, 42, 43, 0.8)')};
  border-radius: 50%;
  position: relative;
  width: 92px;
  height: 92px;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  animation: spin 0.5s infinite linear;
  margin: 0 auto;

  svg {
    position: absolute;
    top: 3px;
    right: 3px;
  }
`;

export const LoaderMessage = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #fff;
  margin-top: 8px;
`;

export const ContentWrapper = styled.div`
  transform: translateY(-10px);
`;
