import * as analytic from '@anm/analytic';
import api from '@anm/api';
import { MediaInfoFormat, MediaInfoProps } from '@anm/api/modules/youtube';
import { UserContext } from '@anm/components/user/UserProvider';
import { isGuestUser } from '@anm/helpers/user/isUser';
import useRequestState from '@anm/hooks/useRequestState';
import useThrottle from '@anm/hooks/useThrottle';
import { useCallback, useContext } from 'react';
import { ReturnFunctionArg } from 'types/helpers';

import mapDefaultFormatNote from '../helpers/mapDefaultFormatNote';
import { isFHD } from '../helpers/resolution';

export type SelectedMediaFormat = MediaInfoFormat & {
  isVideoOnlyFormat?: boolean;
  shouldUpgrade?: boolean;
};

const useMediaFormatsSelector = (formats?: MediaInfoFormat[], formatsVideoOnly?: MediaInfoFormat[]) => {
  const { user } = useContext(UserContext);
  const isUserGuest = isGuestUser(user);

  const selectMediaFormats = useCallback(() => {
    if (!formats) return [];

    const has1080p = !!formats.find(({ format_note }) => isFHD(format_note));
    if (has1080p || !formatsVideoOnly) return formats;

    const mp4Format = formatsVideoOnly.find(({ ext, format_note }) => isFHD(format_note) && ext === 'mp4');

    return [...formats, mp4Format && { ...mp4Format, isVideoOnlyFormat: true, shouldUpgrade: isUserGuest }].filter(
      v => !!v
    ) as SelectedMediaFormat[];
  }, [formats]);

  return selectMediaFormats;
};

const useMediaInfo = () => {
  const { isUserLogged } = useContext(UserContext);

  const getMediaInfoRequest = async (props: MediaInfoProps) => {
    const mediaInfo = await api().youtube.getMediaInfo({ url: props.url });

    const hasAudio = mediaInfo.formats.some(format => format.vcodec === 'none' && format.acodec !== 'none');

    const removeIOSandANDR = (format: MediaInfoFormat) => ({
      ...format,
      format_note: format.format_note.replace(/, IOS|, ANDR/gi, '')
    });

    mediaInfo.formats = mediaInfo.formats
      .filter(
        format =>
          format.vcodec !== 'none' && format.acodec !== 'none' && (format.ext === 'mp4' || format.ext === 'webm')
      )
      .map(removeIOSandANDR);

    mediaInfo.formatsVideoOnly = mediaInfo.formatsVideoOnly.map(removeIOSandANDR);

    return { info: mapDefaultFormatNote(mediaInfo), hasAudio };
  };

  const [mediaInfoState, { request, resetState }] = useRequestState(getMediaInfoRequest, { resetState: true });

  const { formats, formatsVideoOnly } = mediaInfoState.data?.info || {};
  const selectMediaFormats = useMediaFormatsSelector(formats, formatsVideoOnly);

  const getMediaInfo = useThrottle(
    useCallback(
      async (props: ReturnFunctionArg<typeof request>[0]) => {
        if (!props?.url || props?.url === mediaInfoState.params?.url) return Promise.resolve();
        if (!props?.url && mediaInfoState.params?.url) {
          resetState();
          return;
        }
        analytic.trackYoutubeGetInfo(props?.url);
        return request(props);
      },
      [request, mediaInfoState.params, isUserLogged]
    ),
    3000
  );

  return [mediaInfoState, { getMediaInfo, selectMediaFormats }] as const;
};

export default useMediaInfo;
