import { media } from '@anm/styles/helpers';
import styled from 'styled-components';

const wrapperOnMobile = media.phone``;

export const Wrapper = styled.section`
  position: relative;
  width: 100%;
  img {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  ${wrapperOnMobile};
`;

export default Wrapper;
