import { useEffect } from 'react';

import useSwitchState from '../useSwitchState';

const useErrorState = (check: () => boolean) => {
  const [isError, _, hideError, __, setError] = useSwitchState(check());

  useEffect(() => {
    setError(check());
  }, [check()]);

  return [isError, { setError, hideError }] as const;
};

export default useErrorState;
