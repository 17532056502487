import Backdrop from '@anm/components/modals/Modal/Backdrop';
import { FC } from 'react';
import styled from 'styled-components';

export const MODAL_CONTAINER_ID = 'modal-root';

export type ModalRootProps = {
  zIndex?: number;
  backdropBg?: string;
};

export const ModalRootWrapper = styled.div<ModalRootProps>`
  &:not(:empty) {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: ${({ zIndex = 999 }) => zIndex};
    left: 0;
    top: 0;
  }

  ${Backdrop} {
    background-color: ${({ backdropBg }) => backdropBg};
  }
`;

const ModalRoot: FC<ModalRootProps> = ({ backdropBg }) => (
  <ModalRootWrapper id={MODAL_CONTAINER_ID} {...{ backdropBg }} />
);

export default ModalRoot;
