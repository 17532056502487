import useHover from '@anm/hooks/useHover';
import { FC } from 'react';
import { branch, renderComponent } from 'recompose';

import { Cancel, CircleBar, CircleFill, CircleSlice, LinearProgress, ProgressCircle } from './Wrapper';

export { ProgressCircle, CircleBar, CircleFill, Cancel };

export type ProgressBarVariants = 'linear' | 'radial';

export type ProgressBarProps = {
  value: number;
  isSpin?: boolean;
  variant?: ProgressBarVariants;
  onCancel?: () => void;
};

const RadialProgressbar: FC<ProgressBarProps> = ({ onCancel, children, ...otherProps }) => {
  const [ref, isHovered] = useHover<HTMLDivElement>();
  const isCancelButton = onCancel && (children ? isHovered : true);
  const newValue = otherProps.isSpin ? 30 : otherProps.value;

  return (
    <div ref={ref}>
      {isCancelButton && <Cancel onClick={onCancel} />}
      <ProgressCircle>
        {(!isHovered || !onCancel) && children}
        <CircleSlice {...otherProps} value={newValue}>
          <CircleBar {...otherProps} value={newValue} />
          <CircleFill {...otherProps} value={newValue} />
        </CircleSlice>
      </ProgressCircle>
    </div>
  );
};

const LinearProgressBar: FC<ProgressBarProps> = props => <LinearProgress {...props} max={100} />;

const isRadialProgressBar = ({ variant = 'linear' }: ProgressBarProps) => variant === 'radial';

export default branch(isRadialProgressBar, renderComponent(RadialProgressbar))(LinearProgressBar);
