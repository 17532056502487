import { FC } from 'react';

import Body, { BodyProps } from './Body';
import Header, { TableHeaderProps } from './Header';
import Wrapper, { BodyContainer } from './Wrapper';

type TableProps = TableHeaderProps & BodyProps;

const Table: FC<TableProps> = ({ list, header }) => (
  <Wrapper>
    <Header {...{ header }} />
    <BodyContainer>
      <Body {...{ list }} />
    </BodyContainer>
  </Wrapper>
);

export default Table;
