import { FC, RefObject } from 'react';
import styled, { css } from 'styled-components';

export type CardVariants = 'gray' | 'white' | 'black' | 'transparent';

type DefaultProps = {
  variant: CardVariants;
  hasShadow: boolean;
  isPadding: boolean;
};

export type CardProps = {
  minWidth?: string;
  hasShadow?: boolean;
  isPadding?: boolean;
  borderRadius?: number;
  variant?: CardVariants;
  cardRef?: RefObject<HTMLDivElement>;
};

const variants: { [key in CardVariants]: ReturnType<typeof css> } = {
  gray: css`
    background-color: #f5f7fa;
  `,
  white: css`
    background-color: ${({ theme }) => theme.dropdownAndModalBg};
  `,
  black: css`
    background-color: #292a2b;
  `,
  transparent: css`
    background-color: transparent;
  `
};

export const CardWrapper = styled.div<CardProps & DefaultProps>`
  min-width: ${({ minWidth }) => minWidth || 'unset'};
  padding: ${({ isPadding }) => (isPadding ? '10px 20px' : 0)};
  border-radius: ${({ borderRadius }) => borderRadius || 5}px;
  box-shadow: ${({ hasShadow }) => (hasShadow ? '0 2px 10px 0 rgba(55, 61, 63, 0.1);' : 'none')};

  ${({ variant }) => variants[variant]};
`;

const Card: FC<CardProps> = ({
  cardRef,
  variant = 'white',
  hasShadow = true,
  isPadding = true,
  borderRadius = 5,
  ...otherProps
}) => <CardWrapper ref={cardRef} {...{ variant, hasShadow, isPadding, borderRadius, ...otherProps }} />;

export default Card;
