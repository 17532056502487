import { DropdownContent } from '@anm/components/Dropdown';
import { flexRow } from '@anm/styles/helpers';
import styled from 'styled-components';

export const Wrapper = styled.div`
  left: 8px;
  z-index: 10;
  bottom: 12px;
  cursor: pointer;
  position: absolute;
  ${DropdownContent} {
    top: unset;
    left: -230%;
    bottom: -8px;
    width: 320px;
    padding: 25px 0;
    max-height: 464px;
    border-radius: 10px;
    &:after,
    &:before {
      content: '';
      top: unset;
      left: 100%;
      bottom: 15px;
      transform: none;
      position: absolute;
      border-style: solid;
      border-width: 9px 10px 9px;
      border-color: transparent transparent transparent #fff;
    }
  }
`;

export const HintOpenerWrapper = styled.div`
  ${flexRow};
  height: 24px;
  font-size: 12px;
  min-width: 148px;
  padding: 4px 8px;
  border-radius: 20px;
  font-weight: normal;
  border: 1px solid #ccdde6;
  background-color: #f5f7fa;
  img {
    max-height: 16px;
    margin-right: 2px;
  }
`;

export const HintDescription = styled.p`
  margin: 0 0 10px;
  font-size: 16px;
  line-height: 24px;
`;

export const HintTitle = styled(HintDescription)`
  font-weight: bold;
`;

export const HintContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 20px;
  overflow: scroll;
  max-height: 414px;
  img {
    max-width: 100%;
    margin-bottom: 20px;
  }
`;
