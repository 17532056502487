import styled from 'styled-components';

import { media } from '../../../styles/helpers';

import { CustomIconProps } from './types';

const IconHolderOnTablet = media.tablet<CustomIconProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ borderRadius }) =>
    borderRadius ? `${borderRadius}px` : '50%'};
  background-color: ${({ background }) =>
    background ? `${background}` : '#fff200'};

  img {
    flex-shrink: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: ${({ size }) => (size ? `${size}px` : 'unset')};
    max-height: ${({ size }) => (size ? `${size}px` : 'unset')};
  }
`;

const IconHolder = styled.div<CustomIconProps>`
  display: none;
  ${IconHolderOnTablet};
`;

export default IconHolder;
