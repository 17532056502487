import { isAnon } from '@anm/auth/helpers/authAnon';
import hasAuthAccess from '@anm/auth/helpers/hasAuthAccess';
import { UserContext } from '@anm/components/user/UserProvider';
import useEventPending from '@anm/hooks/useEventPending';
import { useCallback, useContext, useEffect, useState, MouseEvent } from 'react';

import { buttonAuthData, ButtonAuthProps } from '..';

import useSignUpUrl from './useSignUpUrl';

const useButtonAuthHandler = (props: ButtonAuthProps) => {
  const { anon } = useContext(UserContext);
  const { kind, anonUser, href: buttonHref } = props;

  const { url: themeUrl, anonUser: themeAnonUser } = buttonAuthData[kind || 'custom'];
  const localUrl = buttonHref || themeUrl;
  const signUpUrl = useSignUpUrl({ url: localUrl, kind });

  const [href, setHref] = useState<string>();
  const anonUserLocal = anonUser || (anonUser === undefined && themeAnonUser);
  const hasAccess = (hasAuthAccess() && !isAnon()) || anonUserLocal;

  const openPage = () => window.open(localUrl, '_self');
  const statEventPending = useEventPending(anon.isPending, openPage);

  useEffect(() => {
    setHref(hasAccess ? localUrl : signUpUrl);
  }, [hasAccess, localUrl, signUpUrl]);

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (hasAuthAccess() || !anonUserLocal || anon.isPending) return;

      e.preventDefault();
      anon.signup();
      statEventPending();
    },
    [anon.isPending, anonUserLocal, hasAuthAccess()]
  );

  return [handleClick, href || signUpUrl, anon] as const;
};

export default useButtonAuthHandler;
