import getFromCdn from '@anm/helpers/getFromCdn';
import styled, { css } from 'styled-components';

import { ProgressBarProps } from './index';

export const LinearProgress = styled.progress`
  display: block;
  width: 100%;
  appearance: none;
  height: auto;

  ::-webkit-progress-bar {
    background: none;
    transition: 0.3s;
  }

  ::-webkit-progress-value {
    height: 4px;
    background: var(--blue_100);
    transition: 0.3s;
  }
`;

export const ProgressCircle = styled.div`
  position: relative;
  font-size: 56px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: rgba(41, 42, 43, 0.8);
  cursor: default;

  &:before {
    display: block;
    position: absolute;
    content: '';
    top: -4px;
    left: -4px;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    border-radius: 50%;
    background: rgba(41, 42, 43, 0.8);
  }

  &:after {
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    border: 2px solid rgba(255, 255, 255, 0.15);
    border-radius: 50%;
    box-sizing: border-box;
  }
`;

export const CircleSlice = styled.div<ProgressBarProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  clip: ${({ value }) => (value >= 51 ? 'rect(auto, auto, auto, auto)' : 'rect(0em, 1em, 1em, 0.5em);')};

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  ${({ isSpin }) => isSpin && `animation: spin 1s infinite linear;`}
`;

export const CircleBar = styled.div<ProgressBarProps>`
  position: absolute;
  border: 2px solid var(--blue_100);
  width: 100%;
  height: 100%;
  clip: rect(0em, 0.5em, 1em, 0em);
  border-radius: 50%;
  transform: ${({ value }) => `rotate(${value * 3.6}deg)`};

  &:after {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    transform: ${({ value }) => (value >= 51 ? `rotate(180deg)` : 'none')};
  }
`;

export const CircleFill = styled.div<ProgressBarProps>`
  ${({ value }) =>
    value >= 51 &&
    css`
      position: absolute;
      border: 2px solid var(--blue_100);
      width: 100%;
      height: 100%;
      clip: rect(0em, 0.5em, 1em, 0em);
      border-radius: 50%;
      transform: rotate(0deg);
    `};

  transform: ${({ value }) => (value >= 51 ? `rotate(180deg)` : 'none')};
`;

export const Cancel = styled.div.attrs({ title: 'Cancel upload' })`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  cursor: pointer;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  transition: 0.3s;
  background: url(${getFromCdn('images/icons/cancel_progress.svg')}) no-repeat center / cover;

  &:hover {
    opacity: 0.8;
  }
`;
