import Avatar from '@anm/components/Avatar';
import { FC } from 'react';
import { User } from 'user';

import withUser from '../../HOCs/withUser';
import lfConfig from '../../config';

export type UserAvatarProps = {
  user: User;
};

const UserAvatar: FC<UserAvatarProps> = ({ user }) => {
  if (!user) return null;

  const userPageUrl = `${lfConfig.waveUrl}videos`;
  return (
    <Avatar src={user.avatar} alt="User Avatar" size={40} href={userPageUrl} />
  );
};

export default withUser(UserAvatar);
