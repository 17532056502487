import { media } from '@anm/styles/helpers';
import styled from 'styled-components';

import SectionTitle from '../../SectionTitle';
import Container from '@anm/components/Container';

const listTitleOnMobile = media.phone`
  max-width: unset;
  padding: 0 20px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 28px;
    line-height: 40px;
    margin: 20px 30px;
  }
  p {
    margin-top: unset;
  }
`;

const cardListContainerOnDesktop = media.desktop`
  padding: 0;
`;

export const ListTitle = styled(SectionTitle)`
  margin: 0 auto 60px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 40px;
    font-weight: 900;
    line-height: 60px;
    max-width: 1000px;
    margin: 50px auto 27px;
  }
  p {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    max-width: 800px;
  }
  ${listTitleOnMobile};
`;

const CardListWrapper = styled.section``;

export const CardListContainer = styled(Container)`
  ${cardListContainerOnDesktop};
`;

export default CardListWrapper;
