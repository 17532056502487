import isServer from '@anm/helpers/is/isServer';
import { PropsWithChildren, ReactElement, ReactNode } from 'react';

import { BreakpointCols, MasonryProps } from './types';

export type GetDynamicBreakpointProps = {
  itemWidth: number;
  containerWidth: number;
};
export const getDynamicBreakpoint = ({ itemWidth, containerWidth }: GetDynamicBreakpointProps) => {
  const windowWidth = isServer() ? null : window.outerWidth;
  if (!windowWidth) return {};

  return {
    [windowWidth]: Math.floor(containerWidth / itemWidth)
  };
};

export const getColumnCount = (breakpointCols: BreakpointCols) => {
  const windowWidth = (!isServer() && window.outerWidth) || Infinity;
  let matchedBreakpoint = Infinity;
  let columns = breakpointCols.default || 2;

  for (const breakpoint in breakpointCols) {
    const optBreakpoint = parseInt(breakpoint, 10);
    const isCurrentBreakpoint = optBreakpoint > 0 && windowWidth <= optBreakpoint;

    if (isCurrentBreakpoint && optBreakpoint < matchedBreakpoint) {
      matchedBreakpoint = optBreakpoint;
      columns = breakpointCols[breakpoint];
    }
  }

  return Math.max(1, columns || 1);
};

export const getSmallestIndex = (columnsSizes: number[]) => {
  const smallestSize = [...columnsSizes].sort((a, b) => a - b)[0];
  const smallestSizeIndex = columnsSizes.indexOf(smallestSize);

  return smallestSizeIndex;
};

export const calcSmallestSizeIndex = () => {
  const columnsSizes: number[] = [];

  return (columnCount: number, index: number, itemHeight: number) => {
    const columnIndex = index % columnCount;

    if (!columnsSizes[columnIndex]) {
      columnsSizes[columnIndex] = 0;
    }

    const smallestSizeIndex = getSmallestIndex(columnsSizes);
    columnsSizes[smallestSizeIndex] = columnsSizes[smallestSizeIndex] + itemHeight;

    return smallestSizeIndex;
  };
};

export const getColumnsWithItems = (columnCount: number, elements: ReactElement[] = []) => {
  const getSmallestSizeIndex = calcSmallestSizeIndex();

  return elements.reduce((acc, element, i) => {
    const newColumnIndex = getSmallestSizeIndex(columnCount, i, element.props.height);

    if (!acc[newColumnIndex]) {
      acc[newColumnIndex] = [];
    }

    acc[newColumnIndex].push(element);

    return acc;
  }, [] as ReactNode[][]);
};

export const getColumnCountAndWidth = (breakpointCols: BreakpointCols) => {
  const count = getColumnCount(breakpointCols);

  return {
    count,
    width: 100 / count
  };
};

export const shouldMasonryUpdate = (prev: PropsWithChildren<MasonryProps>, next: PropsWithChildren<MasonryProps>) => {
  const isSameElement =
    isMasonryContainer(prev?.children) &&
    isMasonryContainer(next?.children) &&
    prev?.children?.length === next.children.length &&
    prev.children?.every(({ key }, i) => isMasonryContainer(next?.children) && key === next.children?.[i]?.key);

  return isSameElement;
};

export const isMasonryContainer = (elements: ReactElement | ReactNode): elements is ReactElement[] =>
  (elements as ReactElement[]).every(element => element.props?.height);

export const getMasonryContent = (children: ReactNode) =>
  isMasonryContainer(children) ? children : ([] as ReactElement[]);
