import api from '@anm/api';
import { MediaInfoFormat, MediaInfoProps, MediaType } from '@anm/api/modules/youtube';
import { getAuthToken } from '@anm/auth/helpers/authToken';
import { toUrlParams } from '@anm/helpers/parseParams';
import useRequestState from '@anm/hooks/useRequestState';

import { getResolutionByFormatId } from '../helpers';

type UseRequestDownloaderProps = Partial<MediaInfoProps> & {
  type: MediaType;
  url?: string;
  title?: string;
  mediaFormats?: MediaInfoFormat[];
};

const useRequestDownloader = ({ url, type, title, mediaFormats }: UseRequestDownloaderProps) => {
  const open = (jobId: string, type: string) => {
    const query = toUrlParams({ type, title, auth_token: getAuthToken() });
    jobId && window.open(`${api().youtube.axiosInstance.defaults.baseURL}/download-new/${jobId}?${query}`);
  };

  const downloadRequest = async (formatId: string) => {
    if (!url) return;

    const resolution = mediaFormats && getResolutionByFormatId(formatId, mediaFormats);
    const typeWithResolution = resolution === 1080 ? `${type}${resolution}` : type;

    const jobId = await api().youtube.getDownloadJobId({ url, type: typeWithResolution });
    open(jobId, typeWithResolution);

    return { jobId };
  };
  const [_, { request: download }] = useRequestState(downloadRequest);

  return download;
};

export default useRequestDownloader;
