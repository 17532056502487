import { FooterWrapperProps } from '@anm/components/footers/TopFooter';
import React, { FC } from 'react';
import { compose } from 'recompose';

import withAnimations from '../../HOCs/withAnimations';
import withUser, { WithUserProps } from '../../HOCs/withUser';
import useVisitTracker from '../../hooks/useVisitTracker';
import Navigation, { HeaderProps } from '../Navigation';
import Footer from '../sections/Footer';
import TopBanner, { TopBannerProps } from '../TopBanner';

type LayoutProps = WithUserProps & {
  header?: HeaderProps;
  footer?: FooterWrapperProps;
  topBanner?: TopBannerProps;
};

const Layout: FC<LayoutProps> = ({ children, footer, topBanner, user }) => {
  useVisitTracker(user);

  return (
    <>
      <div>
        {topBanner && <TopBanner {...topBanner} />}
        <Navigation />
        <main>{children}</main>
        <Footer {...footer} />
      </div>
    </>
  );
};

export default compose(withUser, withAnimations)(Layout);
