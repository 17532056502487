import Container from '@anm/components/Container';
import isMobile from '@anm/helpers/is/isMobile';
import useIsMountedCheck from '@anm/hooks/useIsMountedCheck';
import { FC } from 'react';

import { CommonLandingProps } from '../../../features/getLanding';
import getSubtitleLevel from '../../../helpers/getSubtitleLevel';
import LandingButton, { LandingButtonProps } from '../../LandingButton';
import { SectionTitleProps } from '../../SectionTitle';

import ListContent from './List';
import Slider from './Slider';
import CardListWrapper, { ListTitle } from './Wrapper';
import { ListContentProps } from './types';

export type CardListProps = CommonLandingProps &
  ListContentProps &
  Partial<SectionTitleProps> & {
    button?: LandingButtonProps;
    padding?: string;
    containerSize?: number;
  };

const CardList: FC<CardListProps> = ({
  list,
  button,
  padding,
  headingLevel,
  hasShadow = false,
  itemsPerRow = list.length,
  containerSize = 1150,
  ...titleProps
}) => {
  const canShowSlider = isMobile() && useIsMountedCheck();
  const listProps = { list, itemsPerRow, hasShadow };

  return (
    <CardListWrapper {...{ padding }}>
      <Container size={containerSize}>
        <ListTitle {...titleProps} color="black" level={headingLevel || 2} />
        {canShowSlider ? (
          <Slider {...listProps} />
        ) : (
          <ListContent {...listProps} headingLevel={getSubtitleLevel(headingLevel)} />
        )}
        <LandingButton size="big" variant="light-blue-lowercase" {...{ button }} />
      </Container>
    </CardListWrapper>
  );
};

export default CardList;
