import Container from '@anm/components/Container';
import Image from '@anm/components/image/Image';
import { FC } from 'react';

import Background from '../../Background';
import FlexContainer from '../../FlexContainer';
import LandingButton, { LandingButtonProps } from '../../LandingButton';

import { ContentWrapper, ImageWrapper } from './Wrapper';

export type HorizontalImageTextProps = {
  alt: string;
  text?: string;
  title: string;
  imageUrl: string;
  button?: LandingButtonProps;
};

const HorizontalImageText: FC<HorizontalImageTextProps> = ({ text, title, alt = title, imageUrl, button }) => (
  <section>
    <Background variant="horizontal-image-text">
      <Container>
        <FlexContainer variant="between-on-laptop">
          <ImageWrapper>
            <Image src={imageUrl} alt={alt} />
          </ImageWrapper>
          <ContentWrapper>
            <span>{title}</span>
            <p>{text}</p>
            <LandingButton variant="yellow" size="big" {...button} />
          </ContentWrapper>
        </FlexContainer>
      </Container>
    </Background>
  </section>
);

export default HorizontalImageText;
