import { DropdownContent, DropdownWrapper } from '@anm/components/Dropdown';
import IconButton from '@anm/components/buttons/IconButton';
import { media } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

const mobileNewVideoButtonStyles = media.phone`
  min-width: 50%;

  && button { min-width: 100%; }
`;

export const CreateVideoButton = styled(IconButton)`
  width: 100%;
  max-width: 160px;
`;

export const WrapperNewVideo = styled.div<{ isUploadInProgress: boolean }>`
  position: relative;
  width: 160px;

  button {
    &:hover {
      color: #fff;
      border: none;
      background: #0693e0;
    }
  }
  ${DropdownWrapper}:after {
    margin: 0;
  }
  ${DropdownContent} {
    ${({ isUploadInProgress }) =>
      isUploadInProgress &&
      css`
        display: none;
      `}
  }

  ${mobileNewVideoButtonStyles};
`;
