import { composeUrlQuery } from '@anm/helpers/parseParams';
import useUTMQuery from '@anm/hooks/useUTMQuery';

import { LandingButtonKind } from '../../../../../components/LandingButton/types';
import { getSignUpRedirectParams } from '../../../../../components/auth/AuthButtons';
import lfConfig from '../../../../../config';

const { waveUrl } = lfConfig;

type UseLinkProps = { kind?: LandingButtonKind; url?: string };

const useSignUpUrl = ({ kind, url }: UseLinkProps) => {
  const utmQuery = useUTMQuery();

  const signUpUrl = `${waveUrl}signup${composeUrlQuery(getSignUpRedirectParams({ kind, redirectUrl: url }), utmQuery)}`;

  return signUpUrl;
};

export default useSignUpUrl;
