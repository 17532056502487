import styled, { css } from 'styled-components';

import { media } from '../../styles/helpers';
import { FlexContainerProps, FlexContainerVariants } from '../FlexContainer';

const OnLaptop = media.laptop`
  display: flex;
`;

const CenterBetweenOnLaptop = media.laptop`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ColumnReverseOnMobile = media.laptop`
  display: block;
`;

const ColumnReverseOnMobileRowCenterOnLaptop = media.laptop`
  flex-direction: row;
  align-items: center;
`;

const BaselineOnMobileEndNowrapOnLaptop = media.laptop`
    align-items: flex-end;
    flex-wrap: nowrap;
`;

const BetweenOnTablet = media.tablet`
    display: flex;
    justify-content: space-between;
`;

const BetweenOnLaptop = media.laptop`
    display: flex;
    justify-content: space-between;
`;

const StartCenterOnTablet = media.tablet`
    display: flex;
    justify-content: center;
`;

const CenterCenterOnLaptop = media.laptop`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CenterBetweenOnTablet = media.tablet`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const StartCenterOnLaptop = media.laptop`
    display: flex;
    justify-content: center;
`;

const ColumnCenterOnMobileBetweenReverseOnLaptop = media.tablet`
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
`;

const WrapOnMobileNowrapAroundOnTablet = media.tablet`
    flex-wrap: nowrap;
    justify-content: space-around;
`;

const BetweenWrapOnLaptop = media.laptop`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const EndCenterOnLaptop = media.laptop`
    display: flex;
    justify-content: center;
    align-items: flex-end;
`;

const variants: { [key in FlexContainerVariants]: ReturnType<typeof css> } = {
  'on-laptop': css`
    ${OnLaptop};
  `,

  'center-between-on-laptop': css`
    ${CenterBetweenOnLaptop};
  `,
  'column-reverse-on-mobile': css`
    display: flex;
    flex-direction: column-reverse;
    ${ColumnReverseOnMobile};
  `,
  'column-reverse-on-mobile-row-center-on-laptop': css`
    display: flex;
    flex-direction: column-reverse;
    ${ColumnReverseOnMobileRowCenterOnLaptop};
  `,
  'baseline-on-mobile-end-nowrap-on-laptop': css`
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    ${BaselineOnMobileEndNowrapOnLaptop};
  `,
  'between-on-tablet': css`
    ${BetweenOnTablet};
  `,
  'between-on-laptop': css`
    ${BetweenOnLaptop};
  `,
  'center-center-on-laptop': css`
    ${CenterCenterOnLaptop};
  `,
  'center-between-on-tablet': css`
    ${CenterBetweenOnTablet};
  `,
  'start-center-on-laptop': css`
    ${StartCenterOnLaptop};
  `,
  'column-center-on-mobile-between-reverse-on-tablet': css`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${ColumnCenterOnMobileBetweenReverseOnLaptop};
  `,
  'wrap-on-mobile-nowrap-around-on-tablet': css`
    display: flex;
    flex-wrap: wrap;
    ${WrapOnMobileNowrapAroundOnTablet};
  `,
  'between-wrap-on-laptop': css`
    ${BetweenWrapOnLaptop};
  `,
  'end-center-on-laptop': css`
    ${EndCenterOnLaptop};
  `,
  'start-center-on-tablet': css`
    ${StartCenterOnTablet};
  `
};

export const FlexBlock = styled.div<FlexContainerProps>`
  ${({ variant }) => variants[variant]};
`;
