import CardModal, { CardModalProps } from '@anm/components/modals/CardModal';
import * as semrush from '@anm/helpers/semrush'
import { FC } from 'react';
import styled from 'styled-components';

import useListenEvents from './useListenEvents';

const Iframe = styled.iframe`
  width: 1280px;
  height: 760px;
  border: none;
  max-width: 80vw;
  max-height: 80vh;
  position: relative;
`;

type TRecorderModal = CardModalProps & {
  waveUrl: string;
  folderId?: string | number;
};

const RecorderModal: FC<TRecorderModal> = ({ waveUrl, folderId, onModalClose }) => {
  useListenEvents({ waveUrl, folderId, onClose: onModalClose });

  const isSemrush = semrush.isSemrushIframe();
  const recorderUrl = `${waveUrl}video-record/${isSemrush ? '?isSemrush=true' : ''}`;

  return (
    <CardModal onModalClose={onModalClose} isClickableBackdrop={false}>
      <Iframe allow="camera; microphone; display-capture; autoplay; clipboard-write;" src={recorderUrl} />
    </CardModal>
  );
};

export default RecorderModal;
