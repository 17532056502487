import Container from '@anm/components/Container';
import { PartialButtonProps } from '@anm/components/buttons/Button';
import LazyImage from '@anm/components/image/LazyImage';
import React, { FC } from 'react';

import LandingButton, { LandingButtonProps } from '../../LandingButton';
import { ButtonWrapper } from '../UltraTitle/Wrapper';

import { LeftColumn, ListItem, MasonryImageListSection, MasonryImageListWrapper, RightColumn } from './Wrapper';

export type MasonryImageListItem = {
  src: string;
  alt?: string;
  imagePercentsMaxWidth?: number;
};

export type ListProps = {
  leftColumnList: MasonryImageListItem[];
  rightColumnList: MasonryImageListItem[];
};

export type MasonryImageListProps = ListProps & {
  containerSize?: number;
  leftColumnPercentsWidth?: number;
  rightColumnPercentsWidth?: number;
  button?: LandingButtonProps;
  customButton?: PartialButtonProps;
  customAuthButtonGuestText?: string;
};

const MasonryImageList: FC<MasonryImageListProps> = ({
  leftColumnList,
  rightColumnList,
  leftColumnPercentsWidth,
  rightColumnPercentsWidth,
  containerSize,
  customAuthButtonGuestText,
  button
}) => (
  <MasonryImageListSection>
    <Container size={containerSize || 1150}>
      <MasonryImageListWrapper>
        <LeftColumn {...{ leftColumnPercentsWidth }}>
          {leftColumnList.map(({ src, alt, imagePercentsMaxWidth }) => (
            <ListItem key={`${src}`} {...{ imagePercentsMaxWidth }}>
              {<LazyImage src={src} alt={alt || 'Wave.video'} />}
            </ListItem>
          ))}
        </LeftColumn>
        <RightColumn {...{ rightColumnPercentsWidth }}>
          {rightColumnList.map(({ src, alt, imagePercentsMaxWidth }) => (
            <ListItem key={`${src}`} {...{ imagePercentsMaxWidth }}>
              {<LazyImage src={src} alt={alt || 'Wave.video'} />}
            </ListItem>
          ))}
        </RightColumn>
      </MasonryImageListWrapper>

      <LandingButton size="upper-big" variant="orange" {...{ ...button, customAuthButtonGuestText }}>
        {buttonAuth => <ButtonWrapper>{buttonAuth}</ButtonWrapper>}
      </LandingButton>
    </Container>
  </MasonryImageListSection>
);

export default MasonryImageList;
