import getFromCdn from '@anm/helpers/getFromCdn';
import isUrlAbsolute from '@anm/helpers/is/isUrlAbsolute';

// TODO: add tests for helpers below

export const getFallback = (src: string) =>
  src.replace(/_([^_]*).webp$/, `.$1`);

export const getSrc = (url: string) =>
  isUrlAbsolute(url) ? url : getFromCdn(url);

export const getFallBackUrl = (src: string) => {
  const webpFallback = getFallback(src);

  return getSrc(webpFallback);
};

export const isWebp = (src: string) => new RegExp('.webp').test(src);

export const getSafeImageUrl = (src: string) =>
  isWebp(src) ? getFallback(src) : getSrc(src);

export const fetchImage = (src: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.src = src;
    image.onload = resolve;
    image.onerror = reject;
  });
