export type WaveTypes = 'bottom' | 'top' | 'both' | 'no-wave';

export type WaveProps = {
  waves?: WaveTypes;
};

const getWaves = (wave?: WaveTypes) => {
  return wave === 'both'
    ? 'with-both-waves'
    : wave === 'top'
    ? 'with-top-wave'
    : wave === 'bottom'
    ? 'with-bottom-wave'
    : wave === 'no-wave'
    ? 'no-waves'
    : null;
};

export default getWaves;
