import Card from '@anm/components/Card';
import { Wrapper as ProgressiveVideoPreviewWrapper } from '@anm/components/video/ProgressiveVideoPreview';
import getFromCdn from '@anm/helpers/getFromCdn';
import { getFadeInAnimation, media, scaleIn, stretchBlock } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

import { PreviewTitleCardTitleProps, PreviewTitleCardTitleWeights } from './types';

export const CardWrapper = styled.div`
  cursor: pointer;
`;

export const TitleCardDesktop = media.desktop_small`
    padding: 6px 9px 10px;
`;

export const TitleCardMobile = media.phone`
    padding: 14px 20px;
`;

const weights: {
  [key in PreviewTitleCardTitleWeights]: ReturnType<typeof css>;
} = {
  bold: css`
    padding: 10px 20px;
    font-weight: bold;
    line-height: 1.56;
  `,
  normal: css`
    font-weight: 400;
    line-height: 25px;
    padding: 14px 20px;
  `
};

export const TitleCard = styled.h5<PreviewTitleCardTitleProps>`
  margin: 0;
  hyphens: auto;
  color: #292a2b;
  font-size: 16px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  ${TitleCardDesktop};
  ${TitleCardMobile};

  ${({ weight }) => weight && [...weights[weight]]};
`;

export const PreviewCard = styled.div`
  position: relative;
  > img {
    width: 100%;
    border-radius: 5px 5px 0 0;
    ${getFadeInAnimation()};
  }
  ${ProgressiveVideoPreviewWrapper} {
    border-radius: 5px 5px 0 0;
  }
`;

const PreviewTitleCardWrapper = styled(Card)`
  position: relative;
  &:hover {
    &:after {
      ${stretchBlock};
      content: '';
      z-index: 150;
      display: block;
      border-radius: 5px;
      pointer-events: none;
      background-color: rgba(34, 148, 223, 0.1);
    }
  }
  a {
    font-size: 0;
    text-decoration: none;
  }
`;

export const PreviewCardPlayButton = styled.div`
  ${stretchBlock};
  z-index: 150;
  pointer-events: none;
  transition: transform 0.5s;
  img {
    opacity: 0;
  }
  &:after {
    ${scaleIn};
    ${stretchBlock};
    content: '';
    right: 0;
    bottom: 0;
    z-index: 16;
    width: 40px;
    height: 40px;
    margin: auto;
    pointer-events: none;
    transition: transform 0.5s;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url(${getFromCdn('images/play-icon.svg')});
  }
`;

export default PreviewTitleCardWrapper;
