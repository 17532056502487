import Checkbox from '@anm/components/form/Checkbox';
import styled, { css } from 'styled-components';

import { SelectProps, SelectSizes } from '../Select';

const sizes: { [key in SelectSizes]: ReturnType<typeof css> } = {
  small: css`
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    min-width: 100%;
  `,
  middle: css``,
  narrow: css``
};

export const CheckboxWrapper = styled(Checkbox)`
  line-height: 20px;
  margin-right: 10px;
`;

export const SelectWrapper = styled.div<Partial<SelectProps>>`
  ${({ size }) => sizes[size || 'middle']};
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: default;
      opacity: 0.4;
    `};

  position: relative;
`;

export default SelectWrapper;
