import { StockItem as StockProps } from '@anm/api/modules/stocks';
import { DEFAULT_ITEM_HEIGHT, DEFAULT_ITEM_MARGIN } from '@anm/components/stocks/BrowseMoreButton';
import ProgressiveVideoPreview from '@anm/components/video/ProgressiveVideoPreview';
import formatTime from '@anm/helpers/time/formatTime';
import { FC, RefObject } from 'react';

import { StockPrice } from '../StocksModal';

import Wrapper, { HoverLayout, ItemCard, VideoDuration } from './Wrapper';

type ColumnProps = {
  index: number;
  columnCount: number;
  columnWidth: number;
};

type StockItemProps = StockProps & {
  isVideo: boolean;
  isHovered: boolean;
  priceValue: string;
  columnProps: ColumnProps;
  hoverRef: RefObject<HTMLDivElement>;
  itemMargin?: number;
  itemHeight?: number;
  onCardClick(): void;
};

const StockItem: FC<StockItemProps> = ({
  title,
  isVideo,
  duration,
  hoverRef,
  isHovered,
  priceValue,
  preview_url,
  columnProps,
  thumbnail_url,
  itemMargin = DEFAULT_ITEM_MARGIN,
  itemHeight = DEFAULT_ITEM_HEIGHT,
  onCardClick
}) => (
  <Wrapper ref={hoverRef} {...{ ...columnProps, itemMargin, itemHeight }}>
    <ItemCard onClick={onCardClick} {...{ ...columnProps, itemMargin }}>
      {duration && <VideoDuration>{formatTime(duration)}</VideoDuration>}
      {isHovered && (
        <HoverLayout>
          <StockPrice>{priceValue}</StockPrice>
        </HoverLayout>
      )}
      <ProgressiveVideoPreview
        alt={title}
        height={157}
        loader={({ src }) => src}
        videoSrc={preview_url}
        previewSrc={isVideo || !preview_url ? thumbnail_url : preview_url}
      />
    </ItemCard>
  </Wrapper>
);

export default StockItem;
