import { usersCount } from '@anm/helpers/getDaysAfterHostingRealise';
import numberWithCommas from '@anm/helpers/numberWithCommas';
import { FC } from 'react';

import { CommonLandingProps } from '../../../features/getLanding';
import LandingButton, { LandingButtonProps } from '../../LandingButton';
import { SectionTitleColor, SectionTitleProps } from '../../SectionTitle';

import Wrapper, { BannerTitle, ContainerWrapper, Content } from './Wrapper';

export type TitleVariant = 'medium' | 'big';

export type BannerProps = CommonLandingProps &
  Omit<SectionTitleProps, 'color'> & {
    color?: SectionTitleColor;
    isFullWidth?: boolean;
    isLastSection?: boolean;
    button?: LandingButtonProps;
    customBackground?: string;
    titleVariant?: TitleVariant;
  };

const defaultTitle = `Join ${numberWithCommas(usersCount)} fellow marketers`;

const Banner: FC<BannerProps> = ({
  title,
  button,
  isFullWidth,
  titleVariant = 'big',
  customBackground,
  isLastSection = false,
  headingLevel,
  ...titleProps
}) => (
  <Wrapper {...{ isLastSection, isFullWidth }}>
    <ContainerWrapper size={1150} {...{ isFullWidth }}>
      <Content {...{ customBackground }}>
        <BannerTitle
          color="black"
          level={headingLevel}
          title={title || defaultTitle}
          {...{ ...titleProps, titleVariant }}
        />
        <LandingButton size="upper-big" variant="light-blue-lowercase" {...button} />
      </Content>
    </ContainerWrapper>
  </Wrapper>
);

export default Banner;
