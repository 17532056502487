import Dropdown from '@anm/components/Dropdown';
import Image from '@anm/components/image/Image';
import { FC } from 'react';

import { HintContentWrapper, HintDescription, HintOpenerWrapper, HintTitle, Wrapper } from './Wrapper';

const CONTENT = [
  {
    title: 'Insert line breaks to divide the text',
    imgSrc: 'images/storyboard/lines-storyboard.gif',
    description: 'Lines of text will appear one by one on the same background.'
  },
  {
    title: 'Use an empty line for next visual',
    imgSrc: 'images/storyboard/paragraphs-storyboard.gif',
    description: 'Each paragraph will become a video scene.'
  }
];

const HintContent: FC = () => (
  <HintContentWrapper>
    {CONTENT.map(({ title, description, imgSrc }) => (
      <div key={title}>
        <HintTitle>{title}</HintTitle>
        <HintDescription>{description}</HintDescription>
        <Image src={imgSrc} alt={title} />
      </div>
    ))}
  </HintContentWrapper>
);

const TextFormatingHint: FC = () => (
  <Wrapper>
    <Dropdown openByAction="click" openerVariant="default" direction="to-left" renderContent={() => <HintContent />}>
      <HintOpenerWrapper>
        <Image src="images/storyboard/lightning.png" alt="hint"></Image>
        Text formatting hints
      </HintOpenerWrapper>
    </Dropdown>
  </Wrapper>
);

export default TextFormatingHint;
