import React, { useEffect, useState, FC } from 'react';
import ReactDOM from 'react-dom';

import { MODAL_NOTIFICATION_CONTAINER_ID } from './ModalNotificationRoot';

type ModalPortalProps = {};

type Elements = {
  modalRoot: HTMLElement;
  modalContainer: HTMLDivElement;
};

const ModalPortal: FC<ModalPortalProps> = ({ children }) => {
  const [elements, setElements] = useState<Elements | null>(null);

  useEffect(() => {
    setElements({
      modalContainer: document.createElement('div'),
      modalRoot: document.getElementById(MODAL_NOTIFICATION_CONTAINER_ID)!
    });
  }, []);

  useEffect(() => {
    if (!elements) return;

    const { modalRoot, modalContainer } = elements;
    modalRoot && modalRoot.appendChild(modalContainer);

    return () => {
      elements!.modalRoot.removeChild(elements!.modalContainer);
    };
  }, [elements]);

  return <>{elements && elements.modalContainer && ReactDOM.createPortal(children, elements.modalContainer)}</>;
};

export default ModalPortal;
