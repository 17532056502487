import api from '@anm/api';
import useRequestState from '@anm/hooks/useRequestState';
import { useRef } from 'react';

const useUploadCheck = () => {
  const checkUploadStatusRequest = async (jobId: string) => {
    return jobId ? api().youtube.checkUploadStatus(jobId) : Promise.reject(`Job ID is empty`);
  };
  const [uploadStatusState, { request: checkUploadStatus }] = useRequestState(checkUploadStatusRequest);

  const checkTimeoutId = useRef<number>();
  const waitUploadFinish = (jobId: string) =>
    new Promise(resolve => {
      clearTimeout(checkTimeoutId.current);

      (async function run() {
        if (uploadStatusState.data === 'finished') {
          return resolve('ok');
        }

        await checkUploadStatus(jobId);
        checkTimeoutId.current = window.setTimeout(run, 1000);
      })();
    });

  return waitUploadFinish;
};

export default useUploadCheck;
