import { GetMediaInfoResponse, MediaInfoFormat } from '@anm/api/modules/youtube';

const createDefaultFormatNote = (formats: MediaInfoFormat[]) =>
  formats
    .filter(({ format_note, width, height }) => format_note || (width && height))
    .map(({ format_note, width, height, ...otherProps }) => ({
      ...otherProps,
      width,
      height,
      format_note: format_note || `${Math.min(width, height)}p`
    }));

const mapDefaultFormatNote = ({ formats, formatsVideoOnly, ...otherProps }: GetMediaInfoResponse) => ({
  ...otherProps,
  formats: createDefaultFormatNote(formats),
  formatsVideoOnly: createDefaultFormatNote(formatsVideoOnly)
});

export default mapDefaultFormatNote;
