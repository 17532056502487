import { Manifest } from '@anm/hosting-player';

export const getPlayerThumbnail = ({ meta, main }: Manifest) => ({
  name: meta.name,
  thumbnail: main.find(e => e.type === 'image')?.path!
});

const prodDomain = 'wave.video';
const testDomain = 'wave-test.video';

export const fetchWaveData = async (videoId: string, isProd: boolean) => {
  const domain = isProd ? prodDomain : testDomain;
  const fetchUrl = `https://embed.${domain}/${videoId}/manifest.json`;

  const res = await fetch(fetchUrl);
  return await res?.json();
};

export const getVideoData = async (videoIds: string[], isProd = true) => {
  const uniqIds = [...new Set(videoIds)];
  return Promise.all(uniqIds.map(videoId => fetchWaveData(videoId, isProd)));
};

type ManifestWithError = Manifest & {
  code?: number;
};

export const getFilterVideoData = async (videoIds: string[], isProd = true) => {
  const videoManifests = (await getVideoData(videoIds, isProd)) as ManifestWithError[];
  const filteredManifest = videoManifests.filter(m => !m.code);

  return filteredManifest as Manifest[];
};
