import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  color: gray;
  margin-top: 20px;
`;

export const YoutubeHint = () =>
  <Wrapper>
    <div>next time add <b>vvv</b> before <b>.com</b> in youtube url:&nbsp;
      <span style={{color: "black"}}>https://youtube<span style={{color: "red", fontWeight: "bold"}}>vvv</span>.com/watch/XXXXXXX</span>
    </div>
  </Wrapper>
