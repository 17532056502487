import * as semrush from '@anm/helpers/semrush'
import useTextAreaOrInputState from '@anm/hooks/useTextAreaOrInputState';
import { useCallback, useState } from 'react';

import { MOODS } from '../components/MoodPicker';
import { useTextOnScene } from '../hooks';
import { GenerateVideoModalProps } from '../types';

const initStockAssetValue = semrush.isSemrushIframe() ? 1 : 0;

export const useCreateVideoFromUrlHandlers = (onCreateVideo: GenerateVideoModalProps['onCreateVideo']) => {
  const [time, setTime] = useState(0); // in seconds
  const [mood, setMood] = useState(MOODS[0]);
  const [text, handleTextChange] = useTextAreaOrInputState('');
  const [activeStockAssetsButton, setActiveStockAssetsButton] = useState(initStockAssetValue);
  const [slideLength, activeTextSceneButton, handleTextSceneChange] = useTextOnScene();

  const isPremiumStocks = !activeStockAssetsButton;

  const handleCreateVideo = useCallback(() => {
    onCreateVideo({
      mood,
      source: text,
      max_len: time,
      max_slide_len: slideLength,
      use_premium_stocks: isPremiumStocks
    });
  }, [mood, text, time, slideLength, isPremiumStocks]);

  return [
    text,
    activeTextSceneButton,
    activeStockAssetsButton,
    setTime,
    setMood,
    handleTextChange,
    handleCreateVideo,
    handleTextSceneChange,
    setActiveStockAssetsButton
  ] as const;
};
