import { useCallback, useEffect, useState } from 'react';

type UseAlertSoundProps = { soundUrl: string; speakerId?: string };
const beep = ({ soundUrl, speakerId }: UseAlertSoundProps) => {
  const audio = document.createElement('audio');

  audio.src = soundUrl;
  (audio as any).setSinkId?.(speakerId);

  const destroy = () => audio.pause();

  return {
    audio,
    destroy
  };
};

const useAlertSound = ({ soundUrl, speakerId }: UseAlertSoundProps) => {
  const [audio, setAudio] = useState<HTMLAudioElement>();

  const handleClick = useCallback(() => {
    if (!audio) return;
    audio.paused || audio.currentTime === 0 ? audio.play() : audio.pause();
  }, [audio]);

  useEffect(() => {
    if (!speakerId) return;

    const { audio, destroy } = beep({ soundUrl, speakerId });
    audio && setAudio(audio);

    return () => {
      destroy();
    };
  }, [speakerId]);

  return { alert: handleClick };
};

export default useAlertSound;
