import Image from '@anm/components/image/Image';
import { FC } from 'react';

import IconHolder from './IconHolder';
import { IconProps, ListItemProps, VerticalListItem } from './types';

const Icon: FC<IconProps> = ({ iconUrl, title, ...customIcon }) => (
  <IconHolder {...customIcon}>
    <Image src={iconUrl} alt={title} />
  </IconHolder>
);

const Content: FC<VerticalListItem> = ({ title, description }) => (
  <div>
    <p>{title}</p>
    <span dangerouslySetInnerHTML={{ __html: description }} />
  </div>
);

const ListItem: FC<ListItemProps> = ({
  title,
  iconUrl,
  customIcon,
  description
}) => (
  <li key={title}>
    {iconUrl && <Icon {...{ ...customIcon, title, iconUrl }} />}
    <Content {...{ title, description }} />
  </li>
);

export default ListItem;
