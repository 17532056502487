import Background, { BackgroundProps } from '@anm/components/Background';
import { FC } from 'react';

import Faq, { FaqItem } from '../../Faq';

import FaqSectionWrapper, {
  FaqSectionContainer,
  FaqTitleBlock
} from './Wrapper';

export type FaqSectionProps = BackgroundProps & {
  list: FaqItem[];
  title: string;
  headingLevel?: number;
};

const FaqSection: FC<FaqSectionProps> = ({
  list,
  title,
  headingLevel,
  customBackground
}) => (
  <Background variant="white" {...{ customBackground }}>
    <FaqSectionContainer as="section">
      <FaqSectionWrapper>
        <FaqTitleBlock level={headingLevel || 2} {...{ title }} />
        <Faq {...{ list }} />
      </FaqSectionWrapper>
    </FaqSectionContainer>
  </Background>
);

export default FaqSection;
