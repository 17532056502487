type Media = {
  path: string;
  width: number;
  height: number;
  isOriginal?: boolean | null;
};

export default (sources: Media[]) =>
  sources.reduce((acc, item) => {
    const duplicatedSource = acc.find(
      s => s.height === item.height && s.width === item.width
    );

    const isDuplicatedSourceOriginal = duplicatedSource?.isOriginal;

    return isDuplicatedSourceOriginal
      ? [...acc.filter(s => !s.isOriginal), item]
      : duplicatedSource
      ? acc
      : [...acc, item];
  }, [] as Media[]);
