import Container from '@anm/components/Container';
import { useEffect, useState, FC } from 'react';

import { VideoGalleryProps } from '..';
import Background from '../../../Background';
import LandingButton from '../../../LandingButton';
import WavePlayerModal from '../../../WavePlayerModal';
import { VideoProps } from '../types';

import Wrapper, { ButtonsWrapper, Column, GridWrapper, ImageContainer, Title } from './Wrapper';
import { generateColumns } from './helpers';

const ImageGallery: FC<VideoGalleryProps> = ({
  title,
  description,
  button,
  headingLevel,
  customBackground,
  ...gridProps
}) => {
  const [columns, setColumns] = useState<VideoProps[][]>([[]]);

  useEffect(() => {
    const newColumns = generateColumns(gridProps);
    setColumns(newColumns!);
  }, []);

  return (
    <Wrapper>
      <Background variant={customBackground ? 'white' : 'no-waves'} {...{ customBackground }}>
        <Container size={1150}>
          <Title color="black" level={headingLevel} {...{ title, description }} />
          <GridWrapper>
            {columns.map((column, index) => (
              <Column key={index}>
                {column.map(item => (
                  <ImageContainer key={item.videoId}>
                    <WavePlayerModal playIconVariant="white" playIconBehavior="hover-opacity" {...item} />
                  </ImageContainer>
                ))}
              </Column>
            ))}
          </GridWrapper>
          <ButtonsWrapper>
            <LandingButton size="upper-big" variant="light-blue-lowercase" {...button} />
          </ButtonsWrapper>
        </Container>
      </Background>
    </Wrapper>
  );
};

export default ImageGallery;
