import { flexCenter, media } from '@anm/styles/helpers';
import styled from 'styled-components';

import SectionTitle from '../../../SectionTitle';
import { CardProps } from '../types';

const descriptionOnMobile = media.phone`
  font-size: 14px;
  line-height: 22px;
  min-height: auto;
`;

export const Title = styled(SectionTitle)`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
  }
  p {
    margin: 0;
    font-size: 16px;
    text-align: left;
    line-height: 25px;
    min-height: 155px;
    font-weight: normal;
    ${descriptionOnMobile};
  }
`;

const CardWrapperMobile = media.phone`
  margin: 0 auto 30px;
`;

const CardWrapperTablet = media.tablet``;

export const Wrapper = styled.a<CardProps>`
  ${flexCenter};
  margin: 10px;
  min-height: 50px;
  padding: ${({ hasIcon }) => (hasIcon ? '8px 20px 8px 12px' : '0 30px')};
  border-radius: ${({ borderRadius }) => borderRadius || 15}px;
  background-color: ${({ background }) => background || '#E9D0FF'};
  ${CardWrapperTablet};
  ${CardWrapperMobile};
  &:hover {
    background-color: #fff;
  }
`;

export const IconContainer = styled.div`
  ${flexCenter};
  border-radius: 50%;
  width: 44px;
  height: 44px;
  background-color: #fff;
  margin-right: 12px;
  img {
    width: 22px;
    height: 22px;
  }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export default ListWrapper;
