import IconPlay from '@anm/components/IconPlay';
import { getPlayerThumbnail } from '@anm/helpers/waveVideo';
import useSwitchState from '@anm/hooks/useSwitchState';
import { Manifest } from '@anm/hosting-player';
import { useContext, FC } from 'react';

import { VideoDataContext } from '../../../../../store/VideoDataContext';
import PlayerModal from '../../../../modals/PlayerModal';
import { MediaBlockProps, MediaPlayerProps } from '../../types';

const Player: FC<MediaPlayerProps> = ({ videoId, customButton }) => {
  const [isModalOpen, openModal, closeModal] = useSwitchState();
  const videoData = useContext(VideoDataContext);
  const manifest = videoData[videoId] as Manifest;

  if (!manifest) return null;

  const { thumbnail, name } = getPlayerThumbnail(manifest);

  return (
    <li>
      <IconPlay size="80x80" onClick={openModal} />
      <img src={thumbnail} alt={name} />
      {isModalOpen && (
        <PlayerModal
          {...{ videoId, customButton, manifest }}
          onModalClose={closeModal}
        />
      )}
    </li>
  );
};

const PlayerBlock: FC<MediaBlockProps> = ({ videos }) => (
  <>
    {videos?.map(({ videoId, customButton }) => (
      <Player key={videoId} {...{ videoId, customButton }} />
    ))}
  </>
);

export default PlayerBlock;
