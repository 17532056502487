import { FC } from 'react';

import ListItem from './ListItem';
import { ListWrapper } from './Wrapper';
import { ListProps } from './types';

const List: FC<ListProps> = ({ list }) => (
  <ListWrapper>
    {list.map(item => (
      <ListItem key={item.title} {...{ ...item }} />
    ))}
  </ListWrapper>
);
export default List;
