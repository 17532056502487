import ButtonLoader from '@anm/components/buttons/ButtonLoader';
import { DropdownContent } from '@anm/components/Dropdown';
import styled from 'styled-components';

export const ButtonWrapper = styled(ButtonLoader)``;

export const Wrapper = styled.div`
  position: relative;

  &:hover {
    ${ButtonWrapper} {
      background: yellow;
    }
  }

  ${DropdownContent} {
    width: 230px;
    max-width: 230px;
    padding: 14px 0;
    box-shadow: 0px 5px 20px rgba(1, 83, 130, 0.2);
    border-radius: 10px;

    a {
      height: 55px;
      padding: 0 30px 0 76px;
      font-weight: 400;
      font-size: 16px;
      line-height: 55px;

      &:hover {
        color: #30aef2;
      }
    }
  }
`;
