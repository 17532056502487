import { FC } from 'react';
import { DropdownComponentProps } from './types';
import { ButtonWrapper, Wrapper } from './Wrapper';
import ActionsDropDown from '@anm/components/ActionsDropdown';
import { DEFAULT_DROPDOWN_DELAY } from '@anm/constants/delays';
import useDropdownButton from './hooks/useDropdownButton';

const DropdownButton: FC<DropdownComponentProps> = ({ text = 'Create video', ...otherProps }) => {
  const [actions, isPending, renderModals] = useDropdownButton(otherProps);

  return (
    <Wrapper>
      {renderModals()}
      <ButtonWrapper isPending={isPending} {...otherProps}>
        {text}
      </ButtonWrapper>
      <ActionsDropDown
        openDelay={DEFAULT_DROPDOWN_DELAY}
        closeDelay={DEFAULT_DROPDOWN_DELAY}
        openerVariant="no-icon"
        direction="to-bottom-from-left-corner"
        actions={actions}
      />
    </Wrapper>
  );
};

export default DropdownButton;
