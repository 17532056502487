import { MobileUserMenuLinkList } from '@anm/components/header';
import { FC } from 'react';

import UserBlock from '../../UserBlock';
import UserMenuLinkList from '../../UserMenuLinkList';

const UserLinksList: FC = () => (
  <MobileUserMenuLinkList>
    <UserMenuLinkList>
      <UserBlock />
    </UserMenuLinkList>
  </MobileUserMenuLinkList>
);

export default UserLinksList;
