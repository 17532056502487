import Container from '@anm/components/Container';
import TitleBlock from '@anm/components/TitleBlock';
import { TitleDescription } from '@anm/components/TitleBlock/Wrapper';
import styled from 'styled-components';

export const FaqSectionContainer = styled(Container)`
  background-color: inherit;
`;

export const FaqTitleBlock = styled(TitleBlock)`
  text-align: center;
  margin-bottom: 33px;

  ${TitleDescription} {
    display: none;
  }
`;

const FaqSectionWrapper = styled.div`
  padding: 78px 0 81px;
  max-width: 620px;
  margin: 0 auto;
  a {
    color: #292a2b;
    text-decoration: underline;
    &:hover {
      color: #2294df;
    }
  }
`;

export default FaqSectionWrapper;
