import api from '@anm/api';
import { AllJobIdParams, JobIdFromSourceParams, JobIdFromTextParams } from '@anm/api/modules/storyboard';
import { Action } from '@anm/components/ActionsDropdown';
import FormatAndStyleModal from '@anm/components/my-projects/FormatAndStyleModal';
import VideoFromTextModal from '@anm/components/my-projects/VideoModals/VideoFromTextModal';
import VideoFromUrlModal from '@anm/components/my-projects/VideoModals/VideoFromUrlModal';
import { UserContext } from '@anm/components/user/UserProvider';
import createArray from '@anm/helpers/array/createArray';
import { getDropdownAction } from '@anm/helpers/dropdownActions';
import useFetchTemplatesFormats from '@anm/hooks/useFetchTemplatesFormats';
import useSwitchState from '@anm/hooks/useSwitchState';
import { useContext, useEffect, useState } from 'react';
import config from '../../../../../config';
import { DropdownComponentProps, CreateVideoFromSource, ActionsMap } from '../types';
import useSafeAction from '@anm/auth/hooks/useSafeAction';
import useVideoFromAILimit from '@anm/components/modals/VideoFromAILimitModal/hooks/useVideoFromAILimit';

export const ROOT_FOLDER_ID = 1;

export const useCreateVideoFromSource = () => {
  const [jobId, setJobId] = useState<string | null>(null);
  const [isPendingJobId, setIsPendingJobId] = useState(false);
  const [params, setParams] = useState<AllJobIdParams>();

  const handleCreateVideoFromSource = async ({ source, params }: CreateVideoFromSource) => {
    setIsPendingJobId(true);
    setParams(params);

    const { job_id } =
      source === 'text'
        ? await api().storyboard.getJobIdFromText(params as JobIdFromTextParams)
        : await api().storyboard.getJobIdFromUrl(params as JobIdFromSourceParams);

    setJobId(job_id);
    setIsPendingJobId(false);
  };

  const clearJobId = () => setJobId(null);

  return [jobId, handleCreateVideoFromSource, params, isPendingJobId, clearJobId] as const;
};

const useDropdownButton = ({ actions: ddActions }: DropdownComponentProps) => {
  const { anon } = useContext(UserContext);

  const [isFormatStyleModal, openFormatStyleModal, closeFormatStyleModal] = useSwitchState();
  const [isVideoFromTextModalOpened, openVideoFromTextModal, closeVideoFromTextModal] = useSwitchState();
  const [isVideoFromBlogModalOpened, openVideoFromBlogModal, closeVideoFromBlogModal] = useSwitchState();

  const timeList = createArray(3, 0).map(t => (t === 0 ? `30 sec` : `${t} min`));

  const templates = useFetchTemplatesFormats({
    apiUrl: config.apiUrl,
    isOpenModal: isVideoFromTextModalOpened || isVideoFromBlogModalOpened
  });

  const [jobId, handleCreateVideoFromSource, jobParams, isPendingJobId, clearJobId] = useCreateVideoFromSource();

  const getSafeAction = useSafeAction();
  const [handleCreateVideo, limitGuard, renderModal] = useVideoFromAILimit({
    jobParams,
    limit: config.guestLimits.maxVideoFromAI
  });

  useEffect(() => {
    if (!jobId) return;

    closeVideoFromTextModal();
    closeVideoFromBlogModal();
    openFormatStyleModal();
  }, [jobId]);

  const actionsMap: ActionsMap = {
    videoFromBlog: {
      key: 'onVideoFromBlog',
      handler: limitGuard(getSafeAction(openVideoFromBlogModal))
    },
    videoFromText: {
      key: 'onVideoFromText',
      handler: limitGuard(getSafeAction(openVideoFromTextModal))
    }
  };

  const actions: Action[] = !ddActions?.length
    ? []
    : ddActions.map(action => {
        const mappedAction = actionsMap[action];

        return {
          ...getDropdownAction(mappedAction.key),
          handler: mappedAction.handler
        };
      });

  const renderModals = () => (
    <>
      {renderModal()}
      {isVideoFromBlogModalOpened && (
        <VideoFromUrlModal
          {...{ timeList }}
          isPending={isPendingJobId}
          clearJobId={clearJobId}
          onCreateVideo={params => handleCreateVideoFromSource({ source: 'url', params })}
          onModalClose={closeVideoFromBlogModal}
        />
      )}
      {isVideoFromTextModalOpened && (
        <VideoFromTextModal
          {...{ timeList }}
          isPending={isPendingJobId}
          clearJobId={clearJobId}
          onCreateVideo={params => handleCreateVideoFromSource({ source: 'text', params })}
          onModalClose={closeVideoFromTextModal}
        />
      )}
      {isFormatStyleModal && jobId && (
        <FormatAndStyleModal
          {...{ templates, jobId }}
          editorUrl={config.editorUrl}
          folderId={ROOT_FOLDER_ID}
          onCreateVideo={handleCreateVideo}
          onModalClose={closeFormatStyleModal}
        />
      )}
    </>
  );

  return [actions, anon.isPending, renderModals] as const;
};

export default useDropdownButton;
