import { SelectOption } from '@anm/components/select/Select';
import { compose, filter, reverse, sortBy, uniqBy } from 'lodash/fp';

import { Upgrade } from '../../Dropdown';
import { SelectedMediaFormat } from '../hooks/useMediaInfo';

import createNumberFromResolution from './createNumberFromResolution';

type OptionValue = { format_id: string; shouldUpgrade?: boolean; isVideoOnlyFormat?: boolean };

export const mapFormatsToOptions = <T extends SelectedMediaFormat>(formats: T[]): SelectOption<OptionValue>[] =>
  compose(
    (formats: T[]) =>
      formats.map(({ format_note, format_id, shouldUpgrade, isVideoOnlyFormat }: T) => ({
        label: format_note,
        value: { format_id, shouldUpgrade, isVideoOnlyFormat },
        ...(shouldUpgrade && { renderIcon: () => <Upgrade>Upgrade</Upgrade> })
      })),
    reverse,
    sortBy(({ format_note }: T) => createNumberFromResolution(format_note)),
    uniqBy(({ format_note }: T) => format_note),
    filter(({ format_note }: T) => !!format_note)
  )(formats);

export default mapFormatsToOptions;
