import * as analytic from '@anm/analytic';
import api from '@anm/api';
import { MediaInfoFormat } from '@anm/api/modules/youtube';
import useAnonAuth from '@anm/auth/hooks/useAnonAuth';
import useRequestState from '@anm/hooks/useRequestState';
import { useCallback, useEffect } from 'react';
import { MediaType } from 'types/plyr';

import useMediaStorageCheck from './useMediaStorageCheck';
import useUploadCheck from './useUploadCheck';

type UseUploadProps = {
  mediaType: MediaType;
  url?: string;
  jobId?: string;
  mediaFormats?: MediaInfoFormat[];
  duration?: number;
  uploadedSize?: number;
};

const useNewUploadRequest = (url?: string) => {
  const request = async () => {
    if (!url) return Promise.reject('url is empty');

    const { uploadId } = await api().youtube.createVideoUploadByJobId(url);

    return uploadId;
  };

  return request;
};

const useOldUploadRequest = (mediaType: MediaType, url?: string) => {
  const waitUploadFinish = useUploadCheck();

  const request = async () => {
    const encodedUrl = url && window.encodeURIComponent(url);
    const jobIdLocal = encodedUrl && (await api().youtube.getDownloadJobId({ type: mediaType, url: encodedUrl }));

    if (!jobIdLocal) return Promise.reject(`Job ID is empty`);

    api().youtube.createUploadByJobId(jobIdLocal);
    await waitUploadFinish(jobIdLocal);

    const { id } = await api().youtube.createUploadByJobId(jobIdLocal);
    return id;
  };

  return request;
};

const useUpload = ({ mediaFormats, url, mediaType, duration }: UseUploadProps) => {
  const checkStorage = useMediaStorageCheck(mediaFormats);
  const newUploadRequest = useNewUploadRequest(url);
  const oldUploadRequest = useOldUploadRequest(mediaType, url);
  const authAnonUser = useAnonAuth();

  const uploadRequest = useCallback(
    async (intent: string) => {
      if (!url) return Promise.reject('url is empty');
      await authAnonUser();
      const analyticData = { duration, intent, kind: mediaType, url };
      analytic.trackYoutubeEditStarted(analyticData);
      const error = await checkStorage();
      if (error) return error;

      const uploadId = mediaType === 'audio' ? await oldUploadRequest() : await newUploadRequest();

      analytic.trackYoutubeEdiEnded(analyticData);

      return { id: uploadId };
    },
    [url, mediaType, duration]
  );

  const [uploadState, { request: upload, cancelRequest: cancelUpload, resetState }] = useRequestState(uploadRequest, {
    resetState: true
  });

  useEffect(() => {
    url && mediaType && resetState();
  }, [url, mediaType]);

  return [uploadState, { upload, cancelUpload }] as const;
};

export default useUpload;
