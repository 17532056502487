import Avatar from '@anm/components/Avatar';
import Container from '@anm/components/Container';
import { FC } from 'react';

import { CommonLandingProps } from '../../../features/getLanding';

import Wrapper, { Content, Title } from './Wrapper';

type Author = {
  role: string;
  name: string;
  avatar: string;
};

export type PostAuthorProps = CommonLandingProps & {
  author: Author;
};

const PostAuthor: FC<PostAuthorProps> = ({
  author: { avatar, role, name },
  headingLevel
}) => (
  <Wrapper>
    <Container size={640}>
      <Content>
        <Avatar src={avatar} />
        <Title
          color="black"
          title={name}
          description={role}
          level={headingLevel}
        />
      </Content>
    </Container>
  </Wrapper>
);
export default PostAuthor;
