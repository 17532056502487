import Container from '@anm/components/Container';
import { flexCenter, media } from '@anm/styles/helpers';
import styled from 'styled-components';

import Background from '../../Background';
import { Description } from '../../SectionTitle';
import { ListItem, ListWrapper as HorizontalListWrapper } from '../HorizontalList';

import { getDescriptionVariant, DescriptionVariant } from './Description';

type TitleProps = {
  titleMaxWidth?: number;
  descriptionVariant?: DescriptionVariant;
};

const LeftBlockTablet = media.tablet`
  ${flexCenter};
  max-width: unset;
  margin-bottom: 20px;
  flex-direction: column;
`;

export const LeftBlock = styled.div`
  ${LeftBlockTablet};
  max-width: 440px;
  width: 100%;
`;

export const ListWrapper = styled.div`
  flex: 1 1 0;
  display: flex;
`;

const WrapperTablet = media.tablet`
   flex-direction: column;
  ${ListItem} {
   width: 50%;
  }
  h1,
  h2,
  h3,
  h4,
  h5, ${Description} {
    max-width: unset;
    text-align: center;
  }
  ${HorizontalListWrapper} {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }
`;

const WrapperMobile = media.phone`
  ${ListItem} {
   width: 100%;
  }
`;

export const ContainerWrapper = styled(Container)<TitleProps>`
  display: flex;
  flex-direction: row;
  margin-bottom: 100px;
  ${Background} {
    padding: 0;
  }
  ${ListItem} {
    padding: 0;
    margin-bottom: 37px;
  }
  ul {
    margin: 0;
  }
  ${Description} {
    font-weight: normal;
    max-width: ${({ titleMaxWidth }) => titleMaxWidth || 295}px;
    ${({ descriptionVariant }) => getDescriptionVariant(descriptionVariant || 'big')}
    text-align: left;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    max-width: ${({ titleMaxWidth }) => titleMaxWidth || 200}px;
    text-align: left;
  }
  p a {
    color: var(--blue_100);
  }
  img {
    object-fit: contain;
  }
  ${WrapperTablet};
  ${WrapperMobile};
`;

const Wrapper = styled.section``;

export default Wrapper;
