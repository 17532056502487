import getUniqueSources from '@anm/helpers/getUniqueSources';

import {
  Configs,
  ControlsMap,
  EndBehaviorMap,
  Media,
  MediaObject,
  Playbar,
  PlaybarControls,
  PlayerMappedControls
} from '../../types';
import checkCanDownload from '../checkCanDownload';

const controlsMap: ControlsMap = {
  logo: 'logo',
  speed: 'settings-speed',
  volume: 'volume',
  quality: 'settings-quality',
  download: 'download',
  settings: 'settings',
  fullScreen: 'fullscreen',
  currentTime: 'current-time',
  playbarButton: 'playbar-button',
  bigPlayButton: 'play-large',
  smallPlayButton: 'play'
};

const endBehaviorMap: EndBehaviorMap = {
  loop: {
    resetOnEnd: false,
    loop: { active: true }
  },
  showLastFrame: {
    resetOnEnd: false,
    loop: { active: false }
  },
  showThumbnail: {
    resetOnEnd: false,
    loop: { active: false }
  }
};

const filterControls = (playbar: Playbar): PlayerMappedControls[] =>
  Object.keys(playbar)
    .filter((control: PlaybarControls) => !playbar[control])
    .map((control: PlaybarControls) => controlsMap[control]);

const checkSettingsExists = ({ speed, quality }: Playbar, sources: Media[]) => {
  const uniqueSources = getUniqueSources(sources);

  const needShowQualitySettings = quality && uniqueSources.length > 1;

  return speed || needShowQualitySettings;
};

const getControls = (playbar: Playbar, bigPlayButton: boolean, sources: Media[]) => {
  const isSettingsExists = checkSettingsExists(playbar, sources);

  const canDownload = checkCanDownload(sources as MediaObject[]);

  const mappedPlaybar: Playbar = !canDownload ? { ...playbar, download: false } : playbar;

  const allControls = {
    bigPlayButton,
    settings: isSettingsExists,
    ...mappedPlaybar
  };

  return filterControls(allControls);
};

const mapConfigs = (configs: Configs, sources: Media[]) => {
  const { playbar, bigPlayButton, end, ...other } = configs;
  if (!playbar) return;

  const controls = getControls(playbar, bigPlayButton, sources);

  const afterPlayed = end ? endBehaviorMap[end] : endBehaviorMap['showLastFrame'];

  return {
    controls,
    ...afterPlayed,
    ...other
  };
};

export default mapConfigs;
