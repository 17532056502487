import Card from '@anm/components/Card';
import { getFadeInAnimation, media } from '@anm/styles/helpers';
import styled from 'styled-components';

export const CardModalContentTabletStyles = media.tablet`
  width: 100%;
  height: 100%;
`;

const CardModalContent = styled(Card)`
  opacity: 0;
  filter: none;
  max-width: inherit;
  pointer-events: all;
  ${getFadeInAnimation()};
  ${CardModalContentTabletStyles};
`;

export default CardModalContent;
