import Tooltip from '@anm/components/Tooltip';
import getFromCdn from '@anm/helpers/getFromCdn';
import { flexRow, media } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

import { SwitchVariant } from '.';

type WrapperProps = {
  inActive: boolean;
  disabled: boolean;
  hasTooltip: boolean;
  variant?: SwitchVariant;
};

type SwitchWrapperProps = {
  checked: boolean;
  disabled: boolean;
  isBeta?: boolean;
};

export const TooltipWrapper = styled(Tooltip)`
  min-width: 220px;
  left: 58px;
`;

const switchOnDesktopSmall = media.desktop_small`
  line-height: 22px;
  align-items: flex-start;
`;

const switchOnMobiles = media.phone`
  line-height: 18px;
  font-size: 14px;
  align-items: center;
`;

const labelTextHolderResponsive = media.desktop_small`
  margin-right: 8px;
`;

export const LabelTextHolder = styled.span<SwitchWrapperProps>`
  color: ${({ theme, disabled }) => (disabled ? theme.disabledTextColor : theme.textColor)};
  margin-left: 10px;
  ${labelTextHolderResponsive};

  ${({ isBeta }) =>
    isBeta &&
    css`
      &:after {
        display: block;
        content: '';
        width: 32px;
        height: 16px;
        position: absolute;
        right: -38px;
        bottom: 12px;
        background: url(${getFromCdn('images/icons/ic_beta.svg')}) no-repeat center / cover;
      }
    `}
`;

const formInlineLabel = css`
  label {
    display: flex;
    align-items: flex-start;
    line-height: 25px;
  }
`;
const variants: { [key in SwitchVariant]: ReturnType<typeof css> } = {
  'form-inline': css`
    margin-bottom: 24px;
    ${formInlineLabel}
  `,
  'form-inline-last-item': css`
    ${formInlineLabel}
  `,
  'form-inline-last-item-reverse': css`
    ${formInlineLabel};
    label {
      flex-direction: row-reverse;
      align-items: center;
    }
    ${LabelTextHolder} {
      margin: 0 20px 0 0;
      padding: 0;
    }
  `
};

export const SwitchLabel = styled.label`
  ${flexRow};
  align-items: center;
`;

export const Wrapper = styled.div<WrapperProps>`
  position: relative;

  label {
    user-select: none;
    padding-bottom: ${({ hasTooltip }) => (hasTooltip ? 10 : 0)}px;
  }

  ${({ inActive }) =>
    inActive &&
    css`
      cursor: pointer;
      pointer-events: none;
    `}

  ${({ variant }) => variant && variants[variant]}
`;

export const SwitchWrapper = styled.div<SwitchWrapperProps>`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  ${switchOnDesktopSmall};
  ${switchOnMobiles};

  &:before {
    display: block;
    content: '';
    width: 48px;
    height: 24px;
    border-radius: 12px;
    background: ${({ disabled, theme }) => (disabled ? '#EBEEF5' : theme.disabledSwitchBg)};
    flex-shrink: 0;
    transition: 0.1s;
    align-self: flex-start;
  }

  &:after {
    display: block;
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${({ disabled }) => (disabled ? '#E0E4ED' : '#fff')};
    position: absolute;
    transition: 0.1s;
    top: 2px;
    left: 2px;
    align-self: flex-start;
  }

  ${({ checked, theme, disabled }) =>
    checked
      ? css`
          &:before {
            background: ${disabled ? '' : theme.checkedSwitchBackgroundColor};
          }

          &:after {
            left: 26px;
          }
        `
      : css`
          &:before {
            background: ${theme.uncheckedSwitchBackgroundColor};
          }
        `}
`;
