import Button from '@anm/components/buttons/Button';
import styled from 'styled-components';

export const StyledButtonLink = styled(Button)`
  color: #292a2b;
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  height: unset;
`;
