const fill = (elements: Element[], color: string) =>
  elements.forEach((el: HTMLElement) => (el.style.backgroundColor = color));

type ColorPidsProps = {
  volume: number;
  pidsElements: HTMLCollection;
  isError?: boolean;
};
export const colorPids = ({ volume, isError, pidsElements }: ColorPidsProps) => {
  const allPids = Array.from(pidsElements)!;
  const amountPids = Math.round(volume / 10);
  const elementsRange = allPids.slice(0, amountPids);

  if (isError) {
    fill(allPids, '#EE3D3D');
    return;
  }

  fill(allPids, '#e6e7e8');
  fill(elementsRange, '#69ce2b');
};
