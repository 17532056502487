import getFromCdn from '@anm/helpers/getFromCdn';
import { mobileMenuItem } from '@anm/styles/helpers';
import styled from 'styled-components';
import Button from '@anm/components/buttons/Button/Container';

const MobileNavigationWrapper = styled.div``;

export const NestedLinksWrapper = styled.div`
  background-color: rgba(203, 220, 229, 0.1);
  display: flex;
  flex-direction: column;
  padding: 0 50px 0 61px;
  a {
    ${mobileMenuItem};
    font-weight: normal;
  }
`;

export const MobileUserMenuLinkList = styled.div`
  li {
    margin: 0 20px;
    border-bottom: solid 1px rgba(203, 220, 229, 0.5);
    > a,
    ${Button} a,
    span {
      ${mobileMenuItem};
    }
  }
`;

export const IconClose = styled.div`
  &:before {
    top: 0;
    right: 0;
    content: '';
    width: 64px;
    height: 71px;
    z-index: 150;
    position: absolute;
    background: url(${getFromCdn('images/icons/mobile_navigation/x.svg')})
      no-repeat center center transparent;
  }
`;

export const MainListWrapper = styled.div``;

export const MobileNavigationLogoWrapper = styled.div`
  width: 100%;
  height: 71px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  border-bottom: solid 1px rgba(203, 220, 229, 0.5);
`;

export const MobileMainList = styled.ul`
  padding: 0 20px;
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px rgba(203, 220, 229, 0.5);
    > div {
      height: auto;
      display: block;
      line-height: inherit;
    }
    a,
    button {
      ${mobileMenuItem};
    }
  }
`;

export const IconArrow = styled.div`
  &:before {
    width: 6px;
    content: '';
    height: 10px;
    display: block;
    background: url(${getFromCdn('images/icons/mobile_navigation/arrow.svg')})
      no-repeat center center transparent;
  }
`;

export const MyAccountMenuItem = styled.div`
  ${mobileMenuItem};
  display: flex;
  align-items: center;
  border-bottom: solid 1px rgba(203, 220, 229, 0.5);
  margin: 0 20px;
`;

export const MyAccount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-left: 12px;
`;

export default MobileNavigationWrapper;
