/* tslint:disable:no-else-after-return */
import localStorage from '@anm/helpers/localStorage';
import { analyticsUrl } from '@anm/hosting-player';

import { SendData } from './types';

export { analyticsUrl };

const VIEWER_KEY = 'viewerId';

export const analyticsDataUrl = `${analyticsUrl}player`;
export const analyticsCTAUrl = `${analyticsUrl}cta`;
export const analyticsEventUrl = `${analyticsUrl}event`;

export const isDev = process.env.TS_ENV === 'dev';
export const isProd = process.env.TS_ENV === 'prod';

export const generateObjectId = () => {
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);

  return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => ((Math.random() * 16) | 0).toString(16)).toLowerCase();
};

// export const requestServerTimestamp = () =>
//   axios
//     .get<number>(analyticsUrl + '/timestamp')
//     .then(r => r.data)
//     .catch(() => Date.now());

export const generateViewId = () => generateObjectId();

export const setViewer = () => {
  const viewerId = generateObjectId();
  localStorage().setItem(VIEWER_KEY, viewerId);
  return viewerId;
};

// todo check if localstorage is available, use cookie if not
export const getViewer = () => localStorage().getItem(VIEWER_KEY) || setViewer();

export const sendData = (data: SendData) => sendDataUrl(analyticsDataUrl)(data);

const usePost = true;

// todo add retry
// todo add queue and send several events at once
export const sendDataUrl = (url: string) => (data: Object | Object[]) => {
  const events = Array.isArray(data) ? [...data] : [data];
  const str = JSON.stringify(events);

  if (usePost) {
    // return navigator.sendBeacon(url, str);
    const request = new XMLHttpRequest();
    request.open('POST', url, true);
    request.setRequestHeader('Content-Type', 'text/plain');
    request.onerror = e => console.log('Error sending analytics', e);
    request.send(str);
    return true;
  } else {
    const img = new Image();
    img.src = `${url}?data=${btoa(str)}`;
    img.onerror = (e: Event) => console.log('Error sending analytics', e);
    return true;
  }
};
