import UserProvider, { UserContext } from '@anm/components/user/UserProvider';
import * as sentry from '@anm/helpers/sentry/client';
import useRedirectSemrushUser from '@anm/hooks/useRedirectSemrushUser';
import setupApi from 'landings/helpers/setupApi';
import { NextPageContext } from 'next';
import React, { useContext, FC } from 'react';
import { compose } from 'recompose';

import withAnalyticSupport from '../HOCs/withAnalyticSupport';
import IntroduceVideo from '../components/IntroduceVideo';
import Layout from '../components/Layout';
import MetaHead from '../components/MetaHead';
import LandingFramework from '../components/landing/LandingFramework';
import lfConfig from '../config';
import config from '../config';
import { LandingData } from '../features/getLanding';
import getVideoData, { VideoData } from '../features/getVideoData';
import initAffiliate from '../helpers/initAffiliate';
import useLandingIntercom from '../hooks/useLandingIntercom';
import useRedirect from '../hooks/useRedirect';
import { EnvProvider } from '../store/EnvContext';
import { LandingDataProvider } from '../store/LandingDataContext';
import { VideoDataProvider } from '../store/VideoDataContext';

const { isProd } = config;

type IndexProps = {
  path: string;
  videoData: VideoData;
  currentLanding: LandingData;
};
export type IndexQuery = {
  landing: string;
};

const HooksContainer: FC = () => {
  const { user } = useContext(UserContext);
  sentry.setUser(user);
  useRedirectSemrushUser(user, isProd);

  useRedirect();
  useLandingIntercom();

  return null;
};

setupApi();

const Index = ({ currentLanding, videoData, path }: IndexProps) => {
  const {
    title,
    footer,
    preview,
    noindex,
    isSimple,
    canonical,
    description,
    topBanner,
    facebookPixelIds,
    ads
  } = currentLanding;

  initAffiliate();

  return (
    <UserProvider apiUrl={lfConfig.apiUrl} onLogin={setupApi}>
      <LandingDataProvider value={currentLanding}>
        <IntroduceVideo />
        <HooksContainer />
        <MetaHead
          {...{
            path,
            title,
            noindex,
            preview,
            description,
            canonical,
            facebookPixelIds,
            ads
          }}
        />
        {isSimple ? (
          <LandingFramework landing={currentLanding} />
        ) : (
          <Layout {...{ footer, topBanner }}>
            <VideoDataProvider value={videoData}>
              <EnvProvider value={{ env: 'prod' }}>
                <LandingFramework landing={currentLanding} />
              </EnvProvider>
            </VideoDataProvider>
          </Layout>
        )}
      </LandingDataProvider>
    </UserProvider>
  );
};

Index.getInitialProps = async ({ query, asPath }: NextPageContext) => {
  const { getLandingByUrl } = await import('../features/getLanding');
  const currentLanding = getLandingByUrl((query.landing as string) || '');

  const videoData = currentLanding && (await getVideoData(currentLanding));

  const path = asPath && asPath.replace('/landings', '').replace(/^\//, '');
  const url = `${lfConfig.waveUrl}${path}`;

  return { currentLanding, videoData, path: url };
};

export default compose(withAnalyticSupport)(Index as any);
