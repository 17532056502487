import styled from 'styled-components';

import { media } from '../../../styles/helpers';

const SideMediaContentWrapperOnLaptop = media.laptop`
  display: flex;
  position: relative;
`;

const SideMediaContentWrapper = styled.div`
  ${SideMediaContentWrapperOnLaptop};
`;

const MediaContentTablet = media.tablet`
  width: 100%;
  min-width: unset;
`;

const MediaContentDesktop = media.laptop<{ mediaMaxWidth?: number }>`
  min-width: ${({ mediaMaxWidth }) => `${mediaMaxWidth}px` || '100%'};
`;

export const MediaContent = styled.div<{ mediaMaxWidth?: number }>`
  max-width: 100%;
  ${MediaContentDesktop};
  ${MediaContentTablet};
`;

export default SideMediaContentWrapper;
