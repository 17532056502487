import getFromCdn from '@anm/helpers/getFromCdn';
import styled, { css } from 'styled-components';

import { media } from '../../../styles/helpers';

type AuthorInfoProps = {
  center?: boolean;
};

export const AuthorInfo = styled.div`
  margin-top: 20px;

  div {
    color: #000000;
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
  }

  span {
    color: #a0a0a7;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
  }

  ${({ center }: AuthorInfoProps) =>
    center &&
    css`
      text-align: center;
    `};
`;

const AuthorWrapperOnLaptop = media.laptop`
  margin: 0 20px 0 0;
`;

export const AuthorWrapper = styled.div`
  margin: 0 auto 30px;
  max-width: 240px;
  ${AuthorWrapperOnLaptop};
`;

const TextWrapperOnLaptop = media.laptop`
  font-size: 24px;
  line-height: 40px;
`;

export const TextWrapper = styled.div`
  min-height: 130px;
  position: relative;
  padding: 20px 0 0 47px;
  background: url(${getFromCdn('images/data/quote-bg.svg')}) no-repeat left top;
  color: #000000;
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
  ${TextWrapperOnLaptop};

  p {
    font-weight: 300;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

const ContentWrapperOnTablet = media.tablet`
  padding: 30px 0 184px 0;
`;

export const ContentWrapper = styled.div`
  padding: 0 0 150px 0;
  ${ContentWrapperOnTablet};
`;

const Wrapper = styled.section`
  padding: 150px 0 0 0;
`;

export default Wrapper;
