import TitleBlock, { TitleDescription } from '@anm/components/TitleBlock';
import ButtonLoader from '@anm/components/buttons/ButtonLoader';
import { flexCenter, flexColumn } from '@anm/styles/helpers';
import styled from 'styled-components';

const AlertWrapper = styled.div`
  ${flexCenter};
  ${flexColumn};
  text-align: center;
`;

export const TitleBlockWrapper = styled(TitleBlock)`
  padding: 0 20px;
  ${TitleDescription} {
    margin-top: 7px;
    line-height: 24px;
  }
`;

export const OkButton = styled(ButtonLoader)`
  min-width: 130px;
`;

export const CancelButton = styled(ButtonLoader)`
  padding: 0 20px;
  min-width: 130px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: calc(100% + 20px);
  margin: 11px -10px -10px;
  flex-wrap: wrap;

  button,
  a {
    ${flexCenter};
    margin: 10px;
  }
`;

export default AlertWrapper;
