import { WorkspaceContext } from '@anm/contexts/WorkspaceContext';
import isId from '@anm/helpers/is/isId';
import { toUrlParams } from '@anm/helpers/parseParams';
import pickUTMParams from '@anm/helpers/utm/pickUTMParams';
import usePageParams from '@anm/hooks/usePageParams';
import useSignupRedirect from '@anm/hooks/useSignupRedirect';
import { useCallback, useContext } from 'react';

export type EditorTarget = 'edit' | 'uploads' | 'stock-library';
export type ProjectKind = 'video' | 'image';

type GetFormatUrlProps = {
  waveUrl: string;
  editorUrl: string;
  isUserLogged: boolean;
  currentFolderId: number | null;
  newParam?: string | null;
  editorTarget?: EditorTarget;
  projectKind?: ProjectKind;
};

const useGetFormatUrl = ({
  waveUrl,
  newParam,
  editorUrl,
  isUserLogged,
  currentFolderId,
  editorTarget = 'edit',
  projectKind = 'video'
}: GetFormatUrlProps) => {
  const { workspace } = useContext(WorkspaceContext);
  const { embed, folderId: folderIdFromPageParams, ...others } = usePageParams();
  const utmParams = pickUTMParams(others);

  const folderId = folderIdFromPageParams || currentFolderId;

  const getParams = (formatParam: string) => ({
    new: newParam || 1,
    target: editorTarget,
    kind: projectKind,
    format: formatParam,
    workspace,
    ...(embed && { embed }),
    ...(folderId && { folderId }),
    ...(utmParams && utmParams)
  });

  const getFormatUrl = useCallback(
    (formatParam: string) => {
      const isEmbedId = isId(embed);
      const strParams = toUrlParams(getParams(formatParam));
      const editorLink = `${editorUrl}/#${strParams}`;

      const linkWithRedirect = useSignupRedirect({
        isUserLogged,
        link: editorLink,
        domain: waveUrl
      });

      const formatLink = isEmbedId ? editorLink : linkWithRedirect;

      return formatLink;
    },
    [isUserLogged, embed, getParams]
  );

  return getFormatUrl;
};

export default useGetFormatUrl;
