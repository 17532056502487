import api from '@anm/api';

export default async (email: string) => {
  try {
    await api().user.checkEmail(email);
    return true;
  } catch (e) {
    return false;
  }
};
