import styled from 'styled-components';

import { media } from '../../../styles/helpers';

const AuthButtonsOnMobile = media.laptop`
    padding: 0 20px 0 0;
    > * {
        width: auto;
    }
`;

const AuthButtonsWrapper = styled.div`
  white-space: nowrap;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  ${AuthButtonsOnMobile};
  > * {
    width: 45%;
    min-width: 110px;
    text-align: center;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export default AuthButtonsWrapper;
