import arrayToObject from '@anm/helpers/array/arrayToObject';
import { getFilterVideoData } from '@anm/helpers/waveVideo';
import { Manifest } from '@anm/hosting-player';
import 'isomorphic-fetch';
import isEmpty from 'lodash/fp/isEmpty';

import lfConfig from '../../config';
import { getLandingVideos } from '../generateSitemaps/helpers/generateLandingsVideoData';
import { LandingData } from '../getLanding';

export type VideoDataItem = {
  id: string;
  name: string;
  thumbnail: string;
};

export type VideoData = {
  [videoId: string]: VideoDataItem | Manifest;
};

export const fetchWistiaData = async (id: string) => {
  const { apiKey, oauthUrl } = lfConfig.services.wistia;
  const fetchUrl = `${oauthUrl}medias/${id}.json`;

  const res = await fetch(`${fetchUrl}?api_password=${apiKey}`);
  const { name, thumbnail } = await res.json();

  return { id, name, thumbnail: thumbnail && thumbnail.url };
};

const getVideoIds = (landing: LandingData) => {
  const { videoIds } = getLandingVideos(landing);

  return videoIds;
};

const getVideoData = async (currentLanding: LandingData) => {
  const videoIds = getVideoIds(currentLanding);
  if (!videoIds || isEmpty(videoIds)) return null;

  const videoData = await getFilterVideoData(videoIds);

  return arrayToObject(videoData);
};

export default getVideoData;
