import styled, { css } from 'styled-components';

import { media } from '../../../styles/helpers';

import { ModalProps } from '.';

export const ModalBody = styled.div`
  display: block;
`;

export const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  backdrop-filter: blur(10px);
  background: rgba(41, 42, 43, 0.9);
`;

const closeButtonOnTablet = media.tablet`
  right: -25px;
  top: -25px;
`;

export const ModalDialog = styled.div<Partial<ModalProps>>`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10000;
  margin: 10px 0;
  max-width: 90vw;
  max-height: 90vh;
  visibility: hidden;
  position: relative;
  transition: all 0.5s;
  transform: translate(-50%, -50%);
  opacity: ${({ isModalOpened, isVisible }) =>
    isVisible && isModalOpened ? 1 : !isVisible ? 0 : 1};
  transition: all 0.3 ease-in-out;

  ${({ modalWidth }) =>
    modalWidth &&
    css`
      width: ${modalWidth}px;
    `};

  ${({ modalHeight }) =>
    modalHeight &&
    css`
      max-height: ${modalHeight - 1}px;
    `};

  ${({ isModalOpened }) =>
    isModalOpened &&
    css`
      visibility: visible;
    `};

  &:after {
    display: block;
    content: '\u2715';
    font-weight: 800;
    font-size: 26px;
    position: absolute;
    font-weight: bold;
    top: -30px;
    pointer-events: none;
    right: 0;
    color: hsla(0, 0%, 100%, 0.6);
    ${closeButtonOnTablet};
  }
`;

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  left: 0;
  top: 0;
`;

export default Wrapper;
