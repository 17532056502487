import {
  CircleBar,
  CircleFill,
  ProgressCircle
} from '@anm/components/ProgressBar';
import { flexCenter, media, Row } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

import SectionTitle, { Description } from '../../../SectionTitle';

type CellProps = { isFirstCell?: boolean };

const firstCellStyles = css`
  flex: 2;
  justify-content: flex-start;
`;

const cellOnMobile = media.phone`
span {
  font-size: 12px;
  line-height: 16px;
}
 ${ProgressCircle} {
    font-size: 48px;
    p {
      ${flexCenter};
      margin: 0;
      width: 48px;
      height: 48px;
      font-size: 14px;
    }
  }
`;

export const Cell = styled.div<CellProps>`
  flex: 1;
  ${flexCenter};
  ${({ isFirstCell }) => isFirstCell && firstCellStyles};
  span {
    color: inherit;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    font-weight: normal;
  }
  ${CircleBar}, ${CircleFill} {
    border-width: 3px;
    border-color: ${({ color }) => color};
  }
  ${ProgressCircle} {
    font-size: 64px;
    background: none;
    position: relative;
    &:before {
      background: none;
    }
    p {
      ${flexCenter};
      margin: 0;
      width: 64px;
      height: 64px;
      font-size: 20px;
      font-weight: bold;
      position: absolute;
      color: ${({ color }) => color};
    }
  }
  ${cellOnMobile};
`;

export const ProgressBase = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  position: absolute;
  border: 3px solid #f5f7fa;
`;

export const HeaderTitle = styled.h3`
  ${flexCenter};
  color: inherit;
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
`;

const mobileHeaderRow = media.phone`
  width: 100vw;
  padding: 0 20px;
  min-height: 48px;
  margin-top: 40px;
  border-radius: 0;
  border-left: none;
  border-right: none;
  ${HeaderTitle} {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const HeaderRow = styled(Row)<{ headerHeight: string }>`
  z-index: 2;
  width: 100%;
  padding: 0 50px;
  position: sticky;
  margin-top: 70px;
  min-height: 68px;
  background: #f5f7fa;
  border-radius: 10px 10px 0 0;
  border: 1px rgb(204, 221, 230) solid;
  top: ${({ headerHeight }) => headerHeight};
  border-bottom: none;
  ${mobileHeaderRow};
`;

const bodyRowOnMobile = media.phone`
  padding: 15px 20px 22px;
`;

export const BodyRow = styled(Row)`
  padding: 35px 50px 33px;
  border-top: 1px solid #ccdde6;
  ${bodyRowOnMobile};
`;

const titleMobileStyles = media.phone`
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 12px;
  }
  ${Description} {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const BodyTitle = styled(SectionTitle)`
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 24px;
    text-align: left;
    font-weight: bold;
    line-height: 29px;
    font-style: normal;
    margin-bottom: 10px;
  }
  ${Description} {
    margin: 0;
    font-size: 18px;
    text-align: left;
    max-width: 400px;
    line-height: 32px;
    font-weight: normal;
  }
  ${titleMobileStyles};
`;

const bodyConteinerOnMobile = media.phone`
  width: 100vw;
  border-radius: 0;
  border-left: none;
  border-right: none;
  ${BodyRow} {
    padding: 15px 20px;
  }
  img {
    max-height: 30px;
    max-width: 30px;
  }
`;

export const BodyContainer = styled.div`
  width: 100%;
  color: #292a2b;
  overflow: hidden;
  border: 1px solid #ccdde6;
  border-radius: 0 0 10px 10px;
  ${BodyRow}:first-child {
    border: none;
  }
  ${bodyConteinerOnMobile};
`;

const Wrapper = styled.div`
  margin-bottom: 40px;
`;

export default Wrapper;
