import { ButtonProps } from '@anm/components/buttons/Button';
import React, { FC } from 'react';

import IconButtonWrapper from './Wrapper';

export { IconButtonWrapper };

type IconSizes =
  | 'small'
  | 'medium'
  | 'big'
  | '18x18'
  | '20x20'
  | '32x32'
  | '24x24';
type IconDirections = 'horizontal' | 'vertical';

export type IconButtonProps = ButtonProps & {
  icon?: string;
  iconSize?: IconSizes;
  withIconDirection?: IconDirections;
  className?: string;
};

const IconButton: FC<IconButtonProps> = ({
  className,
  children,
  iconSize = 'small',
  withIconDirection = 'horizontal',
  ...buttonProps
}) => (
  <IconButtonWrapper
    className={className}
    iconSize={iconSize}
    withIconDirection={withIconDirection}
    {...buttonProps}
  >
    {children}
  </IconButtonWrapper>
);

export default IconButton;
