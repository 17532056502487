import { createSelector } from 'reselect';
import appNotificationsReducer from './reducer';

type AppState = {
  appNotifications: ReturnType<typeof appNotificationsReducer>;
};

const selectNotifications = ({ appNotifications }: AppState) => appNotifications;

export const selectAppNotifications = createSelector(
  selectNotifications,
  appNotifications => appNotifications.notifications
);
