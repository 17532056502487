export interface RelationItem {
  name: string;
  title: string;
  preview: string;
  description: string;
  url?: string;
  alt?: string;
}

export const relationData: RelationItem[] = [
  {
    name: 'make-video',
    preview: 'images/data/success_stories/success_story_logo_981_png.webp',
    description: 'http://981media.com/',
    title: 'http://981media.com/',
    url: 'https://www.981media.com/'
  },
  {
    name: 'fb-cover',
    preview:
      'images/data/success_stories/be-brilliant/fb-cover_preview_png.webp',
    description: 'Read more:',
    title: 'How to Make a Facebook Cover Video in 5 Easy Steps',
    url:
      'https://wave.video/blog/how-to-make-a-facebook-cover-video-in-5-easy-steps/'
  },
  {
    name: 'edit-social-video-5-steps',
    preview: 'images/data/success_stories/981-media/981_edit_videos_jpg.webp',
    description: 'Read more:',
    title: 'How to Edit Videos for Social Media in 5 Easy Steps',
    url: 'https://wave.video/blog/edit-videos-for-social-media-in-5-easy-steps/'
  },
  {
    name: 'make-video-never-done-before',
    preview: 'images/data/success_stories/981-media/981-diy-video_jpg.webp',
    description: 'Read more:',
    title: `How to Make a Video if You've Never Done it Before`,
    url: 'https://wave.video/blog/how-to-make-a-video/'
  },
  {
    name: 'what-is-video-marketing',
    preview:
      'images/data/success_stories/homestars/what-is-video-marketing_jpg.webp',
    description: 'Read more:',
    title: 'What Is Video Marketing and Why Does Your Company Need It?',
    url:
      'https://wave.video/blog/what-is-video-marketing-and-why-does-your-company-need-it/'
  },
  {
    name: 'sm-cheat-sheet',
    preview: 'images/data/success_stories/homestars/cheat_sheet_jpg.webp',
    description: 'Read more:',
    title: 'Social Media Cheat Sheet for Video Ad Specs',
    url: 'https://wave.video/blog/social-media-cheat-sheet-for-video-ad-specs/'
  },
  {
    name: 'facebook-video-success',
    preview:
      'images/data/success_stories/real-estate-video-marketing/readmore_mari_png.webp',
    description: 'Read more:',
    title: 'Facebook Video Success with Free and Paid Methods',
    url: 'https://wave.video/blog/facebook-video-success/'
  },
  {
    name: 'real-estate-marketing',
    preview:
      'images/data/success_stories/real-estate-video-marketing/readmore_realestate_png.webp',
    description: 'Read more:',
    title: 'Real Estate Marketing: How to Promote Your Listing Effectively',
    url: 'https://wave.video/blog/real-estate-marketing/'
  },
  {
    name: '5-ways-to-brand-identity-on-social-media',
    preview:
      'images/data/success_stories/real-estate-video-marketing/5+Ways+to+Maintain_jpg.webp',
    description: 'Read more:',
    title: '5 Ways to Maintain a Strong Brand Identity on Social Media',
    url: 'https://wave.video/blog/5-ways-to-brand-identity-on-social-media/'
  },
  {
    name: 'how-to-make-social-media-videos',
    preview:
      'images/data/success_stories/thrive-mortgage/andy-crestodina-preview.jpg',
    description: 'Read more:',
    title: 'How to Make Solid Social Media Videos: 9 Steps for More Traffic',
    url: 'https://wave.video/blog/how-to-make-social-media-videos/'
  },
  {
    name: 'how-long-should-social-video-be',
    preview:
      'images/data/success_stories/all-things-nordic/cut-video-image.jpg',
    description: 'Read more:',
    title: 'How Long Should Your Social Video Be?',
    url: 'https://wave.video/blog/how-long-should-social-video-be/'
  }
];
