import { PartialButtonProps } from '@anm/components/buttons/Button';
import { FC } from 'react';

import { CommonLandingProps } from '../../../features/getLanding';

import Wrapper, {
  ButtonWrapper,
  ImageWrapper,
  Item,
  ListWrapper,
  Title,
  TitleWrapper
} from './Wrapper';
import { getNewPartners } from './helpers';

export type PartnersProps = CommonLandingProps & {
  title?: string;
  blackList?: string[];
  customButton?: PartialButtonProps;
};

const Partners: FC<PartnersProps> = ({
  title = 'We work well with',
  blackList,
  headingLevel,
  customButton
}) => (
  <Wrapper>
    <TitleWrapper color="black" {...{ title }} level={headingLevel} />
    <ListWrapper size={800}>
      {getNewPartners(blackList).map(({ iconUrl, name }) => (
        <Item key={name}>
          <ImageWrapper src={iconUrl} alt={name} />
          <Title>{name}</Title>
        </Item>
      ))}
    </ListWrapper>
    {customButton && (
      <ButtonWrapper
        size="small"
        variant="link"
        textColor="#000"
        {...customButton}
      />
    )}
  </Wrapper>
);

export default Partners;
