import { FC } from 'react';

import FlexContainer from '../../../FlexContainer';
import { CircleProgressList } from '../types';

import StatisticCircleItem from './Item';

const CircleList: FC<CircleProgressList> = ({ circleProgressList }) => (
  <FlexContainer variant="between-on-tablet" isList={true}>
    {circleProgressList.map(item => (
      <StatisticCircleItem {...item} key={item.title} />
    ))}
  </FlexContainer>
);

export default CircleList;
