import getHeightByRatio from '@anm/helpers/getHeightByRatio';
import noop from '@anm/helpers/noop';
import { FC } from 'react';

import { ColumnTitle, ColumnWrapper, Icon, ImagesContainer, InnerContent, Preview } from './Wrapper';
import { FormatItemProps } from './types';

const FormatItem: FC<FormatItemProps> = ({
  title,
  format,
  images,
  description,
  getFormatUrl,
  onModalClose,
  previewWidth,
  urlFormatParam,
  linkTarget = '_blank',
  onClick
}) => {
  const previewHeight = getHeightByRatio(format, previewWidth);
  const href = getFormatUrl?.(urlFormatParam);
  const element = href ? 'a' : 'div';
  const previewContent = format;

  return (
    <ColumnWrapper onClick={href ? onModalClose : () => onClick?.(format) || noop}>
      <InnerContent as={element} {...{ ...(href && { href, target: linkTarget }) }}>
        <Preview width={previewWidth} height={previewHeight} dangerouslySetInnerHTML={{ __html: previewContent }} />
        <ColumnTitle {...{ title, description }} level={3} />
        <ImagesContainer>
          {images.map(imageName => (
            <Icon key={imageName} alt={imageName} src={`images/icons/social/${imageName}_v1.svg`} />
          ))}
        </ImagesContainer>
      </InnerContent>
    </ColumnWrapper>
  );
};

export default FormatItem;
