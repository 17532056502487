import { Component } from 'react';

import withUser from '../../HOCs/withUser';
import UserAvatar from '../UserAvatar';

import UserInfo, { UserEmailInfo } from './UserInfo';
import UserBlockWrapper from './Wrapper';

export interface UserBlockProps {
  user: any;
}

class UserBlock extends Component<UserBlockProps> {
  render() {
    const { user } = this.props;
    if (!user) return null;

    const { email, displayName } = user;

    return (
      <UserBlockWrapper>
        <UserAvatar user={user} />
        <UserInfo>
          <span>{displayName}</span>
          <UserEmailInfo>{email}</UserEmailInfo>
        </UserInfo>
      </UserBlockWrapper>
    );
  }
}

export default withUser(UserBlock);
