import Button from '@anm/components/buttons/Button';
import { flexCenter, media } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

import SectionTitle, { Description } from '../../../SectionTitle';

type CardProps = {
  hasShadow?: boolean;
  background?: string;
  itemsPerRow?: number;
};

const descriptionOnMobile = media.phone`
  font-size: 14px;
  line-height: 22px;
  min-height: 125px;
`;

export const CardTitle = styled(SectionTitle)<{ isSmallIcon: boolean }>`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
    text-align: left;
  }
  ${Description} {
    margin: 0;
    font-size: 16px;
    text-align: left;
    line-height: 25px;
    min-height: ${({ isSmallIcon }) => (isSmallIcon ? 155 : 100)}px;
    font-weight: normal;
    a {
      color: #292a2b;
      text-decoration: underline;
      &:hover {
        color: #2294df;
      }
    }
    ${descriptionOnMobile};
  }
`;

const CardWrapperMobile = media.phone`
  margin: 0;
  min-width: 260px;
  min-height: 380px;
  margin-right: 10px;
  width: unset !important;
  padding: 25px 30px 19px;
`;

const CardWrapperTablet = media.tablet`
  width: calc(50% - 30px);
`;

export const CardWrapper = styled.div<CardProps>`
  display: flex;
  padding: 30px;
  border-radius: 20px;
  margin: 0 15px 30px;
  flex-direction: column;
  width: ${({ itemsPerRow }) => `calc(100% / ${itemsPerRow} - 30px)`};
  background: ${({ background }) => background || '#fff'};
  box-shadow: ${({ hasShadow }) =>
    hasShadow && '0px 5px 20px rgba(1, 83, 130, 0.1);'};
  ${CardWrapperTablet};
  ${CardWrapperMobile};
`;

const iconContainerOnMobile = media.phone`
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
  img {
    width: 30px;
    height: 30px;
  }
`;

const smallIconStyles = css`
  width: 70px;
  height: 70px;
  background: #fff;
  border-radius: 70px;
  img {
    width: 40px;
    height: 40px;
  }
  ${iconContainerOnMobile};
`;

export const IconContainer = styled.div<{ isSmallIcon: boolean }>`
  ${flexCenter};
  ${({ isSmallIcon }) => isSmallIcon && smallIconStyles}
  margin-bottom: 25px;
`;

const cardLinkOnMobile = media.phone`
  font-size: 14px;
`;

export const CardLink = styled(Button)`
  height: 27px;
  display: flex;
  margin-top: auto;
  align-items: center;
  text-decoration: underline;
  ${cardLinkOnMobile};
`;

const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

export default ListWrapper;
