import noop from '@anm/helpers/noop';
import { FC } from 'react';
import { isObject } from 'lodash/fp';

import { colors } from '..';
import Wrapper, {
  ButtonClose,
  Content,
  ContentColor,
  NotificationDescription,
  NotificationIcon,
  NotificationTitle
} from '../Wrapper';
import { useCanShowNotification } from '../hooks';
import { NotificationBlockProps } from '../types';

import SlideAnimation from './SlideAnimation';

export * from '../types';

const getIcon = (name: string) => `images/icons/notification/highlight/${name}.svg`;

const HighLightNotification: FC<NotificationBlockProps> = ({
  type,
  title,
  timeout,
  iconSrc,
  variant = 'highlight',
  children,
  description,
  onModalClose = noop
}) => {
  const [canShow, handleCloseNotification] = useCanShowNotification({ timeout, onModalClose });

  return (
    <SlideAnimation {...{ canShow }} onAnimationEnd={onModalClose}>
      <Wrapper {...{ variant }}>
        <Content color={colors[type] as ContentColor} {...{ type, variant }}>
          <ButtonClose src={getIcon('cross')} onClick={handleCloseNotification} />
          <NotificationIcon {...{ type }} src={iconSrc || getIcon(type)} />
          {title && <NotificationTitle {...{ title }} size="super-small" color="black" />}
          {description && isObject(description) && <NotificationDescription> {description}</NotificationDescription>}
          {description && typeof description === 'string' && (
            <NotificationDescription dangerouslySetInnerHTML={{ __html: description as string }} />
          )}
          {children}
        </Content>
      </Wrapper>
    </SlideAnimation>
  );
};

export default HighLightNotification;
