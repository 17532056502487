import getFromCdn from '@anm/helpers/getFromCdn';

export type ActionsName =
  | 'onMove'
  | 'onEdit'
  | 'onKick'
  | 'onBan'
  | 'onShare'
  | 'editAvatar'
  | 'onStats'
  | 'onDelete'
  | 'onRename'
  | 'onEditPen'
  | 'onLanding'
  | 'onSettings'
  | 'onEmbed'
  | 'onEditVideo'
  | 'onEditName'
  | 'onDownload'
  | 'onDuplicate'
  | 'onEditImage'
  | 'onStaffEdit'
  | 'onPermission'
  | 'onAddToBrand'
  | 'onInviteGuest'
  | 'onStreamVideo'
  | 'onStaffMetaEdit'
  | 'onCopyProjectId'
  | 'onTemplateSettings'
  | 'onVideoFromText'
  | 'onVideoFromBlog';

export type ActionsHandlers = Record<ActionsName, () => void>;

type ActionData = {
  name: string;
  icon?: string;
};

const allActions: Record<ActionsName, ActionData> = {
  onMove: {
    name: 'Move',
    icon: 'images/dashboard/move.svg'
  },
  onDelete: {
    name: 'Delete',
    icon: 'images/dashboard/delete.svg'
  },
  onStats: {
    name: 'View stats',
    icon: 'images/dashboard/stats.svg'
  },
  onDownload: {
    name: 'Download',
    icon: 'images/dashboard/download.svg'
  },
  onDuplicate: {
    name: 'Duplicate',
    icon: 'images/dashboard/duplicate.svg'
  },
  onRename: {
    name: 'Rename',
    icon: 'images/dashboard/edit-big_v1.svg'
  },
  onEditPen: {
    name: 'Edit',
    icon: 'images/dashboard/edit-big_v1.svg'
  },
  onSettings: {
    name: 'Player',
    icon: 'images/dashboard/player-btn-gray-v2.svg'
  },
  onEmbed: {
    name: 'Embed',
    icon: 'images/embed_icon.svg'
  },
  onShare: {
    name: 'Share',
    icon: 'images/dashboard/share-gray-icon.svg'
  },
  onEdit: {
    name: 'Edit',
    icon: 'images/dashboard/edit_grey_icon.svg'
  },
  onEditVideo: {
    name: 'Edit video',
    icon: 'images/dashboard/edit_grey_icon.svg'
  },
  onEditImage: {
    name: 'Edit image',
    icon: 'images/dashboard/edit_grey_icon.svg'
  },
  onStaffEdit: {
    name: 'Staff Edit',
    icon: 'images/dashboard/edit_grey_icon.svg'
  },
  onStaffMetaEdit: {
    name: 'Staff Meta Edit',
    icon: 'images/dashboard/edit_v2.svg'
  },
  onCopyProjectId: {
    name: 'Copy Template ID',
    icon: 'images/icons/copy_ic.svg'
  },
  onTemplateSettings: {
    name: 'Template Settings',
    icon: 'images/icons/settings_ic.svg'
  },
  onLanding: {
    name: 'Landing page',
    icon: 'images/icons/landing_grey_icon.svg'
  },
  onStreamVideo: {
    name: 'Stream this video',
    icon: 'images/icons/broadcast_icon.svg'
  },
  onInviteGuest: {
    name: 'Invite guests',
    icon: 'images/streaming/invite-guest-icon.svg'
  },
  onKick: {
    name: 'Ban from stream',
    icon: 'images/streaming/kick.svg'
  },
  onBan: {
    name: 'Ban forever',
    icon: 'images/streaming/ban.svg'
  },
  onPermission: {
    name: 'Permissions',
    icon: 'images/streaming/set_permissions.svg'
  },
  editAvatar: {
    name: 'Change avatar',
    icon: 'images/streaming/edit_avatar.svg'
  },
  onEditName: {
    name: 'Edit name',
    icon: 'images/dashboard/edit-big_v1.svg'
  },
  onAddToBrand: {
    name: 'Send to Live Studio',
    icon: 'images/icons/brand_icon.svg'
  },
  onVideoFromText: {
    name: 'From Text',
    icon: 'images/icons/text_hover_ic.svg'
  },
  onVideoFromBlog: {
    name: 'From Blog Post',
    icon: 'images/icons/blog_hover_ic.svg'
  }
};

export const getDropdownAction = (action: ActionsName) => {
  const { name, icon } = allActions[action];

  return {
    name,
    icon: icon && `${getFromCdn(icon)}`
  };
};

export const getDropdownActions = (actions: Partial<ActionsHandlers>) => {
  const filteredActions = Object.keys(actions).filter((action: ActionsName) => allActions[action]);

  const actionsWithHandlers = filteredActions.map((action: ActionsName) => ({
    ...getDropdownAction(action),
    handler: actions[action]!
  }));

  return actionsWithHandlers;
};
