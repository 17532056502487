import { FC } from 'react';

import WavePlayerModal from '../../WavePlayerModal';

import { TutorialProps } from '.';
import { PlayerPreviewWrapper, TitleWrapper, TutorialCard, VideoDuration } from './Wrapper';

const Tutorial: FC<TutorialProps> = ({ title, videoId, videoTime }) => (
  <TutorialCard>
    <PlayerPreviewWrapper>
      <WavePlayerModal playIconBehavior="hover-opacity" {...{ videoId }} />
      <VideoDuration>{videoTime}</VideoDuration>
    </PlayerPreviewWrapper>
    <TitleWrapper {...{ title }} level={3} />
  </TutorialCard>
);

export default Tutorial;
