import { StockItem as StockProps } from '@anm/api/modules/stocks';
import StockItemWrapper from '@anm/components/stocks/StockItem';
import { UserContext } from '@anm/components/user/UserProvider';
import useHover from '@anm/hooks/useHover';
import { useCallback, useContext, FC } from 'react';

import { getStockPriceValue } from './helpers';

type StockItemProps = {
  index: number;
  stock: StockProps;
  columnCount: number;
  columnWidth: number;
  itemHeight?: number;
  itemMargin?: number;
  onCardClick(index: number): void;
};

export const StockItem: FC<StockItemProps> = ({
  stock,
  index,
  columnCount,
  columnWidth,
  onCardClick,
  ...otherProps
}) => {
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();
  const { user } = useContext(UserContext);

  const isVideo = stock.type === 'STOCK_VIDEO';
  const subscriptionDetails = user?.subscriptionDetails;

  const priceValue = getStockPriceValue(stock, subscriptionDetails);
  const columnProps = { index, columnCount, columnWidth };

  const handleCardClick = useCallback(() => {
    onCardClick(index);
  }, []);

  return (
    <StockItemWrapper
      {...{
        ...stock,
        isVideo,
        hoverRef,
        isHovered,
        priceValue,
        columnProps,
        ...otherProps
      }}
      onCardClick={handleCardClick}
    />
  );
};

export default StockItem;
