import { DirectionType } from './types';

export const getAnimationDirection = (index: number, direction: DirectionType) => {
  const even = index % 2 === 0;

  if (direction === 'text-video') {
    return even ? 'right-appear' : 'left-appear';
  }
  if (direction === 'video-text') {
    return even ? 'left-appear' : 'right-appear';
  }

  return 'right-appear';
};
