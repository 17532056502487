import Container from '@anm/components/Container';
import SmallFooter from '@anm/components/footers/SmallFooter';
import FooterWrapper, {
  FooterSocialWrapper,
  FooterTopSection,
  FooterWrapperProps,
  NavListWrapper,
  SubscribtionAndSocialRow
} from '@anm/components/footers/TopFooter';
import SocialLinksList from '@anm/components/footers/SocialLinksList';
import NewsletterBlock from '@anm/components/footers/TopFooter/NewsletterBlock';
import { FC, PropsWithChildren } from 'react';
import { compose } from 'recompose';

import withNavigation, { Links, WithNavigationProps } from '../../../HOCs/withNavigation';
import withUser, { UserState, WithUserProps } from '../../../HOCs/withUser';
import HideOnMobile from '../../mobile/HideOnMobile';

import NavList from './NavList';

export type FooterProps = FooterWrapperProps &
  UserState &
  WithNavigationProps &
  Links & {
    isNewsletterSubscriptionFooter?: boolean;
  };

const Noop = ({ children }: PropsWithChildren<{}>) => <>{children}</>;

const Footer: FC<FooterProps> = ({
  user,
  footerLinks,
  pickSocialLinks,
  isNavListOnMobile = false,
  isNewsletterSubscription = false,
  ...restProps
}) => {
  const socialLinks = pickSocialLinks([
    user ? 'socialFacebookGroup' : 'socialFacebookDefault',
    'socialYoutube',
    'socialTwitter',
    'socialInstagram',
    'socialLinkedin',
    'socialPinterest',
    'socialTelegram'
  ]);

  const NavContainer = isNavListOnMobile ? Noop : HideOnMobile;

  return (
    <FooterWrapper {...restProps}>
      <FooterTopSection>
        <Container size={1150}>
          <NavContainer>
            <NavListWrapper>
              <NavList {...{ footerLinks, ...restProps }} />
            </NavListWrapper>
          </NavContainer>
          <SubscribtionAndSocialRow>
            {isNewsletterSubscription && <NewsletterBlock {...restProps} />}
            <FooterSocialWrapper>
              <SocialLinksList {...{ socialLinks }} />
            </FooterSocialWrapper>
          </SubscribtionAndSocialRow>
        </Container>
      </FooterTopSection>
      <SmallFooter {...restProps} />
    </FooterWrapper>
  );
};

export default compose<WithUserProps, FooterWrapperProps>(withUser, withNavigation)(Footer);
