import checkContainMedia from '@anm/helpers/checkContainMedia';
import useVideoStatusChecker from '@anm/hooks/useVideoStatusChecker';
import isVideoBlocked from '../../helpers/isVideoBlocked';
import isEqual from 'lodash/fp/isEqual';
import { useEffect, useRef, useState } from 'react';

import playerConfig from '../../config';
import getManifest from '../../helpers/getManifest';
import parseManifest from '../../helpers/parseManifest';
import {
  ApiError,
  Manifest,
  ManifestErrorResponse,
  VideoStatusCode,
  VideoData,
  VideoDataProps,
  VideoReadyProps,
  VideoInfo
} from '../../types';
import isSupportedCodec from '@anm/helpers/is/isSupportedCodec';
import isStreamVideo from '@anm/helpers/is/isStreamVideo';
import getBlockedVideoData from '../../helpers/getBlockedVideoData';

const { supportedExtensions } = playerConfig();

export const useVideoData = ({
  env,
  source = 'api',
  hostId,
  manifest,
  playerWidth,
  parentVideoData
}: VideoDataProps) => {
  const [color, setColor] = useState<string>();
  const [errorCode, setErrorCode] = useState<VideoStatusCode>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [videoData, setVideoData] = useState<VideoData>();

  useEffect(() => {
    if (parentVideoData || !hostId) return;

    (async () => {
      try {
        const response = manifest || ((await getManifest({ hostId, source, env })) as Manifest);

        const containSupportedVideo = checkContainMedia({
          supportedExtensions,
          list: response.main,
          type: 'video'
        });

        const videos = response.main.filter(s => s.type === 'video');
        const isNoVideo = !videos.length;
        const isProcessingError = !!response.error;
        const isBlocked = isVideoBlocked(response);

        if (isBlocked) {
          setErrorCode(VideoStatusCode.blocked);
          setVideoData(getBlockedVideoData(response as any));
          return;
        }

        const isCodecError = videos.length === 1 && !isStreamVideo(videos[0]?.path) && !isSupportedCodec(videos[0]);
        const isNoVideoError = isNoVideo && !isProcessingError && !isBlocked;
        const isVideoInProcess = !containSupportedVideo && !isNoVideo && !isProcessingError && !isBlocked;
        const isProtected = !!response.password?.hash && !!response.password?.isEnabled;

        switch (true) {
          case isProtected:
            setErrorCode(VideoStatusCode.protected);
            break;
          case isProcessingError:
            setErrorCode(VideoStatusCode.processingError);
            setErrorMessage(response.error || '');
            break;
          case isCodecError:
            setErrorCode(VideoStatusCode.codecErorr);
            break;
          case isNoVideoError:
            setErrorCode(VideoStatusCode.no_video);
            break;
          case isVideoInProcess:
            setErrorCode(VideoStatusCode.in_process);
        }

        setVideoData(parseManifest(response, playerWidth));
      } catch (err) {
        const { code, message, meta } = err as ManifestErrorResponse;
        setErrorCode(code);
        setErrorMessage(message);
        meta?.color && setColor(meta.color);
      }
    })();
  }, [hostId, playerWidth, parentVideoData]);

  return parentVideoData || { videoData, errorCode, errorMessage, color };
};

export const usePlayerData = ({ env, hostId, manifest, playerWidth, parentVideoData }: VideoReadyProps) => {
  const [password, setPassword] = useState<string>();
  const [isPending, setIsPending] = useState(false);
  const [videoInfo, setVideoInfo] = useState<VideoInfo>();

  const initialVideoInfo = useVideoData({
    env,
    hostId,
    manifest,
    playerWidth,
    parentVideoData,
    source: 'cdn'
  });

  const isManifestInProcess = !parentVideoData && initialVideoInfo.errorCode === VideoStatusCode.in_process;

  const { isMediaReady: isVideoReady, videoManifest } = useVideoStatusChecker({
    hostId,
    supportedExtensions,
    progress: isManifestInProcess ? 100 : null
  });

  useEffect(() => {
    if (!initialVideoInfo || isEqual(initialVideoInfo, videoInfo)) {
      return;
    }

    setVideoInfo(initialVideoInfo);
  }, [initialVideoInfo.videoData, initialVideoInfo.errorCode, initialVideoInfo.errorMessage]);

  useEffect(() => {
    if (!isVideoReady || !videoManifest) return;

    setVideoInfo({ videoData: parseManifest(videoManifest) });
  }, [isVideoReady, videoManifest]);

  useEffect(() => {
    if (typeof password === 'undefined') {
      return;
    }

    setIsPending(true);

    getManifest({
      env,
      hostId,
      query: password,
      source: 'cdn'
    })
      .then(response => setVideoInfo({ videoData: parseManifest(response as Manifest, playerWidth) }))
      .catch(err => {
        const { code } = err as ApiError;
        setVideoInfo({ errorCode: code });
      })
      .finally(() => setIsPending(false));
  }, [password, playerWidth]);

  return [videoInfo, isPending, setPassword] as const;
};

export const usePlayerWidth = (playerWithFromProps?: number) => {
  const playerRef = useRef<HTMLDivElement | null>(null);
  const [playerWidth, setPlayerWidth] = useState(playerWithFromProps);

  useEffect(() => {
    if (!playerRef.current || playerWidth) return;

    setPlayerWidth(playerRef.current.offsetWidth || 1);
  }, [playerRef.current]);

  return [playerRef, playerWidth] as const;
};
