import KeyDownOnBlock from '@anm/components/KeyDownOnBlock';
import styled from 'styled-components';

export const KeyDownOnBlockWrapper = styled(KeyDownOnBlock)`
  width: 100%;
  height: 100%;
`;

export const InnerModalWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
`;
