import { FC } from 'react';

import Wrapper, { ButtonText, PlusIcon } from './Wrapper';
export * from './Wrapper';

export const DEFAULT_ITEM_MARGIN = 40;
export const DEFAULT_ITEM_HEIGHT = 157;

export type BrowseMoreButtonProps = {
  href: string;
  width: number;
  columnCount: number;
  itemMargin?: number;
  itemHeight?: number;
};

const BrowseMoreButton: FC<BrowseMoreButtonProps> = ({
  itemMargin = DEFAULT_ITEM_MARGIN,
  itemHeight = DEFAULT_ITEM_HEIGHT,
  ...otherProps
}) => (
  <Wrapper {...{ ...otherProps, itemMargin, itemHeight }}>
    <PlusIcon />
    <ButtonText>
      Browse More in <br /> Media Library
    </ButtonText>
  </Wrapper>
);

export default BrowseMoreButton;
