import Container from '@anm/components/Container';
import isMobile from '@anm/helpers/is/isMobile';
import useIsMountedCheck from '@anm/hooks/useIsMountedCheck';
import { FC } from 'react';

import getSubtitleLevel from '../../../helpers/getSubtitleLevel';
import Background from '../../Background';
import LandingButton from '../../LandingButton';
import HideOnMobile from '../../mobile/HideOnMobile';

import AccordionMedia from './AccordionMedia';
import Carousel from './Carousel';
import Wrapper, { ButtonWrapper, Title } from './Wrapper';
import { AccordionMediaProps } from './types';

export * from './types';

const AccordionMediaSection: FC<AccordionMediaProps> = ({
  title,
  customBackground,
  backgroundVariant,
  button,
  customAuthButtonGuestText,
  headingLevel = 2,
  ...props
}) => (
  <Wrapper>
    <Background variant={backgroundVariant || 'white'} {...{ customBackground }}>
      <Title level={headingLevel} {...{ title }} color="black" />
      <Container size={1150}>
        {isMobile() && useIsMountedCheck() ? (
          <Carousel headingLevel={getSubtitleLevel(headingLevel)} {...{ ...props }} />
        ) : (
          <AccordionMedia headingLevel={getSubtitleLevel(headingLevel)} {...{ ...props }} />
        )}
        <LandingButton size="big" variant="light-blue-lowercase" {...{ customAuthButtonGuestText, ...button }}>
          {landingButton => (
            <HideOnMobile>
              <ButtonWrapper>{landingButton}</ButtonWrapper>
            </HideOnMobile>
          )}
        </LandingButton>
      </Container>
    </Background>
  </Wrapper>
);

export default AccordionMediaSection;
