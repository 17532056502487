import Container from '@anm/components/Container';
import { FC } from 'react';

import { CommonLandingProps } from '../../../features/getLanding';
import getWaves from '../../../helpers/getWaves';
import Background from '../../Background';
import LandingButton, { LandingButtonProps } from '../../LandingButton';
import SectionPicture from '../../SectionPicture';

import List from './List';
import VerticalListTitle from './Title';
import { ButtonWrapper, VerticalListWrapper } from './Wrapper';
import { ListProps } from './types';

export type VerticalListProps = CommonLandingProps &
  ListProps & {
    title: string;
    button?: LandingButtonProps;
  };

const VerticalList: FC<VerticalListProps> = ({
  list,
  title,
  waves,
  customBackground,
  sectionPicture,
  headingLevel,
  customIcon,
  button
}) => (
  <VerticalListWrapper>
    <Background
      variant={getWaves(waves) || 'white'}
      customBackground={customBackground}
      isSectionPicture={!!sectionPicture}
    >
      <Container>
        <VerticalListTitle title={title} color="black" level={headingLevel || 3} />
        <List {...{ list, customIcon }} />

        <LandingButton size="big" variant="light-blue-lowercase" {...button}>
          {landingButton => <ButtonWrapper>{landingButton} </ButtonWrapper>}
        </LandingButton>
      </Container>
    </Background>
    {sectionPicture && <SectionPicture pictureUrl={sectionPicture} title={title} />}
  </VerticalListWrapper>
);

export default VerticalList;
