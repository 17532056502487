import { ApiError } from '@anm/api';
import isApiError from '@anm/api/helpers/isApiError';
import sessionErrorCodes, { SessionErrorCodes } from '@anm/data/sessionErrorCodes';

import authLogger from '../../../auth/helpers/debugger';

const handleUserSessionError = <T extends {}>(path = '/user/error') => (res: ApiError<SessionErrorCodes> | T) => {
  if (isApiError(res) && sessionErrorCodes.includes(res.code)) {
    authLogger.warn(`session error ${res.code}, reloading`);
    window.location.replace(path);
  }

  return res;
};

export default handleUserSessionError;
