import { flexCenter } from '@anm/styles/helpers';
import styled from 'styled-components';

import { media } from '../../../styles/helpers';

export const ImageWrapper = styled.div`
  position: relative;
  padding-bottom: 56%;
  margin-bottom: 20px;

  & > img,
  & > picture img {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const TemplateLink = styled.a`
  color: #292a2b;
  display: block;

  &:hover {
    color: var(--blue_100);
  }
`;

export const LogoWrapper = styled.div`
  ${flexCenter};
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  padding: 10px;
  transition: 0.3s;
  overflow: hidden;
  background: #fff;
  max-width: 100px;
  max-height: 100px;
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  img {
    width: 80%;
    margin: auto;
    display: block;
  }

  ${TemplateLink}:hover & {
    max-width: 120px;
    max-height: 120px;
  }
`;

const listWrapperOnLaptop = media.laptop`
  padding: 90px 0;
`;

const listOnTablet = media.tablet`
  justify-content: space-between;
`;

const listItemOnTablet = media.tablet`
  max-width: 45%;
`;

const ListWrapper = styled.section`
  background: #fff;
  padding: 30px 0 50px 0;
  ${listWrapperOnLaptop};

  ul {
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    margin: 0 auto;
    ${listOnTablet};
  }

  li {
    width: 100%;
    ${listItemOnTablet};

    &:not(:last-child) {
      margin-bottom: 50px;
    }
  }

  p {
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
  }
`;

export default ListWrapper;
