import { FC } from 'react';

import { ItemText, ListItemLink, NestedListWrapper } from './Wrapper';
import { IconTextItem } from './types';

type ListItemContentProps = { item: Partial<IconTextItem> };

const ListItemContent: FC<ListItemContentProps> = ({
  item: { text, link, list }
}) => {
  if (text) return <ItemText>{text}</ItemText>;
  if (link) {
    return <ListItemLink href={link.url}>{link.title}</ListItemLink>;
  }
  if (!list) return null;

  return (
    <NestedListWrapper>
      {list.map(str => {
        return <li key={str} dangerouslySetInnerHTML={{ __html: str }} />;
      })}
    </NestedListWrapper>
  );
};

export default ListItemContent;
