export enum ClientCommand {
  pushUrl = 'pushUrl',
  scrollTo = 'scrollTo',
  replaceUrl = 'replaceUrl',
  getPageInfo = 'getPageInfo',
  resizeWindow = 'resizeWindow',
  getAccessToken = 'getAccessToken',
  requestUserFeedback = 'requestUserFeedback',
  requestInAppPurchase = 'requestInAppPurchase',
  requestMainProductPurchase = 'requestMainProductPurchase',
  requestEmailSubscriptionChange = 'requestEmailSubscriptionChange'
}

export type TSemrush = {
  config: Record<any, any>;
  init(): Promise<void>;
  onSmSdkLoad(): void;
  client(cmd: ClientCommand, param?: any, options?: any): any;
};

export type TPageInfo = {
  clientHeight: number;
  clientWidth: number;
  offsetLeft: number;
  offsetTop: number;
  scrollLeft: number;
  scrollTop: number;
};
