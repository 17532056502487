import { FC } from 'react';

import { CommonLandingProps } from '../../../features/getLanding';
import { SectionTitleProps } from '../../SectionTitle';

import ListContent from './List';
import CardListWrapper, { CardListContainer, ListTitle } from './Wrapper';
import { ListContentProps } from './types';
import getSubtitleLevel from '../../../helpers/getSubtitleLevel';

export type CardListProps = CommonLandingProps &
  ListContentProps &
  Partial<SectionTitleProps> & {
    containerSize?: number;
  };

const LandingPreviewCardList: FC<CardListProps> = ({
  list,
  headingLevel,
  itemsPerRow = 3,
  containerSize = 1150,
  ...titleProps
}) => {
  const listProps = { list, itemsPerRow };

  return (
    <CardListWrapper>
      <CardListContainer size={containerSize}>
        <ListTitle {...titleProps} color="black" level={headingLevel || 2} />
        <ListContent
          {...listProps}
          headingLevel={getSubtitleLevel(headingLevel, true)}
        />
      </CardListContainer>
    </CardListWrapper>
  );
};

export default LandingPreviewCardList;
