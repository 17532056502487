import isMobile from '@anm/helpers/is/isMobile';

export type VideoResolution = 'vga' | 'hd' | 'fhd' | 'nhd';

export const sizesMap = { nhd: 360 as const, vga: 480 as const, hd: 720 as const, fhd: 1080 as const };
export const resolutionMap = { 360: 'nhd' as const, 480: 'vga' as const, 720: 'hd' as const, 1080: 'fhd' as const };

export type VideoConstrainOptions = Pick<MediaTrackConstraintSet, 'width' | 'height' | 'aspectRatio' | 'frameRate'>;

const videoConstrains = (res: VideoResolution): VideoConstrainOptions => {
  // looks like for aspect to work it should be like this, rounded a bit
  const aspect = 1.7777777778; // 1920 / 1080;

  if (isMobile()) {
    /*
    const vertical = getOrientation() === 'vertical';

    if (vertical) {
      return {
        height: {ideal: Math.round(sizesMap[res] * aspect + 0.3)},
        width: {ideal: sizesMap[res]},
      }
    }
*/
    return {
      width: { ideal: Math.round(sizesMap[res] * aspect + 0.3) },
      height: { ideal: sizesMap[res] }
    };
  } else {
    return {
      width: { ideal: Math.round(sizesMap[res] * aspect + 0.3) },
      aspectRatio: { ideal: aspect },
      frameRate: { ideal: 30 }
    };
  }
};

export default videoConstrains;
