import { Request } from '../../types';

import { AuthUserResponse, LoginData, OAuthData, PasswordRecoveryParams, SignUpAnonData, SignUpData } from './types';

export * from './types';

export const signup = (request: Request) => (data: SignUpData, headers: {}) =>
  request<AuthUserResponse>({
    data,
    headers,
    url: 'signup',
    method: 'POST'
  });

export const signupAnon = (request: Request) => (data?: Partial<SignUpAnonData>) =>
  request<AuthUserResponse>({
    data: { type: 'ANON', product: 'WAVE', entry: 'LANDING', ...data } as SignUpAnonData,
    url: 'signup',
    method: 'POST'
  });

export const loginOAuth = (request: Request) => (data: OAuthData, headers: {}) =>
  request<AuthUserResponse>({
    headers,
    url: 'authenticate',
    method: 'POST',

    data: {
      '@class': 'oauth',
      opts: {
        clever_app: false
      },
      ...data
    }
  });

export const login = (request: Request) => (data: LoginData) =>
  request<AuthUserResponse>({
    url: 'authenticate',
    method: 'POST',
    data: {
      '@class': 'basic',
      ...data
    }
  });

export const passwordRecoveryRequest = (request: Request) => ({ email, ...requestData }: PasswordRecoveryParams) =>
  request({
    url: `confirmations/password/request/${email}`,
    data: requestData,
    method: 'POST'
  });

export const checkPasswordRecoveryLink = (request: Request) => (hash: string) =>
  request({
    url: `confirmations/password/confirm/${hash}`,
    method: 'GET'
  });

export const passwordResetRequest = (request: Request) => (hash: string, password: string) =>
  request<string>({
    url: `confirmations/password/confirm/${hash}`,
    data: { new_password: password },
    method: 'POST'
  });

export const loginSemrush = (request: Request) => (jwt: string) =>
  request<AuthUserResponse>({
    url: 'authenticate/semrush',
    method: 'POST',
    params: { jwt }
  });
