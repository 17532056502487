import stringToSlug from '@anm/helpers/string/stringToSlug';
import { useCallback, useEffect, useState, ChangeEvent } from 'react';

import { TutorialProps } from '.';
import { getFilteredTutorials } from './helpers';

export const useTutorialListFilter = (tutorialList: TutorialProps[]) => {
  const [tutorials, setTutorials] = useState(tutorialList);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setTutorials(getFilteredTutorials(tutorialList, search));
  }, [tutorialList, search]);

  const handleSearchChange = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    setSearch(target.value);
  }, []);

  return [tutorials, search, handleSearchChange] as const;
};

export const useOpenModalFromUrl = (list: TutorialProps[]) => {
  const [videoId, setVideoId] = useState<string | null>(null);

  const mappedTutorials = list.map(tutorial => ({
    ...tutorial,
    slug: stringToSlug(tutorial.title)
  }));

  useEffect(() => {
    const slugFromUrl = location.pathname.split('/').reverse()[0];
    const tutorial = mappedTutorials.find(t => t.slug === slugFromUrl);

    tutorial && setVideoId(tutorial.videoId);
  }, []);

  const handleModalClose = useCallback(() => setVideoId(null), []);

  return [videoId, handleModalClose] as const;
};
