import getFromCdn from '@anm/helpers/getFromCdn';
import { FC } from 'react';

import { ActionItemProps } from './ActionItem';
import ActionsList, { ActionsListProps } from './ActionsList';

export type VideoActionsProps = Omit<ActionsListProps, 'list'>;

const list: ActionItemProps[] = [
  {
    title: 'Edit video',
    description: 'Use a world class online video editor',
    buttonName: 'Open in editor',
    intent: 'edit',
    icon: `${getFromCdn('images/icons/media/edit-video.svg')}`
  },
  {
    title: 'Generate auto-captions',
    description: 'Choose from variety of styles, languages, add karaoke',
    buttonName: 'Add captions',
    intent: 'subtitler',
    icon: `${getFromCdn('images/icons/media/auto-captions-green.svg')}`
  },
  {
    title: 'Translate video with AI voice',
    description: 'Pick from a variety of voices and languages',
    buttonName: 'Translate',
    intent: 'translate-video',
    icon: `${getFromCdn('images/icons/media/translate.svg')}`
  },
  {
    title: 'Resize video',
    description: 'Resize to vertical reels, square video or to any other format',
    buttonName: 'Resize',
    intent: 'resize',
    icon: `${getFromCdn('images/icons/media/resize-video.svg')}`
  },
  {
    title: 'Trim video',
    description: 'Pick the best parts of your video to create new content',
    buttonName: 'Trim',
    intent: 'trim',
    icon: `${getFromCdn('images/icons/media/trim-video.svg')}`
  }
];

const VideoActions: FC<VideoActionsProps> = props => <ActionsList list={list} {...props} />;

export default VideoActions;
