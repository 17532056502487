import { ANON_USER_COOKIE } from '@anm/constants/auth';
import * as cookie from '@anm/helpers/cookie';
import isServer from '@anm/helpers/is/isServer';

export const setAnon = (isAnon: boolean) => {
  if (isServer()) return;
  cookie.set(ANON_USER_COOKIE, JSON.stringify(isAnon));

  return isAnon;
};

export const isAnon = () => {
  if (isServer()) return;

  return JSON.parse(cookie.get(ANON_USER_COOKIE) || 'false');
};
