import noop from '@anm/helpers/noop';
import { ChangeEvent, FC } from 'react';

import { CheckboxContainer, CheckboxImage, HiddenCheckbox, StyledCheckbox } from './styles';

export * from './styles';

export type CheckboxProps = {
  checked: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
};

const Checkbox: FC<CheckboxProps> = ({ className = '', checked, disabled, onChange = noop, ...props }) => (
  <CheckboxContainer {...{ className }}>
    <HiddenCheckbox {...{ ...props, checked, disabled, onChange }} />
    <StyledCheckbox {...{ checked, disabled }}>
      <CheckboxImage src="images/icons/checkbox.svg" alt="checkbox" />
    </StyledCheckbox>
  </CheckboxContainer>
);

export default Checkbox;
