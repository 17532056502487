import { TooltipLabel, TooltipWrapper } from '@anm/components/Tooltip';
import MicVolume from '@anm/components/media/MicVolume';
import { truncate } from '@anm/styles/helpers';
import styled from 'styled-components';

export const MicText = styled.p`
  margin: 0;
  line-height: 40px;
  color: ${({ theme }) => theme.textColor};
  ${truncate('100%')};
`;

const MicInputWrapper = styled.div`
  position: relative;

  ${TooltipWrapper} {
    width: 100%;
  }
  ${TooltipLabel} {
    ${truncate('100%')};
  }
`;

export const MicVolumeWrapper = styled(MicVolume)`
  margin: 0 0 19px;
`;

export default MicInputWrapper;
