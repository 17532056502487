import CardModal from '@anm/components/modals/CardModal';
import getFromCdn from '@anm/helpers/getFromCdn';
import { FC } from 'react';

import { CardTitle, Content, Row, SelectorWrapper, Title } from './Wrapper';
import { getStepsData } from './helpers';
import { TRecordingSelectorModal, TSelectorItem } from './types';

const SelectorItem: FC<TSelectorItem> = ({ image, title, description, handler, href, target }) => (
  <SelectorWrapper onClick={handler} href={href} target={target}>
    <img src={getFromCdn(image)} alt={title} />
    <CardTitle level={4} {...{ title, description }} />
  </SelectorWrapper>
);

const RecordingSelectorModal: FC<TRecordingSelectorModal> = ({
  title,
  description,
  advancedLink,
  onSimpleSelect,
  ...modalProps
}) => {
  const data = getStepsData(advancedLink, onSimpleSelect);

  return (
    <CardModal cardSettings={{ borderRadius: 10, isPadding: false }} {...modalProps}>
      <Content>
        <Title level={2} {...{ title, description }} />
        <Row>
          {data.map(item => (
            <SelectorItem key={item.title} {...item} />
          ))}
        </Row>
      </Content>
    </CardModal>
  );
};

export default RecordingSelectorModal;
