import Avatar from '@anm/components/Avatar';
import Container from '@anm/components/Container';
import { FC } from 'react';

import { CommonLandingProps } from '../../../features/getLanding';
import Background from '../../Background';
import FlexContainer from '../../FlexContainer';
import SectionTitle from '../../SectionTitle';
import LandingVideo, { ModalVideoProps } from '../../landing/LandingVideo';

import ListContent from './ListContent';
import Wrapper, {
  Content,
  DataWrapper,
  ListWrapper,
  Title,
  VideoWrapper
} from './Wrapper';
import { ListContentProps } from './types';

export type CompanyInfoProps = CommonLandingProps &
  ListContentProps & {
    title: string;
    title2: string;
    logoUrl: string;
    description: string;
    background: {
      imageUrl: string;
    };
    alt?: string;
    modalVideo?: ModalVideoProps;
  };

const CompanyInfo: FC<CompanyInfoProps> = ({
  alt,
  list,
  title,
  title2,
  logoUrl,
  modalVideo,
  background,
  description,
  headingLevel = 1
}) => (
  <Wrapper>
    <Background variant="success-stories" imageBackground={background.imageUrl}>
      <Container>
        <Title level={headingLevel} color="white" title={title} />
        {modalVideo && (
          <VideoWrapper>
            <LandingVideo
              withIconPlay={true}
              iconPlayVariant="black"
              alwaysInModal={true}
              wistiaId={modalVideo.wistiaId}
              iconText={modalVideo.iconText}
            />
          </VideoWrapper>
        )}
      </Container>
    </Background>
    <Content>
      <Container size={1000}>
        <FlexContainer variant="column-center-on-mobile-between-reverse-on-tablet">
          <Avatar size={200} alt={alt || title} src={logoUrl} />
          <DataWrapper>
            <SectionTitle
              level={2}
              color="black"
              title={title2}
              {...{ description }}
            />
          </DataWrapper>
        </FlexContainer>
        <ListWrapper>
          <FlexContainer
            variant="wrap-on-mobile-nowrap-around-on-tablet"
            isList
          >
            <ListContent {...{ list }} />
          </FlexContainer>
        </ListWrapper>
      </Container>
    </Content>
  </Wrapper>
);

export default CompanyInfo;
