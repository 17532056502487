import listenAudioVolume, { OnChangeProps } from '@anm/helpers/media/listenAudioVolume';
import { useEffect, useRef, useState } from 'react';

const useVolumeListener = (audioStream?: MediaStream | null, delay?: number) => {
  const defaultVolumeData = { volume: 0, signal: 0 };
  const clearSetTimeoutRef = useRef<() => void>();
  const clearListenerRef = useRef<() => void>();
  const [volumeData, setVolumeData] = useState<OnChangeProps>(defaultVolumeData);

  const clear = () => {
    clearSetTimeoutRef.current?.();
    clearListenerRef.current?.();
  };

  useEffect(() => {
    if (!audioStream && volumeData.volume !== 0) setVolumeData(defaultVolumeData);
  }, [!!audioStream]);

  useEffect(() => {
    if (!audioStream) return;
    clearListenerRef.current?.();
    clearListenerRef.current = listenAudioVolume(audioStream, setVolumeData);

    if (delay) {
      clearSetTimeoutRef.current?.();
      const clearId = window.setTimeout(() => clearListenerRef.current?.(), delay);
      clearSetTimeoutRef.current = () => window.clearTimeout(clearId);
    }

    return () => {
      clear();
    };
  }, [audioStream?.id]);

  return { volumeData, clear };
};

export default useVolumeListener;
