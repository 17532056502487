import {GetMediaInfoResponse} from "@anm/api/modules/youtube";

export const blockedYTChannels = [
  'UCOZ5PfL5oIPmDGBZzOKymIA'
].reduce((acc, id) => {
  acc[id] = id;
  return acc;
}, {} as any);

export const blockedYTVideos = [
  'LL0Njfd4oCk'
].reduce((acc, id) => {
  acc[id] = id;
  return acc;
}, {} as any);

export const isBlocked = (info?: Pick<GetMediaInfoResponse, 'id' | 'channel_id'>) => {
  return info && (blockedYTVideos[info.id] || blockedYTChannels[info.channel_id]);
}