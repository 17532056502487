import { DEFAULT_WAVE_COLOR } from '@anm/constants/colors';
import { Configs, PlaybarControls } from '@anm/hosting-player';

type VideoMetaKeys = 'name' | 'description' | 'duration' | 'uploadDate';

export const DEFAULT_VIDEO_META: { [key in VideoMetaKeys]: string } = {
  name: 'Video hosting by Wave.video',
  description: 'This video is hosted on Wave.video, a versatile video marketing platform for business.',
  duration: 'PT10S',
  uploadDate: new Date().toISOString()
};

export const DEFAULT_CONFIGS: Configs = {
  bigPlayButton: true,
  autoplay: false,
  end: 'showThumbnail',
  muted: false,
  playerColor: DEFAULT_WAVE_COLOR,
  hideUnmute: false,
  playHidden: false,
  playbar: {
    quality: true,
    currentTime: true,
    fullScreen: true,
    logo: true,
    playbarButton: true,
    smallPlayButton: true,
    volume: true,
    speed: true,
    download: false
  }
};

export const PLAYBAR_CONTROLS: PlaybarControls[] = [
  'currentTime',
  'fullScreen',
  'logo',
  'playbarButton',
  'quality',
  'smallPlayButton',
  'speed',
  'volume',
  'download'
];
