import { FC } from 'react';
import styled from 'styled-components';

import LeftSide, { LeftSideProps } from './LeftSide';
import RightSide, { RightSideProps } from './RightSide';

export type MediaCardProps = LeftSideProps & RightSideProps & {};

const Wrapper = styled.div`
  width: fit-content;
  margin: 30px auto;
  padding: 40px;
  display: flex;
  flex-direction: row;

  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(1, 83, 130, 0.2);
  border-radius: 30px;

  > *:first-child {
    margin-right: 50px;
  }
`;
const MediaCard: FC<MediaCardProps> = ({
  title,
  options,
  preview,
  duration,
  isOpenEditorPending,
  onChangeIntent,
  onChangeMediaType,
  startDownload
}) => (
  <Wrapper>
    <LeftSide duration={duration} preview={preview} title={title} />
    <RightSide
      options={options}
      startDownload={startDownload}
      isOpenEditorPending={isOpenEditorPending}
      onChangeIntent={onChangeIntent}
      onChangeMediaType={onChangeMediaType}
    />
  </Wrapper>
);

export default MediaCard;
