import { UploadFile } from '@anm/api/modules/uploads';
import selectProps from '@anm/helpers/store/selectProps';
import { createSelector } from 'reselect';

import { UploadsState } from './types';

type AppState = {
  uploads: UploadsState;
};

const selectUploadsEntity = ({ uploads }: AppState) => uploads;

export const selectUploadsList = createSelector(selectUploadsEntity, entity => entity.data || []);
export const selectUploadsListPending = createSelector(selectUploadsEntity, entity => entity.isPending);

export const selectHasMoreUploads = createSelector(
  selectUploadsEntity,
  entity => entity.hasMoreUploads && !entity.isPending
);

export const selectUploadsListParams = createSelector(selectUploadsEntity, entity => entity.params);

export const selectIsListPending = createSelector(selectUploadsEntity, entity => entity.isPending);

export const selectIsListError = createSelector(selectUploadsEntity, entity => entity.isError);

export const selectMediaUpload = createSelector(selectUploadsEntity, entity => entity.fetchMediaUpload.data);

export const selectBreadcrumbs = createSelector(selectUploadsEntity, entity => entity.breadcrumbs);

export const selectIsStorageFull = createSelector(selectUploadsEntity, entity => entity.isStorageFull);

export const selectCurrentBreadcrumb = createSelector(
  selectBreadcrumbs,
  breadcrumbs => breadcrumbs[breadcrumbs.length - 1]
);
export const selectCurrentTeamUserBreadcrumb = createSelector(
  selectBreadcrumbs,
  breadcrumbs => breadcrumbs.length > 1 && breadcrumbs[1]
);

export const selectCurrentFolderPath = createSelector(selectCurrentBreadcrumb, breadcrumb =>
  breadcrumb?.extraProps?.type === 'user' ? '' : breadcrumb.name
);

export const selectCreateFolderIsPending = createSelector(selectUploadsEntity, entity => entity.createFolder.isPending);

export const selectIsListHaveMediaUploads = createSelector(selectUploadsList, data => {
  if (Array.isArray(data)) return (data || [])?.some((f: UploadFile) => f.meta);

  return false;
});

export const selectUpdateFolderMeta = createSelector(selectUploadsEntity, entity => entity.updateFolderMeta);

export const selectUploadingFile = createSelector(selectUploadsEntity, selectProps<string>(), (entity, uploadId) =>
  entity.uploading.files.find(f => f.uploadId === uploadId)
);

export const selectUploadingFiles = createSelector(selectUploadsEntity, entity => entity.uploading.files);

export const selectMediaProgress = createSelector(selectUploadsEntity, entity => entity.uploading?.uploads);
export const selectProgressListener = createSelector(selectUploadsEntity, entity => entity.uploading.progressListener);

export const selectUploadProjects = createSelector(selectUploadsEntity, entity => entity.uploadProjects);

export const selectUploadForBrandIsPending = createSelector(
  selectUploadsEntity,
  entity => entity.uploadForBrand.isPending
);

export const selectUploadForBrandData = createSelector(selectUploadsEntity, entity => entity.uploadForBrand.data);

export const selectUploadForBrandParams = createSelector(selectUploadsEntity, entity => entity.uploadForBrand.params);

export const selectLatestUpload = createSelector(
  selectUploadsEntity,
  entity => entity.uploading?.files[entity.uploading?.files.length - 1]
);

export const selectUploadFileById = createSelector(selectUploadsEntity, selectProps<string>(), (entity, id) =>
  entity.uploading?.files.find(f => f.uploadId === id)
);

export const selectUploadById = createSelector(
  selectUploadsEntity,
  selectProps<string>(),
  (entity, id) => entity.uploading?.uploads[id]
);

export const selectIsUploadCompleted = createSelector(
  selectUploadsEntity,
  selectProps<string | undefined>(),
  (entity, id) => (!id ? false : !!entity.uploading.uploads[id]?.isCompleted)
);

export const selectCreatedHostId = createSelector(selectLatestUpload, upload => upload?.hostId);

export const selectUploadPending = createSelector(selectUploadsEntity, entity => !!entity.uploading?.isPending);
export const selectUploadError = createSelector(selectUploadsEntity, entity => entity.uploading?.error);

export const selectIsUploadWithProjectCreationPending = createSelector(
  selectUploadsEntity,
  entity => !!entity.uploading?.files[0]?.createHost
);

export const selectUploadedFileByHostId = createSelector(selectUploadsEntity, selectProps<string>(), (entity, hostId) =>
  entity.uploading?.files.find(f => f.hostId === hostId)
);

export const selectSearchInput = createSelector(selectUploadsEntity, entity => entity.search);

export const selectSearchIsOpen = createSelector(selectUploadsEntity, entity => entity.isSearchOpen);
