import landingsApi from '@anm/api/instances/landingsApi';
import youtubeApi from '@anm/api/instances/youtubeApi';
import { getAuthToken } from '@anm/auth/helpers/authToken';

import lfConfig from '../config';

const { youtubeApiUrl } = lfConfig;

const setupApi = () => {
  youtubeApi({ hasContext: false, baseURL: youtubeApiUrl });

  return landingsApi({ token: getAuthToken() });
};

export default setupApi;
