import isUndefined from 'lodash/fp/isUndefined';

import { ApiError } from './../types';

const isApiError = <T>(res: ApiError | T): res is ApiError => {
  const error = res || {};
  const { code, message } = error as ApiError;

  return !!(code && !isUndefined(message));
};

export default isApiError;
