import Loader from '@anm/components/Loader';
import useDelayedRender from '@anm/hooks/useDelayedRender';
import React, { FC } from 'react';
import styled from 'styled-components';

const LoaderWrapper = styled(Loader)`
  flex-grow: 0 !important;
`;

const DelayedLoader: FC = () => {
  const canRender = useDelayedRender(1000);
  if (!canRender) return null;

  return <LoaderWrapper size="large" color="blue" />;
};

export default DelayedLoader;
