import { FC } from 'react';
import { TopBannerWrapper } from './Wrapper';

export type TopBannerProps = {
  text?: string;
};

const TopBanner: FC<TopBannerProps> = ({ text }) => {
  return <TopBannerWrapper dangerouslySetInnerHTML={{ __html: text as string }} />;
};

export default TopBanner;
