import { FC } from 'react';

import { NavigationDropdownListProps, NavList } from '../../types';

import ContentNestedList, { ContentNestedListProps } from './ContentNestedList';
import ContentVerticalList, {
  ContentNestedLinkProps
} from './ContentVerticalList';

type NavigationDropdownContentProps = Omit<
  NavigationDropdownListProps,
  'variant' | 'navList' | 'title'
> & {
  contentList: NavList;
};

const NavigationDropdownContent: FC<NavigationDropdownContentProps> = ({
  isDropdownWithPreview = false,
  ...restProps
}) => {
  if (isDropdownWithPreview) {
    return <ContentNestedList {...(restProps as ContentNestedListProps)} />;
  }

  return <ContentVerticalList {...(restProps as ContentNestedLinkProps)} />;
};

export default NavigationDropdownContent;
