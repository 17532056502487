import styled, { keyframes } from 'styled-components';

import { media } from '../../../styles/helpers';

type SliderLinesProps = {
  count: number;
  speed: number;
}

const lineFill = keyframes`
  from {
    max-width: 0px;
  }

  to {
    max-width: 100%;
  }
`;

const LineMarginOnTablet = media.tablet`
  margin-right: 16px;
`;

const LineSpacingOnTablet = media.tablet`
  width: calc((100% / ${({ count }: SliderLinesProps) => count}) - 16px);
`;

const LinesWrapperOnlaptop = media.laptop`
  padding: 60px 0;
`;

const SliderLinesWrapper = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  ${LinesWrapperOnlaptop};

  li {
    width: calc((100% / ${({ count }: SliderLinesProps) => count}) - 4px);
    max-width: 180px;
    height: 4px;
    background-color: #e3e5ea;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    border-radius: 2px;
    ${LineSpacingOnTablet};

    &.current {
      &:after {
        animation: ${lineFill} ${({ speed }: SliderLinesProps) => speed / 1000}s
          linear;
        opacity: 1;
      }
    }

    &.filled {
      &:after {
        max-width: 100%;
        opacity: 1;
      }
    }

    &:not(:last-child) {
      margin-right: 4px;
      ${LineMarginOnTablet};
    }

    &:after {
      display: block;
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      opacity: 0;
      background-color: #919eaa;
    }
  }
`;

export default SliderLinesWrapper;
