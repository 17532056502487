import styled from 'styled-components';

import { media } from '../../../landings/styles/helpers';

const SectionPictureOnLaptop = media.laptop`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 32%);
  width: auto;
  max-width: 720px;
  max-height: 425px;
`;

const SectionPictureLineOnLaptop = media.laptop`
  display: none;
`;

const SectionPictureWrapper = styled.div`
  ${SectionPictureOnLaptop};
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  max-width: 335px;
  transform: translate(-50%, 24%);

  &:after {
    display: block;
    content: '';
    width: 100%;
    height: 6px;
    background: #fff;
    position: absolute;
    bottom: calc(24% - 2px);
    ${SectionPictureLineOnLaptop};
  }

  img {
    display: block;
    transform: translate3d(0, 0, 0);
  }
`;

export default SectionPictureWrapper;
