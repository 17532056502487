import styled from 'styled-components';

import { media } from '../../../styles/helpers';

const AvatarWrapperOnLaptop = media.laptop`
  margin: 0 37px 0 0;
  padding-top: 20px;
`;

const AvatarWrapper = styled.div`
  margin: 0 auto 40px auto;
  ${AvatarWrapperOnLaptop};

  & > div {
    margin: 0 auto;
  }

  a {
    outline: none;
  }
`;

export default AvatarWrapper;
