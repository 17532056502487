import { DropdownGroupProps, DropdownOpenerVariants } from '@anm/components/Dropdown';
import Button from '@anm/components/buttons/Button';
import { NavigationDropdownListProps, NavigationVariants } from '@anm/components/header';
import { FC } from 'react';

import { ButtonName, LinkWrapperProps } from '../types';

import NavigationDropdownContent from './Content';
import { BadgeNew, DropdownContentWrapper, NavigationDropdownWrapper } from './Wrapper';

const dropdownVariantMap: {
  [key in NavigationVariants]: DropdownOpenerVariants;
} = {
  pink: 'blue',
  blue: 'light-blue',
  white: 'black',
  grey: 'black',
  'white-hosting': 'black',
  live: 'black',
  lives: 'black',
  transparent: 'black',
  'blue-light': 'black'
};

const LinkWrapper: FC<LinkWrapperProps> = ({ title, route, href, renderNavLink, isNew }) => (
  <>
    <Button variant="link" size="medium" {...(href && { href })}>
      {route
        ? renderNavLink({
            route,
            title
          })
        : title}
    </Button>
    {isNew && <BadgeNew variant={'yellow'}>New!</BadgeNew>}
  </>
);

const NavigationDropdown: FC<NavigationDropdownListProps & DropdownGroupProps> = ({
  title,
  route,
  navList,
  variant,
  delay,
  handleDropdownOpen,
  handleDropdownClose,
  ...contentProps
}) => (
  <NavigationDropdownWrapper buttonName={title.toLowerCase() as ButtonName}>
    <DropdownContentWrapper
      openDelay={delay}
      closeDelay={delay}
      onOpen={handleDropdownOpen}
      onClose={handleDropdownClose}
      buttonName={title.toLowerCase() as ButtonName}
      renderContent={() => <NavigationDropdownContent contentList={navList} {...contentProps} />}
      direction="to-bottom"
      openerVariant={dropdownVariantMap[variant]}
    >
      <LinkWrapper {...{ title, route, ...contentProps }} />
    </DropdownContentWrapper>
  </NavigationDropdownWrapper>
);

export default NavigationDropdown;
