import ViewSwitcher from '@anm/components/ViewSwitcher';
import noop from '@anm/helpers/noop';
import { useCallback, FC } from 'react';

import { PanelWrapper, TabsContentWrapper, TabsPanelItem, TabsPanelWrapper } from './Wrapper';
import { useTabHandlers } from './hooks';
import { HorizontalTabsProps, TabButtonProps } from './types';

export { TabsContentWrapper };

const TabButton: FC<TabButtonProps> = ({ tab, activeTab, onTabChange }) => {
  const handleTabClick = useCallback(() => onTabChange(tab), [tab]);

  return (
    <TabsPanelItem onClick={handleTabClick} isActive={activeTab === tab}>
      {tab}
    </TabsPanelItem>
  );
};

const HorizontalTabs: FC<HorizontalTabsProps> = ({
  tabs,
  hideTabs,
  children,
  renderTabsPanelInnerContent = noop,
  renderTabItem = (props: TabButtonProps) => <TabButton key={props.tab} {...props} />,
  ...restProps
}) => {
  const [activeView, activeTab, setActiveTab] = useTabHandlers({
    ...restProps,
    tabs
  });

  return (
    <>
      {!hideTabs && (
        <PanelWrapper>
          <TabsPanelWrapper>
            {tabs.map(tab => renderTabItem({ tab, activeTab, onTabChange: setActiveTab }))}
          </TabsPanelWrapper>
          {renderTabsPanelInnerContent()}
        </PanelWrapper>
      )}
      {children && (
        <TabsContentWrapper>
          <ViewSwitcher active={activeView}>{children}</ViewSwitcher>
        </TabsContentWrapper>
      )}
    </>
  );
};

export default HorizontalTabs;
