import Image from '@anm/components/image/Image';
import TextArea from '@anm/components/form/TextArea';
import TextInput from '@anm/components/form/TextInput';
import ButtonLoader from '@anm/components/buttons/ButtonLoader';
import styled, { css } from 'styled-components';
import TitleBlock, { TitleDescription } from '@anm/components/TitleBlock';
import { flexRow, flexColumn, flexCenter } from '@anm/styles/helpers';

export const MainTitle = styled(TitleBlock)`
  h1,
  h2,
  h3,
  h4 {
    font-size: 32px;
    line-height: 32px;
    font-weight: bold;
    text-align: center;
  }
  ${TitleDescription} {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-top: 10px;
  }
`;

export const Content = styled.div`
  gap: 24px;
  ${flexColumn};
  padding: 41px 40px 41px;

  input {
    margin: 0;
  }
`;

export const TitleInput = styled(TextInput)``;

export const TextAreaWrapper = styled(TextArea)<{ isUseTextIs: boolean }>`
  textarea {
    resize: none;
    margin-bottom: -5px;
    height: ${({ isUseTextIs }) => (isUseTextIs ? 323 : 188)}px;
  }
`;

export const TextAreaContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: -3.5px;
`;

export const WrappedButtonLoader = styled(ButtonLoader)`
  display: flex;
  height: 50px;
  margin: 0 auto;
  min-width: 130px;
`;

export const Wrapper = styled.div<{ mt?: number }>`
  width: 600px;
  max-width: 100vh;
  background: #fff;
  border-radius: 10px;
  pointer-events: all;
  margin: 5% 0;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
  }
  ${({ mt }) =>
    mt &&
    css`
      margin-top: ${mt}px;
    `}
`;

export const ChecklistRow = styled.div`
  ${flexRow};
  margin-bottom: 25px;
  justify-content: flex-start;
`;

export const WrapperImage = styled(Image)`
  margin-right: 13.5px;
  width: 17px;
  height: 17px;
`;

export const CheckListContent = styled.div`
  padding: 30px 20px;
  ${ChecklistRow}:last-child {
    margin: 0;
  }
`;

export const Description = styled.span`
  color: #919eaa;
  line-height: 24px;
`;

export const WordsCounter = styled.div`
  ${flexCenter};
  left: 11px;
  bottom: 11px;
  height: 25px;
  z-index: 11;
  padding: 0 16px;
  font-size: 14px;
  line-height: 17px;
  position: absolute;
  background: #f4f6f7;
  border-radius: 20px;
  border: 1px solid #ccdde6;
`;

export const CharsCounter = styled.span`
  top: 0;
  right: 0;
  color: #8b9499;
  position: absolute;
  font-size: 14px;
  line-height: 17px;
`;
