import getFromCdn from '@anm//helpers/getFromCdn';
import TitleBlock from '@anm/components/TitleBlock';
import { DOMAttributes, FC } from 'react';
import styled from 'styled-components';

export type TitleBackArrowProps = Pick<
  DOMAttributes<HTMLDivElement>,
  'onClick'
> & {
  title: string;
};
const IconArrow = styled.div`
  &:before {
    top: 0;
    left: 0;
    content: '';
    width: 64px;
    height: 71px;
    z-index: 150;
    position: absolute;
    background: url(${getFromCdn(
        'images/icons/mobile_navigation/arrowback.svg'
      )})
      no-repeat center center transparent;
  }
`;

const ExpandedMenuTitleBlock = styled(TitleBlock)`
  height: 71px;
  line-height: 73px;
  border-bottom: solid 1px rgba(203, 220, 229, 0.5);
  h1 {
    font-size: 16px;
    font-weight: 600;
    color: #292a2b;
    line-height: inherit;
    padding: 0 64px 0 61px;
    text-align: left;
  }
`;

const TitleBackArrow: FC<TitleBackArrowProps> = ({ title, onClick }) => (
  <>
    <IconArrow onClick={onClick} />
    <ExpandedMenuTitleBlock title={title} color="black" />
  </>
);

export default TitleBackArrow;
