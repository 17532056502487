import Container from '@anm/components/Container';
import DiscountBanner from '@anm/components/pricing/DiscountBanner';
import { UserContext } from '@anm/components/user/UserProvider';
import { isGuestUser } from '@anm/helpers/user/isUser';
import { useContext, FC } from 'react';
import styled from 'styled-components';

import lfConfig from '../../../config';

export type PricingBannerSectionProps = { discount?: number };

const Wrapper = styled.div`
  margin: 30px 0 70px;
`;

const { waveUrl } = lfConfig;

const PricingBannerSection: FC<PricingBannerSectionProps> = ({ discount }) => {
  const { user } = useContext(UserContext);
  const isGuest = isGuestUser(user);

  if (!isGuest || !discount) return null;

  return (
    <Wrapper>
      <Container size={1000}>
        <a href={`${waveUrl}pricing`}>
          <DiscountBanner discount={discount * 100} />
        </a>
      </Container>
    </Wrapper>
  );
};

export default PricingBannerSection;
