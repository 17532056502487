import { useCallback, FC } from 'react';

import { Item, List } from './Wrapper';
import { useActiveItem } from './hooks';

type InlineSelectionProps = {
  list: string[];
  onChange: (i: number) => void;
  activeItem?: number;
};

const InlineSelection: FC<InlineSelectionProps> = ({ list, ...itemProps }) => {
  const [activeItem, handleItemClick] = useActiveItem(itemProps);

  return (
    <List>
      {list.map((item, i) => (
        <ItemWrapper
          {...{ item }}
          key={item}
          index={i}
          count={list.length}
          isActive={activeItem === i}
          onItemClick={handleItemClick}
        />
      ))}
    </List>
  );
};

type ItemWrapperProps = {
  item: string;
  count: number;
  index: number;
  isActive: boolean;
  onItemClick: (i: number) => void;
};

const ItemWrapper: FC<ItemWrapperProps> = ({
  item,
  index,
  onItemClick,
  ...otherProps
}) => {
  const handleClick = useCallback(() => onItemClick(index), []);

  return (
    <Item {...otherProps} onClick={handleClick}>
      {item}
    </Item>
  );
};

export default InlineSelection;
