import getFromCdn from '@anm/helpers/getFromCdn';
import { media } from '@anm/styles/helpers';
import { FC } from 'react';
import styled from 'styled-components';

export type DiscountBannerProps = { discount: number };

const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #292a2b;

  ${media.phone`
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
  `}
`;

const Offer = styled.div`
  margin-top: 5px;
  font-weight: 900;
  font-size: 32px;
  line-height: 44px;
  color: #292a2b;

  ${media.phone`
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    width: 250px;
  `}
`;

const Wrapper = styled.div`
  margin-bottom: 30px;
  border-radius: 20px;
  padding: 40px;
  text-align: left;
  height: 170px;
  width: 1000px;
  margin: auto;
  background: url(${getFromCdn('images/pricing/black-friday-50.png')}) no-repeat center / contain;

  ${media.phone`
    background: url(${getFromCdn('images/pricing/black-friday-50-mobile.png')}) no-repeat center / contain;
    height: 300px;
    width: 260px;
    padding: 30px;
    margin: 0 auto 30px;
  `}
`;

const DiscountBanner: FC<DiscountBannerProps> = ({ discount }) => (
  <Wrapper>
    <Title>Black Friday Limited Time Offer</Title>
    <Offer>{discount}% Off Annual Plans</Offer>
  </Wrapper>
);

export default DiscountBanner;
