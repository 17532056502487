import Avatar from '@anm/components/Avatar';
import { FC } from 'react';

import FlexContainer from '../../FlexContainer';

import AvatarWrapper from './AvatarWrapper';
import FeedbackAuthorWrapper from './FeedbackAuthorWrapper';
import { ClassicFeedbacksProps } from './FeedbackList';
import FeedbackTextDataWrapper from './FeedbackTextDataWrapper';
import FeedbackTextWrapper from './FeedbackTextWrapper';

const Title: FC<{ item: ClassicFeedbacksProps }> = ({ item }) =>
  item.link ? <a href={item.link}>{item.name}</a> : <span>{item.name}</span>;

const ClassicFeedbacks: FC<{ item: ClassicFeedbacksProps }> = ({ item }) => (
  <FlexContainer variant="between-on-laptop">
    <AvatarWrapper>
      <Avatar
        size={170}
        alt={item.name}
        href={item.link}
        src={`images/data/feedbacks/${item.avatar}`}
      />
    </AvatarWrapper>
    <FeedbackTextDataWrapper>
      <FeedbackTextWrapper>{item.twit}</FeedbackTextWrapper>
      <FeedbackAuthorWrapper>
        <Title {...{ item }} />
        <p>{item.company}</p>
        {item.tutorial && <a href={item.link}>{item.tutorial}</a>}
      </FeedbackAuthorWrapper>
    </FeedbackTextDataWrapper>
  </FlexContainer>
);

export default ClassicFeedbacks;
