import { FC } from 'react';
import styled from 'styled-components';

export type CameraSettingsBlockProps = { stream: MediaStream; className?: string };

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px;
  gap: 4px;

  background: #f4f6f7;
  border-radius: 10px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
`;

const Title = styled.div`
  font-weight: 400;
  margin-bottom: 5px;
`;

type Caps = {
  frameRate?: number;
  width?: number;
  height?: number;
};

const CameraSettingsBlock: FC<CameraSettingsBlockProps> = ({ stream, className }) => {
  const track = stream?.getVideoTracks()[0];

  const hasCaps = !!track['getCapabilities'];

  let caps: Caps;

  if (hasCaps) {
    const c = track.getCapabilities();
    caps = {
      frameRate: c.frameRate?.max, 
      width: c.width?.max, 
      height: c.height?.max 
    }
  } else {
    caps = track.getSettings();
  }

  return (
    <Wrapper className={className}>
      <Item>
        <Title>{hasCaps? 'Max frame rate:': 'Frame rate'}</Title>
        <div>{Math.floor(caps.frameRate || 0)}fps</div>
      </Item>
      <Item>
        <Title>{hasCaps? 'Max resolution:': 'Resolution:'}</Title>
        <div>
          {Math.floor(caps.width || 0)}x{Math.floor(caps.height || 0)} px
        </div>
      </Item>
    </Wrapper>
  );
};

export default CameraSettingsBlock;
