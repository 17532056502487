import { media } from '@anm/styles/helpers';
import { FC } from 'react';
import styled, { css } from 'styled-components';

export type TabProps = {
  title: string;
  route: string;
  isActive: boolean;
};

const tabsAdaptive = media.phone`
    max-width: calc((100% - 40px) / 3);
`;

const active = css`
  > a {
    color: #292a2b;
    border-bottom: 2px solid var(--blue_100);
    padding-bottom: 18px;
  }
`;
const Wrapper = styled.div<Pick<TabProps, 'isActive'>>`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-right: 40px;
  white-space: nowrap;

  &:last-of-type {
    margin-right: 0;
  }
  > a {
    color: #919eaa;
    padding-bottom: 20px;
    display: inline-block;
    vertical-align: bottom;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  ${tabsAdaptive};
  ${({ isActive }) => isActive && active}
`;

const Tab: FC<TabProps> = ({ title, route, isActive }) => (
  <Wrapper isActive={isActive}>
    <a href={route}>{title}</a>
  </Wrapper>
);

export default Tab;
