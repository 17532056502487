import * as analytic from '@anm/analytic';
import isMobileOrIPad from '@anm/helpers/is/isMobileOrIPad';
import noop from '@anm/helpers/noop';
import * as semrush from '@anm/helpers/semrush';
import { MouseEvent } from 'react';

import { TButtonProps, TDropdownOptions } from './types';

const isMobileDevice = isMobileOrIPad();

const getDesktopOnlyHandler = (handler: (e: MouseEvent<any>) => void) => (isMobileDevice ? noop : handler);

export const getDropdownOptionsList = ({
  workspace,
  renderUploadButton,
  renderLinkWrapper,
  onBlankVideo,
  onBlankImage,
  onNewRecording,
  onGenerateFromText,
  onStocksOpen,
  onGenerateFromLink
}: Omit<TDropdownOptions, 'currentFolderId'>): TButtonProps[] =>
  [
    isMobileDevice && {
      label: 'Start with:'
    },
    {
      label: 'Blank video',
      isDesktopOnly: true,
      icon: 'images/icons/blank_video_ic_V1.svg',
      handler: getDesktopOnlyHandler(onBlankVideo)
    },
    {
      label: 'Blank image',
      isDesktopOnly: true,
      icon: 'images/icons/blank_image_ic_V4.svg',
      handler: getDesktopOnlyHandler(onBlankImage)
    },
    {
      label: 'Video recording',
      icon: 'images/icons/video_recording_ic_V1.svg',
      handler: onNewRecording
    },
    renderUploadButton
      ? {
          label: 'Upload',
          icon: 'images/icons/upload_ic.svg',
          isDesktopOnly: true,
          renderComponent: () =>
            renderUploadButton(onClick => <div onClick={getDesktopOnlyHandler(onClick)}>Upload</div>)
        }
      : {
          label: '',
          key: 'empty-1'
        },
    !isMobileDevice && {
      label: 'Generate video with AI:'
    },
    !isMobileDevice && {
      label: 'Start by browsing:'
    },
    {
      label: 'From Text',
      isDesktopOnly: true,
      icon: 'images/icons/text_hover_ic.svg',
      handler: getDesktopOnlyHandler(onGenerateFromText)
    },
    {
      label: 'Templates',
      route: 'video-templates',
      icon: 'images/icons/templates_hover_ic.svg',
      renderComponent: () =>
        renderLinkWrapper ? (
          renderLinkWrapper(
            <a data-button="video templates" onClick={() => analytic.trackCreateVideoTemplatesChoosen()}>
              Templates
            </a>
          )
        ) : (
          <a
            data-button="video templates"
            href="/video-templates"
            onClick={() => analytic.trackCreateVideoTemplatesChoosen()}
          >
            Templates
          </a>
        )
    },
    {
      label: 'From Blog post',
      isDesktopOnly: true,
      icon: 'images/icons/blog_hover_ic.svg',
      handler: getDesktopOnlyHandler(onGenerateFromLink)
    },
    {
      label: 'Stock assets',
      isDesktopOnly: true,
      icon: 'images/icons/stocks_hover_ic.svg',
      handler: getDesktopOnlyHandler(onStocksOpen)
    },
    !isMobileDevice &&
      !semrush.isSemrushIframe() && {
        label: 'Import video from:'
      },
    !isMobileDevice &&
      !semrush.isSemrushIframe() && {
        label: '',
        key: 'empty-2'
      },
    !semrush.isSemrushIframe() && {
      label: 'YouTube',
      icon: 'images/icons/youtube_social_icon_red.png',
      href: `/convert/youtube-to-mp4?workspace=${workspace}`
    },
    !semrush.isSemrushIframe() && {
      label: 'Instagram',
      icon: 'images/icons/instagram_download.png',
      href: `/convert/instagram-to-mp4?workspace=${workspace}`
    }
  ].filter(v => v) as TButtonProps[];
