import ButtonLoader from '@anm/components/buttons/ButtonLoader';
import Image from '@anm/components/image/Image';
import { noop } from 'lodash/fp';
import { FC } from 'react';
import styled, { css } from 'styled-components';

const Title = styled.h5`
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
`;

const Description = styled.div`
  margin-right: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
`;

const Icon = styled(Image)`
  margin-right: 15px;
`;

const ButtonWrapper = styled(ButtonLoader)`
  min-width: 150px;
  margin-left: auto;
  padding: 0;

  color: #292a2b;
  border-color: transparent;
  background: #ffffff;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  ${({ disabled }) =>
    disabled &&
    css`
      color: #8b9499;
      cursor: default;
      background: #fff !important;
    `}
`;

const Wrapper = styled.div<{ disabled?: boolean }>`
  padding: 20px;
  display: flex;
  align-items: center;

  background: #f4f6f7;
  border-radius: 10px;

  :hover {
    background: #e9eef1;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background: #f4f6f7 !important;
    `}
`;

export type ActionItemProps = {
  icon: string;
  title: string;
  intent: string;
  buttonName: string;
  description: string;
  canEdit?: boolean;
  isPending?: boolean;
  onClick?: (intent: string) => void;
};

const ActionItem: FC<ActionItemProps> = ({
  intent,
  icon,
  title,
  isPending,
  description,
  canEdit = true,
  buttonName,
  onClick = noop
}) => {
  const handleClick = () => {
    onClick(intent);
  };
  const disabled = !canEdit;

  return (
    <Wrapper disabled={disabled}>
      <Icon src={icon} />
      <div>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </div>
      <ButtonWrapper
        newWindow
        disabled={disabled || isPending}
        color="gray"
        variant="white"
        size="medium"
        onClick={handleClick}
      >
        {isPending ? 'Opening' : buttonName}
      </ButtonWrapper>
    </Wrapper>
  );
};
export default ActionItem;
