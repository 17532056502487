import { MediaIds } from '@anm/hooks/media/useMediaStreams';
import { useCallback } from 'react';

import { MediaTesterFormFields } from '../../MediaTesterForm';

const useAutoSaveHandler = (createStreams: (ids: Partial<MediaIds>) => void) => {
  const handler = useCallback(
    ({ camera, microphone }: MediaTesterFormFields) => {
      createStreams({ webcamId: camera?.value, micId: microphone?.value });
    },
    [createStreams]
  );

  return handler;
};

export default useAutoSaveHandler;
