import styled from 'styled-components';

import { media } from '../../../styles/helpers';

const contentWrapperOnLaptop = media.laptop`
  margin-top: 0;
  text-align: left;
  margin-left: 33px;
  padding: 90px 0 36px 0;
`;

export const ContentWrapper = styled.div`
  color: #fff;
  margin-top: 10px;
  padding: 0 0 30px 0;
  text-align: center;
  ${contentWrapperOnLaptop};

  span {
    font-size: 34px;
    font-weight: 700;
    line-height: 44px;
  }

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    margin: 17px 0 25px 0;
  }
`;

export const ImageWrapper = styled.div`
  max-width: 600px;
  flex-shrink: 0;
  margin: 0 auto;
  position: relative;
`;
