import { FC } from 'react';

import vtConfig from '../../config';
import LogoutButton from '../auth/LogoutButton';

const menuLinkList = [
  {
    title: 'My projects',
    link: `${vtConfig.waveUrl}videos`
  },
  {
    title: 'Profile',
    link: `${vtConfig.waveUrl}account/profile`
  },
  {
    title: 'Subscription',
    link: `${vtConfig.waveUrl}subscription`
  }
];

type MenuItem = {
  title: string;
  link: string;
};

const UserMenuListItem: FC<MenuItem> = ({ title, link }) => (
  <li key={title}>
    <a href={link}>{title}</a>
  </li>
);

const UserMenuLinkList: FC = ({ children }) => (
  <ul>
    {children}

    {menuLinkList.map(UserMenuListItem)}

    <li key="logout-button">
      <LogoutButton />
    </li>
  </ul>
);

export default UserMenuLinkList;
