import { ApiError } from '@anm/api';
import isApiError from '@anm/api/helpers/isApiError';
import NotificationModal from '@anm/components/modals/NotificationModal';
import { FC } from 'react';

export type MediaLocalError = {
  title?: string;
  description?: string;
};

export type PossibleError = ApiError | string | MediaLocalError;

export type ErrorProps = { error: PossibleError };

const isError = (error: PossibleError): error is MediaLocalError => !!(error as MediaLocalError).title;

const getError = (error: PossibleError): MediaLocalError => {
  const title = 'Oops, something went wrong!';
  switch (true) {
    case isApiError(error) && (error.message === 'no response' || error.code >= 500):
      return { title, description: 'We are working on fixing the problem. Please try again later.' };

    case isApiError(error):
      return { title, description: (error as ApiError).message };

    case isError(error):
      return error as MediaLocalError;

    default:
      return { title, description: error as string };
  }
};

const Error: FC<ErrorProps> = ({ error }) => {
  const { title, description } = getError(error);

  return (
    <NotificationModal
      type="error"
      title={title}
      variant="aside"
      timeout={30 * 1000}
      description={description}
      onModalClose={console.log}
    />
  );
};
export default Error;
