import { TutorialProps } from '.';

export const getFilteredTutorials = (
  tutorials: TutorialProps[],
  searchQuery: string
) => {
  if (!searchQuery) return tutorials;

  const filteredTutorials = tutorials.filter(t =>
    t.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return filteredTutorials;
};
