import { PageName, ReviewMap, Snippet } from './types';
export * from './types';

const mainPageReview = {
  review: {
    '@type': 'Review',
    reviewRating: {
      '@type': 'Rating',
      ratingValue: 5
    },
    author: {
      '@type': 'Person',
      name: 'Travis W.'
    },
    reviewBody:
      'Wave video has helped me create amazing videos that help me to grow my business. THANK YOU. the software is pretty easy to get to know.'
  }
};

const onlineVideoEditorPageReview = {
  review: {
    '@type': 'Review',
    reviewRating: {
      '@type': 'Rating',
      ratingValue: 5
    },
    author: {
      '@type': 'Person',
      name: 'Travis W.'
    },
    reviewBody:
      'I like how easy it is to create videos. I like how it will build your audience and drive your business. I like that there are multiple templates to choose from.'
  }
};

const videoHostingPageReview = {
  review: {
    '@type': 'Review',
    reviewRating: {
      '@type': 'Rating',
      ratingValue: 5
    },
    author: {
      '@type': 'Person',
      name: 'Veronica Jeans'
    },
    reviewBody:
      'I have been using this tool for the last couple of years for myself and my clients, for Ads, posts, blogs and websites. They truly make it easy to post online or to embed anywhere you want to. And now with the hosting platform, it is even better.'
  }
};

const pricingPageReview = {
  review: {
    '@type': 'Review',
    reviewRating: {
      '@type': 'Rating',
      ratingValue: 5
    },
    author: {
      '@type': 'Person',
      name: 'Anita Wong'
    },
    reviewBody:
      'Their software is AMAZING. They are a very innovative team and are always updating their wave.video editor and enhancing it for better user experience. '
  }
};

const calendarAndVideoTemplatesPagesReview = {
  review: {
    '@type': 'Review',
    reviewRating: {
      '@type': 'Rating',
      ratingValue: 4.9
    },
    author: {
      '@type': 'Person',
      name: 'Mari Smith'
    },
    reviewBody:
      'there’s no better tool for creating stunning social videos than Wave.video. '
  }
};

export const reviewNameMap: ReviewMap = {
  '/': mainPageReview,
  '/main': mainPageReview,
  '/pricing': pricingPageReview,
  '/video-hosting': videoHostingPageReview,
  '/calendar': calendarAndVideoTemplatesPagesReview,
  '/social-calendar': calendarAndVideoTemplatesPagesReview,
  '/online-video-editor': onlineVideoEditorPageReview,
  '/online-video-maker': onlineVideoEditorPageReview,
  '/video-captioning': onlineVideoEditorPageReview,
  '/tools/audio-editing/subtitler': onlineVideoEditorPageReview,
  '/assets/effects/video-intro-maker': onlineVideoEditorPageReview,
  '/assets/effects/animated-text': onlineVideoEditorPageReview,
  '/tools/video-marketing/instagram': onlineVideoEditorPageReview,
  '/tools/video-editing/merge-video-clips': onlineVideoEditorPageReview,
  '/assets/stock/free-videos': onlineVideoEditorPageReview,
  '/facebook-cover-video': onlineVideoEditorPageReview,
  '/assets/graphics/lower-third': onlineVideoEditorPageReview,
  '/how-to-make-facebook-video': onlineVideoEditorPageReview,
  '/video-templates': calendarAndVideoTemplatesPagesReview
};

export const hasSnippetForCurrentPage = (
  pageName: string | PageName
): pageName is PageName => !!reviewNameMap[pageName as PageName];

export const reviewSnippet: Partial<Snippet> = {
  aggregateRating: {
    '@type': 'AggregateRating',
    bestRating: 5,
    ratingCount: 337,
    ratingValue: 4.9
  },
  offers: {
    '@type': 'Offer',
    price: 0,
    priceCurrency: 'USD'
  }
};
