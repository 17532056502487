import { FC } from 'react';
import { branch, renderComponent } from 'recompose';

import EnvHostingPlayer from '../../EnvHostingPlayer';
import LandingVideo from '../../landing/LandingVideo';

import { VideoMediaProps } from './types';

const WistiaPlayer: FC<VideoMediaProps> = props => (
  <LandingVideo {...props} autoplay />
);

const WavePlayer: FC<VideoMediaProps> = ({ videoId }) => (
  <EnvHostingPlayer videoId={videoId!} />
);

const isWistiaPlayer = ({ wistiaId }: VideoMediaProps) => !!wistiaId;

export default branch(
  isWistiaPlayer,
  renderComponent(WistiaPlayer)
)(WavePlayer);
