import Loader from '@anm/components/Loader';
import { UserContext } from '@anm/components/user/UserProvider';
import VideoAvatar from '@anm/components/video/VideoAvatar';
import { memo, useContext, FC } from 'react';

import { LoaderWrapper, Video } from './Wrapper';
import { useMediaRef } from './hooks';

export type StartupBroadcastContentProps = {
  avatar?: string;
  userName?: string;
  isMirror?: boolean;
  isSettings?: boolean;
  videoStream?: MediaStream | null;
};

const Content: FC<StartupBroadcastContentProps> = ({ avatar, userName, isSettings, videoStream, isMirror = false }) => {
  const { user } = useContext(UserContext);
  const videoRef = useMediaRef<HTMLVideoElement>(videoStream);

  switch (true) {
    case videoStream === null:
    case videoStream === undefined:
      return <VideoAvatar size={120} avatar={avatar} name={user?.displayName || userName || ''} />;

    case videoStream?.active:
      return <Video ref={videoRef} autoPlay playsInline muted controls={false} {...{ isMirror, isSettings }} />;

    default:
      return (
        <LoaderWrapper>
          <Loader color="blue" size="large" />
        </LoaderWrapper>
      );
  }
};

const StartupBroadcastContent = memo(Content, (prevProps, nextProps) => {
  const isVideoStreamSame =
    ((nextProps.videoStream === null || nextProps.videoStream === undefined) &&
      prevProps.videoStream === nextProps.videoStream) ||
    (!!nextProps.videoStream?.id && prevProps.videoStream?.id === nextProps.videoStream?.id);

  const isUserNameSame = prevProps.userName === nextProps.userName;
  const isMirrorSame = prevProps.isMirror === nextProps.isMirror;
  const isAvatarSame = prevProps.avatar === nextProps.avatar;

  return isVideoStreamSame && isUserNameSame && isMirrorSame && isAvatarSame;
});

export default StartupBroadcastContent;
