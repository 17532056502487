import axios, { AxiosError, AxiosResponse, Cancel, InternalAxiosRequestConfig } from 'axios';

import { ApiError } from '../types';

type CreateErrorHandlerProps = { isDebug?: boolean };

const getInfoFromConfig = ({ url, baseURL, method, headers, params }: InternalAxiosRequestConfig) => ({
  url,
  method,
  params,
  headers,
  baseURL
});

const getErrorFromSuccessResponse = (err: AxiosResponse<ApiError>): ApiError =>
  err.data || {
    code: err.status || 444,
    message: err.statusText || 'no response'
  };

const getErrorFromFailureResponse = (err: AxiosError<unknown>) => ({
  data: err.response?.data,
  code: err.code || err.response?.status || 444,
  message: err.response?.statusText || err.message || 'no response'
});

const isAxiosError = (err: AxiosError<ApiError> | AxiosResponse<ApiError>): err is AxiosError<ApiError> =>
  (err as AxiosError<ApiError>).isAxiosError;

const isCanceled = (err: AxiosError<ApiError> | AxiosResponse<ApiError> | Cancel): err is Cancel =>
  err instanceof axios.Cancel;

const createErrorHandler = ({ isDebug }: CreateErrorHandlerProps) => (
  err: AxiosError<ApiError> | AxiosResponse<ApiError>
) => {
  const details = isCanceled(err)
    ? { code: 499, ...err }
    : isAxiosError(err)
    ? getErrorFromFailureResponse(err)
    : getErrorFromSuccessResponse(err);

  if (isDebug) {
    const message = `Api error - details: ${JSON.stringify({
      ...details,
      ...(err.config && getInfoFromConfig(err.config))
    })}`;
    console.error(message, new Error());
  }

  return Promise.reject(details);
};

export default createErrorHandler;
