import isServer from '@anm/helpers/is/isServer';
import localStorage from '@anm/helpers/localStorage';

const isAnimatronState = (key: string) => /^animatron/.test(key);
const clearAnimatronKey = (key: string) => isAnimatronState(key) && localStorage().removeItem(key);

const clearAnimatronState = () => Object.keys(localStorage()).forEach(clearAnimatronKey);

const resetAppState = () => {
  if (isServer()) return;

  clearAnimatronState();
};

export default resetAppState;
