import * as analytic from '@anm/analytic';
import { MediaInfoFormat, MediaType } from '@anm/api/modules/youtube';
import { useCallback } from 'react';

import { StartDownloadProps } from '../../DownloadMedia';
import { getResolutionByFormatId } from '../helpers';

import useDirectDownloader from './useDirectDownloader';
import useRequestDownloader from './useRequestDownloader';

type UseStartDownloadProps = {
  url?: string;
  mediaType: MediaType;
  mediaFormats: MediaInfoFormat[];
  title?: string;
  duration?: number;
};

const useStartDownload = ({ mediaFormats, mediaType, url, title, duration }: UseStartDownloadProps) => {
  const downloadDirect = useDirectDownloader({ mediaFormats });
  const downloadRequest = useRequestDownloader({
    url,
    mediaFormats,
    type: mediaType,
    title
  });

  const startDownload = useCallback(
    ({ formatId, mediaType, isVideoOnlyFormat }: StartDownloadProps) => {
      analytic.trackYoutubeDownload({
        url,
        kind: mediaType,
        duration,
        resolution: getResolutionByFormatId(formatId, mediaFormats)
      });

      mediaType === 'audio' || isVideoOnlyFormat ? downloadRequest(formatId) : downloadDirect(formatId);
    },
    [downloadDirect, downloadRequest, duration]
  );

  return startDownload;
};

export default useStartDownload;
