import { MediaProps } from '@anm/components/Accordion';
import { branch, compose, renderComponent } from 'recompose';

import EnvHostingPlayer from '../../../EnvHostingPlayer';

import Image from './Image';
import WistiaPlayer from './WistiaPlayer';

const isWistiaPlayer = ({ wistiaId }: MediaProps) => !!wistiaId;
const isImage = ({ imgUrl }: MediaProps) => !!imgUrl;

const Media = compose(
  branch(isWistiaPlayer, renderComponent(WistiaPlayer)),
  branch(isImage, renderComponent(Image))
)(EnvHostingPlayer);

export default Media;
