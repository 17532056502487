import { SUMO_SITE_ID_WAVE_UNSCREEN_RESTREAM } from '@anm/constants/sumo';
import isServer from '@anm/helpers/is/isServer';

const ALLOWED_PAGES = ['monetize-live-streams'];

const canLoadSumo = (link: string) => {
  if (isServer()) return false;

  const pathName = new URL(link).pathname;
  const pageName = pathName
    .split('/')
    .slice(-2)
    .join('');

  return ALLOWED_PAGES.includes(pageName);
};

const injectScript = (u: Document, m: string, o: string, j?: any, v?: any) => {
  j = u.createElement(m);
  v = u.getElementsByTagName(m)[0];
  j.async = 1;
  j.src = o;
  j.dataset.sumoSiteId = SUMO_SITE_ID_WAVE_UNSCREEN_RESTREAM;
  v?.parentNode.insertBefore(j, v);
};

export let isLoaded = false;

export const setIsLoaded = (val: boolean) => (isLoaded = val);

const loadSumo = (link: string) => {
  if (!canLoadSumo(link) || isLoaded) return;

  sumoHandlers.injectScript(document, 'script', '//load.sumo.com/');

  setIsLoaded(true);
};

const sumoHandlers = {
  loadSumo,
  injectScript
};

export default sumoHandlers;
