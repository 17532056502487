import styled, { css } from 'styled-components';

import { SectionVariants } from '..';
import { media } from '../../../../styles/helpers';

type VideosWrapperPros = {
  design: SectionVariants;
  isSingle?: boolean;
};

const DefaultVideosWrapperOnTablet = media.tablet`
    margin: 51px auto 0;
`;

const NotLastListItem = media.tablet`
    margin-bottom: 0;
`;

const DefaultListItemOnTablet = media.tablet`
    width: 31%;
    max-width: 290px;
    margin: 0;
`;

const NewListItemOnTablet = media.tablet`
    width: 32%;
    max-width: 272px;
    margin: 0;
    
`;

const NewVideosWrapperOnTablet = media.tablet`
    margin: 70px auto 0;
`;

const variants: { [key in SectionVariants]: ReturnType<typeof css> } = {
  new: css`
    margin: 26px auto 0;
    max-width: 880px;
    ${NewVideosWrapperOnTablet};

    li {
      ${NewListItemOnTablet};

      &:not(:last-child) {
        margin-bottom: 24px;
        ${NotLastListItem};
      }
    }
  `,
  default: css`
    max-width: 1000px;
    margin: 20px auto 0;
    ${DefaultVideosWrapperOnTablet};

    li {
      max-width: 80%;
      margin: 0 auto;
      ${DefaultListItemOnTablet};

      &:not(:last-child) {
        margin-bottom: 30px;
        ${NotLastListItem};
      }
    }
  `
};

const VideosWrapper = styled.div<VideosWrapperPros>`
  ${({ design }) => variants[design]};

  li {
    position: relative;

    ${({ isSingle }: VideosWrapperPros) =>
      isSingle &&
      css`
        width: 100%;
        max-width: 720px;
        margin: 0 auto;
      `};
  }
`;

export default VideosWrapper;
