export const getInitials = (name: string) =>
  name
    .split(' ')
    .map(word => word[0])
    .join('')
    .slice(0, 2)
    .toUpperCase();

export const getRandomColor = () => {
  const colors = ['#E3ECF1', '#C4EAFF', '#FEF791', '#FFD5C6', '#CFF3CA', '#E9D0FF'];

  const randomIndex = Math.floor(Math.random() * colors.length);

  return colors[randomIndex];
};
