import HostingPlayer, { Manifest } from '@anm/hosting-player';
import { useContext, FC } from 'react';

import EnvContext from '../../store/EnvContext';
import { VideoDataContext } from '../../store/VideoDataContext';

type EnvPlayerProps = {
  videoId: string;
  playerWidth?: number;
  onInit?(): void;
};

const EnvPlayer: FC<EnvPlayerProps> = props => {
  const { env } = useContext(EnvContext);
  const videoData = useContext(VideoDataContext);

  const manifest = videoData[props.videoId] as Manifest;

  return <HostingPlayer {...{ env, manifest, ...props }} />;
};

export default EnvPlayer;
