import { media } from '@anm/styles/helpers';
import styled from 'styled-components';

type TabsPanelItemProps = {
  isActive: boolean;
};

const tabsWrapperMobile = media.phone`
  justify-content: center;
`;

export const TabsPanelWrapper = styled.ul`
  display: flex;
  align-items: center;
  padding: 12px 0 38px;
  ${tabsWrapperMobile};
`;

export const PanelWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const TabsContentWrapper = styled.div``;

const tabItemMobile = media.phone`
  font-size: 14px;

  &:not(:last-child) {
    margin-right: 20px;
  }
`;

export const TabsPanelItem = styled.li<TabsPanelItemProps>`
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  line-height: 19px;
  color: ${({ theme, isActive }) => (isActive ? theme.textColor : theme.addMediaTabTextColor)};

  &:not(:last-child) {
    margin-right: 30px;
  }
  ${tabItemMobile};
`;
