import Container from '@anm/components/Container';
import { FC } from 'react';

import TemplateItem from './Item';
import ListWrapper from './Wrapper';

export type Template = {
  logo: string;
  href: string;
  title: string;
  background: string;
};

export type TemplatePageListProps = {
  list: Template[];
};

const TemplatePageList: FC<TemplatePageListProps> = ({ list }) => (
  <ListWrapper>
    <Container>
      <ul>
        {list.map(({ href, ...otherProps }) => (
          <TemplateItem key={href} {...{ ...otherProps, href }} />
        ))}
      </ul>
    </Container>
  </ListWrapper>
);

export default TemplatePageList;
