import { getFallback, getSrc, isWebp } from '@anm/helpers/image';
import { useCallback, useEffect, useState, Dispatch, FC, MouseEvent, RefObject, SetStateAction } from 'react';

export type ImageProps = {
  src: string;
  alt?: string;
  width?: string;
  height?: string;
  className?: string;
  defaultSrc?: string;
  draggable?: boolean;
  imageRef?: Dispatch<SetStateAction<Element | null>> | RefObject<HTMLImageElement | null>;
  onLoad?(): void;
  onError?(): void;
  onClick?: (e: MouseEvent<HTMLImageElement>) => void;
  onAnimationEnd?(): void;
  itemProp?: string;
};

export type ImageState = {
  src: string;
};

const RegularImage: FC<ImageProps> = ({ imageRef, ...otherProps }) => (
  <img ref={imageRef as RefObject<HTMLImageElement>} {...otherProps} />
);

const WebpImage: FC<ImageProps> = ({ src: imgSource, imageRef, ...otherProps }) => (
  <picture>
    <source type="image/webp" srcSet={imgSource} />
    <img ref={imageRef as RefObject<HTMLImageElement>} src={getFallback(imgSource)} {...otherProps} />
  </picture>
);

const Image: FC<ImageProps> = ({ src, draggable = true, ...otherProps }) => {
  const [imgSource, setImgSource] = useState(getSrc(src));

  const handleError = useCallback(() => {
    if (!src) return;

    setImgSource(getSrc(src));
  }, [src]);

  useEffect(() => {
    setImgSource(getSrc(src));
  }, [src]);

  return (
    <>
      {isWebp(src) ? (
        <WebpImage src={imgSource} {...{ draggable, ...otherProps }} />
      ) : (
        <RegularImage src={imgSource} onError={handleError} {...{ draggable, ...otherProps }} />
      )}
    </>
  );
};

export default Image;
