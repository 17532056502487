import { flexCenter, media, Column } from '@anm/styles/helpers';
import styled from 'styled-components';

export const FormWrapper = styled.form`
  width: 100%;
  max-width: 459px;
`;

export const Label = styled.label<{ isDark?: boolean }>`
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  color: ${({ isDark }) => (isDark ? '#fff' : '#292a2b')};
`;

export const FormGroup = styled(Column)`
  width: inherit;
  margin: 0 auto;
`;

const fieldWrapperOnTablet = media.tablet`
  height: 40px;
  width: inherit;
  margin-top: 12px;
  margin-bottom: 31px;
`;

export const FieldWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 23px;
  min-height: 48px;
  align-self: center;
  ${fieldWrapperOnTablet};
`;

const inputOnMobile = media.phone`
  font-size: 14px;
  max-width: unset;
`;

export const Input = styled.input`
  margin: 0;
  width: 100%;
  display: flex;
  flex: 1;
  color: #292a2b;
  font-size: 16px;
  max-width: 290px;
  background: #fff;
  padding: 12px 15px;
  border: 1px solid #ccdde6;
  border-radius: 5px 0px 0px 5px;
  ::placeholder {
    color: #919eaa;
  }
  ${inputOnMobile};
`;

const sendButtonOnMobile = media.phone`
  padding: 0 20px;
`;

export const SendButton = styled.button.attrs({
  type: 'submit'
})`
  ${flexCenter};
  margin: 0;
  cursor: pointer;
  padding: 0 30px;
  background: #f5f7fa;
  border-radius: 0px 5px 5px 0px;
  border: 1px solid #ccdde6;
  border-left: none;
  &:disabled {
    cursor: not-allowed;
  }
  span {
    margin-right: 10px;
  }
  ${sendButtonOnMobile};
`;

export const ButtonText = styled.span`
  color: #292a2b;
  font-size: 16px;
  font-weight: bold;
`;
