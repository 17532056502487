import isServer from '@anm/helpers/is/isServer';
import useTimer from '@anm/hooks/useTimer';
import { FC } from 'react';

import MediaError from '../MediaError';

import { LoaderWrapper } from './Wrapper';

export type MediaLoaderProps = {};

const MediaLoader: FC<MediaLoaderProps> = () => {
  const [waitForMedia] = useTimer({ timeout: 5 * 1000, startByDefault: true });

  switch (true) {
    case isServer(): {
      return <LoaderWrapper size="large" color="blue" />;
    }

    case waitForMedia: {
      return (
        <>
          <LoaderWrapper size="large" color="blue" />
          <MediaError hideIcon={true} title="Please connect your camera and microphone" />
        </>
      );
    }

    default: {
      return <MediaError title="Camera or microphone not found, please check your devices and try again" />;
    }
  }
};

export default MediaLoader;
