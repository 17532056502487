import TitleBlock, { TitleDescription } from '@anm/components/TitleBlock';
import { TooltipContent } from '@anm/components/Tooltip';
import Image from '@anm/components/image/Image';
import { flexCenter, Column, Row } from '@anm/styles/helpers';
import styled  from 'styled-components';

type PreviewProps = {
  width: number;
  height: number;
};

export const ColumnWrapper = styled(Column)`
  margin-right: 10px;
  & > div {
    margin-top: auto;
    ${TooltipContent} {
      left: 19%;
      right: unset;
      transform: translateY(-100%);
    }
  }
`;

export const ColumnTitle = styled(TitleBlock)`
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 20px;
    line-height: 32px;
    text-align: center;
  }
  ${TitleDescription} {
    color: #919eaa;
    font-size: 14px;
    min-height: 66px;
    line-height: 22px;
    text-align: center;
    margin: 5px -10px 23px;
  }
`;

export const Preview = styled.div<PreviewProps>`
  ${flexCenter};
  font-size: 20px;
  line-height: 24px;
  border-radius: 5px;
  background: #ebeef5;
  margin-bottom: 22px;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  & > p {
    max-width: 150px;
  }
`;

export const InnerContent = styled.div`
  ${flexCenter};
  padding: 10px;
  color: #292a2b;
  cursor: pointer;
  margin-top: auto;
  position: relative;
  border-radius: 10px 10px 12px;
  flex-direction: column;
  & > img {
    top: 13px;
    right: 12px;
    position: absolute;
  }
  &:hover {
    background: rgba(32, 171, 210, 0.1);
  }
`;

export const Content = styled(Row)`
  display: flex;
  flex-wrap: nowrap;
  ${ColumnWrapper}:last-of-type {
    margin: 0;
  }
`;

export const MainTitle = styled(TitleBlock)`
  margin-top: 24px;
  text-align: center;
  margin-bottom: 25px;
  h2 {
    font-size: 32px;
    line-height: 48px;
    font-weight: bold;
    font-style: normal;
  }
`;

export const Icon = styled(Image)`
  max-height: 21px;
  margin-right: 12.5px;
`;

export const ImagesContainer = styled(Row)`
  ${Icon}:last-of-type {
    margin: 0;
  }
`;

const Wrapper = styled.div`
  pointer-events: all;
  padding: 10px 31px 44px;
`;

export default Wrapper;
