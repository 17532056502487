const textToVideoCheckList = [
  {
    label: 'Text in English',
    pattern: /[a-zA-Z]/
  },
  {
    label: 'At least 3 sentences',
    pattern: /(([A-Za-z0-9,;:@#$%'\-&"–’\s])+[.?!\n\r\s]+){3,}/
  }
];

export default textToVideoCheckList;