import isValuesEmpty from '@anm/helpers/is/isValuesEmpty';
import { CommonStats, HostingStats, User, UserPartial } from 'user';

import { Request } from '../../types';

import { ChangePasswordData, ConfirmEmailRequestData, GetTokenParams } from './types';
export * from './types';

export const changePassword = (request: Request) => (data: ChangePasswordData) =>
  request<User>({
    data,
    url: 'profile/password',
    method: 'PUT'
  });

export const getUser = (request: Request) => () =>
  request<User>({
    url: 'profile',
    method: 'GET'
  });

export const getToken = (request: Request) => ({ userId, secret }: GetTokenParams) =>
  request<string>({
    url: `profile/request-token/${userId}?product=WAVE`,
    headers: {
      'Animatron-Internal-Secret': secret
    },
    method: 'GET'
  });

export const deleteAccount = (request: Request) => () =>
  request({
    url: 'profile',
    method: 'DELETE'
  });

export const uploadAvatar = (request: Request) => (file: File) => {
  const fd = new FormData();
  fd.append('file', file);

  return request<User>({
    url: 'profile/upload-avatar',
    data: fd,
    method: 'PUT'
  });
};

export const updateUser = (request: Request) => async ({ avatar, ...otherProps }: UserPartial) => {
  avatar && (await uploadAvatar(request)(avatar));
  return isValuesEmpty(otherProps)
    ? getUser(request)()
    : request<User>({
        method: 'PUT',
        url: 'profile',
        data: otherProps
      });
};

export const checkEmail = (request: Request) => (email: string) =>
  request<User>({
    url: `profile/check-email/${email}`,
    method: 'GET'
  });

export const updateEmail = (request: Request) => (data: ConfirmEmailRequestData) =>
  request<User>({
    data,
    url: 'confirmations/email/request',
    method: 'POST'
  });

export const resendEmail = (request: Request) => () =>
  request<User>({
    url: 'confirmations/email/resend',
    method: 'POST'
  });

export const confirmEmail = (request: Request) => (hash: string) =>
  request<string>({
    url: `confirmations/email/confirm/${hash}`,
    method: 'POST'
  });

export const getUserEmbedStats = (request: Request) => () =>
  request<CommonStats>({
    url: 'profile/stats/embed',
    method: 'GET'
  });

export const getUserTranscribeStats = (request: Request) => () =>
  request<CommonStats>({
    url: 'profile/stats/transcribe',
    method: 'GET'
  });

export const getUserStorageStats = (request: Request) => () =>
  request<CommonStats>({
    url: 'profile/stats/storage',
    method: 'GET'
  });

export const getUserHostingStats = (request: Request) => () =>
  request<HostingStats>({
    url: 'profile/stats/hosting',
    method: 'GET'
  });

export const getUserTextToSpeechStats = (request: Request) => () =>
  request<CommonStats>({
    url: 'profile/stats/text2speech',
    method: 'GET'
  });

export const updateYoutubeChannelsList = (request: Request) => (channels: string[]) =>
  request({
    method: 'POST',
    url: 'profile/meta/youtubeChannels',
    data: channels
  });
