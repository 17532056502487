import { useEffect, useRef, useState } from 'react';

export const useTextFieldFocusStatus = <
  T extends HTMLInputElement | HTMLTextAreaElement
>(
  isFieldFocused: boolean
) => {
  const [isFocused, setIsFocused] = useState(isFieldFocused);
  const [isPlaceholderShown, setIsPlaceholderShown] = useState(false);
  const fieldRef = useRef<T>(null);

  useEffect(() => {
    if (!fieldRef.current) return;

    const isPlaceholderShown = !isFocused && !fieldRef.current.value.length;
    setIsPlaceholderShown(isPlaceholderShown);
  }, [isFocused]);

  useEffect(() => {
    if (!isFieldFocused) return;

    fieldRef.current?.focus();
  }, [isFieldFocused]);

  return [fieldRef, isFocused, setIsFocused, isPlaceholderShown] as const;
};
