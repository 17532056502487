import noop from '@anm/helpers/noop';
import { useEffect, useState, FC } from 'react';

import CodeSample from '../../CodeSample';
import { EmbedButtonProps } from '../EmbedButton';

export type EmbedButtonCodeProps = EmbedButtonProps & {
  onChange: (code: string) => void;
};

const getCode = ({ color, size, format, apiKey }: EmbedButtonProps) => `<script>
(function (d,w,e,c,a) {
    var cf = w[c] = w[c] || {}; cf.apiKey = a;
    var sc=d.createElement(e),s=d.getElementsByTagName(e)[0];
    sc.async=!0;
    sc.src=cf.embed || 'https://wave.video/editor/embed.js';
    s.parentNode.insertBefore(sc, s);
})(document, window, 'script', 'waveconfig', '${apiKey}');
</script>
<span class="wave-video-button ${size} ${color}"
data-video-format="${format}">Make video</span>`;

const EmbedButtonCode: FC<EmbedButtonCodeProps> = ({
  onChange = noop,
  ...otherProps
}) => {
  const [code, setCode] = useState(getCode(otherProps));

  useEffect(() => {
    setCode(getCode(otherProps));
  });

  useEffect(() => {
    onChange(code);
  }, [code]);

  return <CodeSample code={code} />;
};

export default EmbedButtonCode;
