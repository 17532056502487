import { MediaType } from '@anm/api/modules/youtube';
import ButtonLoader from '@anm/components/buttons/ButtonLoader';
import { useCallback, useContext, useEffect, useState, FC } from 'react';
import styled from 'styled-components';

import Dropdown from './Dropdown';
import UpgradeAlert from './UpgradeAlert';
import { MediaDownloaderContext } from './context/MediaDownloaderProvider';
import { mapFormatsToOptions } from './context/helpers';

export type DownloadMediaOptions = ReturnType<typeof mapFormatsToOptions>;
export type StartDownloadProps = {
  formatId: string;
  mediaType: MediaType;
  isVideoOnlyFormat: boolean;
};
export type DownloadMediaProps = {
  mediaType: MediaType;
  options: DownloadMediaOptions;
  isSaving?: boolean;
  startDownload: (props: StartDownloadProps) => void;
  onCanDownloadChange?: (canDownload: boolean) => void;
};

const Title = styled.h4`
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  color: #292a2b;
`;

const Wrapper = styled.div`
  padding: 20px;
  border: 1px solid rgba(0, 56, 87, 0.2);
  border-radius: 10px;
  display: flex;
  align-items: center;
`;

const mapMediaTypeToTitle = { audio: 'Convert and download .mp3 audio', video: 'Select .mp4 resolution' } as {
  [key in MediaType]: string;
};

export const getDefaultOption = (options: DownloadMediaOptions) =>
  options.find(({ value: { isVideoOnlyFormat } = {} }) => !isVideoOnlyFormat) as DownloadMediaOptions[0] | undefined;

const DownloadMedia: FC<DownloadMediaProps> = ({
  mediaType,
  options,
  isSaving,
  onCanDownloadChange,
  startDownload
}) => {
  const { mediaInfoState } = useContext(MediaDownloaderContext);

  const defaultOption = getDefaultOption(options);
  const [currentOption, setCurrentOption] = useState(defaultOption);
  const isVideoType = mediaType === 'video';
  const canDownload = !isVideoType || !currentOption?.value?.shouldUpgrade;
  const formatId = currentOption?.value?.format_id;

  const handleDownload = useCallback(() => {
    formatId && startDownload({ formatId, mediaType, isVideoOnlyFormat: !!currentOption?.value?.isVideoOnlyFormat });
  }, [formatId, startDownload]);

  useEffect(() => {
    onCanDownloadChange?.(canDownload);
  }, [canDownload]);

  useEffect(() => {
    setCurrentOption(defaultOption);
  }, [!!defaultOption]);

  return (
    <div>
      <Wrapper>
        <Title>{mapMediaTypeToTitle[mediaType]}</Title>
        {isVideoType && currentOption && (
          <Dropdown current={currentOption} options={options} onChange={setCurrentOption} />
        )}
        <ButtonLoader
          disabled={!canDownload || mediaInfoState.isPending}
          isGrayPending
          isPending={isSaving}
          variant="light-blue"
          size="medium"
          onClick={handleDownload}
        >
          Download
        </ButtonLoader>
      </Wrapper>
      {!canDownload && <UpgradeAlert />}
    </div>
  );
};
export default DownloadMedia;
