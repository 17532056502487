import Image from '@anm/components/image/Image';
import * as semrush from '@anm/helpers/semrush';
import { FC } from 'react';

import LogoHolder from './LogoHolder';
import { LogoText } from './Wrapper';
import { useLogoData } from './hooks';
import { LogoProps } from './types';

export * from './types';

const Logo: FC<LogoProps> = ({ link, variant, waveUrl, studioUrl, className }) => {
  const [href, iconSrc, alt, textLogo] = useLogoData({
    link,
    waveUrl,
    variant,
    studioUrl
  });

  const isLogoText = textLogo && semrush.isSemrushIframe();

  return (
    <LogoHolder {...{ href, className }}>
      {isLogoText ? <LogoText>{textLogo}</LogoText> : <Image src={iconSrc} alt={alt} />}
    </LogoHolder>
  );
};

export default Logo;
