import {
  IconArrow,
  MyAccount,
  MyAccountMenuItem
} from '@anm/components/header/MobileNavigation/Wrapper';
import { DOMAttributes, FC } from 'react';

import UserAvatar from '../../UserAvatar';
import AuthUserMenuBlock from '../../auth/AuthUserMenuBlock';

export type MobileNavigationUserBlockProps = DOMAttributes<HTMLDivElement> & {};

const MobileNavigationUserBlock: FC<MobileNavigationUserBlockProps> = ({
  onClick
}) => (
  <MyAccountMenuItem>
    <UserAvatar />
    <MyAccount onClick={onClick}>
      <span>Account</span>
      <IconArrow />
    </MyAccount>
  </MyAccountMenuItem>
);

export type UserMenuProps = {
  onUserBlockClick(): void;
};

const UserMenu: FC<UserMenuProps> = ({ onUserBlockClick }) => (
  <AuthUserMenuBlock
    size="upper-medium"
    variant="white-blue"
    renderUserMenuBlock={() => (
      <MobileNavigationUserBlock onClick={onUserBlockClick} />
    )}
  />
);

export default UserMenu;
