import Button from '@anm/components/buttons/Button';
import {
  DesktopNavigation,
  MobileMenuButtonWrapper,
  NavigationLinkProps,
  NavigationVariants,
  NavigationWrapper
} from '@anm/components/header';
import MobileNavigation from '@anm/components/header/MobileNavigation';
import NavigationList from '@anm/components/header/NavigationList';
import useScrolledOut from '@anm/hooks/useScrolledOut';
import useSwitchState from '@anm/hooks/useSwitchState';
import { useCallback, useContext, FC } from 'react';
import { compose } from 'recompose';

import withNavigation, { Links } from '../../HOCs/withNavigation';
import withUser, { WithUserProps } from '../../HOCs/withUser';
import { LandingData } from '../../features/getLanding';
import { LandingDataContext } from '../../store/LandingDataContext';
import { LandingButtonKind } from '../LandingButton/types';
import Logo from '../Logo';
import SidebarModal from '../SidebarModal';
import AuthUserMenuBlock from '../auth/AuthUserMenuBlock';

import UserLinksList from './MobileNavigation/UserLinksList';
import UserMenu from './MobileNavigation/UserMenu';
import NavigationLink from './NavigationLink';
export * from './NavigationLink';

export type HeaderProps = {
  isLight?: boolean;
  isFixed?: boolean;
  logoLink?: string | boolean;
  variant?: NavigationVariants;
  authRedirectUrl?: string;
  authRedirectKind?: LandingButtonKind;
};

type HOCs = WithUserProps;

export type NavigationProps = HOCs & HeaderProps & Links;

const Navigation: FC<NavigationProps> = ({ navList }) => {
  const isScrolled = useScrolledOut(30);
  const [isModalOpened, openModal, closeModal] = useSwitchState();

  const { header }: LandingData = useContext(LandingDataContext);
  const { isLight, logoLink } = header || {};

  const renderUserLinkList = useCallback(() => <UserLinksList />, []);
  const renderLogo = useCallback(() => <Logo variant="dark-wave" />, []);
  const renderUserMenu = useCallback((onUserBlockClick: () => void) => <UserMenu {...{ onUserBlockClick }} />, []);
  const renderNavLink = useCallback((params: NavigationLinkProps) => <NavigationLink {...params} />, []);

  return (
    <>
      {isModalOpened && (
        <SidebarModal width="100%" direction="right" background="#fff" onModalClose={closeModal}>
          <MobileNavigation
            list={navList}
            onModalClose={closeModal}
            {...{
              renderLogo,
              renderNavLink,
              renderUserMenu,
              renderUserLinkList
            }}
          />
        </SidebarModal>
      )}
      <NavigationWrapper variant={header?.variant || 'white'} isFixed={!!header?.isFixed} {...{ isScrolled }}>
        <Logo variant={isLight || isScrolled ? 'dark-wave' : 'wave'} link={logoLink} />

        <DesktopNavigation>
          <nav>
            <NavigationList
              list={navList}
              variant="white"
              renderNavLink={(params: NavigationLinkProps) => <NavigationLink {...params} />}
            />
          </nav>

          <AuthUserMenuBlock size="medium" variant={isLight || isScrolled ? 'white' : 'blue'} />
        </DesktopNavigation>
        <MobileMenuButtonWrapper onClick={openModal}>
          <Button variant={isLight || isScrolled ? 'silver-border' : 'border-transparent'} size="medium">
            Menu
          </Button>
        </MobileMenuButtonWrapper>
      </NavigationWrapper>
    </>
  );
};

export default compose(withUser, withNavigation)(Navigation);
