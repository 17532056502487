import { ModalProps } from '@anm/components/modals/Modal';
import { flexCenter } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

const topAlignedModalStyles = css`
  padding-top: 10vh;
  align-items: flex-start;
`;

export type ContentProps = {
  isPadding?: boolean;
};

const Content = styled.div<ModalProps>`
  z-index: 1;
  ${flexCenter};
  height: inherit;
  max-width: 100%;
  min-height: 100%;
  align-items: center;
  padding: ${({ contentPadding }) => contentPadding || '30px 0'};
  ${({ isModalTopAligned }) => isModalTopAligned && topAlignedModalStyles};
`;

export default Content;
