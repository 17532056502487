import { ExtractTypeFromReadonlyArray } from 'helpers';

const sessionErrorCodes = [403, 440, 460] as const;

export const isSessionErrorCode = <T>(
  code: SessionErrorCodes | T
): code is SessionErrorCodes =>
  sessionErrorCodes.includes(code as SessionErrorCodes);

export type SessionErrorCodes = ExtractTypeFromReadonlyArray<
  typeof sessionErrorCodes
>;

export default sessionErrorCodes;
