import styled, { css } from 'styled-components';

import { media } from '../../../styles/helpers';
import SectionTitle from '../../SectionTitle';

import { ImageProps, ContentWrapperProps } from './types';

const TitleBlockOnLaptop = media.laptop`
    margin-bottom: 40px;
`;

const MainTitleOnLaptop = media.laptop`
    font-size: 36px;
    line-height: normal;
`;

export const MainTitle = styled(SectionTitle)`
  margin-bottom: 32px;
  ${TitleBlockOnLaptop};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 30px;
    line-height: 1.2;
    ${MainTitleOnLaptop};
  }

  p {
    font-size: 24px;
    margin-top: 20px;
    max-width: 740px;
  }
`;

const SecondaryTitleOnLaptop = media.laptop`
  font-size: 32px;
  margin-top: 30px;
  text-align: left;
  line-height: 25px;
`;

const SecondaryTitleBlockOnLaptop = media.laptop`
    margin-bottom: 25px;
`;

export const SecondaryTitle = styled(MainTitle)`
  ${SecondaryTitleBlockOnLaptop};
  h1,
  h2,
  h3,
  h4,
  h5 {
    ${SecondaryTitleOnLaptop};
  }
`;

const TextSectionImageHolderOnTablet = media.tablet<ImageProps>`
  width: ${({ imageMeasure }) => imageMeasure?.width || 282}px;
  margin: 9px 87px 30px 0;
  transform-origin: top right;
  height: ${({ imageMeasure }) => imageMeasure?.height || 282}px;
  `;

const rotateStyles = css`
  transform: rotate(-5deg);
`;

export const ImageHolder = styled.div<ImageProps>`
  width: 282px;
  height: ${({ imageMeasure }) => imageMeasure?.height || 200}px;

  flex-shrink: 0;
  margin: 9px auto 32px;
  transform-origin: center center;
  border-radius: 10px;
  overflow: hidden;
  ${({ rotateImage }) => rotateImage && rotateStyles};
  ${TextSectionImageHolderOnTablet};

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const TextSectionContentWrapperOnLaptop = media.laptop<ContentWrapperProps>`
  max-width: ${({ maxWidth }) => maxWidth || '480px'};
`;

export const ContentWrapper = styled.div<ContentWrapperProps>`
  ${TextSectionContentWrapperOnLaptop};

  p {
    font-size: 24px;
    font-weight: 300;
    line-height: 1.5;
  }

  h3 {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    margin-bottom: 10px;

    & + p {
      font-size: 20px;
      line-height: 1.44;
    }
  }
`;

const TextSectionButtonWrapperOnTablet = media.tablet`
  justify-content: flex-start;
`;

export const ButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  a {
    font-size: 20px;
  }

  ${TextSectionButtonWrapperOnTablet}
`;

const Wrapper = styled.section`
  position: relative;
  overflow: hidden;
`;

export default Wrapper;
