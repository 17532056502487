import getFromCdn from '@anm/helpers/getFromCdn';
import { stretchBlock } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

import {
  IconPlayBehavior,
  IconPlayProps,
  IconPlaySizes,
  IconPlayVariants
} from '.';

export type IconProps = IconPlayProps & {
  playIconUrl: string;
  variant: IconPlayVariants;
  behavior: IconPlayBehavior;
};

const getBehaviorVariant = (behavior: IconPlayBehavior) =>
  ({
    'hover-scale': css`
      &:hover:after {
        transform: scale(1.2);
      }
    `,
    'hover-opacity': css`
      &:hover:after {
        opacity: 1;
      }
    `
  }[behavior]);

const getVariant = (playIconUrl: string, variant: IconPlayVariants) =>
  ({
    black: css`
      display: block;
      content: '';
      cursor: pointer;
      width: 36px;
      height: 36px;
      flex-shrink: 0;
      background: url(${getFromCdn('images/icons/btn-play-bordered-dark.svg')})
        no-repeat center / cover;
    `,
    custom_image: css<IconProps>`
      ${stretchBlock};
      cursor: pointer;

      &:after {
        ${stretchBlock};
        right: 0;
        bottom: 0;
        z-index: 3;
        content: '';
        margin: auto;
        transition: all 0.5s;
        ${({ size }) => getSize(size)};
        background: center / cover no-repeat url(${getFromCdn(playIconUrl)});
        opacity: ${({ behavior }) => (behavior === 'hover-opacity' ? 0 : 1)};
      }
      ${({ behavior }) => getBehaviorVariant(behavior)};
    `,
    absolute: css`
      left: 50%;
      top: 50%;
      z-index: 10;
      margin-top: -50px;
      margin-left: -50px;
      position: absolute;
      cursor: pointer;
      background: url(${getFromCdn(playIconUrl)}) no-repeat center / cover;
    `
  }[variant!]);

const getSize = (size?: IconPlaySizes) =>
  ({
    '90x90': css`
      width: 90px;
      height: 90px;
    `,
    '80x80': css`
      width: 80px;
      height: 80px;
    `,
    '50x50': css`
      width: 50px;
      height: 50px;
    `
  }[size!]);

const IconPlayWrapper = styled.div<IconProps>`
  ${({ transparentPlayIcon }) =>
    transparentPlayIcon &&
    css`
      background-color: transparent;
    `};

  ${({ variant, playIconUrl }) => getVariant(playIconUrl, variant)};
`;

export default IconPlayWrapper;
