import Container from '@anm/components/Container';
import Image from '@anm/components/image/Image';
import { FC } from 'react';

import { CommonLandingProps } from '../../../features/getLanding';
import { RelationItem } from '../../../features/relationList';
import getSideBarArticles from '../../../helpers/getSidebarArticles';
import AnimatedWrapper, { AnimatedVariants } from '../../Animated';
import FlexContainer from '../../FlexContainer';

import ContentList from './ContentList';
import Wrapper, { SidebarWrapper, TextAsideTitle } from './Wrapper';
import {
  ArticleListProps,
  ContentListProps,
  FilteredList,
  TextAsideVariant,
  TransformedImageProps
} from './types';

export type TextAsideProps = CommonLandingProps &
  TransformedImageProps &
  ArticleListProps &
  ContentListProps & {
    margin?: string;
    variant?: TextAsideVariant;
    animationVariant?: AnimatedVariants;
  };

const Sidebar: FC<ArticleListProps & TransformedImageProps> = ({
  alt,
  title,
  imageUrl,
  relationList
}) => {
  const filteredList = relationList && getSideBarArticles(relationList);

  if (filteredList) return <ArticleList asideFilteredList={filteredList} />;

  return (
    <Image src={imageUrl!} alt={alt || title} className="transformed-image" />
  );
};

const ArticleList: FC<FilteredList> = ({ asideFilteredList }) => (
  <ul>
    {asideFilteredList.map(item => (
      <li key={item.name}>
        <TextAsideArticle {...item} />
      </li>
    ))}
  </ul>
);

const TextContent: FC<RelationItem> = ({
  alt,
  title,
  preview,
  description
}) => (
  <>
    <Image src={preview} alt={alt || description} />
    <span>{description}</span>
    <p>{title}</p>
  </>
);

const TextAsideArticle: FC<RelationItem> = props =>
  props.url ? (
    <a href={props.url}>
      <TextContent {...props} />
    </a>
  ) : (
    <TextContent {...props} />
  );

const TextAside: FC<TextAsideProps> = ({
  title,
  margin,
  content,
  variant,
  headingLevel,
  animationVariant,
  ...otherProps
}) => {
  const showSideBar = otherProps.relationList || otherProps.imageUrl;

  return (
    <Wrapper variant={variant || 'default'} {...{ margin }}>
      <AnimatedWrapper
        variant={animationVariant || 'default'}
        className="animated"
      >
        <Container size={variant === 'full-width' ? 650 : 1000}>
          <FlexContainer variant="between-wrap-on-laptop">
            <TextAsideTitle color="black" level={headingLevel} title={title} />
            <ContentList {...{ content }} />
            {showSideBar && (
              <SidebarWrapper>
                <Sidebar {...otherProps} />
              </SidebarWrapper>
            )}
          </FlexContainer>
        </Container>
      </AnimatedWrapper>
    </Wrapper>
  );
};

export default TextAside;
