import TitleBlock, { TitleDescription } from '@anm/components/TitleBlock';
import Button from '@anm/components/buttons/Button';
import CardModal, { CardModalContent } from '@anm/components/modals/CardModal';
import styled, { css } from 'styled-components';

export const ModalWrapper = styled(CardModal)`
  text-align: center;
  ${CardModalContent} {
    width: 600px;
    padding: 30px 60px;
  }
`;

export const ModalTitle = styled(TitleBlock)`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 12px;
  }

  ${TitleDescription} {
    line-height: 24px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

const commonButtonStyles = css`
  height: 50px;
  line-height: 48px;
  font-size: 16px;
  text-align: center;
`;

export const CancelButton = styled(Button)`
  ${commonButtonStyles};
  color: #30aef2;
  min-width: 125px;
`;

export const UpgradeButton = styled(Button)`
  ${commonButtonStyles};
  min-width: 130px;
  color: #fff;
  margin-left: 10px;
`;
