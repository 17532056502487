import Button from '@anm/components/buttons/Button';
import { HeaderNavigationItem, RenderNavLinkProp } from '@anm/components/header';
import noop from '@anm/helpers/noop';
import { FC } from 'react';

import { IconArrow, MainListWrapper, MobileMainList, MobileNavigationLogoWrapper } from './Wrapper';
import { Logo } from './types';

export type MobileNavigationMainListProps = {
  list: HeaderNavigationItem[];
  onNestedClick?(nestedList: HeaderNavigationItem): void;
  onUserBlockClick?(): void;
};

const isNavigationLinksContainer = (props: HeaderNavigationItem): props is HeaderNavigationItem =>
  !!(props as HeaderNavigationItem).children;

type MainListProps = Pick<MobileNavigationMainListProps, 'list' | 'onNestedClick'> & RenderNavLinkProp;

const MainList: FC<MainListProps> = ({ list, renderNavLink, onNestedClick = noop }) => (
  <MobileMainList>
    {list.map(item => {
      const { title } = item;
      return (
        <li key={title}>
          {isNavigationLinksContainer(item) ? (
            <>
              <Button size="medium" variant="link" onClick={() => onNestedClick(item)}>
                {title}
              </Button>
              <IconArrow />
            </>
          ) : (
            renderNavLink(item)
          )}
        </li>
      );
    })}
  </MobileMainList>
);

const MobileNavigationMainList: FC<MobileNavigationMainListProps & RenderNavLinkProp & Logo> = ({
  list,
  renderNavLink,
  renderLogo,
  onNestedClick,
  children
}) => (
  <MainListWrapper>
    <MobileNavigationLogoWrapper>{renderLogo()}</MobileNavigationLogoWrapper>
    {children}
    <MainList {...{ list, renderNavLink }} onNestedClick={onNestedClick} />
  </MainListWrapper>
);

export default MobileNavigationMainList;
