import { GetMediaInfoResponse, MediaInfoProps, MediaType } from '@anm/api/modules/youtube';
import { isBlocked } from '@anm/components/video/MediaDownloader/context/blocked';
import { RequestState } from '@anm/hooks/useRequestState';
import { createContext, useEffect, useState, FC } from 'react';

import { StartDownloadProps } from '../DownloadMedia';
import { PossibleError } from '../Error';

import { getError, isLiveVideo, mapFormatsToOptions } from './helpers';
import useMediaInfo from './hooks/useMediaInfo';
import useStartDownload from './hooks/useStartDownload';
import useUpload from './hooks/useUpload';

export type MediaDownloaderProps = {
  mediaType: MediaType;
  uploadState: RequestState<{ id: string }>;
  formatsOptions: ReturnType<typeof mapFormatsToOptions>;
  mediaInfoState: RequestState<{ info: GetMediaInfoResponse; hasAudio: boolean }, MediaInfoProps>;
  url?: string;
  error?: PossibleError;
  upload: (intent: string) => Promise<void>;
  changeUrl: (url: string) => void;
  getMediaInfo: (props: MediaInfoProps) => Promise<void>;
  startDownload: (props: StartDownloadProps) => void;
  changeMediaType: (mediaType: MediaType) => void;
  cancelUpload: () => void;
};

export const MediaDownloaderContext = createContext({} as MediaDownloaderProps);

type MediaDownloaderProviderProps = { mediaType: MediaType };

export const MediaDownloaderProvider: FC<MediaDownloaderProviderProps> = ({
  mediaType: defaultMediaType,
  children
}) => {
  const [url, changeUrl] = useState<string>();
  const [mediaType, changeMediaType] = useState<MediaType>(defaultMediaType);
  const [mediaInfoState, { getMediaInfo, selectMediaFormats }] = useMediaInfo();
  const mediaFormats = selectMediaFormats();
  const [error, setError] = useState<PossibleError>();

  const [uploadState, { upload, cancelUpload }] = useUpload({
    mediaFormats,
    url,
    mediaType,
    duration: mediaInfoState.data?.info.duration
  });

  const startDownload = useStartDownload({
    mediaType,
    mediaFormats,
    url: mediaInfoState.params?.url,
    title: mediaInfoState.data?.info.title,
    duration: mediaInfoState.data?.info.duration
  });

  useEffect(() => {
    if (isBlocked(mediaInfoState.data?.info)) {
      setError('Blocked');
    } else if (mediaInfoState.data?.info && isLiveVideo(mediaInfoState.data?.info)) {
      setError({ title: 'Live video error', description: 'Live video is not supported' });
    } else {
      setError(getError([mediaInfoState.error]));
    }
  }, [mediaInfoState, mediaInfoState.data?.info]);

  return (
    <MediaDownloaderContext.Provider
      value={{
        url,
        error,
        upload,
        changeUrl,
        mediaType,
        uploadState,
        getMediaInfo,
        startDownload,
        mediaInfoState,
        changeMediaType,
        cancelUpload,
        formatsOptions: mediaFormats ? mapFormatsToOptions(mediaFormats) : []
      }}
    >
      {children}
    </MediaDownloaderContext.Provider>
  );
};
