import styled, { css } from 'styled-components';

type CodeProps = {
  isComment: boolean;
};

type LineProps = {
  isSingleLine: boolean;
};

export const Wrapper = styled.div`
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
`;

export const List = styled.ol`
  margin: 0 0 20px 0;
  padding: 11px 0;
  background: #f5f7fa;
  border-radius: 10px;
  counter-reset: lineCounter;
  overflow-x: auto;
`;

export const Code = styled.pre<CodeProps>`
  margin: 0;
  font-size: 14px;
  line-height: 28px;
  font-family: 'Source Code Pro', monospace;
  color: ${({ isComment }) => (isComment ? '#919EAA' : '#292A2B')};
`;

export const Line = styled.li<LineProps>`
  counter-increment: lineCounter;
  display: flex;
  align-items: center;
  position: relative;

  &:before {
    color: #919eaa;
    content: counter(lineCounter);
    margin-right: 20px;
    min-width: 40px;
    text-align: center;
    line-height: 28px;
    z-index: 1;
  }

  &:after {
    content: '';
    width: 40px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: #ebeef5;
  }

  &:first-child {
    &:after {
      border-radius: 10px 0 0 0;
      top: -11px;
    }
  }

  &:last-child {
    &:after {
      border-radius: 0 0 0 10px;
      bottom: -11px;
    }
  }

  &:before,
  &:after {
    ${({ isSingleLine }) =>
      isSingleLine &&
      css`
        opacity: 0;
        visibility: hidden;
        margin: 0;
      `}
  }
`;
