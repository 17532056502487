import Container from '@anm/components/Container';
import Image from '@anm/components/image/Image';
import { FC } from 'react';

import EnvHostingPlayer from '../../EnvHostingPlayer';
import FlexContainer from '../../FlexContainer';

import { MediaWrapper } from './Wrapper';

export type MediaAspectVariants = '16x9' | 'default';

export type SimpleMediaProps = {
  imageUrl?: string;
  videoId?: string;
  mediaMaxWidth?: number;
  containerSize?: number;
  mediaVariant?: MediaAspectVariants;
};

const SimpleMedia: FC<SimpleMediaProps> = ({
  imageUrl,
  videoId,
  mediaMaxWidth,
  mediaVariant = '16x9',
  containerSize
}) => (
  <section>
    <Container size={containerSize || 1150}>
      <FlexContainer variant="between-on-laptop">
        <MediaWrapper {...{ mediaMaxWidth, mediaVariant }}>
          {imageUrl && <Image src={imageUrl} />}
          {videoId && <EnvHostingPlayer videoId={videoId!} />}
        </MediaWrapper>
      </FlexContainer>
    </Container>
  </section>
);

export default SimpleMedia;
