import makeValue from '@anm/helpers/makeValue';
import { useEffect, useState } from 'react';

import { getColumnCountAndWidth } from './helpers';
import { BreakpointCols } from './types';

export const useColumn = (breakpointCols: BreakpointCols | (() => BreakpointCols)) => {
  const breakpoint = makeValue(breakpointCols);
  const initializeColumn = getColumnCountAndWidth(breakpoint);
  const [columnCount, setColumnCount] = useState(initializeColumn.count);
  const [columnWidth, setColumnWidth] = useState(initializeColumn.width);

  const reCalculateColumn = () => {
    const newColumn = getColumnCountAndWidth(breakpoint);

    setColumnCount(newColumn.count);
    setColumnWidth(newColumn.width);
  };

  useEffect(() => {
    reCalculateColumn();
  }, [breakpoint]);

  return { columnCount, columnWidth, reCalculateColumn };
};
