import ProgressiveVideoPreview from '@anm/components/video/ProgressiveVideoPreview';
import noop from '@anm/helpers/noop';
import { FC } from 'react';

import { PreviewCard, PreviewCardPlayButton } from './Wrapper';
import { PreviewContentProps } from './types';

const PreviewContent: FC<PreviewContentProps> = ({
  alt,
  size,
  isHover,
  preview,
  videoUrl,
  // isFirstRenderPreview,
  renderDurationBadge = noop
}) => {
  if (!preview) return null;

  const canShowPlayButton = isHover && videoUrl;

  return (
    <PreviewCard>
      {canShowPlayButton && <PreviewCardPlayButton />}
      {renderDurationBadge()}
      <ProgressiveVideoPreview
        isHovered={isHover}
        videoSrc={videoUrl}
        previewSrc={preview}
        {...{
          ...(size && size),
          alt
        }}
      />
    </PreviewCard>
  );
};

export default PreviewContent;
