import { AvatarWrapper } from '@anm/components/Avatar';
import LazyImage from '@anm/components/image/LazyImage';
import { flexCenter } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

import { media } from '../../../styles/helpers';
import SectionTitle from '../../SectionTitle';

const commonTextStyles = css`
  font-size: 20px;
  font-weight: 300;
  line-height: 36px;
`;

const nestedListOnLaptop = media.laptop`
  font-size: 20px;
  line-height: 36px;
`;

export const NestedListWrapper = styled.ul`
  color: #000;
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  a {
    color: rgb(31, 127, 215);
  }
  ${nestedListOnLaptop};
`;

const listItemOnTablet = media.tablet`
  width: 25%;
`;

export const ListItem = styled.li`
  width: 50%;
  padding: 0 10px;
  margin: 0 auto 20px;
  text-align: center;
  img {
    width: 70px;
    min-height: 70px;
    border-radius: 50%;
    margin: 0 auto 14px;
    background-color: #fff200;
  }
  ${listItemOnTablet};
`;

export const LazyImageWrapper = styled(LazyImage)``;

const contentOnTablet = media.tablet`
  margin-top: -100px;
`;

export const Content = styled.div`
  z-index: 1;
  margin-top: -150px;
  position: relative;
  ${AvatarWrapper} {
    ${flexCenter};
    background: #fff;
    overflow: hidden;
    min-width: 200px;
    box-shadow: rgba(4, 25, 79, 0.15) 0px 5px 50px;
    img {
      margin-top: 4px;
      max-width: 170px;
      max-height: 170px;
      border-radius: unset;
    }
  }
  ${contentOnTablet};
`;

export const ItemText = styled.span`
  ${commonTextStyles};
`;

export const ListItemLink = styled.a`
  ${commonTextStyles};
  color: #1f7fd7;
`;

export const ListItemTitle = styled.p`
  ${commonTextStyles};
  color: #8f8e96;
  font-weight: 400;
`;

const dataWrapperOnTablet = media.tablet`
  max-width: 650px;
  padding-top: 35px;
  margin: 0 20px 0 0;
`;

export const DataWrapper = styled.div`
  color: #292a2b;
  margin: 30px 0 0 0;
  ${dataWrapperOnTablet};

  h1,
  h2,
  h3,
  h4,
  h5,
  h5 {
    font-size: 34px;
    font-weight: 700;
    text-align: left;
    line-height: 44px;
  }
  p {
    font-size: 20px;
    text-align: left;
    font-weight: 300;
    line-height: 32px;
    margin: 17px 0 0 0;
  }
`;

const titleOnLaptop = media.tablet`
  padding: 105px 0 45px 0;
`;

export const Title = styled(SectionTitle)`
  padding: 70px 0 45px 0;
  max-width: 700px;
  position: relative;
  margin: 0 auto;
  ${titleOnLaptop};
`;

export const VideoWrapper = styled.div`
  ${flexCenter};
  z-index: 1;
  margin: 0 auto;
  max-width: 700px;
  position: relative;
  margin-bottom: 20px;
`;

const listWrapperOnTablet = media.tablet`
  padding-top: 90px;
`;

export const ListWrapper = styled.div`
  padding-top: 50px;
  ${listWrapperOnTablet};
`;

const wrapperOnLaptop = media.laptop`
  padding-bottom: 80px;
`;

const Wrapper = styled.section`
  padding-bottom: 50px;
  ${wrapperOnLaptop};
`;

export default Wrapper;
