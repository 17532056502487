import Dropdown from '@anm/components/Dropdown';
import styled, { css } from 'styled-components';

import { DropdownWrapperProps } from '../types';
import DropdownWrapper from '@anm/components/Dropdown/Wrapper';
import Badge from '@anm/components/Badge';

const toolsSmallDesktopOffset = css`
  @media (min-width: 1023px) and (max-width: 1150px) {
    left: 40px;
  }
`;

const toolsSmallDesktopStyles = css`
  @media (min-width: 1023px) and (max-width: 1150px) {
    &:before,
    &:after {
      transform: translateY(-100%) translateX(0);
    }
  }
`;

const toolsOffset = css`
  left: 50px;
  ${toolsSmallDesktopOffset};
`;

const templatesOffset = css`
  left: -200px;
  transform: none;
`;

const getDropdownContentStyles = ({ buttonName }: DropdownWrapperProps) =>
  ({
    tools: css`
      ${toolsOffset};
      &:before,
      &:after {
        transform: translateY(-100%) translateX(-23px);
      }
      ${toolsSmallDesktopStyles};
    `,
    templates: css`
      ${templatesOffset};
      &:before,
      &:after {
        margin-left: 26%;
      }
    `,
    'creative assets': css``,
    resources: css``
  }[buttonName]);

const getDropdownHoverPlugStyles = ({ buttonName }: DropdownWrapperProps) =>
  ({
    tools: css`
      ${toolsOffset};
    `,
    templates: css`
      ${templatesOffset};
    `,
    'creative assets': css``,
    resources: css``
  }[buttonName]);

export const DropdownContentWrapper = styled(Dropdown)<DropdownWrapperProps>`
  top: 57px;
  a {
    padding-right: 30px;
  }
  ${getDropdownContentStyles}
`;

export const NavigationDropdownWrapper = styled.div<DropdownWrapperProps>`
  ${DropdownWrapper} {
    &:before {
      ${getDropdownHoverPlugStyles}
    }
  }
`;

export const BadgeNew = styled(Badge)`
  margin-left: 10px;
  border-radius: 4px;
  text-transform: none;
  font-weight: normal;
  padding: 0 5px;
  cursor: pointer;
`;
