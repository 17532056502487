import { ApiError } from '@anm/api';
import hasAuthAccess from '@anm/auth/helpers/hasAuthAccess';
import signupAnon from '@anm/auth/helpers/signupAnon';
import { useCallback, useState } from 'react';

const useAnonUser = () => {
  const [isPending, setPending] = useState(false);
  const [error, setError] = useState<ApiError>();

  const signupAndRun = useCallback(
    async (cb: () => void) => {
      if (hasAuthAccess()) return;

      const response = await signup();
      response && cb();
    },
    [signupAnon]
  );

  const signup = useCallback(async () => {
    if (hasAuthAccess()) return;

    try {
      setPending(true);
      return await signupAnon();
    } catch (err) {
      setError(err);
    } finally {
      setPending(false);
    }
  }, [signupAnon]);

  return [
    { isPending, error },
    { signup, signupAndRun }
  ] as const;
};

export default useAnonUser;
