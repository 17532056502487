import checkContainMedia from '@anm/helpers/checkContainMedia';
import getAuthHeaders from '@anm/helpers/getAuthHeaders';
import { getManifest, Manifest, ManifestSource, MediaUpload } from '@anm/hosting-player';
import { useEffect, useRef, useState } from 'react';

export type CheckerProps = {
  hostId: string;
  progress: number | null;
  supportedExtensions: string[];
  source?: ManifestSource;
  mediaType?: 'video' | 'image' | 'audio';
  canCleanState?: boolean;
  uploadCheckerUrl?: string;
};

type CheckerState = {
  isMediaReady: boolean;
  videoManifest: Manifest | null;
};

const initialCheckerState: CheckerState = {
  isMediaReady: false,
  videoManifest: null
};

const DELAY = 1000;

const useVideoStatusChecker = ({
  hostId,
  source = 'cdn',
  progress,
  mediaType = 'video',
  canCleanState = false,
  uploadCheckerUrl,
  supportedExtensions
}: CheckerProps) => {
  const [media, setMedia] = useState(initialCheckerState);
  const timer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (progress !== 100 || media.isMediaReady) return;

    const checkManifestStatus = async () => {
      const manifest = (await getManifest({
        source,
        hostId
      })) as Manifest;

      const containMedia = checkContainMedia({
        supportedExtensions,
        type: mediaType,
        list: manifest.main
      });

      if (containMedia) {
        setMedia({
          isMediaReady: true,
          videoManifest: manifest
        });
      } else {
        timer.current = global.setTimeout(checkManifestStatus, DELAY);
      }
    };

    const checkUploadStatus = async () => {
      const mediaUpload: Response = await fetch(uploadCheckerUrl!, getAuthHeaders());
      const mediaUploadResponse: MediaUpload = await mediaUpload.json();

      const files = mediaUploadResponse.files?.filter(item => item.file.fileType === mediaType);

      const isStable = files?.some(media => media.file.status === 'stable');

      if (isStable) {
        setMedia({
          ...media,
          isMediaReady: true
        });
      } else {
        timer.current = global.setTimeout(checkUploadStatus, DELAY);
      }
    };

    (uploadCheckerUrl ? checkUploadStatus : checkManifestStatus)();

    return () => clearTimeout(timer.current!);
  }, [progress, media.isMediaReady]);

  useEffect(() => {
    if (media.isMediaReady) {
      clearTimeout(timer.current!);
    }
  }, [media.isMediaReady]);

  useEffect(() => {
    canCleanState && setMedia(initialCheckerState);
  }, [canCleanState]);

  return media;
};

export default useVideoStatusChecker;
