import { PartialButtonProps } from '@anm/components/buttons/Button';
import LazyImage from '@anm/components/image/LazyImage';
import { FC } from 'react';

import { CommonLandingProps } from '../../../features/getLanding';
import LandingButton, { LandingButtonProps } from '../../LandingButton';
import SectionTitle from '../../SectionTitle';
import HorizontalList, { HorizontalListItem } from '../HorizontalList';

import { DescriptionVariant } from './Description';
import Wrapper, { ContainerWrapper, LeftBlock, ListWrapper } from './Wrapper';

export type SideListProps = CommonLandingProps & {
  title: string;
  description: string;
  imgUrl?: string;
  titleMaxWidth?: number;
  button?: LandingButtonProps;
  list?: HorizontalListItem[];
  customButton?: PartialButtonProps;
  descriptionVariant?: DescriptionVariant;
};

const SideList: FC<SideListProps> = ({
  title,
  description,
  list,
  imgUrl,
  button,
  titleMaxWidth,
  descriptionVariant,
  headingLevel
}) => {
  return (
    <Wrapper>
      <ContainerWrapper size={1150} {...{ titleMaxWidth, descriptionVariant }}>
        <LeftBlock {...{ titleMaxWidth }}>
          <SectionTitle color="black" level={headingLevel} {...{ title, description }} />
          <LandingButton size="upper-big" variant="light-blue-lowercase" {...button} />
        </LeftBlock>
        <ListWrapper>
          {list && <HorizontalList waves="no-wave" list={list} iconSize="90x90" multiLine />}
          {imgUrl && <LazyImage src={imgUrl} alt={title} />}
        </ListWrapper>
      </ContainerWrapper>
    </Wrapper>
  );
};

export default SideList;
