import styled from 'styled-components';
import TitleBlock from '@anm/components/TitleBlock';
import { flexCenter, media } from '@anm/styles/helpers';
import InlineListItemWrapper from "@anm/components/InlineListItem/Wrapper";

const buttonExampleHeaderLeftAdaptive = media.tablet`
    order: 2;
    flex: auto;
`;

const buttonExampleHeaderRightAdaptive = media.tablet`
    order: 2;
    flex: auto;
`;

const buttonExampleHeaderTitleAdaptive = media.tablet`
    order: 1;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
`;

export const ButtonExampleSection = styled.section`
  background-color: #292a2b;
  padding: 12px 10px;
  ${InlineListItemWrapper} {
    flex-wrap: wrap;
  }
`;

export const ButtonExampleHeaderLeft = styled.div`
  flex: 1;
  ${buttonExampleHeaderLeftAdaptive};
`;

export const ButtonExampleHeaderRight = styled.div`
  flex: 1;
  text-align: right;
  ${buttonExampleHeaderRightAdaptive};
`;

export const ButtonExampleHeaderTitle = styled(TitleBlock)`
  width: auto;
  ${buttonExampleHeaderTitleAdaptive};
`;

export const ButtonExampleHeaderBackButton = styled.a`
  ${flexCenter};
  display: inline-flex;
  padding: 14px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  img {
    margin-right: 10px;
    margin-top: -2px;
  }
`;
