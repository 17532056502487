import { FC } from 'react';

import Wrapper, { Content, CustomSaveText } from '../Wrapper';
import { useCanShowNotification } from '../hooks';
import { NotificationBlockProps } from '../types';

export * from '../types';

const CustomSuccessNotification: FC<NotificationBlockProps> = ({ type, timeout, variant, children, onModalClose }) => {
  const [canShow] = useCanShowNotification({ timeout, onModalClose });

  if (!canShow) return null;

  return (
    <Wrapper {...{ variant }}>
      <Content {...{ type, variant }}>
        <CustomSaveText>Saved</CustomSaveText>
        {children}
      </Content>
    </Wrapper>
  );
};

export default CustomSuccessNotification;
