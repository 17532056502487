import { YoutubeHint } from '@anm/components/video/MediaDownloader/YoutubeHint';
import isMobile from '@anm/helpers/is/isMobile';
import { useCallback, useContext, FC } from 'react';
import styled from 'styled-components';
import { ReturnFunctionArg } from 'types/helpers';

import { PossibleError } from './Error';
import InputVideoUrl, { InputVideoUrlProps } from './InputVideoUrl';
import MediaCard, { MediaCardProps } from './MediaCard';
import Mobile from './Mobile';
import { MediaDownloaderContext } from './context/MediaDownloaderProvider';

export type MediaDownloaderProps = InputVideoUrlProps &
  MediaCardProps & {
    url?: string;
    isPending?: boolean;
    changeUrl: (url: string) => void;
    youtubeHint?: boolean;
    error?: PossibleError;
  };

const Wrapper = styled.div``;

const Unavailable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  line-height: 42px;
  color: red;
  font-weight: 700;
  margin-bottom: 40px;
`;

const ENABLED = true; // !isProd

const MediaDownloader: FC<MediaDownloaderProps> = ({
  url,
  preview,
  mediaType,
  title,
  options,
  duration,
  isOpenEditorPending,
  onUrlEnter,
  startDownload,
  changeUrl,
  onChangeIntent,
  error,
  onChangeMediaType,
  youtubeHint
}) => {
  const { mediaInfoState } = useContext(MediaDownloaderContext);

  const handleEnterUrl = useCallback(
    (props: ReturnFunctionArg<typeof onUrlEnter>[0]) => {
      changeUrl(props.url);
      onUrlEnter(props);
    },
    [onUrlEnter]
  );
  return (
    <Wrapper>
      {!ENABLED && <Unavailable>This service is temporarily unavailable. Please come back later.</Unavailable>}
      <InputVideoUrl mediaType={mediaType} onUrlEnter={handleEnterUrl} />
      {youtubeHint && <YoutubeHint />}
      {url &&
        !error &&
        !mediaInfoState.isError &&
        ENABLED &&
        (isMobile() ? (
          <Mobile
            options={options}
            mediaType={mediaType}
            title={title}
            duration={duration}
            preview={preview}
            onChangeMediaType={onChangeMediaType}
            startDownload={startDownload}
          />
        ) : (
          <MediaCard
            title={title}
            duration={duration}
            preview={preview}
            options={options}
            isOpenEditorPending={isOpenEditorPending}
            onChangeIntent={onChangeIntent}
            onChangeMediaType={onChangeMediaType}
            startDownload={startDownload}
          />
        ))}
    </Wrapper>
  );
};
export default MediaDownloader;
