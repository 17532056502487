import TitleBlock from '@anm/components/TitleBlock';
import Image from '@anm/components/image/Image';
import { FC } from 'react';

import {
  AuthorInfo,
  AuthorTitle,
  AuthorWrapper,
  AvatarImage,
  Content,
  MarkDetails,
  PostInfo,
  Wrapper
} from './Wrapper';
import { ReviewItem } from './types';

type ReviewSlideProps = ReviewItem & {
  minHeight: number;
};

const DEFAULT_AVATAR = 'images/avatar-placeholder.svg';

const ReviewSlide: FC<ReviewSlideProps> = ({
  logo,
  title,
  author,
  position,
  minHeight,
  description,
  avatar = DEFAULT_AVATAR
}) => (
  <Wrapper minHeight={minHeight}>
    <Content>
      <PostInfo>
        <TitleBlock {...{ title, description }} level={4} />
      </PostInfo>
      <AuthorWrapper>
        <MarkDetails>
          <Image src="images/icons/5_stars.svg" alt="5 stars" />
          <a href={logo.link} target="_blank">
            <Image src={logo.src} alt="social media" />
          </a>
        </MarkDetails>
        <AuthorInfo>
          <AvatarImage src={avatar} alt={author} />
          <AuthorTitle title={author} description={position} level={4} />
        </AuthorInfo>
      </AuthorWrapper>
    </Content>
  </Wrapper>
);

export default ReviewSlide;
