import PreviewTitleCard from '@anm/components/PreviewTitleCard';
import useHover from '@anm/hooks/useHover';
import { useCallback, FC } from 'react';

import { ProgressiveVideoPreviewWrapper, SelectedWrapper } from './Wrapper';
import { useTemplateData } from './hooks';
import { TemplatePreviewProps } from './types';

const TemplatePreview: FC<TemplatePreviewProps> = ({
  templateId,
  displayName,
  currentFormat,
  selectedIdFormat,
  meta: { media },
  onSelect
}) => {
  const [ref, isHovered] = useHover<HTMLDivElement>();
  const [mediaValue, mediaKey, previewUrl, videoUrl, isSelected, templateSize] = useTemplateData({
    media,
    templateId,
    selectedIdFormat,
    currentFormat
  });

  const handleSelect = useCallback(() => onSelect(`${templateId}-${currentFormat}-${mediaKey}`), [
    onSelect,
    templateId,
    currentFormat
  ]);

  if (!mediaValue) return null;

  return (
    <SelectedWrapper ref={ref} {...{ ...templateSize, isSelected }}>
      <ProgressiveVideoPreviewWrapper onClick={handleSelect}>
        <PreviewTitleCard
          size={templateSize}
          preview={previewUrl}
          alt={displayName}
          isHover={isHovered}
          videoUrl={videoUrl}
        />
      </ProgressiveVideoPreviewWrapper>
    </SelectedWrapper>
  );
};

export default TemplatePreview;
