import { TitleBlockColors } from '@anm/components/TitleBlock';
import { FC } from 'react';

import AsideNotification from './components/AsideNotification';
import CustomSuccessNotification from './components/CustomSuccessNotification';
import HighLightNotification from './components/HighLightNotification';
import { NotificationBlockProps, NotificationType } from './types';

export * from './types';

export const colors: Record<NotificationType, TitleBlockColors> = {
  error: 'red',
  success: 'green',
  progress: 'green',
  info: 'blue',
  warning: 'yellow'
};

const NotificationBlock: FC<NotificationBlockProps> = props => {
  const { type, variant } = props;
  const isCustomSuccessNotification = variant === 'custom' && type === 'success';

  switch (true) {
    case isCustomSuccessNotification:
      return <CustomSuccessNotification {...props} />;
    case variant === 'highlight':
      return <HighLightNotification {...props} />;
    default:
      return <AsideNotification {...props} />;
  }
};

export default NotificationBlock;
