import styled, { css } from 'styled-components';

import { inlineCodeStyles, media } from '../../../styles/helpers';
import SectionTitle from '../../SectionTitle';

import { TextAsideVariant, WrapperProps } from './types';

const ListWrapperOnLaptop = media.laptop`
  max-width: 65%;
  margin-bottom: 0;
`;

const ImageOnLaptop = media.laptop`
  transform: translateX(10%);
  max-width: 825px;
`;

export const Subtitle = styled(SectionTitle)`
  p {
    color: #292a2b;
    font-size: 20px;
    font-weight: 300;
    line-height: 32px;
    text-align: left;
    code {
      ${inlineCodeStyles};
    }
    img {
      display: block;
      margin: 20px auto;
      ${ImageOnLaptop};
    }
  }
  a {
    color: #2294df;
  }
  h4 {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 5px;
    text-align: left;
  }
`;

export const ListWrapper = styled.div`
  margin-bottom: 30px;
  ${ListWrapperOnLaptop};
`;

export const ContentPartWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 25px;
  }
`;

const TextAsideTitleOnLaptop = media.laptop`
  padding-right: 35%;
`;

export const TextAsideTitle = styled(SectionTitle)`
  width: 100%;
  margin-bottom: 17px;
  ${TextAsideTitleOnLaptop};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 34px;
    text-align: left;
    font-weight: 700;
    line-height: 44px;
  }
`;

const asideVariants: { [key in TextAsideVariant]: ReturnType<typeof css> } = {
  default: css`
    padding-bottom: 45px;
  `,
  'with-bottom-content': css`
    padding-bottom: 20px;
    & + *:not(section) {
      margin-bottom: 93px;
      padding: 0 20px;
      max-width: 1040px;
    }
  `,
  'full-width': css`
    ${ListWrapper} {
      max-width: 100%;
    }
    ${TextAsideTitle} {
      padding-right: 0;
    }
  `
};

const Wrapper = styled.section<WrapperProps>`
  ${({ variant }) => asideVariants[variant]};
  margin: ${({ margin }) => margin || '0'};
`;

const SidebarWrapperOnLaptop = media.laptop`
  max-width: 220px;

`;

const TransformedImageOnLaptop = media.laptop`
    max-width: 100%;
    transform-origin: top;
    transform: translateY(-125px) rotate(10deg);
`;

export const SidebarWrapper = styled.div`
  ${SidebarWrapperOnLaptop};

  li {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  a {
    display: block;
    width: 100%;
  }

  img:not(.transformed-image) {
    display: block;
    width: 100%;
    margin-bottom: 14px;
  }

  span {
    color: #8f8e96;
  }

  .transformed-image {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    display: block;
    filter: drop-shadow(0 5px 50px rgba(4, 25, 79, 0.15));
    ${TransformedImageOnLaptop};
  }
`;

export default Wrapper;
