type MediaObject = 'video' | 'image' | 'thumbnail' | 'audio';

export type Source<T> = {
  path: string;
  type: MediaObject;
  width: number;
  height: T;
};

type QualityProps<T extends number> = {
  type: MediaObject;
  sources: Source<T>[];
  playerWidth?: number;
};

const getBestFitQuality = <T extends number>({ sources, playerWidth, type }: QualityProps<T>): T | undefined => {
  if (!playerWidth || !sources.length) return;

  const BEST_FIT_INDEX = 0;
  const ratio = sources[0].width / sources[0].height;
  const height = Math.round(playerWidth / ratio);

  const correctQuality = height * devicePixelRatio;

  const filteredSources = sources.filter(s => s.type === type);

  const sortedExistingQualities = filteredSources.map(v => v.height).sort((a, b) => a - b);

  const bestAvailableQuality = sortedExistingQualities[sortedExistingQualities.length - 1];

  const calculatedQuality =
    sortedExistingQualities.filter(v => v >= correctQuality)[BEST_FIT_INDEX] || bestAvailableQuality;

  return calculatedQuality;
};

export default getBestFitQuality;
