import Image from '@anm/components/image/Image';
import { FC } from 'react';

import { EmptyListWrapper } from './Wrapper';

export type WrapperProps = {
  margin?: string;
  isArrow?: boolean;
};

type TabEmptyListProps = WrapperProps & {
  icon: string;
  title: string;
};

const TabEmptyList: FC<TabEmptyListProps> = ({ icon, title, children, ...wrapperProps }) => (
  <EmptyListWrapper {...wrapperProps}>
    <div>
      <Image src={icon} alt={title} />
      <h1 dangerouslySetInnerHTML={{ __html: title }} />
    </div>
    {children}
  </EmptyListWrapper>
);

export default TabEmptyList;
