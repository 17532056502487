import Image from '@anm/components/image/Image';
import { FC } from 'react';

import Wrapper, { A, Content, Description, Title } from './Wrapper';

export type UpgradeAlertProps = {};

const UpgradeAlert: FC<UpgradeAlertProps> = () => (
  <Wrapper>
    <Image src="images/icons/check-yellow-star.svg" />
    <Content>
      <Title>HD video downloader for our users</Title>
      <Description>
        <A href="/login">Login</A> or <A href="/pricing">Upgrade</A> to download the high-definition videos and get
        access to all the editing tools.
      </Description>
    </Content>
  </Wrapper>
);

export default UpgradeAlert;
