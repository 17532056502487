import { MediaType } from '@anm/api/modules/youtube';
import HorizontalTabs from '@anm/components/HorizontalTabs';
import { TabsContentWrapper, TabsPanelWrapper } from '@anm/components/HorizontalTabs/Wrapper';
import { TabButtonProps } from '@anm/components/HorizontalTabs/types';
import { useCallback, useContext, useState, FC } from 'react';
import styled from 'styled-components';

import { MediaDownloaderContext } from './context/MediaDownloaderProvider';

export type TabsProps = {
  className?: string;
  onChangeMediaType: (mediaType: MediaType) => void;
};

const TabButtonWrapper = styled.div<{ isActive: boolean }>`
  background: ${({ isActive }) => (isActive ? '#fff' : 'f4f6f7')};
  padding: 10px 20px;
  border-radius: 20px;

  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  color: #292a2b;

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const Wrapper = styled.div`
  ${TabsPanelWrapper} {
    width: 100%;
    padding: 10px 30px;
    background: #f4f6f7;
    border-radius: 30px;
    justify-content: space-around;
  }

  ${TabsContentWrapper} {
    margin-top: 30px;
  }
`;

const TabButton: FC<TabButtonProps> = ({ tab, activeTab, onTabChange }) => {
  const handleTabClick = useCallback(() => onTabChange(tab), [tab]);

  return (
    <TabButtonWrapper onClick={handleTabClick} isActive={activeTab === tab}>
      {tab}
    </TabButtonWrapper>
  );
};

const VIDEO_TAB = 0;
const AUDIO_TAB = 1;
const TABS: { id: MediaType; name: string }[] = [
  { id: 'video', name: 'Convert to video' },
  { id: 'audio', name: 'Convert to audio' }
];

const Tabs: FC<TabsProps> = ({ className, children, onChangeMediaType }) => {
  const { mediaType, mediaInfoState } = useContext(MediaDownloaderContext);
  const defaultTab = mediaType === 'video' ? VIDEO_TAB : AUDIO_TAB;
  const [activeTab, setActiveTab] = useState(TABS[defaultTab].name);

  const handleChangeTab = useCallback((index: number) => {
    const { id, name } = TABS[index];
    onChangeMediaType(id);
    setActiveTab(name);
  }, []);

  return (
    <Wrapper className={className}>
      <HorizontalTabs
        tabs={TABS.map(v => v.name)}
        hideTabs={!mediaInfoState.data?.hasAudio}
        defaultTab={activeTab}
        onTabChange={handleChangeTab}
        renderTabItem={props => <TabButton key={props.tab} {...props} />}
      >
        {children}
      </HorizontalTabs>
    </Wrapper>
  );
};

export default Tabs;
