import styled, { css } from 'styled-components';

import { mobileMenuItem } from '../../styles/helpers';

import { CollapseVariants } from '.';
import { RowProps } from './types';

const variants: { [key in CollapseVariants]: ReturnType<typeof css> } = {
  'right-down-arrow': css<RowProps>`
    overflow: auto;
    height: ${({ isOpened }) => (isOpened ? 'auto' : 0)};
    div {
      padding: 0 80px;
    }
  `,
  'up-down-arrow': css``,
  'plus-minus': css`
    background-color: rgba(203, 220, 229, 0.1);
    display: flex;
    flex-direction: column;
    padding: 0 50px 0 61px;
    a {
      ${mobileMenuItem};
      font-weight: normal;
    }
  `,
  'up-down-small-arrow': css``,
  'arrow-with-title': css``
};

const DynamicHeight = styled.div<RowProps>`
  overflow: auto;
  height: ${({ isOpened, minHeight }) => (isOpened ? 'auto' : minHeight ? `${minHeight}px` : 0)};
  opacity: ${({ isOpened, minHeight }) => (minHeight || isOpened ? 1 : 0)};
  ${({ variant }) => variants[variant]};
`;

export default DynamicHeight;
