import Container from '@anm/components/Container';
import { ButtonProps } from '@anm/components/buttons/Button';
import { FC } from 'react';

import WorkshopItem from './WorkshopItem';

export type CardContentProps = {
  background: string;
};

export type WorkshopCardProps = CardContentProps & {
  title: string;
  imageSrc: string;
  description: string;
  customButton: Partial<ButtonProps>;
};

export type WorkshopListProps = {
  list: WorkshopCardProps[];
};

const WorkshopList: FC<WorkshopListProps> = ({ list }) => (
  <Container size={1000}>
    {list.map(props => (
      <WorkshopItem key={props.title} {...props} />
    ))}
  </Container>
);

export default WorkshopList;
