import TitleBlock from '@anm/components/TitleBlock';
import { flexCenter } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

const TITLE_BORDER_OUT_MARGIN = 5;

export * from './helpers';

type TitleProps = {
  hasBorder: boolean;
  titleMaxWidth: number;
};

const borderStyles = css`
  margin-top: 0;
  padding-top: 30px;
  border-top: 1px solid #ccdde6;
`;

export const MainTitle = styled(TitleBlock)<TitleProps>`
  h1,
  h2,
  h3,
  h4,
  h5 {
    display: flex;
    flex-direction: row;
    font-size: 35px;
    margin-top: -20px;
    white-space: nowrap;
    padding: 0px 5px 30px;
    margin: -20px -${TITLE_BORDER_OUT_MARGIN}px 0;
    width: calc(100% + ${TITLE_BORDER_OUT_MARGIN * 2}px);
    max-width: ${({ titleMaxWidth }) => titleMaxWidth}px;
    ${({ hasBorder }) => hasBorder && borderStyles};
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-shrink: 1;
    }
  }
`;

const Wrapper = styled.div`
  ${flexCenter};
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  div {
    display: flex;
  }
`;

export default Wrapper;
