import styled, { css } from 'styled-components';

import { media } from '../../../styles/helpers';
import SectionTitle from '../../SectionTitle';

import { ListItemProps } from './types';

const videoContainerOnLaptop = media.laptop`
    margin: 0;
`;

export const VideoContainer = styled.div`
  width: 100%;
  height: 400px;
  margin: 0 auto;
  max-width: 400px;
  position: relative;
  img {
    height: 100%;
  }
  ${videoContainerOnLaptop};
`;

const VideoStatisticItemOnLaptop = media.laptop`
  display: flex;
  justify-content: space-between;

  ${({ reverse }: ListItemProps) =>
    reverse &&
    css`
      flex-direction: row-reverse;
    `};

`;

const NotLastOnLaptop = media.laptop`
  margin-bottom: 82px;
`;

export const VideoStatisticItem = styled.div`
  ${VideoStatisticItemOnLaptop};

  ${VideoContainer} img {
    height: auto;
  }

  &:not(:last-child) {
    margin-bottom: 40px;
    ${NotLastOnLaptop};
  }
`;

const statImageWrapperOnLaptop = media.laptop`
  max-width: 100%;
  margin: -150px 0 0 0;
  max-width: 260px;

  img {
    display: block;
    transform-origin: top;
    transform: rotate(10deg);
    filter: drop-shadow(0 5px 50px rgba(4, 25, 79, 0.15));
  }
`;

export const StatImageWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  ${statImageWrapperOnLaptop};
`;

const videoStatisticDataWrapperOnLaptop = media.laptop`
  margin: 0;
  max-width: 540px;
`;

const notLastListItemOnLaptop = media.laptop`
  margin-bottom: 0;
`;

const ListItemOnLaptop = media.laptop`
  margin: 0;
`;

export const VideoStatisticDataWrapper = styled.div`
  margin: 30px 0 0 0;
  ${videoStatisticDataWrapperOnLaptop};

  li {
    max-width: 160px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    ${ListItemOnLaptop};

    &:not(:last-child) {
      margin-bottom: 20px;
      ${notLastListItemOnLaptop};
    }

    p {
      color: #000000;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-transform: uppercase;
    }
  }
`;

const videoHolderOnLaptop = media.laptop`
  margin: 0;
  flex-shrink: 0;
`;

const subTitleOnLaptop = media.laptop`
  margin-bottom: 60px;
`;

const wrapperOnLaptop = media.laptop`
  padding: 10px 0 95px 0;;
`;

const Wrapper = styled.section`
  padding: 10px 0 50px 0;
  ${wrapperOnLaptop};

  .video-holder {
    background: #000;
    width: 100%;
    max-width: 400px;
    height: 400px;
    margin: 0 auto;
    position: relative;
    ${videoHolderOnLaptop};
  }
`;

export const VideoStatisticWrapper = styled.div`
  margin-top: 40px;
`;

export const Title = styled(SectionTitle)`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 34px;
    line-height: 44px;
  }
`;

export const SubTitle = styled(SectionTitle)`
  margin-bottom: 30px;
  ${subTitleOnLaptop};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 4px;
    text-align: left;
  }

  p {
    color: #292a2b;
    font-size: 20px;
    font-weight: 300;
    line-height: 32px;
    text-align: left;
  }
`;

export default Wrapper;
