import { ButtonSize, ButtonVariants } from '@anm/components/buttons/Button';
import { FC } from 'react';

import UserMenuBlock from '../../UserMenuBlock';
import AuthBlock from '../AuthBlock';
import AuthButtons from '../AuthButtons';

export type AuthUserMenuBlockVariants = 'blue' | 'white' | 'white-blue';

type AuthUserMenuBlockProps = {
  variant: AuthUserMenuBlockVariants;
  size: ButtonSize;
  renderUserMenuBlock?: () => JSX.Element;
};

const loginVariantMap: {
  [key in AuthUserMenuBlockVariants]: ButtonVariants;
} = {
  white: 'silver-border',
  blue: 'border-transparent',
  'white-blue': 'silver-border'
};

const signUpVariantMap: {
  [key in AuthUserMenuBlockVariants]: ButtonVariants;
} = {
  white: 'orange',
  blue: 'yellow',
  'white-blue': 'light-blue-lowercase'
};

const myProjectButtonVariantMap: {
  [key in AuthUserMenuBlockVariants]: ButtonVariants;
} = {
  white: 'silver-border',
  blue: 'silver-border',
  'white-blue': 'silver-border'
};

const renderAuthButtons = (
  variant: AuthUserMenuBlockVariants,
  size: ButtonSize
) => () => (
  <AuthButtons
    buttons={[
      {
        size,
        type: 'login',
        variant: loginVariantMap[variant]
      },
      {
        size,
        type: 'signup',
        variant: signUpVariantMap[variant]
      }
    ]}
  />
);

const AuthUserMenuBlock: FC<AuthUserMenuBlockProps> = ({
  size,
  variant,
  renderUserMenuBlock = () => (
    <UserMenuBlock variant={myProjectButtonVariantMap[variant]} />
  )
}) => (
  <AuthBlock
    renderUserBlock={renderUserMenuBlock}
    renderAuthButtons={renderAuthButtons(variant, size)}
  />
);

export default AuthUserMenuBlock;
