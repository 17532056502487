import { flexColumn, flexRow, media } from '@anm/styles/helpers';
import styled from 'styled-components';

import MediaTesterForm from '../MediaTesterForm';
import StartupBroadcast from '../StartupBroadcast';

const mobileMediaTestForm = media.phone`
  max-width: unset;
`;

export const MediaTesterFormWrapper = styled(MediaTesterForm)`
  max-width: 280px;

  ${mobileMediaTestForm};
`;
export const StartupBroadcastWrapper = styled(StartupBroadcast)`
  max-width: 850px;
  margin-right: 40px;
`;

const mobileWrapper = media.phone`
  ${flexColumn};
  gap: 30px;
`;

const Wrapper = styled.div`
  ${flexRow};
  justify-content: center;

  ${mobileWrapper}
`;

export default Wrapper;
