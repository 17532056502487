import styled from 'styled-components';

import { media } from '../../../styles/helpers';

const FeedbackTextWrapperOnLaptop = media.laptop`
  margin: 0 auto 18px;
  line-height: 40px;
  font-size: 24px;
  text-align: left;
  min-height: 130px;
`;

const FeedbackTextWrapper = styled.div`
  position: relative;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  max-width: 760px;
  margin: 0 auto 13px;
  ${FeedbackTextWrapperOnLaptop};
`;

export default FeedbackTextWrapper;
