import { FC } from 'react';

import { CommonLandingProps } from '../../../features/getLanding';
import { SectionTitleProps } from '../../SectionTitle';

import ListContent from './List';
import Wrapper, { ListContainer, ListTitle } from './Wrapper';
import { ListContainerProps, ListContentProps } from './types';
import getSubtitleLevel from '../../../helpers/getSubtitleLevel';

export type CardListProps = CommonLandingProps &
  ListContentProps &
  ListContainerProps &
  Partial<SectionTitleProps> & {
    borderRadius?: number;
    containerSize?: number;
  };

const IconLinksList: FC<CardListProps> = ({
  title,
  headingLevel,
  containerSize = 1150,
  customMargin,
  customPadding,
  customBackground,
  customMarginMobile,
  ...listProps
}) => {
  return (
    <Wrapper>
      <ListContainer
        size={containerSize}
        {...{
          customBackground,
          customMargin,
          customPadding,
          customMarginMobile
        }}
      >
        {title && (
          <ListTitle {...{ title }} color="black" level={headingLevel || 2} />
        )}
        <ListContent
          {...{ ...listProps }}
          headingLevel={getSubtitleLevel(headingLevel)}
        />
      </ListContainer>
    </Wrapper>
  );
};

export default IconLinksList;
