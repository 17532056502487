import * as analytic from '@anm/analytic';
import deleteRequest from '@anm/auth/helpers/logoutRequest';
import { AUTH_COOKIE } from '@anm/constants/auth';
import * as cookie from '@anm/helpers/cookie';
import isServer from '@anm/helpers/is/isServer';
import localStorage from '@anm/helpers/localStorage';

import { setAuthToken } from '../authToken';

const logout = async () => {
  let res: Response | null = null;
  if (!isServer() && window.name !== 'semrush_iframe') {
    res = await deleteRequest();
  }

  cookie.remove(AUTH_COOKIE);
  localStorage().clear();
  analytic.logout();

  return res;
};

const updateAuthCookie = async (token: string | null) => (token ? setAuthToken(token) : await logout());

export default updateAuthCookie;
