import { FC } from 'react';

import LandingVideo from '../../../landing/LandingVideo';

type WistiaPlayerProps = {
  wistiaId: string;
};

const WistiaPlayer: FC<WistiaPlayerProps> = ({ wistiaId }) => (
  <LandingVideo key={wistiaId} wistiaId={wistiaId} autoplay={true} />
);

export default WistiaPlayer;
