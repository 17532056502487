import { SelectOption } from '@anm/components/select/Select';
import useDefaultDevices from '@anm/hooks/media/useDefaultDevices';
import { useEffect, useState } from 'react';

import { MediaTesterFormFields } from '../../MediaTesterForm';

const mapDevice = (device: MediaDeviceInfo): SelectOption => ({
  label: device.label,
  value: device.deviceId
});

const useInitialValues = () => {
  const { webcam, mic, speaker } = useDefaultDevices();
  const [values, setValues] = useState<MediaTesterFormFields>();

  useEffect(() => {
    if (!webcam || !mic || !speaker) return;

    setValues({
      camera: mapDevice(webcam),
      microphone: mapDevice(mic),
      speaker: mapDevice(speaker)
    });
  }, [webcam?.label, mic?.label, speaker?.label]);

  return values;
};

export default useInitialValues;
