import mediaManager, { MediaDevicesList, MediaOptions } from '@anm/helpers/media/mediaManager';
import { useEffect, useState } from 'react';

export const useDefaultDevice = (mediaDevice: MediaDevicesList<MediaOptions>) => {
  const current = mediaDevice.getCurrentDevice();
  const [defaultDevice, setDefaultDevice] = useState<MediaDeviceInfo | undefined>(current);

  useEffect(
    () =>
      mediaDevice.changeDeviceEvent.on(({ newDevice }) => {
        setDefaultDevice(newDevice);
      }),
    []
  );

  return defaultDevice;
};

const useDefaultDevices = () => {
  const mic = useDefaultDevice(mediaManager().mics);
  const webcam = useDefaultDevice(mediaManager().webcams);
  const speaker = useDefaultDevice(mediaManager().speakers);

  return {
    mic,
    webcam,
    speaker
  };
};

export default useDefaultDevices;
