import isServer from '@anm/helpers/is/isServer';

import lfConfig from '../config';

const { tap } = lfConfig.affiliates;

let inited = false;
export default async () => {
  if (isServer() || inited) return null;

  try {
    inited = true;

    const affiliate = await import('@anm/helpers/affiliate');
    tap.enable && tap.id && affiliate.init(tap.id);
  } catch (err) {
    inited = false;
    console.log(`Affiliate load error: ${err}`);
  }
};
