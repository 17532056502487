import createStream, { CreateStreamProps, MediaErrorName } from '@anm/helpers/media/createStream';
import mediaManager from '@anm/helpers/media/mediaManager';
import useCancelPromise from '@anm/hooks/useCancelPromise';
import { useState } from 'react';

import useMediaManagerOptions from '../useMediaManagerOptions';

export type MediaErrors = {
  video?: MediaErrorName | null;
  audio?: MediaErrorName | null;
};

const useStreamCreator = () => {
  const micOption = useMediaManagerOptions(mediaManager().mics);
  const camOption = useMediaManagerOptions(mediaManager().webcams);

  const videoPromise = useCancelPromise();
  const audioPromise = useCancelPromise();

  const [audioStream, setAudioStream] = useState<MediaStream | null>();
  const [videoStream, setVideoStream] = useState<MediaStream | null>();
  const [currentMicId, setCurrentMicId] = useState<string>();
  const [currentCamId, setCurrentCamId] = useState<string>();

  const [errors, setErrors] = useState<MediaErrors>();

  const createStreamLocal = async (props: CreateStreamProps) => {
    try {
      if (props.webcamId && (props.webcamId !== currentCamId || !camOption.enable)) {
        videoPromise.cancelPromise();

        const stream = await videoPromise.runPromise(() => createStream(props));
        setVideoStream(stream);
        setCurrentCamId(props.webcamId);
        setErrors(prev => ({ ...prev, video: null }));
      }

      if (props.micId && (currentMicId !== props.micId || !micOption.enable)) {
        audioPromise.cancelPromise();

        const stream = await audioPromise.runPromise(() => createStream(props));
        setAudioStream(stream);
        setCurrentMicId(props.micId);
        setErrors(prev => ({ ...prev, audio: null }));
      }
    } catch (e) {
      if (e === 'cancelled') return;
      const streamName = props.webcamId ? 'video' : 'audio';
      setErrors(prev => ({ ...prev, [streamName]: e }));
    }
  };

  return [
    { errors, videoStream, audioStream },
    { setAudioStream, setVideoStream, createStream: createStreamLocal, setErrors }
  ] as const;
};

export default useStreamCreator;
