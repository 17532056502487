import { isAnon } from '@anm/auth/helpers/authAnon';
import hasAuthAccess from '@anm/auth/helpers/hasAuthAccess';
import getWaveSubscription from '@anm/helpers/subscription/getWaveSubscription';
import { compose } from 'lodash/fp';
import { User } from 'types/user';

const getUserSubscriptionName = (user: User | null) => {
  if (!user) return;

  const subscription = getWaveSubscription(user.subscriptionDetails);
  return subscription?.name;
};

const isUser = (testValue: string) =>
  compose((subscriptionName: string) => new RegExp(testValue).test(subscriptionName), getUserSubscriptionName);

export const isAnonUser = isUser('Guest');
export const isFreeUser = isUser('WaveFree');
export const isGuestUser = (user: User | null): boolean =>
  !hasAuthAccess() || isAnon() || isUser('Guest|WaveFree')(user);
export const isStaffUser = isUser('Jedi|Staff');

export default isUser;
