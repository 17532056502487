import { css } from 'styled-components';

export const leftOriented = css`
  left: auto;
`;

export const leftCenterOriented = css`
  right: -1px;
  left: auto;
`;

export const rightCenterOriented = css`
  left: -1px;
  right: auto;
`;

export const leftCenterOrientedHeight = css`
  calc(100% + 10px);
`;

export const leftCenterOrientedWithArrowHeight = css`
  calc(100% + 15px);
`;

export const centerOriented = css`
  left: 50%;
  transform: translateX(-50%);
`;

export const lowTunnelBottomDropdownStyles = css`
  height: 50%;
  bottom: -10px;
  top: auto;
`;
