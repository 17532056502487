import { organizationData } from '@anm/data/structures/Organization';
import getPageNameFromUrl from '@anm/helpers/getPageNameFromUrl';

const addOrganizationData = () => JSON.stringify(organizationData);

export const isMainPage = (pathName: string | boolean) =>
  typeof pathName === 'string' && getPageNameFromUrl(pathName) === 'main';

const organizationSnippet = () => (
  <script
    type="application/ld+json"
    dangerouslySetInnerHTML={{ __html: addOrganizationData() }}
  />
);

export default organizationSnippet;
