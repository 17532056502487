import Button from '@anm/components/buttons/Button';
import isMobileOrIPad from '@anm/helpers/is/isMobileOrIPad';
import { FC } from 'react';

import { WorkshopCardProps } from '.';
import { CardContent, Preview, Title, WorkshopCardWrapper } from './Wrapper';

const WorkshopCard: FC<WorkshopCardProps> = ({
  title,
  imageSrc,
  background,
  description,
  customButton
}) => (
  <WorkshopCardWrapper>
    <CardContent {...{ background }}>
      <Title color="white" level={3} {...{ title, description }} />
      <Button
        size="medium"
        variant="yellow"
        children="Go To Workshop"
        {...customButton}
      />
    </CardContent>
    {!isMobileOrIPad() && <Preview src={imageSrc} alt={title} />}
  </WorkshopCardWrapper>
);

export default WorkshopCard;
