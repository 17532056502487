import { noop } from 'lodash/fp';
import { useEffect, useRef } from 'react';

import useSwitchState from '../useSwitchState';

type UseTimerProps = {
  timeout: number;
  startByDefault?: boolean;
  onTimeoutFinished?: () => void;
};
const useTimer = ({ timeout, startByDefault, onTimeoutFinished = noop }: UseTimerProps) => {
  const [isStared, startTimer, localStopTimer] = useSwitchState(false);
  const clearTimerRef = useRef<number>();
  const handleTimeoutRef = useRef(noop);
  const clearTimer = () => clearTimerRef.current && window.clearTimeout(clearTimerRef.current);
  const stopTimer = () => {
    clearTimer();
    localStopTimer();
  };

  handleTimeoutRef.current = () => {
    stopTimer();
    onTimeoutFinished();
  };

  useEffect(() => {
    startByDefault && startTimer();
  }, [startByDefault]);

  useEffect(() => {
    if (!isStared) return;

    clearTimer();
    clearTimerRef.current = window.setTimeout(() => handleTimeoutRef.current(), timeout);

    return () => {
      stopTimer();
    };
  }, [isStared]);

  return [isStared, { startTimer, stopTimer }] as const;
};

export default useTimer;
