import { variants } from '@anm/components/buttons/Button/Button';
import styled from 'styled-components';

import IconButton from '../IconButton';

import { ProgressWrapperProps } from './types';

export const ProgressWrapper = styled(IconButton)<ProgressWrapperProps>`
  height: 60px;
  border-radius: 8px;
  pointer-events: none;
  position: relative;
  border: none;
  overflow: hidden;
  font-size: 20px;
  min-height: 60px;

  &&& {
    max-width: 100%;
  }

  span,
  &:before {
    z-index: 1;
    position: relative;
  }

  i {
    display: inline-block;
    min-width: 50px;
    font-style: normal;

    &:empty {
      display: none;
    }
  }

  &:after {
    ${({ progressVariant }) => variants[progressVariant || 'orange']}
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: ${({ value }) => value};
    border-radius: 8px 0 0 8px;
  }
`;
