import getFromCdn from '@anm/helpers/getFromCdn';
import styled from 'styled-components';

import { CircleProgressItem } from '../types';

const circleBg = ({ icon }: Partial<CircleProgressItem>) =>
  `url(${getFromCdn(icon!)})`;

const CircleContainer = styled.div`
  width: 160px;
  height: 160px;
  position: relative;
  margin-bottom: 14px;
  background: ${circleBg} no-repeat center / cover;

  div {
    position: absolute;
  }

  .big-value,
  .small-value {
    color: #a0a0a7;
    font-size: 12px;
    font-weight: 400;
    right: 55%;
    text-align: right;
  }

  .small-value {
    top: 15px;
  }

  .percent-value {
    color: #292a2b;
    font-size: 30px;
    font-weight: 400;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default CircleContainer;
