import TitleBlockWrapper, { TitleDescription } from '@anm/components/TitleBlock/Wrapper';
import { media } from '@anm/styles/helpers';
import styled from 'styled-components';

import { DialogProps } from '.';

const dialogContentResponsive = media.tablet`
  padding: 16px;
  min-width: unset;
`;

const DialogContent = styled.div<DialogProps>`
  overflow-y: auto;
  max-width: 540px;
  border-radius: 4px;
  position: relative;
  pointer-events: all;
  padding: 28px 42px 30px;
  max-height: calc(100vh - 40px);
  min-width: ${({ noMinWidth }) => (noMinWidth ? 'unset' : '480px')};
  background: ${({ dialogBackground, theme }) => dialogBackground || theme.dropdownAndModalBg};
  ${dialogContentResponsive};
  ${TitleBlockWrapper} {
    padding: 0;
    max-width: 400px;
    margin: 0 auto;
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-bottom: 12px;
      line-height: 32px;
    }
    ${TitleDescription} {
      margin: 0;
      line-height: 24px;
    }
  }
  @media (orientation: landscape) {
    max-width: 630px;
  }
`;

export default DialogContent;
