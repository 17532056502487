import { flexCenter, media } from '@anm/styles/helpers';
import styled from 'styled-components';

import SectionTitle from '../../SectionTitle';

const Wrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
`;

const mobileTitle = media.phone`
margin-bottom: 20px;

h1 {
  font-size: 28px;
    line-height: 40px;
}
`;

export const SectionTitleWrapper = styled(SectionTitle)`
  margin-bottom: 50px;

  h1 {
    font-size: 82px;
    line-height: 90px;
  }

  ${mobileTitle};
`;

export const ButtonContainer = styled.div`
  margin-top: 40px;

  ${flexCenter}
`;

export default Wrapper;
