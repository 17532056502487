import {MediaType} from '@anm/api/modules/youtube';
import Background from '@anm/components/Background';
import Container from '@anm/components/Container';
import MediaDownloaderConsumer from '@anm/components/video/MediaDownloader/context/MediaDownloaderConsumer';
import {MediaDownloaderProvider} from '@anm/components/video/MediaDownloader/context/MediaDownloaderProvider';
import isMobile from '@anm/helpers/is/isMobile';
import {FC, useEffect, useState} from 'react';

import lfConfig from '../../../config';
import UltraTitle from '../UltraTitle';
import {UltraTitleProps} from '../UltraTitle/types';

import Wrapper from './Wrapper';

export type MediaDownloaderSectionProps = UltraTitleProps & { mediaType: MediaType };

const {editorUrl} = lfConfig;

const useBgColor = () => {
  const [color, setColor] = useState('#F4F6F7');

  useEffect(() => {
    setColor(isMobile() ? '#fff' : '#F4F6F7');
  }, []);

  return color;
};

const MediaDownloaderSection: FC<MediaDownloaderSectionProps> = ({
                                                                   mediaType,
                                                                   headingLevel,
                                                                   youtubeHint,
                                                                   ...titleProps
                                                                 }) => {
  const bgColor = useBgColor();

  return (
    <MediaDownloaderProvider mediaType={mediaType}>
      <Background variant="custom" customBackground={bgColor}>
        <Wrapper>
          <Container fullWidth>
            <UltraTitle level={headingLevel} {...titleProps} />
            <MediaDownloaderConsumer editorUrl={editorUrl} youtubeHint={youtubeHint}/>
          </Container>
        </Wrapper>
      </Background>
    </MediaDownloaderProvider>
  );
};

export default MediaDownloaderSection;
