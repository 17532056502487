import styled, { css } from 'styled-components';

import { media } from '../../../styles/helpers';

import { DescriptionThemeProps, TextProps } from './types';

type WrapperProps = TextProps & {
  isVideoTitle?: boolean;
  theme?: DescriptionThemeProps;
};

export const centeredStyles = css`
  max-width: 100%;
  text-align: center !important;
`;

const TextWrapperOnLaptop = media.laptop`
  text-align: left;
  max-width: 613px;
  font-size: 24px;
  line-height: 36px;
  margin: ${({ theme }) => theme.margin || '0px 0px 60px'};
  ${({ isFullWidth }: TextProps) => isFullWidth && centeredStyles};
`;

const TextWrapper = styled.p<WrapperProps>`
  color: #292a2b;
  font-size: 17px;
  font-weight: 300;
  line-height: 28px;
  order: ${({ isVideoTitle }) => (isVideoTitle ? 0 : 2)};
  text-align: center;
  margin: 0 0 30px 0;
  ${TextWrapperOnLaptop};
  font-weight: ${({ theme }) => theme.fontWeight || 300};
  ${({ isFullWidth }) => isFullWidth && centeredStyles};
`;

export default TextWrapper;
