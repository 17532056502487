import { RequestParamsState, ResponseDataState } from '@anm/api';
import { getType, PayloadAction } from 'typesafe-actions';

import { AsyncEntity } from '../asyncEntity';

const getParams = <S>(state: S, isParams?: boolean, stateName?: keyof S) =>
  isParams ? ((stateName ? state[stateName] : state) as any).params : null;

const asyncEntityHandlers = <S extends {}>(
  { request, success, failure }: Partial<AsyncEntity>,
  stateName?: keyof S,
  isParams?: boolean
) => ({
  ...(request && {
    [getType(request)]: (state: S, action: PayloadAction<string, any>) => {
      const newState: RequestParamsState<any> = {
        isError: false,
        isPending: true,
        params: action.payload
      };
      return { ...state, ...(stateName ? { [stateName]: { ...state[stateName], ...newState } } : newState) };
    }
  }),
  ...(success && {
    [getType(success)]: (state: S, action: PayloadAction<string, any>) => {
      const newState: ResponseDataState<any> & RequestParamsState<any> = {
        data: action.payload,
        isError: false,
        isPending: false,
        params: getParams(state, isParams, stateName)
      };
      return { ...state, ...(stateName ? { [stateName]: { ...state[stateName], ...newState } } : newState) };
    }
  }),
  ...(failure && {
    [getType(failure)]: (state: S, action: PayloadAction<string, any>) => {
      const newState: Partial<ResponseDataState<any> & RequestParamsState<any>> = {
        error: action.payload,
        isError: true,
        isPending: false,
        params: getParams(state, isParams, stateName)
      };
      return { ...state, ...(stateName ? { [stateName]: { ...state[stateName], ...newState } } : newState) };
    }
  })
});

export default asyncEntityHandlers;
