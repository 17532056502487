import styled from 'styled-components';

export const LogoWrapper = styled.a`
  display: flex;
  flex-shrink: 0;
  color: ${({ theme }) => theme.textColor};
  img {
    max-width: 100%;
  }
`;

export const LogoText = styled.p`
  margin: 0;
  font-size: 20px;
  font-weight: 500;
`;

export const NoLinkLogoWrapper = LogoWrapper.withComponent('div');
