import { MediaInfoProps, MediaType } from '@anm/api/modules/youtube';
import { ENTER_NAME, NUMPAD_ENTER_NAME } from '@anm/constants/keyCodes';
import { toObject } from '@anm/helpers/parseParams';
import { useCallback, useEffect, useRef, useState, ChangeEvent, FC, KeyboardEvent } from 'react';
import styled from 'styled-components';

import Error, { MediaLocalError } from './Error';

export type InputVideoUrlProps = {
  mediaType: MediaType;
  onUrlEnter: (props: MediaInfoProps) => void;
};

export const Input = styled.input`
  max-width: 720px;
  width: 100%;
  height: 60px;
  padding: 15px 20px;

  font-size: 20px;
  line-height: 32px;
  border: 6px solid #a5dfff;
  border-radius: 5px;
  background: #fff;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const mediaTypeMap = { audio: 'mp3', video: 'mp4' } as { [key in MediaType]: string };

const isUrlCorrect = (url: string) => {
  try {
    decodeURIComponent(decodeURIComponent(url));
    return url.match(/^(http(s)?:\/\/)?((w){3}.)?(m.)?(youtu(be|.be))|(vimeo)|(tiktok)|(instagram)?(\.com)?\/.+/gi);
  } catch (e) {
    return false;
  }
};

const InputVideoUrl: FC<InputVideoUrlProps> = ({ mediaType, onUrlEnter }) => {
  const [url, serUrl] = useState<string>('');
  const prevUrlRef = useRef<string>();
  const [error, serError] = useState<MediaLocalError>();

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    serUrl(target?.value);
  };

  const isUrlValid = () => {
    serError(undefined);
    if (isUrlCorrect(url) || url === '') {
      return true;
    } else {
      setTimeout(
        () =>
          serError({
            title: ' Invalid URL',
            description:
              'It doesn&apos;t look like there&apos;s a video at this URL. Please double-check the link and try again.'
          }),
        0
      );
      return false;
    }
  };

  useEffect(() => {
    !prevUrlRef.current && url.length > 25 && handleEnterUrl();
    prevUrlRef.current = url;
  }, [url]);

  const handleEnterUrl = () => onUrlEnter({ url: isUrlValid() ? url : '', type: mediaType });

  useEffect(() => {
    const { u: url } = toObject(window.location.search);
    url && serUrl(url);
  }, []);

  const handleOnKeyDown = useCallback(
    ({ code }: KeyboardEvent<HTMLInputElement>) => [ENTER_NAME, NUMPAD_ENTER_NAME].includes(code) && handleEnterUrl(),
    [handleEnterUrl]
  );

  return (
    <Wrapper>
      <Input
        placeholder="Paste here any video URL"
        value={url}
        onKeyDown={handleOnKeyDown}
        onBlur={handleEnterUrl}
        onChange={handleChange}
      />
      {error && <Error error={error} />}
    </Wrapper>
  );
};
export default InputVideoUrl;
