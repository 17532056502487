import App from './components/App';
import { useVideoData } from './components/App/hooks';
import Loader from './components/Loader';
import playerConfig from './config';
import TapToUnmute from './components/TapToUnmute';
import checkCanDownload from './helpers/checkCanDownload';
import getManifest from './helpers/getManifest';
import getVideoIdFromUrl from './helpers/getVideoIdFromUrl';
import mapConfigs from './helpers/mapConfigs';
import parseManifest from './helpers/parseManifest';
import getBestQuality from './helpers/getBestQuality';
import isVideoBlocked from './helpers/isVideoBlocked';
import getThumbnailHeight from './helpers/getThumbnailHeight';

export * from './types';

const { supportedExtensions, analyticsUrl } = playerConfig();

export {
  Loader,
  mapConfigs,
  getManifest,
  analyticsUrl,
  useVideoData,
  getBestQuality,
  parseManifest,
  isVideoBlocked,
  checkCanDownload,
  getVideoIdFromUrl,
  getThumbnailHeight,
  TapToUnmute,
  supportedExtensions
};

export default App;
