import { styledFormats } from '@anm/data/videoTemplatesFormatModal';
import capitalize from '@anm/helpers/string/capitalize';
import { useCallback, FC } from 'react';

import { FormatButton, FormatImage, TabRow } from './Wrapper';
import { TabItemProps, TabProps } from './types';

const TabItem: FC<TabItemProps> = ({ format, currentFormat, onChange, icon: { src, height, width } }) => {
  const handleChange = useCallback(() => onChange(format), [onChange, format]);

  return (
    <FormatButton key={format} isSelected={currentFormat === format} onClick={handleChange}>
      <FormatImage src={src} height={`${height}px`} width={`${width}px`} alt={format} />
      <span>{capitalize(format)}</span>
    </FormatButton>
  );
};

const Tab: FC<TabProps> = props => (
  <TabRow>
    {styledFormats.map(tab => (
      <TabItem key={tab.format} {...{ ...tab, ...props }} />
    ))}
  </TabRow>
);

export default Tab;
