import { media } from '@anm/styles/helpers';
import styled from 'styled-components';

export const CloseModalButtonWrapperTabletStyles = media.tablet`
  top: 0;
  right: 0;
  position: absolute;
  padding: 17px 17px 0 0;
`;

export const CardModalWrapperTabletStyles = media.tablet`
  padding: 0;
  width: 100%;
  min-width: auto;
  max-width: calc(100vw - 30px);
`;

export const CloseModalButtonWrapper = styled.div`
  top: 0;
  right: 0;
  z-index: 1;
  position: absolute;
  pointer-events: all;
  align-self: flex-start;
  padding: 32px 40px 0 10px;
  ${CloseModalButtonWrapperTabletStyles};
`;

const CardModalWrapper = styled.div`
  ${CardModalWrapperTabletStyles};
  position: relative;
`;

export default CardModalWrapper;
