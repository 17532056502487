import isDate from 'lodash/fp/isDate';

const encode = (value: string) =>
  encodeURIComponent(value)
    .replace(/%40/gi, '@')
    .replace(/%3A/gi, ':')
    .replace(/%24/g, '$')
    .replace(/%2C/gi, ',')
    .replace(/%20/g, '+');

const paramsSerializer = <T extends { [key in string]: any }>(params: T) => {
  return Object.keys(params)
    .map(key => {
      const val: string | string[] = params[key];

      if (val === null || typeof val === 'undefined') {
        return;
      }

      const arrVal = Array.isArray(val) ? (val as string[]) : [val];

      return arrVal
        .map((v: string | Date, index) => {
          const parsedKey = Array.isArray(val) ? `${key}_${index}` : key;
          const parsedVal = isDate(v)
            ? v.toISOString()
            : v !== null && typeof v === 'object'
            ? JSON.stringify(v)
            : v;

          return `${encode(parsedKey)}=${encode(parsedVal)}`;
        })
        .join('&');
    })
    .join('&');
};

export default paramsSerializer;
