import { SocialLink } from '@anm/components/footers/TopFooter';
import { HrefLinkProps } from '@anm/components/header';
import LazyImage from '@anm/components/image/LazyImage';
import { FC } from 'react';

type FooterSocialLinksProps = {
  socialLinks: HrefLinkProps[];
};

const SocialLinksList: FC<FooterSocialLinksProps> = ({ socialLinks }) => (
  <ul>
    {socialLinks.map(({ title, href }) => (
      <li key={title}>
        <SocialLink href={href} aria-label={title} target="_blank">
          <LazyImage src={`images/icons/social/${title}.svg`} alt={title} />
        </SocialLink>
      </li>
    ))}
  </ul>
);

export default SocialLinksList;