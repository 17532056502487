import {
  FooterChildLinksProps,
  FooterListTitle,
  FooterNavigationList,
  FooterNavListProps
} from '@anm/components/footers/TopFooter';
import { FC } from 'react';

import NavigationLink from '../../Navigation/NavigationLink';

const FooterChildLinks: FC<FooterChildLinksProps> = ({ links }) => (
  <ul>
    {links.map(({ title, ...other }) => (
      <li key={title}>
        <NavigationLink {...{ title, ...other }} />
      </li>
    ))}
  </ul>
);

const NavList: FC<FooterNavListProps> = ({ footerLinks, isDark }) => (
  <>
    {footerLinks.map(({ menuTitle, links }) => (
      <FooterNavigationList key={menuTitle} {...{ isDark }}>
        <FooterListTitle>{menuTitle}</FooterListTitle>
        <FooterChildLinks {...{ links, isDark }} />
      </FooterNavigationList>
    ))}
  </>
);

export default NavList;
