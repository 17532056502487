import { AnimatronProduct } from 'types/global';
import { User } from 'user';

import getStudioSubscription from '../subscription/getStudioSubscription';
import getWaveSubscription from '../subscription/getWaveSubscription';
import waitFor from '../waitFor';

declare global {
  interface Window {
    FrontChat: any;
  }
}

type InitOptions = {
  chatId: string;
  product: AnimatronProduct;
  welcomeMessageAppearance?: string;
  useDefaultLauncher?: boolean;
};
let initCompleted: Promise<InitOptions>;
export const init = (options: InitOptions) => {
  initCompleted = new Promise(resolve => {
    const { chatId, welcomeMessageAppearance = 'hidden', useDefaultLauncher = true } = options;
    frontChat('init', {
      chatId,
      welcomeMessageAppearance,
      useDefaultLauncher,
      onInitCompleted: () => {
        resolve(options);
      }
    });
  });
};

export const load = () => {
  function append() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.defer = true;
    s.src = 'https://chat-assets.frontapp.com/v1/chat.bundle.js';

    setTimeout(() => document.body.appendChild(s), 1500);
  }
  if (document.readyState === 'complete') {
    append();
    return;
  }
  window.addEventListener('load', append, false);
};

export const frontChat = async (...a: any[]) => {
  await waitFor(() => !!window.FrontChat, 50, 'wait for FrontChat loaded');
  window.FrontChat && window.FrontChat(...a);
};

export const setUser = async ({
  userId,
  username,
  email,
  meta: { frontStudioHash, frontWaveHash },
  subscriptionDetails
}: User) => {
  if (!userId) return;

  const { product = 'WAVE', ...options } = await initCompleted;

  frontChat('identity', {
    ...options,
    name: username,
    userHash: product === 'WAVE' ? frontWaveHash : frontStudioHash,
    userId,
    contact: {
      email,
      customFields: {
        Admin: `https://webadmin.animatron${
          process.env.TS_ENV === 'prod' ? '' : '-test'
        }.com/admin/user.html?userId=${userId}`,
        'Studio Plan': getStudioSubscription(subscriptionDetails)?.name,
        'Wave Plan': getWaveSubscription(subscriptionDetails)?.name
      }
    }
  });
};

export const setAnonymous = async () => {
  const options = await initCompleted;

  frontChat('shutdown', { clearSession: true });
  init(options);
};

export const openChat = async () => {
  await initCompleted;

  frontChat('show');
};
