import * as semrush from '@anm/helpers/semrush'
import useTextAreaOrInputState from '@anm/hooks/useTextAreaOrInputState';
import { useCallback, useEffect, useRef, useState } from 'react';

import { MOODS } from '../components/MoodPicker';
import { useTextOnScene } from '../hooks';
import { GenerateVideoModalProps } from '../types';

const initStockAssetValue = semrush.isSemrushIframe() ? 1 : 0;

export const useCreateVideoFromTextHandlers = (onCreateVideo: GenerateVideoModalProps['onCreateVideo']) => {
  const [title, handleTitleChange] = useTextAreaOrInputState('');
  const [text, handleTextChange] = useTextAreaOrInputState('');

  const [time, setTime] = useState(0); // in seconds
  const [mood, setMood] = useState(MOODS[0]);
  const [activeSummarizeText, setActiveSummarizeText] = useState(0);
  const [activeStockAssetsButton, setActiveStockAssetsButton] = useState(initStockAssetValue);
  const [slideLength, activeTextSceneButton, handleTextSceneChange] = useTextOnScene();

  const isUseTextIs = !activeSummarizeText;
  const isPremiumStocks = !activeStockAssetsButton;

  const handleCreateVideoFromText = useCallback(
    () =>
      onCreateVideo({
        mood,
        max_len: time,
        text: text,
        max_slide_len: slideLength,
        full_text_summary: isUseTextIs,
        use_premium_stocks: isPremiumStocks,
        ...(title && { title })
      }),
    [title, mood, text, slideLength, time, isUseTextIs, isPremiumStocks]
  );

  return [
    time,
    text,
    title,
    isUseTextIs,
    activeSummarizeText,
    activeTextSceneButton,
    activeStockAssetsButton,
    setTime,
    setMood,
    handleTextChange,
    handleTitleChange,
    handleTextSceneChange,
    setActiveSummarizeText,
    handleCreateVideoFromText,
    setActiveStockAssetsButton
  ] as const;
};

const MODAL_HEIGHT = 710;

export const useModalMarginTop = () => {
  const marginTop = useRef(0);

  useEffect(() => {
    const wh = window.innerHeight;
    const margin = wh < MODAL_HEIGHT ? MODAL_HEIGHT - wh + wh * 0.1 : 0;

    marginTop.current = margin;
  }, []);

  return marginTop.current;
};
