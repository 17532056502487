import Image from '@anm/components/image/Image';
import { getFadeInAnimation } from '@anm/styles/helpers';
import styled from 'styled-components';

const Wrapper = styled.div`
  flex: 1 1;
  display: flex;
  min-height: 50px;
  position: relative;
  max-width: calc(100% - 83px);
  &:only-child {
    max-width: 100%;
  }
`;

export const SearchIcon = styled(Image)`
  top: 15px;
  left: 15px;
  z-index: 1;
  opacity: 0.5;
  cursor: pointer;
  position: absolute;
`;

export const ClearIcon = styled(Image)`
  ${getFadeInAnimation()};
  top: 13px;
  z-index: 1;
  right: 10px;
  opacity: 0.5;
  cursor: pointer;
  position: absolute;
`;

export const Input = styled.input`
  flex: 1 1;
  border: none;
  display: flex;
  color: #292a2b;
  max-width: 100%;
  font-size: 16px;
  line-height: 19px;
  border-radius: 5px;
  padding: 10.5px 45px 9.5px;
  background-position-x: 208px;
  box-shadow: 0px 2px 10px rgba(1, 83, 130, 0.1);
  &::placeholder {
    color: #919eaa;
  }
  &:focus,
  &:active {
    outline: none;
  }
`;

export default Wrapper;
