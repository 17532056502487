import styled from 'styled-components';

import { media } from '../../../styles/helpers';
import SectionTitle from '../../SectionTitle/index';

const VerticalListTitleOnLaptop = media.laptop`

    h1,h2,h3,h4,h5,h6 {
        font-size: 36px;
    }
`;

const VerticalListTitle = styled(SectionTitle)`
  ${VerticalListTitleOnLaptop};

  h1,h2,h3,h4,h5,h6 {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    color: #292a2b;
  }
`;

export default VerticalListTitle;
