import createArray from '@anm/helpers/array/createArray';
import round2Chars from '@anm/helpers/parsers/round2Chars';
import formatAMPM from '@anm/helpers/time/formatAMPM';
import flatten from 'lodash/fp/flatten';

import { FilterHoursProps, GetHourWithMinutesProps, TimeOptions } from './types';

const ONE_HOUR = 60;

const getHourWithMinutes = ({ hour, suffix, interval }: GetHourWithMinutesProps) => {
  const itemsCount = Math.round(ONE_HOUR / interval);
  let minutes: number;

  const output = createArray(itemsCount).map((_, i) => {
    const suffixWithSpace = ` ${suffix}`;

    minutes = i === 0 ? 0 : minutes + interval;

    return minutes < ONE_HOUR ? `${hour}:${round2Chars(minutes)}${suffixWithSpace}` : '';
  });

  return output;
};

const getFixedHours = (hours: number, isEveningHours: boolean) => {
  switch (true) {
    case hours !== 12 && isEveningHours:
      return hours + 12;
    case !isEveningHours && hours === 12:
      return 0;
    default:
      return hours;
  }
};

const getFilteredHours = ({ hours, isEveningHours, options }: FilterHoursProps) =>
  flatten(hours).filter(t => {
    const time = t.split(' ')[0];
    const [hours, minutes] = time.split(':');

    const { startFromTime } = options;

    const fixedHours = getFixedHours(+hours, !!isEveningHours);

    return fixedHours > startFromTime.hours || (fixedHours === startFromTime.hours && +minutes > startFromTime.minutes);
  });

export const getTimeList = (interval: number, options?: TimeOptions) => {
  const hours = createArray(11, 1);
  hours.unshift(12);

  const morningHours = hours.map(hour => getHourWithMinutes({ hour, interval, suffix: 'AM' }));
  const eveningHours = hours.map(hour => getHourWithMinutes({ hour, interval, suffix: 'PM' }));

  const allHours = flatten([...morningHours, ...eveningHours]);

  if (!options) return allHours;

  const filteredMorningHours = getFilteredHours({
    options,
    hours: options.startFromTime.hours <= 12 ? morningHours : []
  });
  const filteredEveningHours = getFilteredHours({ options, hours: eveningHours, isEveningHours: true });

  return [...filteredMorningHours, ...filteredEveningHours];
};

const getValueFromAMPM = (time: string, value: 'hour' | 'minute') => {
  const index = value === 'hour' ? 0 : 1;

  return parseFloat(time.split(':')[index]);
};

const getDaySuffix = (time: string) => time.split(' ')[1];

export const getClosestTime = (timeList: string[]) => {
  const currentDate = new Date(Date.now());
  const currentHour = currentDate.getHours();
  const currentMinutes = currentDate.getMinutes();
  const currentTimeInAMPM = formatAMPM(currentHour, currentMinutes);
  const currentDaySuffix = getDaySuffix(currentTimeInAMPM);
  const firstHour = timeList[0];
  const halfDay = timeList.filter(time => getDaySuffix(time) === currentDaySuffix);

  const currentHourIntervals = halfDay.filter(
    time => getValueFromAMPM(time, 'hour') === getValueFromAMPM(currentTimeInAMPM, 'hour')
  );

  const lastPeriodIndex = timeList.findIndex(time => time === currentHourIntervals[currentHourIntervals.length - 1]);
  const nextHour = timeList[lastPeriodIndex + 1] || firstHour;

  const closestTime = currentHourIntervals.find(time => getValueFromAMPM(time, 'minute') > currentMinutes) || nextHour;

  return closestTime;
};
