import { UserConsumer } from '@anm/components/user/UserProvider';
import { ComponentType, FC } from 'react';
import { User } from 'user';

type UserProps = {};

export type UserState = {
  user: User | null;
  isUserLogged: boolean;
};

export type WithUserProps = UserProps & UserState;

const withUser = <P extends object>(
  WrappedComponent: ComponentType<P & UserProps>
) => {
  const WithUser: FC<P> = props => (
    <UserConsumer>
      {userProps => <WrappedComponent {...props} {...userProps} />}
    </UserConsumer>
  );

  type PropsExcludingDefaults = Pick<P, Exclude<keyof P, keyof UserState>>;
  type RecomposeProps = Partial<UserState> & PropsExcludingDefaults;

  return (WithUser as ComponentType<P>) as ComponentType<RecomposeProps>;
};

export default withUser;
