import { MediaType } from '@anm/api/modules/youtube';
import Loader from '@anm/components/Loader';
import Button from '@anm/components/buttons/Button';
import CardModal, { CardModalProps } from '@anm/components/modals/CardModal';
import { flexCenter } from '@anm/styles/helpers';
import { FC } from 'react';
import styled from 'styled-components';

export type OpenEditorModalProps = CardModalProps & { mediaType: MediaType; onCancel: () => void };

const Wrapper = styled.div`
  ${flexCenter}
  flex-direction: column;
  padding: 20px 40px;
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #292a2b;
`;

const ButtonWrapper = styled(Button)`
  margin-top: 25px;
`;
const LoaderWrapper = styled(Loader)`
  ${flexCenter}

  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
`;
const LoaderContainer = styled.div`
  min-height: 60px;
`;
const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
`;

const OpenEditorModal: FC<OpenEditorModalProps> = ({ mediaType, onCancel, ...cardProps }) => (
  <CardModal isCloseButtonInsideModal {...cardProps}>
    <Wrapper>
      <Title>
        We're processing
        <br /> your {mediaType}
      </Title>
      <LoaderContainer>
        <LoaderWrapper color="blue" size="medium" />
      </LoaderContainer>
      <Description>
        It might take several minutes.
        <br /> Please, don't close this tab.
      </Description>
      <ButtonWrapper size="medium" variant="gray-system" onClick={onCancel}>
        Cancel
      </ButtonWrapper>
    </Wrapper>
  </CardModal>
);

export default OpenEditorModal;
