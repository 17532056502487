import { AnimatronProduct } from '@anm/api/modules/auth';

import {
  initTrackerLibs,
  AnalyticProviders,
  AnalyticProviderNames
} from '../..';
import { WaveAnalytic } from '../../types';

export const transformWaveConfigs = (
  analyticConfigs: WaveAnalytic,
  product: AnimatronProduct
) =>
  Object.keys(analyticConfigs).reduce(
    (acc, providerName: AnalyticProviderNames) => {
      const { ids, domains, apiHosts, ...other } = analyticConfigs[
        providerName
      ];
      acc[providerName] = {
        id: ids![product],
        domain: domains?.[product],
        apiHost: apiHosts?.[product],
        ...other
      };

      return acc;
    },
    {} as AnalyticProviders
  );

const initAnalytic = (
  analyticConfigs: AnalyticProviders,
  product: AnimatronProduct
) => {
  const analyticProviders = Object.keys(analyticConfigs).reduce(
    (acc, providerName: AnalyticProviderNames) => {
      const { id, ...other } = analyticConfigs[providerName];
      acc[providerName] = {
        ...other,
        id: id!
      };

      return acc;
    },
    {} as AnalyticProviders
  );

  initTrackerLibs(analyticProviders, product);
};

export default initAnalytic;
