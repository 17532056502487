import { MediaType } from '@anm/api/modules/youtube';
import { TabsContentWrapper, TabsPanelWrapper } from '@anm/components/HorizontalTabs/Wrapper';
import { FC } from 'react';
import styled from 'styled-components';

import Tabs from '../Tabs';

import DownloadMediaMobile, { DownloadMediaMobileProps } from './DownloadMediaMobile';

export type MobileTabsProps = DownloadMediaMobileProps & {
  onChangeMediaType: (mediaType: MediaType) => void;
};

const TabsWrapper = styled(Tabs)`
  margin-top: 30px;

  ${TabsPanelWrapper} {
    width: 100%;
    padding: 8px;
    background: #f4f6f7;
    border-radius: 30px;
    justify-content: space-around;
  }

  ${TabsContentWrapper} {
    margin-top: 30px;
  }
`;

const MobileTabs: FC<MobileTabsProps> = ({ options, startDownload, onChangeMediaType }) => {
  return (
    <TabsWrapper onChangeMediaType={onChangeMediaType}>
      <div>
        <DownloadMediaMobile options={options} mediaType="video" startDownload={startDownload} />
      </div>
      <div>
        <DownloadMediaMobile options={options} mediaType="audio" startDownload={startDownload} />
      </div>
    </TabsWrapper>
  );
};

export default MobileTabs;
