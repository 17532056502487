import { media } from '@anm/styles/helpers';
import styled from 'styled-components';

export type ContainerSize = {
  size?: number;
  fullWidth?: boolean;
};

const setSizeByProps = ({ size = 1160, fullWidth }: ContainerSize) => (fullWidth ? '100%' : `${size}px`);

const containerOnDesktop = media.desktop`
  padding: 0 20px;
`;

const Container = styled.div`
  padding: 0;
  margin: 0 auto;
  max-width: ${setSizeByProps};
  ${containerOnDesktop};
`;

export default Container;
