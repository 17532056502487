import styled, { css } from 'styled-components';

export type LabelVariant = 'animated-inside' | 'static-above' | 'static-above-dark-uppercase';

type InputLabelProps = {
  variant: LabelVariant;
  isFocused: boolean;
  isPlaceholderShown: boolean;
  isValue?: boolean;
  isRequired?: boolean;
};

const animatedLabelFocusedStyles = css`
  transform: translate(0, 8px) scale(0.75);

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    & ~ div {
      transform: translate(0, 8px) scale(0.75);
    }
  }
`;

const variants: { [key in LabelVariant]: ReturnType<typeof css> } = {
  'animated-inside': css`
    font-size: 16px;
    position: absolute;
    top: 0;
    left: 15px;
    transform-origin: top left;
    transform: translate(0, 15px) scale(1);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    pointer-events: none;

    ${({ isValue, isFocused, isPlaceholderShown }: InputLabelProps) =>
      (isValue || isFocused || !isPlaceholderShown) && animatedLabelFocusedStyles}
  `,
  'static-above': css`
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    margin-bottom: 7px;
  `,
  'static-above-dark-uppercase': css`
    font-size: 12px;
    text-transform: uppercase;
    color: #292a2b;
    margin-bottom: 5px;
  `
};

const requiredFieldLabelStyles = css`
  white-space: nowrap;
  &:after {
    color: #e32;
    content: ' *';
    display: inline;
  }
`;

export default styled.div<InputLabelProps>`
  color: #919eab;
  ${({ variant }) => variants[variant]}
  ${({ isRequired }) => isRequired && requiredFieldLabelStyles}
`;
