import { ApiError } from './../../../api/types';
import { call, put } from '@anm/helpers/saga/effects';

export type AsyncFunc = (...args: any[]) => any;
type Args<T> = T extends (args: infer U) => void ? U : any;

export type AsyncEntity = {
  request: AsyncFunc;
  success: AsyncFunc;
  failure: AsyncFunc;
};

export type AsyncEntityKey = keyof AsyncEntity;

export const createWsEntity = (sendWsMessage: AsyncFunc) => <T extends AsyncEntity, F extends AsyncFunc>(
  entity: T,
  asyncFn: F,
  cdsNames: AsyncEntityKey[] = ['request', 'success', 'failure']
) => {
  const cds = cdsNames.reduce((acc, key) => ({ ...acc, [key]: sendWsMessage }), {} as Partial<AsyncEntity>);

  return asyncEntity(entity, asyncFn, cds);
};

const asyncEntity = <T extends AsyncEntity, F extends AsyncFunc>(entity: T, asyncFn: F, cds?: Partial<AsyncEntity>) =>
  function* fetchEntity<R>(asyncFnPayload?: Args<F>, requestPayload?: R) {
    try {
      const request = entity.request(requestPayload || asyncFnPayload);
      yield put(request);
      if (cds?.request) yield* call(cds.request, request);

      const res = yield* call(asyncFn as any, asyncFnPayload);

      if ((res as ApiError).code === 404) {
        throw res;
      }

      const success = entity.success(res);
      yield put(success);
      if (cds?.success) yield* call(cds.success, success);
    } catch (err) {
      const failure = entity.failure(err);
      yield put(failure);
      if (cds?.failure) yield* call(cds.failure, failure);
    }
  };

export default asyncEntity;
