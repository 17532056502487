import { MediaType } from '@anm/api/modules/youtube';
import { useContext, useState, FC } from 'react';

import DownloadMedia, { DownloadMediaProps } from './DownloadMedia';
import Tabs from './Tabs';
import AudioActions from './actions/AudioActions';
import VideoActions from './actions/VideoActions';
import { MediaDownloaderContext } from './context/MediaDownloaderProvider';

export type DesktopTabsProps = Pick<DownloadMediaProps, 'startDownload' | 'options'> & {
  isOpenEditorPending?: boolean;
  onChangeIntent: (intent: string) => void;
  onChangeMediaType: (mediaType: MediaType) => void;
};

const VideoTab: FC<DesktopTabsProps> = ({ onChangeIntent, isOpenEditorPending, options, startDownload }) => {
  const [canDownload, setCanDownload] = useState(true);
  const { mediaInfoState } = useContext(MediaDownloaderContext);

  return (
    <div>
      <DownloadMedia
        mediaType="video"
        options={options}
        startDownload={startDownload}
        onCanDownloadChange={setCanDownload}
      />

      <VideoActions
        canEdit={canDownload && !mediaInfoState.isPending}
        isPending={isOpenEditorPending}
        onClick={onChangeIntent}
      />
    </div>
  );
};

const DesktopTabs: FC<DesktopTabsProps> = props => {
  const { options, isOpenEditorPending, startDownload, onChangeIntent, onChangeMediaType } = props;
  const { mediaInfoState } = useContext(MediaDownloaderContext);
  return (
    <Tabs onChangeMediaType={onChangeMediaType}>
      <VideoTab {...props} />
      <div>
        <DownloadMedia mediaType="audio" startDownload={startDownload} options={options} />
        <AudioActions canEdit={!mediaInfoState.isPending} onClick={onChangeIntent} isPending={isOpenEditorPending} />
      </div>
    </Tabs>
  );
};

export default DesktopTabs;
