import isApiError from '@anm/api/helpers/isApiError';
import { AUTH_COOKIE, SECURE_TOKEN_HEADER_NAME } from '@anm/constants/auth';
import * as cookie from '@anm/helpers/cookie';
import { User } from 'user';

import { saveUserProfile } from '../userLocalStore';

type Headers = {
  [key: string]: string;
};

const fetchUser = async (apiUrl: string) => {
  const authCookie = cookie.get(AUTH_COOKIE);
  if (!authCookie) return null;

  const headers: Headers = {
    [SECURE_TOKEN_HEADER_NAME]: authCookie
  };

  const userResponse = await fetch(`${apiUrl}profile`, {
    headers
  });

  const res = (await userResponse.json()) as User;

  if (isApiError(res)) return Promise.reject(res);

  saveUserProfile(res);

  return res;
};

export default fetchUser;
