import { Request } from '@anm/api';
import axios from 'axios';

import { getThrottleAdapter } from './../../helpers/cacheAdapters';
import {
  CreateUploadByJobIdResponse,
  CreateVideoUploadByJobIdResponse,
  DownloadBlobProps,
  DownloadJobIdProps,
  GetMediaInfoResponse,
  MediaInfoProps,
  UploadStatus
} from './types';

export * from './types';

export const getMediaInfo = (() => {
  const cacheAdapter = getThrottleAdapter(15 * 1000);
  let cancelInfo: Function | undefined;
  let prevType: string | undefined;

  return (request: Request) => async ({ url, type }: MediaInfoProps) => {
    prevType === type && cancelInfo?.('canceled');
    prevType = type;

    return request<GetMediaInfoResponse>({
      url: 'info',
      params: { url, type },
      adapter: cacheAdapter,
      cancelToken: new axios.CancelToken(c => (cancelInfo = c))
    });
  };
})();

export const getDownloadJobId = (request: Request) => async ({ url, type }: DownloadJobIdProps) =>
  request<string>({
    url: 'query',
    params: { url, type }
  });

export const createUploadByJobId = (request: Request) => (jobId: string) =>
  request<CreateUploadByJobIdResponse>({
    method: 'POST',
    url: `save/${jobId}`
  });

export const createVideoUploadByJobId = (request: Request) => (url: string) =>
  request<CreateVideoUploadByJobIdResponse>({
    method: 'POST',
    url: 'save-new',
    params: { url }
  });

export const checkUploadStatus = (request: Request) => (jobId: string) =>
  request<UploadStatus>({
    url: `status/${jobId}`
  });

export const downloadBlob = (request: Request) => ({ url, filename }: DownloadBlobProps) =>
  request({
    url,
    responseType: 'blob'
  }).then((response: any) => {
    const href = window.URL.createObjectURL(new Blob([response.data]));
    console.log('response = ', response);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
