import Container from '@anm/components/Container';
import { FC } from 'react';
import PlayerModal from '../../modals/PlayerModal';

import Tutorial from './Tutorial';
import Wrapper, { EmptyList, InputContainer, InputIcon, SearchField } from './Wrapper';
import { useOpenModalFromUrl, useTutorialListFilter } from './hooks';

export type TutorialProps = {
  title: string;
  videoId: string;
  videoTime: string;
};

export type TutorialListProps = {
  list: TutorialProps[];
};

const TutorialList: FC<TutorialListProps> = ({ list }) => {
  const [tutorials, search, handleSearchChange] = useTutorialListFilter(list);
  const isNoResult = tutorials.length === 0;

  const [videoId, handleModalClose] = useOpenModalFromUrl(list);

  return (
    <>
      {videoId && <PlayerModal {...{ videoId }} onModalClose={handleModalClose} />}
      <Wrapper>
        <Container size={1000}>
          <InputContainer>
            <SearchField type="text" value={search} placeholder="Search" onChange={handleSearchChange} />
            <InputIcon alt="search icon" src="images/icons/search_filter_v1.svg" />
          </InputContainer>
          {tutorials.map((props, index) => (
            <Tutorial key={props.title + index} {...props} />
          ))}
          {isNoResult && (
            <EmptyList>
              <p>{`${search} - not found in the Tutorials`}</p>
            </EmptyList>
          )}
        </Container>
      </Wrapper>
    </>
  );
};

export default TutorialList;
