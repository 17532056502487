import { media, PlyrVideoTabletStyles } from '@anm/styles/helpers';
import plyrDefaultStyles from '@anm/styles/third-party/plyr';
import styled from 'styled-components';

export const PlyrConrolOverlaidTabletStyles = media.tablet`
  height: 80px;
  width: 80px;
`;

export const PlyrPlayerTabletStyles = media.tablet`
  width: 100%;
`;

export const PlyrTabletStyles = media.tablet`
  height: 100%;
`;

const VideoWrapper = styled.div`
  ${plyrDefaultStyles};

  margin: 0 auto;
  text-align: center;
  z-index: 124;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;

  .plyr {
    height: auto;
    min-width: auto;

    ${PlyrTabletStyles};

    video {
      height: intrinsic;
      margin: 0 auto;
      max-width: 100%;
      margin-bottom: 1px;
      ${PlyrVideoTabletStyles};
      ${PlyrPlayerTabletStyles};
    }

    &.plyr--video {
      background-color: transparent;
      width: 100%;

      .plyr__control {
        svg {
          fill: #fff;
          filter: none;
        }
        &:hover {
          background-color: transparent;
          svg {
            fill: var(--blue_100);
          }
        }
        &[data-plyr='play'] {
          background: none;
          margin-right: 0;
          &.plyr__control--overlaid {
            background-color: var(--blue_100);
            width: 120px;
            height: 120px;
            transform: translate(-50%, -50%) scale(0.8);
            ${PlyrConrolOverlaidTabletStyles};
            svg {
              height: 44px;
              width: 50px;
              margin: 0 auto;
            }
            &:hover {
              transform: translate(-50%, -50%) scale(1);
              svg {
                fill: #fff;
              }
            }
          }
        }
      }
    }

    .plyr__controls {
      z-index: 101;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 10px 10px 20px;
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
      color: #aaa;
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    }

    .plyr__time {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      text-shadow: none;
      margin-right: auto;
    }

    .plyr__progress__buffer {
      box-shadow: none;
      width: 100%;
      margin: 0;
      color: transparent;
      height: 4px;
    }

    .plyr__progress {
      width: calc(100% - 16px);
      left: 8px;
      bottom: 0px;
      position: absolute;
      margin: 0;
    }

    .plyr__menu {
      display: none;
    }

    .plyr__control[data-plyr='mute'] {
      display: block !important;
    }

    .plyr__volume {
      flex: 0;
    }

    input[type='range'] {
      color: #fff;
      width: 100%;
      margin: 0;
      &::-webkit-slider-runnable-track {
        border-radius: 0;
        background-color: rgba(255, 255, 255, 0.3);
        height: 4px;
      }
      &::-webkit-slider-thumb {
        opacity: 0;
      }
      &::-moz-range-thumb {
        opacity: 0;
      }
      &::-moz-range-progress {
        border-radius: 0;
        background-color: #fff;
      }
      &::-moz-range-track {
        background-color: rgba(255, 255, 255, 0.3);
        height: 4px;
      }
    }

    &.plyr--hide-controls {
      .plyr__controls {
        background-image: none;
      }
    }

    &.plyr--video {
      opacity: 1;
    }
  }

  .plyr__poster {
    background-color: transparent;
    display: none;
  }

  .plyr__video-wrapper {
    background: transparent;
    height: 100%;
    display: flex;
  }
`;

export default VideoWrapper;
