import { FC } from 'react';

import WistiaPlayer from '../../../landing/LandingVideo';

type WistiaPlayerProps = {
  wistiaId: string;
  imageUrl: string;
};

const WistiaPlayerWrapper: FC<WistiaPlayerProps> = ({ wistiaId, imageUrl }) => (
  <WistiaPlayer
    {...{ wistiaId, imageUrl }}
    autoplay
    alwaysInModal
    playIconUrl="images/icons/btn-play-dark.svg"
  />
);

export default WistiaPlayerWrapper;
