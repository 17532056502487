import { TEMPLATES_AMOUNT } from '@anm/constants/templates_amount';
import isMobile from '@anm/helpers/is/isMobile';
import { FC, KeyboardEvent } from 'react';

import Wrapper, { ClearIcon, Input, SearchIcon } from './Wrapper';

export * from './Wrapper';

type SearchProps = {
  text: string;
  onBlur(): void;
  clearInput(): void;
  updateFilter(): void;
  onKeyDown(event: KeyboardEvent<HTMLInputElement>): void;
  selectField(event: { target: HTMLInputElement }): void;
  handleChange(event: { target: HTMLInputElement }): void;
};

const placeholder = isMobile() ? 'Search templates' : `Search ${TEMPLATES_AMOUNT}+ templates`;

const Search: FC<SearchProps> = ({ text, onBlur, onKeyDown, clearInput, selectField, handleChange, updateFilter }) => (
  <Wrapper>
    <SearchIcon src="images/icons/search_filter_v1.svg" onClick={updateFilter} alt="search Icon" />
    {text && <ClearIcon src="images/icons/cross_clear_ic.svg" onClick={clearInput} alt="clear icon" />}
    <Input
      type="text"
      value={text}
      onChange={handleChange}
      onFocus={selectField}
      {...{ onKeyDown, onBlur, placeholder }}
    />
  </Wrapper>
);

export default Search;
