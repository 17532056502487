import styled, { css } from 'styled-components';

import { media } from './../../styles/helpers';
import { SectionTitleColor, SectionTitleProps } from './index';

const colors: { [key in SectionTitleColor]: ReturnType<typeof css> } = {
  black: css`
    color: #292a2b;
  `,
  white: css`
    color: #fff;
  `
};

const HeadingOnLaptops = media.laptop`
    font-size: 40px;
    line-height: 50px;
`;

const ParagraphOnLaptop = media.laptop`
    font-size: 16px;
    line-height: 25px;
    margin-left: auto;
    margin-right: auto;
`;

const TitleWrapper = styled.div<SectionTitleProps>`
  ${({ color }) => colors[color]};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
    ${HeadingOnLaptops};
  }

  p {
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
    text-align: center;
    ${ParagraphOnLaptop};
  }
`;

export const Description = styled.p``;

export default TitleWrapper;
