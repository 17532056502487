import { AUTH_COOKIE } from '@anm/constants/auth';
import * as cookie from '@anm/helpers/cookie';

const logoutRequest = () => {
  const token = cookie.get(AUTH_COOKIE);
  if (!token) return null;

  const { TS_ENV } = process.env;
  const baseUrl = TS_ENV === 'prod' ? 'api.wave.video' : 'api.wave-test.video';

  const url = `https://${baseUrl}/profile/sessions/${token}`;
  const headers = {
    'Content-Type': 'application/json',
    'Animatron-Security-Token': token
  };

  return fetch(url, {
    headers,
    method: 'DELETE'
  });
};

export default logoutRequest;
