import * as analytic from '@anm/analytic';
import useSafeAction from '@anm/auth/hooks/useSafeAction';
import Dropdown from '@anm/components/Dropdown';
import DropdownOptions from '@anm/components/NewVideoButton/DropdownOptions';
import FormatAndStyleModal from '@anm/components/my-projects/FormatAndStyleModal';
import FormatModal from '@anm/components/my-projects/FormatModal';
import VideoFromTextModal from '@anm/components/my-projects/VideoModals/VideoFromTextModal';
import VideoFromUrlModal from '@anm/components/my-projects/VideoModals/VideoFromUrlModal';
import RecorderModal from '@anm/components/stream/RecorderModal';
import RecordingSelectorModal from '@anm/components/stream/RecordingSelectorModal';
import { DEFAULT_DROPDOWN_DELAY } from '@anm/constants/delays';
import isMobile from '@anm/helpers/is/isMobile';
import isMobileOrIPad from '@anm/helpers/is/isMobileOrIPad';
import * as semrush from '@anm/helpers/semrush';
import useFetchTemplatesFormats from '@anm/hooks/useFetchTemplatesFormats';
import useHover from '@anm/hooks/useHover';
import useSwitchState from '@anm/hooks/useSwitchState';
import { Features } from '@anm/shared/features';
import { useCallback, FC } from 'react';

import ProgressButton from '../buttons/ProgressButton';
import useVideoFromAILimit from '../modals/VideoFromAILimitModal/hooks/useVideoFromAILimit';

import { CreateVideoButton, WrapperNewVideo } from './Wrapper';
import { getUserTimeList } from './helpers';
import {
  useGenerateVideoFromLink,
  useGenerateVideoFromText,
  useProjectActionsHandlers,
  useTemplateModal
} from './hooks';
import { NewVideoButtonProps } from './types';

const NewVideoButton: FC<NewVideoButtonProps> = ({
  apiUrl,
  jobId,
  isProd,
  waveUrl,
  progress,
  workspace,
  editorUrl,
  isPendingJobId,
  streamingStudioUrl,
  timeList: userTimeList,
  clearJobId,
  onCreateVideo,
  renderUploadButton,
  renderLinkWrapper,
  onCreateVideoFromText,
  onCreateVideoFromLink,
  isUserLogged,
  isUserFree,
  isUserStaff,
  videoFromAILimit,
  autoClose = true,
  currentFolderId,
  direction = 'to-bottom-right-low-tunnel',
  ...buttonProps
}) => {
  const [isFormatStyleModal, openFormatStyleModal, handleCloseTemplateModal] = useTemplateModal();
  const [
    isOpenFormatsModal,
    formatModalLinkTarget,
    getFormatUrl,
    handleStocksOpen,
    handleBlankVideo,
    handleBlankImage,
    handleFormatModalClose
  ] = useProjectActionsHandlers({ jobId, waveUrl, editorUrl, isUserLogged, currentFolderId });

  const [
    isOpenGenerateVideoFromLinkModal,
    handleCreateVideoFromLink,
    openGenerateVideoFromLinkModal,
    closeGenerateVideoFromLinkModal
  ] = useGenerateVideoFromLink({ jobId, isUserLogged, onCreateVideoFromLink, openFormatsModal: openFormatStyleModal });

  const [
    isOpenGenerateVideoFromTextModal,
    handleCreateVideoFromText,
    openGenerateVideoFromTextModal,
    closeGenerateVideoFromTextModal
  ] = useGenerateVideoFromText({ jobId, isUserLogged, onCreateVideoFromText, openFormatsModal: openFormatStyleModal });

  const [hoverRef, isHovered] = useHover<HTMLDivElement>();

  const templates = useFetchTemplatesFormats({
    apiUrl,
    isOpenModal: isOpenGenerateVideoFromLinkModal || isOpenGenerateVideoFromTextModal
  });

  const timeList = getUserTimeList(userTimeList);

  const handleDropdownOpen = useCallback(() => {
    analytic.trackOpenCreateVideoDropdown();
  }, []);

  const dropdownCloseDelay = isMobileOrIPad() ? 0 : DEFAULT_DROPDOWN_DELAY;

  const isCustomizedButton = !!buttonProps.title;

  const [_, limitGuard, renderModal] = useVideoFromAILimit({ limit: videoFromAILimit });

  const isSemrush = semrush.isSemrushIframe();
  const semrushParam = isSemrush ? '&isSemrush=true' : '';
  const recordingUrl = `${streamingStudioUrl}new-recording?folder=${currentFolderId}&skipSetup=yes${semrushParam}`;

  const [isRecordingOptionsModal, openRecordingOptions, closeRecordingOptions] = useSwitchState();
  const [isRecorderModal, openRecorder, closeRecorder] = useSwitchState();

  const handelSimpleSelect = () => {
    openRecorder();
    closeRecordingOptions();
  };
  const recordingAction = () =>
    Features.videoRecorderChooser && !isMobile() ? openRecordingOptions() : window.open(recordingUrl, '_self');

  const getSafeAction = useSafeAction(isUserLogged);
  const signupAndOpenStudio = getSafeAction(recordingAction);

  return (
    <>
      {renderModal()}
      {isRecordingOptionsModal && (
        <RecordingSelectorModal
          advancedLink={{
            href: recordingUrl
          }}
          onSimpleSelect={handelSimpleSelect}
          title="Please choose how would you like to record:"
          onModalClose={closeRecordingOptions}
        />
      )}
      {isRecorderModal && <RecorderModal waveUrl={waveUrl} folderId={currentFolderId} onModalClose={closeRecorder} />}
      {isOpenGenerateVideoFromLinkModal && (
        <VideoFromUrlModal
          {...{ timeList }}
          clearJobId={clearJobId}
          isPending={isPendingJobId}
          onCreateVideo={handleCreateVideoFromLink}
          onModalClose={closeGenerateVideoFromLinkModal}
        />
      )}
      {isOpenGenerateVideoFromTextModal && (
        <VideoFromTextModal
          {...{ timeList }}
          clearJobId={clearJobId}
          isPending={isPendingJobId}
          onCreateVideo={handleCreateVideoFromText}
          onModalClose={closeGenerateVideoFromTextModal}
        />
      )}
      {isOpenFormatsModal && (
        <FormatModal
          {...{ getFormatUrl, isUserFree }}
          linkTarget={formatModalLinkTarget}
          onModalClose={handleFormatModalClose}
        />
      )}
      {isFormatStyleModal && (
        <FormatAndStyleModal
          {...{ templates, jobId, editorUrl }}
          folderId={currentFolderId}
          onCreateVideo={onCreateVideo}
          onModalClose={handleCloseTemplateModal}
        />
      )}
      <WrapperNewVideo ref={hoverRef} data-button="createProject" isUploadInProgress={!!progress}>
        <Dropdown
          autoClose={autoClose}
          onOpen={handleDropdownOpen}
          openDelay={dropdownCloseDelay}
          closeDelay={dropdownCloseDelay}
          isModalOnMobile={true}
          openerVariant="no-icon"
          direction={direction}
          renderContent={() => (
            <DropdownOptions
              workspace={workspace}
              renderLinkWrapper={renderLinkWrapper}
              renderUploadButton={renderUploadButton}
              onNewRecording={signupAndOpenStudio}
              onBlankVideo={handleBlankVideo}
              onBlankImage={handleBlankImage}
              onStocksOpen={handleStocksOpen}
              onGenerateFromText={limitGuard(openGenerateVideoFromTextModal)}
              onGenerateFromLink={limitGuard(openGenerateVideoFromLinkModal)}
            />
          )}
        >
          {progress ? (
            <ProgressButton
              totalFiles={1}
              currentUploadIndex={1}
              progressValue={progress}
              variant={buttonProps.variant}
            />
          ) : (
            <CreateVideoButton
              size="large_long"
              icon={isCustomizedButton ? '' : 'images/dashboard/plus_small.svg'}
              iconSize="18x18"
              variant={isHovered ? 'dark-blue' : 'light-blue'}
              {...buttonProps}
            >
              {buttonProps.title || 'Create'}
            </CreateVideoButton>
          )}
        </Dropdown>
      </WrapperNewVideo>
    </>
  );
};

export default NewVideoButton;
