import { UploadFile, UploadFolder } from '@anm/api/modules/uploads';
import asyncEntityHandlers from '@anm/helpers/redux/asyncEntityHandlers';
import pickRequestFailure from '@anm/helpers/redux/pickRequestFailure';
import reduceFactory from '@anm/helpers/redux/reduceFactory';
import { uploadsActions, UploadsActions, UploadsState } from '@anm/store/modules/uploads';
import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import { setIsStorageFull } from '../actions';

import newUploadReducer from './newUploadReducer';
import searchUploadsReducer from './searchUploadsReducer';
import uploadsListReducer from './uploadsListReducer';

export const DEFAULT_FETCH_PARAMS = { page: 1, pageSize: 60, path: '/' };

const commonParams = {
  data: null,
  params: null,
  isError: false,
  isPending: false
};

export const DEFAULT_BREADCRUMBS = [{ id: '', name: '' }];

export const initialUploadsState: UploadsState = {
  ...commonParams,
  search: '',
  isSearchOpen: false,
  hasMoreUploads: false,
  breadcrumbs: DEFAULT_BREADCRUMBS,
  fetchMediaUpload: commonParams,
  updateFolderMeta: commonParams,
  updateMediaMeta: commonParams,
  createFolder: commonParams,
  deleteFolder: commonParams,
  uploadProjects: commonParams,
  uploadForBrand: commonParams,
  uploading: {
    isError: false,
    isPending: false,
    files: [],
    uploads: {}
  }
};

const uploadsReducer: Reducer<typeof initialUploadsState, UploadsActions> = (state = initialUploadsState, action) => {
  switch (action.type) {
    case getType(uploadsActions.changeSearchOpen):
    case getType(uploadsActions.updateSearchTerm):
      return searchUploadsReducer(state, action);

    case getType(uploadsActions.updateBreadcrumbs):
    case getType(uploadsActions.fetchUploadsAsync.success):
    case getType(uploadsActions.updateFolderPathAsync.success):
    case getType(uploadsActions.updateMediaMetaAsync.success):
    case getType(uploadsActions.createFolderAsync.success):
    case getType(uploadsActions.removeNewAttributeFromFolder):
    case getType(uploadsActions.deleteFolderAsync.success):
    case getType(uploadsActions.removeListItem):
      return uploadsListReducer(state, action);

    case getType(uploadsActions.clearUploadProjects):
      return { ...state, uploadProjects: commonParams };
    case getType(uploadsActions.clearUploadForBrand): {
      return {
        ...state,
        uploadForBrand: commonParams
      };
    }
    case getType(uploadsActions.moveUploadToFolderAsync.success): {
      const list8 = [...(state.data || [])].filter((u: UploadFile) => u.uploadId !== action.payload.id);

      return {
        ...state,
        data: list8
      };
    }
    case getType(uploadsActions.moveUploadFolderToFolderAsync.success): {
      const list9 = [...(state.data || [])].filter((u: UploadFolder) => u.folderId !== action.payload.id);

      return {
        ...state,
        data: list9
      };
    }

    case getType(uploadsActions.createUpload):
    case getType(uploadsActions.createUploadAsync.success):
    case getType(uploadsActions.updateProgress):
    case getType(uploadsActions.fetchUploadMediaAsync.success):
    case getType(uploadsActions.clearCancelToken):
    case getType(uploadsActions.clearUploadedFiles):
    case getType(setIsStorageFull):
    case getType(uploadsActions.changeUploadStatus):
    case getType(uploadsActions.completeMultipartUploadAsync.success): {
      return newUploadReducer(state, action);
    }

    default:
      return reduceFactory(initialUploadsState, {
        ...asyncEntityHandlers<UploadsState>(pickRequestFailure(uploadsActions.fetchUploadsAsync)),
        ...asyncEntityHandlers<UploadsState>(uploadsActions.getUploadProjectsAsync, 'uploadProjects'),
        ...asyncEntityHandlers<UploadsState>(pickRequestFailure(uploadsActions.createFolderAsync), 'createFolder'),
        ...asyncEntityHandlers<UploadsState>(pickRequestFailure(uploadsActions.deleteFolderAsync), 'deleteFolder'),
        ...asyncEntityHandlers<UploadsState>(uploadsActions.fetchUploadForBrandAsync, 'uploadForBrand', true),
        ...asyncEntityHandlers<UploadsState>(
          pickRequestFailure(uploadsActions.fetchUploadMediaAsync),
          'fetchMediaUpload',
          true
        ),
        ...asyncEntityHandlers<UploadsState>(
          pickRequestFailure(uploadsActions.updateFolderPathAsync),
          'updateFolderMeta',
          true
        ),
        ...asyncEntityHandlers<UploadsState>(pickRequestFailure(uploadsActions.updateMediaMetaAsync), 'updateMediaMeta')
      })(state, action);
  }
};

export default uploadsReducer;
