import { isAnon } from '@anm/auth/helpers/authAnon';
import useIsMountedCheck from '@anm/hooks/useIsMountedCheck';
import { FC } from 'react';

import withUser from '../../../HOCs/withUser';

export type AuthBlockProps = {
  isUserLogged: boolean;
  renderUserBlock: () => JSX.Element;
  renderAuthButtons: () => JSX.Element;
};

const AuthBlock: FC<AuthBlockProps> = ({ isUserLogged, renderUserBlock, renderAuthButtons }) => {
  const isMounted = useIsMountedCheck();
  if (!isMounted) return null;

  return <>{isUserLogged && !isAnon() ? renderUserBlock() : renderAuthButtons()}</>;
};

export default withUser(AuthBlock);
