import { FC } from 'react';

import { ListWrapper, MenuItem } from './Wrapper';
import { ContentListProps } from './types';

const ContentList: FC<ContentListProps> = ({ contentList, renderNavLink }) => (
  <ListWrapper>
    <ul>
      {contentList.map(({ title, ...otherProps }) => (
        <MenuItem key={`${title}-${otherProps.href}`} data-button={title}>
          {renderNavLink({ title, ...otherProps })}
        </MenuItem>
      ))}
    </ul>
  </ListWrapper>
);

export default ContentList;
