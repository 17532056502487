const PROMISE_PENDING = 'pending';
const PROMISE_RESOLVED = 'resolved';
const PROMISE_REJECTED = 'rejected';

const pendingState = {
  status: PROMISE_PENDING
};

const promiseState = <T>(promise: Promise<T>) => {
  return Promise.race([promise, pendingState]).then(
    value =>
      value === pendingState
        ? value
        : {
            value,
            status: PROMISE_RESOLVED
          },
    reason => ({
      reason,
      status: PROMISE_REJECTED
    })
  );
};

export const isPromiseResolved = async <T>(promise: Promise<T>) => {
  const { status } = await promiseState(promise);
  return status === PROMISE_RESOLVED;
};

export const isPromiseResolvedOrRejected = async <T>(promise: Promise<T>) => {
  const { status } = await promiseState(promise);
  return status === PROMISE_RESOLVED || status === PROMISE_REJECTED;
};
