import { stretchBlock } from '@anm/styles/helpers';
import styled from 'styled-components';

export const Overlay = styled.div`
  ${stretchBlock};
  cursor: pointer;
  height: calc(100% - 4px);

  &:hover {
    background: rgb(48, 174, 242, 0.2);
  }
`;
