import { noop } from 'lodash/fp';
import { useEffect, useRef, useState } from 'react';

export type MicStatus = {
  noVolume: boolean;
};

const useMicStatus = (volume: number, audioStream?: MediaStream | null) => {
  const clearRef = useRef(noop);
  const maxVolumeRef = useRef(0);
  const [status, setStatus] = useState<MicStatus>({ noVolume: false });

  useEffect(() => {
    if (!audioStream) return;
    clearRef.current();
    maxVolumeRef.current = 0;
    setStatus({ noVolume: false });

    const clearId = setTimeout(() => {
      maxVolumeRef.current === 0 && setStatus({ noVolume: true });
    }, 2 * 1000);

    clearRef.current = () => clearTimeout(clearId);
    return () => clearRef.current();
  }, [audioStream?.id]);

  useEffect(() => {
    maxVolumeRef.current = maxVolumeRef.current > volume ? maxVolumeRef.current : volume;
    maxVolumeRef.current > 0 && setStatus({ noVolume: false });
  }, [volume]);

  return status;
};

export default useMicStatus;
