import { toUrlParams } from '@anm/helpers/parseParams';

import { WithUserProps } from '../../../../HOCs/withUser';
import lpConfig from '../../../../config';

const { waveUrl, editorUrl } = lpConfig;

export type TemplateLinkProps = WithUserProps & {
  slug?: string;
  projectId?: string;
  videoFormat?: string;
};

const useEditTemplateLink = ({ slug, projectId, videoFormat }: TemplateLinkProps) => {
  const editorParams = {
    ...(videoFormat && { format: videoFormat })
  };

  const paramsQuery = editorParams.format ? `&${toUrlParams(editorParams)}` : '';

  const editorLink = `${editorUrl}/#r=${projectId}${paramsQuery}`;
  const videoTemplatesLink = `${waveUrl}video-templates/${slug}`;

  return slug ? videoTemplatesLink : editorLink;
};

export default useEditTemplateLink;
