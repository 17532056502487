import cutLengthTo from '@anm/helpers/parsers/cutLengthTo';
import toNumber from '@anm/helpers/parsers/toNumber';
import compose from 'lodash/fp/compose';

const toNumberGroup = (value: string) => {
  const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;

  return value.replace(regex, (_, $1, $2, $3, $4) =>
    [$1, $2, $3, $4].filter(group => !!group).join(' ')
  );
};

const parseCardNumber = compose(toNumberGroup, cutLengthTo(16), toNumber);

export default parseCardNumber;
