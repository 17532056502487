import { FC } from 'react';

import { ListContentProps } from '../types';

import ListItem from './ListItem';
import ListWrapper from './Wrapper';

const ListContent: FC<ListContentProps> = ({ list, ...otherProps }) => (
  <ListWrapper>
    {list.map(item => (
      <ListItem key={item.title} {...{ ...item, ...otherProps }} />
    ))}
  </ListWrapper>
);

export default ListContent;
