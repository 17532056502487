import mediaManager, { MediaDevicesList, VideoOptions } from '@anm/helpers/media/mediaManager';
import useDefaultDevices from '@anm/hooks/media/useDefaultDevices';
import { MediaIds } from '@anm/hooks/media/useMediaStreams';
import { useEffect } from 'react';

const forceToEnable = (media: MediaDevicesList<VideoOptions>) => media.updateOptions({ enable: true });
const useInitStream = (createStreams: (ids: Partial<MediaIds>) => void) => {
  const { webcam, mic, speaker } = useDefaultDevices();
  const isDevicesExist = !!webcam || !!mic || !!speaker;

  const { mics, webcams, speakers } = mediaManager();

  useEffect(() => {
    if (isDevicesExist) {
      [mics, webcams, speakers].forEach(forceToEnable);

      createStreams({
        webcamId: webcam?.deviceId,
        micId: mic?.deviceId
      });
    }
  }, [isDevicesExist]);
};

export default useInitStream;
