import { useEffect, useRef, useState } from 'react';

import { LandingButtonProps } from '../../../../components/LandingButton';
import { ActiveButton, ButtonsProps } from '../types';

const getActiveButton = ({ buttonA, buttonB }: ButtonsProps) => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const [button, setButton] = useState<Partial<LandingButtonProps> | undefined>(buttonA);
  const [wasButtonChanged, setWasButtonChanged] = useState(false);

  const getButton = (button: ActiveButton) =>
    ({
      buttonA,
      buttonB
    }[button]);

  const canWatchChanges = !!(buttonA && buttonB);

  const checkAttribute = () => {
    const attr = sectionRef.current?.getAttribute('data-button') as ActiveButton;
    const btn = getButton(attr);

    if (btn === button) return;

    setWasButtonChanged(true);
    setButton(btn);
  };

  useEffect(() => {
    const section = sectionRef.current;

    if (!canWatchChanges || wasButtonChanged || !section) return;

    const interval = setInterval(checkAttribute, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [canWatchChanges, wasButtonChanged, sectionRef.current]);

  return [sectionRef, button] as const;
};

export default getActiveButton;
