import Collapse from '@anm/components/Collapse';
import { HeaderNavigationItem, HrefLinkProps, RenderNavLinkProp } from '@anm/components/header';
import { FC } from 'react';

import { NestedLinksWrapper } from './Wrapper';

export type CollapsedLinksProps = { list: HeaderNavigationItem[] };

type NavigationItem = HrefLinkProps & {
  children: NavigationItem[];
};

type LinkProps = RenderNavLinkProp & {
  navList: NavigationItem[];
};

const Links: FC<LinkProps> = ({ navList, renderNavLink }) => (
  <>
    {navList.map(({ href, ...other }) => (
      <span>{renderNavLink({ href, ...other })}</span>
    ))}
  </>
);

const InnerLinks: FC<CollapsedLinksProps & RenderNavLinkProp> = ({ list, renderNavLink }) => {
  const isListOfLinks = !!list[0]?.href || !list[0].title;
  const links = list.reduce((acc, item) => [...acc, ...(item?.links ? item?.links : [item])], [] as NavigationItem[]);

  return (
    <>
      {isListOfLinks ? (
        <NestedLinksWrapper>
          <Links navList={links} {...{ renderNavLink }} />
        </NestedLinksWrapper>
      ) : (
        list.map(({ title, links }) => (
          <Collapse
            key={title}
            variant="plus-minus"
            isTargetClickable={true}
            renderTarget={() => <h3>{title}</h3>}
            renderContent={() => <Links navList={links} {...{ renderNavLink }} />}
          />
        ))
      )}
    </>
  );
};

export default InnerLinks;
