import { useEffect, useState } from 'react';

const defaultConfigs = {
  threshold: 0.01,
  rootMargin: '10%'
};

type IntersectionProps = {
  canObserve?: boolean;
  configs?: {
    threshold: number;
    rootMargin: string;
  };
  onObserveSuccess(): void;
};

const useIntersection = ({
  onObserveSuccess,
  canObserve = true
}: IntersectionProps) => {
  const [targetRef, setImageRef] = useState<Element | null>(null);

  useEffect(() => {
    let observer: IntersectionObserver;
    let didCancel = false;

    if (!targetRef || !canObserve) return;

    if (IntersectionObserver) {
      observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (
            !didCancel &&
            (entry.intersectionRatio > 0 || entry.isIntersecting)
          ) {
            onObserveSuccess();
            observer.unobserve(targetRef!);
          }
        });
      }, defaultConfigs);
      observer.observe(targetRef!);
    } else {
      // Old browsers fallback
      onObserveSuccess();
    }
    return () => {
      didCancel = true;
      if (observer && observer.unobserve) {
        observer.unobserve(targetRef!);
      }
    };
  }, [canObserve, targetRef]);

  return setImageRef;
};

export default useIntersection;
