import { FC } from 'react';

import { CircleProgressItem } from '../types';

import CircleContainer from './Wrapper';

const StatisticCircleItem: FC<CircleProgressItem> = ({
  val,
  icon,
  title,
  bigVal,
  smallVal
}) => (
  <li>
    <CircleContainer icon={icon}>
      {bigVal && <div className="big-value">{bigVal}</div>}
      {smallVal && <div className="small-value">{smallVal}</div>}
      {val && <div className="percent-value">{val}</div>}
    </CircleContainer>
    <p>{title}</p>
  </li>
);

export default StatisticCircleItem;
