import { uploadsActions } from '@anm/store/modules/uploads';
import { call, put } from '@anm/helpers/saga/effects';
import checkIsStorageError from '@anm/helpers/checkIsStorageError';
import { appNotificationActions } from '../appNotifications';
import bytesToMb from '@anm/helpers/size/bytesToMb';
import { Logger } from '@anm/helpers/Debugger';
import { uploadsApi } from '@anm/store/modules/uploads/api';
import getVideoInfo from '@anm/helpers/getVideoInfo';
import { HostingStats } from 'types/user';

const logger = new Logger('UploadRestrictions');

type CheckCanUploadProps = {
  canUpgrade: boolean;
  hostingStats: HostingStats | null;
  limits?: {
    maxSize: number;
    maxSeconds: number;
  };
  file?: File;
  isUpload?: boolean;
  objectUrl?: string;
  extension?: string;
  isFreeUser?: boolean;
  canIgnoreStorage?: boolean;
  canPurchaseStorage?: boolean;
};

const geStorageStats = async () => await uploadsApi().getUserStorageStats();

export const checkCanUploadByStorage = async (uploadedSize: number) => {
  const storageStats = await geStorageStats();

  return !checkIsStorageError({
    storageStats,
    uploadedSize
  });
};

function* checkCanUpload({
  file,
  limits,
  extension,
  objectUrl,
  canUpgrade,
  isFreeUser,
  hostingStats,
  isUpload = true,
  canIgnoreStorage = false
}: CheckCanUploadProps) {
  const { maxSize = 0, maxSeconds = 0 } = limits || {};

  const isTrafficReached = hostingStats?.state === 'disabled';

  function* clearUploadsState() {
    yield* put(uploadsActions.clearUploadedFiles());
  }

  if (isTrafficReached) {
    yield* put(
      appNotificationActions.notify({
        type: 'error',
        title: 'Uploading is not available. Your traffic reached the plan limit'
      })
    );
    yield* clearUploadsState();
    return;
  }

  if (!extension) {
    logger.error(`Can not get extension from video data`);
    return;
  }

  let uploadedSize = 0;
  if (isUpload && file) {
    uploadedSize = file.size;
  }

  if (isUpload) {
    const canUploadByStorage = canIgnoreStorage ? true : yield* call(checkCanUploadByStorage, uploadedSize);
    if (!canUploadByStorage) {
      yield* put(uploadsActions.setIsStorageFull(true));
      return;
    }
  }

  if (isUpload && file) {
    const fileSizeInMB = bytesToMb(uploadedSize);
    const isUploadFileTooLarge = fileSizeInMB > maxSize;
    const limit = isFreeUser ? `${maxSize}Mb` : `${maxSize / 1024}Gb`;

    if (isUploadFileTooLarge) {
      yield* put(
        appNotificationActions.notify({
          type: 'error',
          title: !canUpgrade
            ? `The video file is too large. Please upload file up to ${limit}`
            : `Please upgrade your plan to upload videos larger than ${limit}`
        })
      );

      yield* clearUploadsState();
      return;
    }

    if (objectUrl) {
      let duration = 0;

      yield getVideoInfo({ src: objectUrl, name: file.name })
        .then(info => (duration = info.duration))
        .catch(() => logger.warn(`Can not get duration from video`));

      const isUploadFileTooLong = duration > maxSeconds;
      const hoursLimit = `${maxSeconds / 60 / 60}`;

      if (isUploadFileTooLong) {
        yield* put(
          appNotificationActions.notify({
            type: 'error',
            title: !canUpgrade
              ? `The video file is too long. Please upload file up to ${hoursLimit} hours`
              : `Please upgrade to upload videos larger than ${hoursLimit} hours`
          })
        );
        return;
      }
    }
  }
  return true;
}

export default checkCanUpload;
