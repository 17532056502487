import createApiInstance from '../helpers/createApiInstance';
import presetAxiosInstance from '../helpers/presetAxiosInstance';
import * as auth from '../modules/auth';
import * as hosting from '../modules/hosting';
import * as uploads from '@anm/api/modules/uploads';
import * as storyboard from '../modules/storyboard';
import * as user from '../modules/user';
import { ApiConfig, MainApi } from '../types';

import youtubeApi from './youtubeApi';
export * from '../types';

const genApi = (apiConfig: ApiConfig) => {
  const { bind, axiosInstance } = presetAxiosInstance(apiConfig);

  return {
    axiosInstance,
    auth: bind(auth),
    user: bind(user),
    youtube: youtubeApi(),
    hosting: bind(hosting),
    uploads: bind(uploads),
    storyboard: bind(storyboard)
  } as MainApi;
};

const landingsApi = createApiInstance(genApi);

export default landingsApi;
