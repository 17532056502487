import { FC } from 'react';

import ColumnsList from './ColumnsList';
import Wrapper, { Content, LeftArrow, RightArrow } from './Wrapper';
import { useDropdownArrows, useDropdownMaxWidth } from './hooks';
import { ContentNestedListProps } from './types';

export * from './types';

const ContentNestedList: FC<ContentNestedListProps> = ({ isArrows, contentList, renderNavLink, contentMaxWidth }) => {
  const [wrapperRef, isLeftArrow, isRightArrow, onLeftButtonClick, onRightButtonClick] = useDropdownArrows();

  const [maxWidth, isDropdownContentBig] = useDropdownMaxWidth({
    wrapperRef,
    contentMaxWidth
  });

  const isSimple = !contentList[0].iconSrc;
  const canShowArrow = isDropdownContentBig && isArrows;

  return (
    <Wrapper ref={wrapperRef} {...{ maxWidth, isSimple }}>
      <Content {...{ isSimple }}>
        {isLeftArrow && canShowArrow && (
          <LeftArrow alt="left arrow" src="images/icons/header/header-content-arrow.svg" onClick={onLeftButtonClick} />
        )}
        {isRightArrow && canShowArrow && (
          <RightArrow
            alt="right arrow"
            src="images/icons/header/header-content-arrow.svg"
            onClick={onRightButtonClick}
          />
        )}
        <ColumnsList {...{ contentList, renderNavLink }} />
      </Content>
    </Wrapper>
  );
};

export default ContentNestedList;
