import Button from '@anm/components/buttons/Button/Button';
import { flexCenter, stretchBlock } from '@anm/styles/helpers';
import styled from 'styled-components';

import { media } from '../../../styles/helpers';

const SideMediaButtonsWrapperOnLaptop = media.laptop`
  justify-content: flex-start;
  margin-top: 30px;
  a {
    margin-bottom: 0;
  }
`;

const SideMediaButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  a {
    margin-bottom: 21px;
    padding: 0 20px;
  }
  ${Button} {
    position: relative;
    a {
      ${flexCenter};
      ${stretchBlock};
      color: #292a2b;
      font-size: 20px;
    }
  }
  ${SideMediaButtonsWrapperOnLaptop};
`;

export default SideMediaButtonsWrapper;
