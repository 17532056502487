import { MenuDivider, UserMenuDropdown, UserMenuHeader } from '@anm/components/header';
import { FC } from 'react';
import { User } from 'user';

import withUser from '../../../HOCs/withUser';
import lfConfig from '../../../config';
import UserAvatar from '../../UserAvatar';
import LogoutButton from '../../auth/LogoutButton';

export type UserMenuProps = {
  user: User;
};

type Link = {
  link: string;
  title: string;
};

// TODO need implement in landings
const isStaff = false;

const menuLinkList = [
  {
    title: 'My projects',
    link: `${lfConfig.waveUrl}videos`
  },
  isStaff && {
    title: 'My streams',
    link: `${lfConfig.waveUrl}lives`
  },
  {
    title: 'Profile',
    link: `${lfConfig.waveUrl}account/profile`
  },
  {
    title: 'Subscription',
    link: `${lfConfig.waveUrl}subscription`
  }
].filter(Boolean) as Link[];

const DropdownContent: FC<UserMenuProps> = ({ user }) => (
  <ul>
    {user && (
      <UserMenuHeader>
        <div>{user.displayName}</div>
        <div>{user.email}</div>
      </UserMenuHeader>
    )}
    {menuLinkList.map(({ title, link }, index) => (
      <>
        <li key={title}>
          <a href={link}>{title}</a>
        </li>
        {isStaff && index === 1 && <MenuDivider key="divider" />}
      </>
    ))}
    <li key="logout-button">
      <LogoutButton />
    </li>
  </ul>
);

const UserMenu: FC<UserMenuProps> = ({ user }) => (
  <UserMenuDropdown
    renderContent={() => <DropdownContent {...{ user }} />}
    direction="to-bottom-left-with-arrow"
    openerVariant="black"
  >
    <UserAvatar user={user} />
  </UserMenuDropdown>
);

export default withUser(UserMenu);
