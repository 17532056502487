import Button from '@anm/components/buttons/Button';
import { FC } from 'react';

import EnvHostingPlayer from '../../EnvHostingPlayer';

import { ContentPartWrapper, ListWrapper, Subtitle } from './Wrapper';
import { ContentListProps, ContentProps } from './types';

const ContentItem: FC<ContentProps> = ({
  videoId,
  subtitle,
  paragraph,
  customButton
}) => (
  <ContentPartWrapper>
    <Subtitle
      level={4}
      color="black"
      title={subtitle}
      description={paragraph}
    />
    {customButton && <Button size="big" variant="yellow" {...customButton} />}
    {videoId && <EnvHostingPlayer {...{ videoId }} />}
  </ContentPartWrapper>
);

const TextAsideContentList: FC<ContentListProps> = ({ content }) => (
  <ListWrapper>
    {content.map((item, index) => (
      <ContentItem {...item} key={item.title || index} />
    ))}
  </ListWrapper>
);

export default TextAsideContentList;
