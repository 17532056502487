import { FC } from 'react';
import { branch, compose, renderComponent } from 'recompose';

import { CommonLandingProps } from '../../../features/getLanding';
import getWaves from '../../../helpers/getWaves';
import Background from '../../Background';
import { LandingButtonProps } from '../../LandingButton';
import SectionPicture from '../../SectionPicture';

import SectionContent from './Gallery/SectionContent';
import GridGallery from './GridGallery';
import Wrapper, { ContentWrapper } from './Wrapper';
import { GridProps, MediaBlockProps } from './types';

export * from './types';

export type VideoGalleryProps = CommonLandingProps &
  GridProps &
  MediaBlockProps & {
    title: string;
    description?: string;
    isWavePlayer?: boolean;
    button?: LandingButtonProps;
  };

const VideoGalleryOldView: FC<VideoGalleryProps> = props => (
  <ContentWrapper>
    <SectionContent {...props} />
  </ContentWrapper>
);

const VideoGalleryNewView: FC<VideoGalleryProps> = props => {
  const { waves, customBackground, sectionPicture } = props;

  return (
    <Background
      variant={getWaves(waves) || 'white'}
      customBackground={customBackground}
      isSectionPicture={!!sectionPicture}
    >
      <SectionContent {...props} />
    </Background>
  );
};

const isNewView = ({ design = 'default' }: VideoGalleryProps) => design === 'new';
const isGridGallery = ({ list }: VideoGalleryProps) => !!list;

const VideoGalleryView = compose(
  branch(isNewView, renderComponent(VideoGalleryNewView)),
  branch(isGridGallery, renderComponent(GridGallery))
)(VideoGalleryOldView);

const VideoGallery: FC<VideoGalleryProps> = props => {
  const { title, sectionPicture } = props;

  return (
    <Wrapper>
      <VideoGalleryView {...props} />
      {sectionPicture && <SectionPicture pictureUrl={sectionPicture} title={title} />}
    </Wrapper>
  );
};

export default VideoGallery;
