import Collapse from '@anm/components/Collapse';
import ExpandMenuItem from '@anm/components/Collapse/ExpandMenuItem';
import styled from 'styled-components';

export const FaqCollapse = styled(Collapse)`
  transition: transform 0.4s cubic-bezier(0.39, 0.58, 0.57, 1);
`;

export const FaqContainer = styled.div`
  > div {
    background-color: inherit;
    border-top: 1px solid rgba(203, 220, 229, 0.5);
    cursor: pointer;
    font-size: 20px;
    font-weight: normal;
    line-height: 1.6;
    color: #292a2b;
    position: relative;
    &:last-of-type {
      margin-bottom: 0;
      border-bottom: 1px solid rgba(203, 220, 229, 0.5);
    }
    &:hover {
      background-color: rgba(32, 171, 210, 0.1);
    }
    ${ExpandMenuItem} {
      padding: 25px 60px 25px 20px;
    }
  }
`;

export const FaqAnswer = styled.div`
  font-size: 16px;
  line-height: 1.75;
  color: #292a2b;
  padding: 0 20px 20px;
  opacity: 1;
  height: auto;
  transition: all 0.2s ease;
  p {
    margin: 0 0 15px;
  }
  h5 {
    font-size: 16px;
    margin: 10px 0;
  }
  ul {
    margin-bottom: 15px;
  }
  li {
    list-style: disc inside;
  }
`;

export const FaqAnswerText = styled.div`
  a {
    color: #292a2b;
    text-decoration: underline;
    &:hover {
      color: #2294df;
    }
  }
`;

export const FaqItemWrapper = styled.div``;

export default FaqCollapse;
