import noop from '@anm/helpers/noop';
import { FC } from 'react';

import { colors } from '..';
import Wrapper, {
  ButtonClose,
  Content,
  NotificationDescription,
  NotificationIcon,
  NotificationTitle
} from '../Wrapper';
import { useCanShowNotification } from '../hooks';
import { NotificationBlockProps } from '../types';

import SlideAnimation from './SlideAnimation';

export * from '../types';

const getIcon = (name: string) => `images/icons/notification/${name}.svg`;

const NotificationBlock: FC<NotificationBlockProps> = ({
  type,
  title,
  timeout,
  iconSrc,
  variant = 'aside',
  children,
  description,
  onModalClose = noop
}) => {
  const [canShow, handleCloseNotification] = useCanShowNotification({ timeout, onModalClose });
  const handelWrapperClick = () => {
    timeout === Infinity && handleCloseNotification();
  };

  return (
    <SlideAnimation {...{ canShow }} onAnimationEnd={onModalClose}>
      <Wrapper {...{ variant }} onClick={handelWrapperClick}>
        <Content {...{ type, variant }}>
          <ButtonClose src={getIcon('cross')} onClick={handleCloseNotification} />
          <NotificationIcon {...{ type }} src={iconSrc || getIcon(type)} />
          {title && <NotificationTitle {...{ title }} size="small" color={colors[type]} />}
          {description && <NotificationDescription dangerouslySetInnerHTML={{ __html: description as string }} />}
          {children}
        </Content>
      </Wrapper>
    </SlideAnimation>
  );
};

export default NotificationBlock;
