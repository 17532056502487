import debounce from 'lodash/fp/debounce';
import { useEffect } from 'react';

type UseDebounceEventProps<
  T = { addEventListener: Window['addEventListener']; removeEventListener: Window['removeEventListener'] }
> = {
  getTarget: () => T;
  event: keyof WindowEventMap;
  callback: EventListener;
};
export const useDebounceEvent = ({ getTarget, event, callback }: UseDebounceEventProps) => {
  const debounceCallback = debounce(150)(callback);

  useEffect(() => {
    getTarget().addEventListener(event, debounceCallback);
    return () => {
      getTarget().removeEventListener(event, debounceCallback);
    };
  }, [callback]);
};

export const useViewportResize = (callback: EventListener) => {
  useDebounceEvent({ event: 'resize', getTarget: () => window.visualViewport, callback });
};

const useResize = (callback: EventListener) => {
  useDebounceEvent({ event: 'resize', getTarget: () => window, callback });
  useDebounceEvent({ event: 'orientationchange', getTarget: () => window, callback });
};

export default useResize;
