import Image from '@anm/components/image/Image';
import isEmail from '@anm/helpers/is/isEmail';
import isMobile from '@anm/helpers/is/isMobile';
import { useState, ChangeEvent, FC } from 'react';

import { FooterWrapperProps } from '../types';

import {
  ButtonText,
  FieldWrapper,
  FormGroup,
  FormWrapper,
  Input,
  Label,
  SendButton
} from './Wrapper';

const MAILCHILMP_EMAIL_FIELD_ID = 'MERGE0';

const NewsletterBlock: FC<FooterWrapperProps> = ({ isDark = false }) => {
  const [email, setEmail] = useState('');

  const handleEmailChange = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setEmail(target.value);

  return (
    <FormWrapper
      method="POST"
      target="_blank"
      action="https://video.us8.list-manage.com/subscribe/post"
    >
      <input type="hidden" name="u" value="e24e204ff9603b0ff731d2e1a" />
      <input type="hidden" name="id" value="ff5c4e3fda" />
      <FormGroup>
        <Label htmlFor={MAILCHILMP_EMAIL_FIELD_ID} {...{ isDark }}>
          Subscribe to our newsletter
        </Label>
        <FieldWrapper>
          <Input
            type="text"
            value={email}
            onChange={handleEmailChange}
            id={MAILCHILMP_EMAIL_FIELD_ID}
            name={MAILCHILMP_EMAIL_FIELD_ID}
            placeholder="Your email address"
          />
          <SendButton disabled={!isEmail(email)}>
            {!isMobile() && <ButtonText>Subscribe</ButtonText>}
            <Image src="images/icons/black_arrow.svg" alt="subscribe button" />
          </SendButton>
        </FieldWrapper>
      </FormGroup>
    </FormWrapper>
  );
};

export default NewsletterBlock;
