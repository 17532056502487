import { throttle } from 'lodash/fp';
import { useCallback, useEffect, useRef } from 'react';

const useThrottle = <F extends Function>(cb: F, delay: number) => {
  const cbRef = useRef(cb);

  const cbLocal = useCallback(
    throttle(delay, (...args) => cbRef.current(...args)),
    [delay]
  );

  useEffect(() => {
    cbRef.current = cb;
  });

  useEffect(() => {
    return () => {
      cbLocal.cancel();
    };
  }, []);
  return cbLocal;
};

export default useThrottle;
