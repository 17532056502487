import { isAnon } from '@anm/auth/helpers/authAnon';
import { DropdownContent, DropdownWrapper } from '@anm/components/Dropdown';
import NewVideoButton from '@anm/components/NewVideoButton';
import useVideoFromAILimit from '@anm/components/modals/VideoFromAILimitModal/hooks/useVideoFromAILimit';
import { UserContext } from '@anm/components/user/UserProvider';
import { useContext, useState, FC } from 'react';
import styled from 'styled-components';

import config from '../../../../config';
import { useCreateVideoFromSource, ROOT_FOLDER_ID } from '../DropdownButton/hooks/useDropdownButton';
import UploadButton from '../UploadButton';

import useDirection from './hooks/useDirection';
import { CreateVideoProps } from './types';

const Wrapper = styled.div<{ isProgress?: boolean }>`
  & > div {
    min-height: 60px;
    width: ${({ isProgress }) => (isProgress ? '250px' : '195px')};
    min-width: ${({ isProgress }) => (isProgress ? '250px' : '195px')};
  }

  ${DropdownContent} {
    width: fit-content;
  }

  ${DropdownWrapper} {
    justify-content: flex-start;
  }
`;

const CreateVideo: FC<CreateVideoProps> = props => {
  const { plan, isUserLogged } = useContext(UserContext);
  const [progress, setProgress] = useState('');

  const videoFromAILimit = config.guestLimits.maxVideoFromAI;

  const [jobId, handleCreateVideoFromSource, _, isPendingJobId, clearJobId] = useCreateVideoFromSource();
  const [handleCreateVideo] = useVideoFromAILimit({ limit: videoFromAILimit });

  const isUserFree = !!(isAnon() || plan?.name === 'WaveFree');
  const isUserStaff = plan?.name === 'Staff';

  const [direction, hoverRef] = useDirection();

  return (
    <Wrapper ref={hoverRef} isProgress={!!progress}>
      <NewVideoButton
        {...{
          jobId,
          progress,
          isUserFree,
          isUserStaff,
          isUserLogged,
          isPendingJobId
        }}
        autoClose={false}
        title={props.text}
        isProd={config.isProd}
        apiUrl={config.apiUrl}
        waveUrl={config.waveUrl}
        timeList={3}
        editorUrl={config.editorUrl}
        renderUploadButton={children => (
          <UploadButton {...props} onUploadProgress={setProgress} renderChildren={onClick => children(onClick)} />
        )}
        direction={direction}
        clearJobId={clearJobId}
        onCreateVideo={handleCreateVideo}
        currentFolderId={ROOT_FOLDER_ID}
        streamingStudioUrl={config.streamingStudioUrl}
        onCreateVideoFromText={params => handleCreateVideoFromSource({ params, source: 'text' })}
        onCreateVideoFromLink={params => handleCreateVideoFromSource({ params, source: 'url' })}
        videoFromAILimit={videoFromAILimit}
        {...props}
      />
    </Wrapper>
  );
};

export default CreateVideo;
