import { Component, ComponentType } from 'react';

export interface WithAnimations {}

const WithAnimations = <P extends object>(
  WrappedComponent: ComponentType<P & WithAnimations>
) => {
  class WithAnimations extends Component<P> {
    componentDidMount() {
      function pageAnimation() {
        const withAnimation = document.querySelectorAll(`.animated`);

        if (withAnimation && window.innerWidth >= 1200) {
          const animatedItems: HTMLElement[] = Array.prototype.slice.call(
            withAnimation,
            0
          );
          const currentElement = animatedItems.filter(el => {
            const dimension = el.getBoundingClientRect();
            const top = dimension.top;
            const bottom = dimension.bottom;
            const height = dimension.height;
            const topVisible = top + height >= 0;
            const botVisible = bottom - height <= window.innerHeight;

            const onScreen = topVisible && botVisible;
            if (onScreen) return el;
          });

          const animate = function(currentElement: Element[]) {
            currentElement.forEach(el => {
              if (el.classList.contains('active')) {
                return;
              }

              el.classList.add('active');
            });
          };

          animate(currentElement);
        }
      }

      window.addEventListener('load', () => pageAnimation());
      window.addEventListener('scroll', () => pageAnimation());
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  type PropsExcludingDefaults = Pick<P, Exclude<keyof P, keyof WithAnimations>>;
  type RecomposeProps = Partial<WithAnimations> & PropsExcludingDefaults;

  return (WithAnimations as ComponentType<P>) as ComponentType<RecomposeProps>;
};

export default WithAnimations;
