import { SupportedQualities } from '@anm/hosting-player/types';
import getExtensionFromPath from '../getExtensionFromPath';
import isStreamVideo from '../is/isStreamVideo';
import isSupportedCodec from '../is/isSupportedCodec';

type Media = {
  path: string;
  type: 'video' | 'image' | 'thumbnail' | 'audio';
  width: number;
  height: SupportedQualities;
  codec?: string;
  isOriginal?: boolean | null;
};

const canPlayOriginaVideo = (videos: Media[]) => {
  const isMp4 = (video: Media) => getExtensionFromPath(video.path) === 'mp4';
  const isHLS = (video: Media) => isStreamVideo(video.path);

  const originalVideo = videos.filter(v => v.isOriginal)[0];

  if (!originalVideo) return false;

  const canPlayOriginal = originalVideo.codec
    ? isSupportedCodec(originalVideo)
    : isMp4(originalVideo) || isHLS(originalVideo);

  return canPlayOriginal;
};

export default canPlayOriginaVideo;
