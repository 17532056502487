import { DropdownDirections } from '@anm/components/Dropdown';
import useHover from '@anm/hooks/useHover';
import { useEffect, useState } from 'react';

const MENU_HEIGHT = 385;
const BUTTON_HEIGHT = 60;

const useDirection = () => {
  const [direction, setDirection] = useState<DropdownDirections>();
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();

  useEffect(() => {
    if (!isHovered || !hoverRef.current) return;

    const screenHeight = window.innerHeight;
    const buttonPosition = hoverRef.current?.getBoundingClientRect().y + BUTTON_HEIGHT;

    const canFitToBottom = screenHeight - buttonPosition > MENU_HEIGHT;
    const canFitToRight = screenHeight - buttonPosition > (MENU_HEIGHT - BUTTON_HEIGHT) / 2;

    const direction = canFitToBottom
      ? 'to-bottom-right-low-tunnel'
      : canFitToRight
      ? 'to-right-centered'
      : 'to-top-center';

    setDirection(direction);
  }, [hoverRef.current, isHovered]);

  return [direction, hoverRef] as const;
};

export default useDirection;
