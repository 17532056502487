import { FC } from 'react';

import DefaultThumb from './DefaultThumb';

type ThumbProps = {
  thumb?: string;
  width: number;
  height: number;
};

const Thumb: FC<ThumbProps> = ({ thumb, width, height }) => (
  <>{thumb ? <img src={thumb} /> : <DefaultThumb {...{ width, height }} />}</>
);

export default Thumb;
