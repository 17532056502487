import { LinkProps } from '@anm/components/header';

import commonLinks from '../commonLinks';

export type WaveLinkKeys = keyof ReturnType<typeof getWaveLinks>;
export type WaveLinks = { [key in WaveLinkKeys]: LinkProps };

export type WaveLinksProps = {
  waveUrl: string;
  isProd: boolean;
};

const getWaveLinks = ({ waveUrl, isProd }: WaveLinksProps) => ({
  ...commonLinks(waveUrl),
  myProjects: {
    title: 'My projects',
    route: 'my-projects'
  },
  myStream: {
    title: 'My streams',
    route: 'lives'
  },
  termsUse: {
    title: 'Terms of Use',
    href: `${waveUrl}terms-of-service`,
    route: 'terms-of-service'
  },
  privacyPolicy: {
    title: 'Privacy Policy',
    href: `${waveUrl}privacy`,
    route: 'privacy'
  },
  cookiePolicy: {
    title: 'Cookie Policy',
    href: `${waveUrl}cookie-policy`,
    route: 'cookie-policy'
  },
  appleWebcal: {
    title: 'iCal',
    href: isProd
      ? 'webcal://library.animatron.io/inspiration-calendar/acalendar.ics'
      : 'webcal://s3.amazonaws.com/animatron-test-library/inspiration-calendar/acalendar.ics'
  },
  googleWebcal: {
    title: 'Google',
    href: isProd
      ? 'https://accounts.google.com/ServiceLogin?service=cl&passive=1209600&osid=1&continue=https://calendar.google.com/calendar/render?cid%3Dwebcal%3A%2F%2Flibrary.animatron.io%2Finspiration-calendar%2Fgcalendar.ics%26nocache%3D456&followup=https://calendar.google.com/calendar&scc=1'
      : 'https://accounts.google.com/ServiceLogin?service=cl&passive=1209600&osid=1&continue=https://calendar.google.com/calendar/render?cid%3Dwebcal%3A%2F%2Fs3.amazonaws.com%2Fanimatron-test-library%2Finspiration-calendar%2Fgcalendar.ics%26nocache%3D456&followup=https://calendar.google.com/calendar&scc=1'
  },
  outlookWebcal: {
    title: 'Outlook',
    href: isProd
      ? 'webcal://library.animatron.io/inspiration-calendar/acalendar.ics'
      : 'webcal://s3.amazonaws.com/animatron-test-library/inspiration-calendar/acalendar.ics'
  },
  socialTwitter: {
    title: 'twitter_v1',
    href: 'https://twitter.com/wave_video',
    attrs: { rel: 'noopener' }
  },
  socialFacebookDefault: {
    title: 'facebook_v1',
    href: 'https://www.facebook.com/wave.video',
    attrs: { rel: 'noopener' }
  },
  socialFacebookGroup: {
    title: 'facebook_v1',
    href: 'https://www.facebook.com/groups/wave.video',
    attrs: { rel: 'noopener' }
  },
  socialYoutube: {
    title: 'youtube_v1',
    href: 'https://www.youtube.com/wavevideo',
    attrs: { rel: 'noopener' }
  },
  socialInstagram: {
    title: 'instagram_v1',
    href: 'https://www.instagram.com/wave.video/',
    attrs: { rel: 'noopener' }
  },
  socialLinkedin: {
    title: 'linkedin_v1',
    href: 'https://www.linkedin.com/company/wavevideo/',
    attrs: { rel: 'noopener' }
  },
  socialPinterest: {
    title: 'pinterest_v1',
    href: 'https://pinterest.com/wave_video/',
    attrs: { rel: 'noopener' }
  },
  socialTelegram: {
    title: 'telegram_v2',
    href: 'https://t.me/s/wavevideo',
    attrs: { rel: 'noopener' }
  }
});

export default getWaveLinks;
