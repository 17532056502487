import Dropdown, { DropdownProps } from '@anm/components/Dropdown';
import noop from '@anm/helpers/noop';
import { FC } from 'react';

import ActionItem, { Item } from './ActionItem';
import ActionItemWrapper, { ActionItemLink } from './ActionItem/Wrapper';
export { ActionItemLink, ActionItemWrapper, Item };

export type Action = {
  name?: string;
  icon?: string;
  href?: string;
  target?: '_self' | '_blank';
  disabled?: boolean;
  iconHover?: string;
  autoClose?: boolean;
  desktopOnly?: boolean;
  handler?: () => void;
  renderLink?: (children: JSX.Element) => JSX.Element;
  renderComponent?: () => JSX.Element;
};

type ActionsDropdown = Partial<DropdownProps> & {
  actions: Action[];
  autoClose?: boolean;
  openDelay?: number;
  isBigIcon?: boolean;
  closeDelay?: number;
  dividerIndexes?: (number | undefined)[];
  isModalOnMobile?: boolean;
  isDisabledButton?: boolean;
  closeDropdown?(): void;
  renderContentHeader?(): JSX.Element;
  renderContentFooter?(): JSX.Element;
};

export const ActionList: FC<ActionsDropdown> = ({
  actions,
  autoClose: globalAutoClose = true,
  isBigIcon,
  dividerIndexes,
  children,
  closeDropdown
}) => {
  const actionsList = actions.map(({ name, autoClose = globalAutoClose, ...otherProps }, index) => (
    <ActionItem
      key={name || index}
      showDivider={dividerIndexes?.includes(index)}
      {...{
        name,
        children,
        isBigIcon,
        autoClose,
        closeDropdown,
        ...otherProps
      }}
    />
  ));

  return <ul>{actionsList}</ul>;
};

const ActionsDropdown: FC<ActionsDropdown> = ({
  children,
  className,
  openByAction,
  openDelay = 0,
  closeDelay = 0,
  isModalOnMobile,
  preventBubbling,
  autoClose = false,
  openerVariant = 'dots',
  direction = 'to-bottom-left-low-tunnel',
  isDisabledButton = false,
  onOpen,
  onClose,
  renderContentHeader = noop,
  renderContentFooter = noop,
  ...rest
}) => (
  <Dropdown
    {...{
      onOpen,
      onClose,
      children,
      openDelay,
      direction,
      autoClose,
      className,
      closeDelay,
      openByAction,
      openerVariant,
      preventBubbling,
      isDisabledButton,
      ...(isModalOnMobile && { isModalOnMobile })
    }}
    renderContent={({ closeDropdown }) => (
      <>
        {renderContentHeader()}
        <ActionList
          {...{
            ...rest,
            autoClose,
            closeDropdown
          }}
        />
        {renderContentFooter()}
      </>
    )}
  />
);

export default ActionsDropdown;
