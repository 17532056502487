import Container from '@anm/components/Container';
import React, { Component } from 'react';
import Slider, { CustomArrowProps, Settings } from 'react-slick';
import { CSSProperties } from 'styled-components';

import { CommonLandingProps } from '../../../features/getLanding';
import getWaves from '../../../helpers/getWaves';
import AnimatedWrapper from '../../Animated';
import Background from '../../Background';

import CardFeedback from './CardFeedback';
import CarouselWrapper from './CarouselWrapper';
import ClassicFeedback from './ClassicFeedback';
import feedbacks, {
  CardFeedbacksProps,
  ClassicFeedbacksProps
} from './FeedbackList';
import FeedbackSectionWrapper, {
  FeedbackTitle
} from './FeedbackSectionWrapper';
import SliderArrow from './SliderArrow';
import SliderLinesWrapper from './SliderLinesWrapper';

export type CardThemeProps = {
  maxWidth?: number;
  isCardTheme?: boolean;
};

export type SliderArrowVariants = 'left' | 'right';
export type ArrowSize = '15x24' | '36x36';

export type FeedbackProps = CommonLandingProps &
  CardThemeProps & {
    title: string;
    list: string[];
    arrowSize?: ArrowSize;
  };

type FeedbackState = {
  isArrowVisible: boolean;
};

export type SliderArrowProps = CustomArrowProps &
  CardThemeProps &
  FeedbackState & {
    direction: SliderArrowVariants;
    arrowSize?: ArrowSize;
    style?: CSSProperties;
  };

class Feedback extends Component<FeedbackProps, FeedbackState> {
  timer: number;
  speed = 15000;
  lines: Element[];
  sliderInstance = React.createRef<Slider>();

  state = {
    isArrowVisible: true
  };

  componentDidMount = () => {
    const linesCollection = document.querySelectorAll('.slider-lines li');
    this.lines = Array.prototype.slice.call(linesCollection, 0);
    this.setCurrent(0);
  };

  clearCurrent = () => {
    this.lines.map((el: Element) => {
      el.classList.contains('current') ? el.classList.remove('current') : null;
    });
  };

  clearFilled = (index: number) => {
    this.lines.map((el: Element, i: number) => {
      i >= index && el.classList.contains('filled')
        ? el.classList.remove('filled')
        : null;
    });
  };

  setCurrent = (item: number) => {
    this.clearFilled(item);
    this.lines.map((el: Element, index: number) => {
      if (index < item) {
        el.classList.add('filled');
      }

      if (index === item) {
        el.classList.add('current');
      }
    });
    this.timer = window.setTimeout(() => {
      if (!this.sliderInstance.current) return;

      this.sliderInstance.current.slickGoTo(item + 1);
    }, this.speed);
  };

  handleBeforeChange = (...params: number[]) => {
    const NEXT_SLIDE = 1;
    this.setState({ isArrowVisible: false });
    clearInterval(this.timer);
    this.clearCurrent();
    this.setCurrent(params[NEXT_SLIDE]);
  };

  goToSlide = (slide: number) => {
    if (!this.sliderInstance.current) return;

    clearInterval(this.timer);
    this.clearCurrent();
    this.sliderInstance.current.slickGoTo(slide);
  };

  renderLines = () => {
    const { list } = this.props;

    return list.map((el, i) => {
      return <li key={el} onClick={() => this.goToSlide(i)} />;
    });
  };

  handleSwipe = () => {
    clearInterval(this.timer);
  };

  renderFeedbacks = () => {
    const { list, isCardTheme } = this.props;

    return list.map(currentItem => {
      return feedbacks.map(item => {
        if (item.id !== currentItem) return null;
        return (
          <div key={currentItem}>
            {isCardTheme ? (
              <CardFeedback item={item as CardFeedbacksProps} />
            ) : (
              <ClassicFeedback item={item as ClassicFeedbacksProps} />
            )}
          </div>
        );
      });
    });
  };

  render() {
    const {
      title,
      waves,
      customBackground,
      headingLevel,
      isCardTheme,
      maxWidth,
      arrowSize = '15x24'
    } = this.props;

    const { isArrowVisible } = this.state;

    const settings: Settings = {
      speed: 700,
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      focusOnSelect: true,
      afterChange: () => this.setState({ isArrowVisible: true }),
      prevArrow: (
        <SliderArrow
          direction="left"
          {...{ arrowSize, isCardTheme, isArrowVisible }}
        />
      ),
      nextArrow: (
        <SliderArrow
          direction="right"
          {...{ arrowSize, isCardTheme, isArrowVisible }}
        />
      )
    };

    return (
      <FeedbackSectionWrapper {...{ isCardTheme }}>
        <Background
          variant={getWaves(waves) || 'white'}
          customBackground={customBackground || '#f7f8fa'}
        >
          <AnimatedWrapper variant="default" className="animated">
            <Container size={maxWidth}>
              <FeedbackTitle
                title={title}
                color="black"
                level={headingLevel || 1}
              />
              <CarouselWrapper {...{ maxWidth, isCardTheme }}>
                <Slider
                  beforeChange={this.handleBeforeChange}
                  onSwipe={this.handleSwipe}
                  ref={this.sliderInstance}
                  {...settings}
                >
                  {this.renderFeedbacks()}
                </Slider>
              </CarouselWrapper>
              <SliderLinesWrapper
                count={this.props.list.length}
                speed={this.speed || 10000}
                className="slider-lines"
              >
                {this.renderLines()}
              </SliderLinesWrapper>
            </Container>
          </AnimatedWrapper>
        </Background>
      </FeedbackSectionWrapper>
    );
  }
}

export default Feedback;
