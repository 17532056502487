import isMobile from "@anm/helpers/is/isMobile";

const isChrome = (userAgent?: Navigator['userAgent']) => /CriOS|Chrome/g.test(userAgent || window.navigator.userAgent);

const isFirefox = (userAgent?: Navigator['userAgent']) => /Firefox/.test(userAgent || window.navigator.userAgent);

const isSafari = (userAgent?: Navigator['userAgent']) =>
  /(?=.*(Version))(?=.*(Safari))/.test(userAgent || window.navigator.userAgent) &&
  !/Chrome/g.test(userAgent || window.navigator.userAgent);

const isMobileSafari = () => isMobile() && isSafari();

const isIE = (userAgent?: Navigator['userAgent']) => /MSIE|Trident/g.test(userAgent || window.navigator.userAgent);

const isOldSafari = (userAgent?: Navigator['userAgent']) => {
  if (!isSafari(userAgent)) return false;

  const supportedVersion = 12.1;
  const version = (userAgent || window.navigator.userAgent).match(/Version\/([0-9.]+)/)![1];

  return +version < supportedVersion;
};

export { isFirefox, isSafari, isOldSafari, isChrome, isIE, isMobileSafari };
