import { FC, ReactNode } from 'react';
import styled from 'styled-components';

export type ColumnsProps = { list: ReactNode[][]; columnWidth: number };

const Column = styled.div<{ columnWidth: number }>`
  width: ${({ columnWidth }) => `${columnWidth}%`};
`;

const getKey = (items: any[]) => items.map(el => el.key).join('_');
const Columns: FC<ColumnsProps> = ({ list, columnWidth }) => (
  <>
    {list.map(items => (
      <Column id="masonry-column" key={getKey(items)} columnWidth={columnWidth}>
        {items}
      </Column>
    ))}
  </>
);

export default Columns;
