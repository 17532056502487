import { FC } from 'react';

import { ContentProps } from './types';

const Content: FC<ContentProps> = ({ content }) => (
  <>
    {content.map(item => {
      return (
        <div key={item.paragraph}>
          {item.subTitle && <h3>{item.subTitle}</h3>}
          {item.paragraph && <p dangerouslySetInnerHTML={{__html: item.paragraph}}/>}
        </div>
      );
    })}
  </>
);

export default Content;
