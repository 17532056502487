import { FC } from 'react';

import TitleBackArrow from './TitleBackArrow';
import { MobileUserMenuLinkList } from './Wrapper';
import { UserList } from './types';

export type MobileNavigationUserMenuProps = UserList & { onBack(): void };

const MobileNavigationUserMenu: FC<MobileNavigationUserMenuProps> = ({
  onBack,
  renderUserLinkList
}) => (
  <>
    <TitleBackArrow title="Account" onClick={onBack} />
    <MobileUserMenuLinkList>{renderUserLinkList()}</MobileUserMenuLinkList>
  </>
);

export default MobileNavigationUserMenu;
