import useSafeAction from '@anm/auth/hooks/useSafeAction';
import Button, { PartialButtonProps } from '@anm/components/buttons/Button';
import Image from '@anm/components/image/Image';
import Modal from '@anm/components/modals/Modal';
import isMobileOrIPad from '@anm/helpers/is/isMobileOrIPad';
import useSwitchState from '@anm/hooks/useSwitchState';
import useModalDimension from '@anm/hooks/useVideoModalDimension';
import { Manifest } from '@anm/hosting-player';
import { FC } from 'react';

import withUser, { WithUserProps } from '../../../HOCs/withUser';
import EnvHostingPlayer from '../../EnvHostingPlayer';

import Wrapper, { ModalFooter, PlayerWrapper } from './Wrapper';
import useEditTemplateLink, { TemplateLinkProps } from './hooks/useEditTemplateLink';

export type ButtonActions = 'open-template' | 'open-editor';

type ModalContentProps = WithUserProps &
  TemplateLinkProps & {
    videoId: string;
    imageUrl?: string;
    manifest?: Manifest;
    customButton?: PartialButtonProps;
  };

type PlayerModalProps = ModalContentProps & {
  manifest?: Manifest;
  hasBorder?: boolean;
  onModalClose(): void;
};

const ModalContent: FC<ModalContentProps> = ({ videoId, imageUrl, manifest, customButton, ...otherProps }) => {
  const [isAssetInited, onInitAsset] = useSwitchState();
  const [modalWidth, containerRef] = useModalDimension(isAssetInited, !customButton);

  const getSafeAction = useSafeAction();
  const buttonText = isMobileOrIPad() ? 'Edit on desktop' : 'Edit this template';
  const buttonHref = useEditTemplateLink(otherProps);
  const canCreateAnon = !isMobileOrIPad() && !otherProps.slug;
  const signUpAndOpenEditor = getSafeAction(() => window.open(buttonHref, '_self'));
  const buttonAction = canCreateAnon ? signUpAndOpenEditor : undefined;

  return (
    <Wrapper ref={containerRef} width={modalWidth} {...{ isAssetInited }}>
      {videoId && (
        <PlayerWrapper>
          <EnvHostingPlayer
            playerWidth={1000} // force player to take best quality
            {...{ videoId }}
            onInit={onInitAsset}
          />
        </PlayerWrapper>
      )}
      {imageUrl && <Image src={imageUrl} alt={'title'} onLoad={onInitAsset} />}
      {customButton && (
        <ModalFooter>
          <Button
            size={isMobileOrIPad() ? 'big' : 'upper-big'}
            variant="yellow"
            children={buttonText}
            {...customButton}
            {...(buttonAction ? { onClick: buttonAction } : { href: buttonHref })}
          />
        </ModalFooter>
      )}
    </Wrapper>
  );
};

const PlayerModal: FC<PlayerModalProps> = ({ onModalClose, ...otherProps }) => (
  <Modal {...{ onModalClose }}>
    <ModalContent {...otherProps} />
  </Modal>
);

export default withUser(PlayerModal);
