import { FC } from 'react';

import LandingButton from '../../LandingButton';

import Wrapper, { ButtonWrapper, ContainerWrapper, UltraTitleText } from './Wrapper';
import getActiveButton from './hooks/getActiveButton';
import { UltraTitleProps } from './types';

const UltraTitle: FC<UltraTitleProps> = ({
  title,
  sectionId,
  headingLevel,
  titleVariant = 'medium',
  containerSize,
  descriptionMaxWidth,
  customAuthButtonGuestText,
  bottomMargin,
  button,
  buttonA = button,
  buttonB,
  ...titleProps
}) => {
  const [sectionRef, activeButton] = getActiveButton({ buttonA, buttonB });

  return (
    <Wrapper {...{ bottomMargin }} id={sectionId} data-button={'buttonA'} ref={sectionRef}>
      <ContainerWrapper size={containerSize || 1150}>
        <UltraTitleText
          color="black"
          level={headingLevel}
          title={title}
          {...{ ...titleProps, titleVariant, descriptionMaxWidth }}
        />
        {activeButton && (
          <LandingButton size="upper-big" variant="orange" {...{ ...activeButton, customAuthButtonGuestText }}>
            {landingButton => <ButtonWrapper>{landingButton} </ButtonWrapper>}
          </LandingButton>
        )}
      </ContainerWrapper>
    </Wrapper>
  );
};

export default UltraTitle;
