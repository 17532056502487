import { CardVariants } from '@anm/components/Card';
import { FC } from 'react';

import TooltipWrapper, { TooltipContent, TooltipLabel } from './Wrapper';
import useTooltip from './hook';
export { TooltipContent, TooltipWrapper, TooltipLabel };

export type TooltipWidth = 'fit-content';
export type TooltipDirection =
  | 'to-top'
  | 'above'
  | 'below'
  | 'to-left'
  | 'to-right'
  | 'to-bottom'
  | 'top-center'
  | 'bottom-center';

export type TooltipProps = {
  variant?: CardVariants;
  className?: string;
  width?: TooltipWidth;
  withArrow?: boolean;
  delayShow?: number;
  delayHide?: number;
  isOpened?: boolean;
  direction?: TooltipDirection;
  renderContent?: () => JSX.Element;
};

const Tooltip: FC<TooltipProps> = ({
  children,
  renderContent,
  withArrow = false,
  delayHide = 0,
  delayShow = 0,
  isOpened,
  ...contentProps
}) => {
  const [handleEnter, handleLeave, isHovered, canShow, contentRef] = useTooltip(delayHide, delayShow);

  const canShowTooltipByHover = isOpened === undefined;
  const canShowTooltipContent = ((isHovered || canShow) && renderContent && canShowTooltipByHover) || isOpened;

  return (
    <TooltipWrapper ref={contentRef} onMouseEnter={handleEnter} onMouseLeave={handleLeave}>
      <TooltipLabel>{children}</TooltipLabel>
      {canShowTooltipContent && (
        <TooltipContent
          {...{
            ...contentProps,
            withArrow,
            itemHeight: contentRef.current?.clientHeight,
            itemWidth: contentRef.current?.clientWidth
          }}
        >
          {renderContent?.()}
        </TooltipContent>
      )}
    </TooltipWrapper>
  );
};

export default Tooltip;
