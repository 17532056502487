import { BetaLabelsContext } from '@anm/contexts/BetaLabelsContext';
import { useContext, FC } from 'react';

import { Label } from './Wrapper';

export { Label };

export type LabelPositionVariant = 'inline-right' | 'top-right';
export type LabelStyleVariant = 'beta-blue' | 'new-yellow';

type BetaLabelProps = {
  positionVariant: LabelPositionVariant;
  styleVariant?: LabelStyleVariant;
  labelText?: string;
};

const BetaLabel: FC<BetaLabelProps> = ({ labelText = 'New!', positionVariant, styleVariant = 'new-yellow' }) => {
  const { showLabels } = useContext(BetaLabelsContext);

  if (!showLabels) return null;

  return <Label {...{ positionVariant, styleVariant }}>{labelText}</Label>;
};

export default BetaLabel;
