import Container from '@anm/components/Container';
import React, { FC } from 'react';

import { CommonLandingProps } from '../../../features/getLanding';
import getWaves from '../../../helpers/getWaves';
import Background from '../../Background/';
import LandingButton, { LandingButtonProps } from '../../LandingButton';
import SectionPicture from '../../SectionPicture';
import HideOnMobile from '../../mobile/HideOnMobile';

import List from './List';
import { ButtonWrapper, ListItem, ListWrapper } from './ListWrapper';
import HorizontalListWrapper from './SectionWrapper';
import HorizontalListTitle from './Title';
import { CustomIconProps, ListProps } from './types';

export { ListItem, ListWrapper };

export type HorizontalListItem = {
  alt?: string;
  title?: string;
  iconUrl?: string;
  ariaLabel?: string;
  description?: string;
  clickableIconLink?: string;
  customIcon?: CustomIconProps;
};

export type HorizontalListProps = CommonLandingProps &
  ListProps & {
    title?: string;
    button?: LandingButtonProps;
    containerSize?: number;
    isSimpleImageList?: boolean;
  };

const HorizontalList: FC<HorizontalListProps> = ({
  title,
  waves,
  button,
  columns,
  multiLine,
  listMaxWidth,
  headingLevel,
  sectionPicture,
  customBackground,
  containerSize,
  isSimpleImageList,
  ...listProps
}) => (
  <HorizontalListWrapper {...{ columns }}>
    <Background
      variant={getWaves(waves) || 'white'}
      customBackground={customBackground}
      isSectionPicture={!!sectionPicture}
    >
      <Container size={containerSize ? containerSize : multiLine ? 1220 : 1160}>
        {title && <HorizontalListTitle title={title} color="black" level={headingLevel || 3} />}
        <Container size={listMaxWidth}>
          <List multiLine={multiLine} {...{ ...listProps, columns, isSimpleImageList }} />
        </Container>
        <LandingButton size="big" variant="light-blue-lowercase" {...button}>
          {landingButton =>
            button?.kind === 'custom' ? (
              <HideOnMobile>
                <ButtonWrapper>{landingButton}</ButtonWrapper>
              </HideOnMobile>
            ) : (
              landingButton
            )
          }
        </LandingButton>
      </Container>
    </Background>
    {sectionPicture && <SectionPicture pictureUrl={sectionPicture} title={title || ''} />}
  </HorizontalListWrapper>
);

export default HorizontalList;
