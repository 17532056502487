import getWordsCount from '../getWordsCount';
import { getMinWords } from '../..';

const isCorrectWordsCount = (time: number, value: string) => {
	const currentWords = getWordsCount(value);

	return getMinWords(time) <= currentWords;
};

export default isCorrectWordsCount;
