import Container from '@anm/components/Container';
import Loader from '@anm/components/Loader';
import TitleBlock, { TitleBlockProps, TitleDescription } from '@anm/components/TitleBlock';
import { useColumn } from '@anm/components/list/Masonry';
import BrowseMoreButton from '@anm/components/stocks/BrowseMoreButton';
import Wrapper, { getCuttedList } from '@anm/components/stocks/StockList';
import { UserContext } from '@anm/components/user/UserProvider';
import useResize from '@anm/hooks/useResize';
import { useContext, useState, FC } from 'react';
import styled from 'styled-components';

import NoResultMessage from './NoResultMessage';
import Search from './Search';
import StockItem from './StockItem';
import StockModalView from './StockModalView';
import { getBreakpointColumnsObj } from './helpers';
import { useAllStocksUrl, useFetchStocks, useSearchHandlers } from './hooks';
import { StockFetchProps } from './types';

const Title = styled(TitleBlock)`
  ${TitleDescription} {
    margin: 0 auto;
    font-size: 24px;
    max-width: 860px;
    line-height: 36px;
    text-align: center;
    a {
      color: inherit;
      text-decoration: underline;
    }
  }
`;

const ContainerWrapper = styled(Container)`
  z-index: 1;
  position: relative;
  margin-bottom: 110px;
`;

export type StockListProps = StockFetchProps &
  TitleBlockProps & {
    placeholder: string;
    defaultSearchName: string;
    headingLevel?: number;
  };

const LIST_LIMIT = 11;
const ITEM_MARGIN = 30;
const ITEM_HEIGHT = 149;

const StocksList: FC<StockListProps> = ({
  placeholder,
  description,
  headingLevel,
  defaultSearchName,
  ...otherProps
}) => {
  const { isUserLogged } = useContext(UserContext);

  const { columnCount, columnWidth, reCalculateColumn } = useColumn(getBreakpointColumnsObj());
  useResize(reCalculateColumn);

  const { savedText, ...searchProps } = useSearchHandlers(defaultSearchName);

  const [isPending, stocks] = useFetchStocks({
    ...otherProps,
    searchName: savedText || defaultSearchName
  });

  const allStocksButtonUrl = useAllStocksUrl(savedText, isUserLogged);

  const stockList = getCuttedList({
    columnCount,
    list: stocks,
    limit: LIST_LIMIT
  });

  const isEmptyScreen = !stockList.length && savedText && !isPending;

  const [selectedStockIndex, setSelectedStockIndex] = useState<number | null>(null);

  const canShowModal = selectedStockIndex !== null && stockList[selectedStockIndex];

  return (
    <ContainerWrapper size={1150} as="section">
      {canShowModal && (
        <StockModalView stock={stockList[selectedStockIndex!]} onModalClose={() => setSelectedStockIndex(null)} />
      )}
      <Title {...{ description }} level={headingLevel} />
      <Search {...searchProps} placeholder={placeholder} />
      {isPending ? (
        <Loader color="blue" size="medium" />
      ) : isEmptyScreen ? (
        <NoResultMessage />
      ) : (
        <>
          <Wrapper {...{ columnWidth, columnCount }}>
            {stockList.map((stock, index) => (
              <StockItem
                key={stock.id + stock.service}
                itemHeight={ITEM_HEIGHT}
                itemMargin={ITEM_MARGIN}
                onCardClick={setSelectedStockIndex}
                {...{ stock, index, columnCount, columnWidth }}
              />
            ))}
            <BrowseMoreButton
              itemHeight={ITEM_HEIGHT}
              itemMargin={ITEM_MARGIN}
              width={columnWidth}
              href={allStocksButtonUrl}
              columnCount={columnCount}
            />
          </Wrapper>
        </>
      )}
    </ContainerWrapper>
  );
};

export default StocksList;
