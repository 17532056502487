import getFromCdn from '@anm/helpers/getFromCdn';
import { flexColumn } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

import { WrapperProps } from '.';

const arrowStyles = css`
  &:before {
    content: '';
    width: 102px;
    height: 71px;
    position: absolute;
    top: -45px;
    right: unset;
    transform: rotateY(-180deg);
    left: -150px;
    background: url(${getFromCdn('images/dashboard/curveArrow.svg')}) no-repeat center / contain;
  }
`;

export const EmptyListWrapper = styled.div<WrapperProps>`
  ${flexColumn};
  margin: auto;
  color: #292a2b;
  margin-top: 200px;
  position: relative;
  text-align: center;
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}
  & > div {
    margin: auto;
    display: inline-block;
    position: relative;
    margin-bottom: 33px;
    & > img {
      width: 30px;
      height: 30px;
      margin-right: 18px;
    }
    ${({ isArrow }) => isArrow && arrowStyles}
  h1 {
    display: inline;
    font-size: 30px;
    line-height: 42px;
  }
`;
