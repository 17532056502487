import TitleBlock from '@anm/components/TitleBlock';
import Image from '@anm/components/image/Image';
import { flexRow, media } from '@anm/styles/helpers';
import styled from 'styled-components';

export const InputIcon = styled(Image)`
  right: 10px;
  bottom: -10px;
`;

export const SearchField = styled.input`
  right: 0;
  width: 200px;
  font-size: 16px;
  min-height: 40px;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px 35px 10px 15px;
  border: 1px solid #dedfdb;
  ::placeholder {
    color: #b2b2bf;
  }
`;

const inputContainerOnTablet = media.tablet`
  width: 100%;
  margin-bottom: 14px;
  ${InputIcon} {
    bottom: 10px;
    transform: none;
  }
  ${SearchField} {
    width: inherit;
    transform: none;
    position: static;
  }
  `;

export const InputContainer = styled.div`
  width: 100%;
  position: relative;
  img,
  input {
    position: absolute;
    transform: translateY(calc(-100% - 40px));
  }
  ${inputContainerOnTablet}
`;

export const PlayerPreviewWrapper = styled.div`
  position: relative;
  min-height: 150px;
`;

export const VideoDuration = styled.span`
  right: 10px;
  color: #fff;
  bottom: 14px;
  height: 27px;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  border-radius: 5px;
  position: absolute;
  pointer-events: none;
  background-color: rgba(41, 42, 43, 0.6);
`;

const tutorialCardOnMobile = media.phone`
  max-width: 100%;
  &:nth-child(even) {
    margin-right: 0;
  }
`;

export const TutorialCard = styled.div`
  display: flex;
  margin-bottom: 50px;
  display: inline-block;
  max-width: calc(50% - 25px);
  h1,
  h1,
  h3,
  h4,
  h5 {
    font-size: 22px;
    margin-top: 11px;
    font-weight: bold;
  }
  &:nth-child(even) {
    margin-right: 50px;
  }
  ${tutorialCardOnMobile};
`;

export const EmptyList = styled.div`
  min-height: 200px;
  p {
    color: #b2b2bf;
    font-size: 30px;
    line-height: 35px;
  }
`;

const Wrapper = styled.section`
  position: relative;
  & > div {
    ${flexRow};
    flex-wrap: wrap;
    position: relative;
  }
`;

export const TitleWrapper = styled(TitleBlock)`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 28px;
  }
`;

export default Wrapper;
