import axios, { AxiosRequestConfig } from 'axios';

import { CreateRequestParams } from '../types';

import createErrorHandler from './createErrorHandler';
import paramsSerializer from './paramsSerializer';

export type RequestConfig = AxiosRequestConfig & { onlyData?: boolean };
const defaultRequestConfig: RequestConfig = {
  onlyData: true
};
const createRequest = ({ baseURL, isDebug }: CreateRequestParams) => {
  const requester = axios.create({ baseURL, paramsSerializer });

  requester.interceptors.response.use(res => {
    const { code, message } = res.data;

    if (res.status === 200 && code && code === 200) {
      return { ...res, data: message };
    }

    if (res.status === 200 && code && code >= 400) {
      res.status = code;
      res.statusText = message;
      return Promise.reject(res);
    }

    return res;
  });

  const request = (config: RequestConfig) => {
    const mergedConfig = { ...defaultRequestConfig, ...config };
    return requester(mergedConfig)
      .then(res => (mergedConfig.onlyData ? res.data : res))
      .catch(createErrorHandler({ isDebug }));
  };

  return {
    request,
    axiosInstance: requester
  };
};

export default createRequest;
