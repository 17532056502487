import { ApiConfig } from '@anm/api/types';
import { SECURE_TOKEN_HEADER_NAME } from '@anm/constants/auth';

import bindFor from '../bindFor';
import createRequest from '../createRequest';

const presetAxiosInstance = (apiConfig: ApiConfig) => {
  const { token, baseURL, clientIp, isDebug } = apiConfig;
  const { request, axiosInstance } = createRequest({ baseURL, isDebug });
  const bind = bindFor(request, apiConfig);

  if (token) {
    axiosInstance.defaults.headers[SECURE_TOKEN_HEADER_NAME] = token;
  }

  if (clientIp) {
    axiosInstance.defaults.headers['X-Forwarded-For'] = clientIp;
  }

  return { bind, request, axiosInstance };
};

export default presetAxiosInstance;
