import { createAction } from '@anm/helpers/saga/typesafe-actions';

import { Notification } from './types';

export const notify = createAction('wave.video/notification/ADD_SIDE_NOTIFICATION')<Omit<Notification, 'id'>>();

export const updateSideNotification = createAction('wave.video/notification/UPDATE_SIDE_NOTIFICATION')<
  Partial<Notification>
>();

export const removeSideNotification = createAction('wave.video/notification/REMOVE_SIDE_NOTIFICATION')<string>();

export const cleanNotifications = createAction('wave.video/notification/CLEAN_SIDE_NOTIFICATIONS')();
