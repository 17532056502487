import { ClearIcon, Input, SearchIcon } from '@anm/components/templates/Search';
import { ChangeEvent, FC, KeyboardEvent } from 'react';
import styled from 'styled-components';

const SearchWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 50px auto;
  position: relative;
  input {
    width: 100%;
    height: 50px;
  }
`;

type SearchProps = {
  text: string;
  placeholder: string;
  saveText(): void;
  handleClear(): void;
  handleOnKeyDown(evt: KeyboardEvent<HTMLInputElement>): void;
  handleChange(evt: ChangeEvent<HTMLInputElement>): void;
};

const Search: FC<SearchProps> = ({
  text,
  placeholder,
  saveText,
  handleClear,
  handleOnKeyDown,
  handleChange
}) => (
  <SearchWrapper>
    <SearchIcon
      src="images/icons/search_filter_v1.svg"
      onClick={saveText}
      alt="search Icon"
    />
    {text && (
      <ClearIcon
        alt="clear icon"
        src="images/icons/cross_clear_ic.svg"
        onClick={handleClear}
      />
    )}
    <Input
      type="text"
      value={text}
      onChange={handleChange}
      placeholder={placeholder}
      onBlur={saveText}
      onKeyDown={handleOnKeyDown}
    />
  </SearchWrapper>
);

export default Search;
