export type Dimension = { width: number; height: number };

interface NewDimensionsType extends Dimension {
  [key: string]: any;
}

const resizeWithAspectRatio = (
  w: number,
  h: number,
  maxWidth: number,
  maxHeight?: number,
  round = true,
  upscale = false
): Dimension => {
  let width = w;
  let height = h;

  const newDimension: NewDimensionsType = {
    width: w,
    height: h
  };

  if (upscale) {
    width = width * 9999;
    height = height * 9999;
  }

  if (width > maxWidth) {
    const ratio = maxWidth / width;
    newDimension.width = maxWidth;
    newDimension.height = height * ratio;
    height = height * ratio;
    width = width * ratio;
  }

  if (maxHeight && height > maxHeight) {
    const ratio = maxHeight / height;
    newDimension.height = maxHeight;
    newDimension.width = width * ratio;
  }

  if (round) {
    Object.keys(newDimension).forEach(
      k => (newDimension[k] = Math.round(newDimension[k]))
    );
  }

  return newDimension;
};

export default resizeWithAspectRatio;
