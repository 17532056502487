import compose from 'lodash/fp/compose';

const DEFAULT_LENGTH = 65;

const trim = (str: string) => str.trim();

const filterSpecialCharacters = (str: string) =>
  str.replace(/((?![a-zA-Z0-9 ]).)+/g, '');

const cut = (cutLength: number) => (str: string) => {
  if (str.length <= cutLength) return str;

  return str
    .slice(0, cutLength - 1)
    .split(' ')
    .slice(0, -1)
    .join(' ');
};

export default (cutLength = DEFAULT_LENGTH) =>
  compose(cut(cutLength), trim, filterSpecialCharacters);
