import { StockItem } from '@anm/api/modules/stocks';
import useSafeAction from '@anm/auth/hooks/useSafeAction';
import ButtonLoader from '@anm/components/buttons/ButtonLoader';
import { CardVariants } from '@anm/components/Card';
import StockModal from '@anm/components/stocks/StocksModal';
import { UserContext } from '@anm/components/user/UserProvider';
import isMobile from '@anm/helpers/is/isMobile';
import { useContext, FC, MouseEvent } from 'react';

import { getStockPriceValue } from './helpers';
import { useTemplateFromStockUrl } from './hooks';

const cardSettings = {
  isShadow: false,
  isPadding: false,
  variant: 'transparent' as CardVariants,
  minWidth: isMobile() ? 'unset' : `700px`
};

type StockViewModalProps = {
  stock: StockItem;
  priceValue?: string;
  onModalClose(): void;
};

const StockModalView: FC<StockViewModalProps> = ({ stock, onModalClose }) => {
  const { user } = useContext(UserContext);
  const subscriptionDetails = user?.subscriptionDetails;

  const { type, preview_url, thumbnail_url, ...otherProps } = stock;
  const isVideo = type === 'STOCK_VIDEO';

  const priceValue = getStockPriceValue(stock, subscriptionDetails);

  const templateFromStockUrl = useTemplateFromStockUrl(stock, true);

  const getSafeAction = useSafeAction();

  const signUpAndOpenEditor = getSafeAction(() => window.open(templateFromStockUrl, '_self'));

  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
    signUpAndOpenEditor();
  };

  return (
    <StockModal
      {...{
        isVideo,
        priceValue,
        videoUrl: preview_url,
        onModalClose,
        cardSettings,
        thumbnail_url,
        templateFromStockUrl,
        ...otherProps
      }}
      renderAnonButton={user ? undefined : props => <ButtonLoader onClick={handleClick} {...props} />}
    />
  );
};

export default StockModalView;
