import Container from '@anm/components/Container';
import { TitleBlockProps } from '@anm/components/TitleBlock';
import { FC } from 'react';

import Carousel from './Carousel';
import SectionWrapper, { MainTitle } from './Wrapper';
import reviewsList from './reviewsList';
import { ReviewData } from './types';

type REVIEW_SET = 'set 1' | 'set 2' | 'set 3' | 'set 4' | 'set 5' | 'set 6' | 'set 7';

export type ReviewsProps = TitleBlockProps & {
  reviewSet: REVIEW_SET;
  headingLevel?: number;
};

const Reviews: FC<ReviewsProps> = ({ title, headingLevel, reviewSet }) => (
  <SectionWrapper>
    <Container size={1210}>
      <MainTitle {...{ title }} level={headingLevel || 4} />
      <Carousel {...(reviewsList[reviewSet] as ReviewData)} />
    </Container>
  </SectionWrapper>
);

export default Reviews;
