import Dialog from '@anm/components/modals/Dialog';
import Confirm from '@anm/components/notification/Confirm';
import { WAVE_PROD, WAVE_TEST } from '@anm/constants/domains';
import isDev from '@anm/helpers/is/isDev';
import { FC } from 'react';

type SignupModalProps = {
  onCancel: () => void;
};

const VideoFromAILimitModal: FC<SignupModalProps> = ({ onCancel }) => {
  const signupUrl = `${isDev() ? WAVE_TEST : WAVE_PROD}signup`;
  const openSignUp = () => window.open(signupUrl, '_self');

  return (
    <Dialog onModalClose={onCancel} onSubmit={openSignUp}>
      <Confirm
        title="Please Sign Up to create more videos from text or URL"
        buttonLeft={{
          text: 'Cancel',
          size: 'upper-medium',
          variant: 'silver-border'
        }}
        buttonRight={{
          text: 'Sign Up',
          size: 'upper-medium',
          variant: 'light-blue'
        }}
        onLeftButtonClick={onCancel}
        onRightButtonClick={openSignUp}
      />
    </Dialog>
  );
};

export default VideoFromAILimitModal;
