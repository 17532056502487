import Loader, { LoaderColors, LoaderSizes } from '@anm/components/Loader';
import Button from '@anm/components/buttons/Button';
import IconButton, { IconButtonProps } from '@anm/components/buttons/IconButton';
import { flexCenter } from '@anm/styles/helpers';
import { FC } from 'react';
import styled, { css } from 'styled-components';

export type ButtonLoaderProps = IconButtonProps & {
  color?: LoaderColors;
  isPending?: boolean;
  loaderSize?: LoaderSizes;
  isGrayPending?: boolean;
};

export const LoaderWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  transform: translateY(50%) translateX(-50%);
`;

export const Content = styled.span<Partial<ButtonLoaderProps>>`
  opacity: ${({ isPending }) => (isPending ? 0 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;
const isIconButton = ({ icon }: IconButtonProps) => !!icon;
const ButtonOrIcon: FC<IconButtonProps> = props =>
  isIconButton(props) ? <IconButton {...props} /> : <Button {...props} />;

const ButtonWrapper = styled(ButtonOrIcon)`
  ${flexCenter};
  display: inline-flex;

  ${({ isGrayPending, isPending }: ButtonLoaderProps) =>
    isGrayPending &&
    isPending &&
    css`
      color: #919eaa;
      border-color: #ebeef5;
      background-color: #ebeef5;

      *,
      & {
        cursor: default;
      }

      &:hover {
        border-color: #ebeef5;
        background-color: #ebeef5;
      }
    `}
`;

const ButtonLoader: FC<ButtonLoaderProps> = ({
  children,
  isPending,
  color = 'white',
  loaderSize = 'small',
  ...otherProps
}) => (
  <ButtonWrapper isPending={isPending} disabled={isPending || otherProps.disabled} {...otherProps}>
    {isPending && (
      <LoaderWrapper>
        <Loader size={loaderSize} color={color} />
      </LoaderWrapper>
    )}
    <Content isPending={isPending} disabled={otherProps.disabled}>
      {children}
    </Content>
  </ButtonWrapper>
);

export default ButtonLoader;
