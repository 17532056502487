import isMobile from '@anm/helpers/is/isMobile';
import useIsMountedCheck from '@anm/hooks/useIsMountedCheck';
import { FC } from 'react';
import Slider, { Settings } from 'react-slick';

import ReviewSlide from './ReviewSlide';
import { CarouselWrapper } from './Wrapper';
import { useMinHeight } from './hooks';
import { ReviewData } from './types';

const SliderArrow = () => null;

const Carousel: FC<ReviewData> = ({ list }) => {
  const settings: Settings = {
    speed: 800,
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: false,
    centerMode: useIsMountedCheck() && isMobile(), // check on client side
    prevArrow: <SliderArrow />,
    nextArrow: <SliderArrow />
  };

  const [ref, minHeight] = useMinHeight();

  return (
    <CarouselWrapper {...{ ref }}>
      <Slider {...settings}>
        {list.map(slideProps => (
          <ReviewSlide
            key={slideProps.position}
            {...{ ...slideProps }}
            minHeight={minHeight}
          />
        ))}
      </Slider>
    </CarouselWrapper>
  );
};

export default Carousel;
