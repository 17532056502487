import styled from 'styled-components';

import { media } from '../../../styles/helpers';
import SectionTitle from '../../SectionTitle';

const titleOnLaptop = media.laptop`
  font-size: 36px;
  font-weight: 700;
`;

const descriptionOnLaptop = media.laptop`
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  margin-top: 28px;
`;

export const Title = styled(SectionTitle)`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    ${titleOnLaptop};
  }

  p {
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    margin-top: 14px;
    ${descriptionOnLaptop};
  }
`;

const шmageWrapperOnLaptop = media.laptop`
  margin-bottom: 34px;
`;

export const ImageWrapper = styled.div`
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${шmageWrapperOnLaptop};

  & > * {
    flex-shrink: 0;
  }

  img {
    border: 1px solid #eee;
  }
`;

const ListWrapperOnLaptop = media.laptop`
  margin-top: 52px;
`;

const ListItemOnTablet = media.tablet`
  width: 25%;
  padding: 0 7px;
`;

const ListItemOnLaptop = media.laptop`
  padding: 0 14px;
`;

const NotLastListItemOnTablet = media.tablet`
  margin: 0;
`;

const ParagraphOnLaptop = media.laptop`
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 10px;
`;

const SpanOnLaptop = media.laptop`
  font-size: 18px;
  line-height: 26px;
`;

export const ListWrapper = styled.div`
  margin-top: 24px;
  ${ListWrapperOnLaptop};

  li {
    text-align: center;
    ${ListItemOnTablet};
    ${ListItemOnLaptop};

    &:not(:last-child) {
      margin-bottom: 24px;
      ${NotLastListItemOnTablet};
    }
  }

  p {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 6px;
    ${ParagraphOnLaptop};
  }

  span {
    font-size: 17px;
    font-weight: 300;
    line-height: 28px;
    ${SpanOnLaptop};
  }

  & + div {
    text-align: center;
    margin: 60px auto;
  }
`;

const Wrapper = styled.section`
  position: relative;
  overflow: hidden;
`;
export default Wrapper;
