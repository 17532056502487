import { SelectOptionsContainer, SelectValueContainer } from '@anm/components/select/Select';
import getFromCdn from '@anm/helpers/getFromCdn';
import styled, { css } from 'styled-components';

import { Direction } from './';

const directions: { [key in Direction]: ReturnType<typeof css> } = {
  'to-bottom': css``,
  'to-top': css`
    bottom: 50px;
    top: unset;
  `
};

export const TimePickerWrapper = styled.div<{ contentDirection: Direction }>`
  ${SelectValueContainer} {
    padding: 8px 44px;
    min-height: 40px;
    background: url(${getFromCdn('images/icons/time.svg')}) no-repeat 10px 8px;
  }

  ${SelectOptionsContainer} {
    max-height: 280px;
    overflow-y: auto;
    cursor: pointer;
    z-index: 12;
    background: #fff;

    ${({ contentDirection }) => directions[contentDirection]}
  }
`;
