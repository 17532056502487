import TimePicker from '@anm/components/TimePicker';
import { SelectValueContainer } from '@anm/components/select/Select';
import { flexColumn } from '@anm/styles/helpers';
import { useCallback, useEffect, useState, FC } from 'react';
import styled, { css } from 'styled-components';

import { Label } from './ModalSettings/Wrapper';

const Wrapper = styled.div<WrapperProps>`
  ${flexColumn};
  margin-right: 15px;
  div > ${SelectValueContainer} {
    padding: 10px 12px;
    background-image: none;
    &:after {
      width: 10px;
      height: 15px;
      margin-right: -2px;
    }
    ${({ itemWidth }) =>
      itemWidth &&
      css`
        width: ${itemWidth}px;
      `}
  }
  & > div {
    &:first-child {
      margin-right: 10px;
    }
  }
`;

type WrapperProps = {
  itemWidth?: number;
};

type VideoDurationPickerProps = WrapperProps & {
  timeList: string[];
  onTimeChange(time: any): void;
};

const DEFAULT_TIME = {
  label: '30 sec',
  value: 30
};

const getValueFromLabel = (label: string) => +label.split(' ')[0];

const VideoDurationPicker: FC<VideoDurationPickerProps> = ({ timeList, itemWidth, onTimeChange }) => {
  const [time, setTime] = useState(DEFAULT_TIME.value); // in seconds

  useEffect(() => {
    onTimeChange(time);
  }, [time]);

  const handleTimeChange = useCallback((label: string) => {
    const newTime = label === DEFAULT_TIME.label ? DEFAULT_TIME.value : getValueFromLabel(label) * 60;
    setTime(newTime);
  }, []);

  const timeLabel = time === DEFAULT_TIME.value ? DEFAULT_TIME.label : `${time / 60} min`;

  return (
    <Wrapper {...{ itemWidth }}>
      <Label>Desired duration</Label>
      <TimePicker
        timeList={timeList}
        contentDirection="to-top"
        defaultTime={timeLabel}
        onTimeChange={handleTimeChange}
      />
    </Wrapper>
  );
};

export default VideoDurationPicker;
