import api from '@anm/api';
import memoize from 'lodash/fp/memoize';

export const mapCouponCodeStatus = (statusMessage: string) => ({
  isCodeValid: statusMessage === 'Coupon is valid' || statusMessage !== 'Invalid coupon',
  canCouponUpgradeCurrentPlan:
    !statusMessage.includes('unable to upgrade existing subscription') &&
    !statusMessage.includes('Paid subscription detected'),
  isCouponUsed: statusMessage.includes('already redeemed')
});

export const getCouponErrorMessage = (couponStatus: ReturnType<typeof mapCouponCodeStatus>) =>
  !couponStatus
    ? null
    : !couponStatus.isCodeValid
    ? 'Unrecognized AppSumo code. Please contact AppSumo Support.'
    : couponStatus.isCouponUsed
    ? 'This AppSumo code has been already redeemed.'
    : !couponStatus.canCouponUpgradeCurrentPlan
    ? 'Account is not eligible for AppSumo activation.'
    : null;

const checkCoupon = async (coupon: string) => {
  try {
    return await api().subscription.checkCoupon({ code: coupon });
  } catch ({ message }) {
    return message;
  }
};

const memoizedCheckCoupon = memoize(checkCoupon);

const couponValidator = async (coupon: string) => {
  if (!coupon) return null;

  const message = await memoizedCheckCoupon(coupon);
  const status = mapCouponCodeStatus(message);
  return getCouponErrorMessage(status);
};

export default couponValidator;
