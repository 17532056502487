import Container from '@anm/components/Container';
import { media } from '@anm/styles/helpers';
import styled from 'styled-components';

import SectionTitle, { Description } from '../../SectionTitle';

const Wrapper = styled.section`
  margin-top: 64px;
`;

const titleMobileStyles = media.phone`
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 24px;
    line-height: 32px;
  }
  ${Description} {
    font-size: 14px;
    line-height: 22px;
  }
`;

export const TitleWrapper = styled(SectionTitle)`
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 40px;
    font-size: 40px;
    font-weight: bold;
    line-height: 54px;
    font-style: normal;
    margin-bottom: 25px;
  }
  ${Description} {
    font-size: 18px;
    line-height: 32px;
    font-weight: normal;
    margin-bottom: 31px;
  }
  ${titleMobileStyles};
`;

export const ContainerWrapper = styled(Container)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export default Wrapper;
