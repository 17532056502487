import Copyright from '@anm/components/Copyright';
import { FC } from 'react';

import {
  CopyrightWrapper,
  SmallFooterContainer,
  SmallFooterWrapper
} from './Wrapper';

export type SmallFooterProps = { isDarkFooter?: boolean };

const SmallFooter: FC<SmallFooterProps> = () => (
  <SmallFooterWrapper>
    <SmallFooterContainer size={1150}>
      <CopyrightWrapper>
        <Copyright />
      </CopyrightWrapper>
    </SmallFooterContainer>
  </SmallFooterWrapper>
);

export default SmallFooter;
