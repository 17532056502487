import { css } from 'styled-components';

export type DescriptionVariant = 'small' | 'big';

export const getDescriptionVariant = (variant: DescriptionVariant) =>
  ({
    small: css`
      margin: 24px 0;
      font-size: 16px;
      line-height: 25px;
    `,
    big: css`
      margin: 40px 0;
      font-size: 24px;
      line-height: 40px;
    `
  }[variant]);
