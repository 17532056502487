import noop from '@anm/helpers/noop';
import { useCallback, useEffect, FC, MouseEvent } from 'react';

import Backdrop from './Backdrop';
import Content from './Content';
import Overlay from './Overlay';
import ModalPortal from './Portal';
import { InnerModalWrapper, KeyDownOnBlockWrapper } from './Wrapper';

export { ModalPortal };

export type ModalProps = {
  onSubmit?(): void;
  onModalClose?(): void;
  isInnerModal?: boolean;
  contentPadding?: string;
  isModalTopAligned?: boolean;
  isClickableBackdrop?: boolean;
};
export type ModalPropsWithoutDefault = ModalProps;

export let isModalOpen = false;

type WrapperProps = {
  isInnerModal: boolean;
};

const Wrapper: FC<WrapperProps> = ({ isInnerModal, children }) =>
  isInnerModal ? <InnerModalWrapper>{children}</InnerModalWrapper> : <ModalPortal>{children}</ModalPortal>;

const Modal: FC<ModalProps> = ({
  children,
  onSubmit = noop,
  onModalClose = noop,
  contentPadding,
  isInnerModal = false,
  isModalTopAligned = false,
  isClickableBackdrop = true
}) => {
  useEffect(() => {
    isModalOpen = true;

    return () => {
      isModalOpen = false;
    };
  }, []);

  const handleModalClose = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      onModalClose();
    },
    [onModalClose]
  );

  return (
    <Wrapper {...{ isInnerModal }}>
      <KeyDownOnBlockWrapper onEsc={onModalClose} onEnter={onSubmit}>
        <Backdrop onClick={isClickableBackdrop ? handleModalClose : noop} />
        <Overlay onModalClose={isClickableBackdrop ? onModalClose : noop}>
          <Content {...{ isModalTopAligned, contentPadding }}>{children}</Content>
        </Overlay>
      </KeyDownOnBlockWrapper>
    </Wrapper>
  );
};

export default Modal;
