import { FC } from 'react';
import styled from 'styled-components';

export type VideoContainerProps = React.VideoHTMLAttributes<HTMLVideoElement>;

const VideoWrapper = styled.video`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  position: absolute;
`;

const VideoContainer: FC<VideoContainerProps> = props => (
  <VideoWrapper {...props}></VideoWrapper>
);

export default VideoContainer;
