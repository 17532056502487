import { useEffect, useRef, useState } from 'react';

const SLIDE_TOP_OFFSET = 16;

export const useMinHeight = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [minHeight, setMinHeight] = useState(0);

  useEffect(() => {
    if (!wrapperRef.current) return;

    const slider = wrapperRef.current.querySelector('.slick-track');

    if (!slider) return;

    const sliderHeight = (slider as HTMLDivElement).offsetHeight;

    setMinHeight(sliderHeight - SLIDE_TOP_OFFSET);
  }, [wrapperRef.current]);

  return [wrapperRef, minHeight] as const;
};
