import { FC } from 'react';

import WavePlayerModal, {
  MediaProps as SideMedia
} from '../../../WavePlayerModal';
import { PlayerWrapper } from '../Wrapper';

import WistiaPlayer from './WistiaPlayer';

type ImageProps = SideMedia;

const VideoPlayerModal: FC<ImageProps> = ({ videoId, wistiaId, imageUrl }) => (
  <PlayerWrapper>
    {videoId ? (
      <WavePlayerModal {...{ videoId }} />
    ) : (
      <WistiaPlayer wistiaId={wistiaId!} imageUrl={imageUrl!} />
    )}
  </PlayerWrapper>
);

export default VideoPlayerModal;
