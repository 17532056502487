import Button, { ButtonProps } from '@anm/components/buttons/Button';
import { isFunction } from 'lodash/fp';
import { FC } from 'react';

import ButtonAuth, { ButtonAuthProps } from './components/ButtonAuth';
import CreateVideo from './components/CreateVideo';
import DropdownButton from './components/DropdownButton';
import { DropdownButtonProps } from './components/DropdownButton/types';
import FindTemplate, { FindTemplateProps } from './components/FindTemplate';
import SignupAndOpen from './components/SignupAndOpen';
import UploadMedia from './components/UploadButton';
import { UploadButtonProps } from './components/UploadButton/types';
import { LandingButtonKind } from './types';

export type LandingButtonOwnProps = ButtonProps & {
  text?: string;
  kind?: LandingButtonKind;
  children?: ButtonProps['children'] | ((button: JSX.Element | null) => JSX.Element);
};

export type LandingButtonProps = LandingButtonOwnProps &
  FindTemplateProps &
  ButtonAuthProps &
  UploadButtonProps &
  DropdownButtonProps;

const ButtonSwitcher: FC<LandingButtonProps> = props => {
  const { kind } = props;
  switch (kind) {
    case undefined: {
      return null;
    }
    case 'custom': {
      return <Button {...props}>{props.children || props.text}</Button>;
    }
    case 'upload-media': {
      return <UploadMedia {...props} />;
    }
    case 'find-template': {
      return <FindTemplate {...props} />;
    }
    case 'dropdown': {
      return <DropdownButton {...props} />;
    }
    case 'create': {
      return <CreateVideo {...props} />;
    }
    case 'signup-and-open': {
      return <SignupAndOpen {...props} />;
    }
    default: {
      return <ButtonAuth {...props} />;
    }
  }
};

const LandingButton: FC<LandingButtonProps> = props => {
  const { children, ...otherProps } = props;
  const button = <ButtonSwitcher {...otherProps}>{isFunction(children) ? null : children}</ButtonSwitcher>;

  return isFunction(children) ? children(button) : button;
};

export default LandingButton;
