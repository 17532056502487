import styled from 'styled-components';
import TitleBlock from '../TitleBlock';
import { flexCenter, media } from '@anm/styles/helpers';

const previewListWrapperAdaptive = media.tablet`
  flex-direction: column;
`;

const previewListItemAdaptive = media.tablet`
  margin-right: 0;
  margin-bottom: 30px;
`;

const squarePreviewWrapperAdaptive = media.tablet`
  max-width: 100%;
`;



export const PreviewListWrapper = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  ${previewListWrapperAdaptive};
`;

export const PreviewItemTitle = styled(TitleBlock)`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }
`;

export const PreviewListItem = styled.li`
  margin-right: 20px;
  flex: 1;
  &:last-of-type {
    margin-right: 0;
  }
  ${previewListItemAdaptive};
`;

export const SquarePreviewWrapper = styled.div`
  border: 1px solid #ccdde6;
  box-sizing: border-box;
  display: inline-block;
  box-shadow: 0 2px 10px rgba(1, 83, 130, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
  height: 0;
  padding-top: 100%;
  position: relative;
  max-width: 320px;
  width: 100%;
  ${squarePreviewWrapperAdaptive};
`;

export const PreviewImageWrapper = styled.div`
  ${flexCenter};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 8px;
  img {
    width: 100%;
  }
`;

export const PreviewLink = styled.a`
  display: block;
`;
