import canPlayOriginalVideo from '@anm/helpers/canPlayOriginalVideo';
import getExtensionFromPath from '@anm/helpers/getExtensionFromPath';
import { MediaObject } from '@anm/hosting-player';

type MediaType = 'video' | 'image' | 'thumbnail' | 'audio';

type CheckContainMediaProps = {
  list: MediaObject[];
  type: MediaType;
  supportedExtensions: string[];
};

const checkContainMedia = ({ type, list = [], supportedExtensions }: CheckContainMediaProps) => {
  const isVideo = (item: MediaObject) => item.type === 'video';
  const isImage = (item: MediaObject) => item.type === 'image';
  const isAudio = (item: MediaObject) => item.type === 'audio';

  if (type === 'image') return !!list.filter(item => isImage(item)).length;
  if (type === 'audio') return !!list.filter(item => isAudio(item)).length;

  const isSupported = (video: MediaObject) => {
    const ext = getExtensionFromPath(video.path);
    return ext && supportedExtensions.includes(ext.toLowerCase());
  };

  const allVideos = list.filter(item => isVideo(item));

  const canPlayOriginal = allVideos.length && canPlayOriginalVideo(allVideos);

  const canPlayNotOriginal = !!allVideos.filter(v => !v.isOriginal && isSupported(v)).length;

  const canPlay = canPlayOriginal || canPlayNotOriginal;

  return canPlay;
};

export default checkContainMedia;
