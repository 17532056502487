import * as analytic from '@anm/analytic';
import { JobIdFromSourceParams } from '@anm/api/modules/storyboard';
import createArray from '@anm/helpers/array/createArray';
import convertJobIdParamsToAnalytic from '@anm/helpers/storyboard/convertJobIdParamsToAnalytic';

import { TrackCreateVideoFromAIProps } from './types';

export const getUserTimeList = (arrayLength: number) =>
  createArray(arrayLength, 0).map(t => (t === 0 ? `30 sec` : `${t} min`));

export const trackCreateVideoFromAI = ({ params, jobParams }: TrackCreateVideoFromAIProps) => {
  if (!jobParams) return;

  const isFromBlog = !!(jobParams as JobIdFromSourceParams).source;
  const isFromTextAsIs = !!jobParams.full_text_summary;
  const isFromReducedText = !isFromBlog && !isFromTextAsIs;

  const analyticParams = convertJobIdParamsToAnalytic({ ...jobParams, ...params });

  isFromBlog && analytic.trackCreateVideoFromBlogSubmit(analyticParams);
  isFromTextAsIs && analytic.trackCreateVideoFromTextAsIsSubmit(analyticParams);
  isFromReducedText && analytic.trackCreateVideoFromReducedTextSubmit(analyticParams);
};
