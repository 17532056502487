import { FC } from 'react';
import styled from 'styled-components';
import { Omit } from 'types/helpers';

import CopyRight from '../CopyRight';
import Preview, { PreviewProps } from '../Preview';

import MobileTabs, { MobileTabsProps } from './MobileTabs';

export type MobileProps = Omit<PreviewProps, 'src'> & MobileTabsProps & { preview?: string };

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin-top: 30px;
`;

const Mobile: FC<MobileProps> = ({ preview, options, mediaType, startDownload, onChangeMediaType, ...props }) => (
  <Wrapper>
    <Preview src={preview} {...props} />
    <MobileTabs
      options={options}
      mediaType={mediaType}
      startDownload={startDownload}
      onChangeMediaType={onChangeMediaType}
    />
    <CopyRight />
  </Wrapper>
);

export default Mobile;
