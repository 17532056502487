import { createElement, FC } from 'react';

import TitleWrapper, { Description } from './TitleWrapper';
export { Description, TitleWrapper };

export type SectionTitleProps = {
  color: SectionTitleColor;
  title?: string;
  level?: number;
  className?: string;
  description?: string;
  isFirstSection?: boolean;
  itemProp?: string;
};

export type SectionTitleColor = 'black' | 'white';

const SectionTitle: FC<SectionTitleProps> = props => {
  const { title, description, level, ...otherProps } = props;

  const Title = createElement(`h${level || 1}`, {
    dangerouslySetInnerHTML: { __html: title }
  });

  return (
    <TitleWrapper {...otherProps}>
      {title && Title}
      {description && <Description dangerouslySetInnerHTML={{ __html: description }} />}
    </TitleWrapper>
  );
};

export default SectionTitle;
