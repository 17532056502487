import { css, ThemedCssFunction } from 'styled-components';

type MediaSizes = { [key in keyof Sizes<any>]: ThemedCssFunction<any> };

interface Sizes<T> {
  desktop: T;
  laptop: T;
  tablet: T;
  mobile: T;
}

const setMedia = (size: number) => (
  strings: TemplateStringsArray,
  ...args: string[]
) => css`
  @media (min-width: ${size}px) {
    ${css(strings, ...args)};
  }
`;

export const sizes: Sizes<number> = {
  desktop: 1200,
  laptop: 992,
  tablet: 768,
  mobile: 576
};

export const media: MediaSizes = Object.keys(sizes).reduce<MediaSizes>(
  (acc: MediaSizes, label: keyof Sizes<number>) => {
    acc[label] = setMedia(sizes[label]);
    return acc;
  },
  {} as MediaSizes
);

export const truncate = (width: string) => `
    width: ${width};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;

export const mobileMenuItem = css`
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #292a2b;
  display: block;
  height: 69px;
  line-height: 69px;
  flex: 1;
  text-align: left;
`;

export const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const rightToLeftMenuAppearing = css`
  animation-name: menuIn;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  @keyframes menuIn {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0);
    }
  }
`;

export const inlineCodeStyles = css`
	display: inline-block;
	padding: 0 10px;
	background: #f5f7fa;
	border-radius: 5px;
	font-size: 14px;
	line-height: 28px;
	font-family: 'Source Code Pro', monospace;
`
