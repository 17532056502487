import getBestFitQuality from '@anm/helpers/getBestFitQuality';
import { Manifest } from '@anm/hosting-player';
import { useContext, useEffect, useRef, useState } from 'react';

import { VideoDataContext } from '../../store/VideoDataContext';

type ImageData = {
  src: string;
  alt: string;
};

const DEFAULT_WIDTH = 500;

export const usePlayerData = (videoId: string, imageUrl?: string) => {
  const playerRef = useRef<HTMLDivElement>(null);
  const [imgData, setImgData] = useState<ImageData | null>({ src: imageUrl || '', alt: 'imageUrl' });

  const data = useContext(VideoDataContext);
  const manifest = data?.[videoId] as Manifest;

  useEffect(() => {
    if (!videoId) return;

    const bestHeight = getBestFitQuality({
      type: 'image',
      sources: manifest.main,
      playerWidth: playerRef?.current?.clientWidth || DEFAULT_WIDTH
    });
    const image = manifest.main.find(s => s.height === bestHeight && s.type === 'image')!;

    setImgData({ src: image.path, alt: manifest.meta.name });
  }, [playerRef?.current?.clientWidth]);

  return [playerRef, imgData] as const;
};
