import * as cookie from '@anm/helpers/cookie';
import noop from 'lodash/fp/noop';

type AuthStatusWatcherProps = {
  cookieName: string;
  onLogin: (authCookie: string) => void;
  onLogout: Function;
  onReset?: Function;
};

let isLogin: boolean | null = null;
let prevAuthToken: string | undefined;

const checkAuthStatus = ({ cookieName, onLogin, onLogout, onReset = noop }: AuthStatusWatcherProps) => {
  const authToken = cookie.get(cookieName);
  const isInit = isLogin === null;

  if (!isLogin && authToken) {
    isLogin = true;
    onLogin(authToken);
  } else if (isLogin && !authToken) {
    isLogin = false;
    onLogout();
  } else if (isInit && !authToken) {
    isLogin = false;
    onReset();
  } else if (authToken && prevAuthToken && authToken !== prevAuthToken) {
    isLogin = true;
    onLogin(authToken);
  }

  prevAuthToken = authToken;
};

export const setWasLogged = (newWasLogged: boolean | null) => (isLogin = newWasLogged);

export const setPrevAuthToken = (newPrevAuthToken?: string) => (prevAuthToken = newPrevAuthToken);

export const run = (props: AuthStatusWatcherProps) => {
  const check = () => checkAuthStatus(props);
  const cancelId = setInterval(check, 500);

  check();

  return () => clearInterval(cancelId);
};
