import TitleBlock, { TitleDescription } from '@anm/components/TitleBlock';
import Image from '@anm/components/image/Image';
import { flexColumn, flexRow } from '@anm/styles/helpers';
import styled from 'styled-components';

import { CardContentProps } from '.';

export const Title = styled(TitleBlock)`
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 22px;
    font-weight: bold;
    line-height: 32px;
    margin-bottom: 22px;
  }
  ${TitleDescription} {
    line-height: 28px;
    margin-bottom: 22px;
  }
`;

export const WorkshopCardWrapper = styled.div`
  ${flexRow}
  min-height: 280px;
  margin-bottom: 50px;
`;

export const Preview = styled(Image)`
  width: 100%;
  max-width: 280px;
  object-fit: cover;
`;

export const CardContent = styled.div<CardContentProps>`
  ${flexColumn};
  padding: 34px 40px 43px 50px;
  background: ${({ background }) => background};
  a {
    font-size: 14px;
    max-height: 44px;
    max-width: 180px;
    font-weight: 600;
  }
`;
