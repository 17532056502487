import styled from 'styled-components';

import { OptionContainer, SelectOptionsContainer, SelectValueContainer } from '../select/Select';

export const Wrapper = styled.div`
  left: 20px;
  bottom: 0px;
  z-index: 998;
  position: fixed;

  ${SelectValueContainer} {
    top: unset;
    height: 37px;
    border-radius: 0;
    font-size: 13px;
    padding: 0 0 0 10px;
  }

  ${SelectOptionsContainer} {
    left: 0;
    padding: 0;
    left: unset;
    width: auto;
    font-size: 13px;
    border-radius: 0;
    overflow: auto;
    max-height: 300px;
    transform: translateY(calc(-100% - 50px));
    ${OptionContainer},a {
      min-height: 37px;
      line-height: 37px;
      white-space: nowrap;
      padding: 0 15px 0 10px;
      > div {
        cursor: pointer;
      }
    }
  }
`;
