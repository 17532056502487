import styled from 'styled-components';

type TextAreaProps = {
  resize?: boolean;
  hasError?: boolean;
  minHeight?: number;
};

export const Wrapper = styled.div`
  position: relative;
`;

export const TextAreaWrapper = styled.textarea<TextAreaProps>`
  background-color: #ffffff;
  border: 1px solid #ccdde6;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  width: 100%;
  padding: 12px 15px;
  resize: ${({ resize }) => (resize ? 'auto' : 'none')};
  min-height: ${({ minHeight }) => `${minHeight || 122}px`};

  &:focus {
    outline: 0 none;
    border-color: var(--blue_100);
  }
`;
