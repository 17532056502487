const getWordsCount = (value: string, isUseWordCharacters = true) => {
  let newValue = value;

  if (isUseWordCharacters) {
    newValue = newValue.replace(/[^a-zA-Z0-9 \[\]]+/g, '');
  }

  return newValue.split(' ').filter(Boolean).length;
};

export default getWordsCount