import slick from '@anm/styles/third-party/slick';
import styled from 'styled-components';

export const CarouselWrapper = styled.div`
  position: relative;
  text-align: center;
  padding: 0 0 30px;
  margin: 0 -20px;

  ${slick}

  div {
    outline: none;
    cursor: grab;
  }

  .slick-slide {
    min-width: 260px;
  }

  .slick-slider {
    margin: 0 auto;
    position: static;
    max-width: 650px;
    min-height: 200px;
  }

  .slick-dots {
    bottom: 0;
    li {
      width: 35px;
      margin: 0 0 0 -20px;
      button:before {
        font-size: 20px;
      }
    }
  }
`;
