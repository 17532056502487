import styled from 'styled-components';
import { flexCenter, media } from '@anm/styles/helpers';

const topBannerMobile = media.phone`
  font-size: 16px;
`;

export const TopBannerWrapper = styled.div`
  ${flexCenter};
  background-color: #96cbff;
  width: 100%;
  padding: 10px;
  min-height: 48px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #292a2b;
  flex-wrap: wrap;
  a {
    color: inherit;
    text-decoration: underline;
  }
  ${topBannerMobile};
`;
