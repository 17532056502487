import hasAuthAccess from '@anm/auth/helpers/hasAuthAccess';
import { TERMLY_ID } from '@anm/constants/services';
import Head from 'next/head';
import { useEffect, useState, FC } from 'react';

export type TermlyProps = {};

const Termly: FC<TermlyProps> = () => {
  const [canRender, setCanRender] = useState(false);

  useEffect(() => {
    setCanRender(!hasAuthAccess());
  }, []);

  if (!canRender) return null;

  return (
    <Head>
      <script
        type="text/javascript"
        src="https://app.termly.io/embed.min.js"
        data-auto-block="off"
        data-website-uuid={TERMLY_ID}
      ></script>
    </Head>
  );
};

export default Termly;
