import { MediaObjects } from '../../types';
import getDeviceByScreenSize from '../getDeviceByScreenSize';
import mapThumbnails from '../mapThumbnails';

const defaultPreviewThumbnails = {
  previewThumbnails: {
    enabled: false,
    src: ''
  }
};

const getVideoData = (mediaObjects: MediaObjects) => {
  const device = getDeviceByScreenSize();

  const sourceType = mediaObjects[device]
    ? mediaObjects[device]
    : mediaObjects.main;

  const sources = sourceType!.filter(media => media.type === 'video');
  const posters = sourceType!.filter(media => media.type === 'image');
  const audio = sourceType!.filter(media => media.type === 'audio')[0];
  const thumbnail = sourceType!.filter(media => media.type === 'thumbnail')[0];

  const thumbnailsConfig = thumbnail
    ? mapThumbnails(thumbnail)
    : defaultPreviewThumbnails;

  return {
    audio,
    sources,
    posters,
    thumbnailsConfig
  };
};

export default getVideoData;
