import Button, { ButtonSize, ButtonVariants } from '@anm/components/buttons/Button';
import getPageNameFromUrl from '@anm/helpers/getPageNameFromUrl';
import { composeUrlQuery, toUrlParams } from '@anm/helpers/parseParams';
import toCamelCase from '@anm/helpers/string/toCamelCase';
import useUTMQuery from '@anm/hooks/useUTMQuery';
import { useEffect, useState, FC } from 'react';

import { LandingButtonKind } from '../../../components/LandingButton/types';
import lfConfig from '../../../config';
import { LandingDataConsumer } from '../../../store/LandingDataContext';
import { buttonAuthData } from '../../LandingButton/components/ButtonAuth';

import AuthButtonsWrapper from './Wrapper';

type ButtonType = 'login' | 'signup';

type ButtonItem = {
  type: ButtonType;
  size: ButtonSize;
  variant: ButtonVariants;
};

export type AuthButtonsProps = {
  buttons: ButtonItem[];
};

type AuthButtonProps = {
  button: ButtonItem;
  authRedirectUrl?: string;
  authRedirectKind?: LandingButtonKind;
};

type GetSignUpRedirectParamsProps = { kind?: LandingButtonKind; redirectUrl?: string };
export const getSignUpRedirectParams = ({ kind, redirectUrl }: GetSignUpRedirectParamsProps) => {
  const { entry, redirectUrl: redirectUrlFromTheme } = kind ? buttonAuthData[kind] : { entry: null, redirectUrl: null };

  const redirectParam = redirectUrl || redirectUrlFromTheme;

  const params = entry ? toUrlParams({ entry }) : toUrlParams({ redirectUrl: redirectParam });

  return params;
};

const AuthButton: FC<AuthButtonProps> = ({ button, authRedirectKind, authRedirectUrl }) => {
  const [pageName, setPageName] = useState('');
  const utmQuery = useUTMQuery();
  const { type, ...buttonProps } = button;
  const { waveUrl } = lfConfig;
  const authRedirectUrlLocal =
    authRedirectKind || authRedirectUrl
      ? getSignUpRedirectParams({ kind: authRedirectKind, redirectUrl: authRedirectUrl })
      : '';

  const dataAttr = toCamelCase(`${type} button on ${pageName} page`);
  const redirectParams = composeUrlQuery(authRedirectUrlLocal, utmQuery);

  useEffect(() => {
    setPageName(getPageNameFromUrl(location.href));
  }, []);

  return type === 'login' ? (
    <Button href={`${waveUrl}login${redirectParams}`} data-button={dataAttr} {...buttonProps}>
      Sign In
    </Button>
  ) : (
    <Button href={`${waveUrl}signup${redirectParams}`} data-button={dataAttr} {...buttonProps}>
      Sign Up
    </Button>
  );
};

const AuthButtons: FC<AuthButtonsProps> = ({ buttons }) => (
  <AuthButtonsWrapper>
    <LandingDataConsumer>
      {({ header }) => (
        <>
          {buttons.map(buttonItem => (
            <AuthButton
              key={buttonItem.type}
              button={buttonItem}
              authRedirectUrl={header?.authRedirectUrl}
              authRedirectKind={header?.authRedirectKind}
            />
          ))}
        </>
      )}
    </LandingDataConsumer>
  </AuthButtonsWrapper>
);

export default AuthButtons;
