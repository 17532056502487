import Select, { SelectOption } from '@anm/components/select/Select';
import { SelectOptionsContainer, SelectValueContainer } from '@anm/components/select/Select';
import { useEffect, useState, FC } from 'react';
import styled, { css } from 'styled-components';

import { Label } from './ModalSettings/Wrapper';

const topOpenerStyles = css`
  transform: translate(0, -208px);
`;

const Wrapper = styled.div<{ isOpenToTop: boolean }>`
  z-index: 2;
  position: relative;

  ${SelectValueContainer} {
    height: 40px;
    width: 148px;
    font-size: 16px;
    line-height: 19px;
    padding: 8px 12px;
    &:after {
      right: 0;
      position: absolute;
    }
  }
  ${SelectOptionsContainer} {
    max-height: 150px;
    overflow-y: auto;
    ${({ isOpenToTop }) => isOpenToTop && topOpenerStyles};

    & > div {
      max-height: 40px;
    }
  }
`;

export const MOODS = [
  'inspiring',
  'anxious',
  'confident',
  'confused',
  'crazy',
  'curious',
  'dreamy',
  'epic',
  'funny',
  'happy',
  'melancholy',
  'mysterious',
  'relaxing',
  'romantic',
  'sad'
];

const moodsOptions = MOODS.map(m => ({ label: m, value: m }));

type MoodPickerProps = {
  isOpenToTop: boolean;
  onMoodChange(v?: string): void;
};

const MoodPicker: FC<MoodPickerProps> = ({ isOpenToTop, onMoodChange }) => {
  const [mood, setMood] = useState<SelectOption<string>>(moodsOptions[0]);

  useEffect(() => {
    onMoodChange(mood.value);
  }, [mood]);

  return (
    <Wrapper isOpenToTop={isOpenToTop}>
      <Label>Audio mood</Label>
      <Select name="mood" value={mood} options={moodsOptions} onChange={setMood} />
    </Wrapper>
  );
};

export default MoodPicker;
