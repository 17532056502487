import { ArrowIcon, Row, Wrapper } from '@anm/components/Accordion';
import { TitleDescription } from '@anm/components/TitleBlock';
import styled, { css } from 'styled-components';

import { AccordionMediaProps, SectionDirection } from '../types';

type RowProps = Partial<AccordionMediaProps> & {
  isSingleItem: boolean;
};

const singleItemStyles = css`
  cursor: default;
  ${Wrapper}, ${Row} {
    border: none;
    padding: 0;
  }
  ${ArrowIcon} {
    opacity: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 40px;
    line-height: 54px;
  }
  ${TitleDescription} {
    font-size: 16px;
    line-height: 25px;
    margin-top: 24px;
  }
`;

const getDirectionStyles = (direction: SectionDirection) =>
  ({
    'accordion-media': css`
      flex-direction: row;
    `,
    'media-accordion': css`
      flex-direction: row-reverse;
    `
  }[direction]);

export const RowWrapper = styled.div<RowProps>`
  display: flex;
  ${({ direction }) => getDirectionStyles(direction || 'accordion-media')}
  ${({ isSingleItem }) => isSingleItem && singleItemStyles};
`;

const mediaWrapperGaps = css<Partial<AccordionMediaProps>>`
  margin: ${({ mediaMargin }) => mediaMargin};
`;

export const MediaWrapper = styled.div<Partial<AccordionMediaProps>>`
  width: calc(100% - 383px);
  display: flex;
  min-height: 440px;
  justify-content: center;
  margin: ${({ direction }) =>
    direction === 'accordion-media' ? '0 0 0 20px' : '0 20px 0 0'};
  & > div {
    width: 100%;
  }
  ${({ mediaMargin }) => mediaMargin && mediaWrapperGaps}
`;

export const AccordionWrapper = styled.div`
  ${Wrapper} > div {
    max-width: 363px;
  }
`;

const SectionWrapper = styled.section``;

export default SectionWrapper;
