import { ChangeEvent, FC, MouseEvent } from 'react';

import InputFileWrapper from './Wrapper';

export type UploadFileProps = {
  file: File;
  objectUrl: string;
};

type InputFileProps = {
  accept: string;
  handleUpload: (props: UploadFileProps[]) => void;
  disabled?: boolean;
  multiple?: boolean;
};

const InputFile: FC<InputFileProps> = ({ accept, multiple, handleUpload, disabled }) => {
  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (!target.files) return;

    const props = Array.from(target.files).map(file => ({ file, objectUrl: URL.createObjectURL(file) }));

    handleUpload(props);
    target.value = '';
  };

  const handleClick = (event: MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();

    if (disabled) event.preventDefault();
  };

  return (
    <InputFileWrapper>
      <input
        type="file"
        data-button="input file"
        accept={accept}
        multiple={multiple}
        onChange={handleChange}
        onClick={handleClick}
      />
    </InputFileWrapper>
  );
};

export default InputFile;
