import { noop } from 'lodash/fp';
import { useEffect, useRef, FC } from 'react';
import styled from 'styled-components';

import { colorPids } from './helpers';
import useMicStatus, { MicStatus } from './hooks/useMicStatus';
import useVolumeListener from './hooks/useVolumeListener';

export type MicVolumeProps = {
  className?: string;
  audioStream?: MediaStream;
  onMicStatusChange?: (status: MicStatus) => void;
};

const Wrapper = styled.div`
  width: 100%;
`;
const Pin = styled.div`
  width: calc(10% - 10px);
  height: 4px;
  display: inline-block;
  margin: 5px;
`;

const MicVolume: FC<MicVolumeProps> = ({ className, audioStream, onMicStatusChange = noop }) => {
  const ref = useRef<HTMLDivElement>(null);
  const {
    volumeData: { volume, signal }
  } = useVolumeListener(audioStream);
  const micStatus = useMicStatus(signal, audioStream);
  const { noVolume } = micStatus;

  useEffect(() => {
    if (!ref.current) return;

    colorPids({ volume, pidsElements: ref.current.children, isError: noVolume });
  }, [volume, !!ref.current, noVolume]);

  useEffect(() => {
    onMicStatusChange(micStatus);
  }, [micStatus.noVolume]);

  return (
    <Wrapper ref={ref} className={className}>
      {Array(10)
        .fill(null)
        .map((_, i) => (
          <Pin key={i} className="pid" />
        ))}
    </Wrapper>
  );
};

export default MicVolume;
