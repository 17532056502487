import styled from 'styled-components';
import FormHelperText from '@anm/components/form/FormHelperText';
import { Wrapper } from '@anm/components/form/Switch/Wrapper';

export const SwitchInputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 53px;
  ${Wrapper} {
    margin-bottom: 0;
  }
  ${FormHelperText} {
    margin: 4px 0 0 58px;
    max-width: 220px;
  }
`;

export const SwitchHelperText = styled(FormHelperText)`
  font-size: 12px;
  line-height: 20px;
  color: #8b9499;
`;

export default SwitchInputWrapper;
