import { CommonStats } from 'types/user';

type HelperProps = {
  storageStats: CommonStats | null;
  uploadedSize?: number;
};

const checkIsStorageError = ({ storageStats, uploadedSize }: HelperProps) => {
  // if it's not enabled then no further checks -> cannot upload
  if (storageStats?.state !== 'enabled') return true;

  // if it's enabled then we allow to upload only if there is available storage OR has payment info
  const usedData = storageStats?.used || 0;
  const availableData = storageStats?.available || 0;
  const hasPaymentInfo = !!storageStats?.hasPaymentInfo;
  const neededSize = uploadedSize || 0;

  const hasAvailableStorage = availableData - usedData >= neededSize;

  return !(hasAvailableStorage || hasPaymentInfo);
};

export default checkIsStorageError;
