import { FC } from 'react';

import FaqCollapse, {
  FaqAnswer,
  FaqAnswerText,
  FaqContainer,
  FaqItemWrapper
} from './Wrapper';

export type FaqItem = {
  answer: string;
  question: string;
  isOpened?: boolean;
  scrollRef?: React.RefObject<HTMLElement> | null;
};

export type FaqProps = {
  list: FaqItem[];
};

const Faq: FC<FaqProps> = ({ list }) => (
  <FaqContainer itemScope itemType="https://schema.org/FAQPage">
    {list.map(({ isOpened = false, question, answer, scrollRef }) => (
      <FaqItemWrapper
        key={question}
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
      >
        <FaqCollapse
          key={question + isOpened}
          ref={scrollRef}
          itemProp="name"
          variant="up-down-arrow"
          isOpened={isOpened}
          renderTarget={() => question}
          renderContent={() => (
            <FaqAnswer
              itemScope
              itemProp="acceptedAnswer"
              itemType="https://schema.org/Answer"
            >
              <FaqAnswerText
                itemProp="text"
                dangerouslySetInnerHTML={{ __html: answer }}
              />
            </FaqAnswer>
          )}
        />
      </FaqItemWrapper>
    ))}
  </FaqContainer>
);

export default Faq;
