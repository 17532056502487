import styled from 'styled-components';
import { flexRow } from '@anm/styles/helpers';
import { media } from '../../../styles/helpers';

const simpleLinkListOnTablet = media.tablet`
  a {
    font-size: 24px;
    line-height: 40px;
  }
`;

export const ListItem = styled.li`
  margin: 8px 10px 0;
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
`;

export const SimpleLinkListWrapper = styled.ul`
  ${flexRow};
  width: 100%;
  justify-content: space-evenly;
  margin-top: 32px;
  margin-bottom: 28px;
  flex-wrap: wrap;

  a {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #292a2b;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
  ${simpleLinkListOnTablet};
`;
