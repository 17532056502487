import { getMedia, MediaAllow } from '@anm/components/media/AllowMicCam';
import mediaManager from '@anm/helpers/media/mediaManager';
import useSwitchState from '@anm/hooks/useSwitchState';
import { useCallback, useEffect } from 'react';

import useMediaPending from '../useMediaPending';

type DeviceType = 'video' | 'audio';
type UseAllowMicCamControlsProps = {
  mediaAllow?: MediaAllow;
  deviceType?: DeviceType;
  onError?: (error: string | null) => void;
};

const useAllowMicCamControls = (
  { mediaAllow, onError }: UseAllowMicCamControlsProps | undefined = {
    mediaAllow: { audio: true, video: true }
  }
) => {
  const { isMediaLoaded } = useMediaPending({ mediaAllow });
  const [isCamMicAllowed, allowMicCam] = useSwitchState(isMediaLoaded);

  const refreshMedia = useCallback(async () => {
    await mediaManager().refresh(false);
    allowMicCam();
  }, []);

  const requestMedia = useCallback(async () => {
    try {
      await getMedia(mediaAllow);
      mediaManager().refresh(false);
      onError?.(null);
    } catch (e) {
      onError?.(e.name);
    }
  }, []);

  useEffect(() => {
    isMediaLoaded && !isCamMicAllowed && allowMicCam();
  }, [isMediaLoaded, isCamMicAllowed]);

  return [{ isCamMicAllowed }, { allowMicCam: refreshMedia, requestMedia }] as const;
};

export default useAllowMicCamControls;
