import EmptyList from '@anm/components/EmptyList';
import { Formats } from '@anm/data/videoTemplatesFormatModal';
import { useState, FC } from 'react';

import Tab from './Tab';
import TemplatePreview from './TemplatePreview';
import { CardModalWrapper, Content, CreateButton, TemplatesContainer, Title } from './Wrapper';
import { getSelectedIdFormat } from './helpers';
import { useCurrentFormatWidth, useModalHandlers, useTemplateData } from './hooks';
import { FormatAndStyleModalProps } from './types';

const FormatAndStyleModal: FC<FormatAndStyleModalProps> = ({ folderId, templates, ...handlersProps }) => {
  const [currentFormat, setFormat] = useState<Formats>(Formats.horizontal);
  const [selectedIdFormat, setIdFormat, handleCreateVideo] = useModalHandlers(handlersProps, folderId);

  const [templateId] = getSelectedIdFormat(selectedIdFormat);

  const template = templates?.find(t => t.templateId === templateId);
  const [mediaValue] = useTemplateData({
    templateId: template?.templateId,
    media: template?.meta.media,
    currentFormat,
    selectedIdFormat
  });

  const [width] = useCurrentFormatWidth({
    currentFormat,
    selectedIdFormat
  });

  return (
    <CardModalWrapper onModalClose={handlersProps.onModalClose} cardSettings={{ isPadding: false, borderRadius: 10 }}>
      <Content>
        <Title
          size="super-medium"
          title={'Choose a format and style for your video'}
          description="Here you can choose the aspect ratio, style and composition of your video. And AI will pick the relevant visuals for your text."
        />
        <Tab onChange={setFormat} currentFormat={currentFormat} />
        <TemplatesContainer {...{ width }}>
          {templates ? (
            templates.map(template => (
              <TemplatePreview
                key={template.templateId}
                {...{ ...template, currentFormat, selectedIdFormat }}
                onSelect={setIdFormat}
              />
            ))
          ) : (
            <EmptyList
              icon="images/icons/refresh.png"
              title="Something went wrong.<br> Try to refresh"
              margin="145px 0 0"
            />
          )}
        </TemplatesContainer>
        <CreateButton
          size="large"
          variant="light-blue-lowercase"
          onClick={handleCreateVideo}
          disabled={!selectedIdFormat || !mediaValue}
        >
          Create video
        </CreateButton>
      </Content>
    </CardModalWrapper>
  );
};

export default FormatAndStyleModal;
