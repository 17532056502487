import { SEMRUSH_PROD_APP_URL, SEMRUSH_TEST_APP_URL } from '@anm/constants/services';
import * as semrush from '@anm/helpers/semrush';
import isInIframe from '@anm/helpers/is/isInIframe';
import { useEffect } from 'react';
import { User } from 'user';
import jwt_decode, { JwtPayload } from 'jwt-decode';

import usePageParams from '../usePageParams';

export const isExpiredJwt = (jwt: string) => {
  const decoded: JwtPayload = jwt_decode(jwt);

  const isExpired = decoded.exp && decoded.exp * 1000 < Date.now();

  return isExpired;
}

const useRedirectSemrushUser = (user: User | null, isProd: boolean) => {
  const { jwt } = usePageParams<{ jwt?: string }>();

  const isSemrushIframe = semrush.isSemrushIframe();
  const isSemrushUser = user?.meta.entry === 'SEMRUSH';
  const semrushAppUrl = isProd ? SEMRUSH_PROD_APP_URL : SEMRUSH_TEST_APP_URL;

  const canRedirectToSemrushApp = !isSemrushIframe && isSemrushUser && !isInIframe();

  useEffect(() => {
    if (jwt === undefined) return;

    try {
      isExpiredJwt(jwt) && window.open(semrushAppUrl, '_self');
    } catch (error) {
      window.open(semrushAppUrl, '_self');
    }
  }, [jwt]);

  useEffect(() => {
    canRedirectToSemrushApp && window.open(semrushAppUrl, '_self');
  }, [canRedirectToSemrushApp]);
};

export default useRedirectSemrushUser;
