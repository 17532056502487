import videoConstrains from '@anm/data/media/videoConstrains';
import logger from '@anm/helpers/logger';
import clearStream from '@anm/helpers/media/clearStream';
import { MediaErrorName } from '@anm/helpers/media/createStream';
import isPermissionError from '@anm/helpers/media/isPermissionError';
import { noop } from 'lodash/fp';
import { useEffect, FC } from 'react';

import MediaError from '../MediaError';

export type AllowMicCamProps = {
  onAllow?: () => void;
  onError?: (error?: MediaErrorName | null) => void;
};

let stream: MediaStream | undefined;
export type MediaAllow = { video?: boolean; audio?: boolean };
export const getMedia = async ({ audio: allowAudio, video: allowVideo }: MediaAllow = { video: true, audio: true }) => {
  stream && clearStream(stream);

  stream =
    allowVideo || allowAudio
      ? await navigator.mediaDevices.getUserMedia({ audio: !!allowAudio, video: !!allowVideo && videoConstrains('hd') })
      : undefined;

  clearStream(stream);
};

const AllowMicCam: FC<AllowMicCamProps> = ({ onAllow = noop, onError }) => {
  useEffect(() => {
    (async () => {
      try {
        await getMedia();
        onAllow();
        onError?.(null);
      } catch (e) {
        onError?.(e.name);
        !isPermissionError(e) && logger(`Error occurred when try to allow mic and webcam: ${e}`);
      }
    })();
  }, []);

  return (
    <MediaError
      title="No camera or microphone access"
      description="Click “Allow” in the browser popup to get started."
    />
  );
};

export default AllowMicCam;
