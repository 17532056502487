import { Wrapper as ProgressiveVideoPreviewWrapper } from '@anm/components/video/ProgressiveVideoPreview';
import getFromCdn from '@anm/helpers/getFromCdn';
import { stretchBlock } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

type ItemProps = {
  index: number;
  columnCount: number;
  columnWidth: number;
  itemMargin?: number;
  itemHeight?: number;
};

const getItemWidth = css<ItemProps>`
  ${({ columnCount, columnWidth, itemMargin, index }) =>
    (index + 1) % columnCount === 0
      ? css`
          width: calc(${columnWidth}% - ${itemMargin}px + (${itemMargin}px / ${columnCount}));
        `
      : css`
          width: calc(${columnWidth}% + (${itemMargin}px / ${columnCount}));
        `}
`;

export const ItemCard = styled.div<ItemProps>`
  width: calc(100% - 40px);
  cursor: pointer;
  position: relative;
  margin-right: ${({ itemMargin }) => itemMargin}px;
  background: url(${getFromCdn('images/dashboard/default_preview.svg')}) no-repeat center #ebeef5;
  ${ProgressiveVideoPreviewWrapper} {
    width: 100%;
    & > * {
      width: 100%;
    }
  }
  ${({ columnCount, index }) =>
    (index + 1) % columnCount === 0 &&
    css`
      width: 100% !important;
      margin-right: 0 !important;
    `}
`;

export const VideoDuration = styled.span`
  right: 10px;
  bottom: 10px;
  color: white;
  z-index: 200;
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  position: absolute;
`;

export const HoverLayout = styled.div`
  ${stretchBlock};
  z-index: 130;
  pointer-events: none;
  background: rgba(32, 171, 210, 0.1);
`;

const Wrapper = styled.div<ItemProps>`
  ${getItemWidth};
  height: ${({ itemHeight }) => itemHeight}px;
  overflow: hidden;
  margin-bottom: ${({ itemMargin }) => itemMargin}px;
  img,
  video {
    height: 100%;
    object-fit: cover;
  }
`;

export default Wrapper;
