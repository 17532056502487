import Select, { SelectOption } from '@anm/components/select/Select';
import React, { useCallback, FC } from 'react';

import { TimePickerWrapper } from './Wrapper';
import { getTimeList } from './helpers';

const DEFAULT_INTERVAL = 15;

export type Direction = 'to-top' | 'to-bottom';

type TimePickerProps = {
  onTimeChange: (time: string) => void;
  defaultTime: string;
  timeList?: string[];
  interval?: number;
  contentDirection?: Direction;
};

const TimePicker: FC<TimePickerProps> = ({
  defaultTime,
  onTimeChange,
  timeList: timeListFromProps,
  interval = DEFAULT_INTERVAL,
  contentDirection = 'to-bottom'
}) => {
  const timeList = timeListFromProps || getTimeList(interval);
  const selectOptions = timeList.map(time => ({ label: time }));

  const handleTimeChange = useCallback(
    (time: SelectOption) => {
      onTimeChange(time.label);
    },
    [onTimeChange]
  );

  const defaultTimeValue: SelectOption = { label: defaultTime, value: defaultTime };

  return (
    <TimePickerWrapper {...{ contentDirection }}>
      <Select
        name="time"
        key={defaultTimeValue.label}
        value={defaultTimeValue}
        options={selectOptions}
        onChange={handleTimeChange}
      />
    </TimePickerWrapper>
  );
};

export default TimePicker;
