import { GenericLiveStream, LiveStream } from 'types/stream';
import { PlyrEvent } from 'plyr';
import { EnvType } from './config';

export type VideoSizes = {
  width: number;
  height: number;
};

export type CommonVideoConfigs = {
  end: VideoEnd;
  muted: boolean;
  autoplay: boolean;
  playHidden: boolean;
  hideUnmute: boolean;
  playerColor: string;
  bigPlayButton: boolean;
};

export type Configs = CommonVideoConfigs & {
  playbar: Playbar;
};

type Status = 'stable' | 'created' | 'in_process';
type FileType = 'video';

type UploadFile = {
  file: {
    id: string;
    status: Status;
    fileType: FileType;
  };
};

export type MediaUpload = {
  files?: UploadFile[];
};

export type Manifest = {
  id: string;
  meta: MetaInfo;
  userId: string;
  configs: Configs;
  uploadId: string;
  projectId: string;
  password: PasswordResponse;
  error?: string | null;
  status?: {};
  isEmbedded?: boolean;
} & MediaObjects;

export type ManifestErrorResponse = ApiError & {
  meta?: {
    color: string;
    width: number;
    height: number;
  };
};

type PasswordResponse = {
  hash?: string;
  isEnabled?: boolean;
  description?: string;
};

export type Password = {
  password?: string;
  isEnabled?: boolean;
  description?: string;
};

export type MetaInfo = {
  name: string;
  duration: number;
  uploadDate: string;
  description: string;
};

export type Playbar = { [key in PlaybarControls]: boolean };

export type PlayerConfig = {
  debug?: boolean;
  muted?: boolean;
  ratio?: string;
  poster?: string;
  volume?: number;
  duration?: number;
  quality?: PlayerQuality;
  iconUrl?: string;
  settings?: PlayerSettings[];
  seekTime?: number;
  autoplay?: boolean;
  controls?: PlayerMappedControls[];
  blankVideo?: string;
  resetOnEnd?: boolean;
  iconsColor?: string;
  fullscreen?: FullScreen;
  thumbnails?: string;
  invertTime?: boolean;
  clickToPlay?: boolean;
  playerColor?: string;
  playsinline?: boolean;
  hideControls?: boolean;
  disableContextMenu?: boolean;
  previewThumbnails?: Thumbs;
  storage?: { enabled: boolean };
  listeners?: { [key: string]: (error: PlyrEvent) => void };
};

export type SupportedQualities = 4320 | 2880 | 2160 | 1440 | 1080 | 720 | 576 | 480 | 360 | 240;

export type PlayerQuality = {
  default: SupportedQualities;
  options?: SupportedQualities[];
};

type Thumbs = {
  src: string;
  enabled: boolean;
};

type FullScreen = {
  enabled?: boolean;
  fallback?: boolean;
  iosNative?: boolean;
};

export type Poster = {
  posterUrl: string;
  file?: File;
  extension?: string;
};

export type Audio = {
  path: string;
};

export type VideoData = {
  meta: MetaInfo;
  audio: Audio;
  sizes: VideoSizes;
  poster: Poster;
  configs: Configs;
  sources: MediaObject[];
  uploadId: string;
  password: Password;
  projectId: string;
  customConfigs: PlayerConfig;
  bestQualityVideo: string;
  isEmbedded?: boolean;
};

export enum VideoStatusCode {
  not_found = 404,
  in_process = 409,
  codecErorr = 410,
  private = 441,
  processingError = 442,
  protected = 444,
  no_video = 455,
  blocked = 477,
  default = 'Video unavailable'
}

export type BrokenVideoStatusCodes =
  | VideoStatusCode.not_found
  | VideoStatusCode.private
  | VideoStatusCode.processingError
  | VideoStatusCode.blocked
  | VideoStatusCode.codecErorr;

export type ApiError = {
  code: VideoStatusCode;
  message: string;
};

export type ParentVideoData = {
  errorMessage?: string;
  videoData?: VideoData;
  errorCode?: VideoStatusCode;
};

export type PlayerControls =
  | 'pip'
  | 'play'
  | 'mute'
  | 'restart'
  | 'rewind'
  | 'volume'
  | 'airplay'
  | 'settings'
  | 'captions'
  | 'download'
  | 'progress'
  | 'duration'
  | 'fullscreen'
  | 'play-large'
  | 'fast-forward'
  | 'current-time';

export type PlayerSettings = 'captions' | 'quality' | 'speed' | 'loop';

export type Thumbnail = {
  path: string;
  width: number;
  height: number;
};

export type MediaObject = Media & {
  type: ObjectType;
};

type ObjectType = 'video' | 'image' | 'thumbnail' | 'audio';

export type Media = {
  path: string;
  width: number;
  height: SupportedQualities;
  codec?: string;
  bitrate?: number;
  isOriginal?: boolean | null;
};

export type MediaObjects = {
  main: MediaObject[];
  mobile: MediaObject[] | null;
  desktop: MediaObject[] | null;
};

export type VideoEnd = 'showLastFrame' | 'showThumbnail' | 'loop';

export type CommonControls = 'settings' | 'bigPlayButton';

export type PlaybarControls =
  | 'logo'
  | 'speed'
  | 'volume'
  | 'quality'
  | 'download'
  | 'fullScreen'
  | 'currentTime'
  | 'playbarButton'
  | 'smallPlayButton';

export type ManifestSource = 'cdn' | 'api';

export type AllControls = CommonControls | PlaybarControls;

type PreviewThumbnails = {
  src: string;
  enabled: boolean;
};

export type CustomConfigs = Configs & {
  previewThumbnails: PreviewThumbnails;
};

type PlayerSource = {
  type: string;
  sources: Source[];
  poster?: string;
};

export type Source = {
  src: string;
  type: string;
  size: string;
};

export type SeekEvent = {
  duration: number;
  currentTime: number;
};

export type Player = {
  media: HTMLVideoElement;
  ready: boolean;
  muted: boolean;
  paused: boolean;
  source: PlayerSource;
  quality: SupportedQualities;
  playing: boolean;
  hasAudio: boolean;
  elements: any;
  download: string;
  currentTime: number;
  play(): Promise<void>;
  pause(): () => void;
  destroy(): () => void;
  forward: (time: number) => void;
  togglePlay(): () => void;
  on: (
    evt: string,
    callback: (e: Event | TouchEvent) => void,
    options?: {
      passive: boolean;
    }
  ) => void;
  off: (evt: string, callback: (e: Event | TouchEvent) => void) => void;
  onseek?: (evt: SeekEvent) => void;
};

export type TargetType = 'customization' | 'standalone' | 'embed';

export type Target = {
  target?: TargetType;
};

type EndBehaviorValues = {
  loop: { active: boolean };
  resetOnEnd: boolean;
};

export type EndBehaviorMap = { [key in VideoEnd]: EndBehaviorValues };

export type PlayerMappedControls =
  | 'logo'
  | 'settings-speed'
  | 'volume'
  | 'settings-quality'
  | 'download'
  | 'settings'
  | 'progress'
  | 'fullscreen'
  | 'current-time'
  | 'playbar-button'
  | 'play-large'
  | 'play';

export type ControlsMap = { [key in AllControls]: PlayerMappedControls };

export type VideoPlayerCallbacks = {
  onInit?: (data?: VideoData) => void;
  onTimeChange?: (time: number) => void;
};

export type PlayerBlockCommonProps = {
  playerWidth: number;
  isPopover?: boolean;
  errorCode?: VideoStatusCode;
} & VideoPlayerCallbacks;

export type PlayerBlockProps = {
  videoId: string;
  isPending: boolean;
  onSubmit: (pwd: string) => void;
  color?: string;
  stream?: GenericLiveStream | null;
  password?: Password;
  startTime?: number;
  videoData?: VideoData;
  chatProps?: ChatProps;
  canDestroy?: boolean;
  errorMessage?: string;
  webinarOwner?: string;
  isDisabledSubmit?: boolean;
  isChatMobileView?: boolean;
} & PlayerBlockCommonProps &
  Target;

export type PlayerProps = {
  videoId: string;
  videoData: VideoData;
} & PlayerBlockCommonProps;

export type ChatProps = {
  isChat: boolean;
  isChatOpened: boolean;
  isInputOpened: boolean;
  onToggleChat: () => void;
  onToggleInput: () => void;
  isMobileView?: boolean;
};

export type AppProps = {
  videoId: string;
  env?: EnvType;
  stream?: GenericLiveStream | null;
  password?: Password;
  manifest?: Manifest;
  startTime?: number;
  isPopover?: boolean;
  chatProps?: ChatProps;
  canDestroy?: boolean;
  playerWidth?: number;
  webinarOwner?: string;
  parentVideoData?: ParentVideoData;
  isChatMobileView?: boolean;
} & Target &
  VideoPlayerCallbacks;

export type CommonProps = {
  env?: EnvType;
  playerWidth?: number;
};

export type VideoReadyProps = {
  hostId: string;
  stream?: LiveStream;
  manifest?: Manifest;
  parentVideoData?: ParentVideoData;
} & CommonProps;

export type VideoDataProps = {
  hostId: string;
  env?: EnvType;
  source?: ManifestSource;
  manifest?: Manifest;
  parentVideoData?: ParentVideoData;
} & CommonProps;
export type ProtectedDataProps = {
  hostId: string;
  videoInfo?: ParentVideoData;
} & CommonProps;

export type VideoInfo = ParentVideoData & { color?: string };

export type BlockedManifest = {
  status: {};
  meta: {
    width: number;
    height: number;
    color: string;
  };
};
