import { MODAL_NOTIFICATION_CONTAINER_ID } from '@anm/components/modals/NotificationModal/ModalNotificationRoot';
import isServer from '@anm/helpers/is/isServer';
import useIsMountedCheck from '@anm/hooks/useIsMountedCheck';
import { useEffect, FC } from 'react';
import { createPortal } from 'react-dom';

import { MODAL_CONTAINER_ID } from './ModalRoot';

const MODAL_CONTAINERS_IDS = [MODAL_CONTAINER_ID, MODAL_NOTIFICATION_CONTAINER_ID];

const setBlur = (val: string) => {
  const elements = document.querySelectorAll<HTMLElement>(`#__next > *`);

  elements.forEach(element => {
    if (!MODAL_CONTAINERS_IDS.includes(element.id)) element.style.filter = val;
  });
};

const setOverflow = (val: string) => {
  document.getElementsByTagName('body')[0].style.overflow = val;
};

const ModalPortal: FC = ({ children }) => {
  const isMounted = useIsMountedCheck();

  useEffect(() => {
    setOverflow('hidden');
    setBlur('blur(4px)');

    return () => {
      setOverflow('visible');
      setBlur('none');
    };
  }, []);

  if (isServer()) return null;

  const container = document.getElementById(MODAL_CONTAINER_ID);

  return container && isMounted ? createPortal(children, container) : null;
};
export default ModalPortal;
