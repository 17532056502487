import { CreateVideoFromAISubmitProps } from '@anm/analytic/mixpanelAnalytic';
import { AllJobIdParams } from '@anm/api/modules/storyboard';
import { CreateVideoHandlerProps } from '@anm/components/my-projects/FormatAndStyleModal/types';

type ConvertJobIdParamsToAnalyticProps = AllJobIdParams & CreateVideoHandlerProps;

const convertJobIdParamsToAnalytic = ({
  mood,
  title = '',
  max_len,
  max_slide_len,
  use_premium_stocks,
  format,
  template
}: ConvertJobIdParamsToAnalyticProps): CreateVideoFromAISubmitProps => {
  const texts = max_slide_len === 100 ? 'short' : max_slide_len === 200 ? 'medium' : 'long';
  const stocks = use_premium_stocks ? 'all' : 'free';
  const duration = max_len || 0;

  return {
    mood,
    texts,
    title,
    stocks,
    format,
    duration,
    template
  };
};

export default convertJobIdParamsToAnalytic;
